import {
  ConfiguredFile,
  configureFilesObject,
  confugureFilesBeforeSend,
  FileObject,
  hashFiles,
} from 'helpers';
import { hyphensToCamelCase } from 'utils/formatters';
import {
  VendorFileProp, FileResponse, HashedFile, VendorToCreate, VendorVehicleProp,
} from 'interfaces';
import { createVendor, sendFile } from 'services/commonService';
import { buttons } from 'utils/optionsConfig';

export type PhotosArray = FileObject[];

export const vendorFileProps: VendorFileProp[] = [
  'driverLicenseFront',
  'driverLicenseBack',
  'technicalPassportFront',
  'technicalPassportBack',
  'vehiclePhotoFront',
  'vehiclePhotoSideLeft',
];

const vendorVehicleProps: VendorVehicleProp[] = [
  'vehicleYear',
  'vehicleBrand',
  'vehicleColor',
  'vehicleDoorsCount',
  'vehicleModel',
  'vehicleSeatsCount',
];

const vehicleTypes = ['alongTheWay', 'helpOnRoad'];

export const toDTOVendor = async (
  vendor: VendorToCreate,
  photos: PhotosArray,
  hashesOfFiles: HashedFile[],
): Promise <VendorToCreate> => {
  const { createVehicle, ...newVendor } = vendor;

  const authOptions = buttons.filter(({ type }) => (
    createVehicle ? true : !vehicleTypes.includes(type)
  ));

  const formattedServices = authOptions && authOptions.reduce((
    acc: { [key: string]: { [key: string]: string[] } },
    item,
  ) => {
    const { type, service, contentKey } = item;
    const currType = acc[type] ?? {};
    const currService = currType[service] ?? [];
    const typeCC = hyphensToCamelCase(type);

    const newServicesArray = (currType && currService)
      ? [...currService, contentKey]
      : [contentKey];

    return {
      ...acc,
      [typeCC]: {
        ...currType,
        [service]: newServicesArray,
      },
    };
  }, {});

  const configuredFiles = hashesOfFiles.reduce((acc, fileHash: HashedFile, index: number) => {
    const { field } = photos[index];
    return {
      ...acc,
      [field]: { ...fileHash, permissions: 'private' },
    };
  }, {});

  const formattedvendorVehicleProps = vendorVehicleProps.reduce((acc, prop: VendorVehicleProp) => ({
    ...acc,
    [prop]: newVendor[prop]?.id.toString(),
  }), {});

  return ({
    ...newVendor,
    ...configuredFiles,
    ...formattedServices,
    ...formattedvendorVehicleProps,
  });
};

export const configureVendor = async (data: VendorToCreate): Promise<{
  photos: FileObject[];
  hashesOfFiles: HashedFile[];
  formattedVendor: VendorToCreate;
}> => {
  const photos = await configureFilesObject(vendorFileProps, data);

  const hashesOfFiles = await hashFiles(photos);
  const formattedVendor = await toDTOVendor(data, photos, hashesOfFiles);

  return {
    photos,
    hashesOfFiles,
    formattedVendor,
  };
};

export const submitter = async (data: VendorToCreate): Promise<boolean> => {
  const {
    photos,
    formattedVendor,
    hashesOfFiles,
  } = await configureVendor(data);

  const createdVendor = await createVendor(formattedVendor);

  const formattedFiles = confugureFilesBeforeSend(photos, hashesOfFiles, createdVendor);

  const promises = formattedFiles.map(({ data: formData, id }: ConfiguredFile) => (
    sendFile(formData, 'vendors', id)
  ));
  const results = await Promise.all(promises) as FileResponse[];

  return results.every(({ result }) => result);
};
