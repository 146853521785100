import { Avatar, Box, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Star } from '@material-ui/icons';
import LocationList from 'components/LocationList';
import classNames from 'classnames';
import Icons from 'components/Icons';
import {
  IOrder,
  RatingType,
  Preorder,
  User,
} from 'interfaces';
import { getTranslateFunction, getUserImageLink } from 'helpers';
import { dateFormatter, formatMessage } from 'utils/formatters';
import useStyles from './styles';
import { cyan } from '../../constants';

const {
  CommentTsx,
  nowSvg,
  toTimeSvg,
} = Icons;

interface Props {
  offer: Preorder | IOrder;
  user: User;
  isSingleTypePreorder: boolean;
  userRating: RatingType | undefined;
}

const UserInfoContainer: React.FC<Props> = ({
  offer,
  user,
  isSingleTypePreorder,
  userRating,
}) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  const tripTimeIcon = useMemo(() => (offer.tripTime === 'now' ? nowSvg : toTimeSvg), [offer]);
  const tripTimeText = useMemo(() => (
    offer.tripTime === 'now' ? t('preorder.modal.nowLabel') : dateFormatter(Number(new Date(offer.tripTime)))
  ), [offer]);
  const photoSrc = useMemo(() => getUserImageLink(user?.userPhoto, user?.gender), [user]);
  const isInternalTransfer = useMemo(() => offer.paymentType === 'internalTransfer', []);

  return (
    <Box className={classes.preorderContainer}>
      <Box className={classes.preorderInfo}>
        <Box className={classes.userContainer}>
          <Box className={classes.userName}>{user?.firstName}</Box>
          <Avatar variant="rounded" className={classes.userPhoto} alt={user?.firstName} src={photoSrc} />
        </Box>
        <Box className={classes.preorderSettings}>
          <Box className={classes.userInfos}>
            {userRating && (
              <Box className={classes.listItem}>
                <Star className={`${classes.svg} star`} />
                <Typography>{Math.floor(Number(userRating.politenessRatingAvg))}</Typography>
              </Box>
            )}
          </Box>
          <Box className={classes.preorderServices}>
            {offer.together && (
              <Box className={classes.listItem}>
                <img
                  className={classes.svg}
                  src={Icons[`${offer.together}Svg`]}
                  alt={t(`preorder.modal.${offer.together}Label`)}
                />
                <Typography className={classes.listItemLabel} variant="caption">{t(`preorder.modal.${offer.together}Label`)}</Typography>
              </Box>
            )}
            {offer.tripRequirements && (
              <Box className={classes.listItem}>
                <img
                  className={classes.svg}
                  src={Icons.individualSvg}
                  alt={t(`preorder.modal.${offer.together}Label`)}
                />
                <Typography className={classes.listItemLabel} variant="caption">{t(offer.tripRequirements)}</Typography>
              </Box>
            )}
            <Box className={classes.listItem}>
              <img
                className={classes.svg}
                src={tripTimeIcon}
                alt={tripTimeText}
              />
              <Typography className={classes.listItemLabel} variant="caption">{tripTimeText}</Typography>
            </Box>
            {offer.passengers && (
              <Box className={classes.listItem}>
                <img
                  className={classes.svg}
                  src={Icons.individualSvg}
                  alt={t('preorder.modal.passengers')}
                />
                <Typography className={classes.listItemLabel} variant="caption">{t('preorder.modal.passengers')}</Typography>
                <Typography className={classes.endAdornment} variant="caption">{offer.passengers}</Typography>
              </Box>
            )}
            {offer.baggage && (
              <Box className={classes.listItem}>
                <img
                  className={classes.svg}
                  src={Icons.baggageSvg}
                  alt={t('preorder.modal.baggage')}
                />
                <Typography className={classes.listItemLabel} variant="caption">{t('preorder.modal.baggage')}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box className={classes.proposalContainer}>
        {!isSingleTypePreorder && (
          <LocationList
            dots={offer.dots}
            classes={{
              dotBox: classes.listItem,
              dotBoxLabel: classes.listItemLabelBackgounded,
              svg: classes.svg,
            }}
            withIcon
          />
        )}
        <Box className={classes.listItem}>
          <img
            className={classes.svg}
            src={Icons[`${offer.paymentType}Svg`]}
            alt={t(`preorder.modal.${offer.paymentType}`)}
          />
          <Typography className={classes.listItemLabelBackgounded} variant="caption">{t(`preorder.modal.${offer.paymentType}`)}</Typography>
          <Typography variant="caption" className={classNames(classes.endAdornment, classes.priceLabel)}>{formatMessage('${price} ${currency}', offer)}</Typography>
        </Box>
        {isInternalTransfer && (
          <Box className={classes.listItem}>
            <img
              className={classes.svg}
              src={Icons.internalTransferSvg}
              alt={t('preorder.modal.internalTransfer')}
            />
            <Typography className={classes.listItemLabelBackgounded} variant="caption">{t('order.internalTransfer.description')}</Typography>
          </Box>
        )}
        {offer.comment && (
          <Box className={classes.listItem}>
            <Box
              className={classes.svg}
            >
              <CommentTsx color={cyan} />
            </Box>
            <Typography className={classes.listItemLabelBackgounded} variant="caption">{offer.comment}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UserInfoContainer;
