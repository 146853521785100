import React, {
  useCallback,
  useEffect,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import SharedModal from 'sharedComponents/GlobalModal/GlobalModal';
import { soundModule } from 'services/soundService';
import { getTranslateFunction } from 'helpers';
import RequestService from 'services/requestApi';
import { ORDER_STATUSES } from '../../constants';
import { useNotifications } from './notifications';
import { PortalModal } from './PortalModal';

const GlobalModal = () => {
  const t = getTranslateFunction();
  const {
    exitingModal,
    setExitingModal,
    canceledByVendorModal,
    setCanceledByVendorModal,
    canceledByClientModal,
    setCanceledByClientModal,
    clientComesModal,
    setClientComesModal,
    toTimeModal,
    setToTimeModal,
  }: any = useNotifications();
  const location = useLocation();
  const sound = soundModule();
  const history = useHistory();

  const handleClickExistingModal = useCallback(() => {
    new RequestService('/order/status').put({
      orderId: exitingModal.id,
      status: ORDER_STATUSES.EXITING,
    });
    setExitingModal(null);
  }, [exitingModal]);

  const handleClickCanceledByVendorModal = useCallback(() => {
    setCanceledByVendorModal(null);
    const inOrderPage = location.pathname === '/client-order';
    if (inOrderPage) {
      history.push('/');
    }
  }, [location]);

  const handleClickClientComesModal = useCallback(() => {
    setClientComesModal(null);
  }, []);

  const handleClickToTimeModal = useCallback(() => {
    setToTimeModal(null);
  }, []);

  const goToOrder = useCallback((order) => {
    setToTimeModal(null);
    history.push('/vendor-order-room', [order]);
  }, []);

  const handleClickCanceledByClientModal = useCallback(() => {
    setCanceledByClientModal(null);
    const inOrderPage = location.pathname === '/vendor-order-room';
    if (inOrderPage) {
      history.push('/');
    }
  }, [location]);

  useEffect(() => {
    if (
      exitingModal
      || canceledByVendorModal
      || clientComesModal
      || canceledByClientModal
    ) {
      sound.playSound({ force: true });
    }
  }, [
    exitingModal,
    canceledByVendorModal,
    clientComesModal,
    canceledByClientModal,
  ]);

  return (
    <PortalModal>
      {toTimeModal && (
        <SharedModal
          isOpen={Boolean(toTimeModal)}
          confirm={handleClickToTimeModal}
          secondaryConfirm={() => goToOrder(toTimeModal)}
          title={t('notification.youHaveToTimeOrder')}
          content={`${toTimeModal.timeLabel} ${t('notification.toOrder')}`}
          buttonText={t('ClientOrderPage.Ok')}
          secondaryButtonText={t('notification.goToOrder')}
        />
      )}
      {exitingModal && (
        <SharedModal
          isOpen={Boolean(exitingModal)}
          confirm={handleClickExistingModal}
          title={t('ClientOrderPage.notificationHeader')}
          content={t('ClientOrderPage.notification.DriverInPlace')}
          buttonText={t('ClientOrderPage.exitingLabel')}
        />
      )}
      {canceledByVendorModal && (
        <SharedModal
          isOpen={Boolean(canceledByVendorModal)}
          confirm={handleClickCanceledByVendorModal}
          content={t('ClientOrderPage.notification.OrderWasCancledByVendor')}
          buttonText={t('ClientOrderPage.Ok')}
        />
      )}
      {canceledByClientModal && (
        <SharedModal
          isOpen={Boolean(canceledByClientModal)}
          confirm={handleClickCanceledByClientModal}
          content={canceledByClientModal.text}
          buttonText={t('preorderPage.button.confirmLocationSelect')}
        />
      )}
      {clientComesModal && (
        <SharedModal
          isOpen={Boolean(clientComesModal)}
          confirm={handleClickClientComesModal}
          content={clientComesModal.text}
          buttonText={t('OrderPage.confirm')}
        />
      )}
    </PortalModal>
  );
};

export default GlobalModal;
