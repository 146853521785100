import { makeStyles } from '@material-ui/core';

export default makeStyles({
  modal: {
    position: 'absolute',
    left: '20px',
    top: '20px',
  },
  svg: {
    width: '25px',
    height: 'auto',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    marginLeft: '5px',
  },
}, { index: 1 });
