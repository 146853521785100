import { makeStyles } from '@material-ui/core/styles';
import { red } from '../../constants';

export default makeStyles({
  mainContainer: {
    marginBottom: '100px',
  },
  submitBtn: {
    color: 'white',
    fontWeight: 'bold',
    width: '100%',
  },
  errorMessage: {
    color: red,
  },
}, { index: 1 });
