import React, { useCallback, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { useHistory } from 'react-router';

import ChatForm from 'components/Chat/Form';
import MenuModal from 'pages/ActiveChat/components/MenuModal';
import UserInfoBlock from 'pages/Chat/components/UserInfoBlock';
import MessagesBlock from 'components/Chat/MessagesBlock';
import Preloader from 'components/Preloader/Preloader';
import { IOnChange } from 'interfaces';
import supportIcon from 'components/common/supportIcon.png';

import { getTranslateFunction } from 'helpers';
import { useSupportChat } from 'hooks/chat';
import { formatMessagesToChat } from 'utils/formatters';
import { useAuth } from 'services/auth';
import { deleteChatAction } from 'pages/ActiveChat/config';
import useStyles from '../ActiveChat/styles';

const SupportChat = () => {
  const t = getTranslateFunction();
  const { user } = useAuth();
  const classes = useStyles();
  const history = useHistory();

  const {
    messages,
    deleteChat,
    sendMessage,
    isLoading,
    loadMoreMessages,
    setIsLoading,
  } = useSupportChat();

  const handleGoBack = () => {
    if (history.action === 'POP') {
      history.push('/chat');
      return;
    }

    history.goBack();
  };

  const sortedMessages = useMemo(() => (
    formatMessagesToChat(messages)
  ), [messages]);

  const handleDeleteChat = useCallback(async () => {
    try {
      setIsLoading(true);
      await deleteChat();
      history.push('/chat');
    } finally {
      setIsLoading(false);
    }
  }, [deleteChat]);

  const onChange = ({ action }: IOnChange) => {
    switch (action) {
      case deleteChatAction: {
        handleDeleteChat();
        break;
      }
      default: break;
    }
  };

  return (
    <Box className={classes.chat}>
      <Box className={classes.chatUserInfoWrapper}>
        <Box onClick={handleGoBack}>
          <ArrowBackIos className={classes.arrowIcon} fontSize="large" />
        </Box>

        <UserInfoBlock
          imgUrl={supportIcon}
          firstName={t('Andrea')}
          lastName={t('Rosso')}
        />

        <MenuModal isSupportChat onChange={onChange} />
      </Box>

      <MessagesBlock
        loadMoreMessages={loadMoreMessages}
        className={classes.container}
        messages={sortedMessages}
        usermeUuid={user!.uuid}
      />

      <ChatForm onSubmit={sendMessage} />

      {isLoading && <Preloader />}
    </Box>
  );
};

export default SupportChat;
