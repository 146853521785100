import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import CommentSvg from 'images/comment';
import { Controller, useForm } from 'react-hook-form';
import { getTranslateFunction } from 'helpers';
import { IOnChange } from 'interfaces';
import useWindowSize from 'services/useWindowSize';
import useStyles from './ClientOrderPageStyle';
import { textColor } from '../../constants';

interface ClientRatingModalProps {
  onChange: (a: IOnChange) => void;
  id: number;
  children: any;
  type: string;
  tripType: string;
}

export const ClientRatingModal: React.FC<ClientRatingModalProps> = ({
  id,
  onChange,
  children,
  type,
  tripType,
}) => {
  const t = getTranslateFunction();
  const { height } = useWindowSize();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      politenessRating: 5,
      vehicleConditionRating: 5,
      drivingSkillRating: 5,
      diligenceRating: 5,
    },
  });

  const [message, setMessage] = useState<string>('');
  const classes = useStyles({ height });
  const listItems = [
    {
      label: t('ClientOrderPage.politeness'),
      name: 'politenessRating',
    },
  ];

  const diligence = {
    label: t('ClientOrderPage.diligence'),
    name: 'diligenceRating',
  };

  const alongTheWay = [
    {
      label: t('ClientOrderPage.driving'),
      name: 'drivingSkillRating',
    },
    {
      label: t('ClientOrderPage.vehicleCondition'),
      name: 'vehicleConditionRating',
    },
  ];

  if (tripType === 'courier' || tripType === 'helpOnRoad' || tripType === 'buyThings') {
    listItems.splice(1, 0, diligence);
  }

  if (tripType === 'alongTheWay') {
    listItems.splice(1, 0, ...alongTheWay);
    if (type === t('HomePage.alongTheWay.TemporaryDriver')) {
      listItems.pop();
    }
  }

  const handleSendReport = useCallback((data) => {
    onChange({
      action: 'setVendorRating',
      data: {
        rating: data,
        orderId: id,
        comment: message,
      },
    });
  }, [message]);

  const onChangeRating = useCallback((typeEvent: string, name: any) => {
    const prev = Number(getValues(name));
    let value = prev;
    if (typeEvent === '-' && value > 0) value -= 1;
    if (typeEvent === '+') value += 1;

    if (value !== prev) {
      setValue(name, value);
    }
  }, [getValues, setValue]);

  return (
    <div className={classes.ratingBox}>
      <form onSubmit={handleSubmit(handleSendReport)} className={classes.form}>
        <Typography
          variant="h5"
          className={classes.ratingTitle}
        >
          {t('ClientOrderPage.orderFinished')}
        </Typography>
        {children}
        <Typography
          variant="h5"
          className={classes.ratingTitle}
        >
          {t('ClientOrderPage.rateVendor')}
        </Typography>
        <Box className={classes.ratingItem}>
          <Typography className={classes.maxLabel}>{t('ClientOrderPage.maximumRating')}</Typography>
          <Box className={classes.numberBox}>
            <Typography>10</Typography>
          </Box>
        </Box>
        {listItems.map((item) => (
          <Box className={classes.ratingItem} key={item.name}>
            <Typography>{item.label}</Typography>
            <Box className={classes.ratingItem} key={item.name}>
              <Controller
                render={({ value }) => (
                  <Box className={classes.numberBox}>
                    <Box onClick={() => onChangeRating('-', item.name)}>-</Box>
                    <Typography>{value}</Typography>
                    <Box onClick={() => onChangeRating('+', item.name)}>+</Box>
                  </Box>
                )}
                name={item.name}
                control={control}
              />
            </Box>
          </Box>
        ))}
        <TextField
          className={classes.reportMessage}
          value={message}
          multiline
          placeholder={t('ClientOrderPage.sendComment')}
          onChange={({ target: { value } }) => setMessage(value)}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <Box className={classes.svg}>
                <CommentSvg color={textColor} />
              </Box>
            ),
          }}
        />
        <Button
          color="primary"
          type="submit"
          className={classes.ratingSendBtn}
        >
          {t('ClientOrderPage.sendRating')}
        </Button>
      </form>
    </div>
  );
};

interface OrderInfo {
  modal: boolean;
  onClose: (a: any) => void;
  children: any;
}

export const OrderInfoModal: React.FC<OrderInfo> = ({
  modal,
  onClose,
  children,
}) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  return (
    <Dialog className={classes.withBorder} open={modal} onClose={onClose} fullWidth>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t('ClientOrderPage.Ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface DriverOnRoad {
  modal: boolean;
  onClose: (a: any) => void;
}

export const DriverOnRoadModal: React.FC<DriverOnRoad> = ({
  modal,
  onClose,
}) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  return (
    <Dialog className={classes.withBorder} open={modal} onClose={onClose} fullWidth>
      <DialogContent>
        <DialogContentText>
          {t('ClientOrderPage.notification.DriverInRoad')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t('ClientOrderPage.Ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface VendorInfo {
  modal: boolean;
  onClose: (a: any) => void;
  children: any;
  head: any;
}

export const VendorInfoModal: React.FC<VendorInfo> = ({
  modal,
  onClose,
  children,
  head,
}) => {
  const { height } = useWindowSize();
  const t = getTranslateFunction();
  const classes = useStyles(height);

  return (
    <Dialog className={`${classes.modalComment} ${classes.withBorder}`} open={modal} onClose={onClose} fullWidth>
      <DialogTitle className={classes.modalCommentContent}>{head}</DialogTitle>
      <DialogContent className={classes.modalCommentContent}>
        {children}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t('ClientOrderPage.Ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
