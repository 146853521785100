import React, { FC } from 'react';

interface Props {
  color: string;
}

const Archive: FC<Props> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 72 72">
    <defs />
    <path d="M2 11.9v11.5h68.1V11.9zM44.6 27.4c0 3.6-3.8 6.6-8.6 6.6-4.7 0-8.5-3-8.5-6.6H7v35.3H65V27.4H44.6z" fill={color} />
  </svg>
);

export default Archive;
