import { makeStyles } from '@material-ui/core';
import { cyan, grayBG } from '../../../constants';

export default makeStyles({
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${grayBG}`,
    height: '50px',
    width: '100%',
    backgroundColor: grayBG,
  },
  sentBtn: {
    color: cyan,
    padding: '10px',
    fontWeight: 'bold',
    fontSize: '12px',
    minWidth: '40px',
    height: '40px',
    borderRadius: '50%',
    margin: '5px',
  },
  messageInput: {
    border: `1px solid ${grayBG}`,
    width: '100%',
    height: '27px',
    marginLeft: '10px',
    borderRadius: '21px',
    padding: '0 10px',
    fontSize: '12px',
    outline: 'none',
  },
  filesInputImg: {
    width: '25px',
    height: '25px',
  },
  filesInput: {
    display: 'none',
  },
}, { index: 2 });
