import React, { FC } from 'react';

interface Props {
  color: string;
}

const Home: FC<Props> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
    <path fill={color} d="M70.76 37.62L36 5.3 1.24 37.62h10.54V66.7h15.68V47.19h17.08V66.7h15.68V37.62z" />
  </svg>
);

export default Home;
