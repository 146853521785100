import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { User } from 'interfaces';
import { getTranslateFunction } from 'helpers';
import { useIsLoggedIn } from 'services/auth';
import { recoverPassword } from 'services/commonService';

import { useRequests } from 'services/customHooks';
import { parseQueryString } from 'utils/formatters';
import { RESET_PASSWORD_STATUSES } from '../../constants';

export const useResetPassword = (): {
  status: number,
  resetPassword: (newPassword: string) => Promise<User>;
  attempts: number;
  isAttemptsForCodeLeft: boolean;
  sendCode: (code: string) => Promise<void>;
  resendRequest: () => Promise<void>;
  sendRequest: (data: { login: string }) => Promise<void>;
} => {
  const location = useLocation();
  const { search } = location;
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [status, setStatus] = useState<number>(RESET_PASSWORD_STATUSES.FILL_FORM);
  const [code, setCode] = useState<number | null>(null);
  const [recoverString, setRecoverString] = useState<string>('');
  const [login, setLogin] = useState<string>('');
  const t = getTranslateFunction();

  useIsLoggedIn();

  const {
    sendRequest,
    sendCode,
    attempts,
    isAttemptsForCodeLeft,
  } = useRequests();

  const handleRequest = async (data: { login: string }) => {
    setIsLoading(true);
    try {
      const response = await sendRequest('/users/create-request', data);
      const { recoveryLink } = response;

      setStatus(RESET_PASSWORD_STATUSES.ENTER_CODE);
      setRecoverString(recoveryLink);
      setLogin(data.login);
    } catch (error) {
      const e = error as Error;
      throw new Error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const goToResetPasswordPage = useCallback(() => {
    history.push('/reset-password');
  }, []);

  useEffect(() => {
    const getQueryData = async () => {
      const query = parseQueryString(search);
      const { recoveryLink, email } = query;

      if (!recoveryLink || !email) {
        goToResetPasswordPage();
        return;
      }

      setIsLoading(true);
      try {
        await handleRequest({ login: email });
      } catch (error) {
        goToResetPasswordPage();
      } finally {
        setIsLoading(false);
      }
    };

    getQueryData();
  }, [search]);

  const resendRequest = () => handleRequest({ login });

  const handleSendCode = async (inputCode: string) => {
    try {
      const response = await sendCode('/users/validate-request', {
        link: recoverString,
        code: Number(inputCode),
        login,
      });

      if (response) {
        setStatus(RESET_PASSWORD_STATUSES.ENTERING_NEW_PASSWORD);
        setCode(Number(inputCode));
      }
    } catch (error) {
      const e = error as Error;
      throw new Error(e.message);
    }
  };

  const handleResetPassword = async (newPassword: string) => {
    if (!code || isLoading) {
      return null;
    }

    try {
      setIsLoading(true);
      const data = {
        password: newPassword,
        link: recoverString,
        code,
        login,
      };
      toast.success(t('resetPasswordPage.toast.successReset'));
      history.push('/signin');
      const response = await recoverPassword(data);
      return response;
    } catch (error) {
      const e = error as Error;
      throw new Error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    status,
    resetPassword: handleResetPassword,
    resendRequest,
    sendRequest: handleRequest,
    sendCode: handleSendCode,
    attempts,
    isAttemptsForCodeLeft,
  };
};
