import React, { useMemo } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import PreorderInfoComponent from 'components/PreorderInfoComponent';
import UserInfoContainer from 'components/UserInfoContainer';
import Modal from 'sharedComponents/Modal';
import VendorInfoItem from 'components/VendorInfoComponent/VendorInfoItem';
import {
  IOnChange,
  IComment,
  IOrder,
  RatingType,
  User,
  Vendor,
} from 'interfaces';
import { getTranslateFunction, isSingleType } from 'helpers';
import useStyles from '../OrderHistoryStyle';
import OrderListItem from './OrderListItem';
import { getChatButtonStatus } from '../helpers';

interface Props {
  orders: IOrder[];
  showOrder: IOrder | null;
  user: User;
  vendors: Vendor[];
  vendor: Vendor | null;
  imVendor: boolean;
  vendorComments: IComment[];
  userRating: RatingType;
  onChange: (a: IOnChange) => void;
}

const OrderHistoryComponent: React.FC<Props> = ({
  orders,
  showOrder,
  user,
  userRating,
  vendor,
  vendorComments,
  vendors,
  imVendor,
  onChange,
}: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  const isSingleTypeOrder = useMemo(() => (
    Boolean(showOrder && isSingleType(showOrder.tripType))
  ), [showOrder]);

  const toChatLabel = imVendor
    ? 'orderHistoryPage.toClientChat'
    : 'orderHistoryPage.toVendorChat';

  const currency = useMemo(() => (imVendor ? 'preorderServiceCurrency' : 'preorderCurrency'), [imVendor]);

  const handleCloseOrderModal = () => {
    onChange({
      action: 'setShowOrder',
      data: null,
    });
  };

  const handleChat = () => {
    onChange({
      action: 'chatToUser',
      data: user?.uuid,
    });
  };

  const handleRepeatOrder = () => {
    onChange({
      action: 'repeatOrder',
      data: showOrder,
    });
  };

  const handleRepeatReverseOrder = () => {
    onChange({
      action: 'repeatReverseOrder',
      data: showOrder,
    });
  };

  const showModal = Boolean(vendor && showOrder && userRating);
  const showChatButton = useMemo(() => getChatButtonStatus(showOrder), [showOrder]);

  return (
    <>
      <Box className={classes.orderHistoryContainer}>
        {orders.map((order: IOrder) => {
          const orderVendor = vendors.find((v) => v.id === order.serviceId);

          return (
            <OrderListItem
              onChange={onChange}
              key={order.preorderId}
              vendor={orderVendor}
              order={{
                ...order,
                currency: order[currency],
              }}
            />
          );
        })}
      </Box>

      <Modal
        withoutBorder
        fullWidth
        isOpen={Boolean(showModal)}
        onClose={handleCloseOrderModal}
      >
        {vendor && showOrder && userRating && (
          <>
            <Typography className={classes.notRelevant}>
              {t('OrderHistory.notRelevant')}
            </Typography>
            {imVendor ? (
              <UserInfoContainer
                isSingleTypePreorder={isSingleTypeOrder}
                offer={{
                  ...showOrder,
                  currency: showOrder[currency],
                }}
                user={user}
                userRating={userRating}
              />
            ) : (
              <>
                <PreorderInfoComponent
                  preorder={showOrder}
                />
                <VendorInfoItem
                  vendor={vendor}
                  t={t}
                  vendorUser={user}
                  order={{
                    ...showOrder,
                    currency: showOrder[currency],
                  }}
                  ratingAvg={1}
                  userRating={userRating}
                  commentsLength={vendorComments.length}
                />
              </>
            )}

            {showChatButton && (
              <Button onClick={handleChat} className={classes.toChatButton} variant="contained">
                {t(toChatLabel)}
              </Button>
            )}

            {!isSingleTypeOrder && !imVendor && (
              <Box className={classes.repeatOrderBox}>
                <Button
                  className={classes.repeatOrderBtn}
                  onClick={handleRepeatOrder}
                  variant="contained"
                  color="primary"
                >
                  {t('orderHistoryPage.repeatOrder')}
                </Button>
                <Button
                  className={classes.repeatOrderBtn}
                  onClick={handleRepeatReverseOrder}
                  variant="contained"
                  color="primary"
                >
                  {t('orderHistoryPage.repeatReverseOrder')}
                </Button>
              </Box>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default OrderHistoryComponent;
