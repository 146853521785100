import React, { FC } from 'react';

interface Props {
  color: string;
}

const betweenCity: FC<Props> = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 72"
  >
    <path
      d="M2.71 9.92v46.71h57.97l8.61 10.83V9.92H2.71zm17.77 27.56a4.21 4.21 0 110-8.42 4.21 4.21 0 010 8.42zm15.52 0a4.21 4.21 0 110-8.42 4.21 4.21 0 010 8.42zm15.52 0a4.21 4.21 0 110-8.42 4.21 4.21 0 010 8.42z"
      fill={color}
    />
  </svg>
);

export default betweenCity;
