import { Box, Link, Typography } from '@material-ui/core';
import moment from 'moment';
import classNames from 'classnames';
import { useMemo } from 'react';
import Gallery from 'sharedComponents/Gallery/Gallery';
import { Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { IMessage } from 'interfaces';
import { imagesSrcFormatter } from 'utils/formatters';
import useStyles from './styles';

interface Props extends Omit<IMessage, '_id'> {
  toMe: boolean;
}

const ChatMessage = ({
  toMe,
  createdAt,
  messageData,
  messageFiles,
  isRead,
  isError,
}: Props) => {
  const classes = useStyles();
  const time = moment(createdAt).format('HH:mm');
  const indicatorClassSide = toMe ? 'indicatorRightMsg' : 'indicatorLeftMsg';
  const timeClassSide = toMe ? 'timeRightMsg' : 'timeLeftMsg';

  const formattedImages = useMemo(() => (
    imagesSrcFormatter(messageFiles)
  ), [messageFiles]);

  return (
    <Box className={toMe ? classes.rightMsg : classes.leftMsg}>
      <Box
        className={classes.message}
      >
        {Boolean(messageFiles && messageFiles.length) && (
          <Gallery
            images={formattedImages}
            acceptDownload
          />
        )}

        <Box className={classes.messageText}>
          <Trans
            components={{
              italic: <i />,
              bold: <strong />,
              br: <br />,
              linkToProfile: <Link component={RouterLink} to="/profile" />,
            }}
          >
            {messageData}
          </Trans>
        </Box>

        <Typography className={classNames(classes.messageTime, classes[timeClassSide])}>
          {time}
        </Typography>

        <Box className={classNames(classes.messageIndicator, classes[indicatorClassSide])}>
          <span className={classNames(classes.burger, {
            isRead: toMe ? isRead : true,
            isError,
          })}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ChatMessage;
