import React from 'react';
import classNames from 'classnames';
import PhoneInput, { Country } from 'react-phone-number-input';
import './PhoneInput.css';

interface Props {
  onChange: (value: string) => void;
  onBlur: () => void;
  onFocus: () => void;
  value: string;
  readOnly?: boolean;
  country?: Country;
  className?: string;
  errorMessage?: string;
}

const PLACEHOLDER = '+39 XX XXX XXXX';

const PhoneInputWrapper: React.FC<Props> = ({
  onChange,
  onBlur,
  onFocus,
  readOnly,
  className,
  value,
  errorMessage,
  country,
}: Props) => {
  const handleChange = (newValue: string) => {
    onChange(newValue ?? '');
  };

  return (
    <>
      <PhoneInput
        disabled={readOnly}
        defaultCountry={country}
        placeholder={PLACEHOLDER}
        className={classNames('input', { error: errorMessage }, className)}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={handleChange}
        autoComplete='new-password'
      />
      <span className="errorMessage">{errorMessage}</span>
    </>
  );
};

export default PhoneInputWrapper;

PhoneInputWrapper.defaultProps = {
  className: '',
  country: undefined,
  readOnly: false,
  errorMessage: '',
};
