import { Controller, UseFormMethods } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { FormControl, FormHelperText } from '@material-ui/core';
import classNames from 'classnames';
import EmailInput from 'components/EmailInput/EmailInput';
import PhoneInputWrapper from 'components/PhoneInput/PhoneInputWrapper';
import { getTranslateFunction } from 'helpers';
import { emailRegExp } from '../../../constants';
import VerificationInputStatus from './VerificationInputStatus';
import { GetIsVerifiedInput } from '../containers/UserInfo';
import useStyles from '../ProfilePageStyle';

type Fields = {
  phone: string;
  email: string;
};

type UseFormMethodsProps = Pick<UseFormMethods<Fields>, 'control' | 'errors' | 'clearErrors'>;
type GetIsVerifiedInputProps = { getIsVerifiedInput: GetIsVerifiedInput };

type Props = UseFormMethodsProps & GetIsVerifiedInputProps;

const ContactInputs = ({ control, errors, clearErrors, getIsVerifiedInput }: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  return (
    <>
      {/* Phone */}
      <VerificationInputStatus isVerified={getIsVerifiedInput('phone')}>
        <Controller
          control={control}
          name='phone'
          render={({ value, onChange, onBlur }) => (
            <FormControl className={classes.formControl} error={!!errors.phone}>
              <PhoneInputWrapper
                className={classNames({ error: errors.phone })}
                value={value}
                onBlur={onBlur}
                errorMessage={errors.phone?.message}
                onChange={onChange}
                onFocus={() => clearErrors('phone')}
                readOnly={getIsVerifiedInput('phone')}
              />
              <FormHelperText className='MuiFormHelperText-root Mui-error'>{t('profilePage.addNumber')}</FormHelperText>
            </FormControl>
          )}
          rules={{
            required: {
              value: true,
              message: 'error.validation.required',
            },
            minLength: {
              value: 9,
              message: 'error.WrongNumberFormat',
            },
            maxLength: {
              value: 15,
              message: 'error.WrongNumberFormat',
            },
            validate: (value) => isValidPhoneNumber(value) || 'error.validation.phoneNumberInvalid',
          }}
        />
      </VerificationInputStatus>

      {/* Email */}
      <VerificationInputStatus isVerified={getIsVerifiedInput('email')}>
        <Controller
          control={control}
          name='email'
          render={({ onChange, value, onBlur }) => (
            <FormControl className={classes.formControl} error={!!errors.email}>
              <EmailInput
                className={classNames({ error: errors.email })}
                onChange={onChange}
                value={value}
                onFocus={() => clearErrors('email')}
                onBlur={onBlur}
                readOnly={getIsVerifiedInput('email')}
              />
              {errors.email?.message && (
                <FormHelperText id='component-error-text' className={classes.errorMessage}>
                  {errors.email.message}
                </FormHelperText>
              )}
            </FormControl>
          )}
          rules={{
            required: {
              value: true,
              message: 'error.validation.required',
            },
            pattern: {
              value: emailRegExp,
              message: 'error.EmailInvalid',
            },
          }}
        />
      </VerificationInputStatus>
    </>
  );
};

export default ContactInputs;
