import React, { useCallback } from 'react';
import {
  Avatar,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import Plus from 'images/plus';
import ArrowRight from 'images/ArrowRight';
import moment from 'moment';
import { IChat, IMessage, IOnChange } from 'interfaces';
import chatReplyIcon from 'components/common/chatReply.png';
import supportIcon from 'components/common/supportIcon.png';
import { getTranslateFunction } from 'helpers';
import { useAuth } from 'services/auth';
import ChatListItem from './ChatListItem';
import useStyles from '../ChatStyle';
import CreateChatModal from './CreateChatModal';
import { backgroundColor, green, red } from '../../../constants';
import UserInfoBlock from './UserInfoBlock';

interface Props {
  chats: IChat[];
  createChatModal: boolean;
  supportLastMessage: IMessage | null;
  replyChatLastMessage: IMessage | null;
  onChange: (a: IOnChange) => void;
}

const ChatPageComponent: React.FC<Props> = ({
  chats,
  onChange,
  supportLastMessage,
  replyChatLastMessage,
  createChatModal,
}: Props) => {
  const { user } = useAuth();
  const classes = useStyles();
  const t = getTranslateFunction();

  const handleOpenCreateChatModal = () => {
    onChange({
      action: 'setCreateChatModal',
      data: true,
    });
  };

  const supportArrowColor = supportLastMessage?.toUser === user?.uuid && !supportLastMessage?.isRead
    ? red : green;

  const replyChatArrowColor = replyChatLastMessage?.toUser === user?.uuid
    && !replyChatLastMessage?.isRead
    ? red : green;

  const goToSupportChat = useCallback(() => {
    onChange({ action: 'goToPage', data: '/chat/support' });
  }, []);

  const goToReplyChat = useCallback(() => {
    onChange({ action: 'goToPage', data: '/chat/reply' });
  }, []);

  return (
    <>
      <Box>
        <Box className={classes.chatsListItem}>
          <Box className={classes.chatsListItemInfo}>
            <Avatar variant="square" className={classes.listUserPhoto} src={chatReplyIcon} />
            <Box className={classes.chatInfoWrapper}>
              <Typography className={classes.replyChatLabel}>
                <Typography variant="caption" className={classes.replyChatPPLabel}>{t('chatPage.replyChat.PP')}</Typography>
                <Typography variant="caption" className={classes.replyChatWWLabel}>{t('chatPage.replyChat.WW')}</Typography>
              </Typography>

              <Typography className={classes.chatsListItemMessage}>
                {t('chatPage.replyChat.title')}
              </Typography>
              {supportLastMessage && (
                <Typography>
                  {moment(supportLastMessage.createdAt).format('DD.MM.YYYY')}
                </Typography>
              )}
            </Box>
          </Box>
          <Box onClick={goToReplyChat}>
            <ArrowRight height={40} width={40} color={replyChatArrowColor} />
          </Box>
        </Box>

        <Box className={classes.chatsListItem}>
          <UserInfoBlock
            imgUrl={supportIcon}
            firstName={t('Andrea')}
            lastName={t('Rosso')}
            title={t('chatPage.support.label')}
            messageTime={supportLastMessage?.createdAt}
            onChange={onChange}
          />
          <Box onClick={goToSupportChat}>
            <ArrowRight height={40} width={40} color={supportArrowColor} />
          </Box>
        </Box>

        {chats.map(({
          lastMessage, orderType, user: chatUser, roomId,
        }) => (
          <ChatListItem
            key={roomId}
            onChange={onChange}
            lastMessage={lastMessage}
            orderType={orderType}
            userPhoto={chatUser.userPhoto}
            gender={chatUser.gender}
            firstName={chatUser.firstName}
            lastName={chatUser.lastName}
            uuid={chatUser.uuid}
          />
        ))}
      </Box>

      <Button className={classes.createChatBtn} onClick={handleOpenCreateChatModal}>
        <Plus color={backgroundColor} width={50} height={50} />
      </Button>
      <CreateChatModal isOpen={createChatModal} onChange={onChange} />
    </>
  );
};

export default ChatPageComponent;
