import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Box, Avatar, Typography } from '@material-ui/core';
import { Star } from '@material-ui/icons';
import classNames from 'classnames';
import LocationList from 'components/LocationList';
import { dateFormatter } from 'utils/formatters';
import userPhoto from 'components/common/userLogo.png';
import Icons from 'components/Icons';
import { getUserImageLink, getTranslateFunction, getCurrencyByCountry } from 'helpers';
import {
  ExtentedCurrencyPreorder,
  Preorder,
  RatingType,
  User,
} from 'interfaces';
import { useAuth } from 'services/auth';
import { convertCurrencies } from 'services/commonService';
import {
  kilometres,
  cyan,
  SINGLE_TYPES,
} from '../../../constants';
import useStyles from '../styles';

const {
  fromSvg,
  toSvg,
  CommentTsx,
} = Icons;

interface Props {
  item: Preorder;
  distanceToYou: number | null;
  distance: number;
  clientUser: User;
  clientRating: RatingType;
  onChange: (a: ExtentedCurrencyPreorder) => void;
}

const PreorderContainer: React.FC<Props> = ({
  item: preorderItem,
  distanceToYou,
  distance,
  onChange,
  clientRating,
  clientUser,
}: Props) => {
  const { user: userme } = useAuth();
  const classes = useStyles();
  const t = getTranslateFunction();
  const [preorderPrice, setPreorderPrice] = useState(preorderItem.price);
  const [preorderCurrency, setPreorderCurrency] = useState(preorderItem.currency);

  const item = useMemo(() => ({
    ...preorderItem,
    price: preorderPrice,
    currency: preorderCurrency,
  }), [preorderItem, preorderPrice, preorderCurrency]);

  useEffect(() => {
    const handleGetPreorderPrice = async () => {
      const toCurrency = getCurrencyByCountry(userme?.country ?? '');
      if (toCurrency === preorderItem.currency) {
        setPreorderPrice(preorderItem.price);
        setPreorderCurrency(preorderItem.currency);
        return;
      }

      const price = await convertCurrencies({
        fromCurrency: preorderItem.currency,
        toCurrency,
        type: 'buy',
        amount: preorderItem.price,
      });

      setPreorderPrice(Number(price));
      setPreorderCurrency(toCurrency);
    };

    handleGetPreorderPrice();
  }, [preorderItem, userme]);

  const handleItem = useCallback(() => {
    onChange({...item, initialCurrency: preorderItem.currency});
  }, [item]);

  const photoSrc = useMemo(() => (
    getUserImageLink(clientUser?.userPhoto, clientUser?.gender)
  ), [clientUser]);

  const isSingleTypePreorder = SINGLE_TYPES.some((type) => type === item.type);
  const tripTimeIcon = item.tripTime === 'now' ? fromSvg : toSvg;
  const tripTimeText = item.tripTime === 'now' ? t('preorder.modal.nowLabel') : dateFormatter(Number(new Date(item.tripTime)));

  if (!clientUser) return null;

  return (
    <Box className={classes.offerInfo} onClick={handleItem}>
      <Box className={classes.userContainer}>
        <Avatar
          className={classes.userImg}
          title={clientUser.firstName}
          src={photoSrc || userPhoto}
          variant="rounded"
        />
        <Typography>{clientUser.firstName}</Typography>
        <Box className={classes.stats}>
          <Box className={classes.listItem}>
            <img className={classes.svg} src={Icons[`${item.paymentType}Svg`]} alt={item.paymentType} />
            <Typography>{`${item.price} ${item.currency}`}</Typography>
          </Box>
          <Box className={classes.listItem}>
            <img className={classes.svg} src={tripTimeIcon} alt={tripTimeText} />
            <Typography>{tripTimeText}</Typography>
          </Box>
          {clientRating && (
          <Box className={classes.listItem}>
            <Star className={classNames(classes.svg, 'star')} />
            <Typography>{Math.floor(Number(clientRating.politenessRatingAvg))}</Typography>
          </Box>
          )}
        </Box>
      </Box>
      <Box className={classes.proposal}>
        {item.preorderType && (
          <Box className={classes.preorderOption}>
            <Box className={classes.listItem}>
              <Typography>{t(`preorder.modal.${item.preorderType}`)}</Typography>
            </Box>
          </Box>
        )}
        {item.preorderName && (
        <Box className={classes.preorderOption}>
          <Box className={classes.listItem}>
            <Typography>{item.preorderName}</Typography>
          </Box>
        </Box>
        )}
        {item.vehicleBrandOfBuy && (
        <Box className={classes.preorderOption}>
          <Box className={classes.listItem}>
            <Typography>{item.vehicleBrandOfBuy.label}</Typography>
          </Box>
        </Box>
        )}
        {item.vehicleModelOfBuy && (
        <Box className={classes.preorderOption}>
          <Box className={classes.listItem}>
            <Typography>{item.vehicleModelOfBuy.label}</Typography>
          </Box>
        </Box>
        )}
        {item.releaseDateFromOfBuy
          && item.releaseDateToOfBuy && (
            <Box className={classes.preorderOption}>
              <Box className={classes.listItem}>
                <Typography>{`${item.releaseDateFromOfBuy.label} | ${item.releaseDateToOfBuy.label}`}</Typography>
              </Box>
            </Box>
        )}
        {item.transmissionOfBuy && (
        <Box className={classes.preorderOption}>
          <Box className={classes.listItem}>
            <Typography>{item.transmissionOfBuy.label}</Typography>
          </Box>
        </Box>
        )}
        {item.buyerCity && (
        <Box className={classes.preorderOption}>
          <Box className={classes.listItem}>
            <Typography>{`${t('preorder.modal.from')} ${item.buyerCity}`}</Typography>
          </Box>
        </Box>
        )}
        {item.together && (
        <Box className={classes.preorderOption}>
          <Box className={classes.listItem}>
            <img
              className={classes.svg}
              src={Icons[`${item.together}Svg`]}
              alt={item.together}
            />
            <Typography variant="caption">{t(`preorder.modal.${item.together}Label`)}</Typography>
          </Box>
        </Box>
        )}
        {item.passengers && (
        <Box className={classes.preorderOption}>
          <Box className={classes.listItem}>
            <img
              className={classes.svg}
              src={Icons.individualSvg}
              alt="passengers"
            />
            <Typography variant="caption">{t('preorder.modal.passengers')}</Typography>
            <Box className={classes.endAdornment}>{item.passengers}</Box>
          </Box>
        </Box>
        )}
        {item.tripRequirements && (
        <Box className={classes.preorderOption}>
          <Box className={classes.listItem}>
            <img
              className={classes.svg}
              src={Icons.individualSvg}
              alt="tripRequirements"
            />
            <Typography variant="caption">{item.tripRequirements}</Typography>
          </Box>
        </Box>
        )}
        {!isSingleTypePreorder && (
        <>
          <LocationList
            dots={item.dots}
            classes={{
              dotBox: classes.listItem,
              svg: classes.svg,
            }}
            withIcon
          />
          <Box className={classes.preorderOption}>
            <Box className={classes.listItem}>
              <img
                className={classes.svg}
                src={fromSvg}
                alt="from"
              />
              <img
                className={classes.svg}
                src={toSvg}
                alt="to"
              />
              <Typography variant="caption">{`${distance} ${t(kilometres)}`}</Typography>
            </Box>
          </Box>
        </>
        )}
        {typeof distanceToYou === 'number' && (
        <Box className={classes.preorderOption}>
          <Box className={classes.listItem}>
            <img
              className={classes.svg}
              src={fromSvg}
              alt="distance"
            />
            <Typography variant="caption">{`${distanceToYou} ${t(kilometres)}`}</Typography>
          </Box>
        </Box>
        )}
        {item.comment && (
        <Box className={classes.preorderOption}>
          <Box className={classes.listItem}>
            {!isSingleTypePreorder && (
            <Box
              className={classes.svg}
            >
              <CommentTsx color={cyan} />
            </Box>
            )}
            <Typography variant="caption">{item.comment}</Typography>
          </Box>
        </Box>
        )}
      </Box>
    </Box>
  );
};

export default PreorderContainer;
