import { Box, Typography } from '@material-ui/core';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import classNames from 'classnames';
import { ArrowBackIos } from '@material-ui/icons';
import { getTranslateFunction } from 'helpers';
import {
  IOnChange,
  PreorderSubType,
  Preorder,
} from 'interfaces';
import Icons from 'components/Icons';
import { useAuth } from 'services/auth';
import { buttons } from 'utils/optionsConfig';
import { hyphensToCamelCase } from 'utils/formatters';
import useStyles from '../styles';
import PreorderListItem from '../components/PreorderListItem';

interface Props {
  preorders: {
    [key: string]: Preorder[];
  };
  distances: {
    id: number;
    distance: number;
  }[];
  currentType: string;
  singleType: boolean;
  canceledPreorders: number[];
  onChange: (a: IOnChange) => void;
}

const icons: {
  [key: string]: string;
} = {
  'help-on-road-engine-start': Icons.engineStartHelp,
  'help-on-road-fuel-delivery': Icons.fuelDeliveryHelp,
  'help-on-road-towing': Icons.towingHelp,
  'help-on-road-tow-track': Icons.towTruckHelp,
  'help-on-road-on-site-repair': Icons.onSiteRepair,
  'help-on-road-another': Icons.anotherHelp,

  'buy-transport': Icons.transportBuy,
  'buy-property': Icons.propertyBuy,
  'buy-appliances': Icons.appliancesBuy,
  'buy-electrical-engineering': Icons.electricalEngineering,
  'buy-sport-goods': Icons.sportGoodsBuy,
  'buy-furniture': Icons.furnitureBuy,
  'buy-clothing': Icons.clothingBuy,
  'buy-medicines': Icons.medicinesBuy,
  'buy-spare-parts': Icons.sparePartsBuy,
  'buy-another': Icons.anotherBuy,

  'rent-car': Icons.carRental,
  'rent-clothing': Icons.clothingRental,
  'rent-property': Icons.propertyRental,
  'rent-sportGoods': Icons.sportGoodsRental,
  'rent-electricalEngineering': Icons.electricalEngineeringRent,
  'rent-another': Icons.otherRental,

  'services-car-repair': Icons.carRepairService,
  'services-web-services': Icons.webServicesService,
  'services-laptop-and-computers': Icons.laptopAndComputersService,
  'services-phones-and-tablets': Icons.phonesAndTablesService,
  'services-photo-video-technics': Icons.photoVideoTechnicsService,
  'services-appliances': Icons.appliancesService,
  'services-electrician': Icons.electricianService,
  'services-carpenter': Icons.woodWorkerService,
  'services-plumber': Icons.plumberService,
  'services-construction-and-repair': Icons.constructionAndRepairService,
  'services-cleaningService': Icons.cleaningService,
  'services-home-master': Icons.woodWorkerService,
  'services-seamstress': Icons.seamstressService,
  'services-handymen': Icons.handymenService,
  'services-medicines': Icons.medicinesService,
  'services-another': Icons.otherService,
};

const MonoTypePreordersList: React.FC<Props> = ({
  distances,
  preorders,
  currentType,
  singleType,
  onChange,
  canceledPreorders,
}: Props) => {
  const t = getTranslateFunction();
  const classes = useStyles();
  const [currentLocalType, setCurrentLocalType] = useState('');
  const { user } = useAuth();

  const allTypes = buttons.filter(({ service }) => service === currentType);

  const preordersMemoized: {
    [key: string]: Preorder[];
  } = useMemo(() => {
    const typeCamelCase = hyphensToCamelCase(currentType) as PreorderSubType;
    const typePreorders = preorders[typeCamelCase] ?? [];

    const activePreorders = typePreorders.filter((item) => (
      !canceledPreorders.includes(item.preorderId)
    ));

    const result = allTypes.reduce((acc, { contentKey }) => ({
      ...acc,
      [contentKey]: activePreorders.filter((item) => item[typeCamelCase] === contentKey),
    }), {});

    return result;
  }, [preorders, currentType, singleType, canceledPreorders, allTypes]);

  const handleType = useCallback((type) => {
    setCurrentLocalType(type);
  }, []);

  useEffect(() => {
    if (!user) return;
    const allPreorders = Object.values(preordersMemoized).flat();

    allPreorders.forEach((preorder) => {
      const providedCities = preorder.providedCities?.map(({label}: {label: string}) => label);

      if ((providedCities ?? []).length && !providedCities.includes(user.city)) {
        onChange({
          action: 'addedCanceledPreorder',
          data: preorder.preorderId,
        });
      }
    });
  }, [preordersMemoized, user]);

  const handleBack = useCallback(() => {
    setCurrentLocalType('');
  }, []);

  const subTypePreorders = useMemo(() => {
    if (!currentLocalType || !Object.keys(preordersMemoized).length) return [];

    return preordersMemoized[currentLocalType].map((item: Preorder) => {
      const distanceTo = distances.find(({ id }: { id: number}) => item.preorderId === id);
      const distance = Math.floor(item.distance) / 1000;
      const distanceToYou = distanceTo ? Math.floor(distanceTo.distance) / 1000 : null;

      return (
        <PreorderListItem
          key={item.key}
          onChange={onChange}
          distance={distance}
          distanceToYou={distanceToYou}
          item={item}
        />
      );
    }).filter(Boolean);
  }, [preordersMemoized, currentLocalType]);

  return (
    <>
      <Box className={classNames(classes.typeContainerTitle, classes.typeContainer)}>
        {currentLocalType && (
          <Box onClick={handleBack} className={classes.arrowBack}>
            <ArrowBackIos />
          </Box>
        )}
        <Typography>{`${t(currentType)} ${currentLocalType && ` - ${t(currentLocalType)}`}`}</Typography>
      </Box>
      {currentLocalType
        ? subTypePreorders
        : Object.entries(preordersMemoized)
          .map(([key, items]) => (
            <Box className={classes.typeContainer} onClick={() => handleType(key)} key={key}>
              <img className={classes.typeSvg} src={icons[key]} alt={t(key)} />
              <Typography>{t(key)}</Typography>
              <Typography>{items.length}</Typography>
            </Box>
          ))}
    </>
  );
};

export default MonoTypePreordersList;
