import React from 'react';
import { Box } from '@material-ui/core';
import { VendorHomeServices } from 'interfaces';
import { indexStyles } from '../VendorHomePageStyle';
import ServiceListItem from './ServiceListItem';

interface Props {
  onChange: (a: string) => void;
  services: VendorHomeServices;
}

const VendorHomePage: React.FC<Props> = ({
  services,
  onChange,
}: Props) => {
  const classes = indexStyles();

  return (
    <Box className={classes.serviceContainer}>
      {Boolean(services)
        && Object.entries(services).map(([key, value]: [string, number], index: number) => (
          <ServiceListItem key={key} item={{ key, value }} index={index} onClick={onChange} />
        ))}
    </Box>
  );
};

export default VendorHomePage;
