import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getTranslateFunction } from 'helpers';
import WebwayLogo from 'components/common/WebwayLogo.png';
import { indexStyles } from '../material';

const Header: React.FC = () => {
  const classes = indexStyles();
  const t = getTranslateFunction();

  return (
    <Box className={classes.header}>
      <Box className={classes.logoContent}>
        <Link to="/signin" className={classes.logoContainer}>
          <img className={classes.logo} alt={t('webway.logo')} src={WebwayLogo} />
        </Link>
        <Typography className={classes.headerTitle} variant="h4">{t('signInPage.link.register')}</Typography>
      </Box>
    </Box>
  );
};

export default Header;
