import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  UseFormMethods,
} from 'react-hook-form';
import ConstructorForm from 'components/ConstructorForm/ConstructorForm';
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import Preloader from 'components/Preloader/Preloader';
import {
  CFInputItem,
  CFItem,
  IOnChange,
  User,
} from 'interfaces';
import { ModelItem } from 'components/ConstructorForm/items/CarCombobox/useBrands';
import { getTranslateFunction } from 'helpers';
import useWindowSize from 'services/useWindowSize';
import Icons from 'components/Icons';
import formConfig from '../preorderFormConfig';
import useStyles from '../PreorderPageStyle';
import { SET_IS_LOADING } from '../../../constants';

type IStringTypes = Record<string, string>;
type IArrayTypes = Record<string, CFItem[]>;

interface IPreorderArrayType {
  key: string;
  preorderType?: string;
  types: IArrayTypes;
  startArray: CFItem[];
}

export type FieldValues = {
  price: string;
  comment: string;
  hasBabyChair: boolean;
  passengers: number;
  together: string;
  tripTime: string;
  tripTimeDate?: string;
  findInAllCities: boolean;
  providedCities: string[];
};

interface Props extends Pick<UseFormMethods, 'register' | 'errors' | 'getValues' | 'setValue' | 'control' | 'clearErrors'> {
  onChange: (a: IOnChange) => void;
  brands: string[];
  models: ModelItem[];
  user: User;
  location: {
    search: string;
    pathname: string;
  };
  selectOnMap: boolean;
  pointControlsList: React.ReactNode[];
  activePoint: number | null;
  addPoint: () => void;
  disabled: boolean;
}

const {
  makePriceSvg,
} = Icons;

const TYPES = ['inCity', 'betweenCity', 'international', 'helpOnRoad', 'buyThings', 'rent', 'services'];

const PreorderPageForm: React.FC<Props> = ({
  register,
  selectOnMap,
  control,
  pointControlsList,
  activePoint,
  addPoint,
  setValue,
  disabled,
  getValues,
  errors,
  user,
  location,
  onChange,
  clearErrors,
  brands,
  models,
}) => {
  const { height } = useWindowSize();
  const t = getTranslateFunction();
  const classes = useStyles({ selectOnMap, height });
  const [currentType, setCurrentType] = useState('');
  const [path, setPath] = useState<string | null>('');
  const [formItems, setFormItems] = useState<CFItem[]>([]);
  const {
    alongTheWay,
    freightTransport,
    temporaryDriver,
    animalTransport,
    anotherTransport,
    courier,
    helpOnRoad,
    buyStartArray,
    buyProperty,
    buyTransport,
    buySpareParts,
    buyElectrical,
    buyOther,
    rentOther,
    servicesMainArray,
    serviceRepairAuto,
    currency,
  } = formConfig({user});

  const mainStartArray: CFItem[] = useMemo(() => [
    {
      type: 'radio',
      name: 'tripTime',
      items: [
        {
          label: t('preorder.modal.nowLabel'),
          value: 'now',
        },
        {
          label: t('preorder.modal.toTimeLabel'),
          value: 'toTime',
        },
      ],
    },
    {
      type: 'datePicker',
      name: 'tripTimeDate',
    },
    {
      type: 'placePicker',
      items: pointControlsList,
      activePoint,
      addPoint,
    },
    {
      type: 'text',
      inputType: 'number',
      name: 'price',
      placeholder: t('preorder.modal.priceLabel'),
      InputProps: {
        disableUnderline: true,
        startAdornment: (
          <img className={classes.svg} src={makePriceSvg} alt="price" />
        ),
        endAdornment: (
          <Typography className={classes.endAdornPrice}>{currency}</Typography>
        ),
        inputProps: {
          inputMode: 'numeric',
        },
      },
    },
    {
      type: 'radio',
      withoutBorder: true,
      title: t('preorder.modal.paymentType'),
      name: 'paymentType',
      items: [
        {
          label: t('preorder.modal.cash'),
          value: 'cash',
        },
        {
          label: t('preorder.modal.toCard'),
          value: 'toCard',
        },
        {
          label: t('preorder.modal.internalTransfer'),
          value: 'internalTransfer',
        },
      ],
    },
  ], [t, pointControlsList, activePoint]);

  useEffect(() => {
    const withBrandSelect = formItems.some((item) => item.type === 'carCombobox');
    if (!withBrandSelect) return;

    const isBrandsLoaded = brands?.length;

    onChange({
      action: SET_IS_LOADING,
      data: !isBrandsLoaded,
    });
  }, [formItems, brands]);

  useEffect(() => {
    setPath(new URLSearchParams(location.search).get(currentType));
  }, [currentType, location]);

  useEffect(() => {
    TYPES.forEach((type) => {
      const condition = location.search.indexOf(type) >= 0;
      if (condition) {
        setCurrentType(type);
      }
    });
  }, [location]);

  const handleSetFormItems = useCallback(() => {
    if (!path) return;

    const alongTheWayTypes: IArrayTypes = {
      'along-the-way': alongTheWay,
      'women-driver': alongTheWay,
      'freight-transport': freightTransport,
      'temporary-driver': temporaryDriver,
      'animal-transport': animalTransport,
      'another-transport': anotherTransport,
    };

    const buyTypes: IArrayTypes = {
      'spare-parts': buySpareParts,
      'electrical-engineering': buyElectrical,
      'sport-goods': buyOther,
      transport: buyTransport,
      property: buyProperty,
      appliances: buyOther,
      furniture: buyOther,
      clothing: buyOther,
      medicines: buyOther,
      another: buyOther,
    };

    const rentTypes: IArrayTypes = {
      car: buyTransport,
      property: buyProperty,
      clothing: rentOther,
      sportGoods: rentOther,
      electricalEngineering: rentOther,
      another: rentOther,
    };

    const serviceTypes: IArrayTypes = {
      'car-repair': serviceRepairAuto,
      'web-services': servicesMainArray,
      'laptop-and-computers': servicesMainArray,
      'phones-and-tablets': servicesMainArray,
      'photo-video-technics': servicesMainArray,
      'home-master': servicesMainArray,
      'construction-and-repair': servicesMainArray,
      medicines: servicesMainArray,
      appliances: servicesMainArray,
      electrician: servicesMainArray,
      carpenter: servicesMainArray,
      plumber: servicesMainArray,
      cleaningService: servicesMainArray,
      seamstress: servicesMainArray,
      handymen: servicesMainArray,
      another: servicesMainArray,
    };

    const courierTypes: IStringTypes = {
      'food-delivery': t('HomePage.courier.FoodDelivery'),
      'products-delivery': t('HomePage.courier.ProductsDelivery'),
      'flowers-delivery': t('HomePage.courier.FlowersDelivery'),
      'medicines-delivery': t('HomePage.courier.MedicinesDelivery'),
      'documents-delivery': t('HomePage.courier.DocumentsDelivery'),
      another: t('HomePage.courier.Another'),
    };

    const helpOnRoadTypes: IStringTypes = {
      'fuel-delivery': t('HomePage.helpOnRoad.FuelDelivery'),
      'on-site-repair': t('HomePage.helpOnRoad.OnSiteRepair'),
      'engine-start': t('HomePage.helpOnRoad.EngineStart'),
      'tow-track': t('HomePage.helpOnRoad.TowTrack'),
      towing: t('HomePage.helpOnRoad.Towing'),
      another: t('HomePage.helpOnRoad.Another'),
    };

    const preorderArraysTypes: {
      key: string;
      types: IArrayTypes;
      startArray: CFItem[];
    }[] = [
      {
        key: 'in-city',
        types: alongTheWayTypes,
        startArray: mainStartArray,
      },
      {
        key: 'between-city',
        types: alongTheWayTypes,
        startArray: mainStartArray,
      },
      {
        key: 'international',
        types: alongTheWayTypes,
        startArray: mainStartArray,
      },
      {
        key: 'buy',
        types: buyTypes,
        startArray: buyStartArray,
      },
      {
        key: 'rent',
        types: rentTypes,
        startArray: buyStartArray,
      },
      {
        key: 'services',
        types: serviceTypes,
        startArray: buyStartArray,
      },
    ];

    const preorderStringsTypes: Record<'helpOnRoad' | 'courier', {
      types: IStringTypes;
      objects: CFInputItem[];
      startArray: CFItem[];
    }> = {
      helpOnRoad: {
        types: helpOnRoadTypes,
        objects: helpOnRoad,
        startArray: mainStartArray,
      },
      courier: {
        types: courierTypes,
        objects: courier,
        startArray: mainStartArray,
      },
    };

    const [type] = location.pathname.split('/').reverse() as ['helpOnRoad' | 'courier'];
    const preorderArrayType:
    IPreorderArrayType | undefined = preorderArraysTypes.find(({key}: {key: string}) => (
      path.includes(key)
    ));
    if (preorderArrayType && type !== 'courier') {
      const {
        types,
        startArray,
      } : IPreorderArrayType = preorderArrayType;

      const [preorderSubType, subArray] = Object.entries(types).find(([key]) => (
        path.includes(key)
      )) as [string, CFItem[]];

      if (preorderSubType) {
        setFormItems(subArray.concat(startArray));
        return;
      }
    }

    if (!type) return;

    const {
      types: stringTypes,
      objects,
      startArray: stringsStartArray,
    } = preorderStringsTypes[type];

    const configuredArray: CFItem[] = objects.map((item) => {
      if (item.name !== 'tripRequirements') return item;

      const [, value] = Object.entries(stringTypes).find(([key]) => (
        path.includes(key)
      )) as string[];

      return {
        ...item,
        value,
      };
    });

    setFormItems(configuredArray.concat(stringsStartArray));
  }, [path, location, pointControlsList]);

  useEffect(() => {
    if (!path) return;

    handleSetFormItems();
  }, [path, pointControlsList]);

  if (!formItems.length) {
    return <Preloader />;
  }

  return (
    <Box className={classes.mapOptionContainer}>
      <ConstructorForm
        clearErrors={clearErrors}
        onChange={onChange}
        brands={brands}
        models={models}
        errors={errors}
        setValue={setValue}
        register={register}
        control={control}
        optionsList={formItems}
        getValues={getValues}
      />

      <Button
        variant="contained"
        data-order-btn="submit"
        className={classes.button}
        disabled={disabled}
        type="submit"
      >
        {t('preorderPage.button.order')}
      </Button>
    </Box>
  );
};

export default PreorderPageForm;
