import React, { useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import VendorMap from 'pages/VendorOffers/VendorMap';
import useWindowSize from 'services/useWindowSize';
import { getTranslateFunction } from 'helpers';
import {
  IComment,
  IOnChange,
  IOrder,
  RatingType,
  User,
} from 'interfaces';
import OrderBuyInfoContainer from '../containers/OrderBuyInfoContainer';
import OrderInfoContainer from '../containers/OrderInfoContainer';
import {
  ORDER_STATUSES,
  vendorFirstStep,
  vendorSecondStep,
} from '../../../constants';
import useStyles from '../OrderPageStyle';
import OrderModals from './OrderModals';
import OrderControlButtons from './OrderControlButtons';

interface Props {
  isSingleTypeOrder: boolean;
  client: User;
  order: IOrder;
  clientComments: IComment[];
  ratingModal: boolean;
  clientRating: RatingType;
  status: number;
  modalUserInfo: boolean;
  cancelModalOrder: boolean;
  modalOrderInfo: boolean;
  myLocation: [number, number] | null;
  onChange: (a: IOnChange) => void;
}

const VendorOrderComponent: React.FC<Props> = ({
  order,
  status,
  modalUserInfo,
  isSingleTypeOrder,
  client,
  clientComments,
  clientRating,
  ratingModal,
  cancelModalOrder,
  modalOrderInfo,
  myLocation,
  onChange,
}: Props) => {
  const { height } = useWindowSize();
  const classes = useStyles(height);
  const t = getTranslateFunction();

  const handleChange = (action: string, data?: any) => {
    onChange({ action, data });
  };

  const suggestionsCancel = useMemo(() => (
    status === ORDER_STATUSES.EXITING ? vendorSecondStep : vendorFirstStep
  ), [status, ORDER_STATUSES]);

  const OrderInfoComponent = isSingleTypeOrder
    ? OrderBuyInfoContainer
    : OrderInfoContainer;
  if (!client) return null;

  return (
    <>
      {!ratingModal && (
        <Box className={classes.offerLayout}>
          {!isSingleTypeOrder && (
            <Box className={classes.mapContainer}>
              <VendorMap items={[order]} />
            </Box>
          )}
          <Box className={classes.preorderContainer}>
            <Typography>{`${client.firstName} ${t('OrderPage.waitingYou')}`}</Typography>
            <Box className={classes.orderInfo}>
              <Box onClick={() => handleChange('handleOrderInfoModal')} className={classes.orderInfoBtn}>{t('OrderPage.orderInfo')}</Box>

              {Boolean(clientComments.length) && (
                <Box onClick={() => handleChange('handleUserInfoModal')} className={classes.orderInfoBtn}>{t('OrderPage.UserInfo')}</Box>
              )}
            </Box>
            <OrderInfoComponent
              order={order}
              userComments={clientComments}
              userRating={clientRating}
            />
            <OrderControlButtons
              client={client}
              status={status}
              order={order}
              handleChange={handleChange}
              isSingleTypeOrder={isSingleTypeOrder}
              myLocation={myLocation}
            />
          </Box>
        </Box>
      )}
      <OrderModals
        cancelModalOrder={cancelModalOrder}
        suggestionsCancel={suggestionsCancel}
        handleChange={handleChange}
        modalUserInfo={modalUserInfo}
        clientComments={clientComments}
        client={client}
        clientRating={clientRating}
        order={order}
        isSingleTypeOrder={isSingleTypeOrder}
        modalOrderInfo={modalOrderInfo}
        ratingModal={ratingModal}
      />
    </>
  );
};

export default VendorOrderComponent;
