import React from 'react';
import { Checkbox as CheckboxMUI, CheckboxProps as CheckboxPropsMUI } from '@material-ui/core';
import classNames from 'classnames';

import useStyles from './styles';

export interface CheckboxProps extends CheckboxPropsMUI {
  error?: boolean;
}

const Checkbox = ({ error, ...rest }: CheckboxProps) => {
  const classes = useStyles();

  return (
    <CheckboxMUI
      icon={(
        <div className={classNames(classes.icon, {
          [classes.checkboxRequiredError]: error,
        })}
        />
    )}
      checkedIcon={<div className={classes.checkedIcon} />}
      color="default"
      {...rest}
    />
  );
};

export default Checkbox;

Checkbox.defaultProps = {
  error: false,
};
