import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { getTranslateFunction, getUniqueItemsPrimitives } from 'helpers';
import {
  IOnChange,
  Preorder,
} from 'interfaces';
import { buttons } from 'utils/optionsConfig';
import PreordersList from '../containers/PreordersList';
import MonoTypePreordersList from '../containers/MonoTypePreordersList';
import useStyles from '../styles';

interface Props {
  singleType: boolean;
  withTogether: boolean;
  currentType: string;
  together: boolean;
  setTogether: (a: (prev: boolean) => boolean) => void;
  distances: any;
  preorders: {
    [key: string]: Preorder[];
  };
  onChange: (a: IOnChange) => void;
  canceledPreorders: number[];
  searchInputValue: null | string;
}

const CreateProjectsComponent: React.FC<Props> = ({
  singleType,
  withTogether,
  currentType,
  together,
  setTogether,
  distances,
  preorders,
  onChange,
  canceledPreorders,
  searchInputValue,
}: Props) => {
  const t = getTranslateFunction();
  const classes = useStyles();
  const [currentChildType, setCurrentChildType] = useState<string>('');

  const allServicesByType = useMemo(() => getUniqueItemsPrimitives(
    buttons
      .filter(({ type }) => type === currentType)
      .map(({ service }) => service),
  ), [currentType]);

  return (
    <Box className={classes.createProjectsContainer}>
      {!singleType && (
        <Typography className={classes.title}>
          {`${t(currentType)} ${
            currentChildType && `- ${t(currentChildType)}`
          }`}

        </Typography>
      )}
      {withTogether && (
        <FormControlLabel
          control={(
            <Checkbox
              checked={together}
              color="default"
              onChange={() => setTogether((prev: boolean) => !prev)}
            />
          )}
          label={t('preorder.modal.togetherLabel')}
        />
      )}
      {singleType ? (
        <MonoTypePreordersList
          distances={distances}
          preorders={preorders}
          canceledPreorders={canceledPreorders}
          currentType={currentType}
          singleType={singleType}
          onChange={onChange}
        />
      ) : (
        allServicesByType.map((key) => (
          <PreordersList
            key={key}
            preorderType={key}
            onChange={onChange}
            initialPreorders={preorders[key] ?? []}
            canceledPreorders={canceledPreorders}
            searchInputValue={searchInputValue}
            together={together}
            distances={distances}
            currentChildType={currentChildType}
            currentType={currentType}
            setCurrentChildType={setCurrentChildType}
          />
        ))
      )}
    </Box>
  );
};

export default CreateProjectsComponent;
