import { Box, Button, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from 'sharedComponents/Table/Table';
import { getTranslateFunction } from 'helpers';
import { IOnChange, IOrder, Vendor } from 'interfaces';
import { ORDER_STATUSES } from '../../../constants';
import useStyles from '../OrderHistoryStyle';
import { getStatusLabelByOrderStatus } from '../helpers';

interface Props {
  order: IOrder;
  vendor: Vendor | undefined;
  onChange: (a: IOnChange) => void;
}

const OrderListItem: React.FC<Props> = ({
  order,
  vendor,
  onChange,
}: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  const statusClassName = order.status === ORDER_STATUSES.FINISHED
    ? 'orderFinished'
    : 'orderCanceled';

  const {
    inCity,
    betweenCity,
    international,
    helpOnRoad,
    buyThings,
    rent,
    services,
  } = order;

  const [rubric] = [
    inCity,
    betweenCity,
    international,
    helpOnRoad,
    buyThings,
    rent,
    services,
  ].flat().filter(Boolean);

  const tableConfig = {
    'ordersHistoryPage.date': moment(order.createdAt).format('DD.MM.YYYY / HH:mm'),
    'ordersHistoryPage.project': `${t(order.tripType)}, ${t(order.subType)}`,
    'ordersHistoryPage.rubric': t(rubric),
    'ordersHistoryPage.status': {
      text: t(getStatusLabelByOrderStatus(order.status)),
      className: statusClassName,
    },
    'ordersHistoryPage.summ': `${order.price} ${order.currency}`,
  };

  const openOrderInfo = () => {
    onChange({
      action: 'setShowOrder',
      data: order,
    });
  };

  const deleteOrder = () => {
    onChange({
      action: 'deleteOrderForMe',
      data: order,
    });
  };

  const vendorName = useMemo(() => (
    [vendor?.vehicleBrand, vendor?.vehicleModel].join(' ')
  ), [vendor]);

  return (
    <>
      <Box className={classes.line} />
      {vendorName && <Typography>{vendorName}</Typography>}
      <Box
        className={classes.orderContainer}
      >
        <Table>
          <TableBody>
            {Object.entries(tableConfig).map(([key, value]) => (
              <TableRow key={key} className={classes.orderTableRow}>
                <TableCell className={classes.orderTableCell}>
                  <Typography>
                    {t(key)}
                  </Typography>
                </TableCell>
                <TableCell className={classes.orderTableCell}>
                  {typeof value === 'string' ? (
                    <Typography>
                      {value}
                    </Typography>
                  ) : (
                    <Typography className={classes[value.className as 'orderFinished' |'orderCanceled']}>
                      {value.text}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box className={classes.orderButtonsContainer}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.orderButton}
            onClick={deleteOrder}
          >
            {t('activeOrdersPage.delete')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.orderButton}
            onClick={openOrderInfo}
          >
            {t('activeOrdersPage.moreDetails')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default OrderListItem;
