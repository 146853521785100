import { makeStyles } from '@material-ui/core';
import {
  cyan,
  backgroundColor,
  grayBG,
  red,
  textColor,
  textNoWrap,
  orange,
} from '../../constants';

export default makeStyles({
  formControl: {
    minHeight: 30,
    display: 'block',

    '&:not(:first-child)': {
      marginTop: 10,
    },
  },
  formRowWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    color: textColor,
    width: '100%',
    paddingLeft: 5,
    paddingTop: 5,
    fontSize: 13,
  },
  textIcon: {
    marginRight: '5px',
    color: cyan,
    fontSize: 14,
  },
  svg: {
    width: 30,
    height: 30,
    marginRight: '5px',
  },
  input: {
    width: '100%',
    backgroundColor: grayBG,
    fontSize: 13,
    padding: '0 5px',
    border: '2px transparent solid',
  },
  inputError: {
    borderColor: red,
  },
  submit: {
    backgroundColor: orange,
    color: backgroundColor,
    marginLeft: 10,
    width: '50%',
    paddingTop: 0,
    paddingBottom: 0,
    ...textNoWrap,
  },
  attempt: {
    color: textColor,
    fontSize: 16,
  },
  resendCodeButton: {
    backgroundColor: grayBG,
    color: textColor,
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0,
  },
  codeErrorMessage: {
    color: red,
    marginLeft: 35,
  },
}, { index: 1 });
