import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { IOnChange, IOrder, IRating } from 'interfaces';
import { useAuth } from 'services/auth';
import { useOrder } from 'hooks/order';
import { useMyPosition } from 'hooks/geolocation';
import VendorOrderComponent from '../components/VendorOrderComponent';

const VendorOrderApp: React.FC = () => {
  const { setIsLoading } = useAuth();
  const history = useHistory<[IOrder]>();
  const [order] = history.location.state;

  const [ratingModal, setRatingModal] = useState<boolean>(false);
  const [cancelModalOrder, setCancelModalOrder] = useState<boolean>(false);
  const [modalOrderInfo, setModalOrderInfo] = useState<boolean>(false);
  const [modalUserInfo, setModalUserInfo] = useState<boolean>(false);

  const {
    status,
    isSingleTypeOrder,
    price,
    clientUser,
    clientComments,
    currency,
    clientRating,
    handleStatus,
    handleChatUser,
    sendClientRating,
    handleSendSaveOurSouls,
    finishOrder,
    cancelOrder,
  } = useOrder(order);

  const [location] = useMyPosition();

  const handleSendClientRating = async (data: IRating) => {
    setIsLoading(true);
    try {
      sendClientRating(data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = useCallback(async ({
    action,
    data,
  }: IOnChange) => {
    if (!clientUser) return;

    switch (action) {
      case 'handleOrderInfoModal': {
        setModalOrderInfo((prev) => !prev);
        break;
      }
      case 'handleUserInfoModal': {
        setModalUserInfo((prev) => !prev);
        break;
      }
      case 'handleChatToClient': {
        handleChatUser(clientUser.uuid);
        break;
      }
      case 'handleChangeDeclineModal': {
        setCancelModalOrder((prev) => !prev);
        break;
      }
      case 'handleSendSaveOurSouls': {
        handleSendSaveOurSouls();
        break;
      }
      case 'handleStatus': {
        handleStatus(data);
        break;
      }
      case 'handleRatingModal': {
        await finishOrder();
        setRatingModal(true);
        break;
      }
      case 'cancelOrderByVendor': {
        cancelOrder(data);
        break;
      }
      case 'setRatingModal': {
        setRatingModal((prev) => !prev);
        break;
      }
      case 'sendClientRating': {
        handleSendClientRating(data);
        break;
      }
      default: break;
    }
  }, [clientUser]);

  return (
    <VendorOrderComponent
      order={{
        ...order,
        price,
        currency,
      }}
      status={status}
      modalUserInfo={modalUserInfo}
      isSingleTypeOrder={isSingleTypeOrder}
      client={clientUser}
      clientComments={clientComments}
      clientRating={clientRating}
      ratingModal={ratingModal}
      cancelModalOrder={cancelModalOrder}
      modalOrderInfo={modalOrderInfo}
      myLocation={location}
      onChange={handleChange}
    />
  );
};

export default VendorOrderApp;
