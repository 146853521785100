import { makeStyles } from '@material-ui/core';
import {
  backgroundColor,
  green,
  orange,
  grayBG,
} from '../../../../constants';

export default makeStyles({
  svg: {
    width: '15px',
    height: 'auto',
    margin: '5px',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
  preorderServiceContainer: {
    padding: '0 10px',
  },
  preorderServiceBuyItem: {
    marginTop: '10px',
    padding: '5px',
    boxSizing: 'border-box',
    backgroundColor: grayBG,
    borderTop: `2px ${orange} solid`,
    borderBottom: `2px ${orange} solid`,
  },
  preorderInfo: {
    display: 'flex',
  },
  userPhoto: {
    width: '100px',
    height: 'auto',
  },
  offerComment: {
    maxWidth: '200px',
  },
  offerInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  offerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > *': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  showBtn: {
    backgroundColor: orange,
    color: backgroundColor,
    margin: '2px 0',
    padding: '2px',
    '&:hover': {
      backgroundColor: orange,
    },
  },
  priceBox: {
    border: `1px ${orange} solid`,
    color: green,
  },
  ratingBox: {
    display: 'flex',
  },
  ratingListItem: {
    '& .MuiTypography-body2': {
      color: green,
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
}, { index: 1 });
