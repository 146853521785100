import { Box, IconButton, Typography} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { getTranslateFunction } from 'helpers';
import useStyles from './styles';

type MessageTypes = 'success' | 'error' | 'warning';

interface IServiceMessageProps {
  message: string;
  onClose: () => void;
  type: MessageTypes;
}

const ServiceMessage = ({
  message, onClose, type,
}: IServiceMessageProps) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  const messageWrapperStyles = classNames(
    classes.messageWrapper,
    classes[type],
  );

  const messageStyles = classNames(
    classes.message,
    classes[type],
  );

  return (
      <Box className={messageWrapperStyles}>
        <Typography className={messageStyles} dangerouslySetInnerHTML={{ __html: message }} />
        <IconButton
          className={classes.button}
          aria-label={t('serviceMessage.closeBtn')}
          onClick={onClose}>
          <CloseIcon fontSize={'large'}
          />
        </IconButton>
      </Box>
  );
};

export default ServiceMessage;
