import { makeStyles } from '@material-ui/core/styles';
import {
  grayBG,
  green,
  orange,
  textColor,
  textNoWrap,
} from '../../constants';

export default makeStyles((theme) => ({
  preorderInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  preorderSettings: {
    width: '100%',
    '& .MuiFormControl-root': {
      width: '50%',
    },
  },
  userInfos: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
  },
  preorderServices: {
    display: 'grid',
    alignItems: 'flex-start',

    [theme.breakpoints.up(375)]: {
      gridTemplateColumns: '1fr 1fr',
    },
    '& > .MuiButton-root': {
      width: '50%',
    },
  },
  listItemLabel: {
    ...textNoWrap,
  },
  listItemLabelBackgounded: {
    backgroundColor: grayBG,
    padding: 5,
    width: '100%',
  },
  svg: {
    minWidth: '20px',
    height: '20px',
    marginRight: '5px',
    margin: '2px 0',
    '&.star': {
      color: orange,
    },
  },
  endAdornment: {
    marginLeft: '5px',
    padding: '5px',
    backgroundColor: grayBG,
    color: green,
  },
  priceLabel: {
    color: green,
    whiteSpace: 'nowrap',
  },
  preorderContainer: {
    display: 'flex',
    fontSize: '10px',
    width: '100%',
    gap: 5,
    flexDirection: 'column',
  },
  userContainer: {
    width: 'fit-content',
    textAlign: 'center',
    display: 'flex',
    padding: '5px',
    flexDirection: 'column',
  },
  userPhoto: {
    minWidth: '50px',
    minHeight: '50px',
  },
  userName: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: textColor,
  },
  proposalContainer: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'grid',
    gap: 5,
  },
}), { index: 1 });
