import { Box, TextField } from '@material-ui/core';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { CFInputItem } from 'interfaces';
import useStyles from '../ConstructorStyles';

type FormMethods = 'control' | 'getValues' | 'setValue';
interface Props extends Pick<UseFormMethods, FormMethods> {
  item: CFInputItem;
}

const TextFieldItem = ({
  item,
  getValues,
  setValue,
  control,
}: Props) => {
  const {
    name,
    withoutBorder,
    InputProps,
    placeholder,
    inputType,
  } = item;
  const classes = useStyles();

  useEffect(() => {
    setValue(name, getValues(name) || item.value || '');
  }, [getValues]);

  return (
    <Box key={name} className={classNames(classes.inputContainer, {withoutBorder})}>
      <Controller
        render={({ value, onChange, ref }) => (
          <TextField
            key={name}
            InputProps={InputProps}
            placeholder={placeholder}
            className={classes.input}
            type={inputType}
            inputRef={ref}
            value={value || item.value || ''}
            onChange={({ target }) => onChange(target.value)}
          />
        )}
        name={name}
        control={control}
      />
    </Box>
  );
};

export default TextFieldItem;
