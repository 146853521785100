import { Box, Typography } from '@material-ui/core';
import React from 'react';
import Icons from 'components/Icons';
import { getTranslateFunction } from 'helpers';
import { Dot } from 'interfaces';
import { PLACE_PICKER_FALLBACK } from '../constants';

interface ListItemProp {
  label: string;
  SvgIcon: React.ReactNode;
  classes: any;
  withLabel?: boolean;
  address: string;
}

const ListItem: React.FC<ListItemProp> = ({
  label,
  SvgIcon,
  classes,
  withLabel,
  address,
}: ListItemProp) => (
  <Box className={classes.dotBox}>
    {SvgIcon}

    <Typography variant="caption" className={classes.dotBoxLabel}>
      {withLabel && label}
      {address}
    </Typography>
  </Box>
);

interface Props {
  dots: Dot[];
  classes: any;
  withIcon?: boolean;
  withLabel?: boolean;
}

const {
  fromSvg,
  toSvg,
} = Icons;

const LocationList: React.FC<Props> = ({
  dots,
  classes,
  withIcon,
  withLabel,
}: Props) => {
  const t = getTranslateFunction();
  if (!dots) {
    return null;
  }

  const getLabel = (index: number) => {
    const isFirst = index === 0;
    if (isFirst) return t('VendorOffers.Page.fromLabel');

    const isLast = index === dots.length - 1;
    if (isLast) return t('VendorOffers.Page.toLabel');

    return t('VendorOffers.Page.stopLabel');
  };

  return (
    <>
      {dots.map((dot: Dot, index: number) => {
        const svgIcon: string = index === 0 ? fromSvg : toSvg;

        return (
          <ListItem
            key={`${dot.lon}${dot.lat}`}
            SvgIcon={withIcon && (
              <img
                className={classes.svg}
                src={svgIcon}
                alt="address"
              />
            )}
            classes={classes}
            address={dot.value || t(PLACE_PICKER_FALLBACK)}
            label={`${getLabel(index)}: `}
            withLabel={withLabel}
          />
        );
      })}
    </>
  );
};

export default LocationList;

LocationList.defaultProps = {
  withIcon: false,
  withLabel: false,
};

ListItem.defaultProps = {
  withLabel: false,
};
