import { Box, Button, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import InfoToggle from 'components/InfoToggle/InfoToggle';
import { useLocation, useHistory } from 'react-router';
import Modal from 'sharedComponents/Modal';
import InfoMessage from 'components/InfoMessage/InfoMessage';
import { getTranslateFunction } from 'helpers';
import { IOnChange } from 'interfaces';
import { useModal } from 'services/customHooks';
import VendorListItem, { VendorItem } from './VendorListItem';
import useStyles from '../styles';

interface Props {
  vendors: VendorItem[];
  activeVendor: number | null;
  onChange: (a: IOnChange) => void;
  menuYourTransportTop: string;
}

interface LocationState {
  addedNewVendor?: boolean;
}

const VendorListComponent = ({ vendors, activeVendor, onChange, menuYourTransportTop }: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();
  const location = useLocation<LocationState>();
  const history = useHistory();
  const { isModalVisible, showModal, hideModal } = useModal();

  const clearRouterState = () => {
    history.push({ ...location, state: {} });
  };

  useEffect(() => {
    if (location.state?.addedNewVendor) {
      showModal();
      clearRouterState();
    }
  }, []);

  const handleModalClose = () => {
    hideModal();
    clearRouterState();
  };

  const isVendorsExist = !!vendors.length;
  const shouldShowInfoToggle = vendors.length > 1;

  return (
    <Box className={classes.page}>
      <Button
        color="primary"
        variant="contained"
        className={classes.addVendorBtn}
        onClick={() => onChange({ action: 'goToCreateVendor' })}
      >
        {t('vendorList.addNewVendor')}
      </Button>

      {menuYourTransportTop && <Typography className={classes.pageTitle}>{menuYourTransportTop}</Typography>}

      {isVendorsExist && (
        <InfoToggle
          description={t('vendorList.infoToggle.description')}
          btnLabel="vendorList.infoToggle.btn"
          isOpen={shouldShowInfoToggle}
        />
      )}

      {Boolean(vendors)
        && vendors.map((vendor: VendorItem) => (
          <VendorListItem
            key={vendor.id}
            vendor={vendor}
            onChange={onChange}
            isActiveVendor={vendor.id === activeVendor}
          />
        ))}

      <Modal withoutBorder withoutPadding isOpen={isModalVisible} onClose={handleModalClose}>
        <InfoMessage onClose={handleModalClose} message="vendorList.addedNewVendor" />
      </Modal>
    </Box>
  );
};

export default VendorListComponent;
