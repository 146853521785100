import React, { FC } from 'react';

interface Props {
  color: string;
}

const WithdrawFunds: FC<Props> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 72 72">
    <defs />
    <path fill={color} d="M35.7 40c-4.8 0-9.1-2.4-11.8-6.1v24H48V33.1A14.5 14.5 0 0135.7 40z" />
    <path fill={color} d="M56.4 10.6h-55v24.8h14.2v30.7h40.8V35.3h14.2V10.6H56.4zm-4 51.5H19.5V25.5h4.6a11.6 11.6 0 0023.3 0h5V62zm13-31.9h-9v-4.7H60v-5.2H11.8v5.2h3.8v4.7h-9V15.9h58.8v14.3z" />
  </svg>
);

export default WithdrawFunds;
