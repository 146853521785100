import {
  DialogContent,
  Typography,
  TextField,
  DialogActions,
  Button,
  FormHelperText,
  FormControl,
} from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'sharedComponents/Modal';
import { IOnChange } from 'interfaces';
import { getTranslateFunction } from 'helpers';
import { useAuth } from 'services/auth';
import { onlyNumbersRegExp, REFERRAL_ID_LENGTH } from '../../../constants';
import useStyles from '../ChatStyle';

type Props = {
  onChange: (a: IOnChange) => void;
  isOpen: boolean;
};

const CreateChatModal: React.FC<Props> = ({ onChange, isOpen }) => {
  const { user } = useAuth();
  const { handleSubmit, register, errors } = useForm({ defaultValues: { referralId: '' }});
  const t = getTranslateFunction();
  const classes = useStyles();

  const onSubmit = ({referralId}: { referralId: string}) => {
    onChange({
      action: 'createChat',
      data: referralId,
    });
  };

  const onClose = () => {
    onChange({
      action: 'setCreateChatModal',
      data: false,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} withoutBorder>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <FormControl error={Boolean(errors.referralId)}>

            <Typography>{t('settings.createChat')}</Typography>
            <TextField
              inputRef={register({
                required: {
                  value: true,
                  message: 'CreateChatModal.required',
                },
                pattern: {
                  value: onlyNumbersRegExp,
                  message: 'CreateChatModal.invalidCode',
                },
                maxLength: {
                  value: REFERRAL_ID_LENGTH,
                  message: 'CreateChatModal.length',
                },
                minLength: {
                  value: REFERRAL_ID_LENGTH,
                  message: 'CreateChatModal.length',
                },
                validate: (value) => value !== user?.referralId,
              })}
              InputProps={{ disableUnderline: false }}
              name="referralId"
            />
            {errors.referralId?.message && (
              <FormHelperText>{t(errors.referralId.message)}</FormHelperText>
            )}
            {errors.referralId?.type === 'validate' && (
              <FormHelperText>{t('CreateChatModal.yourselfId')}</FormHelperText>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button className={classes.createChatSubmitBtn} type="submit">{t('settings.openRoom')}</Button>
        </DialogActions>
      </form>
    </Modal>
  );
};

export default CreateChatModal;
