import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';
import { getTranslateFunction } from 'helpers';
import { IMessage } from 'interfaces';
import ChatMessage from '../Message';

import useStyles from './styles';

export interface MessagesBlockProps {
  messages: [string, IMessage[]][];
  usermeUuid: string;
  className: string;
  loadMoreMessages: () => void;
}
const MessagesBlock = ({
  messages, usermeUuid, className, loadMoreMessages,
}: MessagesBlockProps) => {
  const t = getTranslateFunction();
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);

  const [, lastMessages] = messages[messages.length - 1] || [];
  const [lastMessage] = lastMessages || [];

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [lastMessage?._id]);

  const loadMore = debounce(loadMoreMessages, 500);

  useEffect(() => {
    const handleScroll = ({ target }: Event) => {
      if (target instanceof HTMLElement) {
        const { scrollTop } = target;

        if (scrollTop <= 500) {
          loadMore();
        }
      }
    };

    if (ref.current instanceof HTMLElement) {
      ref.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      ref.current?.removeEventListener('scroll', handleScroll);
    };
  }, [loadMore]);

  return (
    <div ref={ref} className={className}>
      {messages.map(([day, currentMessages]) => {
        const dayNow = moment().format('DD.MM.YYYY');
        const dayFormatted = moment(day).format('DD.MM.YYYY');
        const dayLabel = dayFormatted === dayNow ? t('chat.today') : dayFormatted;

        return (
          <Box key={day}>
            <Box className={classes.messageDate}>
              <Typography className={classes.messageDateText}>
                {dayLabel}
              </Typography>
            </Box>
            {currentMessages.map(({_id, ...message}: IMessage) => (
              <ChatMessage
                key={_id}
                toMe={usermeUuid !== message.toUser}
                {...message}
              />
            )).reverse()}
          </Box>
        );
      })}
    </div>
  );
};

export default MessagesBlock;
