import { Box, Button } from '@material-ui/core';
import classNames from 'classnames';
import Timer from 'components/Timer';
import React, { useMemo } from 'react';
import { IOrder, User } from 'interfaces';
import { getTranslateFunction } from 'helpers';
import useStyles from '../OrderPageStyle';
import NavigationLinks from './NavigationLinks';
import { ORDER_STATUSES } from '../../../constants';
import ButtonsBlock from './ButtonsBlock';

interface Props {
  status: number;
  client: User;
  order: IOrder;
  handleChange: (a: string, b?: number | string) => void;
  isSingleTypeOrder: boolean;
  myLocation: [number, number] | null;
}

const OrderControlButtons: React.FC<Props> = ({
  status,
  order,
  client,
  handleChange,
  isSingleTypeOrder,
  myLocation,
}: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  const isClientCameOut = useMemo(() => (
    status === ORDER_STATUSES.EXITING
  ), [status]);

  const statusLabel = useMemo(() => (
    status === ORDER_STATUSES.WAITING
      ? t('vendorOffers.NotYetExit')
      : t('vendorOffers.preorderListItem.inPlaceLabel')
  ), [status]);

  const showPhoneBtn = useMemo(() => (
    isSingleTypeOrder ? order.showPhone : true
  ), [order]);

  const showChatBtn = useMemo(() => (
    isSingleTypeOrder ? order.withChat : true
  ), [order]);

  const finishTime = useMemo(() => {
    const dateNow = Math.floor((new Date(order?.createdAt).getTime()) / 1000);
    const orderWaitTime = Number(order?.time) * 60;

    return dateNow + orderWaitTime;
  }, [order]);

  if (!client) return null;
  return (
    <>
      <Box className={classes.orderContainer}>
        <Box className={classes.infoContainer}>
          {!isSingleTypeOrder && (
            <Timer
              finishTime={finishTime}
            />
          )}
          <ButtonsBlock
            showPhoneBtn={showPhoneBtn}
            showChatBtn={showChatBtn}
            phone={client.phone}
            handleChange={handleChange}
          />
          <Button className={classNames(classes.clientBtn, 'red')} onClick={() => handleChange('handleChangeDeclineModal')}>
            {t('ClientOrderPage.CancelOrder')}
          </Button>
        </Box>
      </Box>
      <Box className={classes.buttonContainer}>
        {isClientCameOut && (
          <Button className={classNames(classes.button, 'red')} onClick={() => handleChange('handleSendSaveOurSouls')}>
            {t('ClientOrderPage.toFriendInfo')}
          </Button>
        )}
        {!isSingleTypeOrder && (
          <NavigationLinks
            order={order}
            status={status}
            handleChange={handleChange}
            myLocation={myLocation}
          />
        )}
        <Button
          className={classNames(classes.button, 'green')}
          onClick={() => handleChange('handleStatus', ORDER_STATUSES.WAITING)}
        >
          {statusLabel}
        </Button>
        {isClientCameOut && (
          <Button
            className={classNames(classes.button, 'green')}
            onClick={() => handleChange('handleRatingModal')}
          >
            {t('OrderPage.FinishOrder')}
          </Button>
        )}
      </Box>
    </>
  );
};

export default OrderControlButtons;
