import { makeStyles } from '@material-ui/core';
import { green, orange, red } from '../../constants';

export default makeStyles({
  messageWrapper: {
    border: `4px ${orange} solid`,
    padding: 10,
    position: 'relative',
  },

  message: {
    fontSize: 16,
  },

  success: {
    color: green,
    borderColor: green,
  },

  warning: {
    color: orange,
    borderColor: orange,
  },

  error: {
    color: red,
    borderColor: red,
  },

  button: {
    position: 'absolute',
    top: -20,
    right: -20,
    zIndex: 1,
    color: red,
    padding: 0,
  },
});
