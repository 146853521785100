import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Redirect, useHistory } from 'react-router';
import Preloader from 'components/Preloader/Preloader';
import { toast } from 'react-toastify';
import {
  convertCurrencies,
  createPreorderService,
  getPreorderServices,
} from 'services/commonService';
import { getCurrencyByCountry, getErrorMessage } from 'helpers';
import RequestService from 'services/requestApi';
import { useAuth } from 'services/auth';
import socket from 'socket';
import {
  ExtentedCurrencyPreorderService,
  IOrder,
  Preorder,
  PreorderService,
} from 'interfaces';
import { useOrders } from 'services/orders';
import VendorOfferComponent from '../components/VendorOfferComponent';
import { usePreorderService } from '../hooks';

const VendorOffer: React.FC = () => {
  const history = useHistory<any>();
  const {
    vendorId,
    vendorUuid,
    user: userMe,
    setIsLoading,
  } = useAuth();
  const [preorder]: any = history.location.state || [];

  if (!preorder) {
    return <Redirect to="/" />;
  }

  const [modal, setModal] = useState(false);
  const [declinedModal, setDeclinedModal] = useState(false);
  const [preorderServiceId, setPreorderServiceId] = useState<number>(0);
  const [offerPrice, setOfferPrice] = useState(preorder.price);
  const [time, setTime] = useState(2);
  const [reofferModal, setReofferModal] = useState(false);
  const [secondOffer, setSecondOffer] = useState(false);
  const [canceledModal, setCanceledModal] = useState(false);

  const {
    handleAddVendorOrders,
  } = useOrders();

  const {
    user,
    coords,
    userRating,
    isSingleTypePreorder,
  } = usePreorderService(preorder);

  const unsubscribe = () => {
    socket.off('preorderRemoved');
    socket.off('orderCreated');
    socket.off('preorderTaken');
    socket.off('preorderServiceDecline');
  };

  const isMyOrder = useMemo(() => (
    userMe?.uuid === user?.uuid
  ), [userMe, user]);

  const currency = useMemo(() => (
    getCurrencyByCountry(userMe?.country ?? '')
  ), [user]);

  useEffect(() => {
    socket.emit('joinToRoom', preorder.preorderId.toString());
    socket.on('connect', () => {
      socket.emit('joinToRoom', 'vendors');
    });
    socket.on('preorderServiceDecline', (id: number) => {
      if (vendorId === id) {
        setDeclinedModal(true);
      }
    });
    socket.on('preorderTaken', (innerPreorderId: number) => {
      if (innerPreorderId === preorder.preorderId) {
        setModal(true);
      }
    });
    socket.on('preorderRemoved', ([currentPreorder]: Preorder[]) => {
      if (currentPreorder.preorderId === preorder.preorderId) {
        setDeclinedModal(false);
        setCanceledModal(true);
      }
    });
    socket.on('orderCreated', (data: { docs: IOrder[]}) => {
      const { docs: [order]} = data;

      if (order.serviceId === vendorId) {
        unsubscribe();

        handleAddVendorOrders([order]);
        history.push('/vendor-order-room', [order]);
      } else {
        setModal(true);
      }
    });

    return unsubscribe;
  }, [history, vendorId]);

  useEffect(() => {
    const getData = async () => {
      const res = await getPreorderServices(preorder.preorderId);
      if (res.Error) return;
      const { data } = res;

      const service = data.find((item: PreorderService) => item.serviceId === vendorId);
      if (service) {
        setReofferModal(true);
        setSecondOffer(true);
        setPreorderServiceId(1);
      }
    };
    getData();
  }, [vendorId, preorder]);

  const handleCreatePreorderService = async (data: ExtentedCurrencyPreorderService) => {
    const price = await convertCurrencies({
      fromCurrency: currency,
      toCurrency: data.initialCurrency,
      type: 'buy',
      amount: data.price,
    });
    const numberPrice = Number(price);
    const response = await createPreorderService({
      ...data,
      price: numberPrice,
      currency: data.initialCurrency,
    });

    if (response.Error) return;

    setPreorderServiceId(response.preorderServiceId);
  };

  const handleConfirm = useCallback(async ({
    offerPrice: innerPrice,
    vendorLocation: loc,
    time: wayTime,
    vendorId: id,
    vendorUuid: uuid,
    initialCurrency,
  }) => {
    if (!preorderServiceId && userMe) {
      const price = await convertCurrencies({
        fromCurrency: currency,
        toCurrency: initialCurrency,
        type: 'buy',
        amount: innerPrice,
      });
      const numberPrice = Number(price);

      try {
        setIsLoading(true);

        const res = await new RequestService('preorder-service')
          .post({
            ...preorder,
            currency: initialCurrency,
            serviceId: id,
            vendorUuid: uuid,
            price: numberPrice,
            location: loc,
            time: Number(wayTime),
          });

        setPreorderServiceId(res.preorderServiceId);
      } catch (e) {
        const error = e as Error;

        toast.error(getErrorMessage(error));
      } finally {
        setIsLoading(false);
      }
    }
  }, [user, preorderServiceId, currency]);

  const handlePrice = useCallback((value) => {
    if (typeof value === 'string') {
      setOfferPrice(Number(offerPrice) + 5);
      return;
    }
    setOfferPrice(preorder.price);
  }, [offerPrice]);

  const handleTime = useCallback((value) => {
    if (typeof value === 'string') {
      setTime((prev) => Number(prev + 2));
      return;
    }
    setTime(value);
  }, []);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, []);

  const handleClose = useCallback(() => {
    setDeclinedModal(false);
    setModal(false);
    setCanceledModal(false);
    history.goBack();
  }, [modal, declinedModal]);

  const handleCloseModal = useCallback(() => {
    setReofferModal(false);
  }, [reofferModal]);

  const handleChange = useCallback(({
    action,
    data,
  }) => {
    switch (action) {
      case 'setPreorderServiceId': {
        setPreorderServiceId(data);
        break;
      }
      case 'handleTime': {
        handleTime(data);
        break;
      }
      case 'handleGoBack': {
        handleGoBack();
        break;
      }
      case 'handleClose': {
        handleClose();
        break;
      }
      case 'handlePrice': {
        handlePrice(data);
        break;
      }
      case 'handleConfirm': {
        if (preorderServiceId) return;
        handleConfirm(data);
        break;
      }
      case 'handleCloseModal': {
        handleCloseModal();
        break;
      }
      case 'createPreorderService': {
        handleCreatePreorderService(data);
        break;
      }
      default: break;
    }
  }, [preorderServiceId]);

  if (!user || !userRating) {
    return <Preloader />;
  }

  return (
    <VendorOfferComponent
      userRating={userRating}
      isSingleTypePreorder={isSingleTypePreorder}
      user={user}
      preorderServiceId={preorderServiceId}
      preorder={preorder}
      reofferModal={reofferModal}
      vendorUuid={vendorUuid}
      vendorLocation={coords}
      vendorUserUuid={user.uuid}
      canceledModal={canceledModal}
      vendorId={vendorId}
      offerPrice={offerPrice}
      time={time}
      secondOffer={secondOffer}
      modal={modal}
      declinedModal={declinedModal}
      onChange={handleChange}
      isMyOrder={isMyOrder}
    />
  );
};

export default VendorOffer;
