import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useBrands } from 'components/ConstructorForm/items/CarCombobox/useBrands';
import { VendorToCreate } from 'interfaces';
import { useAuth } from 'services/auth';
import CreateVendorPage from '../CreateVendorPage';
import { submitter } from '../helpers';

const CreateVendorPageContainer: React.FC = () => {
  const { user, setIsLoading } = useAuth();
  const history = useHistory();

  const {
    brands,
    getModels,
    models,
  } = useBrands();

  const onSubmit = async (data: VendorToCreate, userId: number) => {
    setIsLoading(true);
    try {
      await submitter({ ...data, userId });

      history.push('/vendors-list', { addedNewVendor: true });
    } catch (error) {
      const e = error as Error;
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = useCallback(({ action, data }) => {
    if (!user) return;

    switch (action) {
      case 'createVendor':
        onSubmit(data, user.id);
        break;
      case 'getModels':
        getModels(data);
        break;
      default:
        break;
    }
  }, [user]);

  return (
    <CreateVendorPage
      brands={brands}
      models={models}
      onChange={handleChange}
    />
  );
};

export default CreateVendorPageContainer;
