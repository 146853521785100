import { TextField, IconButton } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Icons from 'components/Icons';
import { getBase64FromFile, getTranslateFunction } from 'helpers';

import { IMessage } from 'interfaces';
import useStyles from './styles';

const acceptedTypes = ['jpg', 'jpeg', 'png'];
const { fileUpload } = Icons;

const defaultValues = {
  message: '',
  photos: [],
};

export interface ChatFormProps {
  onSubmit: (data: Pick<IMessage, 'messageData' | 'messageFiles'>) => Promise<void | IMessage>;
}

const ChatForm = ({ onSubmit }: ChatFormProps) => {
  const t = getTranslateFunction();
  const classes = useStyles();
  const {
    register,
    getValues,
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const onMessageSend = useCallback(async ({ messageData = '', messageFiles = [] }: Pick<IMessage, 'messageData' | 'messageFiles'>) => {
    reset();

    const isMessageEmpty = !messageData;
    const isPhotosEmpty = !messageFiles.length;

    if (isPhotosEmpty && isMessageEmpty) {
      toast.error(t('error.validation.required'));
      return;
    }

    const sendData = {
      messageData,
      messageFiles,
    };

    onSubmit(sendData);
  }, [onSubmit]);

  const handleFileSend = async ({target: { files }}: {target: HTMLInputElement}) => {
    if (!files) return;
    const toArray = Object.values(files);

    const promises = toArray.map((file) => getBase64FromFile(file));
    const messageFiles = await Promise.all(promises);

    const messageData = getValues('message');

    onMessageSend({ messageFiles, messageData });
  };

  return (
    <form className={classes.messageContainer} onSubmit={handleSubmit(onMessageSend)}>
      <TextField
        className={classes.messageInput}
        name="messageData"
        placeholder={t('chat.message')}
        inputRef={register({
          required: {
            value: true,
            message: t('error.validation.required'),
          },
        })}
        InputProps={{
          autoComplete: 'off',
          disableUnderline: true,
        }}
      />
      <label htmlFor="files">
        <img className={classes.filesInputImg} src={fileUpload} alt={t('chat.fileUpload')} />
        <input
          accept={acceptedTypes.map((type: string) => `image/${type}`).join()}
          className={classes.filesInput}
          id="files"
          name="files"
          type="file"
          multiple
          onChange={handleFileSend}
        />
      </label>
      <IconButton
        className={classes.sentBtn}
        type="submit"
        color="default"
      >
        <Send />
      </IconButton>
    </form>
  );
};

export default ChatForm;
