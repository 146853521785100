import React from 'react';
import { useHistory } from 'react-router';
import { Typography, makeStyles } from '@material-ui/core';
import { textColor } from '../constants';

interface Props {
  to: string;
}

const useStyle = makeStyles({
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: textColor,
    fontSize: '15px',
    '&:last-child': {
      textAlign: 'end',
    },
  },
});

const StyledRouteLink: React.FC<Props> = ({ children, to }) => {
  const history = useHistory();

  const style = useStyle();
  function onClick() {
    history.push(to);
  }

  return (
    <Typography variant="caption" onClick={onClick} className={style.link}>
      {children}
    </Typography>
  );
};

export default StyledRouteLink;
