import React from 'react';
import { IconComponentProps } from 'interfaces';

const Gifts: React.FC<IconComponentProps> = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 72 72"
  >
    <path
      fill={color}
      d="M38.51 33.06H64.8v37.58H38.51zM7.2 33.06v37.58h26.29V33.06H9.35zM52.38 17.34V2.22L38.51 17.34v10.71H64.8V17.34zM19.91 17.34H7.2v10.71h26.29V17.34L19.91 2.22z"
    />
  </svg>
);

export default Gifts;
