import { makeStyles } from '@material-ui/core';
import {
  green,
  textColor,
  orange,
  grayBG,
} from '../../constants';

export default makeStyles({
  vendorContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    margin: '10px 0',
    backgroundColor: grayBG,
    borderTop: `2px ${orange} solid`,
    borderBottom: `2px ${orange} solid`,
    padding: '5px 0',
  },
  vendor: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  svg: {
    width: '20px',
    height: 'auto',
    margin: '5px',
    '&.star': {
      color: orange,
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
  services: {
    paddingRight: 5,
    borderRight: `1px ${textColor} solid`,
    display: 'flex',
    flexDirection: 'column',
  },
  vendorInfo: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 0',
    flex: 1,
    paddingRight: 10,
    width: '100%',
    justifyContent: 'space-between',

    '& .MuiTypography-root': {
      textAlign: 'center',
    },
  },
  vendorPhoto: {
    minWidth: '40px',
    minHeight: '40px',
  },
  vendorInfoStats: {
    display: 'flex',
    justifyContent: 'center',
    gap: 5,
  },
  vendorInfoStatsItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  vendorInfoName: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  vendorStats: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    margin: '5px',
    borderTop: `1px ${textColor} solid`,
    '& .MuiTypography-root': {
      fontSize: '13px',
      '&:last-child ': {
        color: green,
        fontWeight: 'bold',
      },
    },
  },
  vendorStatsItem: {
    textAlign: 'center',
  },
}, { index: 1 });
