import { makeStyles } from '@material-ui/core/styles';
import { red } from '../../constants';

export default makeStyles({
  input: {
    width: '100%',
  },
  inputError: {
    border: `2px ${red} solid`,
    '&.Mui-focused + .Mui-error': {
      color: 'transparent',
    },
    '&:not(.Mui-focused)': {
      borderColor: red,
      color: 'transparent',

      '& input, & input::placeholder': {
        color: 'transparent',
        zIndex: -1,
      },
    },
  },
  inputWrapper: {
    position: 'relative',
    width: '100%',
  },
  errorMessage: {
    position: 'absolute',
    top: 0,
    left: 10,
    fontSize: 16,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    height: '100%',
  },
  errorMessageStartAdornment: {
    left: 35,
  },
}, { index: 1 });
