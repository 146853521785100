import React from 'react';
import { Button } from '@material-ui/core';
import { getTranslateFunction } from 'helpers';
import { indexStyles } from '../material';

interface Props {
  submitTitle: string;
  cancelTitle?: string;
  onSubmit?: () => void;
  onCancel: () => void;
}

const FooterButton: React.FC<Props> = ({
  submitTitle,
  onSubmit,
  onCancel,
  cancelTitle = 'signupPage.footerButtonContainer.cancel',
}) => {
  const classes = indexStyles();
  const t = getTranslateFunction();

  return (
    <div className={classes.footerButtonContainer}>
      <Button className={classes.footerButtonCancel} onClick={onCancel}>{t(cancelTitle)}</Button>

      {onSubmit
        ? (
          <Button
            className={classes.footerButtonSubmit}
            onClick={onSubmit}
          >
            {t(submitTitle)}
          </Button>
        )
        : <Button className={classes.footerButtonSubmit} type="submit">{t(submitTitle)}</Button>}
    </div>
  );
};

export default FooterButton;

FooterButton.defaultProps = {
  cancelTitle: 'signupPage.footerButtonContainer.cancel',
  onSubmit: undefined,
};
