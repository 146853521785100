import { useCallback, useEffect, useState } from 'react';
import { Preorder, User } from 'interfaces';
import { getFilteredPreorders } from 'services/commonService';

export interface IUsePreordersResult {
  preorders: Preorder[];
  deletePreorder: (preorderId: number) => void;
  addPreorder: (newPreorder: Preorder) => void;
}

export const usePreorders = (user: User): IUsePreordersResult => {
  const [preorders, setPreorders] = useState<Preorder[]>([]);

  useEffect(() => {
    if (!user) return;
    const handleGetData = async () => {
      const { customerId } = await getFilteredPreorders({
        findColumnValue: {
          customerId: user.id,
        },
        op: 'and',
      });
      setPreorders(customerId);
    };
    handleGetData();
  }, [user]);

  const deletePreorder = useCallback((preorderId: number) => {
    setPreorders((prev: Preorder[]) => (
      prev.filter((item) => item.preorderId !== preorderId)
    ));
  }, []);

  const addPreorder = useCallback((newItem: Preorder) => {
    setPreorders((prev) => {
      const isExist = prev.some((item) => item.preorderId === newItem.preorderId);

      return isExist
        ? prev.filter((item) => item.preorderId !== newItem.preorderId)
        : [...prev, newItem];
    });
  }, []);

  return {
    preorders,
    deletePreorder,
    addPreorder,
  };
};
