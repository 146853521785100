import { makeStyles } from '@material-ui/core/styles';
import {
  grayBG,
  orange,
} from '../../constants';

export default makeStyles(() => ({
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
  },
  svg: {
    minWidth: '20px',
    height: '20px',
    marginRight: '5px',
    marginTop: '5px',
    '&.star': {
      color: orange,
    },
  },
  endAdornment: {
    marginLeft: '5px',
    padding: '5px',
    fontSize: '15px',
    backgroundColor: grayBG,
  },
  infoAboutCarPreorder: {
    marginLeft: 25,
  },
  preorderOption: {
    margin: '5px 0',
    '& .MuiTypography-body1': {
      width: '100%',
      backgroundColor: grayBG,
      padding: '5px',
    },
  },
  preorderGalleryBox: {
    marginLeft: '25px',
  },
}), { index: 1 });
