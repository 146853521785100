import { makeStyles } from '@material-ui/core/styles';
import {
  backgroundColor,
  checkboxUnchecked,
  grayBG, green, orange, red, textNoWrap,
} from '../../constants';

export default makeStyles((theme) => ({
  page: {
    padding: '10px 30px',
    [theme.breakpoints.down(380)]: {
      padding: 10,
    },
  },
  pageTitle: {
    textAlign: 'center',
  },
  svg: {
    width: '25px',
    height: '25px',
    marginRight: '5px',
  },
  balanceEndAdornment: {
    ...textNoWrap,
    marginLeft: '5px',
    width: '150px',
    backgroundColor: grayBG,
    fontSize: '11px',
    padding: '6px 5px 0',
    textAlign: 'center',
    '&.red': {
      color: red,
    },
  },
  userInfo: {
    marginTop: 20,
    gap: 10,
    display: 'flex',
  },
  userImage: {
    width: 150,
    height: 'auto',
  },
  userLabel: {
    color: green,
    fontWeight: 'bold',
  },
  userRating: {
    color: orange,
    display: 'flex',
    alignItems: 'center',
  },
  statusBlock: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  locationLabel: {
    borderBottom: `1px solid ${checkboxUnchecked}`,
    marginBottom: 10,
    paddingBottom: 10,
    display: 'flex',
    alignItems: 'flex-end',
  },
  linkBlock: {
    backgroundColor: green,
    width: '100%',
    marginTop: 10,
    padding: 5,
    textAlign: 'center',
    boxSizing: 'border-box',
  },
  userLabelId: {
    color: green,
  },
  linkBlockLabel: {
    color: backgroundColor,
  },
  infoWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
  },
}), { index: 1 });
