import { useEffect, useState } from 'react';
import {
  IComment,
  RatingType,
  User,
  Vendor,
} from 'interfaces';
import { useAuth } from '../services/auth';
import {
  getVendor,
  getVendorComments,
  getVendorRating,
} from '../services/commonService';
import { useUser } from './user';

interface UVendorProps {
  vendorUserUuid: string | undefined;
  vendorUuid: string | undefined;
}

interface UVendorRes {
  vendor: Vendor | null;
  vendorUser: User;
  vendorRating: RatingType;
  vendorComments: IComment[];
}

export const useVendor = ({
  vendorUserUuid,
  vendorUuid,
}: UVendorProps): UVendorRes => {
  const { setIsLoading } = useAuth();
  const [vendor, setVendor] = useState<Vendor | null>(null);
  const [vendorRating, setVendorRating] = useState<RatingType | null>(null);
  const [vendorComments, setVendorComments] = useState([]);
  const vendorUser = useUser(vendorUserUuid);

  const resetState = () => {
    setVendorRating(null);
    setVendorComments([]);
    setVendor(null);
  };

  useEffect(() => {
    const getInfo = async () => {
      if (!(vendorUuid && vendorUserUuid)) {
        resetState();
        return;
      }

      try {
        setIsLoading(true);

        const [
          vendorResponse,
          vendorRatingResponse,
          vendorCommentsResponse,
        ] = await Promise.all([
          getVendor(vendorUuid),
          getVendorRating(vendorUuid),
          getVendorComments(vendorUuid),
        ]);

        const [orderVendor] = vendorResponse;
        setVendor(orderVendor);

        setVendorRating(vendorRatingResponse);
        setVendorComments(vendorCommentsResponse);
      } finally {
        setIsLoading(false);
      }
    };

    getInfo();
  }, [vendorUserUuid, vendorUuid]);

  return {
    vendor,
    vendorUser,
    vendorRating,
    vendorComments,
  };
};
