import React, { FC } from 'react';

interface Props {
  color: string;
}

const SendFunds: FC<Props> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 72 72">
    <defs />
    <path fill={color} d="M11.5 43h14.1v5.3H11.5z" />
    <path fill={color} d="M7.3 35.5v-5.3h50.2v4.6H63v-21H1.9v43.8h44.5v-5.3h-39V35.5zm0-16.3h50.2v4.4H7.3v-4.4z" />
    <path fill={color} d="M59 37.6a10.8 10.8 0 100 21.7 10.8 10.8 0 000-21.7zm-.4 16.5v-3.7h-6.4v-3.8h6.4v-3.7l8.1 5.6-8.1 5.6z" />
  </svg>

);

export default SendFunds;
