import { Box, FormControlLabel, Typography } from '@material-ui/core';
import { Controller, UseFormMethods } from 'react-hook-form';
import classNames from 'classnames';
import Checkbox from 'sharedComponents/Checkbox';
import Icons from 'components/Icons';
import { CFCheckboxItem } from 'interfaces';
import useStyles from '../ConstructorStyles';

interface Props extends Pick<UseFormMethods, 'control' | 'errors'> {
  item: CFCheckboxItem;
}

const CheckboxItem = ({
  item,
  errors,
  control,
}: Props) => {
  const classes = useStyles();
  const {
    name,
    icon: Icon,
    fullWidth,
    label,
  } = item;

  return (
    <FormControlLabel
      key={item.name}
      className={classNames(classes.radio, {fullWidth})}
      labelPlacement="start"
      control={(
        <Controller
          key={name}
          name={name}
          control={control}
          render={({ value, onChange }) => (
            <Checkbox
              key={name}
              className={classes.checkbox}
              data-order-checkbox={name}
              onChange={(e) => onChange(e.target.checked)}
              checked={!!value}
              error={errors?.[name]}
            />
          )}
        />
      )}
      label={(
        <Box className={classes.titleRadio}>
          {Icon || (
            <img
              src={Icons[`${name}Svg`]}
              alt={label}
              className={classes.svg}
            />
          )}
          <Typography variant="caption">{label}</Typography>
        </Box>
      )}
    />
  );
};

export default CheckboxItem;
