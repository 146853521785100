import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'services/auth';
import { getAppInfo } from 'services/commonService';
import { HowProjectWork, Lang } from 'interfaces';
import { formatLangItems } from 'helpers';
import HowProjectWorkComponent, { Item } from '../components/HowProjectWorkComponent';

const HowProjectWorkApp: React.FC = () => {
  const { setIsLoading } = useAuth();
  const { i18n } = useTranslation();
  const [items, setItems] = useState<HowProjectWork[]>([]);

  useEffect(() => {
    const handleGetAppInfo = async () => {
      try {
        setIsLoading(true);
        const response = await getAppInfo() as HowProjectWork[];
        const filteredResponse = response.filter(({ position }) => !position);

        setItems(filteredResponse);
      } finally {
        setIsLoading(false);
      }
    };

    handleGetAppInfo();
  }, []);

  const formattedItems = items.map((item: HowProjectWork) => (
    formatLangItems(item, i18n.language as Lang)
  )) as Item[];

  return (
    <HowProjectWorkComponent items={formattedItems} />
  );
};

export default HowProjectWorkApp;
