import React from 'react';
import {
  UseFormMethods,
} from 'react-hook-form';
import classNames from 'classnames';
import { FormControl } from '@material-ui/core';
import Input from 'sharedComponents/Input';
import userSvg from 'images/icons/user.svg';
import { getTranslateFunction } from 'helpers';
import { indexStyles } from '../material';

type Fields = {
  firstName: string;
  lastName: string;
  patronymic: string;
};

type Props = Pick<UseFormMethods<Fields>, 'register' | 'errors' | 'clearErrors'>;

interface FieldsArrType {
  key: 'firstName' | 'lastName' | 'patronymic';
  placeholder: string;
  imageAlt: string;
  patternMsg: string;
  required?: boolean;
}

const fields: FieldsArrType[] = [
  {
    key: 'firstName',
    placeholder: 'signInPage.nameLabel',
    imageAlt: 'nameLabel',
    patternMsg: 'NameWithCharacters',
    required: true,
  },
  {
    key: 'lastName',
    placeholder: 'signInPage.lastnameLabel',
    imageAlt: 'lastnameLabel',
    patternMsg: 'SurnameWithCharacters',
    required: true,
  },
  {
    key: 'patronymic',
    placeholder: 'signInPage.patronymicLabel',
    imageAlt: 'patronymicLabel',
    patternMsg: 'PatronymicWithCharacters',
  },
];

const NameBlock: React.FC<Props> = ({
  register,
  clearErrors,
  errors,
}: Props) => {
  const classes = indexStyles();
  const t = getTranslateFunction();

  return (
    <>
      {fields.map(({
        key,
        placeholder,
        imageAlt,
        patternMsg,
        required,
      }) => (
        <FormControl key={key} className={classes.formControl} error={Boolean(errors[key])}>

          <Input
            className={classNames(classes.textField, { required })}
            autoComplete='new-password'
            name={key}
            placeholder={t(placeholder)}
            disableUnderline
            errorMessage={errors[key]?.message}
            onFocus={() => clearErrors(key)}
            onChange={() => clearErrors(key)}
            startAdornment={<img src={userSvg} alt={t(`signInPage.${imageAlt}`)} className={classes.iconAdornment} />}
            inputRef={register({
              required: {
                value: Boolean(required),
                message: t('error.validation.required'),
              },
              maxLength: {
                value: 80,
                message: t('error.validation.maxLength'),
              },
              pattern: {
                value: /^[a-zA-Za-яА-ЯА-ЩЬЮЯҐЄІЇа-щьюяґєії_ʼ -—ʼ'`]+$/,
                message: t(`error.validation.${patternMsg}`),
              },
            })}
          />
        </FormControl>
      ))}
    </>
  );
};

export default NameBlock;
