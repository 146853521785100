import React from 'react';
import CollapseBox from 'components/CollapseComponent';
import TableVertical from 'sharedComponents/Table/components/TableVertical';
import { Box } from '@material-ui/core';
import { getTranslateFunction } from 'helpers';
import icons from 'components/Icons';

import { RowValue, TCurrency } from 'interfaces';
import useStyles from '../PaymentsPageStyle';
import { cyan } from '../../../constants';

export type IncomeValue = {
  date: string;
  transactionNumber: number;
  typeTransaction: string;
  projectLabel: string;
  address: string;
  currency: TCurrency;
  quantity: number;
};

interface Props {
  values: IncomeValue[];
}

const { IncomeTsx } = icons;

const Income: React.FC<Props> = ({
  values,
}: Props) => {
  const t = getTranslateFunction();
  const classes = useStyles();

  const formattedValues: Record<string, RowValue>[] = values.map(({
    transactionNumber,
    date,
    projectLabel,
    address,
    quantity,
    currency,
  }) => ({
    transactionNumber,
    date,
    projectLabel,
    address,
    quantity: {
      text: `${quantity} ${currency}`,
      className: 'green',
    },
  }));

  return (
    <CollapseBox
      title={t('PaymentsPage.income')}
      Icon={IncomeTsx}
    >
      {formattedValues.map((item) => {
        const formatItem = Object.fromEntries(
          Object.entries(item)
            .map(([key, value]) => ([t(`PaymentsPage.income.${key}`), value])),
        );

        return (
          <Box className={classes.incomeTable} key={Number(item.transactionNumber)}>
            <TableVertical
              tableConfig={formatItem}
            />
            <hr color={cyan} className={classes.hr} />
          </Box>
        );
      })}
    </CollapseBox>
  );
};

export default Income;
