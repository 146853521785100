import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { LatLngTuple } from 'leaflet';
import debounce from 'lodash.debounce';
import { formatAddress, geocode, reverseGeocode } from 'services/geo';
import { useAuth } from 'services/auth';
import { Suggestion } from 'interfaces';

type TUseSuggestionReturn = {
  getSuggestions: (value: string) => void;
  suggestions: Suggestion[] | null;
  getFromCoordinates: (coordinates: LatLngTuple) => Promise<Suggestion>;
  setSuggestions: Dispatch<SetStateAction<Suggestion[] | null>>
  isLoading: boolean;
};

const DEBOUNCE_TIME = 600;

export const useSuggestions = (): TUseSuggestionReturn => {
  const { user, currentIp } = useAuth();
  const [suggestions, setSuggestions] = useState<Suggestion[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getGeocode = useCallback(async (value: string) => {
    setIsLoading(true);
    const city = currentIp?.city || user?.city;
    const items = await geocode(value, currentIp?.country, city);

    const itemsWithLabels = items.map((item) => {
      const [label, cityLabel] = formatAddress(item.address);

      return {
        ...item,
        label,
        cityLabel,
      };
    });

    if (isMounted.current) {
      setSuggestions(itemsWithLabels);
    }
    setIsLoading(false);
  }, [user, currentIp]);

  const getGeocodeWrapper = debounce(getGeocode, DEBOUNCE_TIME);

  const getSuggestions = useCallback((value) => {
    setSuggestions(null);
    if (value.length > 3) getGeocodeWrapper(value);
  }, []);

  const getFromCoordinates = async (coordinates: LatLngTuple): Promise<Suggestion> => {
    const res = await reverseGeocode(coordinates);
    return res;
  };

  return {
    isLoading,
    getSuggestions,
    suggestions,
    getFromCoordinates,
    setSuggestions,
  };
};
