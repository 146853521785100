import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import transactionIcons from 'images/transaction';
import historyIcon from 'images/history';
import chatLogo from 'images/chat';
import homeIcon from 'images/home';
import { getTranslateFunction } from 'helpers';
import { controlButtonsList } from './config';
import { green } from '../../constants';
import useStyles from './BottomMenuStyle';

const icons: Record<string, React.FC<{ color: string }>> = {
  chatLogo,
  historyIcon,
  transactionIcons,
  homeIcon,
};

interface Props {
  unreadMessages: number;
}

const BottomMenu = ({ unreadMessages }: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  return (
    <Box className={classes.buttonWrapper}>
      {controlButtonsList.map(({ label, icon, to, id }) => {
        const Icon = icons[icon];
        const isNewMessagesExist = id === 'chat' && !!unreadMessages;

        return (
          <Link key={label} className={classes.menuBtn} to={to}>
            <Box className={classes.svgIcon}>
              <Icon color={green} />
              {isNewMessagesExist && <span className={classes.newMessageIndicator} />}
            </Box>
            <Typography variant="caption" className={classes.title}>
              {t(label)}
            </Typography>
          </Link>
        );
      })}
    </Box>
  );
};

export default BottomMenu;
