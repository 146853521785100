import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Icons from 'components/Icons';
import { getCurrencyByCountry, getTranslateFunction } from 'helpers';
import {
  CFInputItem,
  CFItem,
  User,
} from 'interfaces';
import {
  cyan,
  green,
  grayBG,
} from '../../constants';

const {
  CommentTsx,
  makePriceSvg,
  PhoneTsx,
  ChatTsx,
  fromSvg,
  toSvg,
} = Icons;

const useStyles = makeStyles({
  svg: {
    width: '20px',
    height: '20px',
    marginRight: '5px',
    display: 'flex',
  },
  endAdornPrice: {
    padding: '0 5px',
    color: green,
    backgroundColor: grayBG,
  },
}, { index: 1 });

const PreorderFormConfig = ({ user }: {
  user: User;
}): {
  freightTransport: CFInputItem[];
  alongTheWay: CFItem[];
  temporaryDriver: CFItem[];
  animalTransport: CFItem[];
  anotherTransport: CFItem[];
  courier: CFInputItem[];
  helpOnRoad: CFInputItem[];
  buyStartArray: CFItem[];
  buyProperty: CFItem[];
  buyTransport: CFItem[];
  buySpareParts: CFItem[];
  buyElectrical: CFItem[];
  buyOther: CFItem[];
  rentOther: CFItem[];
  servicesMainArray: CFItem[];
  serviceRepairAuto: CFItem[];
  currency: string;
} => {
  const t = getTranslateFunction();
  const classes = useStyles();
  const currency = getCurrencyByCountry(user?.country ?? '');

  const commentItem: CFInputItem = {
    type: 'text',
    withoutBorder: true,
    inputType: 'text',
    name: 'comment',
    placeholder: t('PreorderForm.detailedDescription'),
    InputProps: {
      disableUnderline: true,
      startAdornment: (
        <Box
          className={classes.svg}
        >
          <CommentTsx color={cyan} />
        </Box>
      ),
    },
  };

  const preorderNameItem: CFInputItem = {
    type: 'text',
    withoutBorder: true,
    required: true,
    inputType: 'text',
    name: 'preorderName',
    placeholder: t('PreorderForm.preorderName'),
    InputProps: {
      disableUnderline: true,
      startAdornment: (
        <Box
          className={classes.svg}
        >
          <CommentTsx color={cyan} />
        </Box>
      ),
    },
  };

  const tripRequirementsItem: CFInputItem = {
    type: 'text',
    inputType: 'text',
    name: 'tripRequirements',
    value: '',
    InputProps: {
      disableUnderline: true,
      readOnly: true,
      startAdornment: (
        <Box
          className={classes.svg}
        >
          <CommentTsx color={cyan} />
        </Box>
      ),
    },
  };

  const freightTransport: CFInputItem[] = [
    {
      type: 'text',
      inputType: 'text',
      required: true,
      name: 'tripRequirements',
      placeholder: t('HomePage.alongTheWay.FreightTransport'),
      InputProps: {
        disableUnderline: true,
        startAdornment: (
          <Box
            className={classes.svg}
          >
            <CommentTsx color={cyan} />
          </Box>
        ),
      },
    },
    commentItem,
  ];

  const animalTransport: CFInputItem[] = [
    {
      type: 'text',
      inputType: 'text',
      required: true,
      name: 'tripRequirements',
      placeholder: t('HomePage.alongTheWay.AnimalTransport'),
      InputProps: {
        disableUnderline: true,
        startAdornment: (
          <Box
            className={classes.svg}
          >
            <CommentTsx color={cyan} />
          </Box>
        ),
      },
    },
    commentItem,
  ];

  const anotherTransport: CFInputItem[] = [
    {
      type: 'text',
      inputType: 'text',
      name: 'tripRequirements',
      required: true,
      placeholder: t('HomePage.alongTheWay.AnotherTransport'),
      InputProps: {
        disableUnderline: true,
        startAdornment: (
          <Box
            className={classes.svg}
          >
            <CommentTsx color={cyan} />
          </Box>
        ),
      },
    },
    commentItem,
  ];

  const temporaryDriver: CFInputItem[] = [
    {
      type: 'text',
      inputType: 'text',
      name: 'tripRequirements',
      value: t('HomePage.alongTheWay.TemporaryDriver'),
      InputProps: {
        disableUnderline: true,
        readOnly: true,
        startAdornment: (
          <Box
            className={classes.svg}
          >
            <CommentTsx color={cyan} />
          </Box>
        ),
      },
    },
    commentItem,
  ];

  const helpOnRoad: CFInputItem[] = [
    tripRequirementsItem,
    commentItem,
  ];

  const courier: CFInputItem[] = [
    tripRequirementsItem,
    commentItem,
  ];

  const alongTheWay: CFItem[] = [
    {
      type: 'radio',
      name: 'together',
      items: [
        {
          label: t('preorder.modal.individualLabel'),
          value: 'individual',
        },
        {
          label: t('preorder.modal.togetherLabel'),
          value: 'together',
        },
      ],
    },
    {
      type: 'radio',
      name: 'carClass',
      items: [
        {
          label: t('preorder.modal.economy'),
          value: 'economy',
        },
        {
          label: t('preorder.modal.comfort'),
          value: 'comfort',
        },
        {
          label: t('preorder.modal.motorcycle'),
          value: 'motorcycle',
        },
        {
          label: t('preorder.modal.microBus'),
          value: 'microBus',
        },
      ],
    },
    {
      type: 'array',
      items: [
        {
          type: 'checkbox',
          name: 'baggage',
          label: t('preorder.modal.baggage'),
        },
        {
          type: 'checkbox',
          name: 'hasBabyChair',
          label: t('preorder.modal.babyChair'),
        },
        {
          type: 'passengers',
          name: 'passengers',
          label: t('preorder.modal.passengers'),
        },
      ],
    },
    {
      type: 'text',
      inputType: 'text',
      name: 'comment',
      placeholder: t('preorder.modal.commentLabel'),
      InputProps: {
        disableUnderline: true,
        startAdornment: (
          <Box
            className={classes.svg}
          >
            <CommentTsx color={cyan} />
          </Box>
        ),
      },
    },
  ];

  const buyStartArray: CFItem[] = [
    {
      type: 'radio',
      name: 'tripTime',
      items: [
        {
          label: t('preorder.modal.nowLabel'),
          value: 'now',
        },
        {
          label: t('preorder.modal.toTimeLabel'),
          value: 'toTime',
        },
      ],
    },
    {
      type: 'datePicker',
      name: 'tripTimeDate',
    },
    {
      type: 'text',
      withoutBorder: true,
      inputType: 'text',
      name: 'buyerCity',
      placeholder: t('PreorderForm.buyerCity'),
      InputProps: {
        disableUnderline: true,
        startAdornment: (
          <img
            className={classes.svg}
            src={fromSvg}
            alt={t('PreorderForm.buyerCity')}
          />
        ),
      },
    },
    {
      type: 'array',
      items: [
        {
          type: 'checkbox',
          name: 'findInAllCities',
          label: t('preorder.modal.findInAllCities'),
          icon: <Box />,
        },
      ],
    },
    {
      type: 'autocomplete',
      multiple: true,
      limitTags: 4,
      withoutBorder: true,
      inputType: 'text',
      userCountry: user?.country,
      name: 'providedCities',
      placeholder: t('PreorderForm.providedCities'),
      InputProps: {
        disableUnderline: true,
        startAdornment: (
          <img
            className={classes.svg}
            src={toSvg}
            alt={t('PreorderForm.providedCities')}
          />
        ),
      },
    },
    {
      type: 'array',
      items: [
        {
          type: 'checkbox',
          name: 'showPhone',
          label: t('preorder.modal.showPhone'),
          icon: (
            <Box
              className={classes.svg}
            >
              <PhoneTsx color={cyan} />
            </Box>
          ),
          fullWidth: true,
        },
        {
          type: 'checkbox',
          name: 'withChat',
          label: t('preorder.modal.withChat'),
          icon: (
            <Box
              className={classes.svg}
            >
              <ChatTsx color={cyan} />
            </Box>
          ),
          fullWidth: true,
        },
      ],
    },
    {
      type: 'text',
      inputType: 'number',
      name: 'price',
      placeholder: t('preorder.modal.priceLabel'),
      InputProps: {
        disableUnderline: true,
        startAdornment: (
          <img className={classes.svg} src={makePriceSvg} alt="price" />
        ),
        endAdornment: (
          <Typography className={classes.endAdornPrice}>{currency}</Typography>
        ),
        inputProps: {
          inputMode: 'numeric',
        },
      },
    },
    {
      type: 'radio',
      withoutBorder: true,
      title: t('preorder.modal.paymentType'),
      name: 'paymentType',
      items: [
        {
          label: t('preorder.modal.cash'),
          value: 'cash',
        },
        {
          label: t('preorder.modal.toCard'),
          value: 'toCard',
        },
        {
          label: t('preorder.modal.internalTransfer'),
          value: 'internalTransfer',
        },
      ],
    },
  ];

  const buyTransport: CFItem[] = [
    {
      type: 'carCombobox',
      mode: 'preorderService',
    },
    commentItem,
  ];

  const buySpareParts: CFItem[] = [
    {
      type: 'radio',
      name: 'preorderType',
      withoutIcons: true,
      items: [
        {
          label: t('preorder.modal.transport'),
          value: 'transport',
        },
        {
          label: t('preorder.modal.appliances'),
          value: 'appliances',
        },
        {
          label: t('preorder.modal.electricalEngineering'),
          value: 'electricalEngineering',
        },
        {
          label: t('preorder.modal.furniture'),
          value: 'furniture',
        },
      ],
    },
    preorderNameItem,
    commentItem,
    {
      type: 'files',
      withoutBorder: true,
    },
  ];

  const buyElectrical: CFItem[] = [
    {
      type: 'radio',
      name: 'preorderType',
      withoutIcons: true,
      items: [
        {
          label: t('preorder.modal.laptopOrComputers'),
          value: 'laptopOrComputers',
        },
        {
          label: t('preorder.modal.cameraOrVideoCamera'),
          value: 'cameraOrVideoCamera',
        },
        {
          label: t('preorder.modal.phonesOrTablet'),
          value: 'phonesOrTablet',
        },
        {
          label: t('preorder.modal.tv'),
          value: 'tv',
        },
        {
          label: t('preorder.modal.powerTools'),
          value: 'powerTools',
        },
        {
          label: t('preorder.modal.other'),
          value: 'other',
        },
      ],
    },
    preorderNameItem,
    commentItem,
    {
      type: 'files',
      withoutBorder: true,
    },
  ];

  const buyOther: CFItem[] = [
    preorderNameItem,
    commentItem,
    {
      type: 'files',
      withoutBorder: true,
    },
  ];

  const rentOther: CFItem[] = [
    preorderNameItem,
    commentItem,
    {
      type: 'files',
      withoutBorder: true,
    },
  ];

  const buyProperty: CFItem[] = [
    {
      type: 'radio',
      name: 'preorderType',
      withoutIcons: true,
      items: [
        {
          label: t('preorder.modal.flat'),
          value: 'flat',
        },
        {
          label: t('preorder.modal.earth'),
          value: 'earth',
        },
        {
          label: t('preorder.modal.house'),
          value: 'house',
        },
        {
          label: t('preorder.modal.another'),
          value: 'another',
        },
      ],
    },
    preorderNameItem,
    commentItem,
  ];

  const servicesMainArray: CFItem[] = [
    preorderNameItem,
    commentItem,
    {
      type: 'files',
      withoutBorder: true,
    },
  ];

  const serviceRepairAuto: CFItem[] = [
    {
      type: 'carCombobox',
      mode: 'preorderService',
    },
    ...servicesMainArray,
  ];

  return {
    freightTransport,
    alongTheWay,
    temporaryDriver,
    animalTransport,
    anotherTransport,
    courier,
    helpOnRoad,
    buyStartArray,
    buyProperty,
    buyTransport,
    buySpareParts,
    buyElectrical,
    buyOther,
    rentOther,
    servicesMainArray,
    serviceRepairAuto,
    currency,
  };
};

export default PreorderFormConfig;
