import React, { useCallback, useEffect, useMemo } from 'react';
import { IOnChange, Transaction } from 'interfaces';
import { useAuth } from 'services/auth';
import { useAppInfoTextOnly } from 'hooks/appInfo';
import { getCurrencyByCountry, onlyUniquePrimitives } from 'helpers';
import { useOrdersByIds } from 'hooks/order';
import { usePayments, useTransactions } from 'hooks/payment';
import { useCurrency } from 'hooks/currency';
import PaymentsComponent from '../components/PaymentsComponent';
import { USDcurrency } from '../../../constants';

const POSITIONS = [
  'termsOfUse',
];

const PaymentsPageApp: React.FC = () => {
  const { user } = useAuth();
  const { termsOfUse } = useAppInfoTextOnly(POSITIONS);
  const currency = useMemo(() => getCurrencyByCountry(user?.country), [user]);
  const { currencyData } = useCurrency(currency, user?.country);

  const {
    transactions,
    setTransactions,
  } = useTransactions();

  const {
    orders,
    setOrdersLoading,
  } = useOrdersByIds();

  const {
    balance,
    commissions,
    allBonuses,
  } = usePayments();

  useEffect(() => {
    const filteredTransactions = transactions
      .map(({ orderId }) => orderId)
      .filter(Boolean) as number[];

    setOrdersLoading((prev) => prev.concat(filteredTransactions).filter(onlyUniquePrimitives));
  }, [transactions]);

  const handleConfirmRequest = useCallback((transaction: Transaction) => {
    setTransactions((prev) => [...prev, transaction]);
  }, []);

  const handleChange = useCallback(({ action, data }: IOnChange) => {
    switch (action) {
      case 'confirmRequest': {
        return handleConfirmRequest(data);
      }
      default: return null;
    }
  }, []);

  return (
    <PaymentsComponent
      userCurrency={currency ?? USDcurrency}
      currencyData={currencyData}
      uuid={user?.referralId ?? ''}
      commisions={commissions}
      onChange={handleChange}
      balance={balance}
      transactions={transactions}
      orders={orders}
      termsOfUse={termsOfUse}
      bonuses={allBonuses}
    />
  );
};

export default PaymentsPageApp;
