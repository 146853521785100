import { Box, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import Preloader from 'components/Preloader/Preloader';
import Icons from 'components/Icons';
import { getTranslateFunction } from 'helpers';
import { dateFormatter } from 'utils/formatters';
import useStyles from '../OrderPageStyle';
import { cyan, orange } from '../../../constants';

const {
  StarTsx,
  nowSvg,
  toTimeSvg,
  CommentTsx,
} = Icons;

const OrderBuyInfoContainer: React.FC<any> = ({
  order,
  userRating,
  userComments,
}) => {
  const t = getTranslateFunction();
  const classes = useStyles();

  const tripTimeIcon = useMemo(() => (order.tripTime === 'now' ? nowSvg : toTimeSvg), [order]);
  const tripTimeText = useMemo(() => (
    order.tripTime === 'now' ? t('preorder.modal.nowLabel') : dateFormatter(Number(new Date(order.tripTime)))
  ), [order]);

  const isBuyOrderTransport = useMemo(() => (order.buyThings && order.buyThings[0].includes('transport')), [order]);

  if (!userRating) {
    return <Preloader />;
  }

  return (
    <Box className={classes.preorderSettings}>
      <Box className={classes.info}>
        <Box className={classes.services}>
          <Box className={classes.listItem}>
            <img
              className={classes.svg}
              src={Icons[`${order.paymentType}Svg`]}
              alt={t(`preorder.modal.${order.paymentType}Label`)}
            />
            <Typography variant="caption">{`${order.price} ${order.currency}`}</Typography>
          </Box>
          <Box className={classes.listItem}>
            <img
              className={classes.svg}
              src={tripTimeIcon}
              alt={tripTimeText}
            />
            <Typography variant="caption">{tripTimeText}</Typography>
          </Box>
          {Boolean(userRating.ratingAvg) && (
            <Box className={classes.listItem}>
              <Box className={classes.svg}>
                <StarTsx color={orange} />
              </Box>
              <Typography variant="caption">{userRating.ratingAvg}</Typography>
            </Box>
          )}
          <Box className={classes.listItem}>
            <Box className={classes.svg}>
              <CommentTsx color={cyan} />
            </Box>
            <Typography variant="caption">{userComments.length}</Typography>
          </Box>
        </Box>
        <Box className={classes.preorderServices}>
          {order.preorderType && (
          <Box className={classes.listItem}>
            <Typography>{`preorder.modal.${order.preorderType}`}</Typography>
          </Box>
          )}
          {order.preorderName && (
          <Box className={classes.listItem}>
            <Typography>{order.preorderName}</Typography>
          </Box>
          )}
          {!isBuyOrderTransport && order.comment && (
          <Box className={classes.listItem}>
            <Typography>{order.comment}</Typography>
          </Box>
          )}
          {!isBuyOrderTransport && order.buyerCity && (
          <Box className={classes.listItem}>
            <Typography>{`${t('preorder.modal.from')} ${order.buyerCity}`}</Typography>
          </Box>
          )}
          {order.vehicleBrandOfBuy && (
          <Box className={classes.listItem}>
            <Typography>{order.vehicleBrandOfBuy.label}</Typography>
          </Box>
          )}
          {order.vehicleModelOfBuy && (
          <Box className={classes.listItem}>
            <Typography>{order.vehicleModelOfBuy.label}</Typography>
          </Box>
          )}
          {order.releaseDateFromOfBuy
          && order.releaseDateToOfBuy && (
          <Box className={classes.listItem}>
            <Typography>{`${order.releaseDateFromOfBuy.label} | ${order.releaseDateToOfBuy.label}`}</Typography>
          </Box>
          )}
          {order.transmissionOfBuy && (
          <Box className={classes.listItem}>
            <Typography>{order.transmissionOfBuy.label}</Typography>
          </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default OrderBuyInfoContainer;
