import React from 'react';
import CollapseBox from 'components/CollapseComponent';
import {
  FormControl, Box, Typography, FormHelperText, Button,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import Commision from 'images/Commision';
import Input from 'sharedComponents/Input';
import { TCurrency } from 'interfaces';
import { getTranslateFunction } from 'helpers';
import icons from 'components/Icons';
import { cyan, onlyNumbersRegExp } from '../../../constants';
import useStyles from '../PaymentsPageStyle';

const { topUpWalletTsx } = icons;

interface Props {
  currency: TCurrency;
}

const defaultValues = {
  summ: '',
};

const TopUpWallet: React.FC<Props> = ({ currency }) => {
  const t = getTranslateFunction();
  const classes = useStyles();

  const {
    handleSubmit, errors, control,
  } = useForm({ defaultValues });

  const onSubmit = () => {};

  return (
    <CollapseBox
      title={t('PaymentsPage.topUpWallet')}
      Icon={topUpWalletTsx}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl error={Boolean(errors.summ)} className={classes.formControl}>
          <Box className={classes.formRowWrapper}>
            <Box className={classes.svg}>
              <Typography variant="caption" className={classes.textIcon}>{currency}</Typography>
            </Box>

            <Controller
              control={control}
              name="summ"
              rules={{
                required: {
                  value: true,
                  message: 'PaymentsPage.fillInput',
                },
                pattern: {
                  value: onlyNumbersRegExp,
                  message: 'PaymentsPage.quantity.invalidValue',
                },
              }}
              render={(({ value, onChange: onInputChange }) => (
                <Input
                  placeholder={t('PaymentsPage.topUpWallet.summ')}
                  value={value}
                  onChange={onInputChange}
                  className={classes.input}
                />
              ))}
            />

          </Box>
          {errors?.summ?.message && (
          <FormHelperText id="component-error-text">{t(errors.summ.message)}</FormHelperText>
          )}
        </FormControl>

        <FormControl className={classes.formControl}>
          <Box className={classes.formRowWrapper}>
            <Box className={classes.svg}>
              <Commision color={cyan} height={30} width={30} />
            </Box>

            <Typography className={classes.label}>{t('PaymentsPage.topUpWallet.commision')}</Typography>

            <Button variant="contained" className={classes.topUpWalletSubmitButton}>
              {t('PaymentsPage.topUpWallet.submit')}
            </Button>
          </Box>
        </FormControl>

        <Typography className={classes.serviceUnavailable}>
          {t('PaymentsPage.topUpWallet.unavailable')}
        </Typography>
      </form>
    </CollapseBox>
  );
};

export default TopUpWallet;
