import React, { useEffect, useState } from 'react';
import { useAppInfoTextOnly } from 'hooks/appInfo';
import { useUsersRating } from 'hooks/rating';
import { User } from 'interfaces';
import { useAuth } from 'services/auth';
import { getReferrals } from 'services/commonService';
import ShareComponent from '../components/ShareComponent';

const POSITIONS = [
  'menuShareTop',
];

const ShareApp: React.FC = () => {
  const { user, setIsLoading } = useAuth();
  const [referrals, setReferrals] = useState<User[]>([]);
  const appInfo = useAppInfoTextOnly(POSITIONS);
  const {
    usersRating,
    setUsers,
  } = useUsersRating();

  useEffect(() => {
    if (!user) return;

    const handleGetReferrals = async () => {
      setIsLoading(true);
      try {
        const response = await getReferrals(user.id);
        setReferrals(response);
        setUsers(response);
      } finally {
        setIsLoading(false);
      }
    };

    handleGetReferrals();
  }, [user]);

  return (
    <ShareComponent
      menuShareTop={appInfo.menuShareTop}
      user={user}
      referrals={referrals}
      usersRating={usersRating}
    />
  );
};

export default ShareApp;
