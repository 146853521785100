import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import classNames from 'classnames';
import Icons from 'components/Icons';
import { CFRadioItem, CFRadioItemChild } from 'interfaces';
import useStyles from '../ConstructorStyles';

type FormMethods = 'control' | 'setValue' | 'getValues';

interface Props extends Pick<UseFormMethods, FormMethods> {
  item: CFRadioItem;
}

const RadioItem: React.FC<Props> = ({
  item,
  control,
  setValue,
  getValues,
}: Props) => {
  const classes = useStyles();
  const {
    items,
    name,
    title,
    withoutBorder,
    withoutIcons,
  } = item;

  useEffect(() => {
    const [firstItem] = items;
    const defaultValue = getValues(name) || firstItem.value;

    setValue(name, defaultValue);
  }, [items, name]);

  return (
    <Box key={name} className={classNames(classes.inputContainer, {withoutBorder})}>
      <Controller
        name={item.name}
        control={control}
        render={({ value, onChange }) => (
          <>
            {title && (
              <Typography className={classes.paymentType} variant="caption">{title}</Typography>
            )}
            <RadioGroup className={classes.radioGroup} value={value} onChange={onChange}>
              {items.map((radioItem: CFRadioItemChild) => (
                <FormControlLabel
                  key={radioItem.value}
                  labelPlacement="start"
                  className={classes.radio}
                  value={radioItem.value}
                  control={(
                    <Radio
                      data-testid={`radio-${radioItem.value}`}
                      checkedIcon={<Box className={classes.checkedIcon} />}
                      icon={<Box className={classes.icon} />}
                    />
                  )}
                  label={(
                    <Box className={classes.titleRadio}>
                      {!withoutIcons && (
                        <img
                          src={Icons[`${radioItem.value}Svg`]}
                          alt={radioItem.value}
                          className={classes.svg}
                        />
                      )}
                      <Typography variant="caption">{radioItem.label}</Typography>
                    </Box>
                  )}
                />
              ))}
            </RadioGroup>
          </>
        )}
      />
    </Box>
  );
};

export default RadioItem;
