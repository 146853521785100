import {
  Dispatch,
  SetStateAction,
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router';
import socket from 'socket';
import { getCurrencyByCountry, getTranslateFunction, onlyUniquePrimitives } from 'helpers';
import { useAuth } from 'services/auth';
import {
  RatingType,
  IComment,
  OrderStatus,
  IOrder,
  IRating,
  User,
  UUIDs,
  Vendor,
  TCurrency,
} from 'interfaces';
import { useOrders } from 'services/orders';
import RequestService from 'services/requestApi';
import {
  convertCurrencies,
  createChatRoom,
  getClientFinishedOrders,
  getClientOrder,
  getOrder,
  getUuidByOrder,
  getVendorFinishedOrders,
  getVendorOrder,
  hideOrder,
  sendSaveOurSouls,
} from 'services/commonService';
import { hyphensToCamelCase } from 'utils/formatters';
import { ORDER_STATUSES, SINGLE_TYPES, KEYS } from '../constants';
import { useClient } from './client';
import { useVendor } from './vendor';

const { hiddenOnHistoryNameCustomer, hiddenOnHistoryNameService } = KEYS.order;

const convertOrdersCurrency = async (orders: IOrder[], myCurrency: TCurrency) => {
  const promises = orders.map(async (order) => {
    const isDifferentCurrencies = order.preorderCurrency !== myCurrency;

    if (!isDifferentCurrencies) return order;

    const newPrice = await convertCurrencies({
      toCurrency: myCurrency,
      fromCurrency: order.preorderCurrency,
      amount: order.price,
      type: 'buy',
    });

    return {
      ...order,
      price: newPrice,
      preorderServiceCurrency: myCurrency,
    };
  });

  const results = await Promise.all(promises);
  return results;
};

interface UOrderRes {
  vendor: Vendor | null;
  price: number;
  status: number;
  vendorUser: User;
  vendorRating: RatingType;
  vendorComments: IComment[];
  isSingleTypeOrder: boolean;
  waitingLabel: string;
  isCourierOrder: boolean;
  ratingAvg: number;
  clientUser: User;
  clientRating: RatingType;
  clientComments: IComment[];
  currency: TCurrency;
  cancelOrder: (message: string) => void;
  handleChatUser: (uuid: string) => void;
  handleChatToVendor: () => void;
  finishOrder: () => void;
  handleSendSaveOurSouls: () => void;
  sendClientRating: (data: IRating) => Promise<IRating[]>;
  sendVendorRating: (data: IRating) => Promise<IRating[]>;
  handleStatus: (newStatus: number) => void;
}

export const useUsersByOrder = (order: IOrder | null): {
  clientUser: User;
  clientRating: RatingType;
  clientComments: IComment[];
  vendor: Vendor | null;
  vendorUser: User;
  vendorRating: RatingType;
  vendorComments: IComment[];
} => {
  const { setIsLoading } = useAuth();
  const [uuids, setUuids] = useState<UUIDs | null>(null);

  const {
    vendorUserUuid,
    vendorUuid,
    userUuid,
  } = uuids || {};

  const {
    clientUser,
    clientRating,
    clientComments,
  } = useClient(userUuid);

  const {
    vendor,
    vendorUser,
    vendorRating,
    vendorComments,
  } = useVendor({ vendorUserUuid, vendorUuid });

  useEffect(() => {
    const handleGetInfo = async () => {
      if (!order) {
        setUuids(null);
        return;
      }

      try {
        setIsLoading(true);
        const uuidsResponse = await getUuidByOrder(order.serviceId, order.id);

        const [response] = uuidsResponse;
        setUuids(response);
      } finally {
        setIsLoading(false);
      }
    };
    handleGetInfo();
  }, [order]);

  return {
    clientUser,
    clientRating,
    clientComments,
    vendor,
    vendorUser,
    vendorRating,
    vendorComments,
  };
};

export const useOrder = (order: IOrder): UOrderRes => {
  const { setIsLoading, user, vendorId } = useAuth();
  const t = getTranslateFunction();
  const [status, setStatus] = useState(order.status || ORDER_STATUSES.DEFAULT);
  const [myPrice, setMyPrice] = useState<number>(order.price);
  const history = useHistory();
  const {
    clientUser,
    clientRating,
    clientComments,
    vendor,
    vendorUser,
    vendorRating,
    vendorComments,
  } = useUsersByOrder(order);

  const myCurrency = useMemo(() => getCurrencyByCountry(user?.country ??''), [user]);

  const { handleDeleteOrder } = useOrders();

  useEffect(() => {
    if (!myCurrency) return;

    const handleGetMyPrice = async () => {
      const newPrice = await convertCurrencies({
        fromCurrency: order.preorderServiceCurrency,
        toCurrency: myCurrency,
        amount: order.price,
        type: 'buy',
      });

      setMyPrice(newPrice);
    };
    handleGetMyPrice();
  }, [myCurrency, order]);

  useEffect(() => {
    setStatus(order.status);
  }, [order]);

  useEffect(() => {
    if (!order) return;

    socket.emit('joinToRoom', order.preorderId.toString());
  }, [order]);

  useEffect(() => {
    const handleStatus = (data: OrderStatus) => {
      setStatus(data.status);
    };
    if (!socket.on) return () => {};

    socket.on('status', handleStatus);
    return () => socket.off('status', handleStatus);
  }, [socket]);

  const handleSendStatus = useCallback((newStatus: number) => {
    new RequestService('/order/status').put({
      orderId: order.id,
      status: newStatus,
    });
  }, [order]);

  const sendClientRating = async (data: IRating) => (
    new RequestService('/users-rating').post(data)
  );

  const sendVendorRating = async (data: IRating) => (
    new RequestService('/vendors-rating').post(data)
  );

  const ratingAvg = useMemo(() => {
    if (vendorRating) {
      const {
        drivingSkillRatingCount,
        politenessRatingCount,
        vehicleConditionRatingCount,
      } = vendorRating;
      return (
        Math.floor((
          drivingSkillRatingCount
          + politenessRatingCount
          + vehicleConditionRatingCount
        ) / 3)
      );
    }
    return 0;
  }, [vendorRating]);

  const handleSendSaveOurSouls = () => {
    sendSaveOurSouls({ orderId: order.id });
  };

  const cancelOrder = useCallback(async (message) => {
    const cancelStatus = vendorId
      ? ORDER_STATUSES.CANCELED_BY_VENDOR
      : ORDER_STATUSES.CANCELED_BY_CLIENT;

    try {
      setIsLoading(true);
      await new RequestService('/order/status')
        .put({
          status: cancelStatus,
          orderId: order.id,
          rejectionReason: message,
        });
      handleDeleteOrder(order.id);
      history.push('/');
    } finally {
      setIsLoading(false);
    }
  }, [order, vendorId]);

  const handleChatUser = async (uuid: string | undefined) => {
    if (!uuid) return;

    try {
      setIsLoading(true);
      await createChatRoom(uuid);
      history.push(`/chat/${uuid}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChatToVendor = useCallback(async () => {
    if (!vendorUser) return;

    try {
      setIsLoading(true);
      await handleChatUser(vendorUser.uuid);
    } catch (e) {
      handleChatToVendor();
    } finally {
      setIsLoading(false);
    }
  }, [vendorUser]);

  const finishOrder = async () => {
    try {
      setIsLoading(true);
      new RequestService('/order/status').put({
        orderId: order.id,
        status: ORDER_STATUSES.FINISHED,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isSingleTypeOrder = SINGLE_TYPES.some((type) => (
    hyphensToCamelCase(order.tripType) === type
  ));
  const isCourierOrder = order.tripType === 'courier';
  const waitingLabel = isCourierOrder ? t('ClientOrderPage.CourierWaitingLabel') : t('ClientOrderPage.waitingLabel');

  return {
    currency: myCurrency,
    price: myPrice,
    vendor,
    status,
    vendorUser,
    vendorRating,
    vendorComments,
    isSingleTypeOrder,
    waitingLabel,
    isCourierOrder,
    ratingAvg,
    clientUser,
    clientRating,
    clientComments,
    cancelOrder,
    handleChatUser,
    finishOrder,
    handleChatToVendor,
    sendClientRating,
    sendVendorRating,
    handleSendSaveOurSouls,
    handleStatus: handleSendStatus,
  };
};

interface UOrderHistoryRes {
  orders: IOrder[];
  isLoading: boolean;
  hideOrder: (order: IOrder) => Promise<void>;
}

export const useOrdersHistory = (): UOrderHistoryRes => {
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { vendors, user, vendorId } = useAuth();

  const handleRequestOrders = useCallback(async (res: IOrder[]) => {
    const ordersReversed = res.reverse();

    if (!vendorId) {
      setOrders(ordersReversed);
      return;
    }
    const myCurrency = getCurrencyByCountry(user?.country ?? '');
    const results = await convertOrdersCurrency(ordersReversed, myCurrency);

    setOrders(results);
  }, [orders, vendorId, user]);

  const getData = async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      if (!user) return;

      if (vendors.length && vendorId) {
        const promises = vendors.map(({ id }) => getVendorFinishedOrders(id));
        const vendorsRes = (await Promise.all(promises)).flat();
        handleRequestOrders(vendorsRes);
        return;
      }

      const clientRes = await getClientFinishedOrders(user.id);
      handleRequestOrders(clientRes);
    } finally {
      setIsLoading(false);
    }
  };

  const handleHideOrder = async (order: IOrder) => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      const [response] = await hideOrder(order.id);

      if (response) {
        setOrders((prev) => {
          const updatedOrders = prev.map((item) => (
            item.id === response.id ? response : item
          ));
          return updatedOrders;
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const filteredOrders = useMemo(() => {
    const isImVendor = Boolean(vendorId);
    const key = isImVendor ? hiddenOnHistoryNameService : hiddenOnHistoryNameCustomer;
    const filtered = orders.filter((order) => !order[key]);
    return filtered;
  }, [orders, user, vendorId]);

  useEffect(() => {
    getData();
  }, [vendors, user, vendorId]);

  return { orders: filteredOrders, isLoading, hideOrder: handleHideOrder };
};

interface UMyOrdersRes {
  orders: IOrder[];
}

export const useMyOrders = (): UMyOrdersRes => {
  const { user, vendorId, setIsLoading } = useAuth();
  const {
    orders,
    handleAddClientOrders,
    handleAddVendorOrders,
  } = useOrders();

  useEffect(() => {
    const handleGetOrders = async () => {
      if (!user) return;
      const myCurrency = getCurrencyByCountry(user.country ?? '');

      setIsLoading(true);
      try {
        const clientResponse = await getClientOrder(user.id);
        const convertedClients = await convertOrdersCurrency(clientResponse, myCurrency);
        handleAddClientOrders(convertedClients);

        if (!vendorId) return;
        const vendorResponse = await getVendorOrder(vendorId);
        const converted = await convertOrdersCurrency(vendorResponse, myCurrency);
        handleAddVendorOrders(converted);
      } finally {
        setIsLoading(false);
      }
    };

    handleGetOrders();
  }, [user, vendorId]);

  return {
    orders,
  };
};

export const useUsersUuidByOrder = (): {
  usersLoading: string[];
  setOrders: (orders: IOrder[]) => Promise<void>;
} => {
  const [usersLoading, setUsersLoading] = useState<string[]>([]);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const { setIsLoading } = useAuth();

  const getUsersUuid = async (order: IOrder): Promise<string[]> => {
    try {
      setIsLoading(true);
      const [{ userUuid, vendorUserUuid }] = await getUuidByOrder(order.serviceId, order.id);
      const newUsersUuids = [userUuid, vendorUserUuid];

      return newUsersUuids;
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetOrders = async (newOrders: IOrder[]) => {
    const filteredOrders = newOrders.filter((item) => (
      !orders.some((order) => order.id === item.id)
    ));
    setOrders((prev) => [...prev, ...filteredOrders]);

    const promises = filteredOrders.map(getUsersUuid);
    const uuids = (await Promise.all(promises)).flat().filter(onlyUniquePrimitives);
    if (!uuids.length) return;

    setUsersLoading((prev: string[]) => {
      const allUuids = [...prev, ...uuids];

      const uniqueUuids = allUuids.filter(onlyUniquePrimitives);

      return uniqueUuids;
    });
  };

  return {
    usersLoading,
    setOrders: handleSetOrders,
  };
};

export const useOrdersByIds = (): {
  orders: IOrder[];
  setOrdersLoading: Dispatch<SetStateAction<number[]>>;
} => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [ordersLoading, setOrdersLoading] = useState<number[]>([]);

  useEffect(() => {
    if (loading) return;

    const handleGetOrders = async () => {
      const filteredIds = ordersLoading.filter((id) => (
        !orders.some((order) => order.id === id)
      ));

      if (!filteredIds.length) return;
      setLoading(true);

      const responses = (await Promise.all(filteredIds.map(getOrder))).flat();

      setOrders((prev) => [...prev, ...responses]);
      setLoading(false);
    };

    handleGetOrders();
  }, [ordersLoading, orders, loading]);

  return {
    orders,
    setOrdersLoading,
  };
};
