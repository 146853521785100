import { makeStyles } from '@material-ui/core';
import {
  chatBackgroundColor,
  orange,
} from '../../../constants';

export default makeStyles({
  messageDate: {
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',

    '&::after': {
      backgroundColor: orange,
      content: '""',
      top: '50%',
      bottom: '50%',
      left: 0,
      right: 0,
      height: '1px',
      position: 'absolute',
    },
  },
  messageDateText: {
    backgroundColor: chatBackgroundColor,
    padding: '0 10px',
    zIndex: 1,
  },
}, { index: 2 });
