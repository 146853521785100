import { createPortal } from 'react-dom';
import React, { ReactNode } from 'react';

export const portalsRoot = document.createElement('div');
portalsRoot.classList.add('default-portal-root');
document.body.appendChild(portalsRoot);

interface Props {
  children: ReactNode,
  node?: Element,
}

const Portal: React.FC<Props> = ({ children, node }) => createPortal(children, node || portalsRoot);

export default Portal;
