import React from 'react';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { getTranslateFunction } from 'helpers';
import { Dot, IOrder } from 'interfaces';
import useStyles from '../OrderPageStyle';
import { ORDER_STATUSES } from '../../../constants';

interface Props {
  status: number;
  order: IOrder;
  handleChange: (a: string, b: string) => void;
  myLocation: [number, number] | null;
}

const NavigationLinks: React.FC<Props> = ({
  status,
  order,
  handleChange,
  myLocation,
}: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  const NavigationRouteRef = `https://www.google.com/maps/dir/?api=1&origin=${[order.dots[0].lat, order.dots[0].lon]}&destination=${[order.dots[1].lat, order.dots[1].lon]}&dir_action=navigate&travelmode=driving`;
  const NavigationToClientRef = `https://www.google.com/maps/dir/?api=1&origin=${myLocation}&destination=${[order.dots[0].lat, order.dots[0].lon]}&dir_action=navigate&travelmode=driving`;

  const checkedNavRoute = status === ORDER_STATUSES.EXITING && order.dots.every((dot: Dot) => 'lat' in dot && 'lon' in dot);
  const checkedToClientRoute = myLocation && order.dots.every((dot: Dot) => 'lat' in dot && 'lon' in dot);

  const handleRoute = (to: string) => {
    handleChange('handleRoute', to);
  };

  return (
    <>
      {checkedNavRoute && (
        <Button
          className={classNames(classes.button, 'orange')}
          onClick={() => handleRoute(NavigationRouteRef)}
        >
          {t('NavigatiorForRoute')}
        </Button>
      )}

      {checkedToClientRoute && (
        <Button
          className={classNames(classes.button, 'green')}
          onClick={() => handleRoute(NavigationToClientRef)}
        >
          {t('NavigatorToClient')}
        </Button>
      )}
    </>
  );
};

export default NavigationLinks;
