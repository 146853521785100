export const deleteChatLabel = 'menuModal.deleteChat';
export const deleteChatAction = 'deleteChat';
export const deleteChatIcon = 'DeleteTsx';

export const blockAndDeleteChatLabel = 'menuModal.blockAndDeleteChat';
export const blockAndDeleteChatAction = 'blockAndDeleteChat';
export const blockAndDeleteChatIcon = 'LockTsx';

export const complaintAndDeleteChatLabel = 'menuModal.complaintAndDeleteChat';
export const complaintAndDeleteChatAction = 'complaintAndDeleteChat';
export const complaintAndDeleteChatIcon = 'ComplaintTsx';

export const affrontLabel = 'menuModal.affront';
export const affrontAction = 'affront';

export const spamLabel = 'menuModal.spam';
export const spamAction = 'spam';

export const anotherLabel = 'menuModal.another';
export const anotherAction = 'another';
