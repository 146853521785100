import React, { useCallback, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Box,
  Typography,
  Checkbox,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import classNames from 'classnames';
import {
  ArrowDownward,
} from '@material-ui/icons';
import CarCombobox from 'components/ConstructorForm/items/CarCombobox/CarCombobox';
import {
  UseFormMethods, Controller, useWatch,
} from 'react-hook-form';
import Input from 'sharedComponents/Input';
import numberSvg from 'images/icons/number.svg';
import carRightSvg from 'images/icons/carRight.svg';
import carLeftSvg from 'images/icons/carLeft.svg';
import carFrontSvg from 'images/icons/carFront.svg';
import technicalPassportFrontSvg from 'images/icons/technicalPassportFront.svg';
import sportBikeSvg from 'images/icons/sportBike.svg';
import truckSvg from 'images/icons/truck.svg';
import busSvg from 'images/icons/bus.svg';
import documentSvg from 'images/icons/document.svg';
import documentBackSvg from 'images/icons/backSideDocument.svg';
import { ModelItem } from 'components/ConstructorForm/items/CarCombobox/useBrands';
import { getTranslateFunction, getVendorImageLink } from 'helpers';
import { IOnChange, Vendor } from 'interfaces';
import { Step3Styles } from './material';
import DocumentItem, { IDocumentItem } from './components/DocumentItem';

type Props = Pick<UseFormMethods, 'control' | 'errors' | 'setValue' | 'clearErrors'> & {
  models: ModelItem[];
  brands: string[];
  onChangeProp: (a: IOnChange) => void;
};

const Step3: React.FC<Props> = ({
  control,
  errors,
  setValue,
  models,
  brands,
  clearErrors,
  onChangeProp,
}) => {
  const t = getTranslateFunction();
  const classes = Step3Styles();
  const [carInfoOpen, setCarInfoOpen] = useState(true);
  const [carPhotoOpen, setCarPhotoOpen] = useState(true);
  const [techPassOpen, setTechPassOpen] = useState(false);
  const [driverLicenseOpen, setDriverLicenseOpen] = useState(false);

  const handleCarInfo = () => setCarInfoOpen((prev) => !prev);
  const handleCarPhoto = () => setCarPhotoOpen((prev) => !prev);
  const handlePathPass = () => setTechPassOpen((prev) => !prev);
  const handleDriverLicense = () => setDriverLicenseOpen((prev) => !prev);
  const vendor = useWatch({ control }) as Vendor;

  const createFileControls = (items: IDocumentItem[]) => (
    items.map((item) => (
      <DocumentItem
        key={item.key}
        item={item}
        control={control}
        errors={errors}
      />
    ))
  );

  const vehicleTypeConfig = [
    {
      label: t('signInPage.sedan'),
      name: 'sedan',
      icon: carRightSvg,
    },
    {
      label: t('signInPage.minibus'),
      name: 'minibus',
      icon: busSvg,
    },
    {
      label: t('signInPage.truck'),
      name: 'truck',
      icon: truckSvg,
    },
    {
      label: t('signInPage.moto'),
      name: 'moto',
      icon: sportBikeSvg,
    },
  ];

  const vehicleServicesConfig = [
    {
      label: t('signInPage.vehicleConditioner'),
      name: 'vehicleConditioner',
    },
    {
      label: t('signInPage.abs'),
      name: 'antiLockBrakingSystem',
    },
    {
      label: t('signInPage.vehicleMultimedia'),
      name: 'vehicleRecordPlayer',
    },
    {
      label: t('signInPage.airBag'),
      name: 'airBag',
    },
    {
      label: t('signInPage.childChair'),
      name: 'babyChair',
    },
    {
      label: t('signInPage.bsp'),
      name: 'bspSystem',
    },
  ];

  const controlsConfig = {
    vehicleExterior: [
      {
        key: 'vehiclePhotoFront',
        id: 'vehiclePhotoFront',
        preview: getVendorImageLink(vendor?.vehiclePhotoFront) || carFrontSvg,
        text: 'signInPage.vehiclePhotoFront',
        rules: {
          required: {
            value: true,
            message: 'error.validation.required',
          },
        },
      },
      {
        key: 'vehiclePhotoSideLeft',
        id: 'vehiclePhotoSideLeft',
        preview: getVendorImageLink(vendor?.vehiclePhotoSideLeft) || carLeftSvg,
        text: 'signInPage.vehiclePhotoSideLeft',
        rules: {
          required: {
            value: true,
            message: 'error.validation.required',
          },
        },
      },
    ],
    driver: [
      {
        key: 'driverLicenseFront',
        id: 'driverLicenseFront',
        preview: getVendorImageLink(vendor?.driverLicenseFront) || documentSvg,
        text: 'signInPage.driverLicenseFront',
        rules: {
          required: {
            value: true,
            message: 'error.validation.required',
          },
        },
      },
      {
        key: 'driverLicenseBack',
        id: 'driverLicenseBack',
        preview: getVendorImageLink(vendor?.driverLicenseBack) || documentBackSvg,
        text: 'signInPage.driverLicenseBack',
        rules: {
          required: {
            value: true,
            message: 'error.validation.required',
          },
        },
      },
    ],
    technicalPassport: [
      {
        key: 'technicalPassportFront',
        id: 'technicalPassportFront',
        preview: getVendorImageLink(vendor?.technicalPassportFront) || technicalPassportFrontSvg,
        text: 'signInPage.technicalPassportFront',
        rules: {
          required: {
            value: true,
            message: 'error.validation.required',
          },
        },
      },
      {
        key: 'technicalPassportBack',
        id: 'technicalPassportBack',
        preview: getVendorImageLink(vendor?.technicalPassportBack) || documentBackSvg,
        text: 'signInPage.technicalPassportBack',
        rules: {
          required: {
            value: true,
            message: 'error.validation.required',
          },
        },
      },
    ],
  };

  const vehiclePhotosExteriorControls = createFileControls(
    controlsConfig.vehicleExterior,
  );
  const driverLicenceControls = createFileControls(controlsConfig.driver);
  const technicalPassportControls = createFileControls(
    controlsConfig.technicalPassport,
  );

  const handleRadio = useCallback((onChange, value) => onChange(value), []);

  return (
    <>
      <Box className={classNames(classes.label, { open: carInfoOpen })} onClick={handleCarInfo}>
        <Typography variant="h6">
          {t('signInPage.vehicleInfoHeading')}
        </Typography>
        <Box className={classNames(classes.arrow, { open: carInfoOpen })}>
          <ArrowDownward />
        </Box>
      </Box>

      <Box className={classNames(classes.box, { open: carInfoOpen })}>
        <Controller
          name="vehicleType"
          control={control}
          render={({ value, onChange }) => (
            <div className="required">
              <RadioGroup
                className={classes.carBodyStyle}
                value={value}
                onChange={onChange}
              >
                {vehicleTypeConfig.map(({ label, name, icon }) => (
                  <Box className={classes.carBodyItem} key={name}>
                    <FormControlLabel
                      className={classes.radioLabel}
                      value={name}
                      control={(
                        <Radio
                          className={classes.radioItem}
                          icon={<div className={classes.icon} />}
                          checkedIcon={<div className={classes.checkedIcon} />}
                        />
                      )}
                      label={label}
                    />
                    <img src={icon} alt={name} className={classes.bodySvg} />
                  </Box>
                ))}
              </RadioGroup>

              <FormHelperText>{errors.vehicleType?.message}</FormHelperText>
            </div>
          )}
        />

        <Box className={classNames(classes.radioContainerStyle, 'border')}>
          {vehicleServicesConfig.map(({ name, label }) => (
            <Controller
              control={control}
              key={name}
              name={name}
              defaultValue={false}
              render={({ onChange, value }) => (
                <Box className={classes.radioBox}>
                  <FormControlLabel
                    className={classes.radioLabel}
                    control={(
                      <Checkbox
                        className={classes.radioItem}
                        checked={Boolean(value)}
                        color="default"
                        icon={<div className={classes.icon} />}
                        checkedIcon={<div className={classes.checkedIcon} />}
                        onChange={(e) => handleRadio(onChange, e.target.checked)}
                      />
                    )}
                    label={label}
                  />
                </Box>
              )}
            />
          ))}
        </Box>

        <CarCombobox
          errors={errors}
          control={control}
          classes={classes}
          mode="register"
          setValue={setValue}
          models={models}
          brands={brands}
          onChange={onChangeProp}
          clearErrors={clearErrors}
        />

        <FormControl className={classNames(classes.formControl, 'required')} error={!!errors.vehicleNumber}>
          <Controller
            name="vehicleNumber"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error.validation.required'),
              },
              pattern: {
                value: /^[a-zA-Z0-9]*$/g,
                message: t('error.validation.invalidValue'),
              },
              minLength: {
                value: 5,
                message: t('error.validation.minLength'),
              },
              maxLength: {
                value: 15,
                message: t('error.validation.maxLength'),
              },
            }}
            render={({ value, onChange }) => (
              <Input
                className={classNames(
                  classes.textField,
                  classes.vehicleNumberField,
                )}
                placeholder={t('signInPage.vehicleNumber')}
                type="text"
                value={value}
                onChange={({ target }) => {
                  onChange(target.value);
                }}
                errorMessage={errors.vehicleNumber?.message}
                disableUnderline
                startAdornment={<img src={numberSvg} alt={t('signInPage.vehicleNumber')} className={classes.iconAdornment} />}
              />
            )}
          />
        </FormControl>
      </Box>

      <Box className={classes.boxCenter}>
        <Box className={classNames(classes.label, { open: carPhotoOpen })} onClick={handleCarPhoto}>
          <Typography variant="h6">
            {t('signInPage.vehiclePhotoHeading')}
          </Typography>
          <Box className={classNames(classes.arrow, { open: carPhotoOpen })}>
            <ArrowDownward />
          </Box>
        </Box>

        <Box className={classNames(classes.box, { open: carPhotoOpen })}>
          {vehiclePhotosExteriorControls}
        </Box>

        <Box
          className={classNames(classes.label, { open: driverLicenseOpen })}
          onClick={handleDriverLicense}
        >
          <Typography variant="h6">
            {t('signInPage.drivingLicenceLabel')}
          </Typography>
          <Box className={classNames(classes.arrow, { open: driverLicenseOpen })}>
            <ArrowDownward />
          </Box>
        </Box>
        <Box className={classNames(classes.box, { open: driverLicenseOpen })}>
          {driverLicenceControls}
        </Box>

        <Box className={classNames(classes.label, { open: techPassOpen })} onClick={handlePathPass}>
          <Typography variant="h6">
            {t('signInPage.technicalPassportLabel')}
          </Typography>
          <Box className={classNames(classes.arrow, { open: techPassOpen })}>
            <ArrowDownward />
          </Box>
        </Box>
        <Box className={classNames(classes.box, { open: techPassOpen })}>
          {technicalPassportControls}
        </Box>
      </Box>
    </>
  );
};

export default Step3;
