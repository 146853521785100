import { UseFormMethods } from 'react-hook-form';
import { FormControl } from '@material-ui/core';
import Input from 'sharedComponents/Input';
import userSvg from 'images/icons/user.svg';
import { getTranslateFunction } from 'helpers';
import { alphanumericWithCharactersRegExp } from '../../../constants';
import VerificationInputStatus from './VerificationInputStatus';
import { GetIsVerifiedInput } from '../containers/UserInfo';
import useStyles from '../ProfilePageStyle';

type Fields = {
  firstName: string;
  lastName: string;
  patronymic: string;
};

type FieldSchema = {
  key: keyof Fields;
  placeholder: string;
  imageAlt: string;
  patternMsg: string;
  required: boolean;
};

const NAME_FIELDS: FieldSchema[] = [
  {
    key: 'firstName',
    placeholder: 'profilePage.nameLabel',
    imageAlt: 'nameLabel',
    patternMsg: 'NameWithCharacters',
    required: true,
  },
  {
    key: 'lastName',
    placeholder: 'profilePage.lastNameLabel',
    imageAlt: 'lastNameLabel',
    patternMsg: 'SurnameWithCharacters',
    required: true,
  },
  {
    key: 'patronymic',
    placeholder: 'profilePage.patronymicLabel',
    imageAlt: 'patronymicLabel',
    patternMsg: 'PatronymicWithCharacters',
    required: false,
  },
];

type UseFormMethodsProps = Pick<UseFormMethods<Fields>, 'register' | 'errors' | 'clearErrors'>;
type GetIsVerifiedInputProps = { getIsVerifiedInput: GetIsVerifiedInput };

type Props = UseFormMethodsProps & GetIsVerifiedInputProps;

const NameInputs = ({ register, clearErrors, errors, getIsVerifiedInput }: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  return (
    <>
      {NAME_FIELDS.map(({ key, placeholder, imageAlt, patternMsg, required }) => (
        <VerificationInputStatus isVerified={getIsVerifiedInput(key)} key={key}>
          <FormControl className={classes.formControl} error={Boolean(errors[key])}>
            <Input
              className={classes.textField}
              autoComplete='new-password'
              name={key}
              placeholder={t(placeholder)}
              readOnly={required && getIsVerifiedInput(key)}
              disableUnderline
              errorMessage={errors[key]?.message}
              onFocus={() => clearErrors(key)}
              startAdornment={
                <img
                  src={userSvg}
                  alt={t(`profilePage.${imageAlt}`)}
                  className={classes.iconAdornment}
                />
              }
              inputRef={register({
                required: {
                  value: Boolean(required),
                  message: t('error.validation.required'),
                },
                maxLength: {
                  value: 80,
                  message: t('error.validation.maxLength'),
                },
                pattern: {
                  value: alphanumericWithCharactersRegExp,
                  message: t(`error.validation.${patternMsg}`),
                },
              })}
            />
          </FormControl>
        </VerificationInputStatus>
      ))}
    </>
  );
};

export default NameInputs;
