import { makeStyles } from '@material-ui/core';

export default makeStyles({
  pageContainer: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  logoStyle: {
    width: '100%',
    maxWidth: '200px',
  },
  logoContainer: {
    textAlign: 'center',
    margin: '20px 0',
  },
  title: {
    textAlign: 'center',
    fontSize: '30px',
    marginBottom: '20px',
  },
  text: {
    textAlign: 'justify',
  },
}, { index: 1 });
