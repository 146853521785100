import React, { ChangeEvent } from 'react';
import CollapseBox from 'components/CollapseComponent';
import {
  FormControlLabel,
  Radio,
  Box,
  RadioGroup,
} from '@material-ui/core';
import { IOnChange } from 'interfaces';
import { getTranslateFunction } from 'helpers';
import useStyles from '../SettingsPageStyle';

type Props = {
  notificationSound: string;
  onChange: (a: IOnChange) => void;
};

const notificationsVariants = [
  {
    value: 'without',
    label: 'settings.notification.withoutSound',
  },
  {
    value: 'notification1',
    label: 'settings.notification.notification1',
  },
  {
    value: 'notification2',
    label: 'settings.notification.notification2',
  },
  {
    value: 'notification3',
    label: 'settings.notification.notification3',
  },
];

const NotificationSound: React.FC<Props> = ({
  notificationSound,
  onChange,
}) => {
  const t = getTranslateFunction();
  const classes = useStyles();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      action: 'setNotificationSound',
      data: e.target.value,
    });
  };

  return (
    <CollapseBox title={t('settings.notificationSound.title')} withoutMargins>
      <RadioGroup value={notificationSound} onChange={handleChange}>
        {notificationsVariants.map((radioItem) => (
          <FormControlLabel
            key={radioItem.value}
            labelPlacement="start"
            className={classes.radio}
            value={radioItem.value}
            control={(
              <Radio
                className={classes.radioButton}
                checkedIcon={<Box className={classes.checkedIcon} />}
                icon={<Box className={classes.icon} />}
              />
            )}
            label={t(radioItem.label)}
          />
        ))}
      </RadioGroup>
    </CollapseBox>
  );
};

export default NotificationSound;
