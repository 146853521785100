import React from 'react';
import classNames from 'classnames';
import preloader from '../common/preloader.svg';
import useStyles from './PreloaderStyle';

interface Props {
  alwaysOnTop?: boolean;
}

const Preloader: React.FC<Props> = ({ alwaysOnTop = true }) => {
  const classes = useStyles();

  const logoContainerClasses = classNames(classes.logoContainer, {
    [classes.alwaysOnTop]: alwaysOnTop,
  });

  return (
    <div className={logoContainerClasses}>
      <img className={classes.img} src={preloader} data-preloader='logo' alt='Preloader' />
    </div>
  );
};

export default Preloader;
