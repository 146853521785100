import { makeStyles } from '@material-ui/core/styles';
import {
  backgroundColor,
  cyan,
  grayBG,
  green,
  orange,
  red,
  textColor,
} from '../../constants';

export default makeStyles({
  link: {
    color: backgroundColor,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    '& > .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  svg: {
    width: '20px',
    height: 'auto',
    margin: '5px',
    '&.star': {
      color: orange,
    },
  },
  orderInfoBtn: {
    backgroundColor: green,
    color: backgroundColor,
    padding: '5px 10px',
    boxSizing: 'border-box',
  },
  orderInfo: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10px',
    alignItems: 'center',
    '& > *': {
      width: '100%',
      textAlign: 'center',
      fontSize: '16px',
      marginTop: '5px',
    },
  },
  orderContainer: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  offerLayout: {
    textAlign: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: (height) => (`calc(${height}px - 120px)`),
  },
  preorderContainer: {
    display: 'flex',
    fontSize: '10px',
    width: '100%',
    flexDirection: 'column',
  },
  mapContainer: {
    width: '100%',
    height: '100%',
  },
  buttonContainer: {
    padding: '5px',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
  button: {
    transition: '0.3s',
    textAlign: 'center',
    width: '100%',
    marginTop: '5px',
    color: backgroundColor,
    fontSize: '13px',
    textTransform: 'none',
    padding: '5px 20px',
    boxSizing: 'border-box',
    '&.green': {
      backgroundColor: green,
    },
    '&.cyan': {
      backgroundColor: cyan,
    },
    '&.active': {
      backgroundColor: orange,
    },
    '&.orange': {
      backgroundColor: orange,
    },
    '&.red': {
      backgroundColor: red,
    },
    '&.isDisabled': {
      opacity: 0.5,
    },
  },
  passengers: {
    color: green,
    fontWeight: 'bold',
    marginLeft: '5px',
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  timer: {
    border: `2px ${cyan} solid`,
    color: cyan,
    '&.ended': {
      animation: '$timerLeft 2000ms infinite',
    },
  },
  '@keyframes timerLeft': {
    '50%': {
      border: `2px ${red} solid`,
      color: red,
    },
  },
  clientBtn: {
    padding: '5px',
    fontSize: '13px',
    fontWeight: 'bold',
    height: 'fit-content',
    textTransform: 'none',
    textAlign: 'center',
    margin: '2px 0',
    color: backgroundColor,
    '&.red': {
      backgroundColor: red,
    },
    '&.orange': {
      backgroundColor: orange,
    },
    '&.cyan': {
      backgroundColor: cyan,
    },
    '&.sos': {
      backgroundColor: red,
      width: 'fit-content',
    },
  },
  iconBtn: {
    width: '30px',
    height: '50px',
    padding: '0',
    '& svg': {
      width: '30px',
      height: 'auto',
    },
    '& img': {
      width: '30px',
      height: 'auto',
    },
    '&.phone': {
      marginTop: '8px',
    },
  },
  services: {
    paddingRight: '15px',
    borderRight: `1px ${textColor} solid`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  preorderSettings: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    margin: '10px 0',
    backgroundColor: grayBG,
    borderTop: `2px ${orange} solid`,
    borderBottom: `2px ${orange} solid`,
    padding: '5px 0',
  },
  info: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  ratingBox: {
    height: (height) => (`calc(${height}px - 120px)`),
    marginBottom: '60px',
    display: 'flex',
    padding: '0 10px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  sendRating: {
    color: backgroundColor,
    backgroundColor: green,
    marginTop: '10px',
    width: '100%',
  },
  ratingItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: '20px',
    '& .MuiTypography-root': {
      fontSize: '15px',
    },
  },
  ratingTitle: {
    backgroundColor: green,
    color: backgroundColor,
    width: '100%',
    textAlign: 'center',
    marginTop: '5px',
    '&.cyan': {
      backgroundColor: cyan,
      cursor: 'pointer',
    },
  },
  numberBox: {
    backgroundColor: grayBG,
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
    '& > *': {
      marginLeft: '5px',
      padding: '5px',
    },
  },
  reportMessage: {
    width: '100%',
    backgroundColor: grayBG,
    marginTop: '10px',
    padding: '5px',
  },
  maxLabel: {
    color: green,
    fontWeight: 'bold',
  },
  statsList: {
    display: 'flex',
    '& .MuiTypography-root': {
      fontSize: '13px',
    },
    '& > *': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  titleCommentsBox: {
    display: 'flex',
    alignItems: 'center',
  },
  titleComments: {
    marginLeft: '15px',
  },
  preorderServices: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingLeft: '10px',
  },
  withBorder: {
    '& .MuiPaper-root': {
      border: `4px ${green} solid`,
      boxShadow: '0px 11px 29px -7px rgb(0 0 0 / 30%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
    },
  },
  userContainerBuyForm: {
    display: 'flex',
    alignItems: 'center',
  },
  userStats: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    '& > *': {
      width: 'auto',
    },
  },
  userName: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: textColor,
  },
  userContainer: {
    width: 'fit-content',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '5px',
  },
  userPhoto: {
    minWidth: '50px',
    minHeight: '50px',
  },
  ratingOrderInfoBox: {
    width: '100%',
  },
}, { index: 1 });
