import { makeStyles } from '@material-ui/core/styles';
import {
  backgroundColor,
  grayBG,
  green,
  red,
} from '../../constants';

export default makeStyles({
  page: {},
  contentWrapper: {
    padding: '0 20px',
  },
  title: {
    margin: '20px 0 ',
    backgroundColor: grayBG,
    padding: '10px 20px',
  },
  warningTitle: {
    color: red,
  },
  sosRouteItem: {
    width: '100%',
    margin: '15px 0',
    display: 'flex',
  },
  sosRouteConfirmed: {
    backgroundColor: green,
    color: green,
    display: 'flex',
    margin: '15px 0',
    width: '100%',
  },
  itemNumber: {
    backgroundColor: grayBG,
    padding: '5px 10px',
    marginRight: '5px',
  },
  textField: {
    backgroundColor: grayBG,
    border: 'none',
    fontSize: '1rem',
    outline: 'none',
    padding: '0 10px',
    width: '100%',
  },
  addBtn: {
    width: '25px',
    height: '25px',
    padding: 0,
    minWidth: '25px',
    marginBottom: '15px',
    backgroundColor: green,
    color: backgroundColor,
    '&:hover': {
      backgroundColor: `${green}60`,
    },
    '& .MuiButton-label': {
      display: 'flex',
    },
  },
  submitBtn: {
    color: green,
    marginBottom: '20px',
    width: '100%',
  },
  removeBtn: {
    backgroundColor: grayBG,
    color: green,
    width: '40px',
  },
  sosIcon: {
    maxWidth: '200px',
    display: 'block',
    margin: '0 auto',
  },
  sosExample: {
    backgroundColor: grayBG,
    padding: '10px',
  },
}, { index: 1 });
