/* eslint-disable @typescript-eslint/naming-convention */
import axios from 'axios';
import { LatLngTuple } from 'leaflet';
import i18next from 'i18next';
import { getEnv } from 'helpers';
import { IAddress, Suggestion } from 'interfaces';

const languageParams = (lng: string) => {
  const language = (item: string) => {
    switch (item) {
      case 'rus': return 'ru-RU, ru;';
      case 'eng': return 'en-US;';
      case 'ukr': return 'uk-UA;';
      case 'tur': return 'tr-TR;';
      case 'aze': return 'az-AZ;';
      case 'geo': return 'ka';
      case 'deu': return 'de';
      case 'ita': return 'it';
      case 'fre': return 'fr-FR;';
      case 'spa': return 'es-ES;';

      default:
        return 'ua';
    }
  };
  return { 'Accept-Language': language(lng) };
};

const axiosInstanceHeaders = (params: Record<string, string>) => axios.create({
  headers: {
    ...params,
  },
});

export const geocode = async (
  address: string,
  ...args: (string | undefined)[]
): Promise<Suggestion[]> => {
  const [countryCode, city] = args;

  const instance = axiosInstanceHeaders({ ...languageParams(i18next.languages[0]) });
  const { REACT_APP_NOMINATIM_HOST } = getEnv();
  const cityQuery = city ? `&city=${city}`: '';
  const countryCodeQuery = countryCode ? `&countrycodes=${countryCode}`: '';

  const queryString = args.length
    ? `street=${address}${cityQuery}${countryCodeQuery}`
    : `q=${address}`;

  const { data } = await instance.get<Suggestion[]>(
    `${REACT_APP_NOMINATIM_HOST}/search?${queryString}&format=json&addressdetails=1&limit=5`,
  );

  if (!data.length && args.length) return geocode(address, ...args.slice(0, -1));

  return data;
};

interface ReverseGeocodeOptions {
  limit?: number;
  addressdetails?: number;
  lang?: string;
}

export const reverseGeocode = async (
  coords: LatLngTuple,
  options?: ReverseGeocodeOptions,
): Promise<Suggestion> => {
  const { limit = 5, addressdetails = 1, lang } = options || {};
  const [lat, lng] = coords;
  const instance = axiosInstanceHeaders({ ...languageParams(lang ?? i18next.languages[0]) });
  const { REACT_APP_NOMINATIM_HOST } = getEnv();

  const response = await instance.get<Suggestion>(
    `${REACT_APP_NOMINATIM_HOST}/reverse?lat=${lat}&lon=${lng}&addressdetails=${addressdetails}&limit=${limit}&format=json`,
  );
  return response.data;
};

export function getCountryCodeByCoords(coords: LatLngTuple): Promise<string> {
  return reverseGeocode(coords).then(
    (res) => res.address.country_code,
  );
}

export function formatAddress(address: IAddress, type?: string): [string, string] {
  const {
    country,
    state,
    city,
    town,
    village,
    road,
    house_number,
    shop,
    suburb,
    amenity,
    building,
    office,
    railway,
    industrial,
  } = address;

  const addressLabel = [
    building,
    office,
    shop,
    suburb,
    railway,
    amenity,
    industrial,
    road,
    house_number,
  ].filter(Boolean).join(', ');

  const cityLabel = [
    village,
    city || town,
    state,
    country,
  ];

  if (type) {
    switch (type) {
      case 'inCity':
        cityLabel.pop();
        cityLabel.pop();
        cityLabel.pop();
        break;
      case 'betweenCity':
        cityLabel.pop();
        cityLabel.pop();
        break;
      default:
        break;
    }
  }

  return [addressLabel, cityLabel.filter(Boolean).join(', ')];
}
