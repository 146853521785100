import React from 'react';
import { IconComponentProps } from 'interfaces';

const Security: React.FC<IconComponentProps> = ({ color, width, height }) => (
  <svg
    version="1.1"
    width={width}
    height={height}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 72"
  >
    <path
      fill={color}
      d="M24.48 23.75l-5.7 5.7L30.23 40.9l-.01.02 5.7 5.7.01-.02.02.02 5.7-5.7-.02-.02 22.93-22.92-5.7-5.7L35.93 35.2z"
    />
    <path
      fill={color}
      d="M54.71 40.43C51.94 51.85 40.73 60.18 35.5 63.44c-5.23-3.26-16.35-10.85-19.12-22.27-2.75-11.32-3.22-19.79-3.32-22.84L35.5 9.61l15.55 5.94 4.27-4.27L35.65 3.5 7.56 14.61l-.1 2.03c-.02.38-.43 9.52 3.56 25.94 3.44 14.16 16.03 21.95 22.8 26.14l1.83 1.14 1.84-1.14c6.76-4.18 19.35-11.97 22.79-26.14 2.01-8.27 2.9-14.4 3.29-18.79l-6.67 6.59c-.49 2.98-1.25 6.2-2.19 10.05z"
    />
  </svg>
);

export default Security;
