import { Box, Button, FormHelperText } from '@material-ui/core';
import classNames from 'classnames';
import { Close } from '@material-ui/icons';
import useStyles from './styles';

interface Props {
  message: string;
  onClose: () => void;
}

const InfoMessage = ({ message, onClose }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.infoMessageWrapper}>
      <FormHelperText className={classNames(classes.infoMessage, 'MuiFormHelperText-root', 'Mui-error')}>
        {message}
      </FormHelperText>

      <Button onClick={onClose} color="secondary" variant="text" className={classes.infoMessageBtn}>
        <Close />
      </Button>
    </Box>
  );
};

export default InfoMessage;
