import { ReactNode } from 'react';
import useStyles from './ExternalLinkStyles';

interface IExternalLinkProps {
  to: string;
  children: ReactNode
}
const ExternalLink = ({ to, children }:IExternalLinkProps) => {
  const classes = useStyles();

  return (
    <a href={to} target="_blank" rel="noopener noreferrer" className={classes.externalLink}>
      {children}
    </a>
  );
};

export default ExternalLink;
