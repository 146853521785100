import React, { FC } from 'react';

interface Props {
  color: string | undefined;
}

const Password: FC<Props> = ({ color }): any => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 72 72"
  >
    <path fill={color} d="M10.52,27.48v42.55h52.82V27.48H10.52z" />
    <path
      fill={color}
      d="M24.06,24.79v-3.08c0-7.1,5.77-12.87,12.87-12.87S49.8,14.61,49.8,21.71v3.08h6.52v-3.08
      c0-10.69-8.7-19.4-19.4-19.4s-19.39,8.7-19.39,19.4v3.08H24.06z"
    />
  </svg>
);

export default Password;
