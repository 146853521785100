import { useCallback } from 'react';
import { RingtoneType } from 'interfaces';
import { useSettings } from 'pages/SettingsPage/SettingsContext';
import notification1 from 'sound/notification1.wav';
import notification2 from 'sound/notification2.wav';
import notification3 from 'sound/notification3.wav';

const audios = {
  notification1,
  notification2,
  notification3,
  without: '',
};

export const createSound = (ringtone: RingtoneType): HTMLAudioElement => {
  const audio = audios[ringtone];

  const sound = new Audio(audio);

  return sound;
};

interface PlaySoundParams {
  force: boolean;
}

export const soundModule = (): {
  playSound: (options?: PlaySoundParams) => void;
} => {
  const { pushNotificationSound, ringtoneType } = useSettings();

  const createInstance = useCallback(() => {
    const sound = createSound(ringtoneType);

    return {
      playSound: (options?: PlaySoundParams) => {
        const { force } = options || {};
        if (!force && !(pushNotificationSound && sound)) return;
        sound.play().catch(() => {});
      },
    };
  }, [pushNotificationSound, ringtoneType]);

  return createInstance();
};
