import { Box } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import classNames from 'classnames';
import React, { useState } from 'react';
import useStyles from './styles';

type Props = {
  children: React.ReactNode;
  isExpandedByDefault?: boolean;
};

const CollapsedMenu: React.FC<Props> = ({
  children,
  isExpandedByDefault = false,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(() => isExpandedByDefault);

  const toggleExpanded = () => setExpanded((prev) => !prev);

  return (
    <Box>
      <Box className={classNames(classes.collapseContainer, {
        [classes.collapseContainerOpen]: expanded,
      })}
      >
        <Box
          onClick={toggleExpanded}
          className={classes.collapseButton}
        >
          <MoreVert />
        </Box>
        <Box className={classes.buttonsContainer}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default CollapsedMenu;
