import React from 'react';
import CollapseBox from 'components/CollapseComponent';
import { Box } from '@material-ui/core';
import TableVertical from 'sharedComponents/Table/components/TableVertical';
import icons from 'components/Icons';
import { getTranslateFunction } from 'helpers';
import { ArchiveItem } from 'interfaces';
import useStyles from '../PaymentsPageStyle';
import { cyan } from '../../../constants';

interface Props {
  values: ArchiveItem[];
}

const {
  archiveTsx,
} = icons;

const Archive: React.FC<Props> = ({
  values,
}: Props) => {
  const t = getTranslateFunction();
  const classes = useStyles();

  return (
    <CollapseBox
      title={t('PaymentsPage.archive')}
      Icon={archiveTsx}
    >
      {values.map((item: ArchiveItem) => {
        const formatItem = Object.fromEntries(
          Object.entries(item)
            .map(([key, value]) => ([t(`PaymentsPage.securityPayment.${key}`), value])),
        );

        return (
          <Box className={classes.incomeTable} key={item.transactionNumber}>
            <TableVertical
              tableConfig={formatItem}
            />
            <hr color={cyan} className={classes.hr} />
          </Box>
        );
      })}
    </CollapseBox>
  );
};

export default Archive;
