import { makeStyles } from '@material-ui/core/styles';
import { grayBG } from '../../constants';

export default makeStyles({
  logoContainer: {
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    backgroundColor: `${grayBG}40`,
    height: '100%',
  },
  alwaysOnTop: {
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    backgroundColor: `${grayBG}40`,
    height: '100%',
    zIndex: 10001,
  },
  img: {
    width: '250px',
  },
}, { index: 1 });
