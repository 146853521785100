import React, {
  useMemo, useState,
} from 'react';
import {
  Avatar,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { Star } from '@material-ui/icons';
import { getBase64FromFile, getTranslateFunction, getUserImageLink } from 'helpers';
import {
  RatingType,
  User,
  Preorder,
  PreorderService,
} from 'interfaces';
import OfferBuyForm from '../components/OfferBuyForm';

interface Photo {
  id: number;
}

interface Props {
  classes: { [key: string]: string };
  preorder: Preorder;
  user: User;
  userRating: RatingType | null;
  submitBtnText: string;
  vendorId: number;
  vendorUuid: string | null;
  vendorUserUuid: string;
  preorderServiceId: number;
  vendorLocation: [number, number] | null;
  onChange: (a: string, b?: any) => void;
  isMyOrder: boolean;
}

const VendorOfferBuyForm: React.FC<Props> = ({
  classes,
  preorder,
  user,
  userRating,
  submitBtnText,
  onChange,
  vendorId,
  vendorUuid,
  vendorUserUuid,
  preorderServiceId,
  vendorLocation,
  isMyOrder,
}: Props) => {
  const t = getTranslateFunction();
  const { register, handleSubmit, control, errors } = useForm({
    defaultValues: {
      price: preorder.price,
    },
  });
  const [photos, setPhotos] = useState<Photo[]>([]);

  const toDTO = async (data: PreorderService) => {
    const {
      files, price, vendorComment, arrivalDateTime,
    } = data;
    let base64Files: string[] = [];

    if (files) {
      const result = await Promise.all(Object.values(files).filter(Boolean)
        .map(([file]: any) => file && getBase64FromFile(file)));

      base64Files = result.filter(Boolean);
    }

    const preorderService: PreorderService = {
      ...preorder,
      price,
      vendorUuid,
      vendorComment,
      vendorUserUuid,
      arrivalDateTime,
      serviceId: vendorId,
      location: vendorLocation,
      preorderServicePhotos: base64Files,
    };

    if (!base64Files.length) {
      delete preorderService.preorderServicePhotos;
    }

    return preorderService;
  };

  const onSubmit = async (data: PreorderService) => {
    if (preorderServiceId) return;

    const preorderService = await toDTO(data);

    onChange('createPreorderService', preorderService);
  };

  const photoSrc = useMemo(() => getUserImageLink(user?.userPhoto, user?.gender), [user]);

  return (
    <Box className={classes.OfferBuyFormContainer}>
      <Box className={classes.userContainerBuyForm}>
        <Box className={classes.userContainer}>
          <Box className={classes.userName}>{user?.firstName}</Box>
          <Avatar variant="rounded" className={classes.userPhoto} alt={user?.firstName} src={photoSrc} />
        </Box>
        <Box className={classes.userStats}>
          {userRating && (
          <Box className={classes.listItem}>
            <Star className={classNames(classes.svg, 'star')} />
            <Typography>{Math.floor(Number(userRating.politenessRatingAvg))}</Typography>
          </Box>
          )}
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <OfferBuyForm
          register={register}
          preorder={preorder}
          classes={classes}
          control={control}
          photos={photos}
          setPhotos={setPhotos}
          isMyOrder={isMyOrder}
          errors={errors}
        />
        <Box className={classes.mainBtns}>
          <Button
            onClick={() => onChange('handleGoBack')}
            className={classNames(classes.button, 'green')}
          >
            {t('offer.goBack')}
          </Button>

          {!isMyOrder && (
            <Button
              type="submit"
              disabled={Boolean(preorderServiceId)}
              className={classNames(classes.button, 'orange')}
            >
              {submitBtnText}
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default VendorOfferBuyForm;
