import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
} from '@material-ui/core';
import Gallery from 'sharedComponents/Gallery/Gallery';
import React, { useMemo, useState } from 'react';
import { PhotoCamera } from '@material-ui/icons';
import {
  RatingType,
  IOrder,
  User,
  PreorderService,
} from 'interfaces';
import Icons from 'components/Icons';
import { getTranslateFunction, getUserImageLink } from 'helpers';
import { dateFormatter, imagesSrcFormatter } from 'utils/formatters';
import useStyles from './BuyThingsPreorderListItemStyle';
import { cyan, orange } from '../../../../constants';

interface Props {
  order: PreorderService | IOrder;
  user: User;
  ratingAvg: number;
  userRating: RatingType | undefined;
  commentsLength: number;
}

const {
  CommentTsx,
  toTimeSvg,
  StarTsx,
} = Icons;

const BuyThingsPreorderListItem: React.FC<Props> = ({
  order,
  user,
  ratingAvg,
  userRating,
  commentsLength,
}: Props) => {
  const t = getTranslateFunction();
  const classes = useStyles();
  const [showFullText, setShowFullText] = useState(false);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const photoSrc = useMemo(() => (getUserImageLink(user?.userPhoto, user?.gender)), [user]);

  const handleShowModalPhoto = () => setShowPhotoModal((prev) => !prev);
  const handleShowText = () => setShowFullText((prev) => !prev);

  const photoArray = useMemo(() => {
    const { preorderServicePhotos } = order;

    return imagesSrcFormatter(preorderServicePhotos);
  }, [order]);

  const tripTimeText = useMemo(() => (
    order.tripTime === 'now' ? t('preorder.modal.nowLabel') : dateFormatter(Number(new Date(order.tripTime)))
  ), [order]);

  if (!user) return null;

  const politenessRatingAvg = Math.round(Number(userRating?.politenessRatingAvg));
  const diligenceRatingAvg = Math.round(Number(userRating?.diligenceRatingAvg));

  return (
    <Box className={classes.preorderServiceContainer}>
      <Box className={classes.preorderServiceBuyItem}>
        <Box className={classes.offerContainer}>
          <Box>
            <Box className={classes.preorderInfo}>
              <Box>
                <Typography>{user.firstName}</Typography>
                <Avatar className={classes.userPhoto} title={user.firstName} src={photoSrc} variant="rounded" />
              </Box>
              <Box>
                <Box className={classes.listItem}>
                  <Box className={classes.listItem}>
                    <Box className={classes.svg}>
                      <StarTsx color={orange} />
                    </Box>
                    {ratingAvg}
                  </Box>
                  <Box className={classes.listItem}>
                    <Box className={classes.svg}>
                      <CommentTsx color={cyan} />
                    </Box>
                    {commentsLength}
                  </Box>
                </Box>
                <Box className={classes.offerInfo}>
                  <Box className={classes.listItem}>
                    <img src={toTimeSvg} alt={t('preorder.modal.toTimeLabel')} className={classes.svg} />
                    <Typography>{tripTimeText}</Typography>
                  </Box>
                  {Boolean(photoArray) && (
                    <Box className={classes.listItem}>
                      <PhotoCamera className={classes.svg} />
                      <Typography>{photoArray.length}</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            {order.vendorComment && (
              <Box className={classes.listItem}>
                <Box className={classes.svg}>
                  <CommentTsx color={cyan} />
                </Box>
                <Typography className={classes.offerComment} noWrap={!showFullText}>
                  {order.vendorComment}
                </Typography>
              </Box>
            )}
          </Box>
          <Box className={classes.btnsContainer}>
            <Button className={classes.priceBox}>{`${order.price} ${order.currency}`}</Button>
            <Box>
              {Boolean(order.preorderServicePhotos) && (
                <Button className={classes.showBtn} onClick={handleShowModalPhoto}>
                  {t('preorder.modal.show')}
                </Button>
              )}
              {order.vendorComment && (
                <Button className={classes.showBtn} onClick={handleShowText}>
                  {t(`preorder.modal.${showFullText ? 'hide' : 'show'}`)}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Box className={classes.ratingBox}>
          {Boolean(userRating?.politenessRatingCount) && (
            <Box className={classes.ratingListItem}>
              <Typography>{t('ClientOrderPage.politeness')}</Typography>
              <Typography variant="body2">{politenessRatingAvg}</Typography>
            </Box>
          )}
          {Boolean(userRating?.diligenceRatingCount) && (
            <Box className={classes.ratingListItem}>
              <Typography>{t('ClientOrderPage.diligence')}</Typography>
              <Typography variant="body2">{diligenceRatingAvg}</Typography>
            </Box>
          )}
        </Box>
        <Dialog open={showPhotoModal} onClose={handleShowModalPhoto}>
          <DialogContent>
            <Gallery images={photoArray} />
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default BuyThingsPreorderListItem;
