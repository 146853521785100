import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router';
import { AxiosRequestConfig } from 'axios';
import socket from 'socket';
import { soundModule } from 'services/soundService';
import { useAuth } from 'services/auth';
import {
  getErrorMessage,
  getUniqueItemsObjects,
} from 'helpers';
import {
  createChatRoom,
  getMessages,
  getMyChats,
  getUsers,
  setReadMessages,
  banUserInChat,
  deleteChat,
  isMeBannedByUser,
  getBannedUsers,
  getChatReplyUuid,
  getMessagesFromSupportChat,
  deleteSupportChat,
  getSupportBotIdentificator,
  setReadSupportMessages,
  getUuidByOrder,
} from 'services/commonService';
import { useOrders } from 'services/orders';
import {
  BannedUser,
  ChatOptions,
  Complaint,
  ComplaintToSend,
  IChat,
  IMessage,
  IMessageSend,
  IOrder,
  IUser,
  UUIDs,
  User,
} from 'interfaces';
import { configureOrderChat, getTypesFromOrder } from 'pages/Chat/helpers';
import RequestService from 'services/requestApi';
import { CHAT_LIST_SORT_INDEX, ERROR_MSG_CHAT_BANNED } from '../constants';

export const useOrderChats = (): {
  orderChats: IChat[];
  isLoading: boolean;
} => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { orders } = useOrders();
  const { user } = useAuth();
  const [orderChats, setOrderChats] = useState<IChat[]>([]);

  useEffect(() => {
    if (!user || isLoading) return;

    const configOrder = async () => {
      const filteredOrders = orders.filter((order) => (
        !orderChats.some(({ orderIds }) => orderIds.includes(order.id))
      ));

      if (!filteredOrders.length) return;
      setIsLoading(true);
      const formattedOrdersPromises = filteredOrders.map((order) => (
        configureOrderChat(order, user)
      ));
      const formattedOrders = await Promise.all(formattedOrdersPromises);

      setOrderChats((prev) => {
        const chatsWithOrders = formattedOrders.reduce((acc, orderChat) => {
          const existChat = acc.find(({ user: chatUser }) => orderChat.user.uuid === chatUser.uuid);
          const isOrderChatIncludes = existChat?.orderIds.includes(Number(orderChat.orderIds));

          if (isOrderChatIncludes) return acc;

          if (existChat) {
            existChat.orderIds.push(...orderChat.orderIds);
            existChat.orderType.push(...orderChat.orderType);
          } else {
            acc.push(orderChat);
          }

          return acc;
        }, prev as IChat[]);

        return [...chatsWithOrders];
      });
      setIsLoading(false);
    };

    configOrder();
  }, [orders, orderChats, user, isLoading]);

  return {
    orderChats,
    isLoading,
  };
};

export const useChats = (): {
  chats: IChat[];
  isLoading: boolean;
  replyChatLastMessage: IMessage | null;
  supportLastMessage: IMessage | null;
  setChats: Dispatch<SetStateAction<IChat[]>>
  addChatsLoading: (roomId: string) => void;
} => {
  const { user } = useAuth();
  const [chatsLoading, setChatsLoading] = useState<string[]>([]);
  const [chats, setChats] = useState<IChat[]>([]);
  const [autoReplyId, setAutoReplyId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [supportBotIdentificator, setSupportBotIdentificator] = useState('');
  const [supportLastMessage, setSupportLastMessage] = useState<IMessage | null>(null);
  const [replyChatLastMessage, setReplyChatLastMessage] = useState<IMessage | null>(null);
  const sound = soundModule();

  const addChatsLoading = (roomId: string) => (
    setChatsLoading((prev) => ([...prev, roomId]))
  );

  const getSupportLastMessage = useCallback(async () => {
    const [supportLastMessageResponse] = await getMessagesFromSupportChat({ limit: 1 });
    setSupportLastMessage(supportLastMessageResponse);

    return true;
  }, []);

  const getReplyChatLastMessage = useCallback(async () => {
    const [supportLastMessageResponse] = await getMessages(autoReplyId, { limit: 1 });
    setSupportLastMessage(supportLastMessageResponse);

    return true;
  }, [autoReplyId]);

  const configureChat = async (roomId: string, userme: IUser): Promise<IChat> => {
    const [lastMessage] = await getMessages(roomId, { limit: 1 });
    const userUuid = userme.uuid === lastMessage.toUser

      ? lastMessage.fromUser
      : lastMessage.toUser;
    const [chatUser] = await getUsers(userUuid);

    const chat: IChat = {
      roomId,
      user: chatUser,
      lastMessage,
      orderIds: [],
      orderType: [],
      sortIndex: CHAT_LIST_SORT_INDEX.DEFAULT,
    };

    return chat;
  };

  useEffect(() => {
    if (!user) return;

    const setAllChats = async () => {
      const newChatIds = chatsLoading.filter((chatIdLoading) => (
        !chats.some(({ roomId }) => chatIdLoading === roomId)
      ));

      if (!newChatIds.length) return;

      setIsLoading(true);
      try {
        const configuredChats = await Promise.all(newChatIds.map((id) => configureChat(id, user)));
        setChats((prevChats) => getUniqueItemsObjects<IChat>([...prevChats, ...configuredChats], 'roomId'));
      } finally {
        setIsLoading(false);
      }
    };

    setAllChats();
  }, [chatsLoading, user, chats]);

  useEffect(() => {
    if (!user) return;

    const getReplyChat = async (replyUserChat: IUser, chatReplyChatId: string) => {
      const [response] = await getSupportBotIdentificator();

      setSupportBotIdentificator(response);
      const { uuid: replyUserUuid } = replyUserChat;

      setAutoReplyId(replyUserUuid);
      if (user.uuid === replyUserUuid) return;

      const [lastMessage] = await getMessages(chatReplyChatId, { limit: 1 });
      setReplyChatLastMessage(lastMessage);
    };

    const getChats = async () => {
      setIsLoading(true);
      try {
        await getSupportLastMessage();

        const [replyUserChat] = await getChatReplyUuid();
        const [{ roomId: chatReplyChatId }] = await createChatRoom(replyUserChat.uuid);
        const response = await getMyChats();
        const chatWithoutSupport = response.filter((id) => !(chatReplyChatId.includes(id)));

        getReplyChat(replyUserChat, chatReplyChatId);
        setChatsLoading(chatWithoutSupport);
      } finally {
        setIsLoading(false);
      }
    };

    getChats();
  }, [user]);

  const handleMessage = async (uuid: string) => {
    if (uuid === supportBotIdentificator) {
      getSupportLastMessage();
      return;
    }

    if (uuid === autoReplyId) {
      getReplyChatLastMessage();
      return;
    }

    const currentChat = chats.find(({ user: chatUser }) => uuid === chatUser.uuid);
    sound.playSound();

    if (!currentChat) {
      const [{ roomId }] = await createChatRoom(uuid);
      addChatsLoading(roomId);
      return;
    }

    const { lastMessage } = currentChat;
    const fromDate = lastMessage
      ? new Date(lastMessage.updatedAt).getTime()
      : 0;

    const lastMessages = await getMessages(
      currentChat.roomId,
      {
        fromDate,
        limit: 1,
      },
    );
    const [newesLastMessage] = lastMessages;

    setChats((prev) => (
      prev.map((chat) => {
        if (chat.roomId !== currentChat.roomId) return chat;
        return {
          ...chat,
          lastMessage: newesLastMessage,
        };
      })
    ));
  };

  useEffect(() => {
    if (user) {
      socket.emit('joinToRoom', user.uuid);
      socket.on('newMessage', handleMessage);
    }

    return () => {
      socket.off('newMessage', handleMessage);
    };
  }, [handleMessage]);

  return {
    chats,
    supportLastMessage,
    replyChatLastMessage,
    isLoading,
    setChats,
    addChatsLoading,
  };
};

export interface UseChatReturn {
  messages: IMessage[];
  isLoading: boolean;
  sendMessage: (url: string, data: IMessageSend) => Promise<IMessage>;
  getMessages: (url: string, params: AxiosRequestConfig) => Promise<void>;
  loadMoreMessages: (url: string) => Promise<void>;
  readMessages: () => void;
  addNewMessages: (newMessages: IMessage[]) => void;
}

const useChat = (): UseChatReturn => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState<null | number>(null);

  const handleGetMessages = useCallback(async (url: string, params: AxiosRequestConfig) => {
    setIsLoading(true);
    try {
      const response = await new RequestService(url).getAll(params);

      setMessages(response.docs);
      setTotal(response.total);
    // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSendMessage = async (url: string, data: IMessageSend) => {
    try {
      const response = await new RequestService(url).post(data);

      setMessages((prev) => [response, ...prev]);
      return response;
    // eslint-disable-next-line no-useless-catch
    } catch (e) {
      const dateNow = moment().format();
      const idMessage = `${data.messageData}${dateNow}`;

      const messageWithError = {
        ...data,
        _id: idMessage,
        isRead: false,
        isError: true,
        createdAt: dateNow,
        updatedAt: dateNow,
      };

      setMessages((prev) => prev.concat([messageWithError]));
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const addNewMessages = useCallback((newNessages: IMessage[]) => {
    setMessages((prev) => [...newNessages, ...prev]);
  }, []);

  const loadMoreMessages = async (url: string) => {
    const isTotalGreatestThenLoadedMessages = (total && total <= messages.length);

    if (isLoading || isTotalGreatestThenLoadedMessages) {
      return;
    }

    const lastMessage = messages[messages.length - 1];

    const allMessages = await new RequestService(url).get({
      params: {
        fromDate: 0,
        toDate: new Date(lastMessage.createdAt).getTime() - 1000,
      },
    });

    if (allMessages) {
      setMessages((prev) => prev.concat(allMessages));
    }
  };

  const readMessages = () => {
    setMessages((prev) => (
      prev.map((message) => ({
        ...message,
        isRead: true,
      }))
    ));
  };

  return {
    messages,
    isLoading,
    sendMessage: handleSendMessage,
    getMessages: handleGetMessages,
    loadMoreMessages,
    readMessages,
    addNewMessages,
  };
};

export interface UseActiveChatReturn extends Pick<UseChatReturn, 'messages'> {
  me: User;
  chatUser: User;
  chatOptions: ChatOptions;
  roomId: string;
  orderType: [string, string][];
  isLoading: boolean;
  loadMoreMessages: () => void;
  sendMessage: (newMessage: IMessageSend) => Promise<void>;
  deleteChat: () => Promise<boolean>;
  blockChat: () => Promise<boolean | BannedUser>;
  complaintChat: (data: string) => Promise<Complaint | null>;
}

interface OrdersIds extends IOrder, UUIDs {}

export const useActiveChat = (): UseActiveChatReturn => {
  const { user } = useAuth();
  const { orders } = useOrders();
  const history = useHistory();
  const location = useLocation<ChatOptions>();
  const params = useParams<{ uuid: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [chatUser, setChatUser] = useState<User>(null);
  const [chatOptions, setChatOptions] = useState<ChatOptions>({} as ChatOptions);
  const [roomId, setRoomId] = useState<string>('');
  const [ordersIds, setOrdersIds] = useState<OrdersIds[]>([]);

  const {
    messages,
    sendMessage,
    getMessages: handleGetMesages,
    loadMoreMessages,
    addNewMessages,
    readMessages,
  } = useChat();

  useEffect(() => {
    if (location.state) setChatOptions(location.state);
  }, [location]);

  useEffect(() => {
    const filterOrders = async () => {
      const promises = orders.map(async (order) => {
        const [response] = await getUuidByOrder(order.serviceId, order.id);

        return { ...order, ...response };
      });

      const responses = await Promise.all(promises);
      setOrdersIds(responses);
    };

    filterOrders();
  }, [orders]);

  const ordersWithCompanion = useMemo(() => {
    if (!chatUser) return [];

    return ordersIds.filter((order) => {
      const isClient = order.userUuid === chatUser.uuid;
      const isVendor = order.vendorUserUuid === chatUser.uuid;

      return isClient || isVendor;
    });
  }, [chatUser, ordersIds]);

  const orderType = useMemo(() => {
    const orderTypes = ordersWithCompanion.map(getTypesFromOrder);

    return orderTypes;
  }, [ordersWithCompanion]);

  const handleSendMessage = async (sendData: IMessageSend) => {
    if (!user) return;

    setIsLoading(true);

    try {
      await sendMessage('/chat', { ...sendData, fromUser: user.uuid });
    } catch (error) {
      const errorMessage = getErrorMessage(error as Error);
      if (errorMessage === ERROR_MSG_CHAT_BANNED) {
        setChatOptions((prev) => ({ ...prev, isChatBanned: true }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleMessage = useCallback(async (userUuidNewMessage: string) => {
    if (!roomId || !chatUser) return;
    if (chatUser.uuid !== userUuidNewMessage) return;

    readMessages();
    setReadMessages({ fromUser: chatUser.uuid });

    const [lastMessage] = messages.reverse();

    const fromDate = lastMessage
      ? new Date(lastMessage.updatedAt).getTime()
      : 0;

    const allMessages = await getMessages(roomId, { fromDate });

    addNewMessages(allMessages);
  }, [roomId, chatUser, messages]);

  useEffect(() => {
    if (!user) return;

    const setChat = async () => {
      const { uuid } = params;
      const isMyUuid = uuid === user.uuid;

      if (isMyUuid) {
        history.push('/chat');
        return;
      }

      setIsLoading(true);

      try {
        const [res] = await createChatRoom(uuid);

        const [currChatUser] = await getUsers(uuid);

        const { roomId: thisChatRoomId } = res;

        const myBannedList = await getBannedUsers();
        const [isMeBanned] = await isMeBannedByUser(user.uuid, currChatUser.uuid);
        const isUserBanned = myBannedList.some(({ bannedUser }) => (
          currChatUser.uuid === bannedUser
        ));

        setReadMessages({
          fromUser: currChatUser.uuid,
        });
        setChatOptions((prev) => ({
          ...prev,
          isChatBanned: isMeBanned || isUserBanned,
        }));
        setRoomId(thisChatRoomId);
        setChatUser(currChatUser);

        await handleGetMesages(
          `/chat/last-messages/${thisChatRoomId}`,
          {
            params: {
              fromDate: 0,
              toDate: new Date().getTime(),
            },
          },
        );
      } finally {
        setIsLoading(false);
      }
    };

    setChat();
  }, [user, handleGetMesages]);

  useEffect(() => {
    const unsubscribe = () => {
      socket.off('newMessage', handleMessage);
      socket.off('readMessage', readMessages);
    };
    if (!(roomId && user)) return unsubscribe;

    socket.emit('joinToRoom', user.uuid);
    socket.on('newMessage', handleMessage);
    socket.on('readMessage', readMessages);

    return unsubscribe;
  }, [roomId, user, handleMessage]);

  const handleDeleteChat = useCallback(async () => {
    if (!chatUser) return false;
    if (!messages.length) return true;

    const response = await deleteChat(chatUser.uuid);

    return response;
  }, [chatUser, messages]);

  const handleBlockChat = useCallback(async () => {
    if (!chatUser || !user) return false;
    if (chatOptions.isChatBanned) return true;

    const data = {
      user: user.uuid,
      bannedUser: chatUser.uuid,
    };

    return banUserInChat(data);
  }, [chatUser, user, chatOptions]);

  const complaintChat = useCallback(async (reason: string): Promise<Complaint | null> => {
    if (!chatUser || !user) return null;
    setIsLoading(true);
    const [{ id: orderId }] = ordersWithCompanion;

    try {
      const data: ComplaintToSend = {
        reason,
        orderId,
        abuserId: chatUser.id,
      };

      const response = await new RequestService('/complaint').post(data);

      return response;
    } finally {
      setIsLoading(false);
    }
  }, [chatUser, user, ordersWithCompanion]);

  const handleLoadMoreMessages = () => loadMoreMessages(`/chat/last-messages/${roomId}`);

  return {
    chatUser,
    chatOptions,
    isLoading,
    roomId,
    orderType,
    me: user,
    messages,
    loadMoreMessages: handleLoadMoreMessages,
    sendMessage: handleSendMessage,
    blockChat: handleBlockChat,
    deleteChat: handleDeleteChat,
    complaintChat,
  };
};

export interface UseSupportChatReturn {
  messages: IMessage[];
  isLoading: boolean;
  sendMessage: (data: Pick<IMessage, 'messageData' | 'messageFiles'>) => Promise<void>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  deleteChat: () => Promise<boolean>;
  loadMoreMessages: () => void;
}

export const useSupportChat = (): UseSupportChatReturn => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [supportBotIdentificator, setSupportBotIdentificator] = useState('');

  const {
    messages,
    sendMessage,
    getMessages: handleGetMesages,
    loadMoreMessages,
    addNewMessages,
    readMessages,
  } = useChat();

  const handleReadSupportMessages = async () => {
    setIsLoading(true);
    try {
      return await setReadSupportMessages();
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendMessage = useCallback(async (data: Pick<IMessage, 'messageData' | 'messageFiles'>) => {
    try {
      setIsLoading(true);
      await handleReadSupportMessages();

      await sendMessage('/chat-support', data as IMessageSend);
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  const handleDeleteChat = useCallback(async () => {
    if (!messages.length) return true;
    const response = await deleteSupportChat();

    return response;
  }, [messages]);

  const handleReadMyMessage = (userUuidNewMessage: string) => {
    if (userUuidNewMessage !== supportBotIdentificator) return;

    readMessages();
  };

  const handleMessage = async (userUuidNewMessage: string) => {
    if (userUuidNewMessage !== supportBotIdentificator) return;

    const [lastMessage] = messages;

    const fromDate = lastMessage
      ? new Date(lastMessage.updatedAt).getTime()
      : 0;

    const allNewMessages = await getMessagesFromSupportChat({ fromDate });
    await handleReadSupportMessages();

    addNewMessages(allNewMessages);
  };

  useEffect(() => {
    socket.on('newMessage', handleMessage);
    socket.on('readMessage', handleReadMyMessage);

    return () => {
      socket.off('newMessage', handleMessage);
      socket.off('readMessage', handleReadMyMessage);
    };
  }, [handleMessage, handleReadMyMessage]);

  useEffect(() => {
    if (!user) return;
    socket.emit('joinToRoom', user.uuid);

    const setChat = async () => {
      setIsLoading(true);

      try {
        await setReadSupportMessages();

        const [response] = await getSupportBotIdentificator();
        setSupportBotIdentificator(response);

        await handleGetMesages(
          '/chat-support/last-messages/',
          {
            params: {
              fromDate: 0,
              toDate: new Date().getTime(),
            },
          },
        );
      } finally {
        setIsLoading(false);
      }
    };

    setChat();
  }, [user]);

  const handleLoadMoreMessages = () => loadMoreMessages('/chat-support/last-messages/');

  return {
    messages,
    isLoading,
    loadMoreMessages: handleLoadMoreMessages,
    sendMessage: handleSendMessage,
    deleteChat: handleDeleteChat,
    setIsLoading,
  };
};

export interface UseReplyChatReturn {
  isLoading: boolean;
  messages: IMessage[];
  loadMoreMessages: () => void;
}

export const useReplyChat = (): UseReplyChatReturn => {
  const { user } = useAuth();
  const [userUuid, setUserUuid] = useState<string | null>(null);
  const [roomId, setRoomId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    messages,
    getMessages: handleGetMesages,
    loadMoreMessages,
    addNewMessages,
    readMessages,
  } = useChat();

  const handleMessage = useCallback(async (userUuidNewMessage: string) => {
    if (!roomId || userUuid !== userUuidNewMessage) return;
    await setReadMessages({ fromUser: userUuid });
    readMessages();

    const [lastMessage] = messages;

    const fromDate = lastMessage
      ? new Date(lastMessage.updatedAt).getTime()
      : 0;

    const allNewMessages = await getMessages(roomId, { fromDate });
    addNewMessages(allNewMessages);
  }, [userUuid, messages, roomId]);

  useEffect(() => {
    const handleGetData = async () => {
      try {
        setIsLoading(true);
        const [{ uuid: chatReplyUserUuid }] = await getChatReplyUuid();
        const [{ roomId: roomIdReponse }] = await createChatRoom(chatReplyUserUuid);

        await handleGetMesages(`/chat/last-messages/${roomIdReponse}/`, { params: { fromDate: 0 } });
        await setReadMessages({ fromUser: chatReplyUserUuid });

        setUserUuid(chatReplyUserUuid);
        setRoomId(roomIdReponse);
      } finally {
        setIsLoading(false);
      }
    };

    handleGetData();
  }, []);

  useEffect(() => {
    if (user) {
      socket.emit('joinToRoom', user.uuid);
      socket.on('newMessage', handleMessage);
    }

    return () => {
      socket.off('newMessage', handleMessage);
    };
  }, [user, handleMessage]);

  const handleLoadMoreMessages = () => loadMoreMessages(`/chat/last-messages/${roomId}/`);

  return {
    isLoading,
    loadMoreMessages: handleLoadMoreMessages,
    messages,
  };
};
