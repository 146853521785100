import React from 'react';
import { useAppInfoTextOnly } from 'hooks/appInfo';
import { IOnChange, SosRoute } from 'interfaces';
import { useSettings } from 'pages/SettingsPage/SettingsContext';
import { useAuth } from 'services/auth';
import SecurityComponent from '../components/SecurityComponent';

const POSITIONS = [
  'menuSecurityTop',
  'menuSecurityBottom',
];

const SecurityPage: React.FC = () => {
  const { setIsLoading, user } = useAuth();
  const { sosRoute, updateUserSettings } = useSettings();
  const appInfo = useAppInfoTextOnly(POSITIONS);

  const handleSaveSosRoute = (data: { sosRoute: SosRoute }) => {
    setIsLoading(true);
    try {
      updateUserSettings(data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = ({ data, action }: IOnChange) => {
    switch (action) {
      case 'setSosRoute': {
        handleSaveSosRoute(data);
        break;
      }
      default: break;
    }
  };

  return (
    <SecurityComponent
      menuSecurityBottom={appInfo.menuSecurityBottom}
      menuSecurityTop={appInfo.menuSecurityTop}
      user={user}
      onChange={handleChange}
      sosRoute={sosRoute}
    />
  );
};

export default SecurityPage;
