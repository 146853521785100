import React from 'react';
import { IconComponentProps } from 'interfaces';

const Settings: React.FC<IconComponentProps> = ({ color, width, height }) => (
  <svg
    version="1.1"
    width={width}
    height={height}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 72"
  >
    <path
      fill={color}
      d="M69.72 40.53v-9.05h-9.1a24.88 24.88 0 00-4.01-9.68l6.43-6.43-6.4-6.4-6.43 6.43a25.006 25.006 0 00-9.68-4.01v-9.1h-9.05v9.1a24.88 24.88 0 00-9.68 4.01l-6.43-6.43-6.4 6.4 6.43 6.43a25.006 25.006 0 00-4.01 9.68h-9.1v9.05h9.1a24.88 24.88 0 004.01 9.68l-6.43 6.43 6.4 6.4 6.43-6.43c2.86 1.97 6.14 3.36 9.68 4.01v9.1h9.05v-9.1a24.88 24.88 0 009.68-4.01l6.43 6.43 6.4-6.4-6.43-6.43c1.97-2.86 3.36-6.14 4.01-9.68h9.1zM36 52.75c-9.25 0-16.75-7.5-16.75-16.75S26.75 19.25 36 19.25 52.75 26.75 52.75 36 45.25 52.75 36 52.75z"
    />
    <circle
      fill={color}
      cx="36"
      cy="36"
      r="7.57"
    />
  </svg>
);

export default Settings;
