import { Box, Button, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'sharedComponents/Modal';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getTranslateFunction } from 'helpers';
import { ListItem } from 'interfaces';
import { LANGUAGES } from '../../constants';
import useStyles from './styles';

type Props = {
  isOpen: boolean;
  onChange: (lang: ListItem) => void;
  onClose: () => void;
};

const LanguagesModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onChange,
}: Props) => {
  const { i18n } = useTranslation('general');
  const t = getTranslateFunction();
  const classes = useStyles();
  const [choise, setChoise] = useState<ListItem>(() => (
    LANGUAGES.find((item) => i18n.language === item.info.lang) as ListItem
  ));

  const confirm = useCallback(() => {
    if (i18n.language !== choise.info.lang) {
      onChange(choise);
    }
    onClose();
  }, [choise, i18n.language]);

  const handleClose = useCallback(() => {
    if (i18n.language === choise.info.lang) {
      onClose();
    }
  }, [i18n.language, choise]);

  useEffect(() => {
    const defaultItem = LANGUAGES.find((item) => i18n.language === item.info.lang) as ListItem;
    setChoise(defaultItem);
  }, [i18n.language]);

  return (
    <Modal
      modalClassName={classes.modalWrapper}
      backdropClassName={classes.backdrop}
      isOpen={isOpen}
      withoutBorder
      onClose={handleClose}
    >
      {LANGUAGES.map((item: ListItem) => (
        <Box
          data-lang-btn={`${item.info.lang}`}
          className={classes.langBtn}
          key={item.label}
          onClick={() => setChoise(item)}
        >
          <Typography className={classes.btnTitle}>
            {t(item.label)}
          </Typography>
          <Box className={classNames(
            classes.indicator,
            {[classes.activeIndicator]: choise.info.lang === item.info.lang},
          )}
          />
        </Box>
      ))}
      <Button onClick={confirm}>
        <Typography className={classes.btnTitle}>{t('languagesSelect.closeModal')}</Typography>
      </Button>
    </Modal>
  );
};

export default LanguagesModal;
