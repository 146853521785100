import React, { FC } from 'react';
import { IconComponentProps } from 'interfaces';

const Comment: FC<IconComponentProps> = ({ color, width = 20, height = 20 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" width={width} height={height}>
    <defs />
    <path
      fill={color}
      d="M2.68,7.89v46.75H60.7l8.61,10.84V54.64V7.89H2.68z M52.18,43.77H19.82v-5.62h32.37V43.77z M52.18,34.02H19.82 V28.4h32.37V34.02z M52.18,24.27H19.82v-5.62h32.37V24.27z"
    />
  </svg>

);

export default Comment;
