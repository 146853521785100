import { makeStyles } from '@material-ui/core/styles';
import {
  green,
  grayBG,
  textColor,
  cyan,
  greenSecondary,
  red,
  orange,
  backgroundColor,
  checkboxUnchecked,
  textNoWrap,
} from '../../constants';

export const indexStyles = makeStyles({
  textField: {
    backgroundColor: grayBG,
    padding: '5px',
    border: `2px ${grayBG} solid`,
    '& :-webkit-autofill': {
      boxShadow: `0 0 0px 1000px ${grayBG} inset`,
    },
    '& input::placeholder': {
      color: textColor,
      opacity: 0.5,
    },
    '&.Mui-focused.error + p': {
      display: 'none',
    },
    '&:not(.Mui-focused).error': {
      borderColor: red,
      '& input, & input::placeholder': {
        color: 'transparent',
        zIndex: -1,
      },
    },
  },
  autocomplete: {
    padding: '3px 5px',
    border: `2px ${grayBG} solid`,

    '& :-webkit-autofill': {
      boxShadow: `0 0 0px 1000px ${grayBG} inset`,
    },
    '& input::placeholder': {
      color: textColor,
      opacity: 0.5,
    },
  },
  radioGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  radio: {},
  radioBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '&.gender': {
      width: 'unset',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    justifyContent: 'space-between',
  },
  infoStepLabel: {
    '& .MuiTypography-body1': {
      color: cyan,
    },
  },
  iconAdornment: {
    width: '20px',
    height: 'auto',
    margin: '5px',
    color: cyan,
  },
  iconDocs: {
    width: '100px',
    height: 'auto',
  },
  uploadBox: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  documentsBox: {
    marginBottom: 10,
  },
  countryPicker: {
    backgroundColor: grayBG,
  },
  submitButton: {
    fontWeight: 'bold',
    width: '100%',
    backgroundColor: green,
    color: 'white',
    lineHeight: '30px',
  },
  confirmFormContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#fff',
    zIndex: 2000,
    marginTop: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formControl: {
    width: '100%',
    '&.unRequired': {
      marginBottom: '3px',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
  },
  modalConfirm: {
    backgroundColor: '#fff',
    width: '100%',
    border: `2px ${grayBG} solid`,
    boxSizing: 'border-box',
    boxShadow: '#eee',
    height: '100%',
    padding: '20px',
    '&:focus': {
      outline: 'none',
    },
  },
  confirmForm: {
    height: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  mainContainer: {
    position: 'relative',
    padding: '0',
    height: '100%',
  },
  headerTitle: {
    fontSize: 20,
    textTransform: 'uppercase',
    textAlign: 'center',
    letterSpacing: '0.1em',
  },
  logoContainer: {
    lineHeight: 0,
  },
  birthdayInput: {
    '&:has(input[value=""]:focus-visible)': {
      backgroundColor: 'red',
    },
    '&:has(input[value=""])': {
      '&::after': {
        content: 'attr(data-placeholder)',
        color: '#8e8c8c',
        left: '39px',
        position: 'absolute',
        pointerEvents: 'none',
      },
      '& > input.MuiInputBase-input': {
        color: 'transparent',
      },
    },
  },
  header: {
    borderBottom: `2px ${textColor} solid`,
    borderTop: `2px ${textColor} solid`,
    padding: '10px 0',
  },
  logoContent: {
    padding: '2px 20px',
    boxSizing: 'border-box',
    backgroundColor: grayBG,
    gap: 5,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  uploadContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '15px',
    overflowX: 'hidden',
  },
  logo: {
    width: '80px',
    height: 'auto',
    backgroundColor,
  },
  errorIcon: {
    color: cyan,
  },
  buttonContainer: {
    margin: '10px 0',
    display: 'block',
    textAlign: 'center',
  },
  signupStepContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  infoStepContent: {
    height: '100%',
    overflow: 'hidden',
    padding: '20px 20px 0',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    fontSize: 14,
  },
  infoBlockContent: {
    padding: 10,
    backgroundColor: grayBG,
    fontSize: 14,
    height: '100%',
    overflow: 'auto',

    '& > p, & > ol, & > ul, & > li': {
      margin: 0,
    },
  },
  footerButtonContainer: {

  },
  footerButtonCancel: {
    width: '100%',
    fontSize: 16,
    color: red,
  },
  footerButtonSubmit: {
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 26,
    fontWeight: 'bold',
    color: green,
    borderTop: `2px solid ${textColor}`,
  },
  icon: {
    width: 14,
    height: 14,
    backgroundColor: checkboxUnchecked,
    border: `2px ${checkboxUnchecked} solid`,
  },
  checkedIcon: {
    backgroundColor: orange,
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      content: '""',
    },
  },
  checkboxRequiredError: {
    borderColor: red,
  },
  formContent: {
    height: '100%',
    overflow: 'auto',
  },
  clientInfoFormContent: {
    position: 'relative',
    paddingTop: 10,
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  placeOfResidenceMapWrapper: {
    position: 'absolute',
    inset: 0,
    height: '100%',
    zIndex: 100,
  },
  positionStatic: {
    position: 'static',
  },
  errorMessage: {
    position: 'absolute',
    top: '15%',
    fontSize: 16,
    left: 35,
    right: 0,
    pointerEvents: 'none',
    ...textNoWrap,
  },
  documentItemDescriptionWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 5,
    paddingLeft: 10,
  },
  documentItemDescriptionWrapperCollapsed: {
    backgroundColor: grayBG,
    position: 'relative',
    inset: 0,
    zIndex: 1,
    '&::after': {
      content: '\'\'',
      position: 'absolute',
      inset: '17px 19px',
      border: `${red} 1px solid`,
      pointerEvents: 'none',
    },
  },
  documentItemDescButton: {
    minWidth: 'unset',
  },
  textNoWrap,
  documentDecs: {
    height: '90%',
    overflowY: 'auto',
    margin: '10px 0',
  },
  documentDecsFull: {
    margin: '25px 0 25px 20px',
  },
  passwordRequirementsLabel: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  passwordField: {},
}, { index: 1 });

export const Step3Styles = makeStyles((theme) => ({
  label: {
    width: '100%',
    textAlign: 'start',
    margin: '10px 0',
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    transition: '.3s',
    backgroundColor: greenSecondary,
    '& .MuiTypography-root': {
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '18px',
    },
    '&.open': {
      backgroundColor: green,
    },
  },
  iconAdornment: {
    width: '20px',
    height: 'auto',
    margin: '5px',
    color: cyan,
  },
  textField: {
    backgroundColor: grayBG,
    border: `2px ${grayBG} solid`,
    boxSizing: 'border-box',
    padding: '5px',
    '& :-webkit-autofill': {
      boxShadow: `0 0 0px 1000px ${grayBG} inset`,
    },
    '& input::placeholder': {
      color: textColor,
      opacity: 0.5,
    },
    '&.error': {
      borderColor: red,
      '& input': {
        color: 'transparent',
        zIndex: -1,
      },
    },
  },
  radioOverride: {
    maxWidth: '45%',
  },
  radioLabel: {
    margin: 0,
    '& .MuiTypography-body1': {
      fontWeight: '500',
      [theme.breakpoints.down(390)]: {
        fontSize: '13px',
      },
    },
  },
  inputFormControl: {
    margin: '5px 0',
    width: '100%',
    '& .MuiFilledInput-root': {
      padding: '12px',
      backgroundColor: grayBG,
    },
  },
  comboBoxItem: {},
  inputLabel: {
    [theme.breakpoints.down(390)]: {
      fontSize: '14px',
    },
    paddingBottom: '5px',
  },
  radioContainerStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '20px 0',
    margin: '20px 0',
    [theme.breakpoints.down(390)]: {
      flexDirection: 'column',
    },
    '&.border': {
      borderTop: `2px ${textColor} solid`,
      borderBottom: `2px ${textColor} solid`,
    },
  },
  comboboxContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    marginBottom: 10,
  },
  fileInputContainer: {
    marginBottom: '15px',
    borderTop: `2px ${textColor} solid`,
    '&:first-child': {
      borderTop: 'none',
    },
  },
  boxCenter: {
    textAlign: 'center',
  },
  autoComplete: {
    '& .MuiFilledInput-root': {
      borderRadius: '0',
      height: 'auto',
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
      marginTop: '-2px',
      padding: '0',
    },
    '& .MuiInputLabel-formControl': {
      transform: 'none',
      top: '8px',
      left: '5px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      direction: 'ltr',
      maxWidth: '120px',
      whiteSpace: 'nowrap',
      fontSize: '13px',
    },
  },
  bodySvg: {
    width: '35px',
  },
  radioBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '45%',
  },
  formControl: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  arrow: {
    color: '#fff',
    transition: '.5s',
    '&.open': {
      transform: 'rotateX(180deg)',
    },
  },
  box: {
    maxHeight: 0,
    transition: 'max-height .5s ease-out',
    display: 'none',
    marginBottom: 10,
    '&.open': {
      display: 'block',
      maxHeight: 'none',
      transition: 'max-height .5s ease-in',
    },
  },
  carBodyStyle: {},
  carBodyItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    width: 14,
    height: 14,
    backgroundColor: checkboxUnchecked,
    border: `2px ${checkboxUnchecked} solid`,
  },
  checkedIcon: {
    backgroundColor: orange,
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      content: '""',
    },
  },
  radioItem: {
    paddingLeft: 0,
  },
  errorMessage: {
    position: 'absolute',
    top: '15%',
    fontSize: 16,
    left: 35,
    right: 0,
    pointerEvents: 'none',
    ...textNoWrap,
  },
  vehicleNumberField: {
    padding: '10px 5px',
  },
}), { index: 1 });

export const verifyEmailStyles = makeStyles({
  confirmFormContainer: {
    height: '100%',
    backgroundColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    padding: '0 40px',
  },
  logoContainer: {
    textAlign: 'center',
  },
  logo: {
    maxWidth: '200px',
    height: 'auto',
  },
  textField: {
    padding: '5px',
    margin: '15px 0',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: grayBG,
  },
  svgIcon: {
    '& svg': {
      width: '20px',
      height: 'auto',
      marginRight: '10px',
    },
  },
  button: {
    width: '100%',
    '&:hover': {
      backgroundColor: `${green}80`,
    },
    '&.green': {
      backgroundColor: green,
      color: backgroundColor,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  editEmailInputWrapper: {
    position: 'relative',
    display: 'flex',
  },
  editEmailInput: {
    padding: '0 5px',
    fontSize: '13px',
    backgroundColor: grayBG,
    border: `2px ${grayBG} solid`,
    marginLeft: 35,
    width: '100%',
    '&.error': {
      borderColor: red,
      '& input': {
        color: 'transparent',
        zIndex: -1,
      },
    },
  },

  editEmailWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  editEmailBottom: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },
  codeErrorMessage: {
    color: red,
  },
  editEmailButton: {
    padding: '0 8px',
    backgroundColor: orange,
    color: backgroundColor,
    width: '100%',
  },
  logoGif: {
    width: 'min(400px, 80%)',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cancelButton: {
    color: red,
    fontSize: 24,
  },
  siteLinkWrapper: {
    textAlign: 'center',
  },
  siteLink: {
    textDecoration: 'none',
    color: cyan,
  },
  top: {},
  errorMessage: {
    position: 'absolute',
    top: '4px',
    fontSize: 12,
    left: 45,
    right: 0,
    pointerEvents: 'none',
    color: red,
    ...textNoWrap,
  },
}, { index: 1 });
