import React, {
  useState,
  useEffect,
} from 'react';
import { Redirect, useHistory, useLocation } from 'react-router';
import {
  sendSaveOurSouls,
} from 'services/commonService';
import RequestService from 'services/requestApi';
import { useAuth } from 'services/auth';
import { useOrder } from 'hooks/order';
import { IOnChange, IOrder } from 'interfaces';
import { useVendorLocation } from 'hooks/geolocation';
import {
  ORDER_STATUSES,
} from '../../../constants';
import socket from '../../../socket';
import {
  DriverOnRoadModal,
} from '../ClientOrderPageModal';
import ClientOrderComponent from '../components/ClientOrderComponent';

const ClientOrderApp: React.FC = () => {
  const history = useHistory();
  const { setIsLoading } = useAuth();
  const location = useLocation<[IOrder]>();
  const [openMap, setOpenMap] = useState(false);
  const [ratingModal, setRatingModal] = useState(false);
  const [cancelModalOrder, setCancelModalOrder] = useState(false);
  const [modalOrderInfo, setModalOrderInfo] = useState(false);
  const [modalVendorInfo, setModalVendorInfo] = useState(false);
  const [modalDriverOnRoad, setModalDriverOnRoad] = useState(false);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const [order] = location.state;
  const { id: orderId, preorderId } = order;

  const {
    vendor,
    status,
    currency,
    vendorUser,
    vendorRating,
    vendorComments,
    isSingleTypeOrder,
    waitingLabel,
    ratingAvg,
    cancelOrder,
    handleChatToVendor,
  } = useOrder(order);

  const {
    distance,
    vendorLocation,
  } = useVendorLocation(vendor?.id);

  useEffect(() => {
    socket.emit('joinToRoom', preorderId.toString());
  }, []);

  useEffect(() => {
    setRatingModal(status === ORDER_STATUSES.FINISHED);
  }, [status]);

  const handleChangeDeclineModal = () => {
    setCancelModalOrder((prev) => !prev);
  };

  const handleCloseDriverOnRoad = () => {
    setModalDriverOnRoad(false);
  };

  const sendVendorRating = async (data: any) => {
    try {
      setIsLoading(true);
      await new RequestService('/vendors-rating').post(data);

      setRatingModal(false);
      history.push('/');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = ({
    action,
    data,
  }: IOnChange) => {
    switch (action) {
      case 'handleChatToVendor': {
        handleChatToVendor();
        break;
      }
      case 'cancelOrder': {
        cancelOrder(data);
        break;
      }
      case 'handleMap': {
        setOpenMap((prev) => !prev);
        break;
      }
      case 'handleVendorInfo': {
        setModalVendorInfo((prev) => !prev);
        break;
      }
      case 'handleOrderInfo': {
        setModalOrderInfo((prev) => !prev);
        break;
      }
      case 'handleSendSaveOurSouls': {
        sendSaveOurSouls({ orderId });
        break;
      }
      case 'handleChangeDeclineModal': {
        handleChangeDeclineModal();
        break;
      }
      case 'handleOpenDriverOnRoad': {
        setModalDriverOnRoad(true);
        break;
      }
      case 'setVendorRating': {
        sendVendorRating(data);
        break;
      }
      default: break;
    }
  };

  return (
    <>
      <ClientOrderComponent
        onChange={handleChange}
        ratingModal={ratingModal}
        isSingleTypeOrder={isSingleTypeOrder}
        order={{
          ...order,
          currency,
        }}
        vendorUser={vendorUser}
        vendorComments={vendorComments}
        ratingAvg={ratingAvg}
        vendorRating={vendorRating}
        vendor={vendor}
        distance={distance}
        vendorLocation={vendorLocation}
        status={status}
        modalOrderInfo={modalOrderInfo}
        modalVendorInfo={modalVendorInfo}
        waitingLabel={waitingLabel}
        openMap={openMap}
        cancelModalOrder={cancelModalOrder}
      />

      {modalDriverOnRoad && (
        <DriverOnRoadModal
          modal={modalDriverOnRoad}
          onClose={handleCloseDriverOnRoad}
        />
      )}
    </>
  );
};

export default ClientOrderApp;
