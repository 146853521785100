import {
  Box,
  FormControlLabel,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Controller, UseFormMethods } from 'react-hook-form';
import Icons from 'components/Icons';
import { CFPassengersItem } from 'interfaces';
import useStyles from '../ConstructorStyles';

const {
  individualSvg,
} = Icons;

interface Props extends Pick<UseFormMethods, 'control'> {
  item: CFPassengersItem;
}

const Passengers: React.FC<Props> = ({
  item,
  control,
}: Props) => {
  const classes = useStyles();
  const {
    name,
    label,
  } = item;
  const handleEditPassengers = useCallback((step: number, prev: number) => {
    const newValue = prev + step;
    if (newValue <= 0) return prev;

    return newValue;
  }, []);

  return (
    <FormControlLabel
      key={name}
      className={classes.textfieldLabel}
      labelPlacement="start"
      control={(
        <Controller
          name={name}
          control={control}
          render={({ value, onChange }) => (
            <Box className={classes.passengers}>
              <IconButton
                className="undoOne"
                onClick={() => {
                  onChange(handleEditPassengers(-1, value));
                }}
              >
                <Remove />
              </IconButton>
              <Typography className={classNames(classes.passengersText, 'passengersNumberValue')}>{value}</Typography>
              <IconButton
                className="addOne"
                onClick={() => {
                  onChange(handleEditPassengers(1, value));
                }}
              >
                <Add />
              </IconButton>
            </Box>
          )}
        />
      )}
      label={(
        <Box className={classes.titleRadio}>
          <img src={individualSvg} alt="passengers" className={classes.svg} />
          {label}
        </Box>
      )}
    />
  );
};

export default Passengers;
