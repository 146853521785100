import { Box, Avatar, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { ReactNode } from 'react';
import { IOnChange } from 'interfaces';
import useStyles from '../ChatStyle';

type Props = {
  imgUrl: string;
  lastName?: string;
  firstName?: string;
  messageTime?: string;
  title?: string | ReactNode;
  orderType?: string;
  onChange?: (data: IOnChange) => void;
};

const UserInfoBlock: React.FC<Props> = ({
  imgUrl,
  lastName,
  firstName,
  messageTime,
  title,
  orderType,
  onChange,
}: Props) => {
  const classes = useStyles();

  const handleGoHistory = () => {
    onChange?.({
      action: 'goToPage',
      data: '/orders-history',
    });
  };

  return (
    <Box className={classes.chatsListItemInfo}>
      <Avatar variant="square" className={classes.listUserPhoto} src={imgUrl} alt={firstName} />
      <Box className={classes.chatInfoWrapper}>
        {(firstName || lastName) && (
          <Typography className={classes.listUserName}>{`${firstName} ${lastName}`}</Typography>
        )}
        {orderType && (
          <Typography onClick={handleGoHistory} className={classes.chatsListItemMessage}>
            {orderType}
          </Typography>
        )}
        {title && (
          <Typography className={classes.chatsListItemMessage}>
            {title}
          </Typography>
        )}
        {messageTime && (
          <Typography>
            {moment(messageTime).format('DD.MM.YYYY')}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default UserInfoBlock;

UserInfoBlock.defaultProps = {
  messageTime: '',
  orderType: '',
  lastName: '',
  firstName: '',
  title: '',
  onChange: () => {},
};
