import { makeStyles } from '@material-ui/core/styles';
import { checkboxUnchecked, orange, red } from '../../constants';

export default makeStyles(() => ({
  icon: {
    width: 14,
    height: 14,
    backgroundColor: checkboxUnchecked,
    border: `2px ${checkboxUnchecked} solid`,
  },
  checkedIcon: {
    backgroundColor: orange,
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      content: '""',
    },
  },
  checkboxRequiredError: {
    borderColor: red,
  },
}), { index: 1 });
