import { makeStyles } from '@material-ui/core/styles';
import {
  grayBG,
} from '../../constants';

export default makeStyles({
  titleName: {
    margin: '0 20px',
  },
  optionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 10px',
    justifyContent: 'space-between',
  },
  optionLinkContainer: {
    padding: '5px',
    boxSizing: 'border-box',
    width: '50%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  optionLink: {
    backgroundColor: grayBG,
    textAlign: 'center',
    padding: '5px 10px',
    fontWeight: 'bold',
    textDecoration: 'none',
    marginTop: '10px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '30px',
  },
}, { index: 1 });
