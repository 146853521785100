import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  content: {
    height: '100%',
    overflow: 'auto',
  },
}, { index: 1 });
