import { makeStyles } from '@material-ui/core';
import { grayBG, textColor, backgroundColor } from '../../constants';

export default makeStyles({
  previewImgBox: {
    margin: '5px',
  },
  previewImg: {
    width: '80px',
    height: '80px',
    objectFit: 'cover',
  },
  previewBox: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  backDrop: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: `${grayBG}E6`,
    zIndex: 2000,
  },
  content: {
    width: '100%',
    height: '100%',
  },
  controlBtnsBox: {
    position: 'absolute',
    top: '25%',
    bottom: '25%',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& .MuiButton-root': {
      color: textColor,
    },
  },
  closeBtn: {
    zIndex: 1,
  },
  activeImgBox: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activeImgContainer: {
    maxHeight: '90%',
    maxWidth: '70%',
    position: 'relative',
  },
  activeImg: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  buttonsContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: '5px',
  },
  downloadIcon: {
    color: backgroundColor,
  },
}, { index: 1 });
