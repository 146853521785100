import L, { Map } from 'leaflet';
import React, {
  useState, useEffect, useCallback, memo,
} from 'react';
import {
  MapContainer, TileLayer, Polyline, Marker,
} from 'react-leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import Preloader from 'components/Preloader/Preloader';
import { IOrder, Preorder } from 'interfaces';
import marker from 'images/icons/markerRed.svg';
import { colors, MAP_CENTER_FALLBACK } from '../../constants';

interface Props {
  items: Preorder[] | IOrder[];
  locate?: number[] | null;
}

const WaysItems = memo(({ currItems, map }: any) => (
  currItems.map(({ dots, path, preorderId }: any) => {
    if (typeof path === 'string') {
      const newPath = JSON.parse(path);
      return newPath;
    }
    const color = colors[preorderId % 100];
    const mark = dots.map(
      (item: Preorder, index: number, array: Preorder[]) => {
        const isLast = index === array.length - 1;
        return (
            <div key={item.lon}>
              {path.length && array[0] && (
                <Polyline
                  color={color}
                  positions={[
                    [array[0].lat, array[0].lon],
                    [path[0][1], path[0][0]],
                  ]}
                  dashArray="6, 6"
                />
              )}
              {path.length
                && array[array.length - 1]
                && 'lat' in array[array.length - 1]
                && 'lon' in array[array.length - 1] && (
                  <Polyline
                    color={color}
                    positions={[
                      [array[array.length - 1].lat, array[array.length - 1].lon],
                      [path[path.length - 1][1], path[path.length - 1][0]],
                    ]}
                    dashArray="6, 6"
                  />
              )}
              <Marker
                icon={L.icon({
                  iconUrl: isLast ? marker : icon,
                  iconSize: [30, 36],
                  iconAnchor: [18, 36],
                })}
                position={[Number(item.lat), Number(item.lon)]}
              />
            </div>
        );
      },
    );
    const allFilled = dots.every(
      (point: any) => 'lat' in point && 'lon' in point,
    );

    if (map && allFilled) {
      map.flyToBounds(dots.map((point: any) => [point.lat, point.lon]));
    }

    return (
        <div key={preorderId}>
          {mark}
          {path.length && (
            <Polyline
              positions={path.map(([lng, lat]: number[]) => [lat, lng])}
              color={color}
            />
          )}
        </div>
    );
  })
));

const PerformerMap: React.FC<Props> = ({ items, locate }: Props) => {
  const [map, setMap] = useState<Map>();
  const [location, setLocation] = useState<any>([]);
  const [initialized, setInitialized] = useState(false);

  const handleSetLocation = async () => {
    setLocation(MAP_CENTER_FALLBACK);
  };

  const handleSetData = useCallback(async () => {
    if (!locate) {
      await handleSetLocation();
    }
    setInitialized(true);
  }, [locate]);

  useEffect(() => {
    handleSetData();

    return () => {
      setInitialized(false);
      setLocation(null);
    };
  }, []);

  const vendorMarker = locate && <Marker position={[locate[0], locate[1]]} />;
  if (!initialized && !map) {
    return <Preloader />;
  }
  return (
    <MapContainer
      center={locate || location}
      zoom={13}
      style={{ height: '100%' }}
      whenCreated={setMap as any}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <WaysItems currItems={items} map={map} locate={locate} />
      {vendorMarker}
    </MapContainer>
  );
};

export default PerformerMap;

PerformerMap.defaultProps = {
  locate: null,
};
