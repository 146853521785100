import { makeStyles } from '@material-ui/core/styles';
import {
  grayBG, textColor, backgroundColor, orange,
} from '../../constants';

export default makeStyles({
  langBtn: {
    padding: '5px',
    margin: '10px',
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalWrapper: {
    width: '100%',
    backgroundColor: `${textColor}d4`,
  },
  backdrop: {
    backgroundColor: 'unset',
  },
  indicator: {
    width: '20px',
    height: '20px',
    backgroundColor: grayBG,
  },
  activeIndicator: {
    backgroundColor: orange,
  },
  btnTitle: {
    color: backgroundColor,
  },
}, { index: 1 });
