import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { IOrder } from 'interfaces';

interface Context {
  orders: IOrder[];
  clientOrders: IOrder[];
  handleAddClientOrders: (orders: IOrder[]) => void;
  vendorOrders: IOrder[];
  handleAddVendorOrders: (orders: IOrder[]) => void;
  handleDeleteOrder: (id: number) => void;
  handleLogout: () => void;
}

export const ordersContext = createContext<Context>({} as Context);

export const useOrders = (): Context => useContext<Context>(ordersContext);

const getUniqueOrders = (prev: IOrder[], orders: IOrder[]) => {
  const filteredOrders = orders.filter((order) => {
    const isExist = prev.filter((item: IOrder) => item.id === order.id).length;
    return (
      isExist ? prev : [...prev, order]
    );
  });

  return filteredOrders;
};

export function useProvideOrders(): Context {
  const [clientOrders, setClientOrders] = useState<IOrder[]>([]);
  const [vendorOrders, setVendorOrders] = useState<IOrder[]>([]);

  const handleAddClientOrders = useCallback((orders) => {
    setClientOrders((prev) => getUniqueOrders(prev, orders));
  }, []);

  const handleAddVendorOrders = useCallback((orders) => {
    setVendorOrders((prev) => getUniqueOrders(prev, orders));
  }, []);

  const handleDeleteOrder = (id: number) => {
    setClientOrders((prev) => prev.filter((order) => order.id !== id));
    setVendorOrders((prev) => prev.filter((order) => order.id !== id));
  };

  const handleLogout = () => {
    setClientOrders([]);
    setVendorOrders([]);
  };

  const orders = useMemo(() => [...clientOrders, ...vendorOrders], [clientOrders, vendorOrders]);

  return {
    orders,
    clientOrders,
    handleAddClientOrders,
    vendorOrders,
    handleAddVendorOrders,
    handleDeleteOrder,
    handleLogout,
  };
}
