import React from 'react';
import { Box, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import PaymentListItem from 'components/PaymentListItem';
import Icons from 'components/Icons';
import { getTranslateFunction } from 'helpers';
import { currencyFormatter } from 'utils/formatters';
import { PendingTransactionStats, TCurrency } from 'interfaces';
import useStyles from '../PaymentsPageStyle';
import { cyan } from '../../../constants';

interface Props {
  pendingTransactions: PendingTransactionStats;
  formattedBonuses: Partial<Record<TCurrency, number>>;
  balance: Record<string, number>;
  incomeSumm: Record<string, number>;
  uuid?: string;
}

const {
  balanceSvg,
  bonusSvg,
  fundsInProcessingSvg,
  walletSvg,
  IncomeTsx,
} = Icons;

const PaymentsStats: React.FC<Props> = ({
  balance,
  incomeSumm,
  pendingTransactions,
  uuid,
  formattedBonuses,
}: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  return (
    <Box>
      {uuid && (
        <PaymentListItem
          label={t('PaymentsPage.yourWallet')}
          icon={walletSvg}
          value={
            <CopyToClipboard text={uuid} onCopy={() => toast.success(t('settings.copied'))}>
              <Typography className={classes.accountNumber}>
                {uuid}
              </Typography>
            </CopyToClipboard>
          }
        />
      )}

      {Object.entries(balance).map(([key, value]) => {
        const [formattedValue] = currencyFormatter(value, key).split(' ');

        return (
          <PaymentListItem
            key={key}
            label={t('PaymentsPage.balance')}
            icon={balanceSvg}
            value={
              <Box className={classes.balanceEndAdornment}>
                <Typography className={classes.formattedValue}>{formattedValue}</Typography>
                <Typography className={classes.currency}>{key}</Typography>
              </Box>
            }
          />
        );
      })}

      {Object.entries(formattedBonuses).map(([currency, value]) => {
        const [formattedValue] = currencyFormatter(Math.abs(value ?? 0), currency).split(' ');

        return (
          <PaymentListItem
            key={currency}
            label={t('PaymentsPage.bonus')}
            icon={bonusSvg}
            value={
              <Box className={classes.balanceEndAdornment}>
                <Typography className={classes.formattedValue}>{formattedValue}</Typography>
                <Typography className={classes.currency}>{currency}</Typography>
              </Box>
            }
          />
        );
      })}

      {Object.entries(pendingTransactions).map(([currency, { outbound, inbound }]) => {
        const [formattedInboundValue] = currencyFormatter(Math.abs(inbound ?? 0), currency).split(' ');
        const [formattedOutboundValue] = currencyFormatter(Math.abs(outbound ?? 0), currency).split(' ');

        return (
          <Box key={currency}>
            <PaymentListItem
              label={t('PaymentsPage.fundsInProcessing')}
              icon={fundsInProcessingSvg}
              value={
                <Box className={classNames(classes.balanceEndAdornment, classes.balanceEndAdornmentGreen)}>
                  <Typography className={classes.formattedValue}>{formattedInboundValue}</Typography>
                  <Typography className={classes.currency}>{currency}</Typography>
                </Box>
              }
            />

            <PaymentListItem
              label={t('PaymentsPage.fundsOutProcessing')}
              icon={fundsInProcessingSvg}
              value={
                <Box className={classNames(classes.balanceEndAdornment, classes.balanceEndAdornmentRed)}>
                  <Typography className={classes.formattedValue}>{formattedOutboundValue}</Typography>
                  <Typography className={classes.currency}>{currency}</Typography>
                </Box>
              }
            />
          </Box>
        );
      })}

      {Object.entries(incomeSumm).map(([incomeCurrency, incomeValue]) => {
        const [formattedValue] = currencyFormatter(incomeValue, incomeCurrency).split(' ');

        return (
          <PaymentListItem
            key={incomeCurrency}
            label={t('PaymentsPage.income')}
            icon={
              <Box className={classes.statsSvg}>
                <IncomeTsx width={25} height={25} color={cyan} />
              </Box>
            }
            value={
              <Box className={classNames(classes.balanceEndAdornment)}>
                <Typography className={classes.formattedValue}>{formattedValue}</Typography>
                <Typography className={classes.currency}>{incomeCurrency}</Typography>
              </Box>
            }
          />
        );
      })}
    </Box>
  );
};

export default PaymentsStats;

PaymentsStats.defaultProps = {
  uuid: '',
};
