import React, { useCallback, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IndexedDB from 'indexedDB';
import { useProvideAuth, authContext } from './services/auth';
import { useProvideOrders, ordersContext } from './services/orders';
import { useProvideSettings, settingsContext } from './pages/SettingsPage/SettingsContext';
import AppRouter from './AppRouter';
import { INDEXED_DB_TABLES } from './constants';

const App: React.FC = () => {
  const auth = useProvideAuth();
  const settings = useProvideSettings(auth.user);
  const orders = useProvideOrders();

  const handleRegisterServiceWorker = useCallback(async () => {
    const { setIsLoading } = auth;
    const isSupported = 'serviceWorker' in navigator;
    if (!isSupported) return;

    setIsLoading(true);
    try {
      await navigator.serviceWorker.register(`${window.location.origin}/service-worker.js`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    new IndexedDB().createDB(INDEXED_DB_TABLES);
    window.addEventListener('load', handleRegisterServiceWorker);

    return () => {
      window.removeEventListener('load', handleRegisterServiceWorker);
    };
  }, []);

  return (
    <authContext.Provider value={auth}>
      <ordersContext.Provider value={orders}>
        <settingsContext.Provider value={settings}>
          <AppRouter />
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            draggable
            pauseOnHover
            limit={2}
          />
        </settingsContext.Provider>
      </ordersContext.Provider>
    </authContext.Provider>
  );
};

export default App;
