import { Box, Typography } from '@material-ui/core';
import CollapseBox from 'components/CollapseComponent';
import { toast } from 'react-toastify';
import CopyToClipboard from 'react-copy-to-clipboard';
import InfoToggle from 'components/InfoToggle/InfoToggle';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { getTranslateFunction } from 'helpers';
import { IRating, User, type IUser } from 'interfaces';
import { getUserByReferralId } from 'services/commonService';
import { useUserRating } from 'hooks/rating';
import ReferralItem from './ReferralItem';
import useStyles from '../styles';

type Props = {
  user: User;
  referrals: User[];
  usersRating: IRating[];
  menuShareTop: string;
};

export type ShareUser = Pick<IUser, 'referralId' | 'firstName' | 'lastName'>;
export type ShareUserRating = Pick<IRating, 'politenessRatingAvg'>;

const INVITED_BY_DEFAULT_USER: ShareUser = {
  referralId: '0 000 000 001',
  firstName: 'PPWW',
  lastName: '',
};

const INVITED_BY_DEFAULT_USER_RATING: ShareUserRating = {
  politenessRatingAvg: 10,
};

const ShareComponent = ({ user, referrals, usersRating, menuShareTop }: Props) => {
  const t = getTranslateFunction();
  const classes = useStyles();
  const [invitedByUser, setInvitedByUser] = useState<IUser>();
  const [showLastReferrals, setShowLastReferrals] = useState(true);

  if (!user) return null;

  useEffect(() => {
    if (user.invitedBy) {
      const fetchReferralUser = async () => {
        const [referralUser] = await getUserByReferralId(user.invitedBy);
        setInvitedByUser(referralUser);
      };
      fetchReferralUser();
    }
  }, []);

  const { rating: invitedByUserRating } = useUserRating(invitedByUser?.uuid);

  const link = `app.ppww.eu/signup?invitedBy=${user.referralId}`;

  const reversedReferrals = Array.isArray(referrals) ? [...referrals].reverse() : [];

  const invitedBy = invitedByUser ?? INVITED_BY_DEFAULT_USER;
  const invitedByRating = invitedByUserRating ?? INVITED_BY_DEFAULT_USER_RATING;

  const toggleLastReferrals = () => {
    setShowLastReferrals(() => !showLastReferrals);
  };

  return (
    <Box className={classes.contentWrapper}>
      {menuShareTop && <InfoToggle description={menuShareTop} btnLabel="sharePage.infoToggle.btn" />}

      <Box className={classes.referralIdBox}>
        <CopyToClipboard text={link} onCopy={() => toast.success(t('sharePage.link-copied'))}>
          <Typography className={classes.referralIdText}>
            {t('profilePage.YourReferralId')}
            {' - '}
            {user.referralId}
          </Typography>
        </CopyToClipboard>
      </Box>

      <div className={classes.referralFieldWrapper}>
        <Typography>{t('sharePage.whoInvited')}</Typography>
        <ReferralItem user={invitedBy} rating={invitedByRating} />
      </div>

      <Box onClick={toggleLastReferrals}>
        <CollapseBox title={`${t('sharePage.referrals')} ${referrals.length}`}>
          <Box className={classes.referralList}>
            {reversedReferrals.map((referral: User) => {
              const rating = usersRating.find((currRating) => currRating.id === referral?.id);
              if (!(rating && referral)) return null;

              return <ReferralItem user={referral} rating={rating} key={referral.id} />;
            })}
          </Box>
        </CollapseBox>

        {showLastReferrals && (
          <Box className={classNames(classes.referralList, { showLastReferrals })}>
            {reversedReferrals.slice(0, 2).map((referral: User) => {
              const rating = usersRating.find((currRating) => currRating.id === referral?.id);
              if (!(rating && referral)) return null;

              return <ReferralItem user={referral} rating={rating} key={referral.id} />;
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ShareComponent;
