import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import Preloader from 'components/Preloader/Preloader';
import { useActiveChat } from 'hooks/chat';
import { IOnChange } from 'interfaces';
import { useLayout } from 'context/layout';
import { getTranslateFunction } from 'helpers';
import Chat from './components/Chat';
import { blockAndDeleteChatAction, deleteChatAction, complaintAndDeleteChatAction } from './config';

const ActiveChat: React.FC = () => {
  const history = useHistory();
  const { setTitle } = useLayout();
  const t = getTranslateFunction();

  const {
    me,
    messages,
    chatOptions,
    chatUser,
    roomId,
    orderType,
    isLoading,
    loadMoreMessages,
    sendMessage,
    blockChat,
    deleteChat,
    complaintChat,
  } = useActiveChat();

  const handleGoBack = () => {
    if (history.action === 'POP') {
      history.push('/chat');
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    setTitle(t('footer.chat'));

    return () => {
      setTitle('');
    };
  }, []);

  const handleDeleteChat = async () => {
    const response = await deleteChat();
    if (response) {
      handleGoBack();
    }
  };

  const handleBlockChat = async () => {
    const response = await blockChat();
    if (response) {
      handleDeleteChat();
    }
  };

  const handleComplaintAndDelete = async (reason: string) => {
    const response = await complaintChat(reason);
    if (response) {
      handleDeleteChat();
    }
  };

  const handleChange = ({ action, data }: IOnChange) => {
    switch (action) {
      case 'clearActiveChat': {
        handleGoBack();
        break;
      }
      case 'sendMessage': {
        sendMessage(data);
        break;
      }
      case 'loadMoreMessages': {
        loadMoreMessages();
        break;
      }
      case blockAndDeleteChatAction: {
        handleBlockChat();
        break;
      }
      case deleteChatAction: {
        handleDeleteChat();
        break;
      }
      case complaintAndDeleteChatAction: {
        handleComplaintAndDelete(data);
        break;
      }
      default: break;
    }
  };

  return (
    <>
      <Chat
        me={me}
        orderType={orderType}
        roomId={roomId}
        user={chatUser}
        chatOptions={chatOptions}
        messages={messages}
        onChange={handleChange}
      />

      {isLoading && <Preloader />}
    </>
  );
};

export default ActiveChat;
