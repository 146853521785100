import { Box, Typography } from '@material-ui/core';
import classNames from 'classnames';
import Star from 'images/star';
import { useMemo } from 'react';
import userSvg from 'images/icons/user.svg';
import type { ShareUser, ShareUserRating } from './ShareComponent';
import useStyles from '../styles';

type Props = {
  user: ShareUser;
  rating: ShareUserRating;
};

const ReferralItem = ({ user, rating }: Props) => {
  const classes = useStyles();

  const color = useMemo(() => {
    const value = Number(rating.politenessRatingAvg);
    if (value === 10) return 'green';
    if (value === 5) return 'yellow';

    return 'red';
  }, [rating]);

  const username = `${user?.firstName} ${user?.lastName}`;

  return (
    <Box className={classes.referralContainer}>
      <img src={userSvg} alt="sharePage.icon" className={classes.iconAdornment} />
      <Typography className={classes.username}>{username}</Typography>
      <Typography className={classes.idLabel}>ID</Typography>
      <Typography className={classes.idValue}>{user?.referralId}</Typography>

      <Box className={classes.ratingContainer}>
        <Box className={classes.svg}>
          <Star color={color} />
        </Box>
        <Typography className={classNames(classes.ratingValue, color)}>{Number(rating.politenessRatingAvg)}</Typography>
      </Box>
    </Box>
  );
};

export default ReferralItem;
