import { Box, Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import classNames from 'classnames';
import React from 'react';
import { CFPlacePickerItem } from 'interfaces';
import useStyles from '../ConstructorStyles';

interface Props extends Omit<CFPlacePickerItem, 'type'> {
  addPoint: () => void;
}

const PlacePicker: React.FC<Props> = ({
  items,
  activePoint,
  addPoint,
}) => {
  const classes = useStyles();
  return (
    <Box key={`${items.length}${activePoint}`} className={classNames(classes.inputContainer, 'withoutBorder')}>
      {items}
      {typeof activePoint !== 'number' && (
        <Button
          variant="contained"
          data-map-btn="add-point"
          className={classes.buttonAddPoint}
          onClick={addPoint}
        >
          <Add />
        </Button>
      )}
    </Box>
  );
};

export default PlacePicker;
