import { useEffect, useState } from 'react';
import Preloader from 'components/Preloader/Preloader';

import { UseFormMethods } from 'react-hook-form';
import { Box } from '@material-ui/core';
import {
  CFArrayItem,
  CFAutocompleteItem,
  CFCarComboboxItem,
  CFDatePickerItem,
  CFFilesItem,
  CFInputItem,
  CFItem,
  CFPlacePickerItem,
  CFRadioItem,
  IOnChange,
} from 'interfaces';
import TextFieldItem from './items/TextFieldItem';
import CheckboxItem from './items/CheckboxItem';
import RadioItem from './items/RadioItem';
import Passengers from './items/Passengers';
import PlacePicker from './items/PlacePicker';
import DatePickerItem from './items/DatePicker';
import CarComboboxItem from './items/CarCombobox/CarCombobox';
import AutocompleteItem from './items/AutoComplete';
import FilesItem from './items/FilesItem';
import useStyles from './ConstructorStyles';
import { ModelItem } from './items/CarCombobox/useBrands';

type FormMethods = 'control' | 'setValue' | 'getValues' | 'register' | 'errors' | 'clearErrors';

interface Props extends Pick<UseFormMethods, FormMethods> {
  optionsList: CFItem[];
  onChange: (a: IOnChange) => void;
  brands: string[];
  models: ModelItem[];
}

const ConstructorForm = ({
  optionsList,
  register,
  control,
  errors,
  onChange,
  brands,
  models,
  setValue,
  getValues,
  clearErrors,
}: Props) => {
  const [list, setList] = useState(optionsList);

  useEffect(() => {
    setList(optionsList);
  }, [optionsList]);

  const classes = useStyles();

  if (!list.length) {
    return <Preloader />;
  }
  const returnItems = list.map((item: CFItem) => {
    const { type } = item;

    switch (type) {
      case 'array': {
        const { items } = item as CFArrayItem;
        const childrens = items.map((object: CFItem) => {
          switch (object.type) {
            case 'passengers': {
              return (
                <Passengers
                  key={object.name}
                  item={object}
                  control={control}
                />
              );
            }
            case 'checkbox': {
              return (
                <CheckboxItem
                  key={object.name}
                  errors={errors}
                  item={object}
                  control={control}
                />
              );
            }
            default: {
              return null;
            }
          }
        });
        return (
          <Box key={items.length} className={classes.inputContainer}>
            {childrens}
          </Box>
        );
      }
      case 'radio': {
        const radioItem = item as CFRadioItem;
        const { name } = radioItem;

        return (
          <RadioItem
            key={name}
            item={radioItem}
            control={control}
            getValues={getValues}
            setValue={setValue}
          />
        );
      }
      case 'placePicker': {
        const placePickerItem = item as CFPlacePickerItem;
        const { items } = placePickerItem;

        return items && (
          <PlacePicker
            key={type}
            addPoint={placePickerItem.addPoint}
            activePoint={placePickerItem.activePoint}
            items={items}
          />
        );
      }
      case 'text': {
        const textItem = item as CFInputItem;
        const { name } = textItem;

        return (
          <TextFieldItem
            key={name}
            item={textItem}
            control={control}
            getValues={getValues}
            setValue={setValue}
          />
        );
      }
      case 'datePicker': {
        const datePickerItem = item as CFDatePickerItem;
        const { name } = datePickerItem;

        return (
          <DatePickerItem
            key={name}
            item={datePickerItem}
            register={register}
            control={control}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
          />
        );
      }
      case 'carCombobox': {
        const carComboboxItem = item as CFCarComboboxItem;
        const { mode } = carComboboxItem;

        return (
          <CarComboboxItem
            clearErrors={clearErrors}
            onChange={onChange}
            brands={brands}
            models={models}
            errors={errors}
            key={type}
            control={control}
            classes={classes}
            setValue={setValue}
            mode={mode}
          />
        );
      }
      case 'autocomplete': {
        const autocompleteItem = item as CFAutocompleteItem;
        const { name } = autocompleteItem;

        return autocompleteItem && (
        <AutocompleteItem
          key={name}
          item={autocompleteItem}
          clearErrors={clearErrors}
          errors={errors}
          control={control}
          setValue={setValue}
          getValues={getValues}
        />
        );
      }
      case 'files': {
        return (
          <FilesItem
            item={item as CFFilesItem}
            key={type}
            control={control}
            classes={classes}
            setValue={setValue}
            getValues={getValues}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  });

  return (
    <>
      {returnItems}
    </>
  );
};

export default ConstructorForm;
