import React from 'react';
import { IconComponentProps } from 'interfaces';

const Orders: React.FC<IconComponentProps> = ({ color, width, height }) => (
  <svg
    version="1.1"
    width={width}
    height={height}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 72"
  >
    <path
      fill={color}
      d="M22.98 47.33h26.97v4.91H22.98zM22.98 27.24h26.97v4.91H22.98zM22.98 37.29h26.97v4.91H22.98zM34.01 10.14h-5.35v4.91h15.61v-4.91h-5.35V7.7h9.23V2.79H24.78V7.7h9.23z"
    />
    <path
      fill={color}
      d="M47.61 10.14v4.91H58.3v49.38H14.63V15.05h10.69v-4.91H9.72v59.2h53.49v-59.2z"
    />
  </svg>
);

export default Orders;
