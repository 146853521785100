import { useEffect, useState, type ChangeEvent } from 'react';
import { Box, Button, FormHelperText, TextField } from '@material-ui/core';
import CollapseBox from 'components/CollapseComponent';
import { useForm } from 'react-hook-form';
import ConfirmCode from 'components/ConfirmCode';
import classNames from 'classnames';
import Modal from 'sharedComponents/Modal';
import { getTranslateFunction } from 'helpers';
import emailSvg from 'images/icons/email.svg';
import { useCountdown, useRequests } from 'services/customHooks';
import i18n from 'i18n';
import { checkAvailability } from 'services/commonService';
import { useAuth } from 'services/auth';
import { emailRegExp, EMAIL_EDIT_ATTEMPTS, EMAIL_TIME_REQUEST } from '../../../constants';
import useStyles from '../SettingsPageStyle';

const TIME_TO_SHOW_MODAL = 7000;

type Props = {
  email?: string;
};

const ChangeEmail = ({ email = '' }: Props) => {
  const t = getTranslateFunction();
  const classes = useStyles();

  const [currentStage, setCurrentStage] = useState<'init' | 'confirm' | 'update' | 'confirmUpdate'>('init');
  const [isConfirmUpdate, setIsConfirmUpdate] = useState(false);
  const [codeCreationTime, setCodeCreationTime] = useState(0);
  const [currentEmail, setCurrentEmail] = useState(() => email);
  const [emailEditCount, setEmailEditCount] = useState(0);
  const [emailError, setEmailError] = useState('');
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const generateType = 'email';

  const { count, setCount } = useCountdown();
  const { sendRequest, sendCode, attempts, isAttemptsForCodeLeft } = useRequests();
  const { user } = useAuth();

  const { handleSubmit, register, errors } = useForm({
    defaultValues: { email },
  });

  const initiateEmailChangeRequest = async () => {
    try {
      setCurrentStage('confirm');
      setFormErrorMessage('');
      const data = { email, lang: i18n.language, userId: user?.id, generateType };
      await sendRequest('/users/create-profile-validation', data);
      setCodeCreationTime(new Date().getTime());
    } catch (error) {
      const e = error as Error;
      setFormErrorMessage(e.message);
    }
  };

  const initiateEmailUpdateRequest = async () => {
    try {
      setIsConfirmUpdate(true);
      setFormErrorMessage('');
      const data = { email: currentEmail, lang: i18n.language, userId: user?.id, generateType };
      await sendRequest('/users/create-profile-validation', data);
      setCodeCreationTime(new Date().getTime());
    } catch (error) {
      const e = error as Error;
      setFormErrorMessage(e.message);
    }
  };

  const confirmVerificationCode = async (emailCode: string) => {
    const data = {
      email: user?.email,
      emailCode: Number(emailCode),
      generateType,
    };

    const res = await sendCode('/users/check-profile-validation', data);

    if (!res) {
      return;
    }

    setCurrentStage('update');
  };

  const validateAndRequestEmailChange = async () => {
    const isValid = currentEmail.match(emailRegExp);

    if (!isValid) {
      setEmailError('error.email.invalid');
      return;
    }

    const emailCheck = await checkAvailability('email', currentEmail);

    if (!emailCheck.result) {
      setEmailError('error.email.exist');
      return;
    }

    setEmailEditCount((prev) => {
      const newValue = prev + 1;
      if (newValue < EMAIL_EDIT_ATTEMPTS) {
        setCount(EMAIL_TIME_REQUEST);
      }
      return newValue;
    });

    initiateEmailUpdateRequest();
  };

  const resetToInitialStage = () => {
    setCurrentStage('init');
  };

  const completeEmailEditing = () => {
    resetToInitialStage();
    setCount(0);
    setModalVisible(true);
  };

  const sendVerificationCode = async (data: { email: string; id?: number; emailCode: number }) => {
    return sendCode('/users/update-email', data);
  };

  const finalizeEmailChange = async (emailCode: string) => {
    const data = {
      email: currentEmail,
      id: user?.id,
      emailCode: Number(emailCode),
    };

    await sendVerificationCode(data);
    completeEmailEditing();
  };

  const updateEmailValue = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentEmail(e.target.value);
  };

  const revertEmailChange = () => {
    setCurrentEmail(email);
    resetToInitialStage();
    setCount(0);
  };

  const submitEmailChange = async () => {
    await validateAndRequestEmailChange();
  };

  useEffect(() => {
    if (modalVisible) {
      const timerId = setTimeout(() => {
        setModalVisible(false);
      }, TIME_TO_SHOW_MODAL);

      return () => clearTimeout(timerId);
    }

    return () => {};
  }, [modalVisible]);

  const shouldEditEmail = !count && emailEditCount < EMAIL_EDIT_ATTEMPTS;

  return (
    <CollapseBox title={t('settings.changeEmail.title')} withoutMargins>
      {currentStage === 'init' && (
        <form onSubmit={handleSubmit(initiateEmailChangeRequest)} className={classes.changeEmailForm}>
          <TextField
            name="email"
            value={currentEmail}
            className={classNames(classes.changeEmailTextField, { error: emailError })}
            onFocus={() => setEmailError('')}
            disabled
            inputRef={register({
              pattern: emailRegExp,
            })}
            InputProps={{
              disableUnderline: true,
              startAdornment: <img src={emailSvg} alt="phone" className={classes.iconAdornment} />,
            }}
          />

          {emailError && <FormHelperText className={classes.changeEmailErrorMessage}>{t(emailError)}</FormHelperText>}
          <FormHelperText id="component-error-text">{errors?.email?.message}</FormHelperText>

          <Box className={classNames(classes.updateProfileSubmitButtonWrapper, classes.changeEmailButtonWrapper)}>
            <Button className={classes.updateProfileSubmitButton} type="submit">
              {t('settings.profile.update')}
            </Button>
          </Box>
        </form>
      )}

      {currentStage === 'confirm' && (
        <>
          <ConfirmCode
            createdCodeTime={codeCreationTime}
            isAttemptsForCodeLeft={isAttemptsForCodeLeft}
            onSendCode={confirmVerificationCode}
            resendRequest={initiateEmailChangeRequest}
            attempts={attempts}
          />

          <FormHelperText className={classes.updateProfileErrorMessage}>{t(formErrorMessage)}</FormHelperText>

          <Button className={classes.updateProfileCancelButton} onClick={revertEmailChange}>
            {t('settings.profile.cancel')}
          </Button>
        </>
      )}

      {currentStage === 'update' && (
        <>
          <form onSubmit={handleSubmit(submitEmailChange)} className={classes.changeEmailForm}>
            <TextField
              name="email"
              value={currentEmail}
              onChange={updateEmailValue}
              className={classNames(classes.changeEmailTextField, { error: emailError })}
              onFocus={() => setEmailError('')}
              disabled={!shouldEditEmail || isConfirmUpdate}
              inputRef={register({
                pattern: emailRegExp,
              })}
              InputProps={{
                disableUnderline: true,
                startAdornment: <img src={emailSvg} alt="phone" className={classes.iconAdornment} />,
              }}
            />

            {emailError && <FormHelperText className={classes.changeEmailErrorMessage}>{t(emailError)}</FormHelperText>}
            <FormHelperText id="component-error-text">{errors?.email?.message}</FormHelperText>

            {!isConfirmUpdate && (
              <Box className={classes.btnContainer}>
                <Button className={classes.updateProfileCancelButton} onClick={resetToInitialStage}>
                  {t('settings.profile.cancel')}
                </Button>

                <Button className={classes.updateProfileSubmitButton} type="submit">
                  {t('settings.profile.update')}
                </Button>
              </Box>
            )}
          </form>

          {isConfirmUpdate && (
            <>
              <ConfirmCode
                createdCodeTime={codeCreationTime}
                isAttemptsForCodeLeft={isAttemptsForCodeLeft}
                onSendCode={finalizeEmailChange}
                resendRequest={initiateEmailChangeRequest}
                attempts={attempts}
              />

              <FormHelperText className={classes.updateProfileErrorMessage}>{t(formErrorMessage)}</FormHelperText>

              <Button className={classes.updateProfileCancelButton} onClick={revertEmailChange}>
                {t('settings.profile.cancel')}
              </Button>
            </>
          )}
        </>
      )}

      <Modal withoutBorder isOpen={modalVisible}>
        <div className={classes.updateProfileModal}>{t('settings.profile.ok')}</div>
      </Modal>
    </CollapseBox>
  );
};

export default ChangeEmail;
