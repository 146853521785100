import { makeStyles } from '@material-ui/core/styles';
import {
  backgroundColor,
  cyan,
  grayBG,
  green,
  red,
  textColor,
} from '../../constants';

export default makeStyles({
  listItemBox: {
    width: '100%',
    '& .MuiTypography-root': {
      fontSize: '13px',
    },
  },
  vendorContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: grayBG,
    padding: '5px 0',
  },
  vendor: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  services: {
    paddingRight: '15px',
    borderRight: `1px ${textColor} solid`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
  vendorPhoto: {
    width: 'auto',
    height: '80px',
  },
  vendorInfo: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 0',
    '& > *': {
      margin: '0 25px',
    },
    '& .MuiTypography-root': {
      textAlign: 'center',
    },
  },
  propouse: {
    border: `2px ${cyan} solid`,
    color: cyan,
    textAlign: 'center',
  },
  vendorStats: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '5px',
    flexWrap: 'wrap',
    borderTop: `1px ${textColor} solid`,
    '& > *': {
      textAlign: 'center',
    },
    '& .MuiTypography-root': {
      '&:last-child ': {
        color: green,
        fontWeight: 'bold',
      },
    },
  },
  btnsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: '5px 0',
    '& .MuiButton-root': {
      padding: '0 15px',
    },
  },
  btnDecline: {
    color: backgroundColor,
    backgroundColor: red,
  },
  btnAccept: {
    color: backgroundColor,
    backgroundColor: green,
  },
  modal: {
    position: 'absolute',
    zIndex: 999,
    backgroundColor: `${textColor}60`,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    overflowY: 'scroll',
    '& .MuiDialog-paper': {
      margin: '10px',
      width: '100%',
    },
  },
  modalContent: {
    backgroundColor,
    width: '100%',
    padding: '10px 0',
    boxSizing: 'border-box',
  },
  preorderContainer: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '5px 20px 65px',
    backgroundColor,
  },
  waitingLabel: {
    textAlign: 'center',
    margin: '10px 0',
    fontWeight: 'bold',
    paddingBottom: '3px',
    borderBottom: `2px solid ${textColor}`,
    '& .MuiTypography-body1': {
      backgroundColor: green,
      color: backgroundColor,
    },
  },
  svg: {
    minWidth: '20px',
    height: '20px',
    marginRight: '5px',
  },
  endAdornment: {
    backgroundColor: grayBG,
    marginLeft: '5px',
    color: green,
    padding: 5,
  },
  preorderOption: {
    padding: '3px',
    margin: '3px 0',
  },
  listItemLabel: {
    width: '100%',
    backgroundColor: grayBG,
    padding: '5px',
    wordBreak: 'break-word',
  },
  button: {
    textAlign: 'center',
    fontSize: '13px',
    width: '45%',
    marginTop: '5px',
    boxSizing: 'border-box',
    padding: '10px',
    fontFamily: 'Inter, sans-serif',
    cursor: 'pointer',
    color: backgroundColor,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.edit': {
      backgroundColor: green,
    },
    '&.cancel': {
      backgroundColor: red,
    },
    '&.raise': {
      backgroundColor: cyan,
    },
    '&.price': {
      justifyContent: 'flex-start',
      padding: '0',
      color: green,
      fontWeight: 'bold',
      '& .MuiTypography-body1': {
        display: 'flex',
        height: '100%',
        color: green,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: grayBG,
      },
    },
  },
  preorderInfo: {
  },
  symbol: {
    fontWeight: 'bold',
    padding: '0 15px',
    fontSize: '20px',
  },
  listItemDotsAddress: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px',
    margin: '3px 0',
    '& .MuiTypography-root': {
      fontSize: '16px',
      width: '100%',
      backgroundColor: grayBG,
      padding: '5px',
    },
  },
  btnBox: {
    borderTop: `2px solid ${textColor}`,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  priceBtns: {
    display: 'flex',
    alignItems: 'center',
  },
  priceBox: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  infoAboutCarPreorder: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& > *': {
      width: '45%',
      marginTop: '5px',
      padding: '3px',
      backgroundColor: grayBG,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  preorderGalleryBox: {
    marginLeft: '25px',
  },
}, { index: 1 });
