import { Box, Button } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import icons from 'components/Icons';
import useStyles from '../OrderPageStyle';
import { cyan } from '../../../constants';

const {
  PhoneTsx,
  CommentTsx,
} = icons;

interface Props {
  showPhoneBtn: boolean;
  showChatBtn: boolean;
  phone: string;
  handleChange: (a: string) => void;
}

const ButtonsBlock: React.FC<Props> = ({
  showPhoneBtn,
  phone,
  showChatBtn,
  handleChange,
}: Props) => {
  const classes = useStyles();

  return (
    <Box>
      {showPhoneBtn && (
        <Button onClick={() => handleChange('handleCallToClient')} className={classNames(classes.iconBtn, 'phone')}>
          <a href={`tel:${phone}`}>
            <PhoneTsx color={cyan} />
          </a>
        </Button>
      )}
      {showChatBtn && (
        <Button className={classes.iconBtn} onClick={() => handleChange('handleChatToClient')}>
          <CommentTsx color={cyan} />
        </Button>
      )}
    </Box>
  );
};

export default ButtonsBlock;
