import React from 'react';
import { IconComponentProps } from 'interfaces';

const Exit: React.FC<IconComponentProps> = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 72 72"
  >
    <path
      fill={color}
      d="M70.53 32.48L54.5 22.47v7H39.95v6.02H54.5v7z"
    />
    <path
      fill={color}
      d="M46.18 57.74H27.4V13.08L12.49 7.22h33.69v17.54h3.72V3.5H3.02v57.96h.22l24.16 9.49v-9.49h22.5V40.2h-3.72z"
    />
  </svg>
);

export default Exit;
