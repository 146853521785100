import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppInfoTextOnly } from 'hooks/appInfo';
import { IOnChange, OrderType } from 'interfaces';
import { useAuth } from 'services/auth';
import {
  filteredButtons,
  filteredOptions,
  HomepageButton,
} from 'utils/optionsConfig';
import { useLayout } from 'context/layout';
import { getTranslateFunction } from 'helpers';
import DropdownOptionsComponent, { ButtonItem } from '../components/DropdownOptionsComponent';
import { configUrlByTypes } from '../helper';

const POSITIONS = [
  'menuExecutorProjectsTop',
];

const DropdownOptions: React.FC = () => {
  const { vendor } = useAuth();
  const history = useHistory();
  const params = useParams<{ [prop: string]: string }>();
  const { setTitle } = useLayout();
  const t = getTranslateFunction();
  const innerType = params.id as OrderType;
  const appInfo = useAppInfoTextOnly(POSITIONS);

  const handleRoute = (to: string) => history.push(to);

  const handleCheckItem = ({ checked, currentType }: {
    checked: HomepageButton[];
    currentType: string;
  }) => {
    if (!checked) return;

    if (vendor) {
      const [type, result] = configUrlByTypes(history.location.pathname, checked);

      handleRoute(`/vendor/${type}?${result}`);
      return;
    }

    const items = checked;
    const [currentItem] = items;
    const query = items.map((innerItem: ButtonItem) => `${innerItem.service}=[${innerItem.contentKey}]`);

    const to = filteredButtons.includes(currentItem.type)
    || filteredOptions.includes(currentItem.type)
      ? `/preorder/${currentType}?${query}`
      : '/';

    handleRoute(to);
  };

  const handleChange = ({ data, action }: IOnChange) => {
    switch (action) {
      case 'checkItem': {
        handleCheckItem(data);
        break;
      }
      case 'historyPush': {
        history.push(data);
        break;
      }
      default: break;
    }
  };

  useEffect(() => {
    setTitle(t(innerType));

    return () => {
      setTitle('');
    };
  }, [innerType]);

  return (
    <DropdownOptionsComponent
      currentType={innerType}
      menuExecutorProjectsTop={appInfo.menuExecutorProjectsTop}
      onChange={handleChange}
      vendor={vendor}
    />
  );
};

export default DropdownOptions;
