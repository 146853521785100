import { makeStyles } from '@material-ui/core';
import { cyan, grayPrimary } from '../../constants';

export default makeStyles(
  {
    page: {
      padding: 10,
    },
    text: {
      fontSize: 14,
      padding: 10,
      backgroundColor: grayPrimary,
      width: '100%',
      boxSizing: 'border-box',
      maxHeight: 400,
      overflow: 'auto',

      '& a': {
        textDecoration: 'none',

        '& strong': {
          color: cyan,
        },
      },

      '& > p, & > ol, & > ul, & > li': {
        margin: 0,
      },

    },
    galleryWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  { index: 1 },
);
