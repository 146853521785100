import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Preorder, User } from 'interfaces';
import { getClientRating, getUsers } from 'services/commonService';
import RequestService from 'services/requestApi';
import { hyphensToCamelCase } from 'utils/formatters';
import { SINGLE_TYPES } from '../../constants';

export const usePreorderService = (preorder: Preorder) => {
  const [user, setUser] = useState<User>(null);
  const [userRating, setUserRating] = useState(null);
  const [coords, setCoords] = useState<[number, number] | null>(null);

  const handleGetUserRating = useCallback(async (uuid) => {
    const response = await getClientRating(uuid);

    setUserRating(response);
  }, []);

  const handleGetUserInfo = useCallback(async () => {
    const response = await getUsers(preorder.userUuid);
    const [orderUser] = response;
    if (!orderUser) return;

    setUser(orderUser);

    handleGetUserRating(orderUser.uuid);
  }, [preorder]);

  const handleGetCoordinates = async () => {
    const response = await new RequestService('/users/userme').get();
    const { ip } = response;

    if (response.Error || !ip) return;

    setCoords(ip.ll);
  };

  useEffect(() => {
    handleGetCoordinates();
    handleGetUserInfo();

    return () => {
      setUser(null);
      setCoords(null);
      setUserRating(null);
    };
  }, []);

  const isSingleTypePreorder = useMemo(() => (
    SINGLE_TYPES.some((type) => type === hyphensToCamelCase(preorder.type))
  ), [preorder]);

  return {
    user,
    coords,
    userRating,
    isSingleTypePreorder,
  };
};
