import { makeStyles } from '@material-ui/core/styles';
import { backgroundColor } from '../../constants';

export default makeStyles({
  selectList: {
    padding: 0,
    backgroundColor,
  },
  inputGeo: {
    width: '100%',
    marginTop: '5px',
    backgroundColor,
    '& .MuiFilledInput-input': {
      padding: '12px 5px',
    },
    '& .MuiFilledInput-root': {
      borderRadius: '0',
    },
  },
  svg: {
    width: '20px',
    height: '20px ',
    marginRight: '5px',
  },
  boxBtnSelect: {
    position: 'absolute',
    zIndex: 400,
    bottom: 10,
    display: 'flex',
    justifyContent: 'center',
    left: '50px',
    right: '50px',
  },
  buttonSelectLocation: {
    fontWeight: 'bold',
  },
  buttonReturn: {
    fontWeight: 'bold',
    position: 'absolute',
    left: '-40px',
  },
  mapWrapper: {
    height: '100%',
    position: 'absolute',
    inset: 0,
  },
  selectGeolocationWrapper: {
    backgroundColor,
    position: 'absolute',
    inset: 0,
    zIndex: 100,
  },
  profile: {
    top: '50px',
    bottom: '74px',
  },
}, { index: 1 });
