import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { getTranslateFunction } from 'helpers';
import buyIcon from 'images/icons/buy.svg';
import alongTheWayIcon from 'images/icons/alongTheWay.svg';
import courierIcon from 'images/icons/courier.svg';
import helpOnRoadIcon from 'images/icons/helpOnRoad.svg';
import rentalIcon from 'images/icons/rental.svg';
import servicesIcon from 'images/icons/services.svg';
import { listItemStyles } from '../VendorHomePageStyle';

interface Props {
  item: {
    value: number;
    key: string;
  };
  index: number;
  onClick: (a: string) => void;
}

const icons: {
  [key: string]: string;
} = {
  alongTheWay: alongTheWayIcon,
  buyThings: buyIcon,
  helpOnRoad: helpOnRoadIcon,
  courier: courierIcon,
  rent: rentalIcon,
  services: servicesIcon,
};

const ServiceListItem: React.FC<Props> = ({
  index,
  item,
  onClick,
}: Props) => {
  const t = getTranslateFunction();
  const classes = listItemStyles({ index });

  return (
    <Box
      className={classes.listContainer}
      onClick={() => onClick(item.key)}
    >
      <Box className={classes.serviceItem}>
        <img src={icons[item.key]} alt={item.key} className={classes.svgIcon} />
        <Typography variant="caption" className={classes.serviceName}>{t(item.key)}</Typography>
      </Box>
      <Typography className={classes.serviceCount}>{item.value}</Typography>
    </Box>
  );
};

export default ServiceListItem;
