import React, { useMemo } from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { KeyboardBackspace } from '@material-ui/icons';

import { Link } from 'react-router-dom';
import ConfirmCode from 'components/ConfirmCode';
import logo from 'components/common/WebwayLogo.png';
import logoHourse from 'components/common/logoHourseWithBG.png';
import { getTranslateFunction } from 'helpers';
import { UseRequestResponse } from 'services/customHooks';
import { User } from 'interfaces';
import useStyles from '../ResetPasswordStyles';
import FirstStep from './FirstStep';
import { RESET_PASSWORD_STATUSES } from '../../../constants';
import SecondStep from './SecondStep';

interface Props extends Pick<UseRequestResponse, 'isAttemptsForCodeLeft' | 'attempts'> {
  status: number;
  sendRequest: (data: { login: string }) => Promise<void>;
  sendCode: (code: string) => Promise<void>;
  resendRequest: () => Promise<void>;
  resetPassword: (password: string) => Promise<User>;
}

const ResetPasswordComponent: React.FC<Props> = ({
  status,
  attempts,
  isAttemptsForCodeLeft,
  resetPassword,
  sendRequest,
  sendCode,
  resendRequest,
}) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  const createdCodeTime = useMemo(() => new Date().getTime(), [attempts]);

  return (
    <Box className={classes.mainContainer}>
      <Link className={classes.arrowBack} to="/signin">

        <KeyboardBackspace fontSize="large" />
        <Typography>{t('resetPassword.cancel')}</Typography>
      </Link>

      <Box className={classes.resetPasswordContainer}>

        <div>

          <Box className={classes.logoContainer}>
            <img className={classes.logoStyle} alt={t('webway.logo')} src={logo} />

            <Typography className={classes.siteLinkWrapper}>
              <Link
                className={classes.siteLink}
                to={t('signInPage.siteLink.link')}
                target="_blank"
              >
                {t('signInPage.siteLink.label')}
              </Link>
            </Typography>

          </Box>

          {status === RESET_PASSWORD_STATUSES.FILL_FORM && (
          <div>
            <Typography variant="h5" className={classes.headingStyle}>
              {t('resetPasswordPage.resetPassword')}
            </Typography>

            <FirstStep sendRequest={sendRequest} />
          </div>
          )}
        </div>

        {status === RESET_PASSWORD_STATUSES.ENTER_CODE && (
          <div>
            <ConfirmCode
              isAttemptsForCodeLeft={isAttemptsForCodeLeft}
              onSendCode={sendCode}
              createdCodeTime={createdCodeTime}
              resendRequest={resendRequest}
              attempts={attempts}
            />
          </div>
        )}

        {status === RESET_PASSWORD_STATUSES.ENTERING_NEW_PASSWORD && (
          <SecondStep sendNewPassword={resetPassword} />
        )}
        <Box className={classes.gifContainer}>
          <img className={classes.logoGif} src={logoHourse} alt="logo" />
        </Box>
      </Box>

    </Box>
  );
};

export default ResetPasswordComponent;
