import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { useTimer } from 'services/customHooks';
import { formatMsToSeconds } from 'utils/formatters';
import useStyles from './style';

interface Props {
  finishTime: number;
  onFinish?: () => void;
}

const Timer: React.FC<Props> = ({
  finishTime,
  onFinish,
}: Props) => {
  const classes = useStyles();
  const timer = useTimer({ finishTime, onFinish });

  return (
    <Typography className={classNames([classes.timer, { ended: !timer }])}>
      {formatMsToSeconds(timer)}
    </Typography>
  );
};

export default Timer;

Timer.defaultProps = {
  onFinish: () => {},
};
