import { Box, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';

interface IPaymentListItem {
  label: string;
  icon: React.ReactNode | string;
  value: React.ReactNode;
}

const PaymentListItem: React.FC<IPaymentListItem> = ({
  label,
  icon,
  value,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.balanceListItem}>
      <Box className={classes.balanceLabel}>
        {typeof icon === 'string' ? (
          <img className={classes.svg} src={icon} alt="" />
        ) : icon}
        <Typography className={classes.balanceTitle}>
          {label}
        </Typography>
      </Box>
      {value}
    </Box>
  );
};

export default PaymentListItem;
