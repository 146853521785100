import { INDEXEDDB_DATABASE_NAME } from './constants';

class IndexedDB {
  private dbName = INDEXEDDB_DATABASE_NAME;

  createDB(tables: {
    tableName: string,
    options?: IDBObjectStoreParameters
  }[]) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1);

      request.onupgradeneeded = () => {
        const db = request.result;

        tables.forEach(({ tableName, options }) => {
          db.createObjectStore(tableName, options);
        });

        resolve(true);
      };

      request.onsuccess = () => {
        const db = request.result;
        const created = Boolean(Array.from(db.objectStoreNames).length);
        resolve(created);
      };

      request.onerror = (error) => {
        reject(error);
      };
    });
  }

  setData(tableName: string, data: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1);

      request.onsuccess = () => {
        const db = request.result;
        const objectStore = db.transaction(tableName, 'readwrite').objectStore(tableName);
        objectStore.put(data);

        resolve(true);
      };

      request.onerror = (error) => {
        reject(error);
      };
    });
  }

  deleteData(tableName: string, key: string): Promise<IDBRequest> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1);

      request.onsuccess = () => {
        const db = request.result;
        const objectStore = db.transaction(tableName, 'readwrite').objectStore(tableName);
        const deleteResult = objectStore.delete(key);

        resolve(deleteResult);
      };

      request.onerror = (error) => {
        reject(error);
      };
    });
  }

  getData<T extends { value: any }>(tableName: string, key: string): Promise<T | undefined> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1);

      request.onsuccess = () => {
        const db = request.result;
        const transaction = db.transaction([tableName]);
        const store = transaction.objectStore(tableName);

        store.get(key).onsuccess = function (e: any) {
          const value = e.target.result;

          resolve(value);
        };
      };

      request.onerror = (error) => {
        reject(error);
      };
    });
  }
}

export default IndexedDB;
