import {
  Dialog,
} from '@material-ui/core';
import classNames from 'classnames';
import Portal from 'pages/SignUpPage/Portal';
import React from 'react';
import useStyles from './modalStyles';

interface Props {
  isOpen: boolean;
  withoutBorder?: boolean;
  withoutPadding?: boolean;
  children: React.ReactNode;
  fullWidth?: boolean;
  modalClassName?: string;
  backdropClassName?: string;
  onClose?: () => void;
}

const Modal: React.FC<Props> = ({
  isOpen,
  withoutBorder,
  withoutPadding,
  fullWidth,
  children,
  modalClassName,
  backdropClassName,
  onClose,
}) => {
  const classes = useStyles();
  return (
    <Portal>
      <Dialog
        fullWidth={fullWidth}
        open={isOpen}
        onClose={onClose}
        BackdropProps={{ className: backdropClassName }}
        PaperProps={{
          className: classNames([
            classes.modal,
            modalClassName,
            { [classes.withBorder]: !withoutBorder },
            { [classes.withoutPadding]: withoutPadding },
          ]),
        }}
      >
        {children}
      </Dialog>
    </Portal>
  );
};

export default Modal;

Modal.defaultProps = {
  withoutBorder: false,
  fullWidth: false,
  modalClassName: '',
  backdropClassName: '',
  onClose: () => {},
};
