import React, { FC } from 'react';
import { IconComponentProps } from 'interfaces';

const Message: FC<IconComponentProps> = ({ color, width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 72 72"
    width={width}
    height={height}
  >
    <path
      fill={color}
      d="M36.24 37.41l31.3-24.3H4.94zM44.44 35.59l26.74 20.77V14.82zM41.51 37.86l-5.19 4.04-.06.07-.02-.01-.02.01-.06-.07-5.19-4.04L3.89 58.89h64.7zM28.04 35.59L1.3 14.82v41.54z"
    />
  </svg>
);

export default Message;
