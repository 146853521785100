import { useCallback, useEffect, useState } from 'react';

export const usePreorderRoute = () => {
  const innerPointsState = [
    { label: 'start', value: '', id: '0' },
    { label: 'finish', value: '', id: '1' },
  ];
  const [points, setPoints] = useState<any[]>(innerPointsState);
  const [activePoint, setActivePoint] = useState<number | null>(null);

  const onChange = useCallback((i: number, obj: Record<string, unknown>) => {
    setPoints((prev) => {
      const updatedPrev = prev.map((item, index) => (
        index === i
          ? { ...item, ...obj }
          : item
      ));
      return updatedPrev;
    });
  }, []);

  const addPoint = useCallback(() => {
    setPoints((prev) => [
      ...prev,
      { value: '', id: String(new Date().getTime()) },
    ]);
  }, [onChange]);

  const handleBack = useCallback((InnerActivePoint) => {
    const newPoints = [...points];
    newPoints.splice(InnerActivePoint, 1, { onChange, value: '', id: String(new Date().getTime()) });
    setPoints(newPoints);
  }, [points]);

  const removePoint = useCallback((index) => {
    setPoints((prev) => prev.filter((_, innerIndex) => innerIndex !== index));
    setActivePoint(null);
  }, []);

  useEffect(() => {
    if (!activePoint) {
      return;
    }

    const isNumber = typeof activePoint === 'number';
    const isInvalidPoint = activePoint >= points.length || activePoint < 0;

    if (!isNumber || isInvalidPoint) setActivePoint(null);
  }, [activePoint, points]);

  return {
    onChange,
    setActivePoint,
    activePoint,
    addPoint,
    removePoint,
    points,
    setPoints,
    handleBack,
  };
};
