import { makeStyles } from '@material-ui/core/styles';
import { green } from '../../constants';

export default makeStyles({

  boxBtnSelect: {
    position: 'absolute',
    zIndex: 400,
    bottom: 10,
    display: 'flex',
    justifyContent: 'center',
    left: '50px',
    right: '50px',
  },
  buttonSelectLocation: {
    color: 'white',
    backgroundColor: green,
    fontWeight: 'bold',
  },
  buttonReturn: {
    color: 'white',
    backgroundColor: green,
    fontWeight: 'bold',
    position: 'absolute',
    left: '-40px',
  },
}, { index: 1 });
