import { Box, Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import VendorMap from 'pages/VendorOffers/VendorMap';
import React from 'react';
import { IOrder } from 'interfaces';
import useWindowSize from 'services/useWindowSize';
import useStyles from '../ClientOrderPageStyle';

interface Props {
  order: IOrder;
  vendorLocation: [number, number] | null;
  onChange: (a: string, b?: any) => void;
}

const OrderMap: React.FC<Props> = ({
  order,
  vendorLocation,
  onChange,
}: Props) => {
  const { height } = useWindowSize();
  const classes = useStyles(height);

  return (
    <Box className={classes.orderMapContainer}>
      <VendorMap items={[order]} locate={vendorLocation} />
      <Button
        className={classes.button}
        variant="contained"
        onClick={() => onChange('handleMap')}
      >
        <ArrowBack />
      </Button>
    </Box>
  );
};

export default OrderMap;
