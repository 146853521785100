import { Box, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import LanguageSvg from 'images/language';
import { useTranslation } from 'react-i18next';
import { getTranslateFunction } from 'helpers';
import useStyles from './LanguageSelectStyle';
import { LANGUAGES, textColor } from '../../constants';

const LanguageSelect: React.FC = () => {
  const classes = useStyles();
  const { i18n } = useTranslation('general');
  const t = getTranslateFunction();

  return (
    <Box className={classes.modal}>
      <Box className={classes.content}>
        <Box className={classes.svg}>
          <LanguageSvg color={textColor} />
        </Box>
        <Select
          value={i18n.language}
          onChange={({target: { value }}) => i18n.changeLanguage(value as string)}
          className={classes.select}
          disableUnderline
        >
          {LANGUAGES.map(({ label, info }) => (
            <MenuItem key={info.lang} value={info.lang}>{t(label)}</MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default LanguageSelect;
