import { makeStyles } from '@material-ui/core/styles';
import {
  backgroundColor,
  checkboxUnchecked,
  orange,
  grayBG,
  cyan,
  red,
  textColor,
  textNoWrap,
  green,
  grayPrimary,
} from '../../constants';

export default makeStyles({
  container: {
    padding: '2px 10px',
    display: 'grid',
  },
  listItem: {
    border: `1px solid ${textColor}`,
    padding: '5px 5px 15px 5px',
    margin: '20px 0',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiTypography-root': {
      textAlign: 'center',
    },
  },
  uuid: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiTypography-body1': {
      color: '#888',
    },
  },
  listContent: {
    transition: 'height 0.3s ease',
    overflow: 'hidden',
  },
  copyIcon: {
    margin: '0 10px',
  },
  submitButton: {
    backgroundColor: green,
    color: backgroundColor,
    width: '100%',
  },
  submitButtonWrapper: {
    marginTop: '10px',
  },
  deleteButton: {
    backgroundColor: red,
    color: backgroundColor,
    width: '100%',
  },
  icon: {
    width: 18,
    height: 18,
    backgroundColor: checkboxUnchecked,
  },
  checkedIcon: {
    backgroundColor: orange,
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      content: '""',
    },
  },
  radio: {
    width: '100%',
    margin: '0',
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
  },
  radioButton: {
    paddingRight: 0,
    width: '18px',
  },
  textField: {
    backgroundColor: grayBG,
    padding: '5px',
    width: '100%',
    boxSizing: 'border-box',
  },
  changeEmailForm: {
    position: 'relative',
  },
  changeEmailTextField: {
    boxSizing: 'border-box',
    padding: '0 5px',
    fontSize: '13px',
    backgroundColor: grayBG,
    border: `2px ${grayBG} solid`,
    width: '100%',
    '&.error': {
      borderColor: red,
      '& input': {
        color: 'transparent',
        zIndex: -1,
      },
    },
  },
  updateProfileSubmitButtonWrapper: {
    marginBottom: '7px',
  },
  changeEmailButtonWrapper: {
    marginTop: '10px',
  },
  updateProfileSubmitButton: {
    backgroundColor: orange,
    color: backgroundColor,
    width: '100%',
  },
  updateProfileCancelButtonWrapper: {
    marginBottom: '7px',
  },
  updateProfileCancelButton: {
    backgroundColor: red,
    color: backgroundColor,
    width: '100%',
  },
  changeEmailErrorMessage: {
    position: 'absolute',
    top: '4px',
    fontSize: 12,
    left: 45,
    right: 0,
    pointerEvents: 'none',
    color: red,
    ...textNoWrap,
  },
  updateProfileErrorMessage: {
    color: red,
  },
  updateProfileModal: {
    width: '250px',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: green,
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  iconAdornment: {
    width: '20px',
    height: 'auto',
    margin: '5px',
    color: cyan,
  },
  adornment: {
    margin: '5px',
  },
  appInfoText: {
    fontSize: 14,
    padding: 10,
    backgroundColor: grayPrimary,
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'auto',

    '& a': {
      textDecoration: 'none',

      '& strong': {
        color: cyan,
      },
    },

    '& > p, & > ol, & > ul, & > li': {
      margin: 0,
    },

  },
  btnContainer: {
    display: 'flex',
    gap: 10,
    marginTop: '10px',
    marginBottom: '7px',
  },
}, { index: 1 });
