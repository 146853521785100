import React, { FC } from 'react';
import { IconComponentProps } from 'interfaces';

const Income: FC<IconComponentProps> = ({ color, width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 72 72"
    width={width}
    height={height}
  >
    <path fill={color} d="M25.8 29.6h12.9V70H25.8zM5.5 41.3h12.9V70H5.5zM66.5 24L52.6 4.2 38.7 24h7.4v46h13V24z" />
  </svg>
);

export default Income;
