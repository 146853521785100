import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { orange, cyan } from '@material-ui/core/colors';
import classNames from 'classnames';
import CommentsListItem from 'components/CommentsListItem/CommentsListItem';
import UserInfoContainer from 'components/UserInfoContainer';
import { VendorInfoModal } from 'pages/ClientOrderPage/ClientOrderPageModal';
import icons from 'components/Icons';
import { getTranslateFunction } from 'helpers';
import {
  IComment,
  IOrder,
  RatingType,
  User,
} from 'interfaces';
import { ConfirmCancelOfferModal, OrderInfoModal, RatingModal } from '../OrderModal';
import useStyles from '../OrderPageStyle';
import SingleTypeOrderInfo from './SingleTypeOrderInfo';

interface Props {
  cancelModalOrder: boolean;
  suggestionsCancel: string[];
  handleChange: (a: string, b?: any) => void;
  modalUserInfo: boolean;
  clientComments: IComment[];
  client: User;
  clientRating: RatingType;
  order: IOrder;
  isSingleTypeOrder: boolean;
  modalOrderInfo: boolean;
  ratingModal: boolean;
}

const {
  StarTsx,
  CommentTsx,
} = icons;

const OrderModals: React.FC<Props> = ({
  cancelModalOrder,
  suggestionsCancel,
  handleChange,
  modalUserInfo,
  clientComments,
  client,
  clientRating,
  order,
  isSingleTypeOrder,
  modalOrderInfo,
  ratingModal,
}: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  return (
    <>
      {cancelModalOrder && (
        <ConfirmCancelOfferModal
          array={suggestionsCancel}
          isOpen={cancelModalOrder}
          handleConfirm={(message) => handleChange('cancelOrderByVendor', message)}
          cancelModal={() => handleChange('handleChangeDeclineModal')}
        />
      )}
      {modalOrderInfo && (
        <OrderInfoModal
          modal={modalOrderInfo}
          onClose={() => handleChange('handleOrderInfoModal')}
        >
          {isSingleTypeOrder
            ? (
              <SingleTypeOrderInfo
                user={client}
                userRating={clientRating}
                userComments={clientComments}
                order={order}
              />
            ) : (
              <UserInfoContainer
                userRating={clientRating}
                isSingleTypePreorder={isSingleTypeOrder}
                user={client}
                offer={order}
              />
            )}
        </OrderInfoModal>
      )}
      {ratingModal && (
        <RatingModal
          onChange={handleChange}
          isOpen={ratingModal}
          toggleModal={() => handleChange('setRatingModal')}
          id={order.id}
        >
          {isSingleTypeOrder
            ? (
              <SingleTypeOrderInfo
                user={client}
                userRating={clientRating}
                userComments={clientComments}
                order={order}
              />
            ) : (
              <>
                <UserInfoContainer
                  userRating={clientRating}
                  isSingleTypePreorder={isSingleTypeOrder}
                  user={client}
                  offer={order}
                />
                <Typography
                  variant="h5"
                  onClick={() => handleChange('handleChatToClient')}
                  className={classNames(classes.ratingTitle, 'cyan')}
                >
                  {t('OrderPage.sendMessageToClient')}
                </Typography>
              </>
            )}
        </RatingModal>
      )}
      {modalUserInfo && (
        <VendorInfoModal
          modal={modalUserInfo}
          onClose={() => handleChange('handleUserInfoModal')}
          head={(
            <Box className={classes.titleCommentsBox}>
              <Box className={classes.statsList}>
                <Box className={classes.svg}>
                  <StarTsx color={orange} />
                </Box>
                <Typography>{Number(clientRating?.politenessRatingAvg)}</Typography>
                <Box className={classes.svg}>
                  <CommentTsx color={cyan} />
                </Box>
                <Typography>{clientComments.length}</Typography>
              </Box>
              <Typography className={classes.titleComments}>{t('ClientOrderPage.Comments')}</Typography>
            </Box>
          )}
        >
          {clientComments.map((item: IComment) => (
            <CommentsListItem commentsLength={clientComments.length} page="vendor" item={item} key={item.id} />
          ))}
        </VendorInfoModal>
      )}
    </>
  );
};

export default OrderModals;
