import React from 'react';
import { IconComponentProps } from 'interfaces';

const Share: React.FC<IconComponentProps> = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 72"
  >
    <path
      fill={color}
      d="M42.53 22.92v5.9h11.75v36.57H17.72V28.82h11.76v-5.9H11.81V71.3h48.38V22.92z"
    />
    <path
      fill={color}
      d="M33.05 47.41h5.9V18.76h8.82L36 2.06l-11.76 16.7h8.81z"
    />
  </svg>
);

export default Share;
