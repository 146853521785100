import {
  Box, Button, Typography,
} from '@material-ui/core';
import React, { useState, useMemo } from 'react';
import Modal from 'sharedComponents/Modal';
import {
  BonusResponseItem,
  CommisionsReponse,
  IOnChange,
  IOrder,
  OwnExchanceRates,
  TCurrency,
  Transaction,
} from 'interfaces';
import { getTranslateFunction } from 'helpers';
import useStyles from '../PaymentsPageStyle';
import Commisions from './Commision';
import PaymentsStats from './PaymentStats';
import TopUpWallet from './TopUpWallet';
import SendFunds from './SendFunds';
import WithdrawFunds from './WithdrawFunds';
import SecurityPayment from './SecurityPayment';
import Archive from './Archive';
import Income from './Income';
import { useValues } from '../../../hooks/payment';

interface Props {
  balance: { [key: string]: number };
  transactions: Transaction[];
  commisions: CommisionsReponse[];
  onChange: (a: IOnChange) => void;
  orders: IOrder[];
  uuid: string;
  termsOfUse?: string;
  userCurrency: TCurrency;
  bonuses: BonusResponseItem[];
  currencyData: OwnExchanceRates | null;
}

const PaymentsComponent: React.FC<Props> = ({
  balance,
  transactions,
  commisions,
  onChange,
  uuid,
  termsOfUse,
  userCurrency,
  orders,
  bonuses,
  currencyData,
}: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();
  const [isOpen, setIsOpen] = useState(false);

  const {
    incomeValue,
    incomeSumm,
    archiveTransactions,
    internalTransferTransactions,
    pendingTransactions,
    formattedBonuses,
  } = useValues({
    orders,
    transactions,
    userCurrency,
    bonuses,
  });

  const commisionWithdrawal = useMemo(() => {
    const founded = commisions.find((commision) => commision.projectType === 'withdrawal');

    return founded || null;
  }, [commisions]);

  const commisionSendFunds = useMemo(() => {
    const founded = commisions.find((commision) => commision.projectType === 'sendFunds');

    return founded || null;
  }, [commisions]);

  return (
    <Box className={classes.paymentContainer}>
      <PaymentsStats
        uuid={uuid}
        pendingTransactions={pendingTransactions}
        balance={balance}
        incomeSumm={incomeSumm}
        formattedBonuses={formattedBonuses}
      />

      <Commisions commisions={commisions} />

      <TopUpWallet currency={userCurrency} />

      <SendFunds
        onChange={onChange}
        currencyData={currencyData}
        commision={commisionSendFunds?.commissionValue}
      />

      <WithdrawFunds
        currencyData={currencyData}
        onChange={onChange}
        commision={commisionWithdrawal?.commissionValue}
      />

      <SecurityPayment values={internalTransferTransactions} />

      <Archive values={archiveTransactions} />

      <Income values={incomeValue} />

      <Typography>
        {t('payment.description')}
        <Button className={classes.termsOfUse} disableRipple onClick={() => setIsOpen(true)} variant="text">
          {t('payment.termsOfUse')}
        </Button>
      </Typography>

      <Modal fullWidth isOpen={Boolean(isOpen && termsOfUse)} onClose={() => setIsOpen(false)}>
        <Typography>
          {termsOfUse}
        </Typography>
      </Modal>
    </Box>
  );
};

export default PaymentsComponent;

PaymentsComponent.defaultProps = {
  termsOfUse: '',
};
