import { useEffect, useState } from 'react';
import { RatingType, IComment, User } from 'interfaces';
import { useAuth } from '../services/auth';
import { getClientRating, getUserComments } from '../services/commonService';
import { useUser } from './user';

interface UClientRes {
  clientUser: User;
  clientRating: RatingType;
  clientComments: IComment[];
}

export const useClient = (uuid: string | undefined): UClientRes => {
  const { setIsLoading } = useAuth();
  const clientUser = useUser(uuid);
  const [clientRating, setClientRating] = useState<RatingType | null>(null);
  const [clientComments, setClientComments] = useState([]);

  const resetState = () => {
    setClientRating(null);
    setClientComments([]);
  };

  useEffect(() => {
    const getInfo = async () => {
      if (!uuid) {
        resetState();
        return;
      }

      try {
        setIsLoading(true);

        const [
          clientRatingRes,
          clientCommentsRes,
        ] = await Promise.all([
          getClientRating(uuid),
          getUserComments(uuid),
        ]);

        setClientRating(clientRatingRes);
        setClientComments(clientCommentsRes);
      } finally {
        setIsLoading(false);
      }
    };

    getInfo();
  }, [uuid]);

  return {
    clientUser,
    clientRating,
    clientComments,
  };
};
