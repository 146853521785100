import React from 'react';
import { Button, FormControl, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import PasswordField from 'components/PasswordField/PasswordField';
import classNames from 'classnames';
import { User } from 'interfaces';
import { getTranslateFunction } from 'helpers';
import useStyles from '../ResetPasswordStyles';
import { passwordRegExp, textColor } from '../../../constants';

interface Props {
  sendNewPassword: (password: string) => Promise<User>;
}

const defaultValues = {
  password: '',
  repeatPassword: '',
};

const SecondStep: React.FC<Props> = ({
  sendNewPassword,
}: Props) => {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
  } = useForm({ defaultValues });
  const t = getTranslateFunction();
  const classes = useStyles();

  const onSubmit = async (data: Record<keyof typeof defaultValues, string>) => {
    const { password, repeatPassword } = data;

    const isPasswordCorrect = password.match(passwordRegExp);
    const isRepeatPasswordCorrect = repeatPassword.match(passwordRegExp);
    const isPasswordEquals = password === repeatPassword;

    if (!isPasswordCorrect) {
      setError('password', { message: 'error.validation.invalidValue'});
    }

    if (!isRepeatPasswordCorrect || !isPasswordEquals) {
      setError('repeatPassword', { message: 'error.validation.invalidValue'});
    }

    const isFormValid = isPasswordCorrect && isRepeatPasswordCorrect && isPasswordEquals;
    if (!isFormValid) return;

    try {
      await sendNewPassword(password);
    } catch (error) {
      setError('password', { message: 'error.validation.invalidValue'});
      setError('repeatPassword', { message: 'error.validation.invalidValue'});
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl className={classes.formControl}>
        <PasswordField
          styles={classNames(
            classes.TextField,
            classes.passwordField,
            { isError: !!errors.password },
          )}
          onFocus={() => clearErrors('password')}
          inputRef={register}
          errorMessage={errors.password?.message && t(errors.password?.message)}
          name="password"
          label={t('resetPasswordPage.passwordLabel')}
          iconColor={textColor}
        />
      </FormControl>

      <Typography className={classes.passwordRequirementsLabel}>
        {t('signUpPage.password.minmaxLabel')}
      </Typography>

      <Typography className={classes.passwordRequirementsLabel}>
        {t('signUpPage.password.latinAndNumberLabel')}
      </Typography>

      <FormControl className={classes.formControl}>
        <PasswordField
          styles={classNames(
            classes.TextField,
            classes.passwordField,
            { isError: !!errors.repeatPassword },
          )}
          onFocus={() => clearErrors('repeatPassword')}
          inputRef={register}
          errorMessage={errors.repeatPassword?.message && t(errors.repeatPassword?.message)}
          name="repeatPassword"
          label={t('resetPasswordPage.confirmPasswordLabel')}
          iconColor={textColor}
        />
      </FormControl>

      <Button
        type="submit"
        variant="contained"
        className={classes.button}
      >
        {t('resetPasswordPage.button.reset')}
      </Button>
    </form>
  );
};

export default SecondStep;
