import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { getUsers } from 'services/commonService';
import { useAuth } from 'services/auth';
import { User } from 'interfaces';

export const useUser = (uuid: string | undefined): User | null => {
  const [user, setUser] = useState<User | null>(null);
  const { setIsLoading } = useAuth();

  useEffect(() => {
    const getData = async () => {
      if (!uuid) {
        setUser(null);
        return;
      }

      try {
        setIsLoading(true);
        const response = await getUsers(uuid);
        const [currUser] = response;

        setUser(currUser);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [uuid]);

  return user;
};

export const useUsers = (): {
  users: User[];
  setUsersLoading: Dispatch<SetStateAction<string[]>>;
  getUser: (id?: number) => User | undefined;
} => {
  const [users, setUsers] = useState<User[]>([]);
  const [usersLoading, setUsersLoading] = useState<string[]>([]);

  useEffect(() => {
    const requestUsers = async () => {
      if (!usersLoading.length) return;
      const filteredIds = usersLoading.filter((uuid) => (
        !users.some((user) => user?.uuid === uuid)
      ));

      const promises = filteredIds.map((id: string) => getUsers(id));
      const currentUsers = (await Promise.all(promises)).flat();

      setUsers((prev) => [...prev, ...currentUsers]);
      setUsersLoading([]);
    };

    requestUsers();
  }, [usersLoading, users]);

  const getUser = (userId?: number) => {
    if (!userId) return undefined;

    let orderUser: User | undefined;
    setUsers((prev) => {
      orderUser = prev.find((user: User) => userId === user?.id);

      return prev;
    });
    return orderUser;
  };

  return {
    users,
    getUser,
    setUsersLoading,
  };
};
