import React from 'react';
import { IconComponentProps } from 'interfaces';

const Support: React.FC<IconComponentProps> = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 72"
  >
    <path
      fill={color}
      d="M69.11 33.69h2.3v12.53h-2.3zM.6 33.73h2.3v12.53H.6zM64.42 24.58C62.03 18.8 58.5 13.9 53.67 10.04c-4.28-3.42-9.08-5.58-14.49-6.17-8.62-.94-16.08 1.85-22.51 7.72-3.95 3.61-6.9 7.99-8.95 13-.13.33-.3.75-.32.7H3.88v27.02h11.86v-3.69-.04c.05-5.61.03-11.22 0-16.83v-6.46h-3.75c1.4-3.14 3.19-5.93 5.42-8.43 3.4-3.82 7.46-6.61 12.34-7.97 6.78-1.9 13.19-.84 19.15 2.95 5.11 3.26 8.73 7.89 11.29 13.45h-3.8v27.09h3.63L46.4 63.91h-3v-1.75H31.91v6.16H43.4v-1.78h4v-.04l16.66-14.12h4.04v-27.1h-3.37c.01 0-.17-.36-.31-.7z"
    />
  </svg>
);
export default Support;
