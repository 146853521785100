import citySvg from 'images/icons/inCity.svg';
import betweenCitySvg from 'images/icons/betweenCity.svg';
import internationalSvg from 'images/icons/international.svg';

export type HomepageButton = {
  type: string;
  service: string;
  contentKey: string;
  icon: string;
};

export interface Services {
  type: string;
  icon: string;
}

export type Option = {
  service: string;
  action: string;
  contentKey: string;
  icon: string | undefined;
};

const IN_CITY = 'inCity';
const BETWEEN_CITY = 'betweenCity';
const INTERNATIONAL = 'international';
const HELP_ON_ROAD = 'helpOnRoad';
const SERVICES = 'services';
const RENT = 'rent';
const BUY = 'buyThings';

type HomepageButtonsGroup = { [prop: string]: HomepageButton[] };

type ServicesButtonsGroup = { [prop: string]: Services[] };

export const services: Services[] = [
  {
    type: 'alongTheWay',
    icon: 'alongTheWayIcon',
  },
  {
    type: 'courier',
    icon: 'courierIcon',
  },
  {
    type: 'helpOnRoad',
    icon: 'helpOnRoadIcon',
  },
  {
    type: 'buyThings',
    icon: 'buyIcon',
  },
  {
    type: 'rent',
    icon: 'rentalIcon',
  },
  {
    type: 'services',
    icon: 'servicesIcon',
  },
];

export const options: Option[] = [
  {
    service: IN_CITY,
    action: '',
    contentKey: IN_CITY,
    icon: citySvg,
  },
  {
    service: BETWEEN_CITY,
    action: '',
    contentKey: BETWEEN_CITY,
    icon: betweenCitySvg,
  },
  {
    service: INTERNATIONAL,
    action: '',
    contentKey: INTERNATIONAL,
    icon: internationalSvg,
  },
];

export const buttons: HomepageButton[] = [
  {
    type: 'alongTheWay',
    service: IN_CITY,
    contentKey: 'in-city-along-the-way',
    icon: 'alongTheWay',
  },
  {
    type: 'alongTheWay',
    service: BETWEEN_CITY,
    contentKey: 'between-city-along-the-way',
    icon: 'alongTheWay',
  },
  {
    type: 'alongTheWay',
    service: INTERNATIONAL,
    contentKey: 'international-along-the-way',
    icon: 'alongTheWay',
  },
  {
    type: 'alongTheWay',
    service: IN_CITY,
    contentKey: 'in-city-women-driver',
    icon: 'womenDriver',
  },
  {
    type: 'alongTheWay',
    service: BETWEEN_CITY,
    contentKey: 'between-city-women-driver',
    icon: 'womenDriver',
  },
  {
    type: 'alongTheWay',
    service: INTERNATIONAL,
    contentKey: 'international-women-driver',
    icon: 'womenDriver',
  },
  {
    type: 'alongTheWay',
    service: IN_CITY,
    contentKey: 'in-city-temporary-driver',
    icon: 'temporaryDriver',
  },
  {
    type: 'alongTheWay',
    service: BETWEEN_CITY,
    contentKey: 'between-city-temporary-driver',
    icon: 'temporaryDriver',
  },
  {
    type: 'alongTheWay',
    service: INTERNATIONAL,
    contentKey: 'international-temporary-driver',
    icon: 'temporaryDriver',
  },
  {
    type: 'alongTheWay',
    service: IN_CITY,
    contentKey: 'in-city-freight-transport',
    icon: 'freightTransport',
  },
  {
    type: 'alongTheWay',
    service: BETWEEN_CITY,
    contentKey: 'between-city-freight-transport',
    icon: 'freightTransport',
  },
  {
    type: 'alongTheWay',
    service: INTERNATIONAL,
    contentKey: 'international-freight-transport',
    icon: 'freightTransport',
  },
  {
    type: 'alongTheWay',
    service: IN_CITY,
    contentKey: 'in-city-animal-transport',
    icon: 'animalTransport',
  },
  {
    type: 'alongTheWay',
    service: BETWEEN_CITY,
    contentKey: 'between-city-animal-transport',
    icon: 'animalTransport',
  },
  {
    type: 'alongTheWay',
    service: INTERNATIONAL,
    contentKey: 'international-animal-transport',
    icon: 'animalTransport',
  },
  {
    type: 'alongTheWay',
    service: IN_CITY,
    contentKey: 'in-city-another-transport',
    icon: 'anotherTransport',
  },
  {
    type: 'alongTheWay',
    service: BETWEEN_CITY,
    contentKey: 'between-city-another-transport',
    icon: 'anotherTransport',
  },
  {
    type: 'alongTheWay',
    service: INTERNATIONAL,
    contentKey: 'international-another-transport',
    icon: 'anotherTransport',
  },
  {
    type: 'courier',
    service: IN_CITY,
    contentKey: 'in-city-food-delivery',
    icon: 'foodDelivery',
  },
  {
    type: 'courier',
    service: BETWEEN_CITY,
    contentKey: 'between-city-food-delivery',
    icon: 'foodDelivery',
  },
  {
    type: 'courier',
    service: INTERNATIONAL,
    contentKey: 'international-food-delivery',
    icon: 'foodDelivery',
  },
  {
    type: 'courier',
    service: IN_CITY,
    contentKey: 'products-delivery',
    icon: 'productsDelivery',
  },
  {
    type: 'courier',
    service: BETWEEN_CITY,
    contentKey: 'between-city-products-delivery',
    icon: 'productsDelivery',
  },
  {
    type: 'courier',
    service: INTERNATIONAL,
    contentKey: 'international-products-delivery',
    icon: 'productsDelivery',
  },
  {
    type: 'courier',
    service: IN_CITY,
    contentKey: 'in-city-flowers-delivery',
    icon: 'flowersDelivery',
  },
  {
    type: 'courier',
    service: BETWEEN_CITY,
    contentKey: 'between-city-flowers-delivery',
    icon: 'flowersDelivery',
  },
  {
    type: 'courier',
    service: INTERNATIONAL,
    contentKey: 'international-flowers-delivery',
    icon: 'flowersDelivery',
  },
  {
    type: 'courier',
    service: IN_CITY,
    contentKey: 'in-city-medicines-delivery',
    icon: 'medicinesDelivery',
  },
  {
    type: 'courier',
    service: BETWEEN_CITY,
    contentKey: 'between-city-medicines-delivery',
    icon: 'medicinesDelivery',
  },
  {
    type: 'courier',
    service: INTERNATIONAL,
    contentKey: 'international-medicines-delivery',
    icon: 'medicinesDelivery',
  },
  {
    type: 'courier',
    service: IN_CITY,
    contentKey: 'in-city-documents-delivery',
    icon: 'documentsDelivery',
  },
  {
    type: 'courier',
    service: BETWEEN_CITY,
    contentKey: 'between-city-documents-delivery',
    icon: 'documentsDelivery',
  },
  {
    type: 'courier',
    service: INTERNATIONAL,
    contentKey: 'international-documents-delivery',
    icon: 'documentsDelivery',
  },
  {
    type: 'courier',
    service: IN_CITY,
    contentKey: 'in-city-another',
    icon: 'anotherDelivery',
  },
  {
    type: 'courier',
    service: BETWEEN_CITY,
    contentKey: 'between-city-another',
    icon: 'anotherDelivery',
  },
  {
    type: 'courier',
    service: INTERNATIONAL,
    contentKey: 'international-another',
    icon: 'anotherDelivery',
  },
  {
    type: 'helpOnRoad',
    service: HELP_ON_ROAD,
    contentKey: 'help-on-road-fuel-delivery',
    icon: 'fuelDeliveryHelp',
  },
  {
    type: 'helpOnRoad',
    service: HELP_ON_ROAD,
    contentKey: 'help-on-road-towing',
    icon: 'towingHelp',
  },
  {
    type: 'helpOnRoad',
    service: HELP_ON_ROAD,
    contentKey: 'help-on-road-engine-start',
    icon: 'engineStartHelp',
  },
  {
    type: 'helpOnRoad',
    service: HELP_ON_ROAD,
    contentKey: 'help-on-road-tow-track',
    icon: 'towTruckHelp',
  },
  {
    type: 'helpOnRoad',
    service: HELP_ON_ROAD,
    contentKey: 'help-on-road-on-site-repair',
    icon: 'onSiteRepair',
  },
  {
    type: 'helpOnRoad',
    service: HELP_ON_ROAD,
    contentKey: 'help-on-road-another',
    icon: 'anotherHelp',
  },
  {
    type: 'buyThings',
    service: BUY,
    contentKey: 'buy-transport',
    icon: 'transportBuy',
  },
  {
    type: 'buyThings',
    service: BUY,
    contentKey: 'buy-property',
    icon: 'propertyBuy',
  },
  {
    type: 'buyThings',
    service: BUY,
    contentKey: 'buy-appliances',
    icon: 'appliancesBuy',
  },
  {
    type: 'buyThings',
    service: BUY,
    contentKey: 'buy-electrical-engineering',
    icon: 'electricalEngineering',
  },
  {
    type: 'buyThings',
    service: BUY,
    contentKey: 'buy-sport-goods',
    icon: 'sportGoodsBuy',
  },
  {
    type: 'buyThings',
    service: BUY,
    contentKey: 'buy-furniture',
    icon: 'furnitureBuy',
  },
  {
    type: 'buyThings',
    service: BUY,
    contentKey: 'buy-clothing',
    icon: 'clothingBuy',
  },
  {
    type: 'buyThings',
    service: BUY,
    contentKey: 'buy-medicines',
    icon: 'medicinesBuy',
  },
  {
    type: 'buyThings',
    service: BUY,
    contentKey: 'buy-spare-parts',
    icon: 'sparePartsBuy',
  },
  {
    type: 'buyThings',
    service: BUY,
    contentKey: 'buy-another',
    icon: 'anotherBuy',
  },
  {
    type: 'rent',
    service: RENT,
    contentKey: 'rent-car',
    icon: 'carRental',
  },
  {
    type: 'rent',
    service: RENT,
    contentKey: 'rent-clothing',
    icon: 'clothingRental',
  },
  {
    type: 'rent',
    service: RENT,
    contentKey: 'rent-property',
    icon: 'propertyRental',
  },
  {
    type: 'rent',
    service: RENT,
    contentKey: 'rent-sportGoods',
    icon: 'sportGoodsRental',
  },
  {
    type: 'rent',
    service: RENT,
    contentKey: 'rent-electricalEngineering',
    icon: 'electricalEngineeringRent',
  },
  {
    type: 'rent',
    service: RENT,
    contentKey: 'rent-another',
    icon: 'otherRental',
  },
  {
    type: 'services',
    service: SERVICES,
    contentKey: 'services-car-repair',
    icon: 'carRepairService',
  },
  {
    type: 'services',
    service: SERVICES,
    contentKey: 'services-web-services',
    icon: 'webServicesService',
  },
  {
    type: 'services',
    service: SERVICES,
    contentKey: 'services-laptop-and-computers',
    icon: 'laptopAndComputersService',
  },
  {
    type: 'services',
    service: SERVICES,
    contentKey: 'services-phones-and-tablets',
    icon: 'phonesAndTablesService',
  },
  {
    type: 'services',
    service: SERVICES,
    contentKey: 'services-photo-video-technics',
    icon: 'photoVideoTechnicsService',
  },
  {
    type: 'services',
    service: SERVICES,
    contentKey: 'services-appliances',
    icon: 'appliancesService',
  },
  {
    type: 'services',
    service: SERVICES,
    contentKey: 'services-electrician',
    icon: 'electricianService',
  },
  {
    type: 'services',
    service: SERVICES,
    contentKey: 'services-carpenter',
    icon: 'woodWorkerService',
  },
  {
    type: 'services',
    service: SERVICES,
    contentKey: 'services-plumber',
    icon: 'plumberService',
  },
  {
    type: 'services',
    service: SERVICES,
    contentKey: 'services-construction-and-repair',
    icon: 'constructionAndRepairService',
  },
  {
    type: 'services',
    service: SERVICES,
    contentKey: 'services-cleaningService',
    icon: 'cleaningService',
  },
  {
    type: 'services',
    service: SERVICES,
    contentKey: 'services-home-master',
    icon: 'handymenService',
  },
  {
    type: 'services',
    service: SERVICES,
    contentKey: 'services-seamstress',
    icon: 'seamstressService',
  },
  {
    type: 'services',
    service: SERVICES,
    contentKey: 'services-handymen',
    icon: 'handymenService',
  },
  {
    type: 'services',
    service: SERVICES,
    contentKey: 'services-medicines',
    icon: 'medicinesService',
  },
  {
    type: 'services',
    service: SERVICES,
    contentKey: 'services-another',
    icon: 'otherService',
  },
];

export const filteredButtons = [
  'alongTheWay',
  'courier',
];
export const filteredOptions = [
  'helpOnRoad',
  'services',
  'rent',
  'buyThings',
];

export const groupByService = (
  array: Services[],
  prop: keyof Services,
): ServicesButtonsGroup => {
  const result: ServicesButtonsGroup = {};
  array.forEach((model) => {
    if (!result[model[prop]]) {
      result[model[prop]] = [];
    }
    result[model[prop]].push(model);
  });
  return result;
};

export const groupByType = ( // TODO change types to generic
  array: HomepageButton[],
  prop: keyof HomepageButton,
): HomepageButtonsGroup => {
  const result: HomepageButtonsGroup = {};
  array.forEach((model) => {
    if (!result[model[prop]]) {
      result[model[prop]] = [];
    }
    result[model[prop]].push(model);
  });
  return result;
};

export const configVendorQueryParams = (data: {
  [key: string]: {
    contentKey: string
  }[];
}): string => Object.entries(data)
  .map(([key, values]) => `${key}=[${values.map(({ contentKey }) => contentKey).join(',')}]`)
  .join('&');
