import { LatLngTuple } from 'leaflet';
import { useEffect, useState } from 'react';
import { ILocation, TLocation } from 'interfaces';
import { useAuth } from 'services/auth';
import { getDistance, getVendorPosition } from 'services/commonService';
import RequestService from 'services/requestApi';
import { VENDOR_POSITIONS_INTERVAL } from '../constants';

export const useMyPosition = (): [TLocation, () => void] => {
  const { currentIp } = useAuth();

  const [location, setLocation] = useState<LatLngTuple | null>(null);

  const getLocation = () => {
    navigator.geolocation
      .getCurrentPosition(
        (res) => setLocation([res.coords.latitude, res.coords.longitude]),
      );
  };

  useEffect(() => {
    if (location) return;

    getLocation();
  }, [location, currentIp]);

  return [location || currentIp?.ll || null, getLocation];
};

export const useLocation = (): ILocation => {
  const { vendorId } = useAuth();
  const [location, getLocation] = useMyPosition();

  useEffect(() => {
    if (!vendorId) {
      return undefined;
    }
    const intervalId = setInterval(getLocation, VENDOR_POSITIONS_INTERVAL);

    return () => clearInterval(intervalId);
  }, [vendorId]);

  useEffect(() => {
    if (!vendorId) {
      return;
    }
    new RequestService('/users-positions').post({
      location,
    });
  }, [location, vendorId]);

  return {
    location,
  };
};

export const useVendorLocation = (vendorId: number | undefined): {
  vendorLocation: LatLngTuple | null;
  distance: number | null;
} => {
  const [vendorLocation, setVendorLocation] = useState<[number, number] | null>(null);
  const [distance, setDistance] = useState<number | null>(null);

  useEffect(() => {
    if (!vendorLocation) return;

    navigator.geolocation
      .getCurrentPosition((pos) => (
        getDistance([pos.coords.latitude, pos.coords.longitude], vendorLocation)
          .then((res) => setDistance(res[0].distance))
      ));
  }, [vendorLocation]);

  useEffect(() => {
    const getGeolocation = async () => {
      if (!vendorId) return;

      const [response] = await getVendorPosition(vendorId);
      setVendorLocation(response.location);
    };

    getGeolocation();
    const intervalId = setInterval(getGeolocation, VENDOR_POSITIONS_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [vendorId]);

  return {
    distance,
    vendorLocation,
  };
};
