import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { User, IRating } from 'interfaces';
import { getClientRating } from 'services/commonService';

export const useUserRating = (uuid: string | undefined): {
  rating: IRating | null;
  loading: boolean;
} => {
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState<IRating | null>(null);

  useEffect(() => {
    if (!uuid) return;

    const handleGetRating = async () => {
      setLoading(true);
      const ratingResponse = await getClientRating(uuid);
      setRating(ratingResponse);
      setLoading(false);
    };

    handleGetRating();
  }, [uuid]);

  return { rating, loading };
};

export const useUsersRating = (): {
  setUsers: Dispatch<SetStateAction<User[]>>;
  usersRating: IRating[];
} => {
  const [users, setUsers] = useState<User[]>([]);
  const [usersRating, setUsersRating] = useState<IRating[]>([]);

  useEffect(() => {
    const getUserRating = async (user: User) => {
      const rating = await getClientRating(user?.uuid);

      return {
        id: user?.id,
        ...rating,
      };
    };

    const getUsersRating = async () => {
      const promises = users.map(getUserRating);

      const responses = await Promise.all(promises);
      setUsersRating(responses);
    };

    getUsersRating();
  }, [users]);

  return {
    usersRating,
    setUsers,
  };
};
