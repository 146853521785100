import {
  Box, Button, TextField, Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import React, { ChangeEvent, useMemo, useState } from 'react';
import Modal from 'sharedComponents/Modal';
import icons from 'components/Icons';
import { getTranslateFunction } from 'helpers';
import { IOnChange } from 'interfaces';
import { red } from '../../../constants';
import {
  affrontAction,
  affrontLabel,
  anotherAction,
  anotherLabel,
  blockAndDeleteChatAction,
  blockAndDeleteChatIcon,
  blockAndDeleteChatLabel,
  complaintAndDeleteChatAction,
  complaintAndDeleteChatIcon,
  complaintAndDeleteChatLabel,
  deleteChatAction,
  deleteChatIcon,
  deleteChatLabel,
  spamAction,
  spamLabel,
} from '../config';
import useStyles from '../styles';

const collapseButtons = [
  {
    label: deleteChatLabel,
    action: deleteChatAction,
    icon: deleteChatIcon,
    supportChatVisible: true,
  },
  {
    label: blockAndDeleteChatLabel,
    action: blockAndDeleteChatAction,
    icon: blockAndDeleteChatIcon,
  },
  {
    label: complaintAndDeleteChatLabel,
    action: complaintAndDeleteChatAction,
    icon: complaintAndDeleteChatIcon,
  },
];

const complaintOptions = [
  {
    label: affrontLabel,
    action: affrontAction,
  },
  {
    label: spamLabel,
    action: spamAction,
  },
  {
    label: anotherLabel,
    action: anotherAction,
  },
];

interface Props {
  onChange: (data: IOnChange) => void;
  isSupportChat?: boolean;
}

const MenuModal: React.FC<Props> = ({ onChange, isSupportChat }) => {
  const t = getTranslateFunction();
  const classes = useStyles();
  const [option, setOption] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [confirmBtn, setConfirmBtn] = useState<boolean>(false);
  const [complaintOption, setComplaintOption] = useState<string>('');
  const [inputValue, setInputValue] = useState('');

  const handleSubmit = () => {
    setIsOpen(false);
    setConfirmBtn(false);
    const data = complaintOption === anotherAction ? inputValue : complaintOption;

    onChange({
      action: option,
      data,
    });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    if (value.length > 500) return;

    setInputValue(value);
  };

  const submitDisabled = useMemo(() => {
    if (complaintOption === anotherAction && inputValue.length < 5) return true;
    if (option === complaintAndDeleteChatAction && !complaintOption) return true;
    if (!option) return true;

    return false;
  }, [option, complaintOption, inputValue]);

  return (
    <Box>
      <Box className={classes.menuOpenBtn} onClick={() => setIsOpen(true)}>
        <span />
        <span />
        <span />
      </Box>
      <Modal
        modalClassName={classes.menuModal}
        withoutBorder
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
      >
        {collapseButtons.map(({
          label, action, icon, supportChatVisible,
        }) => {
          const Icon = icons[icon];
          const isVibible = isSupportChat ? supportChatVisible && isSupportChat : true;
          if (!isVibible) return null;

          return (
            <Box
              className={classes.listItem}
              key={label}
              onClick={() => setOption(action)}
            >
              <Box className={classes.itemWrapper}>
                <Icon width={40} height={40} color={red} />
                <Typography className={classes.label}>{t(label)}</Typography>
              </Box>
              <Box
                className={
                  classNames(classes.indicator, { [classes.indicatorActive]: action === option })
                }
              />
            </Box>
          );
        })}

        {option === complaintAndDeleteChatAction && complaintOptions.map(({ action, label }) => (
          <Box onClick={() => setComplaintOption(action)} key={action} className={classes.listItem}>
            <Typography className={classes.label}>{t(label)}</Typography>

            <Box
              className={classNames(
                classes.indicator,
                { [classes.indicatorActive]: action === complaintOption },
              )}
            />
          </Box>
        ))}

        {complaintOption === anotherAction && (
          <TextField
            InputProps={{
              disableUnderline: true,
              inputProps: { min: 5 },
            }}
            className={classes.input}
            value={inputValue}
            onChange={handleInputChange}
          />
        )}

        <Button
          type="submit"
          disabled={submitDisabled}
          onClick={() => setConfirmBtn(true)}
          color="primary"
          variant="contained"
        >
          {t('menuModal.send')}
        </Button>
      </Modal>

      <Modal
        fullWidth
        withoutBorder
        isOpen={confirmBtn}
        onClose={() => setConfirmBtn(false)}
        modalClassName={classes.confirmModal}
      >
        <Button
          onClick={handleSubmit}
          className={classes.submitBtn}
          variant="contained"
        >
          {t('menuModal.Oki')}
        </Button>
      </Modal>
    </Box>
  );
};

export default MenuModal;

MenuModal.defaultProps = {
  isSupportChat: false,
};
