import OrderListItem from 'pages/ActiveOrdersPage/components/OrderListItem';
import React from 'react';
import {
  IOnChange,
  IOrder,
  Preorder,
} from 'interfaces';
import useStyles from '../styles';

interface Props {
  orders: IOrder[];
  preorders: Preorder[];
  onChange: (a: IOnChange) => void;
}

const ActiveOrdersComponent: React.FC<Props> = ({
  orders,
  preorders,
  onChange,
}: Props) => {
  const classes = useStyles();

  const handleChange = (action: string, data?: IOrder | Preorder) => {
    onChange({ action, data });
  };

  return (
    <div className={classes.container}>
      {preorders.map((item) => (
        <OrderListItem
          order={item}
          key={item.preorderId}
          goToOrder={() => handleChange('goToPreorder', item)}
        />
      ))}
      {orders.map((item) => (
        <OrderListItem
          order={item}
          key={item.preorderId}
          goToOrder={() => handleChange('goToOrder', item)}
        />
      ))}
    </div>
  );
};

export default ActiveOrdersComponent;
