import { Button, FormControl, Box } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import MessageTsx from 'images/message';

import classNames from 'classnames';
import TextField from 'sharedComponents/TextField';
import { getTranslateFunction } from 'helpers';
import useStyles from '../ResetPasswordStyles';
import { cyan, emailRegExp } from '../../../constants';

interface Props {
  sendRequest: (data: { login: string }) => Promise<void | null>;
}

const FirstStep: React.FC<Props> = ({
  sendRequest,
}: Props) => {
  const [login, setLogin] = useState('');
  const [isError, setIsError] = useState(false);

  const classes = useStyles();
  const t = getTranslateFunction();

  const onSubmit = useCallback(async () => {
    const isValidLogin = login.match(emailRegExp);

    if (!isValidLogin) {
      setIsError(true);
      return;
    }

    try {
      await sendRequest({ login });
    } catch (error) {
      setIsError(true);
    }
  }, [login]);

  return (
    <Box>
      <FormControl className={classes.formControl} error={isError}>
        <TextField
          name="login"
          className={classNames(classes.TextField, { isError })}
          placeholder={t('signInPage.loginLabel')}
          value={login}
          errorMessage={isError ? t('resetPasswordPage.loginError') : undefined}
          onChange={({target: {value}}) => setLogin(value)}
          onFocus={() => setIsError(false)}
          InputProps={{
            autoComplete: 'new-password',
            disableUnderline: true,
            startAdornment: (
              <Box className={classes.adornment}>
                <MessageTsx color={cyan} />
              </Box>
            ),
          }}
        />
      </FormControl>

      <Button onClick={onSubmit} className={classes.button} color="primary" variant="contained">
        {t('resetPasswordPage.requestCode')}
      </Button>
    </Box>
  );
};

export default FirstStep;
