import { Box, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { getTranslateFunction, hideValue } from 'helpers';
import { IOnChange, SosRoute, User } from 'interfaces';

import SharedModal from 'sharedComponents/GlobalModal/GlobalModal';
import sosIcon from 'images/icons/SOS.svg';
import useStyles from '../styles';
import { useLocalStorage } from 'services/customHooks';
import SosFields from './SosFields';
import InfoToggle from 'components/InfoToggle/InfoToggle';

type Props = {
  onChange: (a: IOnChange) => void;
  sosRoute: SosRoute;
  user: User;
  menuSecurityTop: string;
  menuSecurityBottom: string;
};

const SecurityComponent: React.FC<Props> = ({
  sosRoute,
  user,
  onChange,
  menuSecurityTop,
  menuSecurityBottom,
}) => {
  const classes = useStyles();
  // TODO create local storage keys file
  // TODO use database instead of local storage (user-settings)
  const [isSecurityPageDialogClosed, setIsSecurityPageDialogClosed] = useLocalStorage('isSecurityPageDialogClosed', false);
  const t = getTranslateFunction();

  const handleSave = (data: { sosRoute: SosRoute }) => {
    onChange({
      action: 'setSosRoute',
      data,
    });
  };

  const messageExample = useMemo(() => {
    if (!user) return null;

    const { firstName, lastName, phone } = user;
    const hiddenPhone = hideValue(phone, 4);

    return `SOS ${firstName} ${lastName} ${hiddenPhone} `;
  }, [user]);

  return (
    <Box className={classes.page}>
        {/**
      <Typography className={classes.title}>
        {menuSecurityTop}
      </Typography>
          */}

      <SharedModal
        isOpen={!isSecurityPageDialogClosed}
        confirm={() => setIsSecurityPageDialogClosed(true)}
        title={t('securityPage.safetyTitle')}
        content={t('securityPage.safetyText')}
        buttonText={t('ClientOrderPage.Ok')}
      />

      <Box className={classes.contentWrapper}>
        <Typography className={classes.warningTitle}>
          {menuSecurityBottom}
        </Typography>

        {/**
        <InfoToggle description={t('securityPage.safetyText')} btnLabel='profilePage.infoToggle.btn' isCollapsedTextColorBase />
        <br />
        <br />
        <InfoToggle description={t('securityPage.safetyWarningText')} btnLabel='profilePage.infoToggle.btn' isButtonColorError />

        <SosFields onSave={handleSave} sosRoute={sosRoute} />
        */}

        <Typography className={classes.sosExample}>
          {messageExample}
        </Typography>

          {/*
        <img src={sosIcon} alt="sosIcon" className={classes.sosIcon} />
          */}
      </Box>
    </Box>
  );
};

export default SecurityComponent;
