import { ICountry } from 'interfaces';

const list: ICountry[] = [
  {
    id: 8,
    lang: 'ita',
    code: 'IT',
    label: 'Italia',
    currency: 'EUR',
    showLang: true,
    cities: [
      { id: 1, label: 'Roma' },
      { id: 2, label: 'Milano' },
      { id: 3, label: 'Napoli' },
      { id: 4, label: 'Torino' },
      { id: 5, label: 'Palermo' },
      { id: 6, label: 'Genova' },
      { id: 7, label: 'Bologna' },
      { id: 8, label: 'Firenze' },
      { id: 9, label: 'Bari' },
      { id: 10, label: 'Catania' },
      { id: 11, label: 'Venezia' },
      { id: 12, label: 'Verona' },
      { id: 13, label: 'Messina' },
      { id: 14, label: 'Padova' },
      { id: 15, label: 'Trieste' },
      { id: 16, label: 'Taranto' },
      { id: 17, label: 'Brescia' },
      { id: 18, label: 'Prato' },
      { id: 19, label: 'Parma' },
      { id: 20, label: 'Modena' },
      { id: 21, label: 'Reggio Calabria' },
      { id: 22, label: 'Reggio nell\'Emilia' },
      { id: 23, label: 'Perugia' },
      { id: 24, label: 'Livorno' },
      { id: 25, label: 'Ravenna' },
      { id: 26, label: 'Cagliari' },
      { id: 27, label: 'Foggia' },
      { id: 28, label: 'Rimini' },
      { id: 29, label: 'Ferrara' },
      { id: 30, label: 'Salerno' },
      { id: 31, label: 'Sassari' },
      { id: 32, label: 'latina' },
      { id: 33, label: 'Monza' },
      { id: 34, label: 'Siracusa' },
      { id: 35, label: 'Pescara' },
      { id: 36, label: 'Giugliano in Campagna' },
      { id: 37, label: 'Bergamo' },
      { id: 38, label: 'Forlì' },
      { id: 39, label: 'Trento' },
      { id: 40, label: 'Vicenza' },
    ],
  },
  {
    id: 3,
    lang: 'aze',
    label: 'Azərbaycan',
    code: 'AZ',
    currency: 'AZN',
    showLang: true,
    cities: [
      { id: 1, label: 'Ağdam' },
      { id: 2, label: 'Ağdərə' },
      { id: 3, label: 'Ağdaş' },
      { id: 4, label: 'Ağcabədi' },
      { id: 5, label: 'Hacıqabul' },
      { id: 6, label: 'Ağstafa' },
      { id: 7, label: 'Astara' },
      { id: 8, label: 'Ağsu' },
      { id: 9, label: 'Babək' },
      { id: 10, label: 'Bakı' },
      { id: 11, label: 'Bərdə' },
      { id: 12, label: 'Beyləqan' },
      { id: 13, label: 'Balakən' },
      { id: 14, label: 'Biləsuvar' },
      { id: 15, label: 'Qəbələ' },
      { id: 16, label: 'Qazax' },
      { id: 17, label: 'Qax' },
      { id: 18, label: 'Göyçay' },
      { id: 19, label: 'Goranboy' },
      { id: 20, label: 'Göygöl' },
      { id: 21, label: 'Göytəpə' },
      { id: 22, label: 'Qobustan' },
      { id: 23, label: 'Qovlar' },
      { id: 24, label: 'Horadiz' },
      { id: 25, label: 'Quba' },
      { id: 26, label: 'Qubadlı' },
      { id: 27, label: 'Qusar' },
      { id: 28, label: 'Gəncə' },
      { id: 29, label: 'Dəliməmmədli' },
      { id: 30, label: 'Daşkəsən' },
      { id: 31, label: 'Cəlilabad' },
      { id: 32, label: 'Cəbrayıl' },
      { id: 33, label: 'Culfa' },
      { id: 34, label: 'Yevlax' },
      { id: 35, label: 'Zaqatala' },
      { id: 36, label: 'Zəngilan' },
      { id: 37, label: 'Zərdab' },
      { id: 38, label: 'İmişli' },
      { id: 39, label: 'İsmayıllı' },
      { id: 40, label: 'Gədəbəy' },
      { id: 41, label: 'Kəlbəcər' },
      { id: 42, label: 'Kürdəmir' },
      { id: 43, label: 'Laçın' },
      { id: 44, label: 'Lənkəran' },
      { id: 45, label: 'Lerik' },
      { id: 46, label: 'Liman' },
      { id: 47, label: 'Masallı' },
      { id: 48, label: 'Mingəçevir' },
      { id: 49, label: 'Naftalan' },
      { id: 50, label: 'Naxçıvan' },
      { id: 51, label: 'Neftçala' },
      { id: 52, label: 'Oğuz' },
      { id: 53, label: 'Ordubad' },
      { id: 54, label: 'Saatlı' },
      { id: 55, label: 'Sabirabad' },
      { id: 56, label: 'Salyan' },
      { id: 57, label: 'Samux' },
      { id: 58, label: 'Siyəzən' },
      { id: 59, label: 'Sumqayıt' },
      { id: 60, label: 'Tovuz' },
      { id: 61, label: 'Tərtər' },
      { id: 62, label: 'Ucar' },
      { id: 63, label: 'Füzuli' },
      { id: 64, label: 'Xankəndi' },
      { id: 65, label: 'Xaçmaz' },
      { id: 66, label: 'Xocavənd' },
      { id: 67, label: 'Xocalı' },
      { id: 68, label: 'Xudat' },
      { id: 69, label: 'Xızı' },
      { id: 70, label: 'Xırdalan' },
      { id: 71, label: 'Şabran' },
      { id: 72, label: 'Şəmkir' },
      { id: 73, label: 'Şərur' },
      { id: 74, label: 'Şahbuz' },
      { id: 75, label: 'Şəki' },
      { id: 76, label: 'Şamaxı' },
      { id: 77, label: 'Şirvan' },
      { id: 78, label: 'Şuşa' },
      { id: 79, label: 'Yardımlı' },
    ],
  },
  {
    id: 9,
    lang: 'fre',
    label: 'France',
    code: 'FR',
    currency: 'EUR',
    showLang: true,
    cities: [
      { id: 1, label: 'Paris' },
      { id: 2, label: 'Marseille' },
      { id: 3, label: 'Lyonnais' },
      { id: 4, label: 'Toulouse' },
      { id: 5, label: 'Agréable' },
      { id: 6, label: 'Nantes' },
      { id: 7, label: 'Strasbourg' },
      { id: 8, label: 'Montpellier' },
      { id: 9, label: 'Bordeaux' },
      { id: 10, label: 'Lille' },
      { id: 11, label: 'Ren' },
      { id: 12, label: 'Reims' },
      { id: 13, label: 'Le Havre' },
      { id: 14, label: 'Saint-Etienne' },
      { id: 15, label: 'Toulon' },
      { id: 16, label: 'Grenoble' },
      { id: 17, label: 'Dijon' },
      { id: 18, label: 'Lui' },
      { id: 19, label: 'Angers' },
      { id: 20, label: 'Villeurbanne' },
      { id: 21, label: 'le Mans' },
      { id: 22, label: 'Saint-Denis' },
      { id: 23, label: 'Aix-en-Provence' },
      { id: 24, label: 'Clermont-Ferrand' },
      { id: 25, label: 'Brest' },
      { id: 26, label: 'Limoges' },
      { id: 27, label: 'Visiter' },
      { id: 28, label: 'Amiens' },
      { id: 29, label: 'Perpignan' },
      { id: 30, label: 'Metz' },
      { id: 31, label: 'Besançon' },
      { id: 32, label: 'Boulogne Billancourt' },
      { id: 33, label: 'Orléans' },
      { id: 34, label: 'Mulhouse' },
      { id: 35, label: 'Rouen' },
      { id: 36, label: 'Saint-Denis' },
      { id: 37, label: 'Kahn' },
      { id: 38, label: 'Argenteuil' },
      { id: 39, label: 'Saint-Paul' },
      { id: 40, label: 'Montreuil' },
      { id: 41, label: 'Nancy' },
      { id: 42, label: 'Nouméa' },
      { id: 43, label: 'Roubaix' },
      { id: 44, label: 'Tourcoing' },
      { id: 45, label: 'Nanterre' },
      { id: 46, label: 'Avignon' },
      { id: 47, label: 'Vitry-sur-Seine' },
      { id: 48, label: 'Créteil' },
      { id: 49, label: 'Dunkerque' },
      { id: 50, label: 'Poitiers' },
      { id: 51, label: 'Asnières-sur-Seine' },
      { id: 52, label: 'Courbevoie' },
      { id: 53, label: 'Versailles' },
      { id: 54, label: 'Colombes' },
      { id: 55, label: 'Fort de France' },
      { id: 56, label: 'One-sous-Bois' },
      { id: 57, label: 'Saint-Pierre' },
      { id: 58, label: 'Cherbourg-en-Cotentin' },
      { id: 59, label: 'Rueil-Malmaison' },
      { id: 60, label: 'Par' },
      { id: 61, label: 'Oberville' },
      { id: 62, label: 'Le Tampon' },
      { id: 63, label: 'Champigny-sur-Marne' },
      { id: 64, label: 'Antibes' },
      { id: 65, label: 'Bézier' },
      { id: 66, label: 'La Rochelle' },
      { id: 67, label: 'Saint-Maur-de-Fosse' },
      { id: 68, label: 'Cannes' },
      { id: 69, label: 'Calais' },
      { id: 70, label: 'Saint-Nazaire' },
      { id: 71, label: 'Mérignac' },
      { id: 72, label: 'Drancy' },
      { id: 73, label: 'Colmar' },
      { id: 74, label: 'Ajaccio' },
      { id: 75, label: 'Bourges' },
      { id: 76, label: 'Issy-les-Moulineaux' },
      { id: 77, label: 'Levallois-Perret' },
      { id: 78, label: 'La Saint-sur-Mer' },
      { id: 79, label: 'Campeur' },
      { id: 80, label: 'Noisy-le-Grand' },
      { id: 81, label: 'Villeneuve-d\'Ask' },
      { id: 82, label: 'Neuilly-sur-Seine' },
      { id: 83, label: 'Valence' },
      { id: 84, label: 'Antoine' },
      { id: 85, label: 'Cergy' },
      { id: 86, label: 'Vénisieux' },
      { id: 87, label: 'Pessac' },
      { id: 88, label: 'Trois' },
      { id: 89, label: 'Clichy-la-Garenne' },
      { id: 90, label: 'Ivry-sur-Seine' },
      { id: 91, label: 'Chambéry' },
      { id: 92, label: 'Lorient' },
      { id: 93, label: 'Les Abims' },
      { id: 94, label: 'Montauban' },
      { id: 95, label: 'Sarcelles' },
      { id: 96, label: 'Niort' },
      { id: 97, label: 'Mamutsu' },
      { id: 98, label: 'Villejuif' },
      { id: 99, label: 'Saint-André' },
      { id: 100, label: 'Hyères' },
      { id: 101, label: 'Saint-Quentin' },
      { id: 102, label: 'Beauvais' },
      { id: 103, label: 'Épinay-sur-Seine' },
      { id: 104, label: 'Cayenne' },
      { id: 105, label: 'Maison Alfort' },
      { id: 106, label: 'Cholet' },
      { id: 107, label: 'Moe' },
      { id: 108, label: 'Shel' },
      { id: 109, label: 'Panten' },
      { id: 110, label: 'Évry' },
      { id: 111, label: 'Fontenay-sous-Bois' },
      { id: 112, label: 'Fréjus' },
      { id: 113, label: 'Wang' },
      { id: 114, label: 'Bondi' },
      { id: 115, label: 'Narbonne' },
      { id: 116, label: 'Le Blanc Mesnil' },
      { id: 117, label: 'La Roche sur Yon' },
      { id: 118, label: 'Saint Louis' },
      { id: 119, label: 'Arles' },
      { id: 120, label: 'Clamart' },
      { id: 121, label: 'Annecy' },
      { id: 122, label: 'Sartrouville' },
      { id: 123, label: 'Grasse' },
      { id: 124, label: 'Laval' },
      { id: 125, label: 'Belfort' },
      { id: 126, label: 'Bobigny' },
      { id: 127, label: 'Évreux' },
      { id: 128, label: 'Vincennes' },
      { id: 129, label: 'Montrouge' },
      { id: 130, label: 'Sevran' },
      { id: 131, label: 'Albi' },
      { id: 132, label: 'Charleville-Mézières' },
      { id: 133, label: 'Suren' },
      { id: 134, label: 'Martigues' },
      { id: 135, label: 'Corbeil-Eson' },
      { id: 136, label: 'Saint-Ouen' },
      { id: 137, label: 'Bayonne' },
      { id: 138, label: 'Cagnes-sur-Mer' },
      { id: 139, label: 'Brive-la-Gaillarde' },
      { id: 140, label: 'Carcassonne' },
      { id: 141, label: 'Masi' },
      { id: 142, label: 'Blois' },
      { id: 143, label: 'Saint-Brieuc' },
      { id: 144, label: 'Aubagne' },
      { id: 145, label: 'Châteauroux' },
      { id: 146, label: 'Chalon-sur-Saône' },
      { id: 147, label: 'Mantes-la-Joly' },
      { id: 148, label: 'Meedon' },
      { id: 149, label: 'Saint-Malo' },
      { id: 150, label: 'Chalon-en-Champagne' },
      { id: 151, label: 'Alfortville' },
      { id: 152, label: 'Ensemble' },
      { id: 153, label: 'Salon de Provence' },
      { id: 154, label: 'Voz-en-Velen' },
      { id: 155, label: 'Puteaux' },
      { id: 156, label: 'Rony-sous-Bois' },
      { id: 157, label: 'Saint Herblain' },
      { id: 158, label: 'Gennevilliers' },
      { id: 159, label: 'Le Cannet' },
      { id: 160, label: 'Livry Gargan' },
      { id: 161, label: 'Saint-Premier' },
      { id: 162, label: 'Istres' },
      { id: 163, label: 'Valenciennes' },
      { id: 164, label: 'Choisy-le-Roy' },
      { id: 165, label: 'Kaluir-e-Cuir' },
      { id: 166, label: 'Boulogne-sur-Mer' },
      { id: 167, label: 'Bastia' },
      { id: 168, label: 'Angoulême' },
      { id: 169, label: 'Garges-le-Gonesse' },
      { id: 170, label: 'Castres' },
      { id: 171, label: 'Thionville' },
      { id: 172, label: 'Vatrelo' },
      { id: 173, label: 'Équilibre' },
      { id: 174, label: 'Saint Laurent du Maroni' },
      { id: 175, label: 'Douai' },
      { id: 176, label: 'Noisy-le-Sec' },
      { id: 177, label: 'Tarbes' },
      { id: 178, label: 'Arras' },
      { id: 179, label: 'Alès' },
      { id: 180, label: 'La Courneuve' },
      { id: 181, label: 'Bourque-en-Bresse' },
      { id: 182, label: 'Compiègne' },
      { id: 183, label: 'Écart' },
      { id: 184, label: 'Melun' },
      { id: 185, label: 'Lamentin' },
      { id: 186, label: 'Rezé' },
      { id: 187, label: 'Saint-Germain-en-Laye' },
      { id: 188, label: 'Marc-en-Bareul' },
      { id: 189, label: 'Ghané' },
      { id: 190, label: 'Anglet' },
      { id: 191, label: 'Draguignan' },
      { id: 192, label: 'Chartres' },
      { id: 193, label: 'Bron' },
      { id: 194, label: 'Bagno' },
      { id: 195, label: 'Colomier' },
      { id: 196, label: 'Saint Martin d\'Her' },
      { id: 197, label: 'Ponto Combo' },
      { id: 198, label: 'Montluçon' },
      { id: 199, label: 'Jouet-le-Tour' },
      { id: 200, label: 'Saint-Joseph' },
      { id: 201, label: 'Poissy' },
      { id: 202, label: 'Savigny-sur-Orge' },
      { id: 203, label: 'Montélimar' },
      { id: 204, label: 'Villefranche-sur-Saône' },
      { id: 205, label: 'Sten' },
      { id: 206, label: 'Saint Benoit' },
      { id: 207, label: 'Bagnolet' },
      { id: 208, label: 'Châtillon' },
      { id: 209, label: 'Le Port' },
      { id: 210, label: 'Sainte-Geneviève-des-Bois' },
      { id: 211, label: 'Saint Martin' },
      { id: 212, label: 'Échirol' },
      { id: 213, label: 'Rouan' },
      { id: 214, label: 'Villepinte' },
      { id: 215, label: 'Saint-Chamond' },
      { id: 216, label: 'Conflans-Saint-Honorine' },
      { id: 217, label: 'Auxerre' },
      { id: 218, label: 'Nièvre' },
      { id: 219, label: 'Neuilly-sur-Marne' },
      { id: 220, label: 'La Ciotat' },
      { id: 221, label: 'Tremblay-en-France' },
      { id: 222, label: 'Thonon-les-Bains' },
      { id: 223, label: 'Vitrol' },
      { id: 224, label: 'Agno' },
      { id: 225, label: 'Sis-Fours-les-Plage' },
      { id: 226, label: 'Agen' },
      { id: 227, label: 'Cray' },
      { id: 228, label: 'Anmas' },
      { id: 229, label: 'Saint Raphaël' },
      { id: 230, label: 'Marignane' },
      { id: 231, label: 'Romans-sur-Isère' },
      { id: 232, label: 'Montigny-le-Bretonneux' },
      { id: 233, label: 'Le Perieu-sur-Marne' },
      { id: 234, label: 'Franconville' },
      { id: 235, label: 'Mâcon' },
      { id: 236, label: 'Saint-Leu' },
      { id: 237, label: 'Cambrai' },
      { id: 238, label: 'Chatne-Malabri' },
      { id: 239, label: 'Sainte-Marie' },
      { id: 240, label: 'Villeneuve-Saint-Georges' },
      { id: 241, label: 'Will' },
      { id: 242, label: 'Épinal' },
      { id: 243, label: 'Doumbéa' },
      { id: 244, label: 'Lance' },
      { id: 245, label: 'Liévain' },
      { id: 246, label: 'Le Muro' },
      { id: 247, label: 'Schiltigheim' },
      { id: 248, label: 'La Possession' },
      { id: 249, label: 'Meisieux' },
      { id: 250, label: 'Dreux' },
      { id: 251, label: 'Nogent-sur-Marne' },
      { id: 252, label: 'Plaisir' },
      { id: 253, label: 'Mont de Marsan' },
      { id: 254, label: 'Palaiseau' },
      { id: 255, label: 'Châtellerault' },
      { id: 256, label: 'Goossenville' },
      { id: 257, label: 'L\'Ail-lès-Rose' },
      { id: 258, label: 'Viry-Châtillon' },
      { id: 259, label: 'Vigneux-sur-Seine' },
      { id: 260, label: 'Piège' },
      { id: 261, label: 'Shatu' },
      { id: 262, label: 'Clichy-sous-Bois' },
      { id: 263, label: 'Vilnave-d\'Ornon' },
      { id: 264, label: 'Rillieu-la-Pape' },
      { id: 265, label: 'Maubeuge' },
      { id: 266, label: 'Charenton-le-Pont' },
      { id: 267, label: 'Malakoff' },
      { id: 268, label: 'Maturité' },
      { id: 269, label: 'Dieppe' },
      { id: 270, label: 'Athe-Mon' },
      { id: 271, label: 'Savigny-le-Temple' },
      { id: 272, label: 'Périgueux' },
      { id: 273, label: 'Be-Mao' },
    ],
  },
  {
    id: 1,
    label: 'England',
    lang: 'eng',
    code: 'GB',
    currency: 'GBP',
    showLang: true,
    cities: [
      { id: 1, label: 'London' },
      { id: 2, label: 'Birmingham' },
      { id: 3, label: 'Leeds' },
      { id: 4, label: 'Glasgow' },
      { id: 5, label: 'Sheffield' },
      { id: 6, label: 'Manchester' },
      { id: 7, label: 'Bradford' },
      { id: 8, label: 'Liverpool' },
      { id: 9, label: 'Edinburgh' },
      { id: 10, label: 'Bristol' },
      { id: 11, label: 'Cardiff' },
      { id: 12, label: 'Coventry' },
      { id: 13, label: 'Belfast' },
      { id: 14, label: 'Leicester' },
      { id: 15, label: 'Nottingham' },
      { id: 16, label: 'Newcastle' },
      { id: 17, label: 'Brighton & Hove' },
      { id: 18, label: 'Plymouth' },
      { id: 19, label: 'Kingston upon Hull' },
      { id: 20, label: 'Wolverhampton' },
      { id: 21, label: 'Stoke-on-Trent' },
      { id: 22, label: 'Derby' },
      { id: 23, label: 'Swansea' },
      { id: 24, label: 'Southampton' },
      { id: 25, label: 'York' },
      { id: 26, label: 'Portsmouth' },
      { id: 27, label: 'Peterborough' },
      { id: 28, label: 'Aberdeen' },
      { id: 29, label: 'Sunderland' },
      { id: 30, label: 'Oxford' },
      { id: 31, label: 'Newport' },
      { id: 32, label: 'Dundee' },
      { id: 33, label: 'Norwich' },
      { id: 34, label: 'Gloucester' },
      { id: 35, label: 'Exeter' },
      { id: 36, label: 'Cambridge' },
      { id: 37, label: 'Preston' },
      { id: 38, label: 'Chester' },
      { id: 39, label: 'Chelmsford' },
      { id: 40, label: 'Salford' },
      { id: 41, label: 'Worcester' },
      { id: 42, label: 'Wakefield' },
      { id: 43, label: 'Lincoln' },
      { id: 44, label: 'Bat' },
      { id: 45, label: 'Londonderry' },
      { id: 46, label: 'Wells' },
      { id: 47, label: 'Lisburn' },
      { id: 48, label: 'Carlisle' },
      { id: 49, label: 'Inverness' },
      { id: 50, label: 'Hereford' },
      { id: 51, label: 'St Albans' },
      { id: 52, label: 'Canterbury' },
      { id: 53, label: 'Lancaster' },
      { id: 54, label: 'Durham' },
      { id: 55, label: 'Perth' },
      { id: 56, label: 'Winchester' },
      { id: 57, label: 'Salisbury' },
      { id: 58, label: 'Sterling' },
      { id: 59, label: 'Lichfield' },
      { id: 60, label: 'Newry' },
      { id: 61, label: 'Chichester' },
      { id: 62, label: 'Or' },
      { id: 63, label: 'Bangor' },
      { id: 64, label: 'Truro' },
      { id: 65, label: 'Ripon' },
      { id: 66, label: 'Arma' },
      { id: 67, label: 'Saint Asaph' },
      { id: 68, label: 'St. Davids' },
    ],
  },
  {
    id: 2,
    label: 'Türkiye',
    lang: 'tur',
    currency: 'TRY',
    code: 'TR',
    showLang: true,
    cities: [
      { id: 1, label: 'İstanbul' },
      { id: 2, label: 'Ankara' },
      { id: 3, label: 'izmir' },
      { id: 4, label: 'Bursa' },
      { id: 5, label: 'Adana' },
      { id: 6, label: 'Gaziantep' },
      { id: 7, label: 'Konya' },
      { id: 8, label: 'Antalya' },
      { id: 9, label: 'Kayseri' },
      { id: 10, label: 'Mersin' },
      { id: 11, label: 'Eskişehir' },
      { id: 12, label: 'Diyarbakır' },
      { id: 13, label: 'Denizli' },
      { id: 14, label: 'Şanlıurfa' },
      { id: 15, label: 'Samsun' },
      { id: 16, label: 'Esenyurt' },
      { id: 17, label: 'Malatya' },
      { id: 18, label: 'Adapazarı' },
      { id: 19, label: 'Kahramanmaraş' },
      { id: 20, label: 'Erzurum' },
      { id: 21, label: 'Van (şehir)' },
      { id: 22, label: 'Elazığ' },
      { id: 23, label: 'yarasa Adam' },
      { id: 24, label: 'Sultanbeyli' },
      { id: 25, label: 'İzmit' },
      { id: 26, label: 'Sivas' },
      { id: 27, label: 'Gebze' },
      { id: 28, label: 'Manisa' },
      { id: 29, label: 'Balıkesir' },
      { id: 30, label: 'sancaktepe' },
      { id: 31, label: 'Tarsus' },
      { id: 32, label: 'Trabzon' },
      { id: 33, label: 'koro' },
      { id: 34, label: 'Çorlu' },
      { id: 35, label: 'Kayapınar' },
      { id: 36, label: 'Osmaniye' },
      { id: 37, label: 'Adıyaman' },
      { id: 38, label: 'Kütahya' },
      { id: 39, label: 'Kırkkale' },
      { id: 40, label: 'Antakya' },
      { id: 41, label: 'Beylikdüzü' },
      { id: 42, label: 'Büyükçekmece' },
      { id: 43, label: 'İskenderun' },
      { id: 44, label: 'Aydın' },
      { id: 45, label: 'Uşak' },
      { id: 46, label: 'Arnavutköy' },
      { id: 47, label: 'Aksaray' },
      { id: 48, label: 'Isparta' },
      { id: 49, label: 'Afyonkarahisar' },
      { id: 50, label: 'İnegöl' },
      { id: 51, label: 'ekmekoy' },
      { id: 52, label: 'Tekirdağ' },
      { id: 53, label: 'Edirne' },
      { id: 54, label: 'Darija' },
      { id: 55, label: 'Gölcük' },
      { id: 56, label: 'Karaman' },
      { id: 57, label: 'Körfez' },
      { id: 58, label: 'sürü' },
      { id: 59, label: 'Siirt' },
      { id: 60, label: 'Kızıltepe' },
      { id: 61, label: 'Derince' },
      { id: 62, label: 'Mezitli' },
      { id: 63, label: 'Düzce' },
      { id: 64, label: 'Tokat' },
      { id: 65, label: 'Bolu' },
      { id: 66, label: 'Menemen' },
      { id: 67, label: 'Turgutlu' },
      { id: 68, label: 'Silivri' },
      { id: 69, label: 'Torbala' },
      { id: 70, label: 'Bandırma' },
      { id: 71, label: 'Nazilli' },
      { id: 72, label: 'Zonguldak' },
      { id: 73, label: 'Karabük' },
      { id: 74, label: 'Siverek' },
      { id: 75, label: 'Kırşehir' },
      { id: 76, label: 'Akhisar' },
      { id: 77, label: 'Pursaklar' },
      { id: 78, label: 'Lüleburgaz' },
      { id: 79, label: 'Hiçbir yerde' },
      { id: 80, label: 'Ceyhan' },
      { id: 81, label: 'Ereğli' },
      { id: 82, label: 'Cizre' },
      { id: 83, label: 'Salihli' },
      { id: 84, label: 'Çanakkale' },
      { id: 85, label: 'Yalova' },
      { id: 86, label: 'Nizip' },
      { id: 87, label: 'Alanya' },
      { id: 88, label: 'Ereğli' },
      { id: 89, label: 'Giresun' },
      { id: 90, label: 'Gemlik' },
      { id: 91, label: 'Rize' },
      { id: 92, label: 'polatlı' },
      { id: 93, label: 'Ağrı' },
      { id: 94, label: 'Bingöl' },
      { id: 95, label: 'Kartepe' },
      { id: 96, label: 'Erzincan' },
      { id: 97, label: 'Nevşehir' },
      { id: 98, label: 'Kastamonu' },
      { id: 99, label: 'Talas' },
      { id: 100, label: 'Nusaybin' },
      { id: 101, label: 'Çayırova' },
      { id: 102, label: 'Mardin' },
      { id: 103, label: 'Elbistan' },
      { id: 104, label: 'Bafra' },
      { id: 105, label: 'Kilis' },
      { id: 106, label: 'Amasya' },
      { id: 107, label: 'Manavgat' },
      { id: 108, label: 'Kadirli' },
      { id: 109, label: 'Silopi' },
      { id: 110, label: 'Ygdır' },
      { id: 111, label: 'Kedi balığı' },
      { id: 112, label: 'Çerkezköy' },
      { id: 113, label: 'Viranşehir' },
      { id: 114, label: 'Kars' },
      { id: 115, label: 'Çubuk' },
      { id: 116, label: 'Kozan' },
      { id: 117, label: 'Kemalpaşa' },
      { id: 118, label: 'Yodemiş' },
      { id: 119, label: 'Yozgat' },
      { id: 120, label: 'Ardıç' },
      { id: 121, label: 'Fethiye' },
      { id: 122, label: 'Erciş' },
      { id: 123, label: 'Dörtyol' },
      { id: 124, label: 'Lapa' },
      { id: 125, label: 'Çankırı' },
      { id: 126, label: 'Kırhan' },
      { id: 127, label: 'şok' },
      { id: 128, label: 'Şırnak' },
      { id: 129, label: 'Hakkari' },
      { id: 130, label: 'Kırklareli' },
      { id: 131, label: 'Başiskele' },
      { id: 132, label: 'Doğubayazıt' },
      { id: 133, label: 'Kuşadası' },
      { id: 134, label: 'Ergani' },
      { id: 135, label: 'Tavşanlı' },
      { id: 136, label: 'Burdur' },
      { id: 137, label: 'Turhal' },
      { id: 138, label: 'Yüksekova' },
      { id: 139, label: 'Tatvan' },
      { id: 140, label: 'Reikhanly' },
      { id: 141, label: 'Muğla' },
      { id: 142, label: 'Kakhta' },
      { id: 143, label: 'Akşehir' },
      { id: 144, label: 'Bergama' },
      { id: 145, label: 'Erbaa' },
      { id: 146, label: 'Bozüyük' },
      { id: 147, label: 'Çarşamba' },
      { id: 148, label: 'Mustafakemalpaşa' },
      { id: 149, label: 'Patnos' },
      { id: 150, label: 'Orhangazi' },
      { id: 151, label: 'Bismil' },
      { id: 152, label: 'sorgun' },
      { id: 153, label: 'Keşan' },
      { id: 154, label: 'Menderes' },
      { id: 155, label: 'Midyat' },
      { id: 156, label: 'Gursu' },
      { id: 157, label: 'Merzifon' },
      { id: 158, label: 'kapasiteler' },
      { id: 159, label: 'Milas' },
      { id: 160, label: 'Karabej' },
      { id: 161, label: 'Aliağa' },
      { id: 162, label: 'Suruç' },
      { id: 163, label: 'Edremit' },
      { id: 164, label: 'kira' },
      { id: 165, label: 'Mudanya' },
      { id: 166, label: 'Fatsa' },
      { id: 167, label: 'Bartın' },
      { id: 168, label: 'Silifke' },
      { id: 169, label: 'Alaşehir' },
      { id: 170, label: 'Didim' },
      { id: 171, label: 'Serik' },
      { id: 172, label: 'Birecik' },
      { id: 173, label: 'Karamürsel' },
      { id: 174, label: 'Karaköy' },
      { id: 175, label: 'Samandağ' },
      { id: 176, label: 'Urla' },
      { id: 177, label: 'Erdemli' },
      { id: 178, label: 'Ceylanpınar' },
      { id: 179, label: 'Gönen' },
      { id: 180, label: 'dilovasi' },
      { id: 181, label: 'Elmadağ' },
      { id: 182, label: 'hendeck' },
      { id: 183, label: 'Dusigi' },
      { id: 184, label: 'Akyazı' },
      { id: 185, label: 'Uzunkyopryu' },
      { id: 186, label: 'Bitlis' },
      { id: 187, label: 'Biga' },
      { id: 188, label: 'Seydişehir' },
      { id: 189, label: 'Kazan' },
      { id: 190, label: 'Silvan' },
      { id: 191, label: 'Afşin' },
      { id: 192, label: 'Burhaniye' },
      { id: 193, label: 'Kestel' },
      { id: 194, label: 'Suluova' },
      { id: 195, label: 'Tekköy' },
      { id: 196, label: 'Budjak' },
      { id: 197, label: 'Sinop' },
      { id: 198, label: 'Develi' },
      { id: 199, label: 'Bulanjak' },
      { id: 200, label: 'Bor (Türkiye)' },
      { id: 201, label: 'Safranbolu' },
      { id: 202, label: 'Akçaabat' },
      { id: 203, label: 'Çatalja' },
      { id: 204, label: 'Ayvalık' },
      { id: 205, label: 'Marmaris' },
      { id: 206, label: 'Payas' },
      { id: 207, label: 'Sungurlu' },
      { id: 208, label: 'Beypazarı' },
      { id: 209, label: 'Zile' },
      { id: 210, label: 'Bilecik' },
      { id: 211, label: 'Keçi' },
      { id: 212, label: 'kumlu' },
      { id: 213, label: 'Bodrum' },
      { id: 214, label: 'Sapanca' },
      { id: 215, label: 'Anamur' },
      { id: 216, label: 'Bayburt' },
      { id: 217, label: 'Karapınar' },
      { id: 218, label: 'Nixar' },
      { id: 219, label: 'Terme' },
      { id: 220, label: 'Ilgın' },
      { id: 221, label: 'Yslahiye' },
      { id: 222, label: 'Beyşehir' },
      { id: 223, label: 'Erzin' },
      { id: 224, label: 'Bolvadin' },
      { id: 225, label: 'Tunceli' },
      { id: 226, label: 'Yenişehir' },
      { id: 227, label: 'Erköy' },
      { id: 228, label: 'Çumra' },
      { id: 229, label: 'Karasu' },
      { id: 230, label: 'babaeski' },
      { id: 231, label: 'malkara' },
      { id: 232, label: 'Chan' },
      { id: 233, label: 'Kumluca' },
      { id: 234, label: 'Kurtalan' },
      { id: 235, label: 'Ortaca' },
      { id: 236, label: 'Şereflikochhisar' },
      { id: 237, label: 'Selçuk' },
      { id: 238, label: 'Pazarcık' },
      { id: 239, label: 'Mut' },
      { id: 240, label: 'Gölbaşı' },
      { id: 241, label: 'Tosya' },
      { id: 242, label: 'Akyurt' },
      { id: 243, label: 'Vesirköprü' },
      { id: 244, label: 'boyabat' },
      { id: 245, label: 'Osmancık' },
      { id: 246, label: 'Delilik' },
      { id: 247, label: 'Sarıkamış' },
      { id: 248, label: 'Seferihisar' },
      { id: 249, label: 'Simav' },
      { id: 250, label: 'Dinar' },
      { id: 251, label: 'Gümüşhane' },
      { id: 252, label: 'Susurluk' },
      { id: 253, label: 'Akçakale' },
      { id: 254, label: 'Foça' },
      { id: 255, label: 'Kilimli' },
      { id: 256, label: 'Harbiye' },
      { id: 257, label: 'İdil' },
      { id: 258, label: 'Ardeşen' },
      { id: 259, label: 'Dalaman' },
      { id: 260, label: 'Gelibolu' },
      { id: 261, label: 'Erdek' },
      { id: 262, label: 'Gazipaşa' },
      { id: 263, label: 'Akçakoja' },
      { id: 264, label: 'Artvin' },
      { id: 265, label: 'Çayjuma' },
      { id: 266, label: 'Çeşme' },
      { id: 267, label: 'Kemer' },
      { id: 268, label: 'Belém' },
      { id: 269, label: 'Ahır' },
      { id: 270, label: 'İznik' },
      { id: 271, label: 'Alaca' },
      { id: 272, label: 'Gerede' },
      { id: 273, label: 'Devrek' },
      { id: 274, label: 'Akdağmadeni' },
      { id: 275, label: 'kaman' },
      { id: 276, label: 'Bayındır' },
      { id: 277, label: 'Serignol' },
      { id: 278, label: 'Kozluk' },
      { id: 279, label: 'Bulanık' },
      { id: 280, label: 'Sarıkaya' },
      { id: 281, label: 'Kulu' },
      { id: 282, label: 'Şarkişla' },
      { id: 283, label: 'Korkuteli' },
      { id: 284, label: 'Oltu' },
      { id: 285, label: 'geive' },
      { id: 286, label: 'İmamoğlu' },
      { id: 287, label: 'Çin' },
      { id: 288, label: 'chivril' },
      { id: 289, label: 'Gyoksun' },
      { id: 290, label: 'Derik' },
      { id: 291, label: 'Hilvan' },
      { id: 292, label: 'Chaeli' },
      { id: 293, label: 'İskilip' },
      { id: 294, label: 'Havza' },
      { id: 295, label: 'Yahyalı' },
      { id: 296, label: 'Hayrabolu' },
      { id: 297, label: 'İncirliova' },
      { id: 298, label: 'Kovancılar' },
      { id: 299, label: 'Gyuroymak' },
      { id: 300, label: 'Muratlı' },
      { id: 301, label: 'Malazgirt' },
      { id: 302, label: 'Diyadin' },
      { id: 303, label: 'Emirdağ' },
      { id: 304, label: 'ne yazık ki' },
      { id: 305, label: 'Bostaniği' },
      { id: 306, label: 'Gediz' },
      { id: 307, label: 'Sarayköy' },
      { id: 308, label: 'Yalvaç' },
      { id: 309, label: 'Mahmutlar' },
      { id: 310, label: 'Demirci' },
      { id: 311, label: 'Ortaköy' },
      { id: 312, label: 'Turgutreis' },
      { id: 313, label: 'Karaağaç' },
      { id: 314, label: 'Pala' },
      { id: 315, label: 'Nurdağ' },
      { id: 316, label: 'Çiftlikköy' },
      { id: 317, label: 'Battalgazi' },
      { id: 318, label: 'Ingeler' },
      { id: 319, label: 'Çermik' },
      { id: 320, label: 'Dargechit' },
      { id: 321, label: 'Genç' },
      { id: 322, label: 'Dursunbey' },
      { id: 323, label: 'Horasan' },
      { id: 324, label: 'Gümüşhajikoy' },
      { id: 325, label: 'Kızılcahamam' },
      { id: 326, label: 'Pamukova' },
      { id: 327, label: 'Eğirdir' },
      { id: 328, label: 'köpekbalığı' },
      { id: 329, label: 'Boğazlıyan' },
      { id: 330, label: 'Hopa' },
      { id: 331, label: 'Eşilli' },
      { id: 332, label: 'Dikili' },
      { id: 333, label: 'Ahlat' },
      { id: 334, label: 'Ardahan' },
      { id: 335, label: 'Yürgüp' },
      { id: 336, label: 'kontrol etme' },
      { id: 337, label: 'Taşköprü' },
      { id: 338, label: 'zeytinli' },
      { id: 339, label: 'eskil' },
      { id: 340, label: 'Muradiye' },
      { id: 341, label: 'espiri' },
      { id: 342, label: 'Bozyazı' },
      { id: 343, label: 'Bigadiç' },
      { id: 344, label: 'Kırağaç' },
      { id: 345, label: 'sarukhanlı' },
      { id: 346, label: 'Demre' },
      { id: 347, label: 'Oğuzeli' },
      { id: 348, label: 'Kızılpınar' },
      { id: 349, label: 'Pazar' },
      { id: 350, label: 'Cihanbeyli' },
      { id: 351, label: 'Marmaraereğlisi' },
      { id: 352, label: 'Altınoluk' },
      { id: 353, label: 'banaz' },
      { id: 354, label: 'Görele' },
      { id: 355, label: 'Buldan' },
      { id: 356, label: 'Elmalı' },
      { id: 357, label: 'Araklı' },
      { id: 358, label: 'Nın-nin' },
      { id: 359, label: 'Çaldıran' },
      { id: 360, label: 'Şemdinli' },
      { id: 361, label: 'Denizciler' },
      { id: 362, label: 'Zekiriyaköy' },
      { id: 363, label: 'Arhavi' },
      { id: 364, label: 'Yan' },
      { id: 365, label: 'Armutalan' },
      { id: 366, label: 'Vakfykebir' },
      { id: 367, label: 'Aybastı' },
      { id: 368, label: 'Karakoçan' },
      { id: 369, label: 'Obaköy' },
      { id: 370, label: 'Narlıca' },
      { id: 371, label: 'Türkoğlu' },
      { id: 372, label: 'Solhan' },
      { id: 373, label: 'Çay' },
      { id: 374, label: 'Osmaneli' },
      { id: 375, label: 'Kadıhanı' },
      { id: 376, label: 'pelitli' },
      { id: 377, label: 'Sürmene' },
      { id: 378, label: 'Ferizli' },
      { id: 379, label: 'Kelkit' },
      { id: 380, label: 'Schiele' },
      { id: 381, label: 'Pasinler' },
      { id: 382, label: 'Kandır' },
      { id: 383, label: 'Kağızman' },
      { id: 384, label: 'Suşehri' },
      { id: 385, label: 'Aziziye (2008\'e kadar - Ilyca)' },
      { id: 386, label: 'Sarıgöl' },
      { id: 387, label: 'Tirebolu' },
      { id: 388, label: 'Başkale' },
      { id: 389, label: 'Bayramiç' },
      { id: 390, label: 'Karaçulka' },
      { id: 391, label: 'Corgan' },
      { id: 392, label: 'Eşme' },
      { id: 393, label: 'Gölhisar' },
      { id: 394, label: 'sindirji' },
      { id: 395, label: 'Arsine' },
      { id: 396, label: 'Ortaklar' },
      { id: 397, label: 'Kavun' },
      { id: 398, label: 'Edremit' },
      { id: 399, label: 'Uludere' },
      { id: 400, label: 'Konaklı' },
      { id: 401, label: 'Balya' },
      { id: 402, label: 'Kocaeli' },
      { id: 403, label: 'Çağlayanjerit' },
      { id: 404, label: 'Germendzhik' },
      { id: 405, label: 'Kadınkedi' },
      { id: 406, label: 'Şarki-Karaağaç' },
      { id: 407, label: 'Çelebibagi' },
      { id: 408, label: 'Bünyan' },
      { id: 409, label: 'Çınarcık' },
      { id: 410, label: 'Şuhut' },
      { id: 411, label: 'Hacılar' },
      { id: 412, label: 'Tavas' },
      { id: 413, label: 'mücur' },
      { id: 414, label: 'Beşikdüzü' },
      { id: 415, label: 'Kumru' },
      { id: 416, label: 'Nallıhan' },
      { id: 417, label: 'acıpayam' },
      { id: 418, label: 'Kınık' },
      { id: 419, label: 'Sason' },
      { id: 420, label: 'Vize' },
      { id: 421, label: 'Hasköy' },
      { id: 422, label: 'Finike' },
      { id: 423, label: 'Doğanşehir' },
      { id: 424, label: 'Isjehisar' },
      { id: 425, label: 'Özalp' },
      { id: 426, label: 'şef' },
      { id: 427, label: 'Kuzudzhulu' },
      { id: 428, label: 'Tyomyuk' },
      { id: 429, label: 'Küçükköy' },
      { id: 430, label: 'Ezine' },
      { id: 431, label: 'Taşova' },
      { id: 432, label: 'Pınarhisar' },
      { id: 433, label: 'Avanos' },
      { id: 434, label: 'Yeniköy' },
      { id: 435, label: 'Kargynary' },
      { id: 436, label: 'Gordes' },
      { id: 437, label: 'Gyolköy' },
      { id: 438, label: 'Zara' },
      { id: 439, label: 'Umurlu' },
      { id: 440, label: 'Ondokuzmayys' },
      { id: 441, label: 'Gerze' },
      { id: 442, label: 'Datça' },
      { id: 443, label: 'Pınarbaşı' },
      { id: 444, label: 'Çınar' },
      { id: 445, label: 'Ayanzhik' },
      { id: 446, label: 'Lig' },
      { id: 447, label: 'Alaham' },
      { id: 448, label: 'Serinhisar' },
      { id: 449, label: 'Yalıkavak' },
      { id: 450, label: 'Yomra' },
      { id: 451, label: 'Yeşilköy [ru]' },
      { id: 452, label: 'Havran' },
      { id: 453, label: 'Çukurca' },
      { id: 454, label: 'Bozova' },
      { id: 455, label: 'Lapseki' },
      { id: 456, label: 'Altınova' },
      { id: 457, label: 'Emet' },
      { id: 458, label: 'Akçay' },
      { id: 459, label: 'Divriği' },
      { id: 460, label: 'Davutlar' },
      { id: 461, label: 'Karaağaç' },
      { id: 462, label: 'Derinkuyu' },
      { id: 463, label: 'çekerek' },
      { id: 464, label: 'Ermenek' },
      { id: 465, label: 'Borçka' },
      { id: 466, label: 'Mazıdağı' },
      { id: 467, label: 'Alaçatı' },
      { id: 468, label: 'keskin' },
      { id: 469, label: 'Ahmetli' },
      { id: 470, label: 'Şefatlı' },
      { id: 471, label: 'Chamash' },
      { id: 472, label: 'Selam dostum' },
      { id: 473, label: 'Bayat' },
      { id: 474, label: 'Büyükkonjali' },
      { id: 475, label: 'Tomarza' },
      { id: 476, label: 'Sultan-Hanlar' },
      { id: 477, label: 'Kepez' },
      { id: 478, label: 'Tunçbilek' },
      { id: 479, label: 'Adiljevaz' },
      { id: 480, label: 'Bozdoğan' },
      { id: 481, label: 'Gevaş' },
      { id: 482, label: 'gelinkaya' },
      { id: 483, label: 'Aşkala' },
      { id: 484, label: 'kangal' },
      { id: 485, label: 'Turgutalp' },
      { id: 486, label: 'Kyoşk' },
      { id: 487, label: 'Yakshikhan' },
      { id: 488, label: 'Cikcilli' },
      { id: 489, label: 'Ayaş' },
      { id: 490, label: 'Adjarlar' },
      { id: 491, label: 'Posantlar' },
      { id: 492, label: 'Enige [ru]' },
      { id: 493, label: 'Sivrihisar' },
      { id: 494, label: 'eruh' },
      { id: 495, label: 'Gölmarmara' },
      { id: 496, label: 'kulp' },
      { id: 497, label: 'Şogüt' },
      { id: 498, label: 'Taşhuju' },
      { id: 499, label: 'Odabaşı' },
      { id: 500, label: 'Fındıklı' },
      { id: 501, label: 'Perşembe' },
      { id: 502, label: 'Gölyak' },
      { id: 503, label: 'Şebinkarahisar' },
      { id: 504, label: 'araban' },
      { id: 505, label: 'Hassa' },
      { id: 506, label: 'Akçadağ' },
      { id: 507, label: 'Savaştepe' },
      { id: 508, label: 'Kaynaşlı' },
      { id: 509, label: 'Karayılan' },
      { id: 510, label: 'Reşadiye' },
      { id: 511, label: 'Khizan' },
      { id: 512, label: 'Sarayönü' },
      { id: 513, label: 'Hani' },
      { id: 514, label: 'Palu' },
      { id: 515, label: 'İnebolu' },
      { id: 516, label: 'Honaz' },
      { id: 517, label: 'Guryun' },
      { id: 518, label: 'Beşiri' },
      { id: 519, label: 'Yunak' },
      { id: 520, label: 'Yeşilhisar' },
      { id: 521, label: 'Avsallar' },
      { id: 522, label: 'Yıldızeli' },
      { id: 523, label: 'Varto' },
      { id: 524, label: 'Mavikent' },
      { id: 525, label: 'Halfeti' },
      { id: 526, label: 'Pertek' },
      { id: 527, label: 'Küçükdalyan' },
      { id: 528, label: 'Çerkez' },
      { id: 529, label: 'Dil' },
      { id: 530, label: 'Akbez' },
      { id: 531, label: 'karakoban' },
      { id: 532, label: 'Çatalağzı' },
      { id: 533, label: 'Tekebaşı' },
      { id: 534, label: 'Kalecik' },
      { id: 535, label: 'Karataş' },
      { id: 536, label: 'Konadzhik' },
      { id: 537, label: 'Hınılar' },
      { id: 538, label: 'İpsala' },
      { id: 539, label: 'Çobanlar' },
      { id: 540, label: 'Havsa' },
      { id: 541, label: 'yakın' },
      { id: 542, label: 'Beoğlu' },
      { id: 543, label: 'Çayırhan' },
      { id: 544, label: 'Sahilkent' },
      { id: 545, label: 'Gülşehir' },
      { id: 546, label: 'Darenda' },
      { id: 547, label: 'Ladik' },
      { id: 548, label: 'Bağırmak' },
      { id: 549, label: 'Kestel' },
      { id: 550, label: 'Eleşkirt' },
      { id: 551, label: 'Sultanköy' },
      { id: 552, label: 'Dilek' },
      { id: 553, label: 'Eniçiftlik' },
      { id: 554, label: 'Turunçova' },
      { id: 555, label: 'Gözcüler' },
      { id: 556, label: 'Sarılar' },
      { id: 557, label: 'Köyceğiz' },
      { id: 558, label: 'Einesil' },
      { id: 559, label: 'Ladik' },
      { id: 560, label: 'Aydıncık' },
      { id: 561, label: 'Tuzluja' },
      { id: 562, label: 'Çelikhan' },
      { id: 563, label: 'Söğütlü' },
      { id: 564, label: 'Kiraz' },
      { id: 565, label: 'Esaret' },
      { id: 566, label: 'Dicle' },
      { id: 567, label: 'Enige [ru]' },
      { id: 568, label: 'toprakkale' },
      { id: 569, label: 'yeşilyurt' },
      { id: 570, label: 'Söğütlü' },
      { id: 571, label: 'Yurtbaşı' },
      { id: 572, label: 'Bostanbaşı' },
      { id: 573, label: 'enijoba' },
      { id: 574, label: 'tadını çıkarın' },
      { id: 575, label: 'Calais' },
      { id: 576, label: 'Savuja' },
      { id: 577, label: 'Andyrin' },
      { id: 578, label: 'Keşhap' },
      { id: 579, label: 'Hekimhan' },
      { id: 580, label: 'ismil' },
      { id: 581, label: 'Enij [tr]' },
      { id: 582, label: 'Narly' },
      { id: 583, label: 'Kuyujak' },
      { id: 584, label: 'Gürgentepe' },
      { id: 585, label: 'acı biber' },
      { id: 586, label: 'Orkhaneli' },
      { id: 587, label: 'Beldibi' },
      { id: 588, label: 'Göl' },
      { id: 589, label: 'Aktepe' },
      { id: 590, label: 'Ayvadzhik' },
      { id: 591, label: 'Çayıralan' },
      { id: 592, label: 'Bozkır' },
      { id: 593, label: 'Karapyurchek' },
      { id: 594, label: 'Saraykent' },
      { id: 595, label: 'Tonya' },
      { id: 596, label: 'kawak' },
      { id: 597, label: 'Keçiborlu' },
      { id: 598, label: 'Çolaklı' },
      { id: 599, label: 'Ovaemir' },
      { id: 600, label: 'Icherihumra' },
      { id: 601, label: 'Macca' },
      { id: 602, label: 'Bitez' },
      { id: 603, label: 'Yağlıdere' },
      { id: 604, label: 'Çarşibaşı' },
      { id: 605, label: 'Durağan' },
      { id: 606, label: 'Kozaklı' },
      { id: 607, label: 'Piraziz' },
      { id: 608, label: 'Bekbele' },
      { id: 609, label: 'Şekeroba' },
      { id: 610, label: 'arytaş' },
      { id: 611, label: 'Atça' },
      { id: 612, label: 'Gökçebei' },
      { id: 613, label: 'Ulukışla' },
      { id: 614, label: 'Gülüç' },
      { id: 615, label: 'Altınova' },
      { id: 616, label: 'Sakçagca' },
      { id: 617, label: 'Atayurt' },
      { id: 618, label: 'Yşiklar' },
      { id: 619, label: 'Karaisalı' },
      { id: 620, label: 'Uluborlu' },
      { id: 621, label: 'Göynük' },
      { id: 622, label: 'Yılgaz' },
      { id: 623, label: 'Atakent' },
      { id: 624, label: 'Çiçekdağı' },
      { id: 625, label: 'Gökçe' },
      { id: 626, label: 'Buharkent' },
      { id: 627, label: 'Velimeşe' },
      { id: 628, label: 'Sheeran' },
    ],
  },
  {
    id: 5,
    lang: 'ukr',
    label: 'Україна',
    currency: 'UAH',
    code: 'UA',
    showLang: true,
    cities: [
      { id: 1, label: 'Авдіївка' },
      { id: 2, label: 'Олександрія' },
      { id: 3, label: 'Олександрівськ' },
      { id: 4, label: 'Олешки' },
      { id: 5, label: 'Алмазна' },
      { id: 6, label: 'Алупка' },
      { id: 7, label: 'Алушта' },
      { id: 8, label: 'Алчевськ' },
      { id: 9, label: 'Амвросіївка' },
      { id: 10, label: 'Ананьєв' },
      { id: 11, label: 'Андрушівка' },
      { id: 12, label: 'Антрацит' },
      { id: 13, label: 'Апостолове' },
      { id: 14, label: 'Армянськ' },
      { id: 15, label: 'Арциз' },
      { id: 16, label: 'Охтирка' },
      { id: 17, label: 'Балаклія' },
      { id: 18, label: 'Балта' },
      { id: 19, label: 'Бар' },
      { id: 20, label: 'Баранівка' },
      { id: 21, label: 'Барвінкове' },
      { id: 22, label: 'Батурин' },
      { id: 23, label: 'Бахмач' },
      { id: 24, label: 'Бахмут' },
      { id: 25, label: 'Бахчисарай' },
      { id: 26, label: 'Баштанка' },
      { id: 27, label: 'Біла' },
      { id: 28, label: 'Білгород-Дністровський' },
      { id: 29, label: 'Белз' },
      { id: 30, label: 'Білицьке' },
      { id: 31, label: 'Білогірськ' },
      { id: 32, label: 'Білозерське' },
      { id: 33, label: 'Білопілля' },
      { id: 34, label: 'Біляївка' },
      { id: 35, label: 'Бердичів' },
      { id: 36, label: 'Бердянськ' },
      { id: 37, label: 'Берегово' },
      { id: 38, label: 'Бережани' },
      { id: 39, label: 'Березань' },
      { id: 40, label: 'Березівка' },
      { id: 41, label: 'Березне' },
      { id: 42, label: 'Берестечко' },
      { id: 43, label: 'Берислав' },
      { id: 44, label: 'Бершадь' },
      { id: 45, label: 'Благовіщенське' },
      { id: 46, label: 'Бобринець' },
      { id: 47, label: 'Бібрка' },
      { id: 48, label: 'Бобровиця' },
      { id: 49, label: 'Богодухів' },
      { id: 50, label: 'Богуслав' },
      { id: 51, label: 'Боково-Кришталеве' },
      { id: 52, label: 'Болград' },
      { id: 53, label: 'Болехів' },
      { id: 54, label: 'Борзна' },
      { id: 55, label: 'Борислав' },
      { id: 56, label: 'Бориспіль' },
      { id: 57, label: 'Борщів' },
      { id: 58, label: 'Боярка' },
      { id: 59, label: 'Бровари' },
      { id: 60, label: 'Броди' },
      { id: 61, label: 'Брянка' },
      { id: 62, label: 'Бунге' },
      { id: 63, label: 'Бурштин' },
      { id: 64, label: 'Буринь' },
      { id: 65, label: 'Буськ' },
      { id: 66, label: 'Буча' },
      { id: 67, label: 'Бучач' },
      { id: 68, label: 'Валки' },
      { id: 69, label: 'Вараш' },
      { id: 70, label: 'Василівка' },
      { id: 71, label: 'Васильків' },
      { id: 72, label: 'Ватутіне' },
      { id: 73, label: 'Вашківці' },
      { id: 74, label: 'Великі' },
      { id: 75, label: 'Верхньодніпровськ' },
      { id: 76, label: 'Верхівцеве' },
      { id: 77, label: 'Вижниця' },
      { id: 78, label: 'Вилкове' },
      { id: 79, label: 'Винники' },
      { id: 80, label: 'Вінниця' },
      { id: 81, label: 'Виноградов' },
      { id: 82, label: 'Вишневе' },
      { id: 83, label: 'Володимир-Волинський' },
      { id: 84, label: 'Вознесеновка' },
      { id: 85, label: 'Вознесенськ' },
      { id: 86, label: 'Волноваха' },
      { id: 87, label: 'Волочиськ' },
      { id: 88, label: 'Вовчанськ' },
      { id: 89, label: 'Вільногірськ' },
      { id: 90, label: 'Вільнянськ' },
      { id: 91, label: 'Ворожба' },
      { id: 92, label: 'Вишгород' },
      { id: 93, label: 'Гадяч' },
      { id: 94, label: 'Гайворон' },
      { id: 95, label: 'Гайсин' },
      { id: 96, label: 'Галич' },
      { id: 97, label: 'Генічеськ' },
      { id: 98, label: 'Герца' },
      { id: 99, label: 'Глиняни' },
      { id: 100, label: 'Глобине' },
      { id: 101, label: 'Глухів' },
      { id: 102, label: 'Гнівань' },
      { id: 103, label: 'Гола' },
      { id: 104, label: 'Голубівка' },
      { id: 105, label: 'Горішні' },
      { id: 106, label: 'Горлівка' },
      { id: 107, label: 'Гірник' },
      { id: 108, label: 'Городенка' },
      { id: 109, label: 'Городище' },
      { id: 110, label: 'Городня' },
      { id: 111, label: 'Городок' },
      { id: 112, label: 'Городок' },
      { id: 113, label: 'Горохів' },
      { id: 114, label: 'Гірське' },
      { id: 115, label: 'Гребінка' },
      { id: 116, label: 'Гуляйполе' },
      { id: 117, label: 'Дебальцеве' },
      { id: 118, label: 'Деражня' },
      { id: 119, label: 'Дергачі' },
      { id: 120, label: 'Джанкой' },
      { id: 121, label: 'Дніпро' },
      { id: 122, label: 'Дніпрорудне' },
      { id: 123, label: 'Добромиль' },
      { id: 124, label: 'Добропілля' },
      { id: 125, label: 'Докучаєвськ' },
      { id: 126, label: 'Должанський' },
      { id: 127, label: 'Долина' },
      { id: 128, label: 'Долинська' },
      { id: 129, label: 'Донецьк' },
      { id: 130, label: 'Дрогобич' },
      { id: 131, label: 'Дружба' },
      { id: 132, label: 'Дружківка' },
      { id: 133, label: 'Дубляни' },
      { id: 134, label: 'Дубно' },
      { id: 135, label: 'Дубровиця' },
      { id: 136, label: 'Дунаївці' },
      { id: 137, label: 'Євпаторія' },
      { id: 138, label: 'Єнакієве' },
      { id: 139, label: 'Жашків' },
      { id: 140, label: 'Жданівка' },
      { id: 141, label: 'Залізне' },
      { id: 142, label: 'Жовті' },
      { id: 143, label: 'Жидачів' },
      { id: 144, label: 'Житомир' },
      { id: 145, label: 'Жмеринка' },
      { id: 146, label: 'Жовква' },
      { id: 147, label: 'Заводське' },
      { id: 148, label: 'Заліщики' },
      { id: 149, label: 'Запоріжжя' },
      { id: 150, label: 'Заставна' },
      { id: 151, label: 'Збараж' },
      { id: 152, label: 'Зборів' },
      { id: 153, label: 'Звенигородка' },
      { id: 154, label: 'Здолбунів' },
      { id: 155, label: 'Зеленодольськ' },
      { id: 156, label: 'Зіньків' },
      { id: 157, label: 'Зимогір\'я' },
      { id: 158, label: 'Зміїв' },
      { id: 159, label: 'Знам\'янка' },
      { id: 160, label: 'Золоте' },
      { id: 161, label: 'Золотоноша' },
      { id: 162, label: 'Золочів' },
      { id: 163, label: 'Зоринськ' },
      { id: 164, label: 'Зугрес' },
      { id: 165, label: 'Івано-Франківськ' },
      { id: 166, label: 'Ізмаїл' },
      { id: 167, label: 'Ізюм' },
      { id: 168, label: 'Ізяслав' },
      { id: 169, label: 'Іллінці' },
      { id: 170, label: 'Іловайськ' },
      { id: 171, label: 'Інкерман' },
      { id: 172, label: 'Ірміно' },
      { id: 173, label: 'Ірпінь' },
      { id: 174, label: 'Іршава' },
      { id: 175, label: 'Ічня' },
      { id: 176, label: 'Кагарлик' },
      { id: 177, label: 'Кадиївка' },
      { id: 178, label: 'Козятин' },
      { id: 179, label: 'Калинівка' },
      { id: 180, label: 'Калуш' },
      { id: 181, label: 'Кальміуське' },
      { id: 182, label: 'Кам\'янець-Подільський' },
      { id: 183, label: 'Кам\'янка' },
      { id: 184, label: 'Кам\'янка-Бузька' },
      { id: 185, label: 'Кам\'янка-Дніпровська' },
      { id: 186, label: 'Кам\'янське' },
      { id: 187, label: 'Камінь-Каширський' },
      { id: 188, label: 'Канів' },
      { id: 189, label: 'Карлівка' },
      { id: 190, label: 'Каховка' },
      { id: 191, label: 'Керч' },
      { id: 192, label: 'Ківерці' },
      { id: 193, label: 'Київ' },
      { id: 194, label: 'Кілія' },
      { id: 195, label: 'Кипуче' },
      { id: 196, label: 'Кіцмань' },
      { id: 197, label: 'Кобеляки' },
      { id: 198, label: 'Ковель' },
      { id: 199, label: 'Кодима' },
      { id: 200, label: 'Коломия' },
      { id: 201, label: 'Комарно' },
      { id: 202, label: 'Конотоп' },
      { id: 203, label: 'Костянтинівка' },
      { id: 204, label: 'Копичинці' },
      { id: 205, label: 'Корець' },
      { id: 206, label: 'Коростень' },
      { id: 207, label: 'Коростишів' },
      { id: 208, label: 'Корсунь-Шевченківський' },
      { id: 209, label: 'Корюківка' },
      { id: 210, label: 'Косів' },
      { id: 211, label: 'Костопіль' },
      { id: 212, label: 'Краматорськ' },
      { id: 213, label: 'Красилів' },
      { id: 214, label: 'Красногорівка' },
      { id: 215, label: 'Красноград' },
      { id: 216, label: 'Красноперекопськ' },
      { id: 217, label: 'Кременець' },
      { id: 218, label: 'Кремінна' },
      { id: 219, label: 'Кременчук' },
      { id: 220, label: 'Крестовка' },
      { id: 221, label: 'Кривий' },
      { id: 222, label: 'Кролевець' },
      { id: 223, label: 'Кропивницький' },
      { id: 224, label: 'Куп\'янськ' },
      { id: 225, label: 'Курахово' },
      { id: 226, label: 'Ладижин' },
      { id: 227, label: 'Ланівці' },
      { id: 228, label: 'Лебедин' },
      { id: 229, label: 'Лиман' },
      { id: 230, label: 'Липовець' },
      { id: 231, label: 'Лисичанськ' },
      { id: 232, label: 'Лозова' },
      { id: 233, label: 'Лохвиця' },
      { id: 234, label: 'Лубни' },
      { id: 235, label: 'Луганськ' },
      { id: 236, label: 'Лутугине' },
      { id: 237, label: 'Луцьк' },
      { id: 238, label: 'Львів' },
      { id: 239, label: 'Любомль' },
      { id: 240, label: 'Люботин' },
      { id: 241, label: 'Макіївка' },
      { id: 242, label: 'Мала' },
      { id: 243, label: 'Малин' },
      { id: 244, label: 'Марганець' },
      { id: 245, label: 'Маріуполь' },
      { id: 246, label: 'Мар\'їнка' },
      { id: 247, label: 'Мелітополь' },
      { id: 248, label: 'Мена' },
      { id: 249, label: 'Мерефа' },
      { id: 250, label: 'Миргород' },
      { id: 251, label: 'Мірноград' },
      { id: 252, label: 'Миронівка' },
      { id: 253, label: 'Міусинськ' },
      { id: 254, label: 'Могилів-Подільський' },
      { id: 255, label: 'Молодогвардійськ' },
      { id: 256, label: 'Молочанськ' },
      { id: 257, label: 'Монастириська' },
      { id: 258, label: 'Монастирище' },
      { id: 259, label: 'Моршин' },
      { id: 260, label: 'Моспине' },
      { id: 261, label: 'Мостиська' },
      { id: 262, label: 'Мукачево' },
      { id: 263, label: 'Надвірна' },
      { id: 264, label: 'Ніжин' },
      { id: 265, label: 'Немирів' },
      { id: 266, label: 'Нетішин' },
      { id: 267, label: 'Миколаїв' },
      { id: 268, label: 'Миколаїв' },
      { id: 269, label: 'Миколаївка' },
      { id: 270, label: 'Нікополь' },
      { id: 271, label: 'Нова' },
      { id: 272, label: 'Нова' },
      { id: 273, label: 'Новгород-Сіверський' },
      { id: 274, label: 'Новоазовськ' },
      { id: 275, label: 'Нововолинськ' },
      { id: 276, label: 'Новоград-Волинський' },
      { id: 277, label: 'Новогродівка' },
      { id: 278, label: 'Новодністровськ' },
      { id: 279, label: 'Новодружеськ' },
      { id: 280, label: 'Новомиргород' },
      { id: 281, label: 'Новомосковськ' },
      { id: 282, label: 'Новоселиця' },
      { id: 283, label: 'Новоукраїнка' },
      { id: 284, label: 'Новояворівськ' },
      { id: 285, label: 'Новий' },
      { id: 286, label: 'Новий' },
      { id: 287, label: 'Новий' },
      { id: 288, label: 'Носівка' },
      { id: 289, label: 'Обухів' },
      { id: 290, label: 'Овруч' },
      { id: 291, label: 'Одеса' },
      { id: 292, label: 'Олевськ' },
      { id: 293, label: 'Горіхів' },
      { id: 294, label: 'Остер' },
      { id: 295, label: 'Острог' },
      { id: 296, label: 'Павлоград' },
      { id: 297, label: 'Первомайськ' },
      { id: 298, label: 'Первомайськ' },
      { id: 299, label: 'Первомайський' },
      { id: 300, label: 'Перевальськ' },
      { id: 301, label: 'Перемишляни' },
      { id: 302, label: 'Перечин' },
      { id: 303, label: 'Перещепине' },
      { id: 304, label: 'Переяслав' },
      { id: 305, label: 'Першотравенськ' },
      { id: 306, label: 'Петрово-Красносілля' },
      { id: 307, label: 'Південно' },
      { id: 308, label: 'Пирятин' },
      { id: 309, label: 'Погребище' },
      { id: 310, label: 'Підгайці' },
      { id: 311, label: 'Підгородне' },
      { id: 312, label: 'Подольск' },
      { id: 313, label: 'Покров' },
      { id: 314, label: 'Покровск' },
      { id: 315, label: 'Пологи' },
      { id: 316, label: 'Полонне' },
      { id: 317, label: 'Полтава' },
      { id: 318, label: 'Помічна' },
      { id: 319, label: 'Попасна' },
      { id: 320, label: 'Почаїв' },
      { id: 321, label: 'Привілля' },
      { id: 322, label: 'Прилуки' },
      { id: 323, label: 'Приморськ' },
      { id: 324, label: 'Прип\'ять' },
      { id: 325, label: 'Пустомити' },
      { id: 326, label: 'Путивль' },
      { id: 327, label: 'П\'ятихатки' },
      { id: 328, label: 'Рава-Руська' },
      { id: 329, label: 'Радехів' },
      { id: 330, label: 'Радивилів' },
      { id: 331, label: 'Радомишль' },
      { id: 332, label: 'Роздільна' },
      { id: 333, label: 'Рахів' },
      { id: 334, label: 'Рені' },
      { id: 335, label: 'Решетилівка' },
      { id: 336, label: 'Ржищів' },
      { id: 337, label: 'Ровеньки' },
      { id: 338, label: 'Рівне' },
      { id: 339, label: 'Рогатин' },
      { id: 340, label: 'Родинське' },
      { id: 341, label: 'Рожище' },
      { id: 342, label: 'Ромни' },
      { id: 343, label: 'Рубіжне' },
      { id: 344, label: 'Рудки' },
      { id: 345, label: 'Саки' },
      { id: 346, label: 'Самбір' },
      { id: 347, label: 'Сарни' },
      { id: 348, label: 'Свалява' },
      { id: 349, label: 'Сватове' },
      { id: 350, label: 'Світловодськ' },
      { id: 351, label: 'Світлодарськ' },
      { id: 352, label: 'Святогірськ' },
      { id: 353, label: 'Севастополь' },
      { id: 354, label: 'Сєвєродонецьк' },
      { id: 355, label: 'Сіверськ' },
      { id: 356, label: 'Селидове' },
      { id: 357, label: 'Семенівка' },
      { id: 358, label: 'Середина-Буда' },
      { id: 359, label: 'Сімферополь' },
      { id: 360, label: 'Синельникове' },
      { id: 361, label: 'Скадовськ' },
      { id: 362, label: 'Скалат' },
      { id: 363, label: 'Сквира' },
      { id: 364, label: 'Сколе' },
      { id: 365, label: 'Славута' },
      { id: 366, label: 'Славутич' },
      { id: 367, label: 'Слов\'янськ' },
      { id: 368, label: 'Сміла' },
      { id: 369, label: 'Сніжне' },
      { id: 370, label: 'Снігурівка' },
      { id: 371, label: 'Сновськ' },
      { id: 372, label: 'Снятин' },
      { id: 373, label: 'Сокаль' },
      { id: 374, label: 'Сокиряни' },
      { id: 375, label: 'Соледар' },
      { id: 376, label: 'Сорокино' },
      { id: 377, label: 'Соснівка' },
      { id: 378, label: 'Старобільськ' },
      { id: 379, label: 'Старокостянтинів' },
      { id: 380, label: 'Старий' },
      { id: 381, label: 'Старий' },
      { id: 382, label: 'Стебник' },
      { id: 383, label: 'Сторожинець' },
      { id: 384, label: 'Стрий' },
      { id: 385, label: 'Судак' },
      { id: 386, label: 'Судова' },
      { id: 387, label: 'Суми' },
      { id: 388, label: 'Суходільськ' },
      { id: 389, label: 'Щастя' },
      { id: 390, label: 'Таврійськ' },
      { id: 391, label: 'Тальне' },
      { id: 392, label: 'Тараща' },
      { id: 393, label: 'Татарбунари' },
      { id: 394, label: 'Теплодар' },
      { id: 395, label: 'Теребовля' },
      { id: 396, label: 'Тернівка' },
      { id: 397, label: 'Тернопіль' },
      { id: 398, label: 'Тетіїв' },
      { id: 399, label: 'Тлумач' },
      { id: 400, label: 'Токмак' },
      { id: 401, label: 'Торецькому' },
      { id: 402, label: 'Тростянець' },
      { id: 403, label: 'Трускавець' },
      { id: 404, label: 'Тульчин' },
      { id: 405, label: 'Турка' },
      { id: 406, label: 'Тисмениця' },
      { id: 407, label: 'Тячів' },
      { id: 408, label: 'Вуглегірськ' },
      { id: 409, label: 'Вугледар' },
      { id: 410, label: 'Угнів' },
      { id: 411, label: 'Ужгород' },
      { id: 412, label: 'Узин' },
      { id: 413, label: 'Українка' },
      { id: 414, label: 'Українськ' },
      { id: 415, label: 'Умань' },
      { id: 416, label: 'Устилуг' },
      { id: 417, label: 'Фастів' },
      { id: 418, label: 'Феодосія' },
      { id: 419, label: 'Харцизьк' },
      { id: 420, label: 'Харків' },
      { id: 421, label: 'Херсон' },
      { id: 422, label: 'Хмільник' },
      { id: 423, label: 'Хмельницький' },
      { id: 424, label: 'Ходорів' },
      { id: 425, label: 'Хорол' },
      { id: 426, label: 'Хоростків' },
      { id: 427, label: 'Хотин' },
      { id: 428, label: 'Христинівка' },
      { id: 429, label: 'Кришталевий' },
      { id: 430, label: 'Хуст' },
      { id: 431, label: 'Хирів' },
      { id: 432, label: 'Часів' },
      { id: 433, label: 'Червоноград' },
      { id: 434, label: 'Черкаси' },
      { id: 435, label: 'Чернігів' },
      { id: 436, label: 'Чорнобиль' },
      { id: 437, label: 'Чернівці' },
      { id: 438, label: 'Чорноморськ' },
      { id: 439, label: 'Чигирин' },
      { id: 440, label: 'Чистяково' },
      { id: 441, label: 'Чоп' },
      { id: 442, label: 'Чортків' },
      { id: 443, label: 'Чугуїв' },
      { id: 444, label: 'Чуднов' },
      { id: 445, label: 'Шаргород' },
      { id: 446, label: 'Шахтарськ' },
      { id: 447, label: 'Шепетівка' },
      { id: 448, label: 'Шостка' },
      { id: 449, label: 'Шпола' },
      { id: 450, label: 'Шумськ' },
      { id: 451, label: 'Щолкіно' },
      { id: 452, label: 'Енергодар' },
      { id: 453, label: 'Південне' },
      { id: 454, label: 'Южноукраїнськ' },
      { id: 455, label: 'Яворів' },
      { id: 456, label: 'Яготин' },
      { id: 457, label: 'Ялта' },
      { id: 458, label: 'Ямпіль' },
      { id: 459, label: 'Яремче' },
      { id: 460, label: 'Ясинувата' },
    ],
  },
  {
    id: 7,
    lang: 'deu',
    label: 'Deutschland',
    currency: 'EUR',
    code: 'DE',
    showLang: true,
    cities: [
      { id: 1, label: 'Berlin' },
      { id: 2, label: 'Hamburg' },
      { id: 3, label: 'München' },
      { id: 4, label: 'Köln' },
      { id: 5, label: 'Frankfurt am Main' },
      { id: 6, label: 'Stuttgart' },
      { id: 7, label: 'Düsseldorf' },
      { id: 8, label: 'Dortmund' },
      { id: 9, label: 'Essen' },
      { id: 10, label: 'Leipzig' },
      { id: 11, label: 'Bremen' },
      { id: 12, label: 'Dresden' },
      { id: 13, label: 'Hannover' },
      { id: 14, label: 'Nürnberg' },
      { id: 15, label: 'Duisburg' },
      { id: 16, label: 'Bochum' },
      { id: 17, label: 'Wuppertal' },
      { id: 18, label: 'Bielefeld' },
      { id: 19, label: 'Bonn' },
      { id: 20, label: 'Münster' },
      { id: 21, label: 'Karlsruhe' },
      { id: 22, label: 'Mannheim' },
      { id: 23, label: 'Augsburg' },
      { id: 24, label: 'Wiesbaden' },
      { id: 25, label: 'Gelsenkirchen' },
      { id: 26, label: 'Mönchengladbach' },
      { id: 27, label: 'Braunschweig' },
      { id: 28, label: 'Chemnitz' },
      { id: 29, label: 'Kiel' },
      { id: 30, label: 'Aachen' },
      { id: 31, label: 'Halle' },
      { id: 32, label: 'Magdeburg' },
      { id: 33, label: 'Freiburg im Breisgau' },
      { id: 34, label: 'Krefeld' },
      { id: 35, label: 'Lübeck' },
      { id: 36, label: 'Oberhausen' },
      { id: 37, label: 'Erfurt' },
      { id: 38, label: 'Mainz' },
      { id: 39, label: 'Sprießen' },
      { id: 40, label: 'Kassel' },
      { id: 41, label: 'Hagen' },
      { id: 42, label: 'Hamm' },
      { id: 43, label: 'Saarbrücken' },
      { id: 44, label: 'Mülheim an der Ruhr' },
      { id: 45, label: 'Potsdam' },
      { id: 46, label: 'Ludwigshafen am Rhein' },
      { id: 47, label: 'Oldenburg' },
      { id: 48, label: 'Leverkusen' },
      { id: 49, label: 'Osnabrück' },
      { id: 50, label: 'Solingen' },
      { id: 51, label: 'Heidelberg' },
      { id: 52, label: 'Herne' },
      { id: 53, label: 'Noyce' },
      { id: 54, label: 'Darmstadt' },
      { id: 55, label: 'Paderborn' },
      { id: 56, label: 'Regensburg' },
      { id: 57, label: 'Ingolstadt' },
      { id: 58, label: 'Würzburg' },
      { id: 59, label: 'Fürth' },
      { id: 60, label: 'Wolfsburg' },
      { id: 61, label: 'Offenbach' },
      { id: 62, label: 'Ulm' },
      { id: 63, label: 'Heilbronn' },
      { id: 64, label: 'Pforzheim' },
      { id: 65, label: 'Göttingen' },
      { id: 66, label: 'Bottrop' },
      { id: 67, label: 'Trier' },
      { id: 68, label: 'Recklinghausen' },
      { id: 69, label: 'Reutlingen' },
      { id: 70, label: 'Bremerhaven' },
      { id: 71, label: 'Koblenz' },
      { id: 72, label: 'Bergisch Gladbach' },
      { id: 73, label: 'Yen' },
      { id: 74, label: 'Remscheid' },
      { id: 75, label: 'Erlangen' },
      { id: 76, label: 'Moers' },
      { id: 77, label: 'Siegen' },
      { id: 78, label: 'Hildesheim' },
      { id: 79, label: 'Salzgitter' },
      { id: 80, label: 'Cottbus' },
      { id: 81, label: 'Kaiserslautern' },
      { id: 82, label: 'Gütersloh' },
      { id: 83, label: 'Schwerin' },
      { id: 84, label: 'Witten' },
      { id: 85, label: 'Hera' },
      { id: 86, label: 'Iserlohn' },
      { id: 87, label: 'Ludwigsburg' },
      { id: 88, label: 'Hanau' },
      { id: 89, label: 'Esslingen am Neckar' },
      { id: 90, label: 'Zwickau' },
      { id: 91, label: 'Düren' },
      { id: 92, label: 'Ratingen' },
      { id: 93, label: 'Tübingen' },
      { id: 94, label: 'Flensburg' },
      { id: 95, label: 'Lünen' },
      { id: 96, label: 'Villingen- Schwenningen' },
      { id: 97, label: 'Gießen' },
      { id: 98, label: 'Gaze' },
      { id: 99, label: 'Dessau' },
      { id: 100, label: 'Konstanz' },
      { id: 102, label: 'Minden' },
      { id: 103, label: 'Felbert' },
    ],
  },
  {
    id: 4,
    label: 'Россия',
    lang: 'rus',
    currency: 'RUB',
    code: 'RU',
    showLang: true,
    cities: [
      { id: 1, label: 'Абаза' },
      { id: 2, label: 'Абакан' },
      { id: 3, label: 'Абдулино' },
      { id: 4, label: 'Абинск' },
      { id: 5, label: 'Агидель' },
      { id: 6, label: 'Агрыз' },
      { id: 7, label: 'Адыгейск' },
      { id: 8, label: 'Азнакаево' },
      { id: 9, label: 'Азов' },
      { id: 10, label: 'Ак-Довурак' },
      { id: 11, label: 'Аксай' },
      { id: 12, label: 'Алагир' },
      { id: 13, label: 'Алапаевск' },
      { id: 14, label: 'Алатырь' },
      { id: 15, label: 'Алдан' },
      { id: 16, label: 'Алейск' },
      { id: 17, label: 'Александров' },
      { id: 18, label: 'Александровск' },
      { id: 19, label: 'Александровск-Сахалинский' },
      { id: 20, label: 'Алексеевка' },
      { id: 21, label: 'Алексин' },
      { id: 22, label: 'Алзамай' },
      { id: 23, label: 'АлупкаОспаривается' },
      { id: 24, label: 'АлуштаОспаривается' },
      { id: 25, label: 'Альметьевск' },
      { id: 26, label: 'Амурск' },
      { id: 27, label: 'Анадырь' },
      { id: 28, label: 'Анапа' },
      { id: 29, label: 'Ангарск' },
      { id: 30, label: 'Андреаполь' },
      { id: 31, label: 'Анжеро-Судженск' },
      { id: 32, label: 'Анива' },
      { id: 33, label: 'Апатиты' },
      { id: 34, label: 'Апрелевка' },
      { id: 35, label: 'Апшеронск' },
      { id: 36, label: 'Арамиль' },
      { id: 37, label: 'Аргун' },
      { id: 38, label: 'Ардатов' },
      { id: 39, label: 'Ардон' },
      { id: 40, label: 'Арзамас' },
      { id: 41, label: 'Аркадак' },
      { id: 42, label: 'Армавир' },
      { id: 43, label: 'АрмянскОспаривается' },
      { id: 44, label: 'Арсеньев' },
      { id: 45, label: 'Арск' },
      { id: 46, label: 'Артём' },
      { id: 47, label: 'Артёмовск' },
      { id: 48, label: 'Артёмовский' },
      { id: 49, label: 'Архангельск' },
      { id: 50, label: 'Асбест' },
      { id: 51, label: 'Асино' },
      { id: 52, label: 'Астрахань' },
      { id: 53, label: 'Аткарск' },
      { id: 54, label: 'Ахтубинск' },
      { id: 55, label: 'Ачинск' },
      { id: 56, label: 'Аша' },
      { id: 57, label: 'Бабаево' },
      { id: 58, label: 'Бабушкин' },
      { id: 59, label: 'Бавлы' },
      { id: 60, label: 'Багратионовск' },
      { id: 61, label: 'Байкальск' },
      { id: 62, label: 'Баймак' },
      { id: 63, label: 'Бакал' },
      { id: 64, label: 'Баксан' },
      { id: 65, label: 'Балабаново' },
      { id: 66, label: 'Балаково' },
      { id: 67, label: 'Балахна' },
      { id: 68, label: 'Балашиха' },
      { id: 69, label: 'Балашов' },
      { id: 70, label: 'Балей' },
      { id: 71, label: 'Балтийск' },
      { id: 72, label: 'Барабинск' },
      { id: 73, label: 'Барнаул' },
      { id: 74, label: 'Барыш' },
      { id: 75, label: 'Батайск' },
      { id: 76, label: 'БахчисарайОспаривается' },
      { id: 77, label: 'Бежецк' },
      { id: 78, label: 'Белая Калитва' },
      { id: 79, label: 'Белая Холуница' },
      { id: 80, label: 'Белгород' },
      { id: 81, label: 'Белебей' },
      { id: 82, label: 'Белёв' },
      { id: 83, label: 'Белинский' },
      { id: 84, label: 'Белово' },
      { id: 85, label: 'Белогорск' },
      { id: 86, label: 'БелогорскОспаривается' },
      { id: 87, label: 'Белозерск' },
      { id: 88, label: 'Белокуриха' },
      { id: 89, label: 'Беломорск' },
      { id: 90, label: 'Белоозёрский' },
      { id: 91, label: 'Белорецк' },
      { id: 92, label: 'Белореченск' },
      { id: 93, label: 'Белоусово' },
      { id: 94, label: 'Белоярский' },
      { id: 95, label: 'Белый' },
      { id: 96, label: 'Бердск' },
      { id: 97, label: 'Березники' },
      { id: 98, label: 'Берёзовский' },
      { id: 99, label: 'Берёзовский' },
      { id: 100, label: 'Беслан' },
      { id: 101, label: 'Бийск' },
      { id: 102, label: 'Бикин' },
      { id: 103, label: 'Билибино' },
      { id: 104, label: 'Биробиджан' },
      { id: 105, label: 'Бирск' },
      { id: 106, label: 'Бирюсинск' },
      { id: 107, label: 'Бирюч' },
      { id: 108, label: 'Благовещенск' },
      { id: 109, label: 'Благовещенск' },
      { id: 110, label: 'Благодарный' },
      { id: 111, label: 'Бобров' },
      { id: 112, label: 'Богданович' },
      { id: 113, label: 'Богородицк' },
      { id: 114, label: 'Богородск' },
      { id: 115, label: 'Боготол' },
      { id: 116, label: 'Богучар' },
      { id: 117, label: 'Бодайбо' },
      { id: 118, label: 'Бокситогорск' },
      { id: 119, label: 'Болгар' },
      { id: 120, label: 'Бологое' },
      { id: 121, label: 'Болотное' },
      { id: 122, label: 'Болохово' },
      { id: 123, label: 'Болхов' },
      { id: 124, label: 'Большой Камень' },
      { id: 125, label: 'Бор' },
      { id: 126, label: 'Борзя' },
      { id: 127, label: 'Борисоглебск' },
      { id: 128, label: 'Боровичи' },
      { id: 129, label: 'Боровск' },
      { id: 130, label: 'Бородино' },
      { id: 131, label: 'Братск' },
      { id: 132, label: 'Бронницы' },
      { id: 133, label: 'Брянск' },
      { id: 134, label: 'Бугульма' },
      { id: 135, label: 'Бугуруслан' },
      { id: 136, label: 'Будённовск' },
      { id: 137, label: 'Бузулук' },
      { id: 138, label: 'Буинск' },
      { id: 139, label: 'Буй' },
      { id: 140, label: 'Буйнакск' },
      { id: 141, label: 'Бутурлиновка' },
      { id: 142, label: 'Валдай' },
      { id: 143, label: 'Валуйки' },
      { id: 144, label: 'Велиж' },
      { id: 145, label: 'Великие Луки' },
      { id: 146, label: 'Великий Новгород' },
      { id: 147, label: 'Великий Устюг' },
      { id: 148, label: 'Вельск' },
      { id: 149, label: 'Венёв' },
      { id: 150, label: 'Верещагино' },
      { id: 151, label: 'Верея' },
      { id: 152, label: 'Верхнеуральск' },
      { id: 153, label: 'Верхний Тагил' },
      { id: 154, label: 'Верхний Уфалей' },
      { id: 155, label: 'Верхняя Пышма' },
      { id: 156, label: 'Верхняя Салда' },
      { id: 157, label: 'Верхняя Тура' },
      { id: 158, label: 'Верхотурье' },
      { id: 159, label: 'Верхоянск' },
      { id: 160, label: 'Весьегонск' },
      { id: 161, label: 'Ветлуга' },
      { id: 162, label: 'Видное' },
      { id: 163, label: 'Вилюйск' },
      { id: 164, label: 'Вилючинск' },
      { id: 165, label: 'Вихоревка' },
      { id: 166, label: 'Вичуга' },
      { id: 167, label: 'Владивосток' },
      { id: 168, label: 'Владикавказ' },
      { id: 169, label: 'Владимир' },
      { id: 170, label: 'Волгоград' },
      { id: 171, label: 'Волгодонск' },
      { id: 172, label: 'Волгореченск' },
      { id: 173, label: 'Волжск' },
      { id: 174, label: 'Волжский' },
      { id: 175, label: 'Вологда' },
      { id: 176, label: 'Володарск' },
      { id: 177, label: 'Волоколамск' },
      { id: 178, label: 'Волосово' },
      { id: 179, label: 'Волхов' },
      { id: 180, label: 'Волчанск' },
      { id: 181, label: 'Вольск' },
      { id: 182, label: 'Воркута' },
      { id: 183, label: 'Воронеж' },
      { id: 184, label: 'Ворсма' },
      { id: 185, label: 'Воскресенск' },
      { id: 186, label: 'Воткинск' },
      { id: 187, label: 'Всеволожск' },
      { id: 188, label: 'Вуктыл' },
      { id: 189, label: 'Выборг' },
      { id: 190, label: 'Выкса' },
      { id: 191, label: 'Высоковск' },
      { id: 192, label: 'Высоцк' },
      { id: 193, label: 'Вытегра' },
      { id: 194, label: 'Вышний Волочёк' },
      { id: 195, label: 'Вяземский' },
      { id: 196, label: 'Вязники' },
      { id: 197, label: 'Вязьма' },
      { id: 198, label: 'Вятские Поляны' },
      { id: 199, label: 'Гаврилов Посад' },
      { id: 200, label: 'Гаврилов-Ям' },
      { id: 201, label: 'Гагарин' },
      { id: 202, label: 'Гаджиево' },
      { id: 203, label: 'Гай' },
      { id: 204, label: 'Галич' },
      { id: 205, label: 'Гатчина' },
      { id: 206, label: 'Гвардейск' },
      { id: 207, label: 'Гдов' },
      { id: 208, label: 'Геленджик' },
      { id: 209, label: 'Георгиевск' },
      { id: 210, label: 'Глазов' },
      { id: 211, label: 'Голицыно' },
      { id: 212, label: 'Горбатов' },
      { id: 213, label: 'Горно-Алтайск' },
      { id: 214, label: 'Горнозаводск' },
      { id: 215, label: 'Горняк' },
      { id: 216, label: 'Городец' },
      { id: 217, label: 'Городище' },
      { id: 218, label: 'Городовиковск' },
      { id: 219, label: 'Гороховец' },
      { id: 220, label: 'Горячий Ключ' },
      { id: 221, label: 'Грайворон' },
      { id: 222, label: 'Гремячинск' },
      { id: 223, label: 'Грозный' },
      { id: 224, label: 'Грязи' },
      { id: 225, label: 'Грязовец' },
      { id: 226, label: 'Губаха' },
      { id: 227, label: 'Губкин' },
      { id: 228, label: 'Губкинский' },
      { id: 229, label: 'Гудермес' },
      { id: 230, label: 'Гуково' },
      { id: 231, label: 'Гулькевичи' },
      { id: 232, label: 'Гурьевск' },
      { id: 233, label: 'Гурьевск' },
      { id: 234, label: 'Гусев' },
      { id: 235, label: 'Гусиноозёрск' },
      { id: 236, label: 'Гусь-Хрустальный' },
      { id: 237, label: 'Давлеканово' },
      { id: 238, label: 'Дагестанские Огни' },
      { id: 239, label: 'Далматово' },
      { id: 240, label: 'Дальнегорск' },
      { id: 241, label: 'Дальнереченск' },
      { id: 242, label: 'Данилов' },
      { id: 243, label: 'Данков' },
      { id: 244, label: 'Дегтярск' },
      { id: 245, label: 'Дедовск' },
      { id: 246, label: 'Демидов' },
      { id: 247, label: 'Дербент' },
      { id: 248, label: 'Десногорск' },
      { id: 249, label: 'ДжанкойОспаривается' },
      { id: 250, label: 'Дзержинск' },
      { id: 251, label: 'Дзержинский' },
      { id: 252, label: 'Дивногорск' },
      { id: 253, label: 'Дигора' },
      { id: 254, label: 'Димитровград' },
      { id: 255, label: 'Дмитриев' },
      { id: 256, label: 'Дмитров' },
      { id: 257, label: 'Дмитровск' },
      { id: 258, label: 'Дно' },
      { id: 259, label: 'Добрянка' },
      { id: 260, label: 'Долгопрудный' },
      { id: 261, label: 'Долинск' },
      { id: 262, label: 'Домодедово' },
      { id: 263, label: 'Донецк' },
      { id: 264, label: 'Донской' },
      { id: 265, label: 'Дорогобуж' },
      { id: 266, label: 'Дрезна' },
      { id: 267, label: 'Дубна' },
      { id: 268, label: 'Дубовка' },
      { id: 269, label: 'Дудинка' },
      { id: 270, label: 'Духовщина' },
      { id: 271, label: 'Дюртюли' },
      { id: 272, label: 'Дятьково' },
      { id: 273, label: 'ЕвпаторияОспаривается' },
      { id: 274, label: 'Егорьевск' },
      { id: 275, label: 'Ейск' },
      { id: 276, label: 'Екатеринбург' },
      { id: 277, label: 'Елабуга' },
      { id: 278, label: 'Елец' },
      { id: 279, label: 'Елизово' },
      { id: 280, label: 'Ельня' },
      { id: 281, label: 'Еманжелинск' },
      { id: 282, label: 'Емва' },
      { id: 283, label: 'Енисейск' },
      { id: 284, label: 'Ермолино' },
      { id: 285, label: 'Ершов' },
      { id: 286, label: 'Ессентуки' },
      { id: 287, label: 'Ефремов' },
      { id: 288, label: 'Железноводск' },
      { id: 289, label: 'Железногорск' },
      { id: 290, label: 'Железногорск' },
      { id: 291, label: 'Железногорск-Илимский' },
      { id: 292, label: 'Жердевка' },
      { id: 293, label: 'Жигулёвск' },
      { id: 294, label: 'Жиздра' },
      { id: 295, label: 'Жирновск' },
      { id: 296, label: 'Жуков' },
      { id: 297, label: 'Жуковка' },
      { id: 298, label: 'Жуковский' },
      { id: 299, label: 'Завитинск' },
      { id: 300, label: 'Заводоуковск' },
      { id: 301, label: 'Заволжск' },
      { id: 302, label: 'Заволжье' },
      { id: 303, label: 'Задонск' },
      { id: 304, label: 'Заинск' },
      { id: 305, label: 'Закаменск' },
      { id: 306, label: 'Заозёрный' },
      { id: 307, label: 'Заозёрск' },
      { id: 308, label: 'Западная Двина' },
      { id: 309, label: 'Заполярный' },
      { id: 310, label: 'Зарайск' },
      { id: 311, label: 'Заречный' },
      { id: 312, label: 'Заречный' },
      { id: 313, label: 'Заринск' },
      { id: 314, label: 'Звенигово' },
      { id: 315, label: 'Звенигород' },
      { id: 316, label: 'Зверево' },
      { id: 317, label: 'Зеленогорск' },
      { id: 318, label: 'Зеленоградск' },
      { id: 319, label: 'Зеленодольск' },
      { id: 320, label: 'Зеленокумск' },
      { id: 321, label: 'Зерноград' },
      { id: 322, label: 'Зея' },
      { id: 323, label: 'Зима' },
      { id: 324, label: 'Златоуст' },
      { id: 325, label: 'Злынка' },
      { id: 326, label: 'Змеиногорск' },
      { id: 327, label: 'Знаменск' },
      { id: 328, label: 'Зубцов' },
      { id: 329, label: 'Зуевка' },
      { id: 330, label: 'Ивангород' },
      { id: 331, label: 'Иваново' },
      { id: 332, label: 'Ивантеевка' },
      { id: 333, label: 'Ивдель' },
      { id: 334, label: 'Игарка' },
      { id: 335, label: 'Ижевск' },
      { id: 336, label: 'Избербаш' },
      { id: 337, label: 'Изобильный' },
      { id: 338, label: 'Иланский' },
      { id: 339, label: 'Инза' },
      { id: 340, label: 'Иннополис' },
      { id: 341, label: 'Инсар' },
      { id: 342, label: 'Инта' },
      { id: 343, label: 'Ипатово' },
      { id: 344, label: 'Ирбит' },
      { id: 345, label: 'Иркутск' },
      { id: 346, label: 'Исилькуль' },
      { id: 347, label: 'Искитим' },
      { id: 348, label: 'Истра' },
      { id: 349, label: 'Ишим' },
      { id: 350, label: 'Ишимбай' },
      { id: 351, label: 'Йошкар-Ола' },
      { id: 352, label: 'Кадников' },
      { id: 353, label: 'Казань' },
      { id: 354, label: 'Калач' },
      { id: 355, label: 'Калач-на-Дону' },
      { id: 356, label: 'Калачинск' },
      { id: 357, label: 'Калининград' },
      { id: 358, label: 'Калининск' },
      { id: 359, label: 'Калтан' },
      { id: 360, label: 'Калуга' },
      { id: 361, label: 'Калязин' },
      { id: 362, label: 'Камбарка' },
      { id: 363, label: 'Каменка' },
      { id: 364, label: 'Каменногорск' },
      { id: 365, label: 'Каменск-Уральский' },
      { id: 366, label: 'Каменск-Шахтинский' },
      { id: 367, label: 'Камень-на-Оби' },
      { id: 368, label: 'Камешково' },
      { id: 369, label: 'Камызяк' },
      { id: 370, label: 'Камышин' },
      { id: 371, label: 'Камышлов' },
      { id: 372, label: 'Канаш' },
      { id: 373, label: 'Кандалакша' },
      { id: 374, label: 'Канск' },
      { id: 375, label: 'Карабаново' },
      { id: 376, label: 'Карабаш' },
      { id: 377, label: 'Карабулак' },
      { id: 378, label: 'Карасук' },
      { id: 379, label: 'Карачаевск' },
      { id: 380, label: 'Карачев' },
      { id: 381, label: 'Каргат' },
      { id: 382, label: 'Каргополь' },
      { id: 383, label: 'Карпинск' },
      { id: 384, label: 'Карталы' },
      { id: 385, label: 'Касимов' },
      { id: 386, label: 'Касли' },
      { id: 387, label: 'Каспийск' },
      { id: 388, label: 'Катав-Ивановск' },
      { id: 389, label: 'Катайск' },
      { id: 390, label: 'Качканар' },
      { id: 391, label: 'Кашин' },
      { id: 392, label: 'Кашира' },
      { id: 393, label: 'Кедровый' },
      { id: 394, label: 'Кемерово' },
      { id: 395, label: 'Кемь' },
      { id: 396, label: 'КерчьОспаривается' },
      { id: 397, label: 'Кизел' },
      { id: 398, label: 'Кизилюрт' },
      { id: 399, label: 'Кизляр' },
      { id: 400, label: 'Кимовск' },
      { id: 401, label: 'Кимры' },
      { id: 402, label: 'Кингисепп' },
      { id: 403, label: 'Кинель' },
      { id: 404, label: 'Кинешма' },
      { id: 405, label: 'Киреевск' },
      { id: 406, label: 'Киренск' },
      { id: 407, label: 'Киржач' },
      { id: 408, label: 'Кириллов' },
      { id: 409, label: 'Кириши' },
      { id: 410, label: 'Киров' },
      { id: 411, label: 'Киров' },
      { id: 412, label: 'Кировград' },
      { id: 413, label: 'Кирово-Чепецк' },
      { id: 414, label: 'Кировск' },
      { id: 415, label: 'Кировск' },
      { id: 416, label: 'Кирс' },
      { id: 417, label: 'Кирсанов' },
      { id: 418, label: 'Киселёвск' },
      { id: 419, label: 'Кисловодск' },
      { id: 420, label: 'Клин' },
      { id: 421, label: 'Клинцы' },
      { id: 422, label: 'Княгинино' },
      { id: 423, label: 'Ковдор' },
      { id: 424, label: 'Ковров' },
      { id: 425, label: 'Ковылкино' },
      { id: 426, label: 'Когалым' },
      { id: 427, label: 'Кодинск' },
      { id: 428, label: 'Козельск' },
      { id: 429, label: 'Козловка' },
      { id: 430, label: 'Козьмодемьянск' },
      { id: 431, label: 'Кола' },
      { id: 432, label: 'Кологрив' },
      { id: 433, label: 'Коломна' },
      { id: 434, label: 'Колпашево' },
      { id: 435, label: 'Кольчугино' },
      { id: 436, label: 'Коммунар' },
      { id: 437, label: 'Комсомольск' },
      { id: 438, label: 'Комсомольск-на-Амуре' },
      { id: 439, label: 'Конаково' },
      { id: 440, label: 'Кондопога' },
      { id: 441, label: 'Кондрово' },
      { id: 442, label: 'Константиновск' },
      { id: 443, label: 'Копейск' },
      { id: 444, label: 'Кораблино' },
      { id: 445, label: 'Кореновск' },
      { id: 446, label: 'Коркино' },
      { id: 447, label: 'Королёв' },
      { id: 448, label: 'Короча' },
      { id: 449, label: 'Корсаков' },
      { id: 450, label: 'Коряжма' },
      { id: 451, label: 'Костерёво' },
      { id: 452, label: 'Костомукша' },
      { id: 453, label: 'Кострома' },
      { id: 454, label: 'Котельники' },
      { id: 455, label: 'Котельниково' },
      { id: 456, label: 'Котельнич' },
      { id: 457, label: 'Котлас' },
      { id: 458, label: 'Котово' },
      { id: 459, label: 'Котовск' },
      { id: 460, label: 'Кохма' },
      { id: 461, label: 'Красавино' },
      { id: 462, label: 'Красноармейск' },
      { id: 463, label: 'Красноармейск' },
      { id: 464, label: 'Красновишерск' },
      { id: 465, label: 'Красногорск' },
      { id: 466, label: 'Краснодар' },
      { id: 467, label: 'Краснозаводск' },
      { id: 468, label: 'Краснознаменск' },
      { id: 469, label: 'Краснознаменск' },
      { id: 470, label: 'Краснокаменск' },
      { id: 471, label: 'Краснокамск' },
      { id: 472, label: 'КрасноперекопскОспаривается' },
      { id: 473, label: 'Краснослободск' },
      { id: 474, label: 'Краснослободск' },
      { id: 475, label: 'Краснотурьинск' },
      { id: 476, label: 'Красноуральск' },
      { id: 477, label: 'Красноуфимск' },
      { id: 478, label: 'Красноярск' },
      { id: 479, label: 'Красный Кут' },
      { id: 480, label: 'Красный Сулин' },
      { id: 481, label: 'Красный Холм' },
      { id: 482, label: 'Кремёнки' },
      { id: 483, label: 'Кропоткин' },
      { id: 484, label: 'Крымск' },
      { id: 485, label: 'Кстово' },
      { id: 486, label: 'Кубинка' },
      { id: 487, label: 'Кувандык' },
      { id: 488, label: 'Кувшиново' },
      { id: 489, label: 'Кудрово' },
      { id: 490, label: 'Кудымкар' },
      { id: 491, label: 'Кузнецк' },
      { id: 492, label: 'Куйбышев' },
      { id: 493, label: 'Кукмор' },
      { id: 494, label: 'Кулебаки' },
      { id: 495, label: 'Кумертау' },
      { id: 496, label: 'Кунгур' },
      { id: 497, label: 'Купино' },
      { id: 498, label: 'Курган' },
      { id: 499, label: 'Курганинск' },
      { id: 500, label: 'Курильск' },
      { id: 501, label: 'Курлово' },
      { id: 502, label: 'Куровское' },
      { id: 503, label: 'Курск' },
      { id: 504, label: 'Куртамыш' },
      { id: 505, label: 'Курчалой' },
      { id: 506, label: 'Курчатов' },
      { id: 507, label: 'Куса' },
      { id: 508, label: 'Кушва' },
      { id: 509, label: 'Кызыл' },
      { id: 510, label: 'Кыштым' },
      { id: 511, label: 'Кяхта' },
      { id: 512, label: 'Лабинск' },
      { id: 513, label: 'Лабытнанги' },
      { id: 514, label: 'Лагань' },
      { id: 515, label: 'Ладушкин' },
      { id: 516, label: 'Лаишево' },
      { id: 517, label: 'Лакинск' },
      { id: 518, label: 'Лангепас' },
      { id: 519, label: 'Лахденпохья' },
      { id: 520, label: 'Лебедянь' },
      { id: 521, label: 'Лениногорск' },
      { id: 522, label: 'Ленинск' },
      { id: 523, label: 'Ленинск-Кузнецкий' },
      { id: 524, label: 'Ленск' },
      { id: 525, label: 'Лермонтов' },
      { id: 526, label: 'Лесной' },
      { id: 527, label: 'Лесозаводск' },
      { id: 528, label: 'Лесосибирск' },
      { id: 529, label: 'Ливны' },
      { id: 530, label: 'Ликино-Дулёво' },
      { id: 531, label: 'Липецк' },
      { id: 532, label: 'Липки' },
      { id: 533, label: 'Лиски' },
      { id: 534, label: 'Лихославль' },
      { id: 535, label: 'Лобня' },
      { id: 536, label: 'Лодейное Поле' },
      { id: 537, label: 'Лосино-Петровский' },
      { id: 538, label: 'Луга' },
      { id: 539, label: 'Луза' },
      { id: 540, label: 'Лукоянов' },
      { id: 541, label: 'Луховицы' },
      { id: 542, label: 'Лысково' },
      { id: 543, label: 'Лысьва' },
      { id: 544, label: 'Лыткарино' },
      { id: 545, label: 'Льгов' },
      { id: 546, label: 'Любань' },
      { id: 547, label: 'Люберцы' },
      { id: 548, label: 'Любим' },
      { id: 549, label: 'Людиново' },
      { id: 550, label: 'Лянтор' },
      { id: 551, label: 'Магадан' },
      { id: 552, label: 'Магас' },
      { id: 553, label: 'Магнитогорск' },
      { id: 554, label: 'Майкоп' },
      { id: 555, label: 'Майский' },
      { id: 556, label: 'Макаров' },
      { id: 557, label: 'Макарьев' },
      { id: 558, label: 'Макушино' },
      { id: 559, label: 'Малая Вишера' },
      { id: 560, label: 'Малгобек' },
      { id: 561, label: 'Малмыж' },
      { id: 562, label: 'Малоархангельск' },
      { id: 563, label: 'Малоярославец' },
      { id: 564, label: 'Мамадыш' },
      { id: 565, label: 'Мамоново' },
      { id: 566, label: 'Мантурово' },
      { id: 567, label: 'Мариинск' },
      { id: 568, label: 'Мариинский Посад' },
      { id: 569, label: 'Маркс' },
      { id: 570, label: 'Махачкала' },
      { id: 571, label: 'Мглин' },
      { id: 572, label: 'Мегион' },
      { id: 573, label: 'Медвежьегорск' },
      { id: 574, label: 'Медногорск' },
      { id: 575, label: 'Медынь' },
      { id: 576, label: 'Межгорье' },
      { id: 577, label: 'Междуреченск' },
      { id: 578, label: 'Мезень' },
      { id: 579, label: 'Меленки' },
      { id: 580, label: 'Мелеуз' },
      { id: 581, label: 'Менделеевск' },
      { id: 582, label: 'Мензелинск' },
      { id: 583, label: 'Мещовск' },
      { id: 584, label: 'Миасс' },
      { id: 585, label: 'Микунь' },
      { id: 586, label: 'Миллерово' },
      { id: 587, label: 'Минеральные Воды' },
      { id: 588, label: 'Минусинск' },
      { id: 589, label: 'Миньяр' },
      { id: 590, label: 'Мирный' },
      { id: 591, label: 'Мирный' },
      { id: 592, label: 'Михайлов' },
      { id: 593, label: 'Михайловка' },
      { id: 594, label: 'Михайловск' },
      { id: 595, label: 'Михайловск' },
      { id: 596, label: 'Мичуринск' },
      { id: 597, label: 'Могоча' },
      { id: 598, label: 'Можайск' },
      { id: 599, label: 'Можга' },
      { id: 600, label: 'Моздок' },
      { id: 601, label: 'Мончегорск' },
      { id: 602, label: 'Морозовск' },
      { id: 603, label: 'Моршанск' },
      { id: 604, label: 'Мосальск' },
      { id: 605, label: 'Москва' },
      { id: 606, label: 'Муравленко' },
      { id: 607, label: 'Мураши' },
      { id: 608, label: 'Мурино' },
      { id: 609, label: 'Мурманск' },
      { id: 610, label: 'Муром' },
      { id: 611, label: 'Мценск' },
      { id: 612, label: 'Мыски' },
      { id: 613, label: 'Мытищи' },
      { id: 614, label: 'Мышкин' },
      { id: 615, label: 'Набережные Челны' },
      { id: 616, label: 'Навашино' },
      { id: 617, label: 'Наволоки' },
      { id: 618, label: 'Надым' },
      { id: 619, label: 'Назарово' },
      { id: 620, label: 'Назрань' },
      { id: 621, label: 'Называевск' },
      { id: 622, label: 'Нальчик' },
      { id: 623, label: 'Нариманов' },
      { id: 624, label: 'Наро-Фоминск' },
      { id: 625, label: 'Нарткала' },
      { id: 626, label: 'Нарьян-Мар' },
      { id: 627, label: 'Находка' },
      { id: 628, label: 'Невель' },
      { id: 629, label: 'Невельск' },
      { id: 630, label: 'Невинномысск' },
      { id: 631, label: 'Невьянск' },
      { id: 632, label: 'Нелидово' },
      { id: 633, label: 'Неман' },
      { id: 634, label: 'Нерехта' },
      { id: 635, label: 'Нерчинск' },
      { id: 636, label: 'Нерюнгри' },
      { id: 637, label: 'Нестеров' },
      { id: 638, label: 'Нефтегорск' },
      { id: 639, label: 'Нефтекамск' },
      { id: 640, label: 'Нефтекумск' },
      { id: 641, label: 'Нефтеюганск' },
      { id: 642, label: 'Нея' },
      { id: 643, label: 'Нижневартовск' },
      { id: 644, label: 'Нижнекамск' },
      { id: 645, label: 'Нижнеудинск' },
      { id: 646, label: 'Нижние Серги' },
      { id: 647, label: 'Нижний Ломов' },
      { id: 648, label: 'Нижний Новгород' },
      { id: 649, label: 'Нижний Тагил' },
      { id: 650, label: 'Нижняя Салда' },
      { id: 651, label: 'Нижняя Тура' },
      { id: 652, label: 'Николаевск' },
      { id: 653, label: 'Николаевск-на-Амуре' },
      { id: 654, label: 'Никольск' },
      { id: 655, label: 'Никольск' },
      { id: 656, label: 'Никольское' },
      { id: 657, label: 'Новая Ладога' },
      { id: 658, label: 'Новая Ляля' },
      { id: 659, label: 'Новоалександровск' },
      { id: 660, label: 'Новоалтайск' },
      { id: 661, label: 'Новоаннинский' },
      { id: 662, label: 'Нововоронеж' },
      { id: 663, label: 'Новодвинск' },
      { id: 664, label: 'Новозыбков' },
      { id: 665, label: 'Новокубанск' },
      { id: 666, label: 'Новокузнецк' },
      { id: 667, label: 'Новокуйбышевск' },
      { id: 668, label: 'Новомичуринск' },
      { id: 669, label: 'Новомосковск' },
      { id: 670, label: 'Новопавловск' },
      { id: 671, label: 'Новоржев' },
      { id: 672, label: 'Новороссийск' },
      { id: 673, label: 'Новосибирск' },
      { id: 674, label: 'Новосиль' },
      { id: 675, label: 'Новосокольники' },
      { id: 676, label: 'Новотроицк' },
      { id: 677, label: 'Новоузенск' },
      { id: 678, label: 'Новоульяновск' },
      { id: 679, label: 'Новоуральск' },
      { id: 680, label: 'Новохопёрск' },
      { id: 681, label: 'Новочебоксарск' },
      { id: 682, label: 'Новочеркасск' },
      { id: 683, label: 'Новошахтинск' },
      { id: 684, label: 'Новый Оскол' },
      { id: 685, label: 'Новый Уренгой' },
      { id: 686, label: 'Ногинск' },
      { id: 687, label: 'Нолинск' },
      { id: 688, label: 'Норильск' },
      { id: 689, label: 'Ноябрьск' },
      { id: 690, label: 'Нурлат' },
      { id: 691, label: 'Нытва' },
      { id: 692, label: 'Нюрба' },
      { id: 693, label: 'Нягань' },
      { id: 694, label: 'Нязепетровск' },
      { id: 695, label: 'Няндома' },
      { id: 696, label: 'Облучье' },
      { id: 697, label: 'Обнинск' },
      { id: 698, label: 'Обоянь' },
      { id: 699, label: 'Обь' },
      { id: 700, label: 'Одинцово' },
      { id: 701, label: 'Озёрск' },
      { id: 702, label: 'Озёрск' },
      { id: 703, label: 'Озёры' },
      { id: 704, label: 'Октябрьск' },
      { id: 705, label: 'Октябрьский' },
      { id: 706, label: 'Окуловка' },
      { id: 707, label: 'Олёкминск' },
      { id: 708, label: 'Оленегорск' },
      { id: 709, label: 'Олонец' },
      { id: 710, label: 'Омск' },
      { id: 711, label: 'Омутнинск' },
      { id: 712, label: 'Онега' },
      { id: 713, label: 'Опочка' },
      { id: 714, label: 'Орёл' },
      { id: 715, label: 'Оренбург' },
      { id: 716, label: 'Орехово-Зуево' },
      { id: 717, label: 'Орлов' },
      { id: 718, label: 'Орск' },
      { id: 719, label: 'Оса' },
      { id: 720, label: 'Осинники' },
      { id: 721, label: 'Осташков' },
      { id: 722, label: 'Остров' },
      { id: 723, label: 'Островной' },
      { id: 724, label: 'Острогожск' },
      { id: 725, label: 'Отрадное' },
      { id: 726, label: 'Отрадный' },
      { id: 727, label: 'Оха' },
      { id: 728, label: 'Оханск' },
      { id: 729, label: 'Очёр' },
      { id: 730, label: 'Павлово' },
      { id: 731, label: 'Павловск' },
      { id: 732, label: 'Павловский Посад' },
      { id: 733, label: 'Палласовка' },
      { id: 734, label: 'Партизанск' },
      { id: 735, label: 'Певек' },
      { id: 736, label: 'Пенза' },
      { id: 737, label: 'Первомайск' },
      { id: 738, label: 'Первоуральск' },
      { id: 739, label: 'Перевоз' },
      { id: 740, label: 'Пересвет' },
      { id: 741, label: 'Переславль-Залесский' },
      { id: 742, label: 'Пермь' },
      { id: 743, label: 'Пестово' },
      { id: 744, label: 'Петров Вал' },
      { id: 745, label: 'Петровск' },
      { id: 746, label: 'Петровск-Забайкальский' },
      { id: 747, label: 'Петрозаводск' },
      { id: 748, label: 'Петропавловск-Камчатский' },
      { id: 749, label: 'Петухово' },
      { id: 750, label: 'Петушки' },
      { id: 751, label: 'Печора' },
      { id: 752, label: 'Печоры' },
      { id: 753, label: 'Пикалёво' },
      { id: 754, label: 'Пионерский' },
      { id: 755, label: 'Питкяранта' },
      { id: 756, label: 'Плавск' },
      { id: 757, label: 'Пласт' },
      { id: 758, label: 'Плёс' },
      { id: 759, label: 'Поворино' },
      { id: 760, label: 'Подольск' },
      { id: 761, label: 'Подпорожье' },
      { id: 762, label: 'Покачи' },
      { id: 763, label: 'Покров' },
      { id: 764, label: 'Покровск' },
      { id: 765, label: 'Полевской' },
      { id: 766, label: 'Полесск' },
      { id: 767, label: 'Полысаево' },
      { id: 768, label: 'Полярные Зори' },
      { id: 769, label: 'Полярный' },
      { id: 770, label: 'Поронайск' },
      { id: 771, label: 'Порхов' },
      { id: 772, label: 'Похвистнево' },
      { id: 773, label: 'Почеп' },
      { id: 774, label: 'Починок' },
      { id: 775, label: 'Пошехонье' },
      { id: 776, label: 'Правдинск' },
      { id: 777, label: 'Приволжск' },
      { id: 778, label: 'Приморск' },
      { id: 779, label: 'Приморск' },
      { id: 780, label: 'Приморско-Ахтарск' },
      { id: 781, label: 'Приозерск' },
      { id: 782, label: 'Прокопьевск' },
      { id: 783, label: 'Пролетарск' },
      { id: 784, label: 'Протвино' },
      { id: 785, label: 'Прохладный' },
      { id: 786, label: 'Псков' },
      { id: 787, label: 'Пугачёв' },
      { id: 788, label: 'Пудож' },
      { id: 789, label: 'Пустошка' },
      { id: 790, label: 'Пучеж' },
      { id: 791, label: 'Пушкино' },
      { id: 792, label: 'Пущино' },
      { id: 793, label: 'Пыталово' },
      { id: 794, label: 'Пыть-Ях' },
      { id: 795, label: 'Пятигорск' },
      { id: 796, label: 'Радужный' },
      { id: 797, label: 'Радужный' },
      { id: 798, label: 'Райчихинск' },
      { id: 799, label: 'Раменское' },
      { id: 800, label: 'Рассказово' },
      { id: 801, label: 'Ревда' },
      { id: 802, label: 'Реж' },
      { id: 803, label: 'Реутов' },
      { id: 804, label: 'Ржев' },
      { id: 805, label: 'Родники' },
      { id: 806, label: 'Рославль' },
      { id: 807, label: 'Россошь' },
      { id: 808, label: 'Ростов-на-Дону' },
      { id: 809, label: 'Ростов' },
      { id: 810, label: 'Рошаль' },
      { id: 811, label: 'Ртищево' },
      { id: 812, label: 'Рубцовск' },
      { id: 813, label: 'Рудня' },
      { id: 814, label: 'Руза' },
      { id: 815, label: 'Рузаевка' },
      { id: 816, label: 'Рыбинск' },
      { id: 817, label: 'Рыбное' },
      { id: 818, label: 'Рыльск' },
      { id: 819, label: 'Ряжск' },
      { id: 820, label: 'Рязань' },
      { id: 821, label: 'СакиОспаривается' },
      { id: 822, label: 'Салават' },
      { id: 823, label: 'Салаир' },
      { id: 824, label: 'Салехард' },
      { id: 825, label: 'Сальск' },
      { id: 826, label: 'Самара' },
      { id: 827, label: 'Санкт-Петербург' },
      { id: 828, label: 'Саранск' },
      { id: 829, label: 'Сарапул' },
      { id: 830, label: 'Саратов' },
      { id: 831, label: 'Саров' },
      { id: 832, label: 'Сасово' },
      { id: 833, label: 'Сатка' },
      { id: 834, label: 'Сафоново' },
      { id: 835, label: 'Саяногорск' },
      { id: 836, label: 'Саянск' },
      { id: 837, label: 'Светлогорск' },
      { id: 838, label: 'Светлоград' },
      { id: 839, label: 'Светлый' },
      { id: 840, label: 'Светогорск' },
      { id: 841, label: 'Свирск' },
      { id: 842, label: 'Свободный' },
      { id: 843, label: 'Себеж' },
      { id: 844, label: 'СевастопольОспаривается' },
      { id: 845, label: 'Северо-Курильск' },
      { id: 846, label: 'Северобайкальск' },
      { id: 847, label: 'Северодвинск' },
      { id: 848, label: 'Североморск' },
      { id: 849, label: 'Североуральск' },
      { id: 850, label: 'Северск' },
      { id: 851, label: 'Севск' },
      { id: 852, label: 'Сегежа' },
      { id: 853, label: 'Сельцо' },
      { id: 854, label: 'Семёнов' },
      { id: 855, label: 'Семикаракорск' },
      { id: 856, label: 'Семилуки' },
      { id: 857, label: 'Сенгилей' },
      { id: 858, label: 'Серафимович' },
      { id: 859, label: 'Сергач' },
      { id: 860, label: 'Сергиев Посад' },
      { id: 861, label: 'Сердобск' },
      { id: 862, label: 'Серов' },
      { id: 863, label: 'Серпухов' },
      { id: 864, label: 'Сертолово' },
      { id: 865, label: 'Сибай' },
      { id: 866, label: 'Сим' },
      { id: 867, label: 'СимферопольОспаривается' },
      { id: 868, label: 'Сковородино' },
      { id: 869, label: 'Скопин' },
      { id: 870, label: 'Славгород' },
      { id: 871, label: 'Славск' },
      { id: 872, label: 'Славянск-на-Кубани' },
      { id: 873, label: 'Сланцы' },
      { id: 874, label: 'Слободской' },
      { id: 875, label: 'Слюдянка' },
      { id: 876, label: 'Смоленск' },
      { id: 877, label: 'Снежинск' },
      { id: 878, label: 'Снежногорск' },
      { id: 879, label: 'Собинка' },
      { id: 880, label: 'Советск' },
      { id: 881, label: 'Советск' },
      { id: 882, label: 'Советск' },
      { id: 883, label: 'Советская Гавань' },
      { id: 884, label: 'Советский' },
      { id: 885, label: 'Сокол' },
      { id: 886, label: 'Солигалич' },
      { id: 887, label: 'Соликамск' },
      { id: 888, label: 'Солнечногорск' },
      { id: 889, label: 'Соль-Илецк' },
      { id: 890, label: 'Сольвычегодск' },
      { id: 891, label: 'Сольцы' },
      { id: 892, label: 'Сорочинск' },
      { id: 893, label: 'Сорск' },
      { id: 894, label: 'Сортавала' },
      { id: 895, label: 'Сосенский' },
      { id: 896, label: 'Сосновка' },
      { id: 897, label: 'Сосновоборск' },
      { id: 898, label: 'Сосновый Бор' },
      { id: 899, label: 'Сосногорск' },
      { id: 900, label: 'Сочи' },
      { id: 901, label: 'Спас-Деменск' },
      { id: 902, label: 'Спас-Клепики' },
      { id: 903, label: 'Спасск' },
      { id: 904, label: 'Спасск-Дальний' },
      { id: 905, label: 'Спасск-Рязанский' },
      { id: 906, label: 'Среднеколымск' },
      { id: 907, label: 'Среднеуральск' },
      { id: 908, label: 'Сретенск' },
      { id: 909, label: 'Ставрополь' },
      { id: 910, label: 'Старая Купавна' },
      { id: 911, label: 'Старая Русса' },
      { id: 912, label: 'Старица' },
      { id: 913, label: 'Стародуб' },
      { id: 914, label: 'Старый КрымОспаривается' },
      { id: 915, label: 'Старый Оскол' },
      { id: 916, label: 'Стерлитамак' },
      { id: 917, label: 'Стрежевой' },
      { id: 918, label: 'Строитель' },
      { id: 919, label: 'Струнино' },
      { id: 920, label: 'Ступино' },
      { id: 921, label: 'Суворов' },
      { id: 922, label: 'СудакОспаривается' },
      { id: 923, label: 'Суджа' },
      { id: 924, label: 'Судогда' },
      { id: 925, label: 'Суздаль' },
      { id: 926, label: 'Сунжа' },
      { id: 927, label: 'Суоярви' },
      { id: 928, label: 'Сураж' },
      { id: 929, label: 'Сургут' },
      { id: 930, label: 'Суровикино' },
      { id: 931, label: 'Сурск' },
      { id: 932, label: 'Сусуман' },
      { id: 933, label: 'Сухиничи' },
      { id: 934, label: 'Сухой Лог' },
      { id: 935, label: 'Сызрань' },
      { id: 936, label: 'Сыктывкар' },
      { id: 937, label: 'Сысерть' },
      { id: 938, label: 'Сычёвка' },
      { id: 939, label: 'Сясьстрой' },
      { id: 940, label: 'Тавда' },
      { id: 941, label: 'Таганрог' },
      { id: 942, label: 'Тайга' },
      { id: 943, label: 'Тайшет' },
      { id: 944, label: 'Талдом' },
      { id: 945, label: 'Талица' },
      { id: 946, label: 'Тамбов' },
      { id: 947, label: 'Тара' },
      { id: 948, label: 'Тарко-Сале' },
      { id: 949, label: 'Таруса' },
      { id: 950, label: 'Татарск' },
      { id: 951, label: 'Таштагол' },
      { id: 952, label: 'Тверь' },
      { id: 953, label: 'Теберда' },
      { id: 954, label: 'Тейково' },
      { id: 955, label: 'Темников' },
      { id: 956, label: 'Темрюк' },
      { id: 957, label: 'Терек' },
      { id: 958, label: 'Тетюши' },
      { id: 959, label: 'Тимашёвск' },
      { id: 960, label: 'Тихвин' },
      { id: 961, label: 'Тихорецк' },
      { id: 962, label: 'Тобольск' },
      { id: 963, label: 'Тогучин' },
      { id: 964, label: 'Тольятти' },
      { id: 965, label: 'Томари' },
      { id: 966, label: 'Томмот' },
      { id: 967, label: 'Томск' },
      { id: 968, label: 'Топки' },
      { id: 969, label: 'Торжок' },
      { id: 970, label: 'Торопец' },
      { id: 971, label: 'Тосно' },
      { id: 972, label: 'Тотьма' },
      { id: 973, label: 'Трёхгорный' },
      { id: 974, label: 'Троицк' },
      { id: 975, label: 'Трубчевск' },
      { id: 976, label: 'Туапсе' },
      { id: 977, label: 'Туймазы' },
      { id: 978, label: 'Тула' },
      { id: 979, label: 'Тулун' },
      { id: 980, label: 'Туран' },
      { id: 981, label: 'Туринск' },
      { id: 982, label: 'Тутаев' },
      { id: 983, label: 'Тында' },
      { id: 984, label: 'Тырныауз' },
      { id: 985, label: 'Тюкалинск' },
      { id: 986, label: 'Тюмень' },
      { id: 987, label: 'Уварово' },
      { id: 988, label: 'Углегорск' },
      { id: 989, label: 'Углич' },
      { id: 990, label: 'Удачный' },
      { id: 991, label: 'Удомля' },
      { id: 992, label: 'Ужур' },
      { id: 993, label: 'Узловая' },
      { id: 994, label: 'Улан-Удэ' },
      { id: 995, label: 'Ульяновск' },
      { id: 996, label: 'Унеча' },
      { id: 997, label: 'Урай' },
      { id: 998, label: 'Урень' },
      { id: 999, label: 'Уржум' },
      { id: 1000, label: 'Урус-Мартан' },
      { id: 1001, label: 'Урюпинск' },
      { id: 1002, label: 'Усинск' },
      { id: 1003, label: 'Усмань' },
      { id: 1004, label: 'Усолье-Сибирское' },
      { id: 1005, label: 'Усолье' },
      { id: 1006, label: 'Уссурийск' },
      { id: 1007, label: 'Усть-Джегута' },
      { id: 1008, label: 'Усть-Илимск' },
      { id: 1009, label: 'Усть-Катав' },
      { id: 1010, label: 'Усть-Кут' },
      { id: 1011, label: 'Усть-Лабинск' },
      { id: 1012, label: 'Устюжна' },
      { id: 1013, label: 'Уфа' },
      { id: 1014, label: 'Ухта' },
      { id: 1015, label: 'Учалы' },
      { id: 1016, label: 'Уяр' },
      { id: 1017, label: 'Фатеж' },
      { id: 1018, label: 'ФеодосияОспаривается' },
      { id: 1019, label: 'Фокино' },
      { id: 1020, label: 'Фокино' },
      { id: 1021, label: 'Фролово' },
      { id: 1022, label: 'Фрязино' },
      { id: 1023, label: 'Фурманов' },
      { id: 1024, label: 'Хабаровск' },
      { id: 1025, label: 'Хадыженск' },
      { id: 1026, label: 'Ханты-Мансийск' },
      { id: 1027, label: 'Харабали' },
      { id: 1028, label: 'Харовск' },
      { id: 1029, label: 'Хасавюрт' },
      { id: 1030, label: 'Хвалынск' },
      { id: 1031, label: 'Хилок' },
      { id: 1032, label: 'Химки' },
      { id: 1033, label: 'Холм' },
      { id: 1034, label: 'Холмск' },
      { id: 1035, label: 'Хотьково' },
      { id: 1036, label: 'Цивильск' },
      { id: 1037, label: 'Цимлянск' },
      { id: 1038, label: 'Циолковский' },
      { id: 1039, label: 'Чадан' },
      { id: 1040, label: 'Чайковский' },
      { id: 1041, label: 'Чапаевск' },
      { id: 1042, label: 'Чаплыгин' },
      { id: 1043, label: 'Чебаркуль' },
      { id: 1044, label: 'Чебоксары' },
      { id: 1045, label: 'Чегем' },
      { id: 1046, label: 'Чекалин' },
      { id: 1047, label: 'Челябинск' },
      { id: 1048, label: 'Чердынь' },
      { id: 1049, label: 'Черемхово' },
      { id: 1050, label: 'Черепаново' },
      { id: 1051, label: 'Череповец' },
      { id: 1052, label: 'Черкесск' },
      { id: 1053, label: 'Чёрмоз' },
      { id: 1054, label: 'Черноголовка' },
      { id: 1055, label: 'Черногорск' },
      { id: 1056, label: 'Чернушка' },
      { id: 1057, label: 'Черняховск' },
      { id: 1058, label: 'Чехов' },
      { id: 1059, label: 'Чистополь' },
      { id: 1060, label: 'Чита' },
      { id: 1061, label: 'Чкаловск' },
      { id: 1062, label: 'Чудово' },
      { id: 1063, label: 'Чулым' },
      { id: 1064, label: 'Чусовой' },
      { id: 1065, label: 'Чухлома' },
      { id: 1066, label: 'Шагонар' },
      { id: 1067, label: 'Шадринск' },
      { id: 1068, label: 'Шали' },
      { id: 1069, label: 'Шарыпово' },
      { id: 1070, label: 'Шарья' },
      { id: 1071, label: 'Шатура' },
      { id: 1072, label: 'Шахты' },
      { id: 1073, label: 'Шахунья' },
      { id: 1074, label: 'Шацк' },
      { id: 1075, label: 'Шебекино' },
      { id: 1076, label: 'Шелехов' },
      { id: 1077, label: 'Шенкурск' },
      { id: 1078, label: 'Шилка' },
      { id: 1079, label: 'Шимановск' },
      { id: 1080, label: 'Шиханы' },
      { id: 1081, label: 'Шлиссельбург' },
      { id: 1082, label: 'Шумерля' },
      { id: 1083, label: 'Шумиха' },
      { id: 1084, label: 'Шуя' },
      { id: 1085, label: 'Щёкино' },
      { id: 1086, label: 'ЩёлкиноОспаривается' },
      { id: 1087, label: 'Щёлково' },
      { id: 1088, label: 'Щигры' },
      { id: 1089, label: 'Щучье' },
      { id: 1090, label: 'Электрогорск' },
      { id: 1091, label: 'Электросталь' },
      { id: 1092, label: 'Электроугли' },
      { id: 1093, label: 'Элиста' },
      { id: 1094, label: 'Энгельс' },
      { id: 1095, label: 'Эртиль' },
      { id: 1096, label: 'Югорск' },
      { id: 1097, label: 'Южа' },
      { id: 1098, label: 'Южно-Сахалинск' },
      { id: 1099, label: 'Южно-Сухокумск' },
      { id: 1100, label: 'Южноуральск' },
      { id: 1101, label: 'Юрга' },
      { id: 1102, label: 'Юрьев-Польский' },
      { id: 1103, label: 'Юрьевец' },
      { id: 1104, label: 'Юрюзань' },
      { id: 1105, label: 'Юхнов' },
      { id: 1106, label: 'Ядрин' },
      { id: 1107, label: 'Якутск' },
      { id: 1108, label: 'ЯлтаОспаривается' },
      { id: 1109, label: 'Ялуторовск' },
      { id: 1110, label: 'Янаул' },
      { id: 1111, label: 'Яранск' },
      { id: 1112, label: 'Яровое' },
      { id: 1113, label: 'Ярославль' },
      { id: 1114, label: 'Ярцево' },
      { id: 1115, label: 'Ясногорск' },
      { id: 1116, label: 'Ясный' },
      { id: 1117, label: 'Яхрома' },
    ],
  },
  {
    id: 6,
    lang: 'geo',
    label: 'საქართველო',
    currency: 'GEL',
    code: 'GE',
    showLang: false,
    cities: [
      { id: 1, label: 'თბილისი' },
      { id: 2, label: 'აბაშა' },
      { id: 3, label: 'ამბროლაური' },
      { id: 4, label: 'ახალქალაქი' },
      { id: 5, label: 'ახალციხე' },
      { id: 6, label: 'ახმეტა' },
      { id: 7, label: 'ბაღდათი' },
      { id: 8, label: 'ბათუმი' },
      { id: 9, label: 'ბოლნისი' },
      { id: 10, label: 'ბორჯომი' },
      { id: 11, label: 'ვალე' },
      { id: 12, label: 'ვანი' },
      { id: 13, label: 'გარდაბანი' },
      { id: 14, label: 'გორი' },
      { id: 15, label: 'გურჯაანი' },
      { id: 16, label: 'დედოფლისწყარო' },
      { id: 17, label: 'ჯვარი' },
      { id: 18, label: 'დმანისი' },
      { id: 19, label: 'დუშეთი' },
      { id: 20, label: 'ზესტაფონი' },
      { id: 21, label: 'ზუგდიდი' },
      { id: 22, label: 'ქარელი' },
      { id: 23, label: 'კასპი' },
      { id: 24, label: 'ყვარელი' },
      { id: 25, label: 'ქობულეთი' },
      { id: 26, label: 'ქუთაისი' },
      { id: 27, label: 'ლაგოდეხი' },
      { id: 28, label: 'ლანჩხუთი' },
      { id: 29, label: 'მარნეული' },
      { id: 30, label: 'მარტვილი' },
      { id: 31, label: 'მცხეთა' },
      { id: 32, label: 'ნინოწმინდა' },
      { id: 33, label: 'ოზურგეთი' },
      { id: 34, label: 'ონი' },
      { id: 35, label: 'ფოთი' },
      { id: 36, label: 'რუსთავი' },
      { id: 37, label: 'საგარეჯო' },
      { id: 38, label: 'სამტრედია' },
      { id: 39, label: 'საჩხერე' },
      { id: 40, label: 'სენაკი' },
      { id: 41, label: 'სიღნაღი' },
      { id: 42, label: 'თელავი' },
      { id: 43, label: 'თერჯოლა' },
      { id: 44, label: 'თეთრი წყარო' },
      { id: 45, label: 'ტყიბული' },
      { id: 46, label: 'ხაშური' },
      { id: 47, label: 'ხობი' },
      { id: 48, label: 'ხონი' },
      { id: 49, label: 'ცაგერი' },
      { id: 50, label: 'წალენჯიხა' },
      { id: 51, label: 'წალკა' },
      { id: 52, label: 'წნორი' },
      { id: 53, label: 'წყალტუბო' },
      { id: 54, label: 'ჭიათურა' },
    ],
  },
  {
    id: 10,
    lang: 'spa',
    label: 'España',
    currency: 'EUR',
    code: 'ES',
    cities: [
      { id: 1, label: 'Madrid' },
      { id: 2, label: 'Barcelona' },
      { id: 3, label: 'Valencia' },
      { id: 4, label: 'Sevilla' },
      { id: 5, label: 'Zaragoza' },
      { id: 6, label: 'Málaga' },
      { id: 7, label: 'Murcia' },
      { id: 8, label: 'Palma' },
      { id: 9, label: 'Las Palmas de Gran Canaria' },
      { id: 10, label: 'Bilbao' },
      { id: 11, label: 'Alicante' },
      { id: 12, label: 'Córdoba' },
      { id: 13, label: 'Valladolid' },
      { id: 14, label: 'Vigo' },
      { id: 15, label: 'Gijón' },
      { id: 16, label: 'L’Hospitalet de Llobregat' },
      { id: 17, label: 'La Coruña' },
      { id: 18, label: 'Granada' },
      { id: 19, label: 'Vitoria' },
      { id: 20, label: 'Elche' },
      { id: 21, label: 'Oviedo' },
      { id: 22, label: 'Santa Cruz de Tenerife' },
      { id: 23, label: 'Badalona' },
      { id: 24, label: 'Cartagena' },
      { id: 25, label: 'Tarrasa' },
      { id: 26, label: 'Jerez de la Frontera' },
      { id: 27, label: 'Sabadell' },
      { id: 28, label: 'Móstoles' },
      { id: 29, label: 'Alcalá de Henares' },
    ],
  },
  {
    label: 'افغانستان', currency: 'USD', code: 'AF', lang: 'pus', cities: [], id: 9,
  },
  {
    label: 'Åland', currency: 'USD', code: 'AX', lang: 'swe', cities: [], id: 10,
  },
  {
    label: 'Shqipëria', currency: 'USD', code: 'AL', lang: 'sqi', cities: [], id: 11,
  },
  {
    label: 'الجزائر', currency: 'USD', code: 'DZ', lang: 'ara', cities: [], id: 12,
  },
  {
    label: 'American Samoa', currency: 'USD', code: 'AS', lang: 'eng', cities: [], id: 13,
  },
  {
    label: 'Andorra', currency: 'USD', code: 'AD', lang: 'cat', cities: [], id: 14,
  },
  {
    label: 'Angola', currency: 'USD', code: 'AO', lang: 'por', cities: [], id: 15,
  },
  {
    label: 'Anguilla', currency: 'USD', code: 'AI', lang: 'eng', cities: [], id: 16,
  },
  {
    label: 'Antarctica', currency: 'USD', code: 'AQ', lang: 'eng', cities: [], id: 17,
  },
  {
    label: 'Antigua and Barbuda', currency: 'USD', code: 'AG', lang: 'eng', cities: [], id: 18,
  },
  {
    label: 'Argentina', currency: 'USD', code: 'AR', lang: 'spa', cities: [], id: 19,
  },
  {
    label: 'Հայաստան', currency: 'USD', code: 'AM', lang: 'hye', cities: [], id: 20,
  },
  {
    label: 'Aruba', currency: 'USD', code: 'AW', lang: 'nld', cities: [], id: 21,
  },
  {
    label: 'Australia', currency: 'USD', code: 'AU', lang: 'eng', cities: [], id: 22,
  },
  {
    label: 'Österreich', currency: 'USD', code: 'AT', lang: 'deu', cities: [], id: 23,
  },
  {
    label: 'Bahamas', currency: 'USD', code: 'BS', lang: 'eng', cities: [], id: 25,
  },
  {
    label: '‏البحرين', currency: 'USD', code: 'BH', lang: 'ara', cities: [], id: 26,
  },
  {
    label: 'Bangladesh', currency: 'USD', code: 'BD', lang: 'ben', cities: [], id: 27,
  },
  {
    label: 'Barbados', currency: 'USD', code: 'BB', lang: 'eng', cities: [], id: 28,
  },
  {
    label: 'Белару́сь', currency: 'USD', code: 'BY', lang: 'bel', cities: [], id: 29,
  },
  {
    label: 'België', currency: 'USD', code: 'BE', lang: 'nld', cities: [], id: 30,
  },
  {
    label: 'Belize', currency: 'USD', code: 'BZ', lang: 'eng', cities: [], id: 31,
  },
  {
    label: 'Bénin', currency: 'USD', code: 'BJ', lang: 'fra', cities: [], id: 32,
  },
  {
    label: 'Bermuda', currency: 'USD', code: 'BM', lang: 'eng', cities: [], id: 33,
  },
  {
    label: 'brug-yul', currency: 'USD', code: 'BT', lang: 'dzo', cities: [], id: 34,
  },
  {
    label: 'Bolivia', currency: 'USD', code: 'BO', lang: 'spa', cities: [], id: 35,
  },
  {
    label: 'Bonaire', currency: 'USD', code: 'BQ', lang: 'nld', cities: [], id: 36,
  },
  {
    label: 'Bosna i Hercegovina', currency: 'USD', code: 'BA', lang: 'bos', cities: [], id: 37,
  },
  {
    label: 'Botswana', currency: 'USD', code: 'BW', lang: 'eng', cities: [], id: 38,
  },
  {
    label: 'Bouvetøya', currency: 'USD', code: 'BV', lang: 'nor', cities: [], id: 39,
  },
  {
    label: 'Brasil', currency: 'USD', code: 'BR', lang: 'por', cities: [], id: 40,
  },
  {
    label: 'British Indian Ocean Territory', currency: 'USD', code: 'IO', lang: 'eng', cities: [], id: 41,
  },
  {
    label: 'United States Minor Outlying Islands', currency: 'USD', code: 'UM', lang: 'eng', cities: [], id: 42,
  },
  {
    label: 'British Virgin Islands', currency: 'USD', code: 'VG', lang: 'eng', cities: [], id: 43,
  },
  {
    label: 'Virgin Islands of the United States', currency: 'USD', code: 'VI', lang: 'eng', cities: [], id: 44,
  },
  {
    label: 'Negara Brunei Darussalam', currency: 'USD', code: 'BN', lang: 'msa', cities: [], id: 45,
  },
  {
    label: 'България', currency: 'USD', code: 'BG', lang: 'bul', cities: [], id: 46,
  },
  {
    label: 'Burkina Faso', currency: 'USD', code: 'BF', lang: 'fra', cities: [], id: 47,
  },
  {
    label: 'Burundi', currency: 'USD', code: 'BI', lang: 'fra', cities: [], id: 48,
  },
  {
    label: 'Kâmpŭchéa', currency: 'USD', code: 'KH', lang: 'khm', cities: [], id: 49,
  },
  {
    label: 'Cameroon', currency: 'USD', code: 'CM', lang: 'eng', cities: [], id: 50,
  },
  {
    label: 'Canada', currency: 'USD', code: 'CA', lang: 'eng', cities: [], id: 51,
  },
  {
    label: 'Cabo Verde', currency: 'USD', code: 'CV', lang: 'por', cities: [], id: 52,
  },
  {
    label: 'Cayman Islands', currency: 'USD', code: 'KY', lang: 'eng', cities: [], id: 53,
  },
  {
    label: 'Ködörösêse tî Bêafrîka', currency: 'USD', code: 'CF', lang: 'fra', cities: [], id: 54,
  },
  {
    label: 'Tchad', currency: 'USD', code: 'TD', lang: 'fra', cities: [], id: 55,
  },
  {
    label: 'Chile', currency: 'USD', code: 'CL', lang: 'spa', cities: [], id: 56,
  },
  {
    label: '中国', currency: 'USD', code: 'CN', lang: 'zho', cities: [], id: 57,
  },
  {
    label: 'Christmas Island', currency: 'USD', code: 'CX', lang: 'eng', cities: [], id: 58,
  },
  {
    label: 'Cocos (Keeling) Islands', currency: 'USD', code: 'CC', lang: 'eng', cities: [], id: 59,
  },
  {
    label: 'Colombia', currency: 'USD', code: 'CO', lang: 'spa', cities: [], id: 60,
  },
  {
    label: 'Komori', currency: 'USD', code: 'KM', lang: 'ara', cities: [], id: 61,
  },
  {
    label: 'République du Congo', currency: 'USD', code: 'CG', lang: 'fra', cities: [], id: 62,
  },
  {
    label: 'République démocratique du Congo', currency: 'USD', code: 'CD', lang: 'fra', cities: [], id: 63,
  },
  {
    label: 'Cook Islands', currency: 'USD', code: 'CK', lang: 'eng', cities: [], id: 64,
  },
  {
    label: 'Costa Rica', currency: 'USD', code: 'CR', lang: 'spa', cities: [], id: 65,
  },
  {
    label: 'Hrvatska', currency: 'USD', code: 'HR', lang: 'hrv', cities: [], id: 66,
  },
  {
    label: 'Cuba', currency: 'USD', code: 'CU', lang: 'spa', cities: [], id: 67,
  },
  {
    label: 'Curaçao', currency: 'USD', code: 'CW', lang: 'nld', cities: [], id: 68,
  },
  {
    label: 'Κύπρος', currency: 'USD', code: 'CY', lang: 'ell', cities: [], id: 69,
  },
  {
    label: 'Česká republika', currency: 'USD', code: 'CZ', lang: 'ces', cities: [], id: 70,
  },
  {
    label: 'Danmark', currency: 'USD', code: 'DK', lang: 'dan', cities: [], id: 71,
  },
  {
    label: 'Djibouti', currency: 'USD', code: 'DJ', lang: 'fra', cities: [], id: 72,
  },
  {
    label: 'Dominica', currency: 'USD', code: 'DM', lang: 'eng', cities: [], id: 73,
  },
  {
    label: 'República Dominicana', currency: 'USD', code: 'DO', lang: 'spa', cities: [], id: 74,
  },
  {
    label: 'Ecuador', currency: 'USD', code: 'EC', lang: 'spa', cities: [], id: 75,
  },
  {
    label: 'مصر', currency: 'USD', code: 'EG', lang: 'ara', cities: [], id: 76,
  },
  {
    label: 'El Salvador', currency: 'USD', code: 'SV', lang: 'spa', cities: [], id: 77,
  },
  {
    label: 'Guinea Ecuatorial', currency: 'USD', code: 'GQ', lang: 'spa', cities: [], id: 78,
  },
  {
    label: 'ኤርትራ', currency: 'USD', code: 'ER', lang: 'tir', cities: [], id: 79,
  },
  {
    label: 'Eesti', currency: 'USD', code: 'EE', lang: 'est', cities: [], id: 80,
  },
  {
    label: 'ኢትዮጵያ', currency: 'USD', code: 'ET', lang: 'amh', cities: [], id: 81,
  },
  {
    label: 'Falkland Islands', currency: 'USD', code: 'FK', lang: 'eng', cities: [], id: 82,
  },
  {
    label: 'Føroyar', currency: 'USD', code: 'FO', lang: 'fao', cities: [], id: 83,
  },
  {
    label: 'Fiji', currency: 'USD', code: 'FJ', lang: 'eng', cities: [], id: 84,
  },
  {
    label: 'Suomi', currency: 'USD', code: 'FI', lang: 'fin', cities: [], id: 85,
  },
  {
    label: 'Guyane française', currency: 'USD', code: 'GF', lang: 'fra', cities: [], id: 87,
  },
  {
    label: 'Polynésie française', currency: 'USD', code: 'PF', lang: 'fra', cities: [], id: 88,
  },
  {
    label: 'Territoire des Terres australes et antarctiques françaises', currency: 'USD', code: 'TF', lang: 'fra', cities: [], id: 89,
  },
  {
    label: 'Gabon', currency: 'USD', code: 'GA', lang: 'fra', cities: [], id: 90,
  },
  {
    label: 'Gambia', currency: 'USD', code: 'GM', lang: 'eng', cities: [], id: 91,
  },
  {
    label: 'Ghana', currency: 'USD', code: 'GH', lang: 'eng', cities: [], id: 94,
  },
  {
    label: 'Gibraltar', currency: 'USD', code: 'GI', lang: 'eng', cities: [], id: 95,
  },
  {
    label: 'Ελλάδα', currency: 'USD', code: 'GR', lang: 'ell', cities: [], id: 96,
  },
  {
    label: 'Kalaallit Nunaat', currency: 'USD', code: 'GL', lang: 'kal', cities: [], id: 97,
  },
  {
    label: 'Grenada', currency: 'USD', code: 'GD', lang: 'eng', cities: [], id: 98,
  },
  {
    label: 'Guadeloupe', currency: 'USD', code: 'GP', lang: 'fra', cities: [], id: 99,
  },
  {
    label: 'Guam', currency: 'USD', code: 'GU', lang: 'eng', cities: [], id: 100,
  },
  {
    label: 'Guatemala', currency: 'USD', code: 'GT', lang: 'spa', cities: [], id: 101,
  },
  {
    label: 'Guernsey', currency: 'USD', code: 'GG', lang: 'eng', cities: [], id: 102,
  },
  {
    label: 'Guinée', currency: 'USD', code: 'GN', lang: 'fra', cities: [], id: 103,
  },
  {
    label: 'Guiné-Bissau', currency: 'USD', code: 'GW', lang: 'por', cities: [], id: 104,
  },
  {
    label: 'Guyana', currency: 'USD', code: 'GY', lang: 'eng', cities: [], id: 105,
  },
  {
    label: 'Haïti', currency: 'USD', code: 'HT', lang: 'fra', cities: [], id: 106,
  },
  {
    label: 'Heard Island and McDonald Islands', currency: 'USD', code: 'HM', lang: 'eng', cities: [], id: 107,
  },
  {
    label: 'Sancta Sedes', currency: 'USD', code: 'VA', lang: 'lat', cities: [], id: 108,
  },
  {
    label: 'Honduras', currency: 'USD', code: 'HN', lang: 'spa', cities: [], id: 109,
  },
  {
    label: '香港', currency: 'USD', code: 'HK', lang: 'eng', cities: [], id: 110,
  },
  {
    label: 'Magyarország', currency: 'USD', code: 'HU', lang: 'hun', cities: [], id: 111,
  },
  {
    label: 'Ísland', currency: 'USD', code: 'IS', lang: 'isl', cities: [], id: 112,
  },
  {
    label: 'भारत', currency: 'USD', code: 'IN', lang: 'hin', cities: [], id: 113,
  },
  {
    label: 'Indonesia', currency: 'USD', code: 'ID', lang: 'ind', cities: [], id: 114,
  },
  {
    label: 'Côte d\'Ivoire', currency: 'USD', code: 'CI', lang: 'fra', cities: [], id: 115,
  },
  {
    label: 'ایران', currency: 'USD', code: 'IR', lang: 'fas', cities: [], id: 116,
  },
  {
    label: 'العراق', currency: 'USD', code: 'IQ', lang: 'ara', cities: [], id: 117,
  },
  {
    label: 'Éire', currency: 'USD', code: 'IE', lang: 'gle', cities: [], id: 118,
  },
  {
    label: 'Isle of Man', currency: 'USD', code: 'IM', lang: 'eng', cities: [], id: 119,
  },
  {
    label: 'יִשְׂרָאֵל', currency: 'USD', code: 'IL', lang: 'heb', cities: [], id: 120,
  },
  {
    label: 'Jamaica', currency: 'USD', code: 'JM', lang: 'eng', cities: [], id: 122,
  },
  {
    label: '日本', currency: 'USD', code: 'JP', lang: 'jpn', cities: [], id: 123,
  },
  {
    label: 'Jersey', currency: 'USD', code: 'JE', lang: 'eng', cities: [], id: 124,
  },
  {
    label: 'الأردن', currency: 'USD', code: 'JO', lang: 'ara', cities: [], id: 125,
  },
  {
    label: 'Қазақстан', currency: 'USD', code: 'KZ', lang: 'kaz', cities: [], id: 126,
  },
  {
    label: 'Kenya', currency: 'USD', code: 'KE', lang: 'eng', cities: [], id: 127,
  },
  {
    label: 'Kiribati', currency: 'USD', code: 'KI', lang: 'eng', cities: [], id: 128,
  },
  {
    label: 'الكويت', currency: 'USD', code: 'KW', lang: 'ara', cities: [], id: 129,
  },
  {
    label: 'Кыргызстан', currency: 'USD', code: 'KG', lang: 'kir', cities: [], id: 130,
  },
  {
    label: 'ສປປລາວ', currency: 'USD', code: 'LA', lang: 'lao', cities: [], id: 131,
  },
  {
    label: 'Latvija', currency: 'USD', code: 'LV', lang: 'lav', cities: [], id: 132,
  },
  {
    label: 'لبنان', currency: 'USD', code: 'LB', lang: 'ara', cities: [], id: 133,
  },
  {
    label: 'Lesotho', currency: 'USD', code: 'LS', lang: 'eng', cities: [], id: 134,
  },
  {
    label: 'Liberia', currency: 'USD', code: 'LR', lang: 'eng', cities: [], id: 135,
  },
  {
    label: '‏ليبيا', currency: 'USD', code: 'LY', lang: 'ara', cities: [], id: 136,
  },
  {
    label: 'Liechtenstein', currency: 'USD', code: 'LI', lang: 'deu', cities: [], id: 137,
  },
  {
    label: 'Lietuva', currency: 'USD', code: 'LT', lang: 'lit', cities: [], id: 138,
  },
  {
    label: 'Luxembourg', currency: 'USD', code: 'LU', lang: 'fra', cities: [], id: 139,
  },
  {
    label: '澳門', currency: 'USD', code: 'MO', lang: 'zho', cities: [], id: 140,
  },
  {
    label: 'Македонија', currency: 'USD', code: 'MK', lang: 'mkd', cities: [], id: 141,
  },
  {
    label: 'Madagasikara', currency: 'USD', code: 'MG', lang: 'fra', cities: [], id: 142,
  },
  {
    label: 'Malawi', currency: 'USD', code: 'MW', lang: 'eng', cities: [], id: 143,
  },
  {
    label: 'Malaysia', currency: 'USD', code: 'MY', lang: 'zsm', cities: [], id: 144,
  },
  {
    label: 'Maldives', currency: 'USD', code: 'MV', lang: 'div', cities: [], id: 145,
  },
  {
    label: 'Mali', currency: 'USD', code: 'ML', lang: 'fra', cities: [], id: 146,
  },
  {
    label: 'Malta', currency: 'USD', code: 'MT', lang: 'mlt', cities: [], id: 147,
  },
  {
    label: 'M̧ajeļ', currency: 'USD', code: 'MH', lang: 'eng', cities: [], id: 148,
  },
  {
    label: 'Martinique', currency: 'USD', code: 'MQ', lang: 'fra', cities: [], id: 149,
  },
  {
    label: 'موريتانيا', currency: 'USD', code: 'MR', lang: 'ara', cities: [], id: 150,
  },
  {
    label: 'Maurice', currency: 'USD', code: 'MU', lang: 'eng', cities: [], id: 151,
  },
  {
    label: 'Mayotte', currency: 'USD', code: 'YT', lang: 'fra', cities: [], id: 152,
  },
  {
    label: 'México', currency: 'USD', code: 'MX', lang: 'spa', cities: [], id: 153,
  },
  {
    label: 'Micronesia', currency: 'USD', code: 'FM', lang: 'eng', cities: [], id: 154,
  },
  {
    label: 'Moldova', currency: 'USD', code: 'MD', lang: 'ron', cities: [], id: 155,
  },
  {
    label: 'Monaco', currency: 'USD', code: 'MC', lang: 'fra', cities: [], id: 156,
  },
  {
    label: 'Монгол улс', currency: 'USD', code: 'MN', lang: 'mon', cities: [], id: 157,
  },
  {
    label: 'Црна Гора', currency: 'USD', code: 'ME', lang: 'srp', cities: [], id: 158,
  },
  {
    label: 'Montserrat', currency: 'USD', code: 'MS', lang: 'eng', cities: [], id: 159,
  },
  {
    label: 'المغرب', currency: 'USD', code: 'MA', lang: 'ara', cities: [], id: 160,
  },
  {
    label: 'Moçambique', currency: 'USD', code: 'MZ', lang: 'por', cities: [], id: 161,
  },
  {
    label: 'Myanma', currency: 'USD', code: 'MM', lang: 'mya', cities: [], id: 162,
  },
  {
    label: 'Namibia', currency: 'USD', code: 'NA', lang: 'eng', cities: [], id: 163,
  },
  {
    label: 'Nauru', currency: 'USD', code: 'NR', lang: 'eng', cities: [], id: 164,
  },
  {
    label: 'नेपाल', currency: 'USD', code: 'NP', lang: 'nep', cities: [], id: 165,
  },
  {
    label: 'Nederland', currency: 'USD', code: 'NL', lang: 'nld', cities: [], id: 166,
  },
  {
    label: 'Nouvelle-Calédonie', currency: 'USD', code: 'NC', lang: 'fra', cities: [], id: 167,
  },
  {
    label: 'New Zealand', currency: 'USD', code: 'NZ', lang: 'eng', cities: [], id: 168,
  },
  {
    label: 'Nicaragua', currency: 'USD', code: 'NI', lang: 'spa', cities: [], id: 169,
  },
  {
    label: 'Niger', currency: 'USD', code: 'NE', lang: 'fra', cities: [], id: 170,
  },
  {
    label: 'Nigeria', currency: 'USD', code: 'NG', lang: 'eng', cities: [], id: 171,
  },
  {
    label: 'Niuē', currency: 'USD', code: 'NU', lang: 'eng', cities: [], id: 172,
  },
  {
    label: 'Norfolk Island', currency: 'USD', code: 'NF', lang: 'eng', cities: [], id: 173,
  },
  {
    label: '북한', currency: 'USD', code: 'KP', lang: 'kor', cities: [], id: 174,
  },
  {
    label: 'Northern Mariana Islands', currency: 'USD', code: 'MP', lang: 'eng', cities: [], id: 175,
  },
  {
    label: 'Norge', currency: 'USD', code: 'NO', lang: 'nor', cities: [], id: 176,
  },
  {
    label: 'عمان', currency: 'USD', code: 'OM', lang: 'ara', cities: [], id: 177,
  },
  {
    label: 'Pakistan', currency: 'USD', code: 'PK', lang: 'eng', cities: [], id: 178,
  },
  {
    label: 'Palau', currency: 'USD', code: 'PW', lang: 'eng', cities: [], id: 179,
  },
  {
    label: 'فلسطين', currency: 'USD', code: 'PS', lang: 'ara', cities: [], id: 180,
  },
  {
    label: 'Panamá', currency: 'USD', code: 'PA', lang: 'spa', cities: [], id: 181,
  },
  {
    label: 'Papua Niugini', currency: 'USD', code: 'PG', lang: 'eng', cities: [], id: 182,
  },
  {
    label: 'Paraguay', currency: 'USD', code: 'PY', lang: 'spa', cities: [], id: 183,
  },
  {
    label: 'Perú', currency: 'USD', code: 'PE', lang: 'spa', cities: [], id: 184,
  },
  {
    label: 'Pilipinas', currency: 'USD', code: 'PH', lang: 'eng', cities: [], id: 185,
  },
  {
    label: 'Pitcairn Islands', currency: 'USD', code: 'PN', lang: 'eng', cities: [], id: 186,
  },
  {
    label: 'Polska', currency: 'USD', code: 'PL', lang: 'pol', cities: [], id: 187,
  },
  {
    label: 'Portugal', currency: 'USD', code: 'PT', lang: 'por', cities: [], id: 188,
  },
  {
    label: 'Puerto Rico', currency: 'USD', code: 'PR', lang: 'spa', cities: [], id: 189,
  },
  {
    label: 'قطر', currency: 'USD', code: 'QA', lang: 'ara', cities: [], id: 190,
  },
  {
    label: 'Republika e Kosovës', currency: 'USD', code: 'XK', lang: 'sqi', cities: [], id: 191,
  },
  {
    label: 'La Réunion', currency: 'USD', code: 'RE', lang: 'fra', cities: [], id: 192,
  },
  {
    label: 'România', currency: 'USD', code: 'RO', lang: 'ron', cities: [], id: 193,
  },
  {
    label: 'Rwanda', currency: 'USD', code: 'RW', lang: 'kin', cities: [], id: 195,
  },
  {
    label: 'Saint-Barthélemy', currency: 'USD', code: 'BL', lang: 'fra', cities: [], id: 196,
  },
  {
    label: 'Saint Helena', currency: 'USD', code: 'SH', lang: 'eng', cities: [], id: 197,
  },
  {
    label: 'Saint Kitts and Nevis', currency: 'USD', code: 'KN', lang: 'eng', cities: [], id: 198,
  },
  {
    label: 'Saint Lucia', currency: 'USD', code: 'LC', lang: 'eng', cities: [], id: 199,
  },
  {
    label: 'Saint-Martin', currency: 'USD', code: 'MF', lang: 'eng', cities: [], id: 200,
  },
  {
    label: 'Saint-Pierre-et-Miquelon', currency: 'USD', code: 'PM', lang: 'fra', cities: [], id: 201,
  },
  {
    label: 'Saint Vincent and the Grenadines', currency: 'USD', code: 'VC', lang: 'eng', cities: [], id: 202,
  },
  {
    label: 'Samoa', currency: 'USD', code: 'WS', lang: 'smo', cities: [], id: 203,
  },
  {
    label: 'San Marino', currency: 'USD', code: 'SM', lang: 'ita', cities: [], id: 204,
  },
  {
    label: 'São Tomé e Príncipe', currency: 'USD', code: 'ST', lang: 'por', cities: [], id: 205,
  },
  {
    label: 'العربية السعودية', currency: 'USD', code: 'SA', lang: 'ara', cities: [], id: 206,
  },
  {
    label: 'Sénégal', currency: 'USD', code: 'SN', lang: 'fra', cities: [], id: 207,
  },
  {
    label: 'Србија', currency: 'USD', code: 'RS', lang: 'srp', cities: [], id: 208,
  },
  {
    label: 'Seychelles', currency: 'USD', code: 'SC', lang: 'fra', cities: [], id: 209,
  },
  {
    label: 'Sierra Leone', currency: 'USD', code: 'SL', lang: 'eng', cities: [], id: 210,
  },
  {
    label: 'Singapore', currency: 'USD', code: 'SG', lang: 'eng', cities: [], id: 211,
  },
  {
    label: 'Sint Maarten', currency: 'USD', code: 'SX', lang: 'nld', cities: [], id: 212,
  },
  {
    label: 'Slovensko', currency: 'USD', code: 'SK', lang: 'slk', cities: [], id: 213,
  },
  {
    label: 'Slovenija', currency: 'USD', code: 'SI', lang: 'slv', cities: [], id: 214,
  },
  {
    label: 'Solomon Islands', currency: 'USD', code: 'SB', lang: 'eng', cities: [], id: 215,
  },
  {
    label: 'Soomaaliya', currency: 'USD', code: 'SO', lang: 'som', cities: [], id: 216,
  },
  {
    label: 'South Africa', currency: 'USD', code: 'ZA', lang: 'afr', cities: [], id: 217,
  },
  {
    label: 'South Georgia', currency: 'USD', code: 'GS', lang: 'eng', cities: [], id: 218,
  },
  {
    label: '대한민국', currency: 'USD', code: 'KR', lang: 'kor', cities: [], id: 219,
  },
  {
    label: 'South Sudan', currency: 'USD', code: 'SS', lang: 'eng', cities: [], id: 220,
  },
  {
    label: 'السودان', currency: 'USD', code: 'SD', lang: 'ara', cities: [], id: 223,
  },
  {
    label: 'Suriname', currency: 'USD', code: 'SR', lang: 'nld', cities: [], id: 224,
  },
  {
    label: 'Svalbard og Jan Mayen', currency: 'USD', code: 'SJ', lang: 'nor', cities: [], id: 225,
  },
  {
    label: 'Swaziland', currency: 'USD', code: 'SZ', lang: 'eng', cities: [], id: 226,
  },
  {
    label: 'Sverige', currency: 'USD', code: 'SE', lang: 'swe', cities: [], id: 227,
  },
  {
    label: 'Schweiz', currency: 'USD', code: 'CH', lang: 'deu', cities: [], id: 228,
  },
  {
    label: 'سوريا', currency: 'USD', code: 'SY', lang: 'ara', cities: [], id: 229,
  },
  {
    label: '臺灣', currency: 'USD', code: 'TW', lang: 'zho', cities: [], id: 230,
  },
  {
    label: 'Тоҷикистон', currency: 'USD', code: 'TJ', lang: 'tgk', cities: [], id: 231,
  },
  {
    label: 'Tanzania', currency: 'USD', code: 'TZ', lang: 'swa', cities: [], id: 232,
  },
  {
    label: 'ประเทศไทย', currency: 'USD', code: 'TH', lang: 'tha', cities: [], id: 233,
  },
  {
    label: 'Timor-Leste', currency: 'USD', code: 'TL', lang: 'por', cities: [], id: 234,
  },
  {
    label: 'Togo', currency: 'USD', code: 'TG', lang: 'fra', cities: [], id: 235,
  },
  {
    label: 'Tokelau', currency: 'USD', code: 'TK', lang: 'eng', cities: [], id: 236,
  },
  {
    label: 'Tonga', currency: 'USD', code: 'TO', lang: 'eng', cities: [], id: 237,
  },
  {
    label: 'Trinidad and Tobago', currency: 'USD', code: 'TT', lang: 'eng', cities: [], id: 238,
  },
  {
    label: 'تونس', currency: 'USD', code: 'TN', lang: 'ara', cities: [], id: 239,
  },
  {
    label: 'Türkmenistan', currency: 'USD', code: 'TM', lang: 'tuk', cities: [], id: 241,
  },
  {
    label: 'Turks and Caicos Islands', currency: 'USD', code: 'TC', lang: 'eng', cities: [], id: 242,
  },
  {
    label: 'Tuvalu', currency: 'USD', code: 'TV', lang: 'eng', cities: [], id: 243,
  },
  {
    label: 'Uganda', currency: 'USD', code: 'UG', lang: 'eng', cities: [], id: 244,
  },
  {
    label: 'دولة الإمارات العربية المتحدة', currency: 'USD', code: 'AE', lang: 'ara', cities: [], id: 246,
  },
  {
    label: 'United Kingdom', currency: 'USD', code: 'GB', lang: 'eng', cities: [], id: 247,
  },
  {
    label: 'United States', currency: 'USD', code: 'US', lang: 'eng', cities: [], id: 248,
  },
  {
    label: 'Uruguay', currency: 'USD', code: 'UY', lang: 'spa', cities: [], id: 249,
  },
  {
    label: 'O‘zbekiston', currency: 'USD', code: 'UZ', lang: 'uzb', cities: [], id: 250,
  },
  {
    label: 'Vanuatu', currency: 'USD', code: 'VU', lang: 'bis', cities: [], id: 251,
  },
  {
    label: 'Venezuela', currency: 'USD', code: 'VE', lang: 'spa', cities: [], id: 252,
  },
  {
    label: 'Việt Nam', currency: 'USD', code: 'VN', lang: 'vie', cities: [], id: 253,
  },
  {
    label: 'Wallis et Futuna', currency: 'USD', code: 'WF', lang: 'fra', cities: [], id: 254,
  },
  {
    label: 'الصحراء الغربية', currency: 'USD', code: 'EH', lang: 'spa', cities: [], id: 255,
  },
  {
    label: 'اليَمَن', currency: 'USD', code: 'YE', lang: 'ara', cities: [], id: 256,
  },
  {
    label: 'Zambia', currency: 'USD', code: 'ZM', lang: 'eng', cities: [], id: 257,
  },
  {
    label: 'Zimbabwe', currency: 'USD', code: 'ZW', lang: 'eng', cities: [], id: 258,
  },
];

export default list;
