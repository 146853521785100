import { makeStyles } from '@material-ui/core';
import { grayBG, green, orange } from '../../constants';

export default makeStyles({
  img: {
    width: '50px',
    height: '50px',
  },
  commentBox: {
    backgroundColor: grayBG,
    borderTop: `2px ${orange} solid`,
    borderBottom: `2px ${orange} solid`,
    padding: '0 5px',
    marginTop: '5px',
  },
  userBox: {
    display: 'flex',
    '& .MuiTypography-root': {
      fontSize: '13px',
      textAlign: 'center',
    },
  },
  statsBox: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    '& > *': {
      '& .MuiTypography-root:last-child': {
        color: green,
        fontWeight: 'bold',
      },
    },
  },
  comment: {
    color: green,
    fontWeight: 'bold',
    marginLeft: '10px',
  },
  statsList: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTypography-root': {
      fontSize: '13px',
    },
    '& > *': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  svg: {
    width: '15px',
    height: 'auto',
    margin: '5px',
  },
  commentDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
  },
}, { index: 1 });
