import React from 'react';
import CollapseBox from 'components/CollapseComponent';
import TableVertical from 'sharedComponents/Table/components/TableVertical';
import { Box } from '@material-ui/core';
import { getTranslateFunction } from 'helpers';
import icons from 'components/Icons';
import { SecurityPaymentItem } from 'interfaces';
import { cyan } from '../../../constants';
import useStyles from '../PaymentsPageStyle';

export interface HeadList {
  label: string;
  name: string;
}

interface Props {
  values: SecurityPaymentItem[];
}

const {
  securityPayTsx,
} = icons;

const SecurityPayment: React.FC<Props> = ({
  values,
}: Props) => {
  const t = getTranslateFunction();
  const classes = useStyles();

  return (
    <CollapseBox
      title={t('PaymentsPage.securityPayment')}
      Icon={securityPayTsx}
    >
      {values.map((item: SecurityPaymentItem) => {
        const formatItem = Object.fromEntries(
          Object.entries(item)
            .map(([key, value]) => ([t(`PaymentsPage.securityPayment.${key}`), value])),
        );

        return (
          <Box className={classes.incomeTable} key={item.transactionNumber}>
            <TableVertical tableConfig={formatItem} />
            <hr color={cyan} className={classes.hr} />
          </Box>
        );
      })}
    </CollapseBox>
  );
};

export default SecurityPayment;
