import { useState } from 'react';
import { Box, Button, FormHelperText } from '@material-ui/core';
import CollapseBox from 'components/CollapseComponent';
import ConfirmCode from 'components/ConfirmCode';
import Modal from 'sharedComponents/Modal';
import { getTranslateFunction, unsubscribeNotification } from 'helpers';
import i18n from 'i18n';
import { useAuth } from 'services/auth';
import { useModal, useRequests } from 'services/customHooks';
import { deactiveteMe } from 'services/commonService';
import RequestService from 'services/requestApi';
import useStyles from '../SettingsPageStyle';

const DELAY_BEFORE_LOGOUT = 7000;

const DeleteUser = () => {
  const t = getTranslateFunction();
  const classes = useStyles();

  const [currentStage, setCurrentStage] = useState<'init' | 'confirm' | 'update'>('init');
  const [codeCreationTime, setCodeCreationTime] = useState(0);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const { sendRequest, sendCode, attempts, isAttemptsForCodeLeft } = useRequests();
  const { isModalVisible, showModal } = useModal();
  const { setIsLoading, user, setUser, setVendor, setVendors } = useAuth();
  const userToDelete = `delete-user-${user?.id}`;
  const generateType = 'delete';

  const initDeleteUser = async () => {
    try {
      setCurrentStage('confirm');
      setFormErrorMessage('');
      const data = { email: user?.email, lang: i18n.language, userId: user?.id, generateType, userToDelete };
      await sendRequest('/users/create-profile-validation', data);
      setCodeCreationTime(new Date().getTime());
    } catch (error) {
      const e = error as Error;
      setFormErrorMessage(e.message);
    }
  };

  const confirmVerificationCode = async (emailCode: string) => {
    const data = {
      email: user?.email,
      emailCode: Number(emailCode),
      generateType,
      userToDelete,
    };

    const res = await sendCode('/users/check-profile-validation', data);

    if (!res) {
      return;
    }

    setCurrentStage('update');
  };

  const resetToInitialStage = () => {
    setCurrentStage('init');
  };

  const deleteUser = async () => {
    if (!user) return;

    setIsLoading(true);

    try {
      await unsubscribeNotification();
      await deactiveteMe(user.id);

      showModal();
      setCurrentStage('init');

      setTimeout(async () => {
        await new RequestService('/users/logout').post();

        localStorage.removeItem('uuid');
        setUser(null);
        setVendors([]);
        setVendor(null);
      }, DELAY_BEFORE_LOGOUT);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CollapseBox title={t('settings.deleteUser.title')} withoutMargins>
      {currentStage === 'init' && (
        <Box className={classes.updateProfileSubmitButtonWrapper}>
          <Button className={classes.updateProfileSubmitButton} onClick={initDeleteUser}>
            {t('settings.profile.update')}
          </Button>
        </Box>
      )}

      {currentStage === 'confirm' && (
        <>
          <ConfirmCode
            createdCodeTime={codeCreationTime}
            isAttemptsForCodeLeft={isAttemptsForCodeLeft}
            onSendCode={confirmVerificationCode}
            resendRequest={initDeleteUser}
            attempts={attempts}
          />

          <FormHelperText className={classes.updateProfileErrorMessage}>{t(formErrorMessage)}</FormHelperText>

          <Button className={classes.updateProfileCancelButton} onClick={resetToInitialStage}>
            {t('settings.profile.cancel')}
          </Button>
        </>
      )}

      {currentStage === 'update' && (
        <Box className={classes.btnContainer}>
          <Button className={classes.updateProfileCancelButton} onClick={resetToInitialStage}>
            {t('settings.profile.cancel')}
          </Button>

          <Button onClick={deleteUser} className={classes.deleteButton} type="submit">
            {t('settings.deleteUser.submit')}
          </Button>
        </Box>
      )}

      <Modal withoutBorder isOpen={isModalVisible}>
        <div className={classes.updateProfileModal}>{t('settings.updateProfile.ok')}</div>
      </Modal>
    </CollapseBox>
  );
};

export default DeleteUser;
