import { makeStyles } from '@material-ui/core';
import {
  chatBackgroundColor,
  cyan,
  green,
  recieverMessageColor,
  red,
  senderMessageColor,
  textColor,
} from '../../../constants';

export default makeStyles({
  leftMsg: {
    textAlign: 'left',
    margin: '10px',
    '& > *': {
      border: `${green} 1px solid`,
      backgroundColor: recieverMessageColor,
    },
  },
  rightMsg: {
    textAlign: 'right',
    margin: '10px',
    '& > *': {
      border: `${cyan} 1px solid`,
      backgroundColor: senderMessageColor,
      textAlign: 'left',
    },
  },
  message: {
    width: '90%',
    boxSizing: 'border-box',
    position: 'relative',
    display: 'inline-block',
    padding: '8px',
    color: textColor,
    fontSize: '12px',
    margin: '3px 0',
    paddingBottom: '40px',
  },
  messageText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'break-spaces',

    color: textColor,
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  messageIndicator: {
    position: 'absolute',
    display: 'flex',
    width: '30px',
    height: '30px',
    bottom: -1,
    backgroundColor: chatBackgroundColor,
  },
  burger: {
    width: '20px',
    height: '2px',
    margin: 'auto',
    backgroundColor: cyan,
    display: 'flex',

    '&::after, &::before': {
      content: '""',
      width: '20px',
      height: '2px',
      position: 'absolute',
      backgroundColor: cyan,
    },

    '&::after': {
      top: '6px',
    },
    '&::before': {
      top: '22px',
    },

    '&.isRead, &.isRead::after, &.isRead::before': {
      backgroundColor: green,
    },

    '&.isError, &.isError::after, &.isError::before': {
      backgroundColor: red,
    },
  },
  indicatorRightMsg: {
    right: -1,
    borderTop: `${cyan} 1px solid`,
    borderLeft: `${cyan} 1px solid`,
  },
  indicatorLeftMsg: {
    borderTop: `${green} 1px solid`,
    borderRight: `${green} 1px solid`,
    left: -1,
  },
  messageTime: {
    position: 'absolute',
    bottom: 0,
  },
  timeRightMsg: {
    right: 35,
  },
  timeLeftMsg: {
    left: 35,
  },
}, { index: 2 });
