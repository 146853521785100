import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';
import { LayoutInputSearchType } from 'interfaces';

interface LayoutProvider {
  searchInput: LayoutInputSearchType;
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  setSearchInput: Dispatch<SetStateAction<LayoutInputSearchType>>;
  shouldShowSearchInput: boolean;
  setShouldShowSearchInput: Dispatch<SetStateAction<boolean>>;
}

export const layoutContext = createContext<LayoutProvider>({} as LayoutProvider);

export const useLayout = (): LayoutProvider => useContext<LayoutProvider>(layoutContext);

export const useProvideLayout = (): LayoutProvider => {
  const [title, setTitle] = useState('');
  const [searchInput, setSearchInput] = useState<LayoutInputSearchType>('');
  const [shouldShowSearchInput, setShouldShowSearchInput] = useState(false);

  return {
    title,
    searchInput,
    setSearchInput,
    setTitle,
    shouldShowSearchInput,
    setShouldShowSearchInput,
  };
};
