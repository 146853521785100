import { Box, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import LocationList from 'components/LocationList';
import { dateFormatter } from 'utils/formatters';
import { getTranslateFunction } from 'helpers';
import { IComment, IOrder, RatingType } from 'interfaces';
import Icons from 'components/Icons';
import useStyles from '../OrderPageStyle';
import {
  cyan,
  minutes,
  orange,
} from '../../../constants';

const {
  nowSvg,
  toTimeSvg,
  CommentTsx,
  StarTsx,
} = Icons;

interface Props {
  order: IOrder;
  userComments: IComment[];
  userRating: RatingType;
}

const OrderInfoContainer: React.FC<Props> = ({
  order,
  userComments,
  userRating,
}: Props) => {
  const t = getTranslateFunction();
  const classes = useStyles();

  const tripTimeIcon = useMemo(() => (order.tripTime === 'now' ? nowSvg : toTimeSvg), [order]);
  const tripTimeText = useMemo(() => (
    order.tripTime === 'now' ? t('preorder.modal.nowLabel') : dateFormatter(Number(new Date(order.tripTime)))
  ), [order]);

  return (
    <Box className={classes.preorderSettings}>
      <Box className={classes.info}>
        <Box className={classes.services}>
          <Box className={classes.listItem}>
            <img
              className={classes.svg}
              src={Icons[`${order.paymentType}Svg`]}
              alt={t(`preorder.modal.${order.paymentType}Label`)}
            />
            <Typography variant="caption">{`${order.price} ${order.currency}`}</Typography>
          </Box>
          {order.time && (
          <Box className={classes.listItem}>
            <img
              className={classes.svg}
              src={tripTimeIcon}
              alt={tripTimeText}
            />
            <Typography variant="caption">{`${order.time} ${t(minutes)}`}</Typography>
          </Box>
          )}
          {userRating && (
          <>
            <Box className={classes.listItem}>
              <Box className={classes.svg}>
                <StarTsx color={orange} />
              </Box>
              <Typography variant="caption">{userRating.ratingAvg}</Typography>
            </Box>
            <Box className={classes.listItem}>
              <Box className={classes.svg}>
                <CommentTsx color={cyan} />
              </Box>
              <Typography variant="caption">{userComments.length}</Typography>
            </Box>
          </>
          )}
        </Box>
        <Box className={classes.preorderServices}>
          <Box className={classes.listItem}>
            {order.together && (
            <Box>
              <img
                className={classes.svg}
                src={Icons[`${order.together}Svg`]}
                alt={t(`preorder.modal.${order.together}Label`)}
              />
              <Typography variant="caption">{t(`preorder.modal.${order.together}Label`)}</Typography>
            </Box>
            )}
            {Boolean(order.passengers) && (
            <Box>
              <img
                className={classes.svg}
                src={Icons.individualSvg}
                alt={t('preorder.modal.passengers')}
              />
              <Typography variant="caption">{t('preorder.modal.passengers')}</Typography>
              <Typography className={classes.passengers} variant="caption">{order.passengers}</Typography>
            </Box>
            )}
          </Box>
          <Box className={classes.listItem}>
            <img
              className={classes.svg}
              src={tripTimeIcon}
              alt={tripTimeText}
            />
            <Typography variant="caption">{tripTimeText}</Typography>
          </Box>
          {order.baggage && (
          <Box className={classes.listItem}>
            <img
              className={classes.svg}
              src={Icons.baggageSvg}
              alt={t('preorder.modal.baggage')}
            />
            <Typography variant="caption">{t('preorder.modal.baggage')}</Typography>
          </Box>
          )}
          <LocationList
            dots={order.dots}
            classes={{
              dotBox: classes.listItem,
              svg: classes.svg,
            }}
            withIcon
          />
          {order.comment && (
          <Box className={classes.listItem}>
            <Box
              className={classes.svg}
            >
              <CommentTsx color={cyan} />
            </Box>
            <Typography variant="caption">{order.comment}</Typography>
          </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default OrderInfoContainer;
