import { Typography } from '@material-ui/core';
import React from 'react';
import { getTranslateFunction } from 'helpers';
import { RowValue } from 'interfaces';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '../Table';
import useStyles from '../TableStyles';

type TableConfig = {
  [key: string]: RowValue;
};

type Props = {
  tableConfig: [string, RowValue][] | TableConfig;
};

const TableVertical: React.FC<Props> = ({ tableConfig }: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();
  const config = Array.isArray(tableConfig)
    ? tableConfig
    : Object.entries(tableConfig);

  return (
    <Table>
      <TableBody>
        {config.map(([key, value]: [string, RowValue]) => (
          <TableRow key={key}>
            <TableCell className={classes.tableCellVertical}>
              <Typography>
                {t(key)}
              </Typography>
            </TableCell>
            <TableCell>
              {value && typeof value === 'object' ? (
                <Typography className={classes[value.className]}>{value.text}</Typography>
              ) : (
                <Typography>{value}</Typography>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TableVertical;
