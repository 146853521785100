import React from 'react';
import { Typography, Box } from '@material-ui/core';
import classNames from 'classnames';
import CommentsListItem from 'components/CommentsListItem/CommentsListItem';
import PreorderInfoComponent from 'components/PreorderInfoComponent';
import VendorInfoItem from 'components/VendorInfoComponent/VendorInfoItem';
import { ConfirmCancelOfferModal } from 'pages/OrderPage/OrderModal';
import Icons from 'components/Icons';
import {
  IOnChange,
  IOrder,
  RatingType,
  User,
  Vendor,
} from 'interfaces';
import { getTranslateFunction } from 'helpers';
import { ClientRatingModal, OrderInfoModal, VendorInfoModal } from '../ClientOrderPageModal';
import { orange, cyan } from '../../../constants';
import useStyles from '../ClientOrderPageStyle';

interface Props {
  ratingModal: boolean;
  order: IOrder;
  onChange: (a: IOnChange) => void;
  vendor: Vendor;
  vendorRating: RatingType | undefined;
  vendorComments: any;
  vendorUser: User;
  ratingAvg: number;
  handleChange: (a: string, b?: any) => void;
  modalOrderInfo: boolean;
  modalVendorInfo: boolean;
  cancelModalOrder: boolean;
  cancelSuggestions: string[];
}

const {
  StarTsx,
  CommentTsx,
} = Icons;

const OrderModals: React.FC<Props> = ({
  ratingModal,
  order,
  onChange,
  vendor,
  vendorRating,
  vendorComments,
  vendorUser,
  ratingAvg,
  handleChange,
  modalOrderInfo,
  modalVendorInfo,
  cancelModalOrder,
  cancelSuggestions,
}: Props) => {
  const t = getTranslateFunction();
  const classes = useStyles();

  return (
    <>
      {ratingModal && (
        <ClientRatingModal
          tripType={order.tripType}
          type={order.tripRequirements}
          onChange={onChange}
          id={order.id}
        >
          <VendorInfoItem
            t={t}
            vendor={vendor}
            vendorUser={vendorUser}
            order={order}
            ratingAvg={ratingAvg}
            userRating={vendorRating}
            commentsLength={vendorComments.length}
          />
          <Typography
            variant="h5"
            onClick={() => handleChange('handleChatToVendor')}
            className={classNames([classes.ratingTitle, 'cyan'])}
          >
            {t('ClientOrderPage.sendMessageToVendor')}
          </Typography>
        </ClientRatingModal>
      )}

      {modalOrderInfo && (
        <OrderInfoModal
          modal={modalOrderInfo}
          onClose={() => handleChange('handleOrderInfo')}
        >
          <PreorderInfoComponent
            preorder={order}
            isBuyMode={order.tripType === 'buyThings'}
          />
        </OrderInfoModal>
      )}

      {modalVendorInfo && (
        <VendorInfoModal
          modal={modalVendorInfo}
          onClose={() => handleChange('handleVendorInfo')}
          head={(
            <Box className={classes.titleCommentsBox}>
              <Box className={classes.statsList}>
                <Box className={classes.svg}>
                  <StarTsx color={orange} />
                </Box>
                <Typography>{ratingAvg}</Typography>
                <Box className={classes.svg}>
                  <CommentTsx color={cyan} />
                </Box>
                <Typography>{vendorComments.length}</Typography>
              </Box>
              <Typography className={classes.titleComments}>{t('ClientOrderPage.Comments')}</Typography>
            </Box>
              )}
        >
          {vendorComments.map((item: any) => (
            <CommentsListItem commentsLength={vendorComments.length} page="client" item={item} key={item.id} />
          ))}
        </VendorInfoModal>
      )}

      {cancelModalOrder && (
        <ConfirmCancelOfferModal
          array={cancelSuggestions}
          isOpen={cancelModalOrder}
          handleConfirm={(message) => handleChange('cancelOrder', message)}
          cancelModal={() => handleChange('handleChangeDeclineModal')}
        />
      )}
    </>
  );
};

export default OrderModals;
