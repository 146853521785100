import React, { FC } from 'react';
import { IconComponentProps } from 'interfaces';

const ArrowRight: FC<IconComponentProps> = ({ color, width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
  >
    <path fill={color} d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z" />
  </svg>
);

export default ArrowRight;
