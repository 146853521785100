import React from 'react';
import { Box, Typography } from '@material-ui/core';
import buyIcon from 'images/icons/buy.svg';
import alongTheWayIcon from 'images/icons/alongTheWay.svg';
import courierIcon from 'images/icons/courier.svg';
import helpOnRoadIcon from 'images/icons/helpOnRoad.svg';
import rentalIcon from 'images/icons/rental.svg';
import servicesIcon from 'images/icons/services.svg';
import { getTranslateFunction } from 'helpers';
import {
  groupByService,
  services,
} from 'utils/optionsConfig';
import useStyles from '../ClientHomePageStyle';

interface Props {
  onChange: (a: string) => void;
}

const icons: {
  [key: string]: string;
} = {
  buyIcon,
  alongTheWayIcon,
  courierIcon,
  helpOnRoadIcon,
  rentalIcon,
  servicesIcon,
};

const ClientHomePage: React.FC<Props> = ({
  onChange,
}: Props) => {
  const t = getTranslateFunction();
  const classes = useStyles();
  const listData = groupByService(services, 'type');

  return (
    <Box className={classes.optionsContainer}>
      {Object.entries(listData).map(([key, item]) => {
        const [{icon}] = item;
        return (
          <Box
            data-home-options-btn={key}
            className={classes.optionLinkContainer}
            key={key}
            onClick={() => onChange(key)}
          >
            <img src={icons[icon]} alt={key} />
            <Box className={classes.optionLink}>
              <Typography variant="body2">{t(key)}</Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ClientHomePage;
