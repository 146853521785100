import { Button, Box, Avatar, Typography, FormHelperText } from '@material-ui/core';
import classNames from 'classnames';
import { Clear, PhotoCamera } from '@material-ui/icons';
import InfoToggle from 'components/InfoToggle/InfoToggle';
import ImageUpploader from 'components/ImageUpploader';
import Preloader from 'components/Preloader/Preloader';
import { useState } from 'react';
import Modal from 'sharedComponents/Modal';
import { getTranslateFunction, getUserImageLink } from 'helpers';
import type { IOnChange, User } from 'interfaces';
import { type HandleUpdateUserFile } from '../containers/UserInfo';
import useStyles from '../ProfilePageStyle';

type Props = {
  user: User,
  isVerified: boolean,
  onChange: (a: IOnChange) => void,
  handleUpdateUserFile: HandleUpdateUserFile;
  menuDescription: string,
};

const ProfileAvatar = ({
  user,
  isVerified,
  onChange,
  handleUpdateUserFile,
  menuDescription,
}: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();
  const { userPhoto, userPhotoUpdate, firstName, gender, lastName } = user || {};
  const [isAvatarOpen, setIsAvatarOpen] = useState(false);

  const isUserPhotoExist = Boolean(userPhoto);
  const avatarId = userPhotoUpdate || userPhoto;
  const avatarSrc = getUserImageLink(avatarId, gender);
  const avatarAlt = `${firstName} ${lastName}`;

  const handleOpenAvatar = () => {
    if (isUserPhotoExist) {
      setIsAvatarOpen(true);
    }
  };

  const handleRemoveAvatar = () => {
    onChange({
      action: 'removeAvatar',
    });
  };

  const updateUserPhoto = (files: FileList | null) => {
    handleUpdateUserFile(files, 'updateUserPhoto');
  };

  const shouldShowDeleteBtn = isUserPhotoExist && isVerified;
  const shouldShowMenu = Boolean(menuDescription?.length);
  const shouldShowPreloader = !isVerified && avatarId !== null;

  return (
    <Box>
      <Box className={classes.avatarBox}>
        <div style={{ position: 'relative' }}>
          <Avatar
            className={classes.avatar}
            variant='square'
            src={avatarSrc}
            alt={avatarAlt}
            onClick={handleOpenAvatar}
          />
          {shouldShowPreloader && <Preloader alwaysOnTop={false} />}
        </div>

        <Box className={classes.avatarText}>
          <Typography className={classes.avatarLabel}>{t('profilePage.avatar')}</Typography>

          <Box className={classes.btnWrapper}>
            <span className={classNames(classes.uploadText, classes.textNoWrap)}>
              {t('profilePage.photo.requirements')}
            </span>

            <Button variant='text' component='label' color='primary' className={classes.uploadBtn}>
              <PhotoCamera />
              <ImageUpploader show={false} onChange={updateUserPhoto} />
            </Button>
          </Box>

          {shouldShowDeleteBtn && (
            <Box className={classNames(classes.btnWrapper, classes.clearBtnWrapper)}>
              <span className={classNames(classes.clearText, classes.textNoWrap)}>
                {t('profilePage.photoDelete.label')}
              </span>

              <Button className={classes.clearAvatarBtn} onClick={handleRemoveAvatar}>
                <Clear className={classes.clearAvatar} />
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      <FormHelperText className={classNames('MuiFormHelperText-root', 'Mui-error')}>
        {t('profilePage.photoNotRequired')}
      </FormHelperText>

      {shouldShowMenu && <InfoToggle description={menuDescription} btnLabel='profilePage.infoToggle.btn' />}

      <Modal withoutBorder isOpen={isAvatarOpen} onClose={() => setIsAvatarOpen(false)}>
        <Avatar className={classes.avatarFull} variant='square' src={avatarSrc} alt={avatarAlt} />
      </Modal>
    </Box>
  );
};

export default ProfileAvatar;
