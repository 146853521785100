import ClientHomePageComponent from 'pages/ClientHomePage/components/ClientHomePageComponent';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from 'services/auth';

const ProjectsPage: React.FC = () => {
  const { vendorId } = useAuth();
  const history = useHistory();

  const handleChange = useCallback((key: string) => {
    const to = `/projects/${key}`;

    history.push(to);
  }, [vendorId]);

  return (
    <ClientHomePageComponent onChange={handleChange} />
  );
};

export default ProjectsPage;
