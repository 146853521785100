import { Box, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './CustomPageStyle';

interface Props {
  content: string;
  header: string;
}

const CustomPage: React.FC<Props> = ({ content, header }) => {
  const classes = useStyles();

  return (
    <Box className={classes.pageContainer}>
      <Typography variant="h2">{header}</Typography>
      <Box dangerouslySetInnerHTML={{ __html: content }} />
    </Box>
  );
};

export default CustomPage;
