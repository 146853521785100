import React, { FC } from 'react';
import { IconComponentProps } from 'interfaces';

const Star: FC<IconComponentProps> = ({ color, width = 20, height = 20 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 72 72">
    <defs />
    <path fill={color} d="M36 5l10.1 20.4 22.5 3.3-16.3 15.9L56.1 67 36 56.4 15.9 67l3.8-22.4L3.4 28.7l22.5-3.3z" />
  </svg>

);

export default Star;
