import React, { useCallback, useState } from 'react';
import {
  RatingArrayItem,
  Preorder,
  PreorderService,
  User,
  Vendor,
} from 'interfaces';
import PreorderOfferComponent from '../components/PreorderOfferComponent';

interface Props {
  offers: PreorderService[];
  preorder: Preorder;
  users: User[];
  vendors: Vendor[];
  vendorsRating: RatingArrayItem[];
  vendorsComments: any;
  updatePreorder: (newPreorder: Preorder) => void;
  editPreorder: (newPreorder: Preorder) => void;
  onChange: (a: any) => void;
}

const PreorderOffersApp: React.FC<Props> = ({
  preorder,
  offers,
  users,
  vendors,
  vendorsRating,
  vendorsComments,
  updatePreorder,
  editPreorder,
  onChange,
}) => {
  const [cancelModalPreorder, setCancelModalPreorder] = useState(false);

  const createPreorderToDTO = (data: any) => {
    const result = data;
    const newTripTime = result.tripTime === 'now' ? result.tripTime : 'toTime';
    const newTripTimeDate = result.tripTime !== 'now' && result.tripTime;

    return {
      ...result,
      tripTime: newTripTime,
      tripTimeDate: newTripTimeDate,
    };
  };

  const handleEditPricePreorder = useCallback((price) => {
    updatePreorder(createPreorderToDTO({ ...preorder, price }));
  }, [preorder]);

  const handleEditPreorder = useCallback(() => {
    editPreorder(createPreorderToDTO(preorder));
  }, [preorder]);

  function handleConfirm(offer: PreorderService) {
    onChange({
      action: 'createOrder',
      data: {
        preorderServiceId: offer.preorderServiceId,
      },
    });
  }

  const declinePreorder = ({
    preorderServiceId,
    preorderId,
    serviceId,
  }: PreorderService) => {
    onChange({
      action: 'declinePreorder',
      data: {
        roomName: preorderId.toString(),
        vendorId: serviceId,
        preorderServiceId,
      },
    });
  };

  function cancelPreorder() {
    onChange({
      action: 'cancelPreorder',
      data: preorder,
    });
  }

  const handleChangeModal = useCallback(() => {
    setCancelModalPreorder(!cancelModalPreorder);
  }, [cancelModalPreorder]);

  return (
    <PreorderOfferComponent
      offers={offers}
      preorder={preorder}
      users={users}
      vendors={vendors}
      vendorsRating={vendorsRating}
      vendorsComments={vendorsComments}
      cancelModalPreorder={cancelModalPreorder}
      handleConfirm={handleConfirm}
      declinePreorder={declinePreorder}
      handleChangeModal={handleChangeModal}
      handleEditPreorder={handleEditPreorder}
      handleEditPricePreorder={handleEditPricePreorder}
      cancelPreorder={cancelPreorder}
    />
  );
};

export default PreorderOffersApp;
