import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  FormControl,
  RadioGroup,
  Typography,
  Box,
  FormControlLabel,
  Radio,
  FormHelperText,
} from '@material-ui/core';
import classNames from 'classnames';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  Controller,
  UseFormMethods,
} from 'react-hook-form';
import MaleTsx from 'images/male';
import FemaleTsx from 'images/female';
import SelectGeolocation from 'sharedComponents/SelectGeolocation';
import Input from 'sharedComponents/Input';
import TextField from 'sharedComponents/TextField';
import DatePicker from 'sharedComponents/DatePicker';
import { dateRegisterFormatter } from 'utils/formatters';
import { ICity, ICountry, Suggestion } from 'interfaces';
import COUNTRIES from 'services/countriesList';
import icons from 'components/Icons';
import { getTranslateFunction } from 'helpers';
import { indexStyles } from '../material';
import { cyan } from '../../../constants';

const filter = createFilterOptions<ICity>();

const MAX_DATE = dateRegisterFormatter(new Date());

const countriesList = COUNTRIES.sort((item1, item2) => item1.label.localeCompare(item2.label, 'en'));

const {
  dateBirthdaySvg,
  markerSvg,
  inCity,
  international,
  numberSvg,
} = icons;

interface Fields {
  gender: string;
  birthday: Date | null;
  country: null | ICountry;
  city: ICity | null;
  placeOfResidence: string;
  passportType: string;
  numberOfPassport: string;
}

type Props = Pick<UseFormMethods<Fields>, 'register' | 'errors' | 'control' | 'setValue' | 'clearErrors' | 'getValues'>;

const UserInfoBlock: React.FC<Props> = ({
  control,
  errors,
  register,
  setValue,
  clearErrors,
  getValues,
}) => {
  const classes = indexStyles();
  const t = getTranslateFunction();
  const [cities, setCities] = useState<ICity[]>([]);
  const [placeOfResidenceMapActive, setPlaceOfResidenceMapActive] = useState(false);

  const handleClosePlaceOfResidenceMap = useCallback(() => {
    setPlaceOfResidenceMapActive(false);
    const container = document.querySelector<HTMLDivElement>('#clientFormStepContainer');
    if (container) {
      container.style.overflow = '';
    }
  }, []);

  const handleFocusPlaceOfResidence = () => {
    clearErrors('placeOfResidence');
    setPlaceOfResidenceMapActive(true);
    const container = document.querySelector<HTMLDivElement>('#clientFormStepContainer');
    if (container) {
      container.scrollTo({ top: 0 });
      container.style.overflow = 'hidden';
    }
  };

  const onMapChange = (item: Suggestion) => {
    setValue('placeOfResidence', item.label);
    handleClosePlaceOfResidenceMap();
  };

  const handleChangeCountry = (countryValue: ICountry) => {
    if (countryValue) {
      setCities(countryValue.cities);
      setValue('city', '');
    }
  };

  useEffect(() => {
    const countryValue = getValues('country');

    if (countryValue) {
      setCities(countryValue.cities);
    }
  }, []);

  return (
    <>
      <FormControl className={classes.formControl} error={!!errors.gender}>
        <Controller
          as={(
            <RadioGroup name="gender" className={classNames(classes.radioGroup, 'required')}>
              <Typography className={classes.label}>{t('signInPage.genderLabel')}</Typography>
              <Box className={`${classes.radioBox} gender`}>
                <FormControlLabel
                  className={classes.label}
                  value="male"
                  control={(
                    <Radio
                      color="default"
                      className={classes.radio}
                      icon={<div className={classes.icon} />}
                      checkedIcon={<div className={classes.checkedIcon} />}
                    />
                  )}
                  labelPlacement="start"
                  label={(
                    <Box className={classes.iconAdornment}>
                      <MaleTsx color={cyan} />
                    </Box>
                  )}
                />
                <FormControlLabel
                  className={classes.label}
                  value="female"
                  control={(
                    <Radio
                      color="default"
                      className={classes.radio}
                      icon={<div className={classes.icon} />}
                      checkedIcon={<div className={classes.checkedIcon} />}
                    />
                  )}
                  labelPlacement="start"
                  label={(
                    <Box className={classes.iconAdornment}>
                      <FemaleTsx color={cyan} />
                    </Box>
                  )}
                />
              </Box>
            </RadioGroup>
          )}
          name="gender"
          control={control}
        />
        <FormHelperText id="component-error-text">{errors.gender?.message}</FormHelperText>
      </FormControl>

      <FormControl className={classes.formControl} error={!!errors.birthday}>
        <Controller
          name="birthday"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'error.validation.required',
            },
          }}
          render={({ value, onChange }) => (
            <DatePicker
              value={value}
              initialFocusedDate={new Date(MAX_DATE)}
              onChange={onChange}
              format="dd/MM/yyyy"
              openTo="year"
              views={['year', 'month', 'date']}
              placeholder={t('signInPage.birthdayLabel')}
              maxDate={new Date(MAX_DATE)}
              maxDateMessage={t('signInPage.birthday.maxDateError')}
              minDateMessage={t('signInPage.birthday.minDateError')}
              errorMessage={errors.birthday?.message && t(errors.birthday?.message)}
              invalidDateMessage={t('signInPage.birthday.invalidDateMessage')}
              icon={<img src={dateBirthdaySvg} alt={t('signInPage.birthdayLabel')} className={classes.iconAdornment} />}
            />
          )}
        />
      </FormControl>

      <FormControl
        className={classes.formControl}
        error={!!errors.country}
      >
        <Controller
          control={control}
          name="country"
          rules={{
            required: {
              value: true,
              message: 'error.validation.required',
            },
          }}
          render={({ value, onChange }) => (
            <Autocomplete
              onChange={(_, data) => {
                onChange(data);
                handleChangeCountry(data);
                clearErrors('country');
              }}
              onFocus={() => clearErrors('country')}
              options={countriesList}
              autoHighlight
              className={classNames(classes.countryPicker, 'required')}
              getOptionLabel={(option) => option.label}
              value={value}
              freeSolo
              renderOption={(option) => option.label}
              renderInput={(option) => (
                <TextField
                  InputLabelProps={option.InputLabelProps}
                  disabled={option.disabled}
                  fullWidth={option.fullWidth}
                  className={classes.autocomplete}
                  placeholder={t('signInPage.countryLabel')}
                  errorMessage={errors.country?.message && t(errors.country?.message)}
                  InputProps={{
                    ...option.InputProps,
                    disableUnderline: true,
                    inputProps: {
                      ...option.inputProps,
                      autoComplete: 'new-password',
                    },
                    startAdornment: (
                      <img src={international} alt={t('signInPage.countryLabel')} className={classes.iconAdornment} />
                    ),
                  }}
                />
              )
              }
            />
          )}
        />
      </FormControl>

      <FormControl
        className={classes.formControl}
        error={!!errors.city}
      >
        <Controller
          control={control}
          name="city"
          rules={{
            required: {
              value: true,
              message: 'error.validation.required',
            },
          }}
          render={({ value, onChange }) => (
            <Autocomplete
              options={cities}
              onChange={(_, data) => {
                onChange(data);
                clearErrors('city');
              }}
              autoHighlight
              onFocus={() => clearErrors('city')}
              value={value}
              freeSolo
              className={classNames(classes.countryPicker, 'required')}
              getOptionLabel={(option: ICity) => option.label || ''}
              renderOption={(option: ICity) => option.label || ''}
              filterOptions={(options: ICity[], params) => {
                const filtered = filter(options, params);
                const isExisted = filtered.some(({ label }) => (
                  label.toLowerCase().includes(params.inputValue.toLowerCase())
                ));

                if (params.inputValue && !isExisted) {
                  filtered.push({
                    id: 0,
                    label: params.inputValue,
                  });
                }

                return filtered;
              }}
              renderInput={(option) => (
                <TextField
                  InputLabelProps={option.InputLabelProps}
                  disabled={option.disabled}
                  fullWidth={option.fullWidth}
                  className={classes.autocomplete}
                  placeholder={t('signInPage.cityLabel')}
                  errorMessage={errors.city?.message && t(errors.city?.message)}
                  InputProps={{
                    ...option.InputProps,
                    disableUnderline: true,
                    inputProps: {
                      ...option.inputProps,
                      autoComplete: 'new-password',
                    },
                    startAdornment: (
                      <img src={inCity} alt={t('signInPage.cityLabel')} className={classes.iconAdornment} />
                    ),
                  }}
                />
              )}
            />
          )}
        />
      </FormControl>

      <FormControl
        className={classNames(classes.formControl, classes.positionStatic)}
        error={!!errors.placeOfResidence}
      >
        <Controller
          name="placeOfResidence"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'error.validation.required',
            },
          }}
          render={({ value, onChange }) => (
            <>
              <TextField
                className={classNames(classes.textField, 'required')}
                placeholder={t('signInPage.PlaceOfResidence')}
                onFocus={handleFocusPlaceOfResidence}
                value={value}
                onChange={onChange}
                errorMessage={errors.placeOfResidence?.message
                  && t(errors.placeOfResidence?.message)}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <img src={markerSvg} alt={t('signInPage.PlaceOfResidence')} className={classes.iconAdornment} />
                  ),
                }}
                type="text"
              />

              {placeOfResidenceMapActive && (
                <SelectGeolocation
                  onChange={onMapChange}
                  onCancel={handleClosePlaceOfResidenceMap}
                  title={t('signInPage.PlaceOfResidence')}
                  defaultValue={value}
                />
              )}
            </>
          )}
        />
      </FormControl>

      <FormControl className={classes.formControl} error={!!errors.passportType}>
        <Controller
          as={(
            <RadioGroup name="passportType" className={classNames(classes.radioGroup, 'required')}>
              <Box className={classes.radioBox}>
                <FormControlLabel
                  className={classes.label}
                  value="old"
                  control={(
                    <Radio
                      color="default"
                      className={classes.radio}
                      icon={<div className={classes.icon} />}
                      checkedIcon={<div className={classes.checkedIcon} />}
                    />
                  )}
                  labelPlacement="start"
                  label={t('signInPage.oldPassport')}
                />
                <FormControlLabel
                  className={classes.label}
                  value="id"
                  control={(
                    <Radio
                      color="default"
                      className={classes.radio}
                      icon={<div className={classes.icon} />}
                      checkedIcon={<div className={classes.checkedIcon} />}
                    />
                  )}
                  labelPlacement="start"
                  label={t('signInPage.idPassport')}
                />
              </Box>
            </RadioGroup>
          )}
          name="passportType"
          control={control}
        />
        {errors.passportType?.message && (
          <FormHelperText id="component-error-text">{t(errors.passportType?.message)}</FormHelperText>
        )}
      </FormControl>

      <FormControl className={classes.formControl} error={!!errors.numberOfPassport}>
        <Input
          className={classNames(classes.textField, 'required')}
          placeholder={t('signInPage.numberOfPassport')}
          disableUnderline
          startAdornment={<img src={numberSvg} alt={t('signInPage.numberOfPassport')} className={classes.iconAdornment} />}
          type="text"
          name="numberOfPassport"
          onChange={() => clearErrors('numberOfPassport')}
          onFocus={() => clearErrors('numberOfPassport')}
          inputRef={register({
            required: {
              value: true,
              message: 'error.validation.required',
            },
            pattern: {
              value: /^[a-zA-Zа-яА-ЯА-ЩЬЮЯҐЄІЇа-щьюяґєії0-9 -]*$/,
              message: 'error.validation.numberOfPassport.invalid',
            },
            minLength: {
              value: 5,
              message: 'error.validation.numberOfPassport.minLength',
            },
            maxLength: {
              value: 15,
              message: 'error.validation.numberOfPassport.maxLength',
            },
          })}
          errorMessage={errors.numberOfPassport?.message && t(errors.numberOfPassport?.message)}
        />
      </FormControl>
    </>
  );
};

export default UserInfoBlock;
