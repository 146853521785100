import React from 'react';
import { IconComponentProps } from 'interfaces';

const Delete: React.FC<IconComponentProps> = ({ color, width = 20, height = 20 }) => (
  <svg
    width={width}
    height={height}
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 72 72"
  >
    <path
      fill={color}
      d="M36,3.94C18.29,3.94,3.94,18.29,3.94,36S18.29,68.06,36,68.06c17.71,0,32.06-14.36,32.06-32.06
  S53.71,3.94,36,3.94z M52.64,49.44h-9.02L36,40.99l-7.63,8.45h-9.02L31.49,36L19.36,22.56h9.02L36,31.01l7.63-8.45h9.02L40.51,36
  L52.64,49.44z"
    />
  </svg>
);

export default Delete;
