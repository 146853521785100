import React, { FC } from 'react';

interface Props {
  color: string;
}

const Transactions: FC<Props> = ({ color }) => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 72 72" fill={color}>
    <path d="M12.53 41.06c-6.25.01-11.33 5.11-11.32 11.36.01 6.27 5.09 11.36 11.32 11.36 6.31 0 11.38-5.09 11.37-11.41-.01-6.25-5.11-11.32-11.37-11.31zm-2.22 18.1c-1.85-1.86-3.74-3.76-5.58-5.62.66-.66 1.4-1.4 2.16-2.15 1.05 1.06 2.18 2.19 3.31 3.33 2.71-2.72 5.37-5.38 8.02-8.05.8.82 1.54 1.58 2.27 2.32-3.39 3.39-6.81 6.8-10.18 10.17z" />
    <path className="st0" d="M6.92 12.05v26.79h5.69v-8.22H65.1v21.54H25.81v5.56h44.98V12.05H6.92zm5.69 11.48v-5.92H65.1v5.92H12.61z" />
    <path className="st0" d="M46.21 42.47h14.75v5.56H46.21z" />
  </svg>
);

export default Transactions;
