import { makeStyles } from '@material-ui/core/styles';
import { green } from '../../constants';

export default makeStyles(
  {
    withBorder: {
      border: `4px ${green} solid`,
      boxShadow:
        '0px 11px 29px -7px rgb(0 0 0 / 30%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
    },
    modal: {
      padding: '10px',
    },
    withoutPadding: {
      padding: 0,
    },
  },
  { index: 1 },
);
