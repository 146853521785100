import React, { FC } from 'react';

interface Props {
  color: string;
}

const inCity: FC<Props> = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 72"
  >
    <path
      d="M52.26 22.75v41.32h-1.61V9.25h-3.5V6.2h-23.2v3.05h-3.5v54.82h-1.61V30.04H2.28V68H31.72v-.05h.47V57.33h6.74v10.62h1.59V68h28.32V22.75H52.26zm-38.7 12.56h3.4v5.08h-3.4v-5.08zm0 6.53h3.4v5.08h-3.4v-5.08zm0 6.49h3.4v5.08h-3.4v-5.08zm0 6.43h3.4v5.08h-3.4v-5.08zm-4.7-19.4h3.4v5.08h-3.4v-5.08zm0 6.53h3.4v5.08h-3.4v-5.08zm0 6.49h3.4v5.08h-3.4v-5.08zm0 6.43h3.4v5.08h-3.4v-5.08zm-4.7-19.5h3.4v5.08h-3.4v-5.08zm0 6.53h3.4v5.08h-3.4v-5.08zm0 6.49h3.4v5.08h-3.4v-5.08zm0 6.43h3.4v5.08h-3.4v-5.08zm19.8-40.23h23.2v5.34h-23.2v-5.34zm0 8.17h23.2v5.34h-23.2V22.7zm0 8.17h23.2v5.34h-23.2v-5.34zm0 8.16h23.2v5.34h-23.2v-5.34zm0 13.52V47.2h23.2v5.34h-23.2zm37.53-26h4.91v5.08h-4.91v-5.08zm0 7.29h4.91v5.08h-4.91v-5.08zm0 7.32h4.91v5.08h-4.91v-5.08zm0 7.3h4.91v5.08h-4.91v-5.08zm0 7.29h4.91v5.08h-4.91v-5.08zm-6.79-29.2h4.91v5.08H54.7v-5.08zm0 7.29h4.91v5.08H54.7v-5.08zm0 7.32h4.91v5.08H54.7v-5.08zm0 7.3h4.91v5.08H54.7v-5.08zm0 7.29h4.91v5.08H54.7v-5.08z"
      fill={color}
    />
  </svg>
);

export default inCity;
