import React from 'react';
import { Input } from '@material-ui/core';
import classNames from 'classnames';
import { indexStyles } from 'pages/SignUpPage/material';
import { getTranslateFunction } from 'helpers';
import emailSvg from 'images/icons/email.svg';

interface Props {
  onChange: (value: string) => void;
  value: string;
  onBlur: () => void;
  onFocus: () => void;
  readOnly?: boolean;
  className?: string;
}

const EmailInput: React.FC<Props> = ({
  onChange,
  className,
  onBlur,
  onFocus,
  value,
  readOnly,
}: Props) => {
  const t = getTranslateFunction();
  const styles = indexStyles();

  return (
    <Input
      className={classNames(styles.textField, className)}
      placeholder={t('signInPage.emailLabel')}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
      onFocus={onFocus}
      readOnly={readOnly}
      disableUnderline
      startAdornment={<img src={emailSvg} alt="phone" className={styles.iconAdornment} />}
      autoComplete='new-password'
    />
  );
};

export default EmailInput;

EmailInput.defaultProps = {
  className: '',
  readOnly: false,
};
