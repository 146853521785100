import { Avatar, Box, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import Icons from 'components/Icons';
import {
  getUsers,
  getClientRating,
} from 'services/commonService';
import userLogo from 'components/common/userLogo.png';
import { getTranslateFunction, getUserImageLink } from 'helpers';
import { IComment, RatingType, User } from 'interfaces';
import useStyles from './CommentsListItemStyle';
import { cyan, orange } from '../../constants';

interface Props {
  item: IComment;
  page: string;
  commentsLength: number;
}

const { CommentTsx, StarTsx } = Icons;

const CommentsListItem: React.FC<Props> = ({ item, page, commentsLength }) => {
  const t = getTranslateFunction();
  const [user, setUser] = useState<User>(null);
  const [userRating, setUserRating] = useState<RatingType>();
  const classes = useStyles();

  useEffect(() => {
    if (page === 'client') {
      getUsers(item.vendorUserUuid).then((res) => setUser(res[0]));
    } else {
      getUsers(item.userUuid).then((res) => setUser(res[0]));
    }
  }, [item, page]);

  useEffect(() => {
    if (user) {
      getClientRating(user.uuid).then(setUserRating);
    }
  }, [user]);

  const photoSrc = useMemo(() => (user && getUserImageLink(user.userPhoto, user.gender)), [user]);

  if (!(user && userRating)) {
    return null;
  }

  return (
    <Box className={classes.commentBox} key={item.id}>
      <Typography>{`${user.firstName} ${t('ClientPage.appreciated')}`}</Typography>
      <Box className={classes.userBox}>
        <Box>
          <Avatar variant="rounded" className={classes.img} src={photoSrc || userLogo} alt={user.firstName} />
          <Box className={classes.statsList}>
            <Box>
              <Box className={classes.svg}>
                <StarTsx color={orange} />
              </Box>
              <Typography>{Number(userRating.politenessRatingAvg)}</Typography>
            </Box>
            <Box>
              <Box className={classes.svg}>
                <CommentTsx color={cyan} />
              </Box>
              <Typography>{commentsLength}</Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.commentDiv}>
          <Box className={classes.statsBox}>
            {Object.keys(item).includes('politenessRating') && (
              <Box>
                <Typography>{t('preorder.modal.politeness')}</Typography>
                <Typography>{Math.round(Number(item.politenessRating))}</Typography>
              </Box>
            )}
            {Object.keys(item).includes('vehicleConditionRating') && (
              <Box>
                <Typography>{t('preorder.modal.vehicleCondition')}</Typography>
                <Typography>{Math.round(Number(item.vehicleConditionRating))}</Typography>
              </Box>
            )}
            {Object.keys(item).includes('drivingSkillRating') && (
              <Box>
                <Typography>{t('preorder.modal.drivingSkill')}</Typography>
                <Typography>{Math.round(Number(item.drivingSkillRating))}</Typography>
              </Box>
            )}
          </Box>
          {Boolean(item.comment) && (
            <Typography className={classes.comment}>{item.comment}</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CommentsListItem;
