import { makeStyles } from '@material-ui/core';
import { grayBG } from '../../constants';

export default makeStyles(
  {
    text: {
      backgroundColor: grayBG,
      width: '100%',
      padding: '20px',
      boxSizing: 'border-box',
    },
    galleryWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  { index: 1 },
);
