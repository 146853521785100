import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import i18n from 'i18n';
import {
  UserSettings,
  RingtoneType,
  UserSettingsResponse,
  User,
  SosRoute,
  Lang,
  TShowNotification,
  UserStatusType,
} from 'interfaces';
import {
  getUserSettings,
  saveUserSettings,
  unsetActiveVendor,
} from 'services/commonService';

interface Context {
  ringtoneType: RingtoneType;
  sosRoute: SosRoute;
  activeVendor: number | null;
  userStatus: UserStatusType | null;
  pushNotificationState: TShowNotification;
  pushNotificationSound: TShowNotification;
  updateUserSettings: (a: Partial<UserSettings>) => Promise<UserSettingsResponse>;
  setActiveVendor: (id: number | null) => Promise<void>;
  updateUserLanguage: (lang: Lang) => void;
}

export const settingsContext = createContext<Context>({} as Context);

export const useSettings = (): Context => useContext<Context>(settingsContext);

export function useProvideSettings(user: User): Context {
  const [pushNotificationState, setPushNotificationState] = useState<TShowNotification>('');
  const [pushNotificationSound, setPushNotificationSound] = useState<TShowNotification>('');
  const [ringtoneType, setRingtoneType] = useState<RingtoneType>('without');
  const [sosRoute, setSosRoute] = useState<SosRoute>([]);
  const [activeVendor, setActiveVendor] = useState<number | null>(null);
  const [userStatus, setUserStatus] = useState<UserStatusType | null>(null);

  const handleLogout = useCallback(async () => {
    setActiveVendor(null);
    setPushNotificationState('');
    setPushNotificationState('');
    setRingtoneType('without');
    setSosRoute([]);
  }, []);

  const setValues = (userSettings: UserSettings) => {
    const withoutSound = 'without' as RingtoneType;
    setPushNotificationState(userSettings.pushNotificationState);
    setPushNotificationSound(userSettings.pushNotificationSound);
    setActiveVendor(userSettings.activeVendor ?? null);
    setUserStatus(userSettings.userStatus || 'client');
    setRingtoneType(userSettings.ringtoneType ?? withoutSound);
    setSosRoute(userSettings.sosRoute ?? []);
  };

  const updateUserLanguage = (newLang: Lang) => {
    saveUserSettings({ userLanguage: newLang });
  };

  useEffect(() => {
    const getSetting = async () => {
      if (!user) {
        handleLogout();
        return;
      }
      const [userSettings] = await getUserSettings();

      if (userSettings.userLanguage) {
        i18n.changeLanguage(userSettings.userLanguage);
      } else {
        updateUserLanguage(i18n.language as Lang);
      }

      setValues(userSettings);
    };

    getSetting();
  }, [user]);

  const updateUserSettings = useCallback(async (data: Partial<UserSettings>) => {
    const [response] = await saveUserSettings(data);
    setValues(response);

    return response;
  }, []);

  const updateActiveVendor = async (id: number | null) => {
    setActiveVendor(id);

    if (id) {
      await saveUserSettings({ activeVendor: id });
      return;
    }

    await unsetActiveVendor();
  };

  return {
    ringtoneType,
    sosRoute,
    userStatus,
    activeVendor,
    pushNotificationSound,
    pushNotificationState,
    updateUserSettings,
    updateUserLanguage,
    setActiveVendor: updateActiveVendor,
  };
}
