import { makeStyles } from '@material-ui/core/styles';
import {
  grayBG,
  green,
  backgroundColor,
  cyan,
  orange,
  textColor,
  red,
  textNoWrap,
  checkboxUnchecked,
} from '../../constants';

export default makeStyles((theme) => ({
  container: {
    boxSizing: 'border-box',
    position: 'relative',

    padding: '10px 30px',
    [theme.breakpoints.down(380)]: {
      padding: '10px',
    },
  },
  avatarBox: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    marginBottom: 20,
  },
  avatar: {
    width: '150px',
    height: '150px',
  },
  avatarFull: {
    width: '100%',
    height: '100%',
  },
  avatarText: {
    display: 'grid',
    gap: '10px',
    gridTemplateRows: 'auto auto 1fr',
  },
  formContent: {
    padding: '5px',
    marginBottom: 20,
  },
  nameInputs: {
    display: 'flex',
    flexDirection: 'column',
  },
  btnWrapper: {
    display: 'grid',
    gridTemplateColumns: '2fr auto',
    alignItems: 'center',
  },
  clearBtnWrapper: {
    alignSelf: 'flex-end',
  },
  uploadBtn: {
    color: textColor,
    width: '40px',
    height: '40px',
    display: 'flex',
    padding: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor,
  },
  textFieldWrapper: {
    width: '100%',
    height: '100%',
  },
  textField: {
    backgroundColor: grayBG,
    border: `2px ${grayBG} solid`,
    boxSizing: 'border-box',
    padding: '5px',

    '& input::placeholder': {
      color: textColor,
      opacity: 0.5,
    },
  },
  formControl: {
    width: '100%',
    '& .required::before': {
      content: 'none',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: textColor,
    },
  },
  formLabel: {
    fontSize: '13px',
  },
  iconAdornment: {
    width: '20px',
    height: 'auto',
    margin: '5px',
    color: cyan,
  },
  fieldEditItemBox: {
    backgroundColor: grayBG,
    display: 'flex',
    margin: '5px 0',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  formButton: {
    backgroundColor: green,
    color: backgroundColor,
    width: '190px',
  },
  uuidBox: {
    backgroundColor: green,
    marginTop: '20px',
    padding: '5px 0',
  },
  uuidText: {
    textAlign: 'center',
    color: backgroundColor,
  },
  ratingBox: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0',
    alignItems: 'center',
    gap: '8px',
  },
  ratingLabel: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  ratingValue: {
    color: orange,
    fontSize: '1.2rem',
  },
  filesDescription: {
    backgroundColor: grayBG,
    margin: '10px 0',
    padding: '10px 0',
  },
  clearAvatar: {
    backgroundColor: red,
    borderRadius: '50%',
    color: backgroundColor,
  },
  uploadText: {
    color: green,
    fontSize: '0.7rem',
  },
  clearText: {
    color: red,
    fontSize: '0.7rem',
  },
  avatarLabel: {
    fontSize: '0.75rem',
    color: textColor,
  },
  textNoWrap,
  clearAvatarBtn: {
    padding: 8,
  },
  photoNotRequired: {
    marginBottom: '-20px',
  },
  ratingIconBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  verificationInputStatusWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: 10,
    alignItems: 'center',

    '&:has(.PhoneInput)': {
      alignItems: 'start',
    },
  },
  verificationInputStatus: {
    width: 46,
    height: 46,
    backgroundColor: red,
  },
  verified: {
    backgroundColor: green,
  },
  autocomplete: {
    padding: '3px 5px',
    border: `2px ${grayBG} solid`,

    '& input::placeholder': {
      color: textColor,
      opacity: 0.5,
    },
  },
  radioGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  radio: {},
  radioBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  gender: {
    width: 'unset',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    justifyContent: 'space-between',

    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: textColor,
    },
  },
  icon: {
    width: 14,
    height: 14,
    backgroundColor: checkboxUnchecked,
    border: `2px ${checkboxUnchecked} solid`,
  },
  checkedIcon: {
    backgroundColor: orange,
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      content: '""',
    },
  },
  countryPicker: {
    backgroundColor: grayBG,
  },
  positionStatic: {
    position: 'static',
  },
  errorMessage: {
    position: 'absolute',
    top: '15%',
    fontSize: 16,
    left: 35,
    right: 0,
    pointerEvents: 'none',
    ...textNoWrap,
  },
  profileForm: {
    display: 'grid',
    gap: 5,
  },
  fileInput: {
    height: 'auto',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '5px 0',
  },
  fileInputImg: {
    height: 'auto',
    maxWidth: '150px',
    minWidth: '150px',
  },
  fileInputBtnWrapper: {
    display: 'grid',
  },
  fileInputText: {
    padding: '3px',
    margin: '5px',
    fontSize: '14px',
    textAlign: 'end',
  },
  fileInputIconWrapper: {
    justifySelf: 'end',
  },
  fileInputSvg: {
    width: '20px',
    height: 'auto',
    margin: '0 10px',
    color: 'red',
  },
  hideIcon: {
    opacity: 0,
  },
}), { index: 1 });
