import { Box } from '@material-ui/core';
import AppNavigation, { AppNavigationProps } from 'components/AppNavigation/AppNavigation';
import BottomMenu from 'components/ControlButtons/BottomMenu';
import { ReactNode } from 'react';
import { layoutContext, useProvideLayout } from 'context/layout';
import { useHomePage } from 'pages/HomePage/hooks';
import useStyles from './styles';

interface Props {
  appNavigationProps?: AppNavigationProps;
  children: ReactNode;
}
const PageLayout = ({ appNavigationProps, children }: Props) => {
  const classes = useStyles();
  const layoutValue = useProvideLayout();
  const { unreadMessages } = useHomePage();

  return (
    <Box className={classes.page}>
      <layoutContext.Provider value={layoutValue}>
        <AppNavigation {...appNavigationProps} title={layoutValue.title} />
        <Box className={classes.content}>{children}</Box>
        <BottomMenu unreadMessages={unreadMessages} />
      </layoutContext.Provider>
    </Box>
  );
};

export default PageLayout;

PageLayout.defaultProps = {
  appNavigationProps: undefined,
};
