import { makeStyles } from '@material-ui/core/styles';
import {
  backgroundColor,
  cyan,
  grayBG,
  green,
  orange,
  red,
  textColor,
} from '../../constants';

export default makeStyles(() => ({
  offerLayout: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  mapContainer: {
    width: '100%',
    height: '100%',
  },
  svg: {
    minWidth: '20px',
    height: '20px',
    margin: '5px 3px',
    '&.star': {
      color: orange,
    },
  },
  priceBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  timeBox: {
    textAlign: 'left',
  },
  timeBtns: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  price: {
    backgroundColor: green,
    '&.active': {
      backgroundColor: orange,
    },
  },
  button: {
    transition: '0.3s',
    textAlign: 'center',
    color: backgroundColor,
    fontSize: '13px',
    textTransform: 'none',
    padding: '5px 20px',
    boxSizing: 'border-box',
    '&.green': {
      backgroundColor: green,
    },
    '&.cyan': {
      backgroundColor: cyan,
    },
    '&.active': {
      backgroundColor: orange,
    },
    '&.orange': {
      backgroundColor: orange,
    },
    '&.red': {
      backgroundColor: red,
    },
    '&.isDisabled': {
      opacity: 0.5,
    },
  },
  mainBtns: {
    display: 'flex',
    padding: '10px 0',
    boxSizing: 'border-box',
    marginTop: '10px',
    '& > .MuiButton-root': {
      width: '100%',
    },
  },
  required: {
    color: red,
    fontSize: '10px',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  requiredPrimary: {
    right: '15px',
  },
  requiredSecondary: {
    right: '-15px',
  },
  settingsContainer: {
    padding: '10px',
    '& .MuiTypography-root': {
      fontSize: '13px',
    },
  },
  preorderSingleType: {
    padding: '10px',
    boxSizing: 'border-box',
  },
  preorderOption: {
    margin: '5px 0',
    '& .MuiTypography-body1': {
      width: '100%',
      backgroundColor: grayBG,
      padding: '5px',
    },
  },
  loadPhotoBoxTitle: {
    display: 'flex',
    backgroundColor: grayBG,
    margin: '10px 0',
    justifyContent: 'space-between',
    '& .MuiTypography-root': {
      paddingTop: '5px',
      paddingLeft: '5px',
    },
    '& .MuiButton-root': {
      color: green,
    },
  },
  formPreorderService: {
    borderTop: `2px ${textColor} solid`,
    padding: '5px 0',
    boxSizing: 'border-box',
    '& .MuiButton-root.green': {
      width: '100%',
    },
  },
  formControl: {
    width: '100%',
  },
  textFieldBuyForm: {
    '& input': {
      padding: '5px',
      backgroundColor: grayBG,
    },
  },
  inputFilesBox: {
    marginLeft: '25px',
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
  },
  infoAboutCarPreorder: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  preorderGalleryBox: {
    marginLeft: '25px',
  },
  endAdornment: {
    marginLeft: '5px',
    padding: '5px',
    fontSize: '15px',
    backgroundColor: grayBG,
  },
  userContainerBuyForm: {
    padding: '10px',
  },
  errorInput: {
    '& input': {
      border: `2px solid ${red}`,
    },
  },
}), { index: 1 });
