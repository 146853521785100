import { createTheme } from '@material-ui/core';
import {
  checkboxUnchecked,
  darkIcon,
  green,
  red,
  cyan,
  textColor,
  textNoWrap,
} from './constants';

export default createTheme({
  palette: {
    primary: {
      main: green,
    },
    secondary: {
      main: red,
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    allVariants: {
      color: textColor,
    },
  },
  props: {
    MuiTextField: {
      autoComplete: 'new-password',
      InputProps: {
        disableUnderline: true,
      },
    },
    MuiInput: {
      disableUnderline: true,
      autoComplete: 'new-password',
    },
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 'unset',
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'Inter, sans-serif',
        color: textColor,
        textTransform: 'none',
        borderRadius: '0',
      },
      label: {
        ...textNoWrap,
        whiteSpace: 'normal',
      },
    },
    MuiCheckbox: {
      root: {
        color: checkboxUnchecked,
        '&$checked': {
          color: red,
        },
      },
    },
    MuiIconButton: {
      root: {
        color: darkIcon,
      },
    },
    MuiRadio: {
      root: {
        color: checkboxUnchecked,
        '&$checked': {
          color: red,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          color: red,
        },
      },
    },
    MuiInput: {
      root: {
        color: textColor,
      },
      input: {
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '&[type=number]': {
          '-moz-appearance': 'textfield',
        },
      },
    },
    MuiTextField: {
      root: {
        color: textColor,
      },
    },
    MuiDialog: {
      root: {
        top: '60px !important',
        bottom: '72px !important',
      },
      paper: {
        margin: 0,
      },
    },
    MuiLink: {
      root: {
        color: cyan,
        textDecoration: 'none !important',
      },
    },
  },
});
