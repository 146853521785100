import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  IOnChange, IOrder, OrderType,
} from 'interfaces';
import { useAuth } from 'services/auth';
import { useMyOrders } from 'hooks/order';
import socket from 'socket';
import { generateStringQuery } from 'helpers';
import { useSettings } from 'pages/SettingsPage/SettingsContext';
import { getVendor } from 'services/commonService';
import ActiveOrdersComponent from '../components/ActiveOrdersComponent';

const wrongSubKeys: OrderType[] = ['alongTheWay', 'courier'];

const ActiveOrdersPage: React.FC = () => {
  const {
    user, preorders, setVendor, vendor,
  } = useAuth();
  const { userStatus, activeVendor, updateUserSettings } = useSettings();
  const { orders } = useMyOrders();

  useEffect(() => {
    orders.forEach((item: IOrder) => socket.emit('joinToRoom', item.preorderId.toString()));
  }, [orders]);

  const history = useHistory();

  const handleRoute = useCallback((to: string, state?: [IOrder]) => {
    history.push(to, state);
  }, []);

  const goToOrder = async (data: IOrder) => {
    const isMeCustomer = user?.id === data.customerId;
    const myRole = isMeCustomer ? 'client' : 'vendor';
    const orderUrl = isMeCustomer ? '/client-order' : '/vendor-order-room';

    if (userStatus === myRole) {
      handleRoute(orderUrl, [data]);
      return;
    }

    if (myRole === 'vendor') {
      if (!activeVendor) {
        history.push('/vendors-list');
        return;
      }

      const [vendorResponse] = await getVendor(activeVendor);
      await updateUserSettings({ userStatus: 'vendor' });

      setVendor(vendorResponse);
    } else {
      await updateUserSettings({ userStatus: 'client' });
      setVendor(null);
    }

    handleRoute(orderUrl, [data]);
  };

  const goToPreorder = async (data: IOrder) => {
    const {
      type: preorderType,
      subType,
    } = data;

    if (vendor) {
      await updateUserSettings({ userStatus: 'client' });
      setVendor(null);
    }

    const subKey = wrongSubKeys.includes(preorderType) ? subType : preorderType;
    const preorderSubType = data[subKey];

    const query = generateStringQuery({[preorderType]: `[${preorderSubType}]`});
    const preorderUrl = `/preorder/${preorderType}${query}`;

    handleRoute(preorderUrl, [data]);
  };

  const handleChange = ({ action, data }: IOnChange) => {
    switch (action) {
      case 'goToHome': {
        handleRoute('/');
        break;
      }
      case 'goToOrder': {
        goToOrder(data);
        break;
      }
      case 'goToPreorder': {
        goToPreorder(data);
        break;
      }
      default: break;
    }
  };

  return (
    <ActiveOrdersComponent
      orders={orders}
      preorders={preorders}
      onChange={handleChange}
    />
  );
};

export default ActiveOrdersPage;
