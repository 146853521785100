import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { IOnChange, Vendor } from 'interfaces';
import { useBrands } from 'components/ConstructorForm/items/CarCombobox/useBrands';
import { useAuth } from 'services/auth';
import { onSubmit } from '../helpers';
import UpdateVendorPage from '../UpdateVendorPage';

interface Location {
  hash: string
  key: string
  pathname: string
  search: string
  vendor: Vendor;
}

const UpdateVendorPageContainer: React.FC = () => {
  const location = useLocation<Location>();
  const { vendor } = location.state;
  const history = useHistory();
  const { user, setIsLoading, setVendors } = useAuth();

  const {
    brands,
    getModels,
    models,
  } = useBrands();

  const handleSubmit = useCallback(async (data: Vendor | any) => {
    setIsLoading(true);
    try {
      const response = await onSubmit({
        ...data,
        createdVehicle: true,
      }, vendor, user);

      setVendors((prev) => (
        prev.map((item) => (item.id === response.id ? response : item))
      ));
      history.push('/vendors-list');
    } catch (error) {
      const e = error as Error;
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleChange = useCallback(({ action, data }: IOnChange) => {
    switch (action) {
      case 'updateVendorSubmit':
        handleSubmit(data);
        break;
      case 'getModels':
        getModels(data);
        break;
      default:
        break;
    }
  }, []);

  return (
    <UpdateVendorPage
      brands={brands}
      models={models}
      vendor={vendor}
      onChange={handleChange}
    />
  );
};

export default UpdateVendorPageContainer;
