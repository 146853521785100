import { makeStyles } from '@material-ui/core/styles';
import { grayBG, red } from '../../constants';

export default makeStyles({
  input: {
    width: '100%',
  },
  inputError: {
    '&.Mui-focused + .Mui-error': {
      color: 'transparent',
    },
    '&:not(.Mui-focused)': {
      borderColor: red,
      color: 'transparent',

      '& input, & input::placeholder': {
        color: 'transparent',
        zIndex: -1,
      },
    },
  },
  inputWrapper: {
    position: 'relative',
    border: `2px ${grayBG} solid`,
    backgroundColor: grayBG,
    padding: '3px 5px',
    gap: 5,
    display: 'flex',
    alignItems: 'center',

    '&:has(.Mui-error)': {
      '&:not(:has(.Mui-focused))': {
        borderColor: red,

        '& input': {
          color: 'transparent',
        },
      },
      '&:has(.Mui-focused)': {
        '& .MuiFormHelperText-root': {
          color: 'transparent',
        },
      },
    },
  },
  errorMessage: {
    position: 'absolute',
    top: 0,
    fontSize: 16,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    height: '100%',
  },
  requireErrorMessage: {
    left: '35px',
  },
}, { index: 1 });
