import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { hyphensToCamelCase } from 'utils/formatters';
import { OrderType, Vendor, VendorHomeServices } from 'interfaces';
import { useAuth } from 'services/auth';
import { getServices } from 'services/commonService';
import { reverseGeocode } from 'services/geo';
import { generateStringQuery } from 'helpers';
import { useMyPosition } from 'hooks/geolocation';
import VendorHomePageComponent from '../components/VendorHomePageComponent';

const VendorHomePage: React.FC = () => {
  const history = useHistory();
  const [location] = useMyPosition();
  const {
    setIsLoading,
    vendor,
    currentIp,
  } = useAuth();
  const [services, setServices] = useState<VendorHomeServices>({});

  const filterServices = (innerVendor: Vendor, innerServices: {[key: string]: number}) => {
    const { alongTheWay, ...otherInnerServices } = innerServices;
    const formattedServices = { alongTheWay, ...otherInnerServices };

    if (!(innerVendor && formattedServices)) return [];
    const filteredServices = Object.entries(formattedServices).reduce((acc, [key, value]) => {
      const currService: OrderType = hyphensToCamelCase(key) as OrderType;
      return (
        innerVendor[currService]
          ? { ...acc, [key]: value }
          : acc);
    }, {});

    return filteredServices;
  };

  useEffect(() => {
    const handleGetData = async () => {
      if (!vendor || !location) return;

      setIsLoading(true);
      try {
        const engData = await reverseGeocode(location, { lang: 'eng' });
        const engQuery = generateStringQuery({
          buyerCity: engData.address.city,
          country: engData.address.country,
        });
        const servicesResponse = await getServices(engQuery);

        const filteredServices = filterServices(vendor, servicesResponse.data);
        setServices(filteredServices);
      } finally {
        setIsLoading(false);
      }
    };

    handleGetData();
  }, [vendor, location, currentIp]);

  const handleServices = useCallback((type) => {
    if (!vendor) return;

    const query = Object.entries(vendor as Vendor).reduce((acc, [key, value]) => (
      key === type ? value : acc
    ));

    const request = Object.entries(query)
      .map(([key, values]) => `${key}=[${values.map((item: string) => item).join(',')}]`)
      .join('&');

    history.push(`/vendor/${type}?${request}`);
  }, [vendor]);

  return (
    <VendorHomePageComponent services={services} onChange={handleServices} />
  );
};

export default VendorHomePage;
