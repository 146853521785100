import { useEffect, useState } from 'react';
import { OwnExchanceRates, TCurrency } from 'interfaces';
import { getCurrencyDataByCountry } from 'services/commonService';

export const useCurrency = (currencyKey: TCurrency, country?: string): {
  currencyData: OwnExchanceRates | null;
} => {
  const [currencyData, setCurrencyData] = useState<OwnExchanceRates | null>(null);
  useEffect(() => {
    const getData = async () => {
      if (!country) return;

      const res = await getCurrencyDataByCountry(currencyKey);

      const data = res.find((item) => country === item.country);
      if (!data) return;

      setCurrencyData(data);
    };

    getData();
  }, [currencyKey, country]);

  return {
    currencyData,
  };
};
