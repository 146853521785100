import { makeStyles } from '@material-ui/core';
import {
  grayBG,
  cyan,
  green,
  backgroundColor,
  grayPrimary,
  graySecondary,
  red,
} from '../../constants';

export default makeStyles((theme) => ({
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px ${grayBG} solid`,
    padding: '5px',
    backgroundColor: grayBG,
  },
  userPhoto: {
    margin: '0 15px',
    width: '45px',
    height: '45px',
  },
  listUserPhoto: {
    width: '70px',
    height: '70px',
    marginRight: '10px',
  },
  userName: {
    margin: '0 10px',
  },
  chatsListItem: {
    width: '100%',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px',
    boxSizing: 'border-box',
    backgroundColor: grayPrimary,
    borderBottom: `1px ${grayBG} solid`,
    '&:nth-child(2n)': {
      backgroundColor: graySecondary,
    },
  },
  chatsListItemInfo: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    maxWidth: 'calc(100% - 55px)',
  },
  chatsListItemMessage: {
    maxWidth: '100%',
    color: green,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  listUserName: {
    color: cyan,
    [theme.breakpoints.down(380)]: {
      fontSize: '14px',
    },
  },
  createChatBtn: {
    position: 'fixed',
    bottom: 90,
    right: '20px',
    width: '50px',
    height: '50px',
    padding: 0,
    minWidth: '50px',
    backgroundColor: green,
    color: backgroundColor,
    '&:hover': {
      backgroundColor: `${green}60`,
    },
    '& .MuiButton-label': {
      display: 'flex',
    },
  },
  goToChatsBtn: {
    minWidth: '30px',
  },
  chatInfoWrapper: {
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  bannedBox: {
    display: 'flex',
    padding: '10px',
    backgroundColor: grayBG,
    height: '50px',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  createChatSubmitBtn: {
    width: '100%',
  },
  replyChatLabel: {},
  replyChatPPLabel: {
    fontWeight: 'bold',
    fontSize: 16,
    color: green,
  },
  replyChatWWLabel: {
    fontWeight: 'bold',
    fontSize: 16,
    color: red,
  },
}), { index: 1 });
