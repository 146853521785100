import React from 'react';
import Gallery from 'sharedComponents/Gallery/Gallery';
import { Box, Typography } from '@material-ui/core';
import CollapseBox from 'components/CollapseComponent';
import { getGiftsImageLink } from 'helpers';
import useStyles from '../styles';

export type Item = {
  header: string;
  text: string;
  photo: number;
};

type Props = {
  promotionsAndGifts: Item[];
};

const PromotionAndGiftsComponent: React.FC<Props> = ({
  promotionsAndGifts,
}) => {
  const classes = useStyles();

  return (
    <>
      {promotionsAndGifts.map(({ header, text, photo}) => {
        const src = getGiftsImageLink(photo);

        return (
          <CollapseBox title={header} key={header}>
            <Box>
              <Box className={classes.galleryWrapper}>
                <Gallery images={[{ src, title: header }]} />
              </Box>
              <Typography className={classes.text}>{text}</Typography>
            </Box>
          </CollapseBox>
        );
      })}
    </>
  );
};

export default PromotionAndGiftsComponent;
