import { LatLngTuple, Map } from 'leaflet';
import React, { useState, useEffect, useMemo } from 'react';
import { Marker, Polyline } from 'react-leaflet';
import { Box, Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import LeafletMapWrapper from 'components/LeafletMapWrapper';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { getTranslateFunction } from 'helpers';
import { reverseGeocode, formatAddress } from 'services/geo';
import { IPoint } from 'interfaces';
import { getDataByPoints } from 'services/commonService';
import useStyles from './styles';

interface Props {
  centeredPinVisible: boolean;
  points: IPoint[];
  activePoint: number | null;
  onMapSelect: (a: boolean) => void;
  setIsMoving: any;
  currentSubType: string;
  onSubmit: (pointFiels: any) => void;
  onCancel: () => void;
}

const PreorderMap: React.FC<Props> = ({
  centeredPinVisible,
  points,
  activePoint,
  onMapSelect,
  setIsMoving,
  currentSubType,
  onSubmit,
  onCancel,
}) => {
  const [curPoint, setCurrPoint] = useState<IPoint | null>(null);
  const [map, setMap] = useState<Map>();
  const [route, setRoute] = useState<LatLngTuple[]>([]);
  const t = getTranslateFunction();
  const classes = useStyles();

  const handleSubmit = () => {
    onSubmit(curPoint);
    onCancel();
  };
  const isNumber = typeof activePoint === 'number';
  const isDisabled = isNumber && !curPoint;

  const formattedPoints = useMemo(() => {
    if (typeof activePoint !== 'number') return points;

    return points.map((item, index) => (index === activePoint
      ? curPoint
      : item));
  }, [points, curPoint, activePoint]);

  useEffect(() => {
    const allFilled = formattedPoints.every((point: any) => point && 'lat' in point && 'lon' in point);

    if (!allFilled || !curPoint || typeof activePoint !== 'number') return;

    const handleGetRoute = async () => {
      try {
        const arr = [...formattedPoints] as IPoint[];
        arr.splice(activePoint, 1, curPoint);
        const { route: newRoute } = await getDataByPoints(arr);

        setRoute(newRoute);
      } catch (error) {
        const e = error as AxiosError;
        if (e.response) {
          toast.error(t(e.response.data.message));
        }

        setCurrPoint(null);
      }
    };

    handleGetRoute();
  }, [formattedPoints, curPoint, activePoint]);

  useEffect(() => {
    let debounceId: ReturnType<typeof setTimeout>;

    const moveEnd = () => {
      if (typeof activePoint !== 'number') return;

      debounceId = setTimeout(async () => {
        const { lat, lng: lon } = (map as Map).getCenter();
        const data = await reverseGeocode([lat, lon]);

        if ('error' in data) {
          setCurrPoint({
            ...data,
            lat,
            lon,
            id: activePoint,
            source: 'coordinates',
            label: '',
            value: '',
          });
          return;
        }
        const [formattedDisplayName] = formatAddress(data.address, currentSubType);
        setCurrPoint({
          ...data,
          lat,
          lon,
          id: activePoint,
          label: formattedDisplayName,
          value: formattedDisplayName,
          source: 'nominatim',
        });
        setIsMoving(false);
      }, 500);
    };

    function move() {
      setRoute([]);
      clearTimeout(debounceId);
      setIsMoving(true);
      setCurrPoint(null);
    }

    if (map && centeredPinVisible) {
      map.addEventListener('moveend', moveEnd);
      map.addEventListener('movestart', move);

      return () => {
        map.removeEventListener('moveend', moveEnd);
        map.removeEventListener('movestart', move);
      };
    }
    return undefined;
  }, [map, centeredPinVisible, currentSubType]);

  // Move to the last selected point
  useEffect(() => {
    if (activePoint !== null && !centeredPinVisible) {
      const point = formattedPoints[activePoint];
      if (map && point && point.lat && point.lon) {
        map.flyTo([point.lat, point.lon]);
        onMapSelect(true);
      }
    }
  }, [activePoint, formattedPoints, centeredPinVisible]);

  useEffect(() => {
    if (map && Boolean(route.length) && activePoint === null) {
      map.flyToBounds(route.map(([lat, lng]: LatLngTuple) => [lng, lat]));
    }
  }, [route]);

  // Make all points visible to the user
  useEffect(() => {
    const allFilled = formattedPoints.every((point: any) => point && 'lat' in point && 'lon' in point);

    if (map && allFilled && activePoint === null) {
      map.flyToBounds(formattedPoints.map((point: any) => [point.lat, point.lon]));
    }
  }, [activePoint, formattedPoints]);

  const markersList = formattedPoints.map((point: any, _: unknown, array: any) => (point && 'lat' in point && 'lon' in point ? (
    <div key={point.id}>
      {Boolean(route.length) && array[0]
        && <Polyline positions={[[array[0].lat, array[0].lon], [route[0][1], route[0][0]]]} dashArray="6, 6" />}
      {Boolean(route.length) && array[array.length - 1] && 'lat' in array[array.length - 1] && 'lon' in array[array.length - 1]
        && <Polyline positions={[[array[array.length - 1].lat, array[array.length - 1].lon], [route[route.length - 1][1], route[route.length - 1][0]]]} dashArray="6, 6" />}
      <Marker position={[point.lat, point.lon]} />
    </div>
  ) : null));

  const currCoords = useMemo(() => {
    if (typeof activePoint !== 'number' || !points[activePoint].lat) return undefined;
    const point = points[activePoint];

    return [point.lat, point.lon] as LatLngTuple;
  }, [points, activePoint]);

  return (
    <LeafletMapWrapper
      defaultMapCenter={currCoords}
      onCreated={setMap}
      showCenteredPin={centeredPinVisible}
    >
      {markersList}
      {Boolean(route.length) && (
        <Polyline positions={route.map(([lng, lat]) => [lat, lng])} />
      )}
      <Box className={classes.boxBtnSelect}>

        <Button
          disabled={isDisabled}
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          className={classes.buttonSelectLocation}
        >
          {t('preorderPage.button.confirmLocationSelect')}
        </Button>
        <Button
          onClick={onCancel}
          color="primary"
          variant="contained"
          className={classes.buttonReturn}
        >
          <ArrowBack />
        </Button>
      </Box>

    </LeafletMapWrapper>
  );
};

export default PreorderMap;
