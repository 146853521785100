import React, { FC } from 'react';

interface Props {
  color: string;
}

const betweenCity: FC<Props> = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 72"
  >
    <path
      d="M71.01 47.62c0-5.42-4.39-9.81-9.81-9.81s-9.81 4.39-9.81 9.81c0 4.91 3.62 8.97 8.33 9.68v9.05H48.71v-54.6H23.28v54.61H12.27v-9.05c4.71-.71 8.33-4.77 8.33-9.68 0-5.42-4.39-9.81-9.81-9.81S.98 42.21.98 47.63c0 4.91 3.62 8.97 8.33 9.68v9.05h-4.4v3.88h62.17v-3.88h-4.4v-9.05c4.71-.71 8.33-4.77 8.33-9.69zm-30.4-27.79h5.22v7.8h-5.22v-7.8zm0 10.03h5.22v7.8h-5.22v-7.8zm0 9.95h5.22v7.8h-5.22v-7.8zm0 9.88h5.22v7.8h-5.22v-7.8zm-7.23-29.78h5.22v7.8h-5.22v-7.8zm0 10.03h5.22v7.8h-5.22v-7.8zm0 9.95h5.22v7.8h-5.22v-7.8zm0 9.88h5.22v7.8h-5.22v-7.8zm-7.22-29.94h5.22v7.8h-5.22v-7.8zm0 10.03h5.22v7.8h-5.22v-7.8zm0 9.95h5.22v7.8h-5.22v-7.8zm0 9.88h5.22v7.8h-5.22v-7.8z"
      fill={color}
    />
  </svg>
);

export default betweenCity;
