import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { getTranslateFunction } from 'helpers';
import { RingtoneType, User } from 'interfaces';
import { useAuth } from 'services/auth';
import { deactiveteMe, updateUser } from 'services/commonService';
import { createSound } from 'services/soundService';
import { useAppInfoTextOnly } from 'hooks/appInfo';
import SettingsPage from '../components/SettingsPageComponent';
import { useSettings } from '../SettingsContext';

const APP_INFO_KEYS = [
  'privacyPolicy',
  'termsOfUse',
];

const SettingsPageContainer = () => {
  const appInfo = useAppInfoTextOnly(APP_INFO_KEYS);
  const {
    setIsLoading, user, setUser, signout,
  } = useAuth();
  const t = getTranslateFunction();
  const { ringtoneType, updateUserSettings } = useSettings();

  const handleSetRingtoneType = async (newValue: RingtoneType) => {
    setIsLoading(true);
    try {
      await updateUserSettings({ ringtoneType: newValue });
      if (newValue === 'without') {
        return;
      }

      createSound(newValue).play();
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeUser = useCallback(async (data: User) => {
    if (!user) return;

    setIsLoading(true);
    try {
      const [updatedUser] = await updateUser({
        ...user,
        ...data,
      });
      setUser(updatedUser);
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  const handleDeactiveteMe = useCallback(async () => {
    if (!user) return;

    setIsLoading(true);

    try {
      await deactiveteMe(user.id);
      await signout();
      toast.success(t('settingPage.deactivatedSuccessfully'));
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  const handleChange = useCallback(async ({ action, data }) => {
    switch (action) {
      case 'setNotificationSound':
        handleSetRingtoneType(data);
        break;
      case 'changeUser':
        handleChangeUser(data);
        break;
      case 'deleteUser':
        handleDeactiveteMe();
        break;
      default:
        break;
    }
  }, []);

  return (
    <SettingsPage
      user={user}
      appInfo={appInfo}
      notificationSound={ringtoneType}
      onChange={handleChange}
    />
  );
};

export default SettingsPageContainer;
