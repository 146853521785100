import React from 'react';
import ResetPasswordComponent from '../components/ResetPasswordComponent';
import { useResetPassword } from '../hooks';

const ResetPasswordApp: React.FC = () => {
  const {
    status,
    resetPassword,
    attempts,
    sendRequest,
    resendRequest,
    sendCode,
    isAttemptsForCodeLeft,
  } = useResetPassword();

  return (
    <ResetPasswordComponent
      status={status}
      resetPassword={resetPassword}
      attempts={attempts}
      sendRequest={sendRequest}
      resendRequest={resendRequest}
      sendCode={sendCode}
      isAttemptsForCodeLeft={isAttemptsForCodeLeft}
    />
  );
};

export default ResetPasswordApp;
