import React, { useCallback, useRef } from 'react';
import valid from 'card-validator';
import {
  Box,
  Typography,
  Button,
  FormControl,
  FormHelperText,
  Input,
} from '@material-ui/core';
import CollapseBox from 'components/CollapseComponent';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import ConfirmCode from 'components/ConfirmCode';
import { formatMessage } from 'utils/formatters';
import { usePaymentRequest } from 'hooks/payment';
import { IOnChange, OwnExchanceRates } from 'interfaces';
import icons from 'components/Icons';
import { getTranslateFunction } from 'helpers';
import useStyles from '../PaymentsPageStyle';
import {
  DEFAULT_COMMISSION, PAYMENT_CLEAR_STATE_TIME, onlyNumbersRegExp,
} from '../../../constants';

export interface IWithdrawalFunds {
  cardId: string;
  quantity: string;
}

const defaultValues = {
  cardId: '',
  quantity: '',
};

const {
  withdrawFundsTsx,
  walletSvg,
  comisionSvg,
} = icons;

interface Props {
  currencyData: OwnExchanceRates | null;
  onChange: (a: IOnChange) => void;
  commision?: number;
}

const WithdrawFunds: React.FC<Props> = ({
  onChange,
  commision = DEFAULT_COMMISSION,
  currencyData,
}) => {
  const classes = useStyles();
  const t = getTranslateFunction();
  const clearTimeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);

  const {
    handleSubmit,
    errors,
    control,
    getValues,
    setError,
    reset,
  } = useForm({ defaultValues });

  const {
    attempts,
    confirmed,
    createdCodeTime,
    isAttemptsForCodeLeft,
    currency,
    sendRequest,
    sendCode,
    resetState,
  } = usePaymentRequest('/transactions/outbound-transaction');

  const handleResetState = useCallback(() => {
    resetState();
    reset();
    if (clearTimeoutId.current) {
      clearTimeout(clearTimeoutId.current);
    }
  }, []);

  const onSubmit = async (data: IWithdrawalFunds) => {
    try {
      await sendRequest(data);
    } catch (error) {
      const e = error as Error;
      setError('cardId', e);
    }
  };

  const resendRequest = useCallback(() => {
    const formValues = getValues();
    onSubmit(formValues);
  }, []);

  const handleSendCode = async (code: string) => {
    const formValues = getValues();

    const verificationCode = Number(code);
    const newTransaction = await sendCode({ ...formValues, verificationCode });
    if (newTransaction) {
      onChange({
        action: 'confirmRequest',
        data: newTransaction,
      });
      clearTimeoutId.current = setTimeout(handleResetState, PAYMENT_CLEAR_STATE_TIME);
    }

    return newTransaction;
  };

  const shouldShowForm = Boolean(!createdCodeTime || confirmed);
  const shouldShowConfirmCodeForm = Boolean(createdCodeTime && !confirmed);

  return (
    <CollapseBox
      title={t('PaymentsPage.withdrawFunds')}
      Icon={withdrawFundsTsx}
    >

      {createdCodeTime && (
      <Box
        className={classNames({[classes.hidden]: !shouldShowConfirmCodeForm})}
      >
        <ConfirmCode
          isAttemptsForCodeLeft={isAttemptsForCodeLeft}
          attempts={attempts}
          createdCodeTime={createdCodeTime}
          onSendCode={handleSendCode}
          resendRequest={resendRequest}
        />
      </Box>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classNames({[classes.hidden]: !shouldShowForm})}
      >
        <FormControl error={Boolean(errors.cardId)} className={classes.formControl}>
          <Box className={classes.formRowWrapper}>
            <img className={classes.svg} src={walletSvg} alt="" />

            <Controller
              control={control}
              name="cardId"
              rules={{
                validate: (v) => valid.number(v).isValid,
                maxLength: {
                  value: 16,
                  message: 'PaymentsPage.cardNumberLengthMustBeEqual16',
                },
                minLength: {
                  value: 16,
                  message: 'PaymentsPage.cardNumberLengthMustBeEqual16',
                },
                pattern: {
                  value: onlyNumbersRegExp,
                  message: 'PaymentsPage.quantity.invalidValue',
                },
                required: {
                  value: true,
                  message: 'PaymentsPage.fillInput',
                },
              }}
              render={(({ value, onChange: onInputChange }) => (
                <Input
                  placeholder={t('PaymentsPage.specifyCardNumber')}
                  value={value}
                  onChange={onInputChange}
                  className={classNames(
                    classes.input,
                    { [classes.inputError]: Boolean(errors.cardId) },
                  )}
                  readOnly={Boolean(createdCodeTime)}
                  inputMode="numeric"
                  name="cardId"
                />
              ))}
            />

          </Box>
          {errors.cardId?.type === 'validate' && (
            <FormHelperText className={classes.errorMessage} id="component-error-text">{t('PaymentsPage.withdrawFunds.invalidCard')}</FormHelperText>
          )}
          {errors?.cardId?.message && (
            <FormHelperText className={classes.errorMessage} id="component-error-text">{t(errors.cardId?.message)}</FormHelperText>
          )}
        </FormControl>

        <FormControl error={Boolean(errors.quantity)} className={classes.formControl}>
          <Box className={classes.formRowWrapper}>
            <Box className={classes.svg}>
              <Typography variant="caption" className={classes.textIcon}>{currency}</Typography>
            </Box>

            <Controller
              control={control}
              name="quantity"
              rules={{
                required: {
                  value: true,
                  message: 'PaymentsPage.fillInput',
                },
                pattern: {
                  value: onlyNumbersRegExp,
                  message: 'PaymentsPage.quantity.invalidValue',
                },
                min: {
                  value: currencyData?.withdrawalMin || 0,
                  message: 'PaymentsPage.quantityHasBeenUpperThanLimit',
                },
                max: {
                  value: currencyData?.withdrawalMax || 0,
                  message: 'PaymentsPage.quantityHasBeenLessThanLimit',
                },
              }}
              render={({ value, onChange: onInputChange }) => (
                <Input
                  placeholder={t('PaymentsPage.indicateAmountOfTransfer')}
                  className={classNames(
                    classes.input,
                    { [classes.inputError]: Boolean(errors.quantity) },
                  )}
                  value={value}
                  onChange={onInputChange}
                  inputMode="numeric"
                  readOnly={Boolean(createdCodeTime)}
                />
              )}
            />

          </Box>
          {errors?.quantity?.message && (
            <FormHelperText className={classes.errorMessage} id="component-error-text">{t(errors.quantity.message)}</FormHelperText>
          )}
        </FormControl>

        <Box className={classNames(classes.formControl, classes.formRowWrapper)}>
          <img className={classes.svg} src={comisionSvg} alt="" />

          <Typography className={classes.label}>
            {formatMessage('${label} ${value}%', { label: t('PaymentsPage.comision'), value: commision})}
          </Typography>

          {confirmed ? (
            <Typography className={classes.confirmedLabel}>
              {t('PaymentsPage.withdrawal.confirmedLabel')}
            </Typography>
          ) : (
            <Button
              className={classes.topUpWalletSubmitButton}
              variant="contained"
              type="submit"
            >
              {t('PaymentsPage.withdrawal.sendCode')}
            </Button>
          )}
        </Box>
      </form>

      {confirmed && (
        <Button
          variant="contained"
          className={classes.confirmedButton}
          onClick={handleResetState}
        >
          {t('PaymentsPage.withdrawal.close')}
        </Button>
      )}

      {!createdCodeTime && currencyData && (
        <Typography className={classes.limitCurrency}>
          {formatMessage(
            '${minLabel}: ${withdrawalMin} ${currency} / ${maxLabel}: ${withdrawalMax} ${currency}',
            { ...currencyData, minLabel: t('PaymentPage.currencyMinLabel'), maxLabel: t('PaymentPage.currencyMaxLabel') },
          )}
        </Typography>
      )}

      {shouldShowConfirmCodeForm && (
        <Box className={classes.resetFormButtonWrapper}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleResetState}
          >
            {t('PaymentsPage.withdrawal.clear')}
          </Button>
        </Box>
      )}
    </CollapseBox>
  );
};

export default WithdrawFunds;

WithdrawFunds.defaultProps = {
  commision: DEFAULT_COMMISSION,
};
