import React, { FC } from 'react';

interface Props {
  color: string;
}

const TopUpWallet: FC<Props> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
    <defs />
    <path fill={color} d="M41.2 11.2v21h11.5L36 54.3 19.4 32.2h11.5v-21h-29v52.4h68.3V11.2z" />
  </svg>

);
export default TopUpWallet;
