import { makeStyles } from '@material-ui/core/styles';
import {
  grayBG,
  textColor,
} from '../../constants';

export const indexStyles = makeStyles({
  serviceContainer: {
    width: '100%',
    padding: '10px',
    textAlign: 'center',
    marginBottom: '50px',
    boxSizing: 'border-box',
  },
}, { index: 1 });

export const listItemStyles = makeStyles({
  listContainer: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 0 20px 0',
    backgroundColor: grayBG,
  },
  serviceItem: {
    display: 'flex',
    alignItems: 'center',
  },
  serviceName: {
    fontSize: '15px',
    color: textColor,
  },
  serviceCount: {
    fontSize: '20px',
    letterSpacing: '0.2rem',
    color: textColor,
  },
  svgIcon: {
    width: '70px',
    height: 'auto',
    marginRight: '10px',
  },
}, { index: 1 });
