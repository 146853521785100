import React, { useCallback, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Input,
  Button,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import Commision from 'images/Commision';
import classNames from 'classnames';
import CollapseBox from 'components/CollapseComponent';
import ConfirmCode from 'components/ConfirmCode';
import { IOnChange, OwnExchanceRates, User } from 'interfaces';
import { getErrorMessage, getTranslateFunction } from 'helpers';
import icons from 'components/Icons';
import { formatMessage } from 'utils/formatters';
import { usePaymentRequest } from 'hooks/payment';
import { getUserByReferralId } from 'services/commonService';
import {
  cyan,
  DEFAULT_COMMISSION,
  ERROR_CODE_ATTEMPTS,
  PAYMENT_CLEAR_STATE_TIME,
  onlyNumbersRegExp,
} from '../../../constants';

import useStyles from '../PaymentsPageStyle';

const defaultValues = {
  referralId: '',
  quantity: '',
};

const { walletSvg, sendFundsTsx } = icons;

export interface ISendFunds {
  referralId: string;
  quantity: string;
}

interface Props {
  onChange: (a: IOnChange) => void;
  commision?: number;
  currencyData: OwnExchanceRates | null;
}

const SendFunds: React.FC<Props> = ({
  onChange,
  commision = DEFAULT_COMMISSION,
  currencyData,
}) => {
  const classes = useStyles();
  const t = getTranslateFunction();
  const [userReciever, setUserReciever] = useState<User>(null);
  const clearTimeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);

  const {
    handleSubmit,
    errors,
    control,
    getValues,
    reset,
    setError,
  } = useForm({ defaultValues });

  const {
    attempts,
    confirmed,
    createdCodeTime,
    currency,
    isAttemptsForCodeLeft,
    sendRequest,
    sendCode,
    resetState,
  } = usePaymentRequest('/transactions/send-funds');

  const handleResetState = useCallback(() => {
    resetState();
    reset();
    if (clearTimeoutId.current) {
      clearTimeout(clearTimeoutId.current);
    }
  }, []);

  const onSubmit = async (data: ISendFunds) => {
    try {
      const { referralId } = data;
      const [user] = await getUserByReferralId(referralId);
      setUserReciever(user);
      await sendRequest({ ...data, toUserUuid: user.uuid});
    } catch (error) {
      const responseMessage = getErrorMessage(error as Error);
      const message = responseMessage === ERROR_CODE_ATTEMPTS
        ? ERROR_CODE_ATTEMPTS
        : 'PaymentPage.incorrectData';
      setError('referralId', { message });
    }
  };

  const resendRequest = useCallback(() => {
    const formValues = getValues();
    onSubmit(formValues);
  }, []);

  const handleSendCode = async (code: string) => {
    if (!userReciever) return null;
    const verificationCode = Number(code);
    const { quantity } = getValues();

    const data = {
      toUserUuid: userReciever.uuid,
      quantity,
      verificationCode,
    };

    const newTransaction = await sendCode(data);
    if (newTransaction) {
      onChange({
        action: 'confirmRequest',
        data: newTransaction,
      });
      clearTimeoutId.current = setTimeout(handleResetState, PAYMENT_CLEAR_STATE_TIME);
    }

    return newTransaction;
  };

  const shouldShowForm = Boolean(!createdCodeTime || confirmed);
  const shouldShowConfirmCodeForm = Boolean(createdCodeTime && !confirmed);

  const userRecieverLabel = formatMessage('${label}: ${name}', {
    name: `${userReciever?.firstName} ${userReciever?.lastName}`,
    label: t('PaymentPage.sendFunds.reciever'),
  });

  return (
    <CollapseBox
      title={t('PaymentsPage.sendFunds')}
      Icon={sendFundsTsx}
    >
      {createdCodeTime && (
        <Box
          className={classNames({[classes.hidden]: !shouldShowConfirmCodeForm})}
        >
          <ConfirmCode
            isAttemptsForCodeLeft={isAttemptsForCodeLeft}
            attempts={attempts}
            createdCodeTime={createdCodeTime}
            onSendCode={handleSendCode}
            resendRequest={resendRequest}
          >
            <Typography className={classes.recieverLabel}>{userRecieverLabel}</Typography>
          </ConfirmCode>
        </Box>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classNames({[classes.hidden]: !shouldShowForm})}
      >
        <FormControl error={Boolean(errors.referralId)} className={classes.formControl}>
          <Box className={classes.formRowWrapper}>
            <img className={classes.svg} src={walletSvg} alt="" />

            <Controller
              control={control}
              name="referralId"
              rules={{
                pattern: {
                  value: onlyNumbersRegExp,
                  message: 'PaymentsPage.invalidUserWallet',
                },
                required: {
                  value: true,
                  message: 'PaymentsPage.fillInput',
                },
              }}
              render={(({ value, onChange: onInputChange }) => (
                <Input
                  placeholder={t('PaymentsPage.specifyUserWallet')}
                  value={value}
                  onChange={onInputChange}
                  className={classNames(
                    classes.input,
                    { [classes.inputError]: Boolean(errors.referralId) },
                  )}
                  readOnly={Boolean(createdCodeTime)}
                />
              ))}
            />
          </Box>

          {errors.referralId?.message && (
          <FormHelperText className={classes.errorMessage} id="component-error-text">{t(errors.referralId.message)}</FormHelperText>
          )}
        </FormControl>

        <FormControl error={Boolean(errors.quantity)} className={classes.formControl}>
          <Box className={classes.formRowWrapper}>
            <Box className={classes.svg}>
              <Typography variant="caption" className={classes.textIcon}>{currency}</Typography>
            </Box>

            <Controller
              control={control}
              name="quantity"
              rules={{
                required: {
                  value: true,
                  message: 'PaymentsPage.fillInput',
                },
                pattern: {
                  value: onlyNumbersRegExp,
                  message: 'PaymentsPage.quantity.invalidValue',
                },
                min: {
                  value: currencyData?.sendFundsMin || 0,
                  message: 'PaymentsPage.quantityHasBeenUpperThanLimit',
                },
                max: {
                  value: currencyData?.sendFundsMax || 0,
                  message: 'PaymentsPage.quantityHasBeenLessThanLimit',
                },
              }}
              render={(({ value, onChange: onInputChange }) => (
                <Input
                  type="text"
                  placeholder={t('PaymentsPage.indicateAmountOfTransfer')}
                  className={classNames(
                    classes.input,
                    { [classes.inputError]: Boolean(errors.quantity) },
                  )}
                  value={value}
                  onChange={onInputChange}
                  readOnly={Boolean(createdCodeTime)}
                />
              ))}
            />
          </Box>

          {errors.quantity?.message && (
          <FormHelperText className={classes.errorMessage} id="component-error-text">{t(errors.quantity.message)}</FormHelperText>
          )}
        </FormControl>

        <Box className={classNames(classes.formControl, classes.formRowWrapper)}>
          <Box className={classes.svg}>
            <Commision color={cyan} height={30} width={30} />
          </Box>

          <Typography className={classes.label}>
            {formatMessage('${label} ${value}%', { label: t('PaymentsPage.topUpWallet.commision'), value: commision})}
          </Typography>

          {confirmed ? (
            <Typography className={classes.confirmedLabel}>
              {t('PaymentsPage.sendFunds.confirmedLabel')}
            </Typography>
          ) : (
            <Button variant="contained" type="submit" className={classes.topUpWalletSubmitButton}>
              {t('PaymentsPage.topUpWallet.submit')}
            </Button>
          )}
        </Box>
      </form>

      {confirmed && (
        <Button
          variant="contained"
          className={classes.confirmedButton}
          onClick={handleResetState}
        >
          {t('PaymentsPage.sendFunds.close')}
        </Button>
      )}

      {!createdCodeTime && currencyData && (
        <Typography className={classes.limitCurrency}>
          {formatMessage(
            '${minLabel}: ${sendFundsMin} ${currency} / ${maxLabel}: ${sendFundsMax} ${currency}',
            { ...currencyData, minLabel: t('PaymentPage.currencyMinLabel'), maxLabel: t('PaymentPage.currencyMaxLabel') },
          )}
        </Typography>
      )}

      {shouldShowConfirmCodeForm && (
        <Box className={classes.resetFormButtonWrapper}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleResetState}
          >
            {t('PaymentsPage.sendFunds.clear')}
          </Button>
        </Box>
      )}
    </CollapseBox>
  );
};

export default SendFunds;

SendFunds.defaultProps = {
  commision: DEFAULT_COMMISSION,
};
