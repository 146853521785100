import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { getTranslateFunction } from 'helpers';

interface Props {
  modal: boolean;
  handleClose: (a: any) => void;
}

export const VendorOfferModal: React.FC<Props> = ({ modal, handleClose }) => {
  const t = getTranslateFunction();

  return (
    <Dialog
      open={modal}
    >
      <DialogContent>{t('VendorOffer.preorderWasTaken')}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>{t('VendorOffer.confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export const VendorOfferCanceled: React.FC<Props> = ({ modal, handleClose }) => {
  const t = getTranslateFunction();

  return (
    <Dialog
      open={modal}
    >
      <DialogContent>{t('VendorOffer.preorderWasCanceled')}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>{t('VendorOffer.confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export const VendorOfferDeclined: React.FC<Props> = ({ modal, handleClose }) => {
  const t = getTranslateFunction();

  return (
    <Dialog
      open={modal}
    >
      <DialogContent>{t('VendorOffer.clientDeclinedYourPurpose')}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>{t('VendorOffer.confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
};
export const ReOfferModal: React.FC<Props> = ({ modal, handleClose }) => {
  const t = getTranslateFunction();

  return (
    <Dialog
      open={modal}
    >
      <DialogContent>{t('VendorOffer.YouHaveAlreadymadeYourOffer')}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          {t('VendorOffer.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
