import { makeStyles } from '@material-ui/core';
import {
  grayBG,
  green,
  backgroundColor,
  cyan,
  textColor,
  red,
  orange,
} from '../../constants';

export default makeStyles({
  pageTitle: {
    backgroundColor: grayBG,
    margin: '15px 0',
    padding: '15px',
  },
  formButton: {
    backgroundColor: green,
    color: backgroundColor,
    width: '190px',
    height: '100%',
  },
  referralIdBox: {
    backgroundColor: green,
    marginTop: '40px',
    padding: '10px',
  },
  referralIdText: {
    textAlign: 'center',
    color: backgroundColor,
  },
  contentWrapper: {
    padding: '10px 15px 0',
  },
  iconAdornment: {
    width: '20px',
    height: 'auto',
    color: cyan,
    gridArea: 'img',
    marginTop: 3,
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      padding: '6px 0 7px',
    },
  },
  referralContainer: {
    display: 'grid',
    gridTemplateAreas: `"img name rating"
                        "id referral rating"`,
    gridTemplateColumns: 'auto 1fr auto',
    gap: '0 15px',
    padding: '2px 5px',
    border: `1px ${textColor} solid`,
    backgroundColor: grayBG,
  },
  linkTitle: {
    color: green,
    marginTop: '15px',
  },
  linkContent: {
    backgroundColor: grayBG,
    textAlign: 'center',
  },
  referralBox: {
    border: `1px ${textColor} solid`,
    display: 'flex',
    alignItems: 'center',
    '& + &': {
      borderTop: 'none',
    },
  },
  referralNum: {
    borderRight: `1px ${textColor} solid`,
    padding: '10px',
  },
  username: {
    gridArea: 'name',
  },
  ratingValue: {
    '&.red': { color: red },
    '&.green': { color: green },
    '&.yellow': { color: orange },
  },
  ratingContainer: {
    display: 'flex',
    gap: '10px',
    gridArea: 'rating',
  },
  svg: {
    width: '20px',
    height: '20px',
  },
  referralFieldWrapper: {
    marginTop: 20,
  },
  idLabel: {
    gridArea: 'id',
  },
  idValue: {
    gridArea: 'referral',
  },
  referralList: {
    display: 'grid',
    gap: '10px',
    '&.showLastReferrals': {
      marginTop: -3,
    },
  },
  shareBtn: {
    width: '100%',
    marginTop: '10px',
  },

}, { index: 1 });
