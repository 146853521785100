import React, {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { toast } from 'react-toastify';

import WarningIcon from 'images/warning';
import EyeSlash from 'images/eyeSlash';
import MapMarker from 'images/mapMarker';
import { getTranslateFunction } from 'helpers';
import { Preorder } from 'interfaces';

import { green, SINGLE_TYPES } from '../../constants';
import useStyles from './CollapseButtonContainerStyle';

interface Props {
  item: Preorder,
  preorderType: string,
  handleItem: (item: Preorder) => void,
  handleHidePreorder: (id: number) => void,
  handleSendAbuse: (a: {
    preorderId: number,
    abuseReason: string,
  }) => void;
}

export const CollapseButtonContainer: FC<Props> = ({
  item,
  handleItem,
  handleHidePreorder,
  preorderType,
  handleSendAbuse,
}) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const t = getTranslateFunction();
  const [modal, setModal] = useState(false);
  const [textReport, setTextReport] = useState('');
  const [typeReport, setTypeReport] = useState('');

  const isSingleTypePreorder = SINGLE_TYPES.includes(preorderType);

  const handleExpandClick = () => {
    setExpanded((prev) => !prev);
  };

  const disabled = useMemo(() => typeReport !== 'other', [typeReport]);

  const handleClose = useCallback(() => {
    setModal(false);
  }, []);

  const handleOpen = useCallback(() => {
    setModal(true);
  }, []);

  const handleSendComplaint = (preorderId: number, abuseReason: string) => {
    handleSendAbuse({
      preorderId,
      abuseReason,
    });
    setExpanded(false);
    setModal(false);
    setTypeReport('');
  };

  const handleReport = useCallback(() => {
    if (typeReport) {
      if (typeReport === 'other') {
        if (!textReport) return toast.warning(t('create-projects.error-empty-field'));

        return handleSendComplaint(item.preorderId, textReport);
      }
      return handleSendComplaint(item.preorderId, typeReport);
    }
    return null;
  }, [typeReport, textReport]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeReport((event.target as HTMLInputElement).value);
  };

  return (
    <Box>
      <Box className={`${classes.collapseContainer} ${expanded && classes.collapseContainerOpen}`}>
        <Box
          onClick={handleExpandClick}
          className={classes.collapseButton}
        >
          <MoreVert />
        </Box>
        <Box className={classes.buttonsContainer}>
          <Box
            className={classes.warningButton}
            onClick={handleOpen}
          >
            <WarningIcon color={green} />
            <Typography variant="body2">
              {t('CreateProjects.collapse.complaint')}
            </Typography>
          </Box>
          <Box
            className={classes.warningButton}
            onClick={() => handleHidePreorder(item.preorderId)}
          >
            <EyeSlash color={green} />
            <Typography variant="body2">
              {t('CreateProjects.collapse.hidden')}
            </Typography>
          </Box>
          {!isSingleTypePreorder && (
            <Box
              className={classes.warningButton}
              onClick={() => handleItem(item)}
            >
              <MapMarker color={green} />
              <Typography variant="body2">
                {t('CreateProjects.collapse.onMap')}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {modal && (
        <Dialog
          open={modal}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            <Typography>{t('create-projects.typeAbuse')}</Typography>
          </DialogTitle>
          <DialogContent>
            <RadioGroup value={typeReport} onChange={handleChange}>
              <FormControlLabel value="wrong-heading" control={<Radio />} label={t('create-projects.wrong-heading')} />
              <FormControlLabel value="prohibited-item" control={<Radio />} label={t('create-projects.prohibited-item')} />
              <FormControlLabel value="fraud" control={<Radio />} label={t('create-projects.fraud')} />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label={t('create-projects.other')}
              />
            </RadioGroup>
            <TextField
              variant="outlined"
              value={textReport}
              InputProps={{
                readOnly: disabled,
              }}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) => setTextReport(target.value)}
            />
          </DialogContent>
          <DialogActions>
            {typeReport && (
              <Button onClick={handleReport} color="primary">
                {t('create-projects.sendAbuse')}
              </Button>
            )}
            <Button onClick={handleClose} color="primary">
              {t('create-projects.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};
