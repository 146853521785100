import { makeStyles } from '@material-ui/core/styles';
import {
  orange,
  checkboxUnchecked,
  grayBG,
  green,
  textColor,
  cyan,
  backgroundColor,
  red,
  textNoWrap,
} from '../../constants';

export default makeStyles((theme) => ({
  icon: {
    width: 18,
    height: 18,
    backgroundColor: checkboxUnchecked,
  },
  checkedIcon: {
    backgroundColor: orange,
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      content: '""',
    },
  },
  inputFilesBox: {
    marginLeft: '25px',
    marginRight: '10px',
    width: '100%',
  },
  loadPhotoBoxTitle: {
    display: 'flex',
    backgroundColor: grayBG,
    margin: '10px 0',
    justifyContent: 'space-between',
    '& .MuiTypography-root': {
      paddingTop: '5px',
      paddingLeft: '5px',
    },
    '& .MuiButton-root': {
      color: green,
    },
  },
  comboboxContainer: {
    marginBottom: '5px',
    padding: '5px',
    boxSizing: 'border-box',
    [theme.breakpoints.up(420)]: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
  comboBoxItem: {
    width: '100%',
    [theme.breakpoints.up(420)]: {
      width: '45%',
    },
  },
  inputFormControl: {
    margin: '5px 0',
    width: '100%',
    '& .MuiFilledInput-root': {
      padding: '5px',
    },
  },
  autoComplete: {
    border: `1px ${backgroundColor} solid`,
    '& .MuiFilledInput-root': {
      borderRadius: '0',
      height: '25px',
    },
    '& .MuiAutocomplete-input.MuiFilledInput-input': {
      marginTop: '-2px',
      padding: '0',
    },
    '& .MuiInputLabel-formControl': {
      transform: 'none',
      top: '8px',
      left: '5px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      direction: 'ltr',
      maxWidth: '120px',
      whiteSpace: 'nowrap',
      fontSize: '13px',
    },
  },
  fullWidth: {
    width: '100%',
  },
  input: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '5px 5px 5px 0',
    border: 'none',
  },
  radio: {
    width: '50%',
    margin: '0',
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '10px',
    boxSizing: 'border-box',
    '&.fullWidth': {
      width: '100%',
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: orange,
    },
    '& .MuiRadio-root': {
      padding: '3px',
    },
    '& .Mui-checked': {
      color: orange,
    },
  },
  checkbox: {
    padding: '3px',
  },
  titleRadio: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
  },
  svg: {
    width: '20px',
    height: 'auto',
    marginRight: '5px',
  },
  datePicker: {
    width: '100%',
    fontSize: '13px',
    backgroundColor: grayBG,
    border: 'none',
    padding: '5px',
    boxSizing: 'border-box',
  },
  textfieldLabel: {
    margin: '0',
  },
  passengers: {
    display: 'flex',
    alignItems: 'center',
    color: green,
    '& .MuiIconButton-root': {
      color: green,
      padding: '5px',
    },
  },
  errorMessageCarCombobox: {
    ...textNoWrap,
    right: 40,
    display: 'block',
  },
  passengersText: {
    fontWeight: 'bold',
    backgroundColor: `${textColor}20`,
    padding: '0 5px',
    color: green,
  },
  buttonAddPoint: {
    padding: '0',
    margin: '3px 0',
    color: cyan,
    boxShadow: 'none',
    backgroundColor,
    fontWeight: 'bold',
    width: '100%',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor,
    },
  },
  paymentType: {
    marginLeft: '25px',
    fontSize: '13px',
  },
  radioGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  inputContainer: {
    borderTop: `1px ${textColor} solid`,
    padding: '5px',
    margin: '0',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '&.withoutBorder': {
      border: 'none',
    },
    '& .MuiInputBase-input': {
      padding: '0',
    },
    '&:first-child': {
      border: 'none',
    },
  },
  carComboboxAutoCompleteError: {
    borderColor: red,
  },
  autoCompleteErrorMessage: {
    pointerEvents: 'none',
    top: 10,
    display: 'block',
    width: 'calc(100% - 30px)',
    left: 0,
    ...textNoWrap,
  },
}), { index: 2 });
