import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TPreorderModal } from 'sharedComponents/PreorderPaymentModal';
import { toast } from 'react-toastify';
import { useBrands } from 'components/ConstructorForm/items/CarCombobox/useBrands';
import socket from 'socket';
import { useOrders } from 'services/orders';
import { getErrorMessage, getTranslateFunction } from 'helpers';
import {
  createOrder,
  createPreorder,
  deletePreorder,
} from 'services/commonService';
import { useBalance } from 'hooks/payment';
import { usePreorderOffersPage } from 'pages/PreorderOffersPage/usePreorderOffersPage';
import { IOnChange, Preorder } from 'interfaces';
import RequestService from 'services/requestApi';
import { useAuth } from 'services/auth';
import { parseQueryString } from 'utils/formatters';
import { reverseGeocode } from 'services/geo';
import { useMyPosition } from 'hooks/geolocation';
import { useLayout } from 'context/layout';
import PreorderPageComponent from './PreorderPageComponent';
import { ERROR_MSG_INSUFFICIENT_FUNDS, ERROR_MSG_TRANSFER_DISABLED } from '../../../constants';

const TYPES = ['alongTheWay', 'courier', 'helpOnRoad', 'buyThings', 'rent', 'services'];
const typesPreorders = ['inCity', 'betweenCity', 'helpOnRoad', 'international', 'buyThings', 'rent', 'services'];

const PreorderPage: React.FC = () => {
  const {
    user,
    isLoading,
    setIsLoading,
    deletePreorder: deletePreorderHook,
    addPreorder,
  } = useAuth();
  const { setTitle } = useLayout();
  const [myLocation] = useMyPosition();
  const t = getTranslateFunction();
  const [currentType, setCurrentType] = useState('');
  const [insufficientModal, setInsufficientModal] = useState<TPreorderModal | null>(null);
  const [preorder, setPreorder] = useState<Preorder | null>(null);
  const history = useHistory();
  const location = useLocation<{preorder?: Preorder}>();

  const [typeKey, typeValue] = useMemo(() => {
    const queryTypes = [...typesPreorders, ...TYPES];
    const searchParams = parseQueryString(location.search);
    const key = queryTypes.find((item) => searchParams[item]) as string;
    const value = searchParams[key];
    const formattedValue = value.slice(1, value.length - 1);

    return [key, formattedValue];
  }, [location]);

  const balance = useBalance();

  const {
    brands,
    getModels,
    models,
  } = useBrands();

  const {
    handleAddClientOrders,
  } = useOrders();

  const handleDeletePreorder = async (preorderId: number) => {
    setIsLoading(true);
    try {
      await deletePreorder(preorderId);
      deletePreorderHook(preorderId);
      return true;
    } catch (error) {
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseInsufficientModal = () => setInsufficientModal(null);

  const handleCreatePreorder = useCallback(async (data: Preorder) => {
    if (!myLocation) return;

    setIsLoading(true);
    const isInternalTransfer = data.paymentType === 'internalTransfer';
    try {
      const engData = await reverseGeocode(myLocation, { lang: 'eng' });
      const response = await createPreorder({
        ...data,
        buyerCity: engData.address.city,
        country: engData.address.country,
      });
      setPreorder(response);
      addPreorder(response);
      if (isInternalTransfer) {
        setInsufficientModal({
          type: 'success',
          price: -data.price,
          onClick: handleCloseInsufficientModal,
        });
      }
    } catch (error) {
      const e = error as Error;
      const errorMessage = getErrorMessage(e);
      const insufficintFunds = errorMessage === ERROR_MSG_INSUFFICIENT_FUNDS;
      const isDisabledTransfer = errorMessage === ERROR_MSG_TRANSFER_DISABLED;

      if (isInternalTransfer && insufficintFunds) {
        setInsufficientModal({
          type: 'insufficient',
          onClick: () => history.push('/payments'),
          price: -data.price,
        });
        return;
      }

      if (isInternalTransfer && isDisabledTransfer) {
        setInsufficientModal({
          type: 'unavailable',
          onClick: handleCloseInsufficientModal,
          price: -data.price,
        });
        return;
      }

      toast.error(t(errorMessage));
    } finally {
      setIsLoading(false);
    }
  }, [myLocation]);

  const {
    users,
    offers,
    vendors,
    vendorsRating,
    vendorsComments,
    setDeclinedOffers,
  } = usePreorderOffersPage(preorder);

  const handleChange = useCallback(async ({
    action,
    data,
  }: IOnChange) => {
    if (!action && isLoading) return;

    switch (action) {
      case 'historyPush': {
        history.push(data);
        break;
      }
      case 'updatePreorder': {
        const hasDeleted = await handleDeletePreorder(data.preorderId);
        if (!hasDeleted) return;

        await handleCreatePreorder(data);
        break;
      }
      case 'editPreorder': {
        const hasDeleted = await handleDeletePreorder(data.preorderId);
        if (!hasDeleted) return;

        setPreorder(null);
        break;
      }
      case 'cancelPreorder': {
        await handleDeletePreorder(data.preorderId);
        history.push('/');
        setPreorder(null);

        break;
      }
      case 'createPreorder': {
        await handleCreatePreorder(data);
        break;
      }
      case 'declinePreorder': {
        socket.emit('preorderServiceDecline', data);
        setDeclinedOffers((prev: number[]) => [...prev, data.preorderServiceId]);
        break;
      }
      case 'createOrder': {
        const newOrder = await createOrder(data);
        handleAddClientOrders([newOrder]);
        deletePreorderHook(newOrder.preorderId);
        history.push('/client-order', [newOrder]);
        break;
      }
      case 'getModels': {
        getModels(data);
        break;
      }
      default: break;
    }
  }, [isLoading, handleCreatePreorder]);

  useEffect(() => {
    TYPES.forEach((type) => {
      const condition = location.pathname.indexOf(type) >= 0;
      if (condition) {
        setCurrentType(type);
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    if (!location?.state?.preorder) return;

    setPreorder(location.state.preorder);
  }, [location.state]);

  const isThisType = useCallback(({type}) => (
    type === currentType
  ), [currentType]);

  const isThisSecondType = useCallback((innerPreorder) => {
    const [, fullType] = location.search.split('=');
    const typesKey = Object.keys(innerPreorder).find((key: string) => typesPreorders.includes(key)) || '';
    const isTrue = fullType.includes(innerPreorder[typesKey]);

    return (fullType && isTrue);
  }, [location.search]);

  useEffect(() => {
    const handleGetPreorders = async () => {
      setIsLoading(true);

      try {
        const preorders = await new RequestService('/preorder').get();

        preorders.data.some((innerPreorder: Preorder) => {
          const isMyPreorder = innerPreorder.customerId === user?.id
            && innerPreorder.type === currentType;

          if (isThisType(innerPreorder)
            && isThisSecondType(innerPreorder)
            && isMyPreorder) {
            setPreorder(innerPreorder);
          }

          return isMyPreorder;
        });
      } finally {
        setIsLoading(false);
      }
    };

    handleGetPreorders();
  }, [currentType]);

  useEffect(() => {
    setTitle([t(typeKey), t(typeValue)].join(' - '));

    return () => {
      setTitle('');
    };
  }, [typeKey, typeValue]);

  return (
    <PreorderPageComponent
      brands={brands}
      models={models}
      preorder={preorder}
      onChange={handleChange}
      user={user}
      vendors={vendors}
      vendorsRating={vendorsRating}
      vendorsComments={vendorsComments}
      currentType={currentType}
      users={users}
      offers={offers}
      balance={balance}
      insufficientModal={insufficientModal}
      onCloseInsufficientModal={handleCloseInsufficientModal}
    />
  );
};

export default PreorderPage;
