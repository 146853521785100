import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Box, Typography } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { toast } from 'react-toastify';
import Modal from 'sharedComponents/Modal';
import ChatForm from 'components/Chat/Form';
import MessagesBlock from 'components/Chat/MessagesBlock';
import { getTranslateFunction, getUserImageLink } from 'helpers';
import {
  ChatOptions,
  IMessage,
  IOnChange,
  User,
} from 'interfaces';
import { formatMessage, formatMessagesToChat } from 'utils/formatters';
import UserInfoBlock from '../../Chat/components/UserInfoBlock';
import MenuModal from './MenuModal';

import { ERROR_MSG_CHAT_BANNED } from '../../../constants';

import useStyles from '../styles';

interface Props {
  chatOptions: ChatOptions;
  messages: IMessage[];
  user: User;
  orderType: string[][];
  me: User;
  roomId: string;
  onChange: (a: IOnChange) => void;
}

const Chat: React.FC<Props> = ({
  user,
  roomId,
  orderType,
  chatOptions,
  me,
  messages,
  onChange,
}: Props) => {
  const {
    isChatBanned,
  } = chatOptions;
  const classes = useStyles();
  const t = getTranslateFunction();
  const [bannedModal, setBannedModal] = useState(false);

  const onMessageSend = useCallback(async ({ messageData = '', messageFiles = [] }: Pick<IMessage, 'messageData' | 'messageFiles'>) => {
    if (!roomId) return;

    if (isChatBanned) {
      setBannedModal(true);
      return;
    }

    const isMessageEmpty = !messageData;
    const isPhotosEmpty = !messageFiles.length;

    if (isPhotosEmpty && isMessageEmpty) {
      toast.error(t('error.validation.required'));
      return;
    }

    const sendData = {
      messageData,
      messageFiles,
      toUser: user?.uuid,
      roomId,
    };

    onChange({
      action: 'sendMessage',
      data: sendData,
    });
  }, [user, roomId]);

  const sortedMessages = useMemo(() => (
    formatMessagesToChat(messages)
  ), [messages]);

  const photoSrc = useMemo(() => (
    getUserImageLink(user?.userPhoto, user?.gender)
  ), [user]);

  const handleGoBack = useCallback(() => {
    onChange({
      action: 'clearActiveChat',
    });
  }, []);

  const loadMoreMessages = () => {
    onChange({ action: 'loadMoreMessages' });
  };

  const types = orderType.map(([mainType, secondaryType]) => (
    `${t(mainType)} / ${t(secondaryType)}`
  )).join(', ');

  const title = user && formatMessage('${label} ${id}', { id: user.referralId, label: t('ActiveChat.yourId') });

  return (
    <Box className={classes.chat}>
      <Box className={classes.chatUserInfoWrapper}>
        <Box onClick={handleGoBack}>
          <ArrowBackIos className={classes.arrowIcon} fontSize="large" />
        </Box>
        <UserInfoBlock
          orderType={types}
          imgUrl={photoSrc}
          title={<Typography className={classes.userIdLabel} variant="caption">{title}</Typography>}
          firstName={user?.firstName}
          lastName={user?.lastName}
          onChange={onChange}
        />

        <MenuModal onChange={onChange} />
      </Box>

      <MessagesBlock
        loadMoreMessages={loadMoreMessages}
        messages={sortedMessages}
        usermeUuid={me!.uuid}
        className={classes.container}
      />

      <ChatForm onSubmit={onMessageSend} />

      <Modal withoutBorder isOpen={bannedModal} onClose={() => setBannedModal(false)}>
        <Typography color="secondary">
          {t(ERROR_MSG_CHAT_BANNED)}
        </Typography>
      </Modal>
    </Box>
  );
};

export default Chat;
