import { makeStyles } from '@material-ui/core/styles';
import {
  cyan,
  green,
  backgroundColor,
  chatBackgroundColor,
  red,
} from '../../constants';

export default makeStyles((theme) => ({
  orderHistoryContainer: {
    marginBottom: 80,
    padding: '0 10px',
    fontSize: '13px',
  },
  orderDate: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
  orderCanceled: {
    fontSize: '10px',
    color: red,
    fontWeight: 'bold',
  },
  orderFinished: {
    fontSize: '10px',
    color: green,
    fontWeight: 'bold',
  },
  orderField: {
    paddingBottom: '5px',
    fontWeight: 'bold',
  },
  orderPrice: {
    fontWeight: 'bold',
    padding: '5px 0',
    borderBottom: '1px solid #bbb',
  },
  historyIsEmpty: {
    textAlign: 'center',
    paddingTop: '30px',
  },
  line: {
    width: '50%',
    height: '3px',
    backgroundColor: cyan,
    margin: '10px auto',
  },
  orderContainer: {
    marginBottom: '15px',
  },
  orderTableCell: {
    width: '50%',
  },
  orderTableRow: {
    height: '30px',
  },
  orderButtonsContainer: {
    display: 'flex',
    gap: 10,
  },
  orderButton: {
    marginTop: 10,
    width: '100%',
  },
  toChatButton: {
    backgroundColor: cyan,
    color: chatBackgroundColor,
  },
  toChatLabel: {
    color: backgroundColor,
  },
  notRelevant: {
    color: backgroundColor,
    textAlign: 'center',
    backgroundColor: green,
  },
  repeatOrderBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    gap: 10,
    [theme.breakpoints.down(380)]: {
      flexDirection: 'column',
    },
  },
  repeatOrderBtn: {
    width: '100%',
    padding: '10px 0',
    flex: '1',
    '& .MuiButton-label': {
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
}), { index: 2 });
