import { makeStyles } from '@material-ui/core';
import {
  backgroundColor,
  green,
  greenSecondary,
} from '../../constants';

export default makeStyles({
  wrapper: {
    margin: '5px 0',
    padding: '8px 0',
  },
  withoutMargins: {
    margin: 0,
  },
  svg: {
    width: '25px',
    height: '25px',
    marginRight: '5px',
  },
  listTitle: {
    backgroundColor: greenSecondary,
    display: 'flex',
    padding: '5px',
    justifyContent: 'space-between',
    '& .MuiTypography-root': {
      color: backgroundColor,
    },
    '&.isOpen': {
      backgroundColor: green,
    },
  },
  titleArrow: {
    color: backgroundColor,
    transition: '.5s',
    '&.isOpen': {
      transform: 'rotateX(180deg)',
    },
  },
  listContent: {
    maxHeight: 0,
    transition: 'max-height .5s ease-out',
    overflowY: 'hidden',
    '&.isOpen': {
      paddingTop: 10,
      maxHeight: 'none',
      transition: 'max-height .5s ease-in',
    },
  },
}, { index: 1 });
