import { Box, Typography, Button } from '@material-ui/core';
import CollapsedMenu from 'components/CollapsedMenu';
import classNames from 'classnames';
import { getTranslateFunction } from 'helpers';
import { IOnChange, Vendor } from 'interfaces';
import useStyles from '../styles';

export type VendorItem = Vendor & {
  ordersNumber: number;
};

interface Props {
  vendor: VendorItem;
  isActiveVendor: boolean;
  onChange: (a: IOnChange) => void;
}

const separator = ' - ';

const VendorListItem = ({ vendor, isActiveVendor, onChange }: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();
  const vehicleModelFirst = [
    vendor.vehicleBrand,
    vendor.vehicleModel,
    vendor.vehicleNumber,
    vendor.ordersNumber && `[${vendor.ordersNumber}]`,
  ].filter(Boolean);
  const vehicleModelSecond: string[] = [
    vendor.vehicleType,
    vendor.vehicleYear?.toString(),
    t(`carCombobox.color.${vendor.vehicleColor}`),
  ].filter(Boolean);

  const handleActivate = () => {
    onChange({
      action: 'activateVendor',
      data: vendor.id,
    });
  };

  const handleDeactivate = () => {
    onChange({
      action: 'deactivateVendor',
    });
  };

  const handleDelete = () => {
    onChange({
      action: 'deleteVendor',
      data: vendor.id,
    });
  };

  const isPendingVerification = vendor.verificationStatus === '0';
  const isFailedVerification = vendor.verificationStatus === '1';
  const isSuccessfulVerification = vendor.verificationStatus === '2';

  return (
    <Box className={classes.listItemContainer}>
      {isSuccessfulVerification && (
        <Typography variant="h6" className={classes.successfulText}>
          {t('vendorList.successfulVerification')}
        </Typography>
      )}

      {isFailedVerification && (
        <Typography variant="h6" className={classes.failedText}>
          {t('vendorList.failedVerification')}
        </Typography>
      )}

      {isPendingVerification && (
        <Box className={classes.pendingOverlay}>
          <Typography variant="h6" className={classes.pendingText}>
            {t('vendorList.inProcessed')}
          </Typography>
        </Box>
      )}

      <Box className={classes.listItem}>
        <Box>
          <Typography variant="body2" className={classes.title}>
            {vehicleModelFirst.join(separator)}
          </Typography>
          <Typography variant="body2" className={classes.subtitle}>
            {vehicleModelSecond.join(separator)}
          </Typography>

          <Box
            className={classNames(classes.vendorStatus, {
              [classes.activeVendor]: isActiveVendor,
              [classes.inactiveVendor]: !isActiveVendor,
            })}
          />
        </Box>

        <CollapsedMenu isExpandedByDefault={isFailedVerification}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => onChange({ action: 'goToUpdateVendor', data: vendor })}
          >
            {t('vendorList.editVendor')}
          </Button>

          {!isActiveVendor && (
            <Button color="primary" variant="outlined" onClick={() => handleDelete()}>
              {t('vendorList.deleteVendor')}
            </Button>
          )}
        </CollapsedMenu>
      </Box>
      <Button
        className={classNames(classes.controlVendorBtn, classes.activate)}
        onClick={handleActivate}
        disabled={isActiveVendor || isFailedVerification}
      >
        {t('vendorList.activate')}
      </Button>
      <Button
        className={classNames(classes.controlVendorBtn, classes.deactivate)}
        onClick={handleDeactivate}
        disabled={!isActiveVendor || isFailedVerification}
      >
        {t('vendorList.deactivate')}
      </Button>
    </Box>
  );
};

export default VendorListItem;
