import { Avatar, Box, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import OrdersIcon from 'images/orders';

import PaymentsStats from 'pages/PaymentsPage/components/PaymentStats';
import PaymentListItem from 'components/PaymentListItem';
import Marker from 'images/marker';
import Comment from 'images/comment';
import classNames from 'classnames';
import { StarBorderRounded } from '@material-ui/icons';
import { calculateAverageRating, getTranslateFunction, getUserImageLink } from 'helpers';
import {
  PendingTransactionStats, IRating, User, TBalance,
} from 'interfaces';
import { formatMessage } from 'utils/formatters';
import { cyan, orange } from '../../../constants';
import useStyles from '../styles';

interface Props {
  pendingTransactions: PendingTransactionStats;
  incomeSumm: Record<string, number>;
  rating: IRating | null;
  user: User;
  ordersNumber: number;
  balance: TBalance;
  isVendor: boolean;
  bonuses: TBalance;
  unreadMessages: number;
  onClick: () => void;
}

const HomePageComponent: React.FC<Props> = ({
  pendingTransactions,
  balance,
  incomeSumm,
  bonuses,
  ordersNumber,
  unreadMessages,
  user,
  rating,
  isVendor,
  onClick,
}) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  const photoSrc = useMemo(() => getUserImageLink(user?.userPhoto, user?.gender), [user]);
  const idLabel = [t('homePage.yourId'), user?.referralId].join(': ');
  const statusLabel = isVendor ? 'vendor' : 'client';
  const linkLabel = isVendor ? 'all-orders' : 'projects';
  const locationLabel = [user?.city, user?.country].join(', ');

  return (
    <Box className={classes.page}>
      <Typography className={classes.pageTitle}>{t('homePage.title')}</Typography>

      <Box className={classes.userInfo}>
        <Avatar variant="square" className={classes.userImage} src={photoSrc} />
        <Box className={classes.infoWrapper}>
          <Typography className={classes.userLabel}>{`${user?.firstName} ${user?.lastName}`}</Typography>
          <Typography className={classes.userLabelId}>
            {idLabel}
          </Typography>
          <Typography className={classes.userRating}>
            <StarBorderRounded fontSize="large" fill={orange} />
            {formatMessage(' - ${rating}', { rating: calculateAverageRating(rating) })}
          </Typography>
        </Box>
      </Box>

      <Typography className={classes.locationLabel}>
        <Marker color={cyan} width={30} height={30} />
        {locationLabel}
      </Typography>

      <Box className={classes.statusBlock}>
        <Typography>{t('homePage.yourStatus')}</Typography>
        <Typography className={classes.userLabel}>{t(`homePage.${statusLabel}`)}</Typography>
      </Box>

      <PaymentsStats
        pendingTransactions={pendingTransactions}
        balance={balance}
        incomeSumm={incomeSumm}
        formattedBonuses={bonuses}
      />

      <PaymentListItem
        label={t('homePage.active-orders')}
        icon={(
          <Box className={classes.svg}>
            <OrdersIcon width={25} height={25} color={cyan} />
          </Box>
        )}
        value={(
          <Typography className={classes.balanceEndAdornment}>
            {ordersNumber}
          </Typography>
        )}
      />

      <PaymentListItem
        label={t('homePage.unread-chats')}
        icon={(
          <Box className={classes.svg}>
            <Comment width={25} height={25} color={cyan} />
          </Box>
        )}
        value={(
          <Typography className={classNames(classes.balanceEndAdornment, { red: unreadMessages })}>
            {unreadMessages}
          </Typography>
        )}
      />

      <Box onClick={onClick} className={classes.linkBlock}>
        <Typography className={classes.linkBlockLabel}>
          {t(`homePage.${linkLabel}`)}
        </Typography>
      </Box>
    </Box>
  );
};

export default HomePageComponent;
