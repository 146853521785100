import { v4 as uuid } from 'uuid';
import { Box, Typography, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Add } from '@material-ui/icons';
import { Controller, UseFormMethods } from 'react-hook-form';
import CustomFileInput from 'components/CustomFileInput/CustomFileInput';
import classNames from 'classnames';
import { getTranslateFunction } from 'helpers';
import { CFFilesItem } from 'interfaces';
import useStyles from '../ConstructorStyles';

interface Photo {
  id: string;
}

type FormMethods = 'getValues' | 'setValue' | 'control';

interface Props extends Pick<UseFormMethods, FormMethods> {
  item: CFFilesItem;
  classes: Record<string, string>;
}

const FilesItem: React.FC<Props> = ({
  item,
  getValues,
  setValue,
  classes,
  control,
}: Props) => {
  const {
    withoutBorder,
  } = item;

  const constuctorClasses = useStyles();

  const t = getTranslateFunction();

  useEffect(() => {
    setValue('photos', getValues('photos') || []);
  }, [getValues]);

  const [photos, setPhotos] = useState<Photo[]>([]);

  const addInput = () => setPhotos((prev: Photo[]) => [...prev, { id: uuid()}]);

  return (
    <Box
      className={classNames(classes.inputContainer, {withoutBorder})}
    >
      <Box className={constuctorClasses.inputFilesBox}>
        {photos.map((photo: { id: string}, index: number) => (
          <Box key={photo.id}>
            <Box className={constuctorClasses.loadPhotoBoxTitle}>
              <Typography>{t('OfferBuyForm.loadPhoto')}</Typography>
            </Box>
            <Controller
              control={control}
              name={`preorderPhotos.${index}`}
              render={({onChange, value}) => (
                <CustomFileInput
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Box>
        ))}
        <Box className={constuctorClasses.loadPhotoBoxTitle}>
          <Typography>{t('OfferBuyForm.addPhoto')}</Typography>
          <Button onClick={addInput}>
            <Add />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FilesItem;
