import React from 'react';
import { IconComponentProps } from 'interfaces';

const Projects: React.FC<IconComponentProps> = ({ color, width, height }) => (
  <svg
    version="1.1"
    width={width}
    height={height}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 72"
  >
    <path
      fill={color}
      d="M43.9 45.87H28.1v-2.56H4.05v25.01h63.9V43.31H43.9zM49.57 15.85v-5.43h2.32V5.66H20.11v4.76h2.32v5.43H1.23V39.1H28.1v-2.55h15.8v2.55h26.87V15.85h-21.2zm-23.91 0v-5.43h20.68v5.43H25.66z"
    />
  </svg>
);

export default Projects;
