import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const modalRoot = document.querySelector('#modal-root') as HTMLElement;

export const PortalModal: React.FC<any> = ({ children }) => {
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    const { current } = el;

    modalRoot!.appendChild(current);

    return () => {
      modalRoot!.removeChild(current);
    };
  }, []);

  return createPortal(children, el.current);
};
