import { makeStyles } from '@material-ui/core';
import {
  backgroundColor, cyan, green, red,
} from '../../constants';

export default makeStyles({
  preorderModalWrapper: {
    border: `3px solid ${cyan}`,
    padding: '20px',
  },
  preorderModal: {
    padding: 0,
    overflow: 'hidden',
  },
  balanceWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: 'none',
    border: `3px solid ${cyan}`,
  },
  preorderModalButton: {
    backgroundColor: green,
    color: backgroundColor,
  },
  balanceIcon: {
    width: '30px',
    paddingRight: '10px',
  },
  balanceItem: {
    borderRight: `3px solid ${cyan}`,
    padding: '5px',
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  bodyText: {
    textAlign: 'center',
    color: green,
    fontWeight: 'bold',
  },
  closeBtn: {
    position: 'absolute',
    minWidth: 'unset',
    right: 0,
    height: '10px',
    padding: '15px',
    backgroundColor: red,
    width: '10px',
    borderRadius: '50%',
    color: backgroundColor,
  },
}, { index: 1 });
