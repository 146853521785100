import { useEffect, useMemo, useState } from 'react';
import { Box, Typography, FormHelperText } from '@material-ui/core';
import Star from 'images/star';
import classNames from 'classnames';
import icons from 'components/Icons';
import { filesHash, getTranslateFunction, getUserDocumentLink, getSucceedObjFields } from 'helpers';
import { IOnChange, RatingType, User, UserFormData } from 'interfaces';
import { getUserVerification } from 'services/commonService';
import { orange } from '../../../constants';
import ProfileForm from '../components/ProfileForm';
import useStyles from '../ProfilePageStyle';
import ProfileAvatar from '../components/ProfileAvatar';

const { documentSvg } = icons;

const VERIFIED_FIELDS = ['email', 'patronymic', 'placeOfResidence'];
const FIELDS_TO_VERIFY = [
  'firstName',
  'lastName',
  'gender',
  'birthday',
  'country',
  'city',
  'passportType',
  'numberOfPassport',
  'passportAndFacePhoto',
  'passportFirstPagePhoto',
  'userPhoto',
];

const getStringFromLabel = (value: string | { label: string }) => {
  return typeof value === 'string' ? value : value.label;
};

export type HandleUpdateUserFile = (files: FileList | null, action: string) => Promise<void>;
export type GetIsVerifiedInput = (field: string) => boolean;

interface Props {
  onChange: (a: IOnChange) => void;
  user: User;
  userRating: RatingType;
  menuProfileTop: string;
  menuProfileBottom: string;
}

const UserInfo = ({ onChange, user, menuProfileTop, menuProfileBottom, userRating }: Props) => {
  const { passportFirstPagePhoto, passportAndFacePhoto, referralId } = user || {};
  const t = getTranslateFunction();
  const classes = useStyles();

  const passportFirstPagePhotoSrc = useMemo(
    () => getUserDocumentLink(passportFirstPagePhoto, documentSvg),
    [user?.passportFirstPagePhoto],
  );

  const passportAndFacePhotoSrc = useMemo(
    () => getUserDocumentLink(passportAndFacePhoto, documentSvg),
    [user?.passportAndFacePhoto],
  );

  const handleUpdateUserFile: HandleUpdateUserFile = async (files, action) => {
    if (!files) return;

    const file = files[0];
    const hashedPhoto = await filesHash(file);

    onChange({
      action,
      data: {
        file,
        hashedPhoto,
      },
    });
  };

  const [verifiedFields, setVerifiedFields] = useState<string[]>(VERIFIED_FIELDS);
  const isUserVerified = useMemo(
    () => FIELDS_TO_VERIFY.every((field) => verifiedFields.includes(field)),
    [verifiedFields],
  );

  const getIsVerifiedInput: GetIsVerifiedInput = (field: string) => {
    return verifiedFields.includes(field);
  };

  useEffect(() => {
    const getUserVerificationData = async () => {
      const [userVerification] = await getUserVerification();
      const succeedVerificationFields = getSucceedObjFields(userVerification, FIELDS_TO_VERIFY);

      setVerifiedFields((prev) => [...prev, ...succeedVerificationFields]);
    };

    if (user) {
      getUserVerificationData();
    }
  }, [user]);

  const referralText = `${t('profilePage.YourReferralId')}: ${referralId}`;

  const onFormSubmit = (data: UserFormData) => {
    const country = getStringFromLabel(data.country);
    const city = getStringFromLabel(data.city);

    onChange({ action: 'updateUser', data: { ...data, city, country } });
  };

  if (!user) return null;

  return (
    <Box className={classes.container}>
      <ProfileAvatar
        user={user}
        isVerified={getIsVerifiedInput('userPhoto')}
        onChange={onChange}
        handleUpdateUserFile={handleUpdateUserFile}
        menuDescription={menuProfileTop}
      />

      <Box className={classes.uuidBox}>
        <Typography className={classes.uuidText}>{referralText}</Typography>
      </Box>

      {userRating && (
        <Box className={classes.ratingBox}>
          <Typography className={classes.ratingLabel}>{`${t('profilePage.yourRating')} - `}</Typography>

          <Box className={classes.ratingIconBox}>
            <Star color={orange} />
            <Typography className={classes.ratingValue}>{userRating?.politenessRatingAvg}</Typography>
          </Box>
        </Box>
      )}

      <ProfileForm
        onSubmit={onFormSubmit}
        defaultValues={user}
        getIsVerifiedInput={getIsVerifiedInput}
        isUserVerified={isUserVerified}
        passportFirstPagePhotoSrc={passportFirstPagePhotoSrc}
        passportAndFacePhotoSrc={passportAndFacePhotoSrc}
        handleUpdateUserFile={handleUpdateUserFile}
      />

      {menuProfileBottom && (
        <FormHelperText className={classNames('MuiFormHelperText-root Mui-error', classes.filesDescription)}>
          {menuProfileBottom}
        </FormHelperText>
      )}
    </Box>
  );
};

export default UserInfo;
