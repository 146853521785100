import { Box, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, {
  useEffect,
  useState,
} from 'react';
import { Controller, UseFormMethods, useWatch } from 'react-hook-form';
import classNames from 'classnames';
import TextField from 'sharedComponents/TextField';
import { CFAutocompleteItem } from 'interfaces';
import countriesList from 'services/countriesList';
import { getTranslateFunction } from 'helpers';
import useStyles from '../ConstructorStyles';

interface City {
  id: number;
  label: string;
}

interface Country {
  cities: City[];
  id: number;
  label: string;
}

interface Props extends Pick<UseFormMethods, 'control' | 'setValue' | 'getValues' | 'errors' | 'clearErrors'> {
  item: CFAutocompleteItem;
}

const AutocompleteItem: React.FC<Props> = ({
  item,
  control,
  setValue,
  errors,
  getValues,
  clearErrors,
}: Props) => {
  const {
    name,
    withoutBorder,
    placeholder,
    limitTags,
    multiple,
    userCountry,
    InputProps,
  } = item;
  const classes = useStyles();
  const t = getTranslateFunction();

  const isProvidedCities = name === 'providedCities';

  const [options, setOptions] = useState<City[]>([]);

  useEffect(() => {
    setValue(name, getValues(name) || []);
  }, [getValues, options]);

  const findInAllCitiesValue = isProvidedCities && useWatch({
    control,
    name: 'findInAllCities',
  });

  useEffect(() => {
    if (!isProvidedCities) return;
    const country: Country | undefined = countriesList.find(
      ({label}) => label === userCountry,
    );
    if (country) {
      setOptions(country.cities);
    }
  }, [isProvidedCities]);

  if (findInAllCitiesValue) {
    return null;
  }

  return (
    <Box key={name} className={classNames(classes.inputContainer, {withoutBorder})}>
      {isProvidedCities && (
        <Typography variant="caption" color="primary">{t('preorderForm.whereAreYouLooking')}</Typography>
      )}

      <Controller
        key={name}
        control={control}
        name={name}
        render={({ value, onChange, ref }) => (
          <Autocomplete
            multiple={multiple}
            limitTags={limitTags}
            className={classes.fullWidth}
            value={value || []}
            getOptionSelected={(option, inner) => option.id === inner.id}
            onChange={(_, data) => {
              if (data.length > limitTags) {
                data?.shift();
              }

              return onChange(data);
            }}
            options={options}
            getOptionLabel={(option) => option.label}
            defaultValue={[]}
            renderInput={(params) => (
              <TextField
                errorMessage={errors?.[name]?.message && t(errors?.[name].message)}
                disabled={params.disabled}
                fullWidth={params.fullWidth}
                onChange={() => clearErrors?.(name)}
                onFocus={() => { clearErrors?.(name); }}
                errorMessageClassName={classes.autoCompleteErrorMessage}
                inputRef={ref}
                InputProps={{
                  disableUnderline: true,
                  ...InputProps,
                  ...params.InputProps,
                  inputProps: params.inputProps,
                }}
                className={classes.input}
                name={name}
                variant="standard"
                placeholder={placeholder}
              />
            )}
          />
        )}
      />
    </Box>
  );
};

export default AutocompleteItem;
