import { IOrder } from 'interfaces';
import { ORDER_HISTORY_MAX_HOURS_SHOW_CHAT_BUTTON, ORDER_STATUSES } from '../../constants';

export const getStatusLabelByOrderStatus = (status: number): string => {
  switch (status) {
    case ORDER_STATUSES.CANCELED_BY_CLIENT:
    case ORDER_STATUSES.CANCELED_BY_VENDOR:
      return 'OrderWasCanceled';
    default:
      return 'OrderWasFinished';
  }
};

export const getChatButtonStatus = (order: IOrder | null): boolean => {
  if (!order) return false;

  const isCanceledOrder = [
    ORDER_STATUSES.CANCELED_BY_CLIENT,
    ORDER_STATUSES.CANCELED_BY_VENDOR,
  ].some((status) => status === order.status);
  if (isCanceledOrder) return false;

  const timestampNow = new Date().getTime();
  const orderTimestamp = new Date(order.updatedAt).getTime();
  const msDifference = timestampNow - orderTimestamp;
  const hoursDifference = msDifference / 1000 / 60 / 60;
  if (hoursDifference > ORDER_HISTORY_MAX_HOURS_SHOW_CHAT_BUTTON) return false;

  return true;
};
