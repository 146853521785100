import { Suspense } from 'react';
import { ThemeProvider } from '@material-ui/core';
import ReactDOM from 'react-dom';
import Preloader from 'components/Preloader/Preloader';
import App from 'App';
import { setTimestampToHtml } from 'helpers';
import theme from 'theme';
import 'index.css';

const IndexApp = () => {
  setTimestampToHtml();

  return (
  <Suspense fallback={<Preloader />}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Suspense>
  );
};

ReactDOM.render(<IndexApp />, document.getElementById('root'));
