import React from 'react';
import { IconComponentProps } from 'interfaces';

const YourTransport: React.FC<IconComponentProps> = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x="0"
    y="0"
    fill={color}
    version="1.1"
    viewBox="0 0 72 72"
    width={width}
    height={height}
  >
    <path d="M67.04 67.18c-.21-.16-.59-.4-.87-.57.47-.73 2.06-2.56 2.06-2.56s-.8-.52-1.58-1c-.3-.19-.59-.37-.8-.5-.04-.03-.1-.05-.16-.08-2.04-1.2-5.07-2.83-7.4-3.44-1.77-.46-4.64-.65-7.23-.73-.28-.12-.59-.26-.94-.42h-.01c-2.12-.95-4.9-2.17-6.72-2.9-2.57-1.03-6.3-1.77-11.1-1.82-.13-.12-1.12-1.02-2.83-.67 0 0 .19.35.25.71-1.6-.14-19.14 2.87-19.14 2.87-3.14.3-4.02 2.03-4.02 2.03-.38.63-.25 1.38-.07 1.91.09.21.14.46.16.75 0 .08.01.28.02.69-.01.43-.06.88-.12 1.29 0 .02 0 .03-.01.05-.03.17-.05.33-.08.48-.04.19-.07.36-.12.48-.05.04-.09.08-.08.16.02.35.81 2.87.81 2.87s.42.54.42.55c.27.42.68.92 1.34 1.36.9.6 2.86.48 2.86.48-.09-.17-.2-.41-.29-.59-.28-.66-.43-1.38-.43-2.14 0-3.1 2.52-5.62 5.62-5.62a5.625 5.625 0 0 1 5.27 7.58c-.06.17-.14.34-.22.5-.02.03-.03.07-.05.1-.08.16-.17.31-.26.46.57-.01 3.72 0 3.72 0s.04-.01.1-.02c.04 0 23.89-.44 24.06-.44.25 0 .45.01.6.01h.28c-.08-.16-.16-.33-.23-.51 0-.01-.01-.02-.01-.02a5.695 5.695 0 0 1-.39-2.05c0-3.1 2.51-5.62 5.62-5.62 1.6 0 3.04.67 4.06 1.74 2.58 2.79.77 6.45.77 6.45s.06 0 .17-.01l.96-.04c3.54-.1 5.86-.41 6.03-.59.42-.44.33-.9-.02-1.18zm-43.15-10.1c.93-.86 3.53-2.1 3.53-2.1l6.2 3.81c-6.08-.4-9.73-1.71-9.73-1.71zm11.52 1.72-7.02-4.13c12.27-1.75 20.19 4.49 20.19 4.49l-13.17-.36z" className="st0" />
    <path d="M16.03 66.42a.58.58 0 1 0 .58-.58.574.574 0 0 0-.58.58zM15.32 66.42 13.2 65.2c-.14.38-.21.79-.21 1.22 0 1 .4 1.9 1.06 2.56l1.43-1.93c-.1-.19-.16-.4-.16-.63zM17.7 65.72l2.02-1.16a3.608 3.608 0 0 0-2.75-1.74v2.36c.31.08.57.28.73.54zM16.26 65.17v-2.36c-1.17.11-2.17.78-2.75 1.74l2.02 1.16c.16-.25.42-.45.73-.54zM16.62 67.71c-.23 0-.44-.06-.62-.16l-1.39 1.88c.58.39 1.27.61 2.01.61.74 0 1.44-.22 2.01-.61l-1.39-1.88c-.19.11-.4.16-.62.16z" className="st0" />
    <path d="M16.62 61.78c-2.56 0-4.64 2.08-4.64 4.64 0 2.56 2.08 4.64 4.64 4.64 2.56 0 4.64-2.08 4.64-4.64-.01-2.56-2.08-4.64-4.64-4.64zm0 8.83c-2.31 0-4.19-1.88-4.19-4.19 0-2.31 1.88-4.19 4.19-4.19 2.31 0 4.19 1.88 4.19 4.19-.01 2.31-1.88 4.19-4.19 4.19z" className="st0" />
    <path d="m20.03 65.2-2.12 1.22c0 .23-.06.44-.16.63l1.43 1.93c.65-.66 1.06-1.56 1.06-2.56 0-.43-.08-.84-.21-1.22zM54.45 66.42a.58.58 0 1 0 .58-.58.574.574 0 0 0-.58.58zM53.74 66.42l-2.12-1.22c-.14.38-.21.79-.21 1.22 0 1 .4 1.9 1.06 2.56l1.43-1.93c-.1-.19-.16-.4-.16-.63zM56.12 65.72l2.02-1.16a3.608 3.608 0 0 0-2.75-1.74v2.36c.31.08.57.28.73.54zM54.68 65.17v-2.36c-1.17.11-2.17.78-2.75 1.74l2.02 1.16c.16-.25.42-.45.73-.54zM55.04 67.71c-.23 0-.44-.06-.62-.16l-1.39 1.88c.58.39 1.27.61 2.01.61.74 0 1.44-.22 2.01-.61l-1.39-1.88c-.19.11-.4.16-.62.16z" className="st0" />
    <path d="M55.04 61.78c-2.56 0-4.64 2.08-4.64 4.64 0 2.56 2.08 4.64 4.64 4.64 2.56 0 4.64-2.08 4.64-4.64-.01-2.56-2.08-4.64-4.64-4.64zm0 8.83c-2.31 0-4.19-1.88-4.19-4.19 0-2.31 1.88-4.19 4.19-4.19 2.31 0 4.19 1.88 4.19 4.19-.01 2.31-1.88 4.19-4.19 4.19z" className="st0" />
    <path d="m58.45 65.2-2.12 1.22c0 .23-.06.44-.16.63l1.43 1.93c.65-.66 1.06-1.56 1.06-2.56 0-.43-.08-.84-.21-1.22zM20.82 39.7h24.64v5.26H20.82zM20.82 20.09h24.64v5.26H20.82zM20.82 29.89h24.64v5.26H20.82zM57.85 7.57H44.22v5.56h9.9v43.18l5.63.7V7.57zM30.32 7.57h-4.84v5.56h15.23V7.57h-4.83v-1.1h8.34V.91H21.97v5.56h8.35zM21.97 7.57H6.52v47.12l5.63-.74V13.13h9.82z" />
  </svg>
);

export default YourTransport;
