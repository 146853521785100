import React, { useMemo } from 'react';
import {
  Button,
  Box,
} from '@material-ui/core';
import VendorInfoItem from 'components/VendorInfoComponent/VendorInfoItem';
import {
  RatingType,
  User,
  Vendor,
  Preorder,
  PreorderService,
} from 'interfaces';
import { getTranslateFunction } from 'helpers';
import useStyles from './PreorderOffersStyle';
import BuyThingsPreorderListItem from './components/BuyThingsPreorderListItem/BuyThingsPreorderListItem';
import { SINGLE_TYPES } from '../../constants';

interface Props {
  offer: PreorderService;
  handleConfirm: (a: PreorderService) => void;
  decline: (a: PreorderService) => void;
  user: User;
  preorder: Preorder;
  vendor: Vendor;
  userRating: RatingType | undefined;
  vendorComments: any;
}

const PreorderListItem: React.FC<Props> = ({
  offer,
  handleConfirm,
  decline,
  user,
  preorder,
  vendor,
  userRating,
  vendorComments,
}: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  const ratingAvg = useMemo(() => {
    if (userRating) {
      const {
        drivingSkillRatingCount,
        politenessRatingCount,
        vehicleConditionRatingCount,
      } = userRating;
      return (
        Math.floor((
          drivingSkillRatingCount
          + politenessRatingCount
          + vehicleConditionRatingCount
        ) / 3)
      );
    }
    return 0;
  }, [userRating]);

  const isSingleTypePreorder = useMemo(() => (
    SINGLE_TYPES.some((type) => preorder.type === type)), [preorder]);

  return (
    <Box key={offer.preorderServiceId} className={classes.listItemBox}>
      {!isSingleTypePreorder && (
        <VendorInfoItem
          t={t}
          vendor={vendor}
          vendorUser={user}
          order={({ ...preorder, price: offer.price, time: offer.time })}
          ratingAvg={ratingAvg}
          userRating={userRating}
          commentsLength={vendorComments.length}
        />
      )}
      {isSingleTypePreorder && (
        <BuyThingsPreorderListItem
          user={user}
          order={{
            ...preorder,
            vendorUuid: vendor.uuid,
            preorderServicePhotos: offer.preorderServicePhotos,
            price: offer.price,
            time: offer.arrivalDateTime,
            vendorComment: offer.comment,
          }}
          ratingAvg={ratingAvg}
          userRating={userRating}
          commentsLength={vendorComments.length}
        />
      )}
      <Box className={classes.btnsContainer}>
        <Button
          onClick={() => handleConfirm(offer)}
          color="default"
          className={classes.btnAccept}
        >
          {t('Accept')}
        </Button>
        <Button className={classes.propouse}>
          15
        </Button>
        <Button
          color="default"
          className={classes.btnDecline}
          onClick={() => decline(offer)}
        >
          {t('Decline')}
        </Button>
      </Box>
    </Box>
  );
};
export default PreorderListItem;
