import { ReactNode } from 'react';
import { Box, FormHelperText } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import classNames from 'classnames';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';

import useStyles from './styles';

export interface DatePickerProps extends KeyboardDatePickerProps {
  errorMessage?: string;
  icon?: ReactNode;
}

const DatePicker = ({
  errorMessage,
  onChange,
  icon,
  label,
  className,
  ...rest
}: DatePickerProps) => {
  const classes = useStyles();

  return (
    <Box component="label" className={classNames(classes.inputWrapper, 'required')}>
      {icon}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          {...rest}
          label={label}
          className={classes.input}
          clearable
          FormHelperTextProps={{ className: classes.errorMessage }}
          invalidLabel={errorMessage}
          onChange={onChange}
        />
      </MuiPickersUtilsProvider>

      {errorMessage && (
        <FormHelperText
          className={classNames(classes.errorMessage, classes.requireErrorMessage)}
        >
          {errorMessage}

        </FormHelperText>
      )}
    </Box>
  );
};

export default DatePicker;

DatePicker.defaultProps = {
  errorMessage: undefined,
  icon: undefined,
};
