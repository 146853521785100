import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';
import { getTranslateFunction } from 'helpers';
import logo from 'components/common/WebwayLogo.png';
import { useAppInfoTextOnly } from 'hooks/appInfo';
import useStyles from './styles';

const POSITIONS = [
  'privacyPolicy',
];

const PrivacyPage: React.FC = () => {
  const t = getTranslateFunction();
  const {
    privacyPolicy,
  } = useAppInfoTextOnly(POSITIONS);
  const classes = useStyles();

  return (
    <Box className={classes.pageContainer}>
      <Link to="/" className={classes.logoContainer}>
        <img className={classes.logoStyle} alt="Pre Pro Web Way logo" src={logo} />
      </Link>
      <Typography className={classes.title} variant="h1">{t('privacyPage.title')}</Typography>
      <Typography className={classes.text}>{privacyPolicy}</Typography>
    </Box>
  );
};

export default PrivacyPage;
