import { makeStyles } from '@material-ui/core/styles';
import {
  green,
  grayBG,
  red,
  cyan,
  backgroundColor,
  textNoWrap,
  textColor,
  orange,
} from '../../constants';

export default makeStyles((theme) => ({
  drawerPaper: {
    top: '50px',
    width: '100vw',
    boxSizing: 'border-box',
    paddingBottom: '35px',
    height: '100%',
  },
  navCurrIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .svg': {
      width: '30px',
      height: 'auto',
      marginRight: '5px',
    },
  },
  navContainer: {
    display: 'flex',
    zIndex: 1400,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `2px solid ${textColor}`,
  },
  burgerIcon: {
    padding: '0 10px',
    height: 25,
  },
  menuContainer: {
    display: 'flex',
    gap: 10,
    padding: '10px 30px 120px 20px',
    flexDirection: 'column',
  },
  navLink: {
    fontSize: '20px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  navLinkChecked: {
    color: cyan,
  },
  buttonsContainer: {
    textAlign: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px',
    paddingLeft: 45,
    alignItems: 'stretch',
    [theme.breakpoints.down(380)]: {
      gridTemplateColumns: '1fr',
    },
  },
  icon: {
    width: '20px',
    height: 'auto',
    marginRight: '10px',
  },
  titleName: {
    width: '100%',
    padding: 10,
    marginLeft: 10,
    backgroundColor: grayBG,
    ...textNoWrap,
  },
  languages: {
    marginRight: '20px',
  },
  menuIcon: {
    width: '40px',
    height: 'auto',
  },
  button: {
    padding: '10px 5px',
    backgroundColor: red,
    color: backgroundColor,
    lineHeight: '1',
    fontSize: '16px',
    display: 'block',
    width: '100%',
    outline: 'none',
    borderRadius: '0',
    '&:hover': {
      backgroundColor: red,
    },
    '&.active': {
      backgroundColor: green,
      '&:hover': {
        backgroundColor: green,
      },
    },
    [theme.breakpoints.down(380)]: {
      '&.active': {
        order: -1,
      },
    },
  },
  volumeContainer: {
    alignSelf: 'center',
  },
  firstName: {
    marginRight: '10px',
  },
  avatar: {
    marginRight: '10px',
  },
  settingsIcons: {
    display: 'flex',

    '& > *': {
      padding: '0 5px',
      height: 25,
      color: green,
      [theme.breakpoints.down(380)]: {
        padding: 0,
      },
    },
  },
  disableNotifications: {
    color: red,
  },
  navigationBtns: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: grayBG,
    padding: '12px 10px',
    boxSizing: 'border-box',
  },
  langBtn: {
    padding: '5px',
    margin: '10px',
    fontSize: '20px',
  },
  vendorsList: {
    '&:first-child': {
      paddingTop: '8px',
    },
  },
  titleRed: {
    color: red,
  },
  searchField: {
    backgroundColor,
    maxWidth: '150px',
    paddingLeft: '5px',
  },
  svg: {
    width: '20px',
    height: 'auto',
    margin: '5px',
    '&.star': {
      color: orange,
    },
  },
}), { index: 1 });
