import {
  FormControl, FormControlLabel, Checkbox, Container,
} from '@material-ui/core';
import classNames from 'classnames';
import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Country } from 'react-phone-number-input';
import {
  ClientFormDataSignUpDefaultValues,
  IOnChangeHandler, SaveDataHandler, UserFormData,
} from 'interfaces';
import { getTranslateFunction } from 'helpers';
import { checkAvailability } from 'services/commonService';
import DocumentsBlock from '../components/DocumentsBlock';
import NameBlock from '../components/NameBlock';
import PasswordsBlock from '../components/PasswordsBlock';
import SocialMedia from '../components/SocialMedia';
import UserInfoBlock from '../components/UserInfoBlock';
import ValidationFieldsBlock from '../components/ValidationFieldsBlock';
import { indexStyles } from '../material';
import FooterButton from '../components/FooterButton';
import { SIGNUP_STEPS } from '../../../constants';
import Header from '../components/Header';

interface CheckboxListItem {
  hidden?: boolean;
  required: boolean,
  label: string;
  name: 'createVehicle' | 'customerAccount' | 'performerAccount',
}

export interface ClientFormStepProps {
  onSaveData: SaveDataHandler;
  onChange: IOnChangeHandler;
  phoneDescription: string;
  userPhotoDescription: string;
  country?: Country;
  defaultValues: ClientFormDataSignUpDefaultValues;
  isFirstRender: boolean;
}

const ClientFormStep: React.FC<ClientFormStepProps> = ({
  phoneDescription,
  userPhotoDescription,
  country,
  onSaveData,
  onChange,
  isFirstRender,
  defaultValues,
}) => {
  const classes = indexStyles();
  const t = getTranslateFunction();

  const {
    register,
    handleSubmit,
    errors,
    watch,
    control,
    setValue,
    trigger,
    clearErrors,
    setError,
    getValues,
    formState,
  } = useForm({ defaultValues, shouldFocusError: false });

  const formValues = watch();

  const handleSaveFormData = async () => {
    const values = getValues();
    return onSaveData('client', values);
  };

  useEffect(() => {
    if (!isFirstRender) return;

    const handleSubmitForm = async (): Promise<void> => {
      await trigger();

      const isHaveErrors = Boolean(Object.keys(formState.errors).length);
      if (isHaveErrors) {
        clearErrors();
      } else {
        onChange({ action: 'submitStep', data: formValues });
      }
    };

    handleSubmitForm();
  }, [isFirstRender]);

  useEffect(() => {
    handleSaveFormData();
  }, [formValues]);

  const performerAccount = watch('performerAccount');

  const CHECKBOX_FIELDS: CheckboxListItem[] = useMemo(() => ([
    {
      required: true,
      label: 'signInPage.customerLabel',
      name: 'customerAccount',
    },
    {
      required: false,
      label: 'signInPage.performerLabel',
      name: 'performerAccount',
    },
    {
      hidden: !performerAccount,
      required: false,
      label: 'signInPage.createVehicle',
      name: 'createVehicle',
    },
  ]), [performerAccount]);

  const onCancel = useCallback(() => {
    onChange({ action: 'setStep', data: SIGNUP_STEPS.INFO });
  }, []);

  const onSubmit = useCallback(async (data: UserFormData) => {
    const emailCheck = await checkAvailability('email', data.email);

    if (!emailCheck.result) {
      setError('email', { message: 'signup.email.existed' });
      return;
    }

    if (data.invitedBy) {
      const referralIdCheck = await checkAvailability('referralId', data.invitedBy);
      if (referralIdCheck.result) {
        setError('invitedBy', { message: 'signup.invitedBy.doesntexisted' });
        return;
      }
    }

    onChange({ action: 'submitStep', data });
  }, [onChange]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.signupStepContainer} autoComplete='off'>
      <Header />

      <Container
        className={classNames(classes.formContent, classes.clientInfoFormContent)}
        id="clientFormStepContainer"
      >

        <ValidationFieldsBlock
          phoneDescription={phoneDescription}
          country={country}
          errors={errors}
          control={control}
          clearErrors={clearErrors}
        />

        <NameBlock clearErrors={clearErrors} register={register} errors={errors} />

        <UserInfoBlock
          errors={errors}
          control={control}
          register={register}
          setValue={setValue}
          getValues={getValues}
          clearErrors={clearErrors}
        />

        <DocumentsBlock
          errors={errors}
          control={control}
          userPhotoDescription={userPhotoDescription}
        />

        <SocialMedia register={register} errors={errors} />

        <PasswordsBlock
          clearErrors={clearErrors}
          register={register}
          errors={errors}
          getValues={getValues}
        />

        {CHECKBOX_FIELDS.map(({
          name, label, required, hidden,
        }) => !hidden && (
          <FormControl key={name} className={classNames(classes.formControl, { required })}>

            <Controller
              name={name}
              control={control}
              rules={{
                required: {
                  value: required,
                  message: 'error.validation.required',
                },
              }}
              render={({ value, onChange: onCheckboxChange }) => (
                <>
                  <FormControlLabel
                    className={classes.label}
                    labelPlacement="start"
                    control={(
                      <Checkbox
                        icon={(
                          <div className={classNames(classes.icon, {
                            [classes.checkboxRequiredError]: errors[name],
                          })}
                          />
                        )}
                        checkedIcon={<div className={classes.checkedIcon} />}
                        color="default"
                        checked={value}
                        onChange={({ target: { checked }}) => {
                          if (!required) onCheckboxChange(checked);
                        }}
                        name={name}
                      />
                    )}
                    label={t(label)}
                  />
                </>
              )}
            />
          </FormControl>
        ))}

      </Container>

      <FooterButton
        submitTitle="signupPage.footerButtonContainer.joinToTeam"
        onCancel={onCancel}
      />
    </form>
  );
};

export default ClientFormStep;

ClientFormStep.defaultProps = {
  country: undefined,
};
