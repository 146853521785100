import React, { useEffect, useMemo, useState } from 'react';
import { formatLangItems } from 'helpers';
import {
  Lang, PromotionAndGift,
} from 'interfaces';
import { useAuth } from 'services/auth';
import { getPromotionsAndGifts } from 'services/commonService';
import countriesList from 'services/countriesList';
import PromotionAndGiftsComponent, { Item } from '../components/PromotionAndGiftsComponent';

const PromotionAndGiftsApp: React.FC = () => {
  const { user } = useAuth();
  const [promotionsAndGifts, setPromotionsAndGifts] = useState<PromotionAndGift[]>([]);

  useEffect(() => {
    if (!user) return;

    const handleGetPromotionsAndGifts = async () => {
      const response = await getPromotionsAndGifts();
      const filteredResponse = response.filter((item: PromotionAndGift) => (
        item.country === user.country
      ));

      setPromotionsAndGifts(filteredResponse);
    };

    handleGetPromotionsAndGifts();
  }, [user]);

  const userLanguage = useMemo(() => {
    const countryData = countriesList.find(
      ({ label, showLang }) => showLang && label === user?.country,
    );
    if (!countryData) return '';

    const { lang } = countryData;

    return lang;
  }, [user]) as Lang;

  const formattedItems = useMemo(() => {
    if (!userLanguage) return [];

    return promotionsAndGifts.map((item: PromotionAndGift) => formatLangItems(item, userLanguage));
  }, [promotionsAndGifts, userLanguage]) as Item[];

  return (
    <PromotionAndGiftsComponent
      promotionsAndGifts={formattedItems}
    />
  );
};

export default PromotionAndGiftsApp;
