import React, { FC } from 'react';

interface Props {
  color: string
}

const Warning: FC<Props> = ({color}): any => (
  <svg
    id="Flat"
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 256 256"
  >
    <path
      fill={color}
      d="M120,144V96a8,8,0,0,1,16,0v48a8,8,0,0,1-16,0Zm116.76758,67.981a23.75438,23.75438,0,0,1-20.791,12.01123H40.02344A23.99994,23.99994,0,0,1,19.252,187.96973L107.22852,35.97705a24.00024,24.00024,0,0,1,41.543,0l87.97657,151.99219A23.75354,23.75354,0,0,1,236.76758,211.981Zm-13.86719-15.99658L134.92383,43.99219a8.00025,8.00025,0,0,0-13.84766,0h0L33.09961,195.98438a8.00079,8.00079,0,0,0,6.92383,12.00781H215.97656a8.00079,8.00079,0,0,0,6.92383-12.00781ZM128,168a12,12,0,1,0,12,12A12.01343,12.01343,0,0,0,128,168Z"
    />
  </svg>
);

export default Warning;
