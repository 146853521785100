import { LatLngTuple, Map } from 'leaflet';
import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import { useAuth } from 'services/auth';
import { MAP_CENTER_FALLBACK } from '../constants';

interface Props {
  showCenteredPin?: boolean;
  zoomLevel?: number;
  onCreated: (map: Map) => void;
  defaultMapCenter?: LatLngTuple;
}

const LeafletMapWrapper: React.FC<Props> = ({
  children,
  defaultMapCenter,
  showCenteredPin = false,
  zoomLevel = 17,
  onCreated,
}) => {
  const { geolocation, currentIp } = useAuth();

  const [map, setMap] = useState<Map>();

  const location = defaultMapCenter ?? geolocation ?? currentIp?.ll ?? MAP_CENTER_FALLBACK;

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (map && !defaultMapCenter) {
      timeoutId = setTimeout(() => map.invalidateSize(false), 250);
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        const { latitude, longitude } = coords;
        map.setView([latitude, longitude], zoomLevel);
      }, undefined, {
        enableHighAccuracy: true,
      });
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [map, defaultMapCenter]);

  useEffect(() => {
    if (showCenteredPin && map) {
      const img = new Image();
      img.style.cssText = `
        margin-left: -12px;
        margin-top: -41px;
        width: 25px;
        height: 41px;
        z-index: 999;
        position: absolute;
        left: 50%;
        top: 50%;
      `;

      img.src = 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png';
      map.getContainer().appendChild(img);

      return () => {
        map.getContainer().removeChild(img);
      };
    }
    return undefined;
  }, [map, showCenteredPin]);

  const whenCreated = (mainMap: Map) => {
    setMap(mainMap);
    onCreated(mainMap);
  };

  return (
    <MapContainer
      center={location}
      zoom={zoomLevel}
      style={{ height: '100%' }}
      whenCreated={whenCreated}
      zoomControl={false}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <ZoomControl position="bottomright" />
      {children}
    </MapContainer>
  );
};

export default LeafletMapWrapper;

LeafletMapWrapper.defaultProps = {
  showCenteredPin: false,
  zoomLevel: 17,
  defaultMapCenter: undefined,
};
