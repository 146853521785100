import React from 'react';
import { IconComponentProps } from 'interfaces';

const Inscruction: React.FC<IconComponentProps> = ({ color, width, height }) => (
  <svg
    version="1.1"
    width={width}
    height={height}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 72"
  >
    <path
      fill={color}
      d="M63.15 9.31c-7.5 0-15-.01-22.5-.01-6.62 0-13.24 0-19.86.01-1.42 0-2.64.95-3 2.3-.34 1.26.18 2.66 1.4 3.29.62.32 1.4.49 2.11.49 12.27.03 24.53.02 36.8.02h.98V64.5h4.99V9.3c-.35.01-.63.01-.92.01z"
    />
    <path
      fill={color}
      d="M20.4 17.26c-.61 0-1.25-.06-1.83-.24-2.11-.65-3.09-2.32-3.19-4.83-.11-2.73 2.21-4.82 4.77-4.8 12.03.07 24.06.03 36.09.03h.87V3.9h-2.34c-11.41 0-22.81 0-34.22.01-.76 0-1.53.03-2.27.2-3.89.88-6.38 4.01-6.39 8-.01 16.11 0 32.22 0 48.33h.01v9.06h45.25v-9.06h-.01c0-13.98 0-27.96.01-41.93v-1.24h-1.02c-11.91 0-23.82 0-35.73-.01zm14.13 2.54a4.39 4.39 0 110 8.78 4.39 4.39 0 110-8.78zm9.06 43.93H25.47v-4.17h4.68V34.5H25.5v-4.17h13.42v29.24h4.68v4.16z"
    />
    <path
      fill={color}
      d="M37.89 37.48c0-1.31-.83-2.43-2.01-2.93v3.89h-2.7v-3.89c-1.19.49-2.01 1.62-2.01 2.93 0 1.4.96 2.6 2.28 3.02v13.38c-1.1.43-1.88 1.5-1.88 2.75a2.96 2.96 0 105.92 0c0-1.25-.78-2.32-1.88-2.75V40.5c1.33-.43 2.28-1.62 2.28-3.02zm-1.61 19.15c0 .97-.78 1.75-1.75 1.75s-1.75-.78-1.75-1.75.78-1.75 1.75-1.75 1.75.79 1.75 1.75z"
    />
  </svg>
);

export default Inscruction;
