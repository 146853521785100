import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';
import Modal from 'sharedComponents/Modal';

interface Props {
  isOpen: boolean;
  buttonText: string;
  content: string;
  confirm: () => void;
  title?: string | null;
  secondaryConfirm?: () => void;
  secondaryButtonText?: string;
}

const GlobalModal: React.FC<Props> = (props) => {
  const {
    buttonText,
    isOpen,
    title,
    content,
    confirm,
    secondaryConfirm,
    secondaryButtonText,
  } = props;
  return (
    <Modal isOpen={isOpen}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {secondaryButtonText && secondaryConfirm && (
          <Button color="primary" onClick={secondaryConfirm}>
            {secondaryButtonText}
          </Button>
        )}
        <Button color="primary" onClick={confirm}>
          {buttonText}
        </Button>
      </DialogActions>
    </Modal>
  );
};

GlobalModal.defaultProps = {
  secondaryConfirm: undefined,
  secondaryButtonText: undefined,
  title: null,
};

export default GlobalModal;
