import React, { FC } from 'react';

interface Props {
  color: string
}

const Male: FC<Props> = ({ color }): any => (
  <svg
    version="1.1"
    width="20px"
    height="30px"
    viewBox="0 0 36 36"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="g3799"
      transform="translate(-36,-12.53)"
    >
      <g
        id="g3797"
      >
        <circle
          cx="52.900002"
          cy="21.08"
          r="8.5500002"
          id="circle3793"
          fill={color}
        />
        <polygon
          points="36,31.91 52.9,59.47 69.79,31.91 "
          id="polygon3795"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default Male;
