import { useEffect, useState } from 'react';
import { FormControl, RadioGroup, Typography, Box, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';
import classNames from 'classnames';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Controller, UseFormMethods } from 'react-hook-form';
import MaleTsx from 'images/male';
import FemaleTsx from 'images/female';
import SelectGeolocation from 'sharedComponents/SelectGeolocation';
import Input from 'sharedComponents/Input';
import TextField from 'sharedComponents/TextField';
import DatePicker from 'sharedComponents/DatePicker';
import Portal from 'pages/SignUpPage/Portal';
import { dateRegisterFormatter } from 'utils/formatters';
import { ICity, ICountry, IUser, Suggestion } from 'interfaces';
import COUNTRIES from 'services/countriesList';
import icons from 'components/Icons';
import { getTranslateFunction } from 'helpers';
import { alphanumericWithSpaceAndDashRegExp, cyan } from '../../../constants';
import VerificationInputStatus from './VerificationInputStatus';
import { GetIsVerifiedInput } from '../containers/UserInfo';
import useStyles from '../ProfilePageStyle';

const filter = createFilterOptions<ICity>();

const MAX_DATE = dateRegisterFormatter(new Date());

const countriesList = COUNTRIES.sort((item1, item2) => item1.label.localeCompare(item2.label, 'en'));

const { dateBirthdaySvg, markerSvg, inCity, international, numberSvg } = icons;

interface Fields {
  gender: string;
  birthday?: Date | string | null;
  country: ICountry | string | null;
  city: ICity | string | null;
  placeOfResidence: string;
  passportType: string;
  numberOfPassport: string;
}

type UseFormMethodsProps = Pick<UseFormMethods<Fields>,
'register' | 'errors' | 'control' | 'setValue' | 'clearErrors'>;

type InputProps = {
  getIsVerifiedInput: GetIsVerifiedInput;
  defaultValues: IUser;
};

type Props = UseFormMethodsProps & InputProps;

const PersonalInfoInputs = ({
  control,
  errors,
  register,
  setValue,
  clearErrors,
  defaultValues,
  getIsVerifiedInput,
}: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  const [cities, setCities] = useState<ICity[]>([]);
  const [placeOfResidenceMapActive, setPlaceOfResidenceMapActive] = useState(false);

  const handleClosePlaceOfResidenceMap = () => {
    setPlaceOfResidenceMapActive(false);
  };

  const handleFocusPlaceOfResidence = () => {
    clearErrors('placeOfResidence');
    setPlaceOfResidenceMapActive(true);
  };

  const onMapChange = (item: Suggestion) => {
    setValue('placeOfResidence', item.label);
    handleClosePlaceOfResidenceMap();
  };

  const handleChangeCountry = (countryValue: ICountry) => {
    setCities(countryValue ? countryValue.cities : []);
    setValue('city', '');
  };

  useEffect(() => {
    const currentCountry = countriesList.find((country) => country.label === defaultValues.country);

    if (currentCountry) {
      setCities(currentCountry.cities);
    }
  }, []);

  return (
    <>
      {/* Gender */}
      <VerificationInputStatus isVerified={getIsVerifiedInput('gender')}>
        <FormControl className={classes.formControl} error={!!errors.gender}>
          <Controller
            as={
              <RadioGroup name='gender' className={classes.radioGroup}>
                <Typography className={classes.label}>{t('profilePage.genderLabel')}</Typography>
                <Box className={classNames(classes.radioBox, classes.gender)}>
                  <FormControlLabel
                    className={classes.label}
                    value='male'
                    control={
                      <Radio
                        color='default'
                        className={classes.radio}
                        icon={<div className={classes.icon} />}
                        checkedIcon={<div className={classes.checkedIcon} />}
                        disabled={getIsVerifiedInput('gender')}
                      />
                    }
                    labelPlacement='start'
                    label={
                      <Box className={classes.iconAdornment}>
                        <MaleTsx color={cyan} />
                      </Box>
                    }
                  />
                  <FormControlLabel
                    className={classes.label}
                    value='female'
                    control={
                      <Radio
                        color='default'
                        className={classes.radio}
                        icon={<div className={classes.icon} />}
                        checkedIcon={<div className={classes.checkedIcon} />}
                      />
                    }
                    labelPlacement='start'
                    label={
                      <Box className={classes.iconAdornment}>
                        <FemaleTsx color={cyan} />
                      </Box>
                    }
                  />
                </Box>
              </RadioGroup>
            }
            name='gender'
            control={control}
          />
          <FormHelperText id='component-error-text'>{errors.gender?.message}</FormHelperText>
        </FormControl>
      </VerificationInputStatus>

      {/* Birthday */}
      <VerificationInputStatus isVerified={getIsVerifiedInput('birthday')}>
        <FormControl className={classes.formControl} error={!!errors.birthday}>
          <Controller
            name='birthday'
            control={control}
               rules={{
                 required: {
                   value: true,
                   message: 'error.validation.required',
                 },
               }}
            render={({ value, onChange }) => (
              <DatePicker
                id='DatePicker'
                value={value}
                onChange={onChange}
                format='dd/MM/yyyy'
                openTo='year'
                views={['year', 'month', 'date']}
                placeholder={t('profilePage.birthdayLabel')}
                maxDate={new Date(MAX_DATE)}
                maxDateMessage={t('profilePage.birthday.maxDateError')}
                minDateMessage={t('profilePage.birthday.minDateError')}
                errorMessage={errors.birthday?.message && t(errors.birthday.message)}
                invalidDateMessage={t('profilePage.birthday.invalidDateMessage')}
                icon={
                  <img src={dateBirthdaySvg} alt={t('profilePage.birthdayLabel')} className={classes.iconAdornment} />
                }
                disabled={getIsVerifiedInput('birthday')}
              />
            )}
          />
        </FormControl>
      </VerificationInputStatus>

      {/* Country */}
      <VerificationInputStatus isVerified={getIsVerifiedInput('country')}>
        <FormControl className={classes.formControl} error={!!errors.country}>
          <Controller
            control={control}
            name='country'
            rules={{
              required: {
                value: true,
                message: 'error.validation.required',
              },
            }}
            render={({ value, onChange }) => (
              <Autocomplete
                onChange={(_, data) => {
                  onChange(data);
                  handleChangeCountry(data);
                  clearErrors('country');
                }}
                onFocus={() => clearErrors('country')}
                options={countriesList}
                autoHighlight
                className={classes.countryPicker}
                getOptionLabel={(option) => option.label || defaultValues.country}
                value={value}
                freeSolo
                disabled={getIsVerifiedInput('country')}
                renderOption={(option) => option.label}
                renderInput={(option) => (
                  <TextField
                    InputLabelProps={option.InputLabelProps}
                    disabled={option.disabled}
                    fullWidth={option.fullWidth}
                    className={classes.autocomplete}
                    placeholder={t('profilePage.countryLabel')}
                    errorMessage={errors.country?.message && t(errors.country.message)}
                    InputProps={{
                      ...option.InputProps,
                      disableUnderline: true,
                      inputProps: {
                        ...option.inputProps,
                        autoComplete: 'new-password',
                      },
                      startAdornment: (
                        <img
                          src={international}
                          alt={t('profilePage.countryLabel')}
                          className={classes.iconAdornment}
                        />
                      ),
                    }}
                  />
                )}
              />
            )}
          />
        </FormControl>
      </VerificationInputStatus>

      {/* City */}
      <VerificationInputStatus isVerified={getIsVerifiedInput('city')}>
        <FormControl className={classes.formControl} error={!!errors.city}>
          <Controller
            control={control}
            name='city'
            rules={{
              required: {
                value: true,
                message: 'error.validation.required',
              },
            }}
            render={({ value, onChange }) => (
              <Autocomplete
                options={cities || ''}
                onChange={(_, data) => {
                  onChange(data);
                  clearErrors('city');
                }}
                autoHighlight
                onFocus={() => clearErrors('city')}
                value={value}
                freeSolo
                className={classes.countryPicker}
                disabled={getIsVerifiedInput('city')}
                getOptionLabel={(option: ICity) => {
                  if (typeof option === 'string') {
                    return option;
                  }

                  return option.label ?? defaultValues.city;
                }}
                renderOption={(option: ICity) => option.label || defaultValues.city}
                filterOptions={(options: ICity[], params) => {
                  const { inputValue } = params;
                  const filtered = filter(options, params);

                  const isExisted = filtered?.some(({ label }) => {
                    return label.toLowerCase().includes(inputValue.toLowerCase());
                  });

                  if (inputValue && !isExisted) {
                    filtered.push({ id: 0, label: inputValue });
                  }

                  return filtered;
                }}
                renderInput={(option) => (
                  <TextField
                    InputLabelProps={option.InputLabelProps}
                    disabled={option.disabled}
                    fullWidth={option.fullWidth}
                    className={classes.autocomplete}
                    placeholder={t('profilePage.cityLabel')}
                    errorMessage={errors.city?.message && t(errors.city.message)}
                    InputProps={{
                      ...option.InputProps,
                      disableUnderline: true,
                      inputProps: {
                        ...option.inputProps,
                        autoComplete: 'new-password',
                      },
                      startAdornment: (
                        <img src={inCity} alt={t('profilePage.cityLabel')} className={classes.iconAdornment} />
                      ),
                    }}
                  />
                )}
              />
            )}
          />
        </FormControl>
      </VerificationInputStatus>

      {/* PlaceOfResidence */}
      <VerificationInputStatus isVerified={getIsVerifiedInput('placeOfResidence')}>
        <FormControl
          className={classNames(classes.formControl, classes.positionStatic)}
          error={!!errors.placeOfResidence}
        >
          <Controller
            name='placeOfResidence'
            control={control}
            rules={{
              required: {
                value: true,
                message: 'error.validation.required',
              },
            }}
            render={({ value, onChange }) => (
              <>
                <TextField
                  className={classes.textField}
                  placeholder={t('profilePage.PlaceOfResidence')}
                  onFocus={handleFocusPlaceOfResidence}
                  value={value}
                  onChange={onChange}
                  errorMessage={errors.placeOfResidence?.message && t(errors.placeOfResidence.message)}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <img src={markerSvg} alt={t('profilePage.PlaceOfResidence')} className={classes.iconAdornment} />
                    ),
                  }}
                  type='text'
                />

                {placeOfResidenceMapActive && (
                  <Portal>
                    <SelectGeolocation
                      onChange={onMapChange}
                      onCancel={handleClosePlaceOfResidenceMap}
                      title={t('profilePage.PlaceOfResidence')}
                      defaultValue={value}
                      variant='profile'
                    />
                  </Portal>
                )}
              </>
            )}
          />
        </FormControl>
      </VerificationInputStatus>

      {/* PassportType */}
      <VerificationInputStatus isVerified={getIsVerifiedInput('passportType')}>
        <FormControl className={classes.formControl} error={!!errors.passportType}>
          <Controller
            as={
              <RadioGroup name='passportType' className={classes.radioGroup}>
                <Box className={classes.radioBox}>
                  <FormControlLabel
                    className={classes.label}
                    value='old'
                    control={
                      <Radio
                        color='default'
                        className={classes.radio}
                        icon={<div className={classes.icon} />}
                        checkedIcon={<div className={classes.checkedIcon} />}
                        disabled={getIsVerifiedInput('passportType')}
                      />
                    }
                    labelPlacement='start'
                    label={t('profilePage.oldPassport')}
                  />
                  <FormControlLabel
                    className={classes.label}
                    value='id'
                    control={
                      <Radio
                        color='default'
                        className={classes.radio}
                        icon={<div className={classes.icon} />}
                        checkedIcon={<div className={classes.checkedIcon} />}
                        disabled={getIsVerifiedInput('passportType')}
                      />
                    }
                    labelPlacement='start'
                    label={t('profilePage.idPassport')}
                  />
                </Box>
              </RadioGroup>
            }
            name='passportType'
            control={control}
          />
          {errors.passportType?.message && (
            <FormHelperText id='component-error-text'>{t(errors.passportType?.message)}</FormHelperText>
          )}
        </FormControl>
      </VerificationInputStatus>

      {/* NumberOfPassport */}
      <VerificationInputStatus isVerified={getIsVerifiedInput('numberOfPassport')}>
        <FormControl className={classes.formControl} error={!!errors.numberOfPassport}>
          <Input
            className={classes.textField}
            placeholder={t('profilePage.numberOfPassport')}
            disableUnderline
            startAdornment={
              <img src={numberSvg} alt={t('profilePage.numberOfPassport')} className={classes.iconAdornment} />
            }
            type='text'
            name='numberOfPassport'
            onFocus={() => clearErrors('numberOfPassport')}
            inputRef={register({
              required: {
                value: true,
                message: 'error.validation.required',
              },
              pattern: {
                value: alphanumericWithSpaceAndDashRegExp,
                message: 'error.validation.numberOfPassport.invalid',
              },
              minLength: {
                value: 5,
                message: 'error.validation.numberOfPassport.minLength',
              },
              maxLength: {
                value: 15,
                message: 'error.validation.numberOfPassport.maxLength',
              },
            })}
            errorMessage={errors.numberOfPassport?.message && t(errors.numberOfPassport.message)}
            readOnly={getIsVerifiedInput('numberOfPassport')}
          />
        </FormControl>
      </VerificationInputStatus>
    </>
  );
};

export default PersonalInfoInputs;
