import { makeStyles } from '@material-ui/core/styles';
import { red } from '../../constants';

export default makeStyles({
  buttonWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    backgroundColor: 'white',
    width: '100%',
    borderTop: '2px solid green',
    zIndex: 1400,
    WebkitOverflowScrolling: 'touch',
    padding: '10px 0',
  },
  menuBtn: {
    border: 'none',
    textDecoration: 'none',
    textAlign: 'center',
  },
  svgIcon: {
    width: '30px',
    height: 'auto',
    display: 'inline-block',
    position: 'relative',

  },
  title: {
    textDecoration: 'none',
    display: 'block',
  },
  newMessageIndicator: {
    position: 'absolute',
    top: 0,
    right: -5,
    width: '10px',
    height: '10px',
    backgroundColor: red,
    borderRadius: '50%',
  },
}, { index: 1 });
