import { Box } from '@material-ui/core';
import { IOnChange, User } from 'interfaces';
import useStyles from '../SettingsPageStyle';
import ResetPassword from './ResetPassword';
import NotificationSound from './NotificationSound';
import ChangeEmail from './ChangeEmail';
import ChangePhone from './ChangePhone';
import DeleteUser from './DeleteUser';
import AppInfoItem from './AppInfoItem';

interface Props {
  notificationSound: string;
  onChange: (data: IOnChange) => void;
  user: User;
  appInfo: {
    [key: string]: string;
  };
}

const SettingsPage = ({ notificationSound, user, onChange, appInfo }: Props) => {
  const appInfoKeys = Object.keys(appInfo);
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <NotificationSound notificationSound={notificationSound} onChange={onChange} />

      <ChangeEmail email={user?.email} />

      <ResetPassword />

      <ChangePhone phone={user?.phone} onChange={onChange} />

      <DeleteUser />

      {!!appInfoKeys.length && appInfoKeys.map((key) => {
        return <AppInfoItem title={key} text={appInfo[key]} key={key} />;
      })}
    </Box>
  );
};

export default SettingsPage;
