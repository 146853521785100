import React, { useCallback, useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import { useHistory } from 'react-router';
import throttle from 'lodash.throttle';
import Preloader from 'components/Preloader/Preloader';
import {
  IOnChangeHandler,
  VendorFormDataSignUpDefaultValues,
  FormDataSignUp,
  SaveDataHandler,
  ClientFormDataSignUpDefaultValues,
  UserFormData,
  VendorFormData,
} from 'interfaces';

import { getBase64FromFile } from 'helpers';
import IndexedDB from 'indexedDB';
import { indexStyles } from '../material';
import ClientFormStep, { ClientFormStepProps } from '../steps/ClientFormStep';
import VendorFormStep, { VendorFormStepProps } from '../steps/VendorFormStep';
import VerifyEmailStep from '../steps/VerifyEmailStep';
import { INDEXEDDB_SIGNUP_TABLE_NAME, SIGNUP_FORM_STEPS } from '../../../constants';
import { formatIndexedDbData } from '../helper';

type SignUpFormStepsKeys = keyof typeof SIGNUP_FORM_STEPS;
type SignUpFormStepsValues = typeof SIGNUP_FORM_STEPS[SignUpFormStepsKeys];
interface SignUpComponentProps extends
  Omit<ClientFormStepProps, 'defaultValues' | 'onSaveData' | 'isFirstRender'>,
  Omit<VendorFormStepProps, 'defaultValues' | 'onSaveData' | 'isFirstRender'> {
  invitedBy: string;
}

const clientDefaultValues: ClientFormDataSignUpDefaultValues = {
  country: null,
  phone: '',
  firstName: '',
  lastName: '',
  patronymic: '',
  email: '',
  passportType: 'old',
  numberOfPassport: '',
  birthday: null,
  city: null,
  placeOfResidence: '',
  gender: 'male',
  password: '',
  passwordConfirm: '',
  userPhoto: null,
  passportAndFacePhoto: null,
  passportFirstPagePhoto: null,
  customerAccount: true,
  performerAccount: false,
  createVehicle: false,
  invitedBy: '',
};

const vendorDefaultValues: VendorFormDataSignUpDefaultValues = {
  vehicleYear: null,
  vehicleBrand: null,
  vehicleColor: null,
  vehicleSeatsCount: null,
  vehicleDoorsCount: null,
  vehicleModel: null,
  vehiclePhotoFront: null,
  vehiclePhotoSideLeft: null,
  driverLicenseBack: null,
  driverLicenseFront: null,
  technicalPassportBack: null,
  technicalPassportFront: null,
  vehicleType: 'sedan',
};

const SignUpComponent: React.FC<SignUpComponentProps> = ({
  onChange,
  invitedBy,
  country,
  phoneDescription,
  userPhotoDescription,
  brands,
  models,
}) => {
  const classes = indexStyles();
  const history = useHistory();
  const [formStep, setFormStep] = useState<SignUpFormStepsValues>(SIGNUP_FORM_STEPS.client);
  const [clientFormData, setClientFormData] = useState<UserFormData | null>(null);
  const [vendorFormData, setVendorFormData] = useState<VendorFormData | null>(null);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const getDBData = useCallback(async (): Promise<[UserFormData, VendorFormData]> => {
    const dbClientData = await new IndexedDB().getData(
      INDEXEDDB_SIGNUP_TABLE_NAME,
      `formSteps-${SIGNUP_FORM_STEPS.client}`,
    );

    const formattedClientData = formatIndexedDbData<UserFormData>(
      dbClientData?.value || [],
      { ...clientDefaultValues, invitedBy },
    );

    const dbVendorData = await new IndexedDB().getData(
      INDEXEDDB_SIGNUP_TABLE_NAME,
      `formSteps-${SIGNUP_FORM_STEPS.vendor}`,
    );

    const formattedVendorData = formatIndexedDbData<VendorFormData>(
      dbVendorData?.value || [],
      vendorDefaultValues,
    );

    return [formattedClientData, formattedVendorData];
  }, []);

  useEffect(() => {
    const handleGetDBData = async () => {
      const [formattedClientData, formattedVendorData] = await getDBData();
      setClientFormData(formattedClientData);
      setVendorFormData(formattedVendorData);
    };

    handleGetDBData();
  }, [formStep]);

  const handleNewStep = (data: NonNullable<FormDataSignUp>) => {
    switch (formStep) {
      case SIGNUP_FORM_STEPS.client: {
        const nextStep = data?.createVehicle ? SIGNUP_FORM_STEPS.vendor : SIGNUP_FORM_STEPS.veriff;

        setFormStep(nextStep);
        break;
      }
      case SIGNUP_FORM_STEPS.vendor: {
        setFormStep(SIGNUP_FORM_STEPS.veriff);
        break;
      }

      default: break;
    }
  };

  const handleSaveData: SaveDataHandler = async (LSKey, values) => {
    const promises = Object.entries(values).map(async ([key, value]) => {
      if (value instanceof FileList && Boolean(value.length)) {
        const [file] = Array.from(value);

        const base64 = await getBase64FromFile(file);
        return [key, { base64, type: 'file' }];
      }

      return [key, value];
    });

    const result = await Promise.all(promises);

    try {
      const resultDB = await new IndexedDB().setData(
        INDEXEDDB_SIGNUP_TABLE_NAME,
        { id: `formSteps-${SIGNUP_FORM_STEPS[LSKey]}`, value: result, updatedAt: Date.now() },
      );

      return resultDB;
    } catch (error) {
      return error;
    }
  };

  const throttledSaveData = throttle(handleSaveData, 1000) as SaveDataHandler;

  const handleChange: IOnChangeHandler = ({ action, data }) => {
    switch (action) {
      case 'submitStep': {
        handleNewStep(data);
        break;
      }

      case 'stepBack': {
        setIsFirstRender(false);
        setFormStep((prev) => {
          if (prev === SIGNUP_FORM_STEPS.veriff) {
            history.push('/signin');

            return prev;
          }

          const prevStep = (prev - 1 || 0) as SignUpFormStepsValues;
          return prevStep;
        });

        break;
      }

      default: onChange({ action, data });
    }
  };

  if (!clientFormData || !vendorFormData) return <Preloader />;

  const formData = { ...clientFormData, ...vendorFormData };

  return (
    <Container className={classes.mainContainer}>

      {formStep === SIGNUP_FORM_STEPS.client && (
      <ClientFormStep
        defaultValues={clientFormData}
        onChange={handleChange}
        userPhotoDescription={userPhotoDescription}
        country={country}
        phoneDescription={phoneDescription}
        onSaveData={throttledSaveData}
        isFirstRender={isFirstRender}
      />
      )}

      {formStep === SIGNUP_FORM_STEPS.vendor && (
      <VendorFormStep
        defaultValues={vendorFormData}
        brands={brands}
        models={models}
        onChange={handleChange}
        onSaveData={throttledSaveData}
        isFirstRender={isFirstRender}
      />
      )}

      {formData && formStep === SIGNUP_FORM_STEPS.veriff && (
      <VerifyEmailStep
        onChange={handleChange}
        formData={formData}
      />
      )}

    </Container>
  );
};

export default SignUpComponent;
