import {
  createChatRoom,
  getUsers,
  getUuidByOrder,
} from 'services/commonService';
import {
  IChat, IOrder, IUser,
} from 'interfaces';
import { CHAT_LIST_SORT_INDEX } from '../../constants';

export const getTypesFromOrder = (order: IOrder): [string, string] => {
  const {
    inCity,
    betweenCity,
    international,
    helpOnRoad,
    buyThings,
    rent,
    services,
  } = order;

  const orderTypes = {
    inCity,
    betweenCity,
    international,
    helpOnRoad,
    buyThings,
    rent,
    services,
  };

  const [types] = Object.entries(orderTypes).filter(([, value]) => value);
  return types;
};

export const configureOrderChat = async (order: IOrder, user: IUser): Promise<IChat> => {
  const {
    serviceId,
    id,
  } = order;

  const types = getTypesFromOrder(order);
  const [mainType, secondaryType] = types || [];
  const [{ vendorUserUuid, userUuid }] = await getUuidByOrder(serviceId, id);
  const chatUserUuid = user?.uuid === userUuid ? vendorUserUuid : userUuid;

  const response = await getUsers(chatUserUuid);
  const [chatUser] = response;
  const [{ roomId }] = await createChatRoom(chatUser?.uuid || '');

  return {
    user: chatUser,
    roomId,
    lastMessage: null,
    orderIds: [id],
    sortIndex: CHAT_LIST_SORT_INDEX.ORDER_CHAT,
    orderType: [[mainType, secondaryType]],
  };
};
