import { FormHelperText, TextField as TextFieldMUI, TextFieldProps as TextFieldMUIProps } from '@material-ui/core';
import { ReactElement } from 'react';
import classNames from 'classnames';
import useStyles from './styles';

export type SharedTextFieldProps = TextFieldMUIProps & {
  errorMessage?: string;
  wrapperClassName?: string;
  errorMessageClassName?: string;
};

const TextField = ({
  errorMessage,
  errorMessageClassName,
  wrapperClassName,
  className,
  ...rest
}: SharedTextFieldProps): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.inputWrapper, wrapperClassName)}>
      <TextFieldMUI
        {...rest}
        className={classNames(
          className,
          classes.input,
          { [classes.inputError]: Boolean(errorMessage) },
        )}
      />

      {errorMessage && (
        <FormHelperText
          className={classNames(
            classes.errorMessage,
            { [classes.errorMessageStartAdornment]: Boolean(rest.InputProps?.startAdornment) },
            errorMessageClassName,
          )}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </div>
  );
};

export default TextField;

TextField.defaultProps = {
  errorMessage: '',
  wrapperClassName: '',
  errorMessageClassName: '',
};
