import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useAuth } from 'services/auth';
import { createPreorder } from 'services/commonService';
import { IOnChange, IOrder } from 'interfaces';
import { useOrdersHistory, useUsersByOrder } from 'hooks/order';
import { getPreorderURLByOrder } from 'helpers';
import { formatOrderToPreorder } from 'utils/formatters';
import OrderHistoryComponent from '../components/OrderHistoryComponent';

const OrdersHistoryApp: React.FC = () => {
  const {
    vendorId,
    setIsLoading,
    vendors,
  } = useAuth();
  const history = useHistory();
  const { orders, hideOrder } = useOrdersHistory();
  const [showOrder, setShowOrder] = useState<IOrder | null>(null);
  const {
    clientUser,
    clientRating,
    vendor,
    vendorUser,
    vendorRating,
    vendorComments,
  } = useUsersByOrder(showOrder);

  const handleChatToUser = (userUuid: string) => {
    history.push(`/chat/${userUuid}`);
  };

  const handleRepeatOrder = async (order: IOrder) => {
    const formattedOrder = formatOrderToPreorder(order);

    setIsLoading(true);
    try {
      const preorder = await createPreorder(formattedOrder);
      history.push(getPreorderURLByOrder(order), { preorder });
    } catch (error) {
      const e = error as Error;
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRepeatReverseOrder = (order: IOrder) => {
    const newArray = { ...order };

    const formattedOrder = {
      ...newArray,
      path: [...newArray.path].reverse(),
      dots: [...newArray.dots].reverse(),
    };

    handleRepeatOrder(formattedOrder);
  };

  const handleChange = ({ action, data }: IOnChange) => {
    switch (action) {
      case 'setShowOrder':
        setShowOrder(data);
        break;
      case 'chatToUser':
        handleChatToUser(data);
        break;
      case 'repeatOrder':
        handleRepeatOrder(data);
        break;
      case 'repeatReverseOrder':
        handleRepeatReverseOrder(data);
        break;
      case 'deleteOrderForMe':
        hideOrder(data);
        break;
      default: break;
    }
  };

  return (
    <OrderHistoryComponent
      vendors={vendors}
      onChange={handleChange}
      user={vendorId ? clientUser : vendorUser}
      imVendor={Boolean(vendorId)}
      vendor={vendor}
      vendorComments={vendorComments}
      showOrder={showOrder}
      userRating={vendorId ? clientRating : vendorRating}
      orders={orders}
    />
  );
};

export default OrdersHistoryApp;
