import { useEffect, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { useHistory } from 'react-router';

import UserInfoBlock from 'pages/Chat/components/UserInfoBlock';
import MessagesBlock from 'components/Chat/MessagesBlock';
import Preloader from 'components/Preloader/Preloader';
import chatReplyIcon from 'components/common/chatReply.png';

import { getTranslateFunction } from 'helpers';
import { useReplyChat } from 'hooks/chat';
import { formatMessagesToChat } from 'utils/formatters';
import { useAuth } from 'services/auth';
import { useLayout } from 'context/layout';
import useStyles from '../ActiveChat/styles';

const SupportChat = () => {
  const t = getTranslateFunction();
  const { user } = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const { setTitle } = useLayout();

  const {
    messages,
    isLoading,
    loadMoreMessages,
  } = useReplyChat();

  const handleGoBack = () => {
    if (history.action === 'POP') {
      history.push('/chat');
      return;
    }

    history.goBack();
  };

  const sortedMessages = useMemo(() => (
    formatMessagesToChat(messages)
  ), [messages]);

  useEffect(() => {
    setTitle(t('replyChatPage.title'));

    return () => {
      setTitle('');
    };
  }, []);

  return (
    <Box className={classes.chat}>
      <Box className={classes.chatUserInfoWrapper}>
        <Box onClick={handleGoBack}>
          <ArrowBackIos className={classes.arrowIcon} fontSize="large" />
        </Box>

        <UserInfoBlock
          imgUrl={chatReplyIcon}
          firstName={t('chatPage.replyChat.PP')}
          lastName={t('chatPage.replyChat.WW')}
        />
      </Box>

      <MessagesBlock
        loadMoreMessages={loadMoreMessages}
        messages={sortedMessages}
        usermeUuid={user!.uuid}
        className={classes.container}
      />

      {isLoading && <Preloader />}
    </Box>
  );
};

export default SupportChat;
