import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useOrders } from 'services/orders';
import { useAuth } from 'services/auth';
import { deleteVendor, getMyVendors } from 'services/commonService';
import { IOnChange, Vendor } from 'interfaces';
import { useSettings } from 'pages/SettingsPage/SettingsContext';
import { useAppInfoTextOnly } from 'hooks/appInfo';
import VendorListComponent from '../components/VendorListComponent';

const POSITIONS = ['menuYourTransportTop'];

const VendorsListApp = () => {
  const { setActiveVendor, activeVendor } = useSettings();
  const { setIsLoading, setVendor } = useAuth();
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const history = useHistory();
  const { orders } = useOrders();
  const appInfo = useAppInfoTextOnly(POSITIONS);

  const formattedVendors = useMemo(() => {
    const vendorsOrders = orders.reduce((acc: { [key: number]: number }, order) => ({
      ...acc,
      [order.serviceId]: (acc[order.serviceId] ?? 0) + 1,
    }), {});

    return vendors.map((vendor) => ({
      ...vendor,
      ordersNumber: vendorsOrders[vendor.id],
    }));
  }, [orders, vendors]);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const response = await getMyVendors();
        setVendors(response);
      } catch (error) {
        const e = error as Error;
        toast.error(e.message);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  const handleSetActiveVendor = async (vendorId: number) => {
    setIsLoading(true);
    try {
      await setActiveVendor(vendorId);
      const newActiveVendor = vendors.find((vendor) => vendor.id === vendorId);
      if (!newActiveVendor) return;

      setVendor(newActiveVendor);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnsetActiveVendor = async () => {
    setIsLoading(true);
    try {
      await setActiveVendor(null);
      localStorage.removeItem('uuid');
      setVendor(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteVendor = async (vendorId: number) => {
    setIsLoading(true);
    try {
      await deleteVendor(vendorId);
      const filteredVendors = vendors.filter(({ id }) => id !== vendorId);
      setVendors(filteredVendors);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRoute = useCallback((to: string, data?: { vendor: Vendor }) => {
    history.push(to, data);
  }, []);

  const handleChange = ({ action, data }: IOnChange) => {
    switch (action) {
      case 'goToUpdateVendor': {
        handleRoute('/update-vendor', { vendor: data });
        break;
      }
      case 'goToCreateVendor': {
        handleRoute('/create-vendor');
        break;
      }
      case 'activateVendor': {
        handleSetActiveVendor(data);
        break;
      }
      case 'deactivateVendor': {
        handleUnsetActiveVendor();
        break;
      }
      case 'deleteVendor': {
        handleDeleteVendor(data);
        break;
      }
      default:
        break;
    }
  };

  return (
    <VendorListComponent
      onChange={handleChange}
      vendors={formattedVendors}
      activeVendor={activeVendor}
      menuYourTransportTop={appInfo.menuYourTransportTop}
    />
  );
};

export default VendorsListApp;
