import { GetIsVerifiedInput, HandleUpdateUserFile } from '../containers/UserInfo';
import FileInput from './FileInput';

interface Props {
  getIsVerifiedInput: GetIsVerifiedInput;
  handleUpdateUserFile: HandleUpdateUserFile;
  passportFirstPagePhotoSrc: string;
  passportAndFacePhotoSrc: string;
}

const DocumentInputs = ({
  getIsVerifiedInput,
  handleUpdateUserFile,
  passportFirstPagePhotoSrc,
  passportAndFacePhotoSrc,
}: Props) => {
  const DOCUMENT_FIELDS = [
    {
      name: 'passportFirstPagePhoto',
      action: 'updatePassportFirstPagePhoto',
      preview: passportFirstPagePhotoSrc,
    },
    {
      name: 'passportAndFacePhoto',
      action: 'updatePassportAndFacePhoto',
      preview: passportAndFacePhotoSrc,
    },
  ];

  return (
    <>
      {DOCUMENT_FIELDS.map(({ name, action, preview }) => {
        return (
          <FileInput
            key={name}
            name={name}
            preview={preview}
            onChange={(files: FileList | null) => handleUpdateUserFile(files, action)}
            getIsVerifiedInput={getIsVerifiedInput}
          />
        );
      })}
    </>
  );
};

export default DocumentInputs;
