import { makeStyles } from '@material-ui/core';
import {
  red,
  cyan,
  green,
  textColor,
  grayBG,
  chatBackgroundColor,
} from '../../constants';

export default makeStyles({
  table: {
    border: `1px solid ${textColor}`,
    borderSpacing: 0,
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHead: {
  },
  tableBody: {
  },
  tableRow: {
    borderBottom: `1px solid ${textColor}`,
    backgroundColor: grayBG,
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:nth-child(2n)': {
      backgroundColor: chatBackgroundColor,
    },
  },
  tableCell: {
    borderRight: `1px solid ${textColor}`,
    '& .MuiTypography-root': {
      fontSize: '11px',
      paddingLeft: 10,
    },
    '&:last-child': {
      borderRight: 'none',
    },
  },
  green: {
    color: green,
    fontWeight: 'bold',
  },
  cyan: {
    color: cyan,
  },
  red: {
    color: red,
  },
  tableCellVertical: {
    width: '50%',
    verticalAlign: 'top',
  },
}, { index: 1 });
