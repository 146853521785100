import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import ArrowRight from 'images/ArrowRight';
import { IChat, IOnChange, IUser } from 'interfaces';
import { getTranslateFunction, getUserImageLink } from 'helpers';
import useStyles from '../ChatStyle';
import UserInfoBlock from './UserInfoBlock';
import { green, red } from '../../../constants';

interface Props extends Pick<IChat, 'lastMessage' | 'orderType'>,
  Pick<IUser, 'userPhoto' | 'gender' | 'firstName' | 'lastName' | 'uuid'> {
  onChange: (a: IOnChange) => void;
}

const ChatListItem: React.FC<Props> = ({
  lastMessage,
  orderType,
  onChange,
  userPhoto,
  gender,
  firstName,
  lastName,
  uuid: userUuid,
}: Props) => {
  const t = getTranslateFunction();
  const classes = useStyles();

  const arrowColor = useMemo(() => {
    if (!lastMessage) return green;

    const isMeReadedMessage = lastMessage.fromUser === userUuid ? lastMessage.isRead : true;

    return isMeReadedMessage ? green : red;
  }, [lastMessage, userUuid]);

  const photoSrc = useMemo(() => getUserImageLink(userPhoto, gender), [userPhoto, gender]);

  const handleSetActiveChat = () => {
    onChange({
      action: 'setActiveChat',
      data: userUuid,
    });
  };

  const lastMessageText = useMemo(() => {
    if (!lastMessage) return null;

    return lastMessage.messageData;
  }, [lastMessage]);

  const types = orderType.map(([mainType, secondaryType]) => (
    `${t(mainType)} / ${t(secondaryType)}`
  )).join(', ');

  return (
    <Box className={classes.chatsListItem}>
      <UserInfoBlock
        imgUrl={photoSrc}
        firstName={firstName}
        lastName={lastName}
        title={lastMessageText}
        orderType={types}
        messageTime={lastMessage?.createdAt}
        onChange={onChange}
      />
      <Box onClick={handleSetActiveChat}>
        <ArrowRight height={40} width={40} color={arrowColor} />
      </Box>
    </Box>
  );
};

export default ChatListItem;
