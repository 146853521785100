import { Box, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { UseFormMethods, useWatch } from 'react-hook-form';
import classNames from 'classnames';
import Icons from 'components/Icons';
import { CFDatePickerItem } from 'interfaces';
import useStyles from '../ConstructorStyles';

type FormMethods = 'register' | 'getValues' | 'setValue' | 'control';

interface Props extends Pick<UseFormMethods, FormMethods> {
  item: CFDatePickerItem;
  control: any;
  setValue: any;
  getValues: any;
  register: any;
  errors: any;
}

const DatePickerItem: React.FC<Props> = ({
  item,
  control,
  setValue,
  getValues,
  register,
  errors,
}: Props) => {
  const { name, withoutBorder } = item;
  const nowDate = new Date();
  const isoString = nowDate.toISOString();
  const formattedDate = isoString.slice(0, 16);
  const minDate = `${nowDate.toISOString().slice(0, 10)}T00:00`;

  const classes = useStyles();

  useEffect(() => {
    setValue(name, getValues(name) || '');
  }, [getValues]);

  const tripTimeValue = useWatch({
    control,
    name: 'tripTime',
  });

  if (tripTimeValue === 'now') {
    return null;
  }

  return (
    <Box data-testid="datePicker" key={name} className={classNames(classes.inputContainer, { withoutBorder })}>
      <TextField
        className={classes.datePicker}
        type="datetime-local"
        defaultValue={formattedDate}
        name={name}
        InputProps={{
          disableUnderline: true,
          inputProps: { min: minDate },
          startAdornment: (
            <img className={classes.svg} src={Icons.toTimeSvg} alt="dateAndTimeIcon" />
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputRef={register({
          required: true,
        })}
        error={!!errors[name]}
        helperText={errors[name]?.message}
      />
    </Box>
  );
};

export default DatePickerItem;
