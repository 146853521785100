import { ChangeEvent, useRef, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { ReactComponent as DownloadSvg } from 'images/icons/download.svg';
import { getTranslateFunction } from 'helpers';
import { INPUT_FILE_ACCEPTABLE_EXTENSIONS } from '../../../constants';
import VerificationInputStatus from './VerificationInputStatus';
import { GetIsVerifiedInput } from '../containers/UserInfo';
import useStyles from '../ProfilePageStyle';

interface Props {
  name: string;
  preview: string;
  onChange: (val: FileList | null) => void;
  getIsVerifiedInput: GetIsVerifiedInput;
}

const FileInput = ({ name, preview, onChange, getIsVerifiedInput }: Props) => {
  const t = getTranslateFunction();
  const classes = useStyles();

  const inputRef = useRef<HTMLInputElement>(null);
  const [previewImg, setPreviewImg] = useState<string>(preview);
  const isVerified = getIsVerifiedInput(name);

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (!files?.length) {
      setPreviewImg(preview);
      return;
    }

    const [file] = files;

    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      setPreviewImg(event.target?.result as string);
    };

    reader.readAsDataURL(file);

    onChange(files);
  };

  return (
    <Box className={classes.fileInput} onClick={handleClick}>
      <input
        accept={INPUT_FILE_ACCEPTABLE_EXTENSIONS}
        ref={inputRef}
        type='file'
        hidden
        onChange={handleFileChange}
        disabled={isVerified}
      />

      <img className={classes.fileInputImg} src={previewImg} alt='Preview' />

      <Box className={classes.fileInputBtnWrapper}>
        <Typography className={classes.fileInputText}>{t(`profilePage.${name}`)}</Typography>

        <VerificationInputStatus isVerified={isVerified}>
          <div
            className={classNames(classes.fileInputIconWrapper, {
              [classes.hideIcon]: isVerified,
            })}
          >
            <DownloadSvg className={classes.fileInputSvg} />
          </div>
        </VerificationInputStatus>
      </Box>
    </Box>
  );
};

export default FileInput;
