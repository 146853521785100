import React, { FC } from 'react';

interface Props {
  color: string;
}

const SecurityPay: FC<Props> = ({ color }) => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 72 72">
    <path fill={color} d="M11.46 43.25h14.12v5.32H11.46zM47.87 39.88v17.97h22.32V39.88H47.87zM60.13 49.9v2.93c0 .61-.49 1.1-1.1 1.1-.61 0-1.1-.49-1.1-1.1V49.9a2.95 2.95 0 01-1.85-2.74 2.949 2.949 0 115.9 0c0 1.24-.77 2.3-1.85 2.74zM50.74 37.44v1.4h2.95v-1.4c0-2.94 2.4-5.34 5.34-5.34s5.34 2.4 5.34 5.34v1.4h2.95v-1.4c0-4.57-3.72-8.29-8.29-8.29s-8.29 3.72-8.29 8.29z" />
    <path fill={color} d="M7.26 35.78v-5.32h45.21c1.72-1.61 4.02-2.61 6.56-2.61 1.39 0 2.7.3 3.89.83V14.15H1.81v43.7H46.3v-5.32H7.26V35.78zm0-16.31h50.22v4.38H7.26v-4.38z" />
  </svg>
);
export default SecurityPay;
