import React from 'react';

const acceptedTypes = ['jpg', 'jpeg', 'png'];

interface Props {
  show: boolean;
  onChange: (a: FileList | null) => void;
}

const ImageUpploader: React.FC<Props> = ({
  onChange,
  show,
}: Props) => (
  <input
    accept={acceptedTypes.map((type: string) => `image/${type}`).join(', ')}
    type="file"
    onChange={(e) => onChange(e.target.files)}
    hidden={!show}
  />
);

export default ImageUpploader;
