import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAppInfo } from 'helpers';
import { AppInfoTextOnlyItem, Lang } from 'interfaces';
import { getAppInfo } from 'services/commonService';

export const useAppInfoTextOnly = (positions: string[]): {
  [key: string]: string;
} => {
  const { i18n } = useTranslation('general');
  const [appInfo, setAppInfo] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    const handleGetAppInfo = async () => {
      const response = await getAppInfo() as AppInfoTextOnlyItem[];
      const result = formatAppInfo(response, i18n.language as Lang, positions);
      setAppInfo(result);
    };

    handleGetAppInfo();
  }, [i18n.language]);

  return appInfo;
};
