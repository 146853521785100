import { Box, Button } from '@material-ui/core';
import {v4 as uuid} from 'uuid';
import {
  ArrowBackIos,
  ArrowForwardIos,
  Clear,
  GetApp,
} from '@material-ui/icons';
import Portal from 'pages/SignUpPage/Portal';
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import useWindowSize from 'services/useWindowSize';
import useStyles from './GalleryStyle';

interface WindowSize {
  width: number | undefined;
  height: number | undefined;
}

interface Image {
  src: string;
  title: string;
}

interface Props {
  images: Image[];
  acceptDownload?: boolean;
}

const Gallery: React.FC<Props> = ({
  images,
  acceptDownload,
}: Props) => {
  const {height, width}: WindowSize = useWindowSize();
  const classes = useStyles({height, width});
  const [activeImg, setActiveImage] = useState<number>(0);
  const galleryIsOpen: boolean = useMemo(() => (Boolean(activeImg)), [activeImg]);

  if (!(images.length || images)) {
    return null;
  }

  const handleCloseGallery = () => setActiveImage(0);
  const handleOpenImage = (id: number) => setActiveImage(id + 1);

  const returnImages = useMemo(() => (
    images.map((image: Image, index: number) => (
      <Box key={image.src} onClick={() => handleOpenImage(index)} className={classes.previewImgBox}>
        <img className={classes.previewImg} src={image.src} alt={image.title} />
      </Box>
    ))
  ), [images]);

  const goToNextImage = useCallback(() => {
    if (!(galleryIsOpen && activeImg)) return;
    setActiveImage(activeImg + 1);
  }, [activeImg, images]);

  const goToPreviousImage = useCallback(() => {
    if (!(galleryIsOpen && activeImg)) return;
    const newId = activeImg - 1;
    setActiveImage(newId < 1 ? images.length : newId);
  }, [activeImg, images]);

  const currentImage: Image | false = useMemo(() => {
    const index = (activeImg - 1) % images.length;
    return (galleryIsOpen && images[index]);
  }, [activeImg, galleryIsOpen, images]);

  const fileNameDownload = useMemo(() => `${uuid()}.jpg`, [activeImg]);

  const showButtonContainer = acceptDownload;

  return (
    <>
      <Box className={classes.previewBox}>
        {returnImages}
      </Box>
      {galleryIsOpen && currentImage && (
      <Portal>
        <Box className={classes.backDrop}>
          <Box className={classes.content}>
            <Button className={classes.closeBtn} onClick={handleCloseGallery}>
              <Clear fontSize="large" />
            </Button>
            <Box className={classes.activeImgBox}>
              <Box className={classes.activeImgContainer}>
                <img
                  className={classes.activeImg}
                  src={currentImage.src}
                  alt={currentImage.title}
                />

                {showButtonContainer && (
                  <Box className={classes.buttonsContainer}>
                    {acceptDownload && (
                      <a
                        download={fileNameDownload}
                        className={classes.downloadIcon}
                        href={currentImage.src}
                      >
                        <GetApp />
                      </a>
                    )}
                  </Box>
                )}
              </Box>
              {returnImages.length > 1 && (
                <Box className={classes.controlBtnsBox}>
                  <Button onClick={goToPreviousImage}>
                    <ArrowBackIos />
                  </Button>
                  <Button onClick={goToNextImage}>
                    <ArrowForwardIos />
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Portal>
      )}
    </>
  );
};

export default Gallery;

Gallery.defaultProps = {
  acceptDownload: false,
};
