import React, { useMemo } from 'react';
import { Box, Avatar, Typography } from '@material-ui/core';
import { cyan } from '@material-ui/core/colors';
import { Star } from '@material-ui/icons';
import classNames from 'classnames';
import PreorderSingleTypeComponent from 'components/PreorderSingleTypeComponent';
import {
  IComment,
  IOrder,
  RatingType,
  User,
} from 'interfaces';
import icons from 'components/Icons';
import { getUserImageLink } from 'helpers';
import useStyles from '../OrderPageStyle';

interface Props {
  user: User;
  userRating: RatingType | undefined;
  userComments: IComment[];
  order: IOrder;
}

const { CommentTsx } = icons;

const SingleTypeOrderInfo: React.FC<Props> = ({
  user,
  userRating,
  userComments,
  order,
}: Props) => {
  const classes = useStyles();
  const photoSrc = useMemo(() => (user && getUserImageLink(user.userPhoto, user.gender)), [user]);
  if (!user) return null;

  return (
    <Box className={classes.ratingOrderInfoBox}>
      <Box className={classes.userContainerBuyForm}>
        <Box className={classes.userContainer}>
          <Box className={classes.userName}>{user.firstName}</Box>
          <Avatar variant="rounded" className={classes.userPhoto} alt={user.firstName} src={photoSrc || ''} />
        </Box>
        <Box className={classes.userStats}>
          {userRating && (
            <Box className={classes.listItem}>
              <Star className={classNames(classes.svg, 'star')} />
              <Typography>{Math.floor(Number(userRating.politenessRatingAvg))}</Typography>
            </Box>
          )}
          {Boolean(userComments.length) && (
            <Box className={classes.listItem}>
              <Box className={classes.svg}>
                <CommentTsx color={cyan} />
              </Box>
              <Typography>{userComments.length}</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <PreorderSingleTypeComponent
        order={order}
      />
    </Box>
  );
};

export default SingleTypeOrderInfo;
