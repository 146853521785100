import React from 'react';
import { IconComponentProps } from 'interfaces';

const Complaint: React.FC<IconComponentProps> = ({ color, width = 20, height = 20 }) => (
  <svg
    width={width}
    height={height}
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 72 72"
  >
    <path
      fill={color}
      d="M36.06,3.57C18.16,3.57,3.64,18.09,3.64,36c0,17.91,14.52,32.43,32.43,32.43S68.49,53.91,68.49,36
C68.49,18.09,53.97,3.57,36.06,3.57z M32.24,12.78h8.24v30.55h-8.24V12.78z M36.06,57.75c-2.9,0-5.24-2.35-5.24-5.24
c0-2.9,2.35-5.24,5.24-5.24c2.9,0,5.24,2.35,5.24,5.24C41.31,55.41,38.96,57.75,36.06,57.75z"
    />
  </svg>
);

export default Complaint;
