import { makeStyles } from '@material-ui/core';
import {
  cyan,
  backgroundColor,
  grayBG,
  red,
  green,
  textColor,
  textNoWrap,
  orange,
} from '../../constants';

export default makeStyles({
  paymentContainer: {
    padding: '10px 10px 60px 10px',
    boxSizing: 'border-box',
  },
  balanceListItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  balanceListItemTitle: {
    fontSize: '13px',
    padding: '5px',
    boxSizing: 'border-box',
  },
  balanceEndAdornment: {
    ...textNoWrap,
    marginLeft: '5px',
    width: '150px',
    backgroundColor: grayBG,
    padding: '6px 5px 0',
    textAlign: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  balanceEndAdornmentGreen: {
    backgroundColor: green,
    color: backgroundColor,
  },
  balanceEndAdornmentRed: {
    backgroundColor: red,
    color: backgroundColor,
  },
  accountNumber: {
    ...textNoWrap,
    marginLeft: '5px',
    width: '150px',
    fontSize: '11px',
    backgroundColor: cyan,
    color: backgroundColor,
    padding: '6px 5px 0',
    textAlign: 'center',
  },
  formattedValue: {
    textAlign: 'end',
    fontSize: '11px',
  },
  currency: {
    textAlign: 'center',
    fontSize: '11px',
  },
  infoBox: {
    margin: '10px 0',
    padding: '5px',
    backgroundColor: grayBG,
    width: '100%',
    boxSizing: 'border-box',
    '& .MuiTypography-root': {
      textAlign: 'center',
      fontSize: '12px',
    },
  },
  topUpWalletSubmitButton: {
    backgroundColor: orange,
    color: backgroundColor,
    width: '50%',
    height: 33,
    marginLeft: 10,
    ...textNoWrap,
  },
  incomeTable: {
    margin: '10px 0',
  },
  formControl: {
    minHeight: 30,
    display: 'block',

    '&:not(:first-child)': {
      marginTop: 10,
    },
  },
  recieverLabel: {
    color: cyan,
    fontSize: 13,
    paddingTop: 5,
  },
  resetFormButtonWrapper: {
    marginTop: 10,
    textAlign: 'center',
  },
  confirmedButton: {
    marginTop: 10,
    backgroundColor: orange,
    color: backgroundColor,
    width: '100%',
  },
  confirmedLabel: {
    backgroundColor: green,
    color: backgroundColor,
    marginLeft: 10,
    padding: '0 10px',
    maxWidth: '50%',
    display: 'flex',
    alingItems: 'center',
  },
  formRowWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  termsOfUse: {
    color: cyan,
    display: 'block',
    transition: 'scale 0.2s ease-out',
    '&:hover': {
      scale: 1.1,
      backgroundColor: 'transparent',
    },
  },
  label: {
    backgroundColor: grayBG,
    color: textColor,
    width: '100%',
    paddingLeft: 5,
    paddingTop: 5,
    fontSize: 13,
  },
  textIcon: {
    marginRight: '5px',
    color: cyan,
    fontSize: 14,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  svg: {
    width: 30,
    height: 30,
    marginRight: '5px',
  },
  statsSvg: {
    width: 25,
    height: 25,
    marginRight: '5px',
    alignSelf: 'center',
  },
  input: {
    width: '100%',
    backgroundColor: grayBG,
    fontSize: 13,
    border: '2px transparent solid',
    padding: '0 5px',
  },
  inputError: {
    borderColor: red,
    color: red,
  },
  commisionItem: {
    '&::after': {
      content: '", "',
    },
    '&:last-child::after': {
      content: 'none',
    },
  },
  balanceListItemLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  sendFuncSubmit: {
    width: '100%',
    marginTop: '10px',
  },
  serviceUnavailable: {
    color: red,
    textAlign: 'center',
  },
  hidden: {
    display: 'none',
  },
  modalCodeErrorMessage: {
    color: red,
    textAlign: 'center',
    fontSize: 18,
  },
  errorModal: {
    padding: 50,
  },
  commisionBox: {
    margin: '20px 0',
  },
  limitCurrency: {
    color: red,
    textAlign: 'center',
    marginTop: 10,
  },
  hr: {
    marginTop: 10,
    width: '70%',
  },
  errorMessage: {
    marginLeft: 35,
  },
}, { index: 1 });
