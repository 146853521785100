import { Box } from '@material-ui/core';
import CollapseBox from 'components/CollapseComponent';

import { getTranslateFunction } from 'helpers';

import useStyles from '../SettingsPageStyle';

type Props = {
  title: string;
  text: string;
};

const AppInfoItem = ({ title, text }: Props) => {
  const t = getTranslateFunction();
  const classes = useStyles();

  return (
    <CollapseBox title={t(`settings.${title}.title`)} withoutMargins isScrollable>
      <Box className={classes.appInfoText} dangerouslySetInnerHTML={{ __html: text }} />
    </CollapseBox>
  );
};

export default AppInfoItem;
