import { Box } from '@material-ui/core';
import CollapseBox from 'components/CollapseComponent';
import React from 'react';
import useStyles from '../styles';

export type Item = {
  header: string;
  text: string;
};

type Props = {
  items: Item[];
};

const HowProjectWorkComponent: React.FC<Props> = ({ items }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.page}>
      {items.map(({ header, text }) => (
        <CollapseBox title={header} key={header}>
          <Box className={classes.text} dangerouslySetInnerHTML={{ __html: text }} />
        </CollapseBox>
      ))}
    </Box>
  );
};

export default HowProjectWorkComponent;
