import React from 'react';
import { IconComponentProps } from 'interfaces';

const Profile: React.FC<IconComponentProps> = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 72"
  >
    <circle fill={color} cx="36" cy="17.41" r="16.51" />
    <path fill={color} d="M48.15 36.25h-5.97L36 46.95l-6.18-10.71h-5.97c-8.51 0-15.4 5.16-15.4 15.4v19.61h10.98v-2.23h4.47c.32-1.74 1-3.36 1.97-4.76l-3.16-3.16 3.15-3.15 3.16 3.16c1.4-.97 3.02-1.65 4.76-1.97v-4.47h4.45v4.47c1.74.32 3.36 1 4.76 1.97l3.16-3.16 3.15 3.15-3.16 3.16c.97 1.4 1.65 3.02 1.97 4.76h4.47v2.23h10.98v-19.6c0-10.25-6.9-15.4-15.41-15.4z" />
    <path
      fill={color}
      d="M36 63.72c-4.16 0-7.53 3.37-7.53 7.53h15.07c-.01-4.15-3.38-7.53-7.54-7.53z"
    />
  </svg>
);

export default Profile;
