import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from 'services/auth';
import RequestService from 'services/requestApi';

export type ModelItem = {
  id: number,
  brand: string,
  model: string,
  createdAt: string,
  updatedAt: string,
};

type ReturnType = {
  brands: string[];
  models: ModelItem[];
  getModels: (brand: string) => void;
};

export const useBrands = (): ReturnType => {
  const { setIsLoading } = useAuth();
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState<ModelItem[]>([]);

  const getBrands = async () => {
    setIsLoading(true);
    try {
      const response = await new RequestService('/car-lists/all-brands').get();

      setBrands(response);
    } catch (curError) {
      const e = curError as Error;
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBrands();
    return () => setBrands([]);
  }, []);

  const handleGetModels = useCallback(async (brand: string) => {
    setIsLoading(true);
    try {
      const response = await new RequestService(`/car-lists/models-for-brand/${brand}`).get();
      setModels(response);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    brands,
    getModels: handleGetModels,
    models,
  };
};
