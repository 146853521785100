import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import giftsIcon from 'images/gifts';
import instructionIcon from 'images/instruction';
import ordersIcon from 'images/orders';
import profileIcon from 'images/profile';
import projectsIcon from 'images/projects';
import securityIcon from 'images/security';
import settingsIcon from 'images/settings';
import supportIcon from 'images/support';
import shareIcon from 'images/share';
import ExitIcon from 'images/exit';
import infoIcon from 'images/info';
import languageIcon from 'images/language';
import transactionIcons from 'images/transaction';
import historyIcon from 'images/history';
import chatLogo from 'images/chat';
import homeIcon from 'images/home';
import YourTransport from 'images/yourTransport';

import {
  Box,
  Button,
  IconButton,
  SwipeableDrawer,
  Typography,
} from '@material-ui/core';
import LanguagesModal from 'components/LanguagesModal';
import classNames from 'classnames';
import PowerActivity from 'images/powerActivity';
import Volume from 'images/volume';
import TextField from 'sharedComponents/TextField';
import navUpIcon from 'images/icons/navUp.svg';
import searchSvg from 'images/icons/search.svg';
import navDownIcon from 'images/icons/navDown.svg';
import { useSettings } from 'pages/SettingsPage/SettingsContext';
import { getEditorPages, getVendor } from 'services/commonService';
import { useAuth } from 'services/auth';
import {
  IconComponentProps, Lang, ListItem, Vendor,
} from 'interfaces';
import { getTranslateFunction } from 'helpers';
import { controlButtonsList } from 'components/ControlButtons/config';
import { useLayout } from 'context/layout';
import { useOrders } from 'services/orders';
import useStyles from './AppNavigationStyles';
import { cyan, green, red } from '../../constants';
import { generateNavigationItems } from './config';

export interface AppNavigationProps {
  noMenu?: boolean;
  children?: React.ReactNode;
  title?: string;
}

const icons: Record<string, React.FC<IconComponentProps>> = {
  giftsIcon,
  instructionIcon,
  ordersIcon,
  profileIcon,
  projectsIcon,
  securityIcon,
  settingsIcon,
  supportIcon,
  shareIcon,
  languageIcon,
  infoIcon,
  chatLogo,
  historyIcon,
  transactionIcons,
  homeIcon,
  YourTransport,
};

interface Page {
  id: number,
  pageName: string;
  pageContent: string;
  isPageActive: boolean;
  pageId: string;
  createdAt: string;
  updatedAt: string;
}

const AppNavigation: React.FC<AppNavigationProps> = ({ noMenu = false, children, title }) => {
  const [pages, setPages] = useState<Page[]>([]);
  const params = useParams<{ [prop: string]: string }>();
  const [projectType, setProjectType] = useState('');
  const history = useHistory();
  const { location } = history;
  const { i18n } = useTranslation('general');
  const t = getTranslateFunction();
  const { handleLogout: clearOrders } = useOrders();
  const {
    vendorId,
    vendors,
    signout,
    setVendor,
    setIsLoading,
  } = useAuth();

  const { searchInput, setSearchInput, shouldShowSearchInput } = useLayout();
  const {
    pushNotificationSound,
    pushNotificationState,
    activeVendor,
    userStatus,
    updateUserSettings,
    updateUserLanguage,
  } = useSettings();
  const [isOpen, setIsOpen] = useState(false);
  const [showLang, setShowLang] = useState(false);
  const classes = useStyles();
  const [currentItem, setCurrentItem] = useState<ListItem | null>(null);

  const menuItemsList = useMemo(() => {
    const defaultItems = generateNavigationItems(Boolean(vendorId));
    const activePages =pages.filter(({ isPageActive }) => isPageActive);

    const pagesNavigationItems = activePages.map(({ id, pageName }) => ({
      label: pageName,
      path: id,
      info: { type: 'navigate' },
      to: `/p/${id}`,
    }));

    defaultItems.splice(2, 0, ...pagesNavigationItems);
    return defaultItems;
  }, [vendorId, pages]);

  const NAVIGATION_ITEMS = [...menuItemsList, ...controlButtonsList as ListItem[]];

  const currentLocation = location.pathname;

  const handleGetPages = async () => {
    setIsLoading(true);
    try {
      const response = await getEditorPages();
      setPages(response);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetPages();

    return () => {
      setPages([]);
    };
  }, []);

  const handleChangeVolume = useCallback(() => {
    updateUserSettings({ pushNotificationSound: pushNotificationSound ? '' : 'disabled'});
  }, [pushNotificationSound]);

  const handleChangeNotifications = () => updateUserSettings({ pushNotificationState: pushNotificationState ? '' : 'disabled'});

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    NAVIGATION_ITEMS.forEach((item) => {
      if (item.to === currentLocation) {
        setCurrentItem(item);
        if (item.info?.type === 'projects') {
          const currentType = params.id;
          setProjectType(t(currentType));
        }
      }
    });
    return () => {
      setCurrentItem(null);
    };
  }, [history, t, params]);

  function handleSelect(item: ListItem) {
    switch (item.info?.type) {
      case 'home': {
        history.push('/');
        break;
      }
      case 'navigate': {
        const to = item.to ?? '';
        history.push(to);
        setIsOpen(false);
        break;
      }
      case 'performer-offers': {
        history.push('/performer-offers');
        break;
      }
      case 'lang': {
        setShowLang(false);
        i18n.changeLanguage(item.info.lang);
        updateUserLanguage(item.info.lang as Lang);
        break;
      }
      case 'langBtn': {
        setShowLang(!showLang);
        break;
      }
      default: break;
    }
  }

  const goHome = useCallback(() => {
    history.push('/');
  }, []);

  const setActiveVendor = async (): Promise<Vendor | null> => {
    if (!activeVendor) {
      history.push('/vendors-list');
      return null;
    }

    const [vendorResponse] = await getVendor(activeVendor);
    updateUserSettings({ userStatus: 'vendor' });

    setVendor(vendorResponse);
    return vendorResponse;
  };

  const selectVendorStatus = useCallback(async () => {
    setIsOpen(false);
    const result = await setActiveVendor();
    if (result) goHome();
  }, [activeVendor, vendors]);

  const isVendorActive = useMemo(() => Boolean(vendorId), [vendorId]);

  const selectClientStatus = useCallback(() => {
    setVendor(null);
    setIsOpen(false);
    updateUserSettings({ userStatus: 'client' });
    goHome();
  }, []);

  useEffect(() => {
    if (!userStatus) return;

    if (userStatus === 'client') {
      setVendor(null);
    }
    if (userStatus === 'vendor') {
      setActiveVendor();
    }
  }, [userStatus, activeVendor]);

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      clearOrders();
      await signout();
      history.push('/signin');
    } finally {
      setIsLoading(false);
    }
  };

  const onClick = () => setIsOpen((prev) => !prev);

  return (
    <>
      <Box className={classes.navContainer}>
        <Box className={classes.navigationBtns}>
          <Box className={classes.burgerIcon} data-burger-btn="icon" onClick={onClick}>
            <img src={isOpen ? navUpIcon : navDownIcon} alt="menuOpenIcon" className={classes.menuIcon} />
          </Box>
          {vendorId && (
            <Box className={classes.settingsIcons}>
              <IconButton
                className={`${pushNotificationState && classes.disableNotifications}`}
                onClick={handleChangeNotifications}
              >
                <PowerActivity width="35px" color={pushNotificationState ? red : green} />
              </IconButton>
              <IconButton onClick={handleChangeVolume}>
                <Volume width="35px" color={pushNotificationSound ? red : green} />
              </IconButton>
            </Box>
          )}

          {isOpen && (
            <Typography variant="body1" noWrap>
              {t('header.yourAccount')}
            </Typography>
          )}

          {!isOpen && currentItem && (
            <Typography variant="body1" noWrap>
              {`${t(currentItem.label)} ${projectType && ` - ${projectType}`}`}
            </Typography>
          )}

          {!isOpen && title && (
            <Typography>{title}</Typography>
          )}
          {shouldShowSearchInput && (
            <TextField
              className={classes.searchField}
              value={searchInput}
              onChange={({ target: { value } }) => setSearchInput(value)}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <img className={classes.svg} src={searchSvg} alt="search" />
                ),
              }}
            />
          )}
          {children}
        </Box>
        <SwipeableDrawer
          open={isOpen}
          onClose={onClick}
          onOpen={onClick}
          PaperProps={{ className: classes.drawerPaper }}
          anchor="left"
        >
          <Box className={classes.menuContainer}>
            {!noMenu && menuItemsList.map((item: ListItem) => {
              const {
                info, label, colorType, icon, to,
              } = item;

              const isChecked = to === currentLocation;
              const iconKey = icon || 'infoIcon';
              const MenuItemIcon = icons[iconKey];
              const isSecurityLabel = colorType && colorType === 'red';
              const color = isSecurityLabel ? red : cyan;

              return (
                <Box
                  key={label}
                  className={classes.navLink}
                >
                  {iconKey && MenuItemIcon && (
                    <MenuItemIcon height="40px" color={color} />
                  )}
                  <Box
                    data-settings-btn={`${info?.type}`}
                    component="span"
                    onClick={() => handleSelect(item)}
                    className={classNames(classes.titleName, {
                      [classes.titleRed]: isSecurityLabel,
                      [classes.navLinkChecked]: isChecked,
                    })}
                  >
                    {t(label)}
                  </Box>
                </Box>
              );
            })}
            <Box className={classes.buttonsContainer}>
              <Button
                color="default"
                onClick={selectClientStatus}
                className={classNames(classes.button, 'client', {active: !isVendorActive})}
              >
                {t('NavMenu.toBeClient')}
              </Button>
              <Button
                color="default"
                onClick={selectVendorStatus}
                className={classNames(classes.button, 'vendor', {active: isVendorActive})}
              >
                {t('NavMenu.toBeVendor')}
              </Button>
            </Box>
            <div
              className={classes.navLink}
            >
              <ExitIcon height="40px" color={cyan} />
              <Box
                data-settings-btn="exit"
                component="span"
                onClick={handleLogout}
                className={classes.titleName}
              >
                {t('navigation.exit')}
              </Box>
            </div>
          </Box>
        </SwipeableDrawer>
      </Box>

      <LanguagesModal
        onClose={() => setShowLang(false)}
        isOpen={showLang}
        onChange={handleSelect}
      />
    </>
  );
};

AppNavigation.defaultProps = {
  noMenu: false,
  children: null,
  title: '',
};

export default AppNavigation;
