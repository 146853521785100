import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { Route as DefaultRoute, RouteProps as DefaultRouteProps, Redirect } from 'react-router';
import { useAuth } from 'services/auth';

type ROLE = 'client' | 'vendor' | 'guest' | 'unverified';

interface RouteProps extends Omit<DefaultRouteProps, 'render' | 'component'> {
  roles?: ROLE[];
  forbiddenRoles?: ROLE[];
}

const Route = ({
  roles = [], forbiddenRoles = [], children, ...rest
}: PropsWithChildren<RouteProps>) => {
  const { user, vendorId } = useAuth();

  const userRole = classNames({
    vendor: Boolean(user) && Boolean(vendorId),
    client: Boolean(user) && !vendorId,
    unverified: user && !user.isUserVerified,
    guest: !user,
  }) as ROLE;

  const isApplyRole = roles.some((role) => userRole.includes(role));
  const isForbiddenRole = forbiddenRoles.some((role) => userRole.includes(role));

  return (
    <DefaultRoute
      {...rest}
      render={() => {
        if (isForbiddenRole) {
          return <Redirect to="/unverified" />;
        }

        const isGuest = userRole === 'guest';
        const redirectPath = isGuest ? '/signin' : '/profile';

        return isApplyRole ? children : <Redirect to={redirectPath} />;
      }}
    />

  );
};

export default Route;
