import React from 'react';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { UseFormMethods } from 'react-hook-form';
import { getTranslateFunction } from 'helpers';
import userSvg from 'images/icons/user.svg';

import { indexStyles } from '../material';

type Props = Pick<UseFormMethods, 'register' | 'errors'>;

const SocialMedia: React.FC<Props> = ({
  register,
  errors,
}: Props) => {
  const classes = indexStyles();
  const t = getTranslateFunction();

  return (
    <FormControl className={classes.formControl} error={!!errors.invitedBy}>
      <TextField
        className={classes.textField}
        placeholder={t('signInPage.invitedByLabel')}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <img src={userSvg} alt={t('signInPage.invitedByLabel')} className={classes.iconAdornment} />
          ),
        }}
        error={!!errors.invitedBy?.message}
        type="text"
        name="invitedBy"
        inputRef={register}
      />
      <FormHelperText id="component-error-text">{errors.invitedBy?.message}</FormHelperText>
    </FormControl>
  );
};

export default SocialMedia;
