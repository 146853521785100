import React, { ChangeEvent, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
import classNames from 'classnames';
import { IOnChange } from 'interfaces';
import { getTranslateFunction } from 'helpers';
import Header from '../components/Header';
import FooterButton from '../components/FooterButton';
import { indexStyles } from '../material';
import { SIGNUP_STEPS } from '../../../constants';

const POSITIONS = [
  'termsOfUse',
  'privacyPolicy',
] as const;

type CheckboxesKeys = typeof POSITIONS[number];

interface Props {
  onChange: (data: IOnChange) => void;
  appInfo: Record<string, string>;
}

const InfoStep: React.FC<Props> = ({ onChange, appInfo }) => {
  const classes = indexStyles();
  const history = useHistory();
  const t = getTranslateFunction();
  const [checkboxes, setCheckboxes] = useState<Record<CheckboxesKeys, boolean>>({
    termsOfUse: false,
    privacyPolicy: false,
  });
  const [errors, setErrors] = useState<Record<CheckboxesKeys, boolean>>({
    termsOfUse: false,
    privacyPolicy: false,
  });
  const [activeKey, setActiveKey] = useState('register');

  const onCancel = useCallback(() => {
    history.push('/');
  }, []);

  const onSubmit = useCallback(() => {
    const unchecked = Object.entries(checkboxes).filter(([, value]) => !value);

    if (unchecked.length) {
      const newErrors = unchecked.reduce((acc, [key]) => ({ ...acc, [key]: true }), errors);
      setErrors(newErrors);
      return;
    }

    onChange({ action: 'setStep', data: SIGNUP_STEPS.FORM });
  }, [checkboxes, errors]);

  const onChangeRuleCheckbox = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setActiveKey(checked ? name : 'register');

    setErrors((prev) => ({ ...prev, [name]: false }));
    setCheckboxes((prev) => ({ ...prev, [name]: checked}));
  }, []);

  return (
    <div className={classes.signupStepContainer}>
      <Header />

      <div className={classes.infoStepContent}>
        <div
          className={classes.infoBlockContent}
          dangerouslySetInnerHTML={{ __html: appInfo[activeKey] }}
        />

        <div>
          <FormControl className={classNames(classes.formControl, 'required')}>
            <FormControlLabel
              className={classNames(classes.label, classes.infoStepLabel)}
              labelPlacement="start"
              control={(
                <Checkbox
                  icon={(
                    <div className={classNames(classes.icon, {
                      [classes.checkboxRequiredError]: errors.privacyPolicy,
                    })}
                    />
                  )}
                  checkedIcon={<div className={classes.checkedIcon} />}
                  color="default"
                  name="privacyPolicy"
                  value={checkboxes.privacyPolicy}
                  onChange={onChangeRuleCheckbox}
                />
            )}
              label={t('signInPage.privacyPolicyLabel')}
            />
          </FormControl>

          <FormControl className={classNames(classes.formControl, 'required')}>
            <FormControlLabel
              className={classNames(classes.label, classes.infoStepLabel)}
              labelPlacement="start"
              control={(
                <Checkbox
                  icon={(
                    <div className={classNames(classes.icon, {
                      [classes.checkboxRequiredError]: errors.termsOfUse,
                    })}
                    />
                  )}
                  checkedIcon={<div className={classes.checkedIcon} />}
                  color="default"
                  name="termsOfUse"
                  value={checkboxes.termsOfUse}
                  onChange={onChangeRuleCheckbox}
                />
            )}
              label={t('signInPage.rulesLabel')}
            />
          </FormControl>
        </div>
      </div>

      <FooterButton
        submitTitle="signupPage.footerButtonContainer.continue"
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default InfoStep;
