import { FormControl, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PasswordField from 'components/PasswordField/PasswordField';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { getTranslateFunction } from 'helpers';
import { cyan, passwordRegExp } from '../../../constants';
import { indexStyles } from '../material';

type Fields = {
  password: string;
  passwordConfirm: string;
};

type Props = Pick<UseFormMethods<Fields>, 'register' | 'errors' | 'getValues' | 'clearErrors'>;

const PasswordsBlock: React.FC<Props> = ({
  errors,
  register,
  getValues,
  clearErrors,
}: Props) => {
  const classes = indexStyles();
  const t = getTranslateFunction();

  return (
    <>
      <FormControl className={classes.formControl} error={!!errors.password}>
        <PasswordField
          styles={classNames(classes.textField, classes.passwordField, 'required')}
          inputRef={register({
            pattern: {
              value: passwordRegExp,
              message: 'error.validation.invalidValue',
            },
            required: {
              value: true,
              message: 'error.validation.required',
            },
          })}
          name="password"
          label={t('signInPage.passwordLabel')}
          iconColor={cyan}
          onFocus={() => clearErrors('password')}
          onChange={() => clearErrors('password')}
          withIcon
          errorMessage={errors.password?.message && t(errors.password?.message)}
        />
      </FormControl>

      <Typography className={classes.passwordRequirementsLabel}>
        {t('signUpPage.password.minmaxLabel')}
      </Typography>

      <Typography className={classes.passwordRequirementsLabel}>
        {t('signUpPage.password.latinAndNumberLabel')}
      </Typography>

      <FormControl className={classes.formControl} error={!!errors.passwordConfirm}>
        <PasswordField
          styles={classNames(classes.textField, classes.passwordField, 'required')}
          inputRef={register({
            pattern: {
              value: passwordRegExp,
              message: 'error.validation.invalidValue',
            },
            required: {
              value: true,
              message: 'error.validation.required',
            },
            validate: (value) => (
              getValues('password') === value
                || 'error.validation.passwordsAreDifferent'
            ),
          })}
          name="passwordConfirm"
          label={t('signInPage.passwordConfirmLabel')}
          iconColor={cyan}
          withIcon
          onFocus={() => clearErrors('passwordConfirm')}
          onChange={() => clearErrors('passwordConfirm')}
          errorMessage={errors.passwordConfirm?.message && t(errors.passwordConfirm?.message)}
        />
      </FormControl>
    </>
  );
};

export default PasswordsBlock;
