import React, { memo, useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CommisionsReponse, CommissionProjectType, RelativeOrAbsolute } from 'interfaces';
import { getCurrencyByCountry, getTranslateFunction } from 'helpers';
import { currencyFormatter, formatMessage } from 'utils/formatters';
import useStyles from '../PaymentsPageStyle';

interface Props {
  commisions: CommisionsReponse[];
}

const EXCLUDE_TYPES: CommissionProjectType[] = ['withdrawal', 'sendFunds'];

const Commisions: React.FC<Props> = ({ commisions }: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  const { absolute, relative = [] } = useMemo(() => (
    commisions.reduce((acc: {
      [key in RelativeOrAbsolute]?: CommisionsReponse[]
    }, item) => {
      const { commissionType, projectType } = item;
      if (EXCLUDE_TYPES.includes(projectType)) return acc;

      const accType = acc[commissionType] ?? [];
      accType.push(item);

      acc[commissionType] = accType;
      return acc;
    }, {})
  ), [commisions]);

  const relativeFormatted = relative.reduce((acc: Record<number, string[]>, item) => {
    acc[item.commissionValue] = (acc[item.commissionValue] ?? []).concat(`"${t(item.projectType)}"`);

    return acc;
  }, {});

  if (!commisions.length) return null;

  return (
    <Box className={classes.commisionBox}>
      {Boolean(Object.keys(relative).length) && (
      <Box className={classes.infoBox}>
        <Typography>{t('PaymentsPage.paymentService')}</Typography>
        <Typography>
          {Object.entries(relativeFormatted).map(([value, items]) => (
            <Typography className={classes.commisionItem} key={value} variant="caption">
              {formatMessage('${type} - ${value}%\t', { type: items.join(', '), value })}
            </Typography>
          ))}
        </Typography>
      </Box>
      )}
      {Array.isArray(absolute) && (
        <Box className={classes.infoBox}>
          <Typography>{t('PaymentsPage.paymentServicePerMonth')}</Typography>
          <Typography>
            {absolute.map((item) => {
              const currency = getCurrencyByCountry(item.country);
              const value = currencyFormatter(item.commissionValue, currency);
              return (
                <Typography className={classes.commisionItem} key={item.projectType} variant="caption">
                  {formatMessage("'${type}' - ${value}\t", { type: t(item.projectType), value})}
                </Typography>
              );
            })}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default memo(Commisions);
