import {
  useState, useCallback, useEffect, useMemo, Dispatch, SetStateAction,
} from 'react';
import { getCurrencyByCountry, getErrorMessage, getTranslateFunction } from 'helpers';
import {
  ArchiveItem,
  BonusPreorderType,
  BonusResponseItem,
  CommisionsReponse,
  IBonus,
  IOrder,
  PaymentRequest,
  PendingTransactionStats,
  SecurityPaymentItem,
  TBalance,
  TCurrency,
  Transaction,
} from 'interfaces';
import { useAuth } from 'services/auth';
import {
  getCommisions, getMyBalance, getMyBonuses, getMyTransactions,
} from 'services/commonService';
import RequestService from 'services/requestApi';
import { dateFormatter } from 'utils/formatters';
import { ERROR_FOR_CODE_ATTEMPTS } from '../constants';
import { IncomeValue } from '../pages/PaymentsPage/components/Income';

export const useBalance = (): TBalance => {
  const { user } = useAuth();
  const [balance, setBalance] = useState<TBalance>(() => {
    const currency = getCurrencyByCountry(user?.country);

    return { [currency]: 0 };
  });

  const getBalance = useCallback(async () => {
    const [response] = await getMyBalance();
    setBalance(response);
  }, []);

  useEffect(() => {
    getBalance();
  }, []);

  return balance;
};

export const useBonuses = (): {
  bonuses: IBonus;
  allBonuses: BonusResponseItem[];
} => {
  const { user } = useAuth();
  const [bonuses, setBonuses] = useState({});
  const [allBonuses, setAllBonuses] = useState<BonusResponseItem[]>([]);

  useEffect(() => {
    if (!user) return;

    const getBonuses = async () => {
      const response = await getMyBonuses();
      setAllBonuses(response);

      const newBonuses = response.reduce((acc: IBonus, {
        bonusPreorderTypes,
        bonusValue,
        bonusExpireDate,
        updatedAt,
        bonusInfluenceType,
      }) => {
        const localAcc: IBonus = {};
        bonusPreorderTypes.forEach((label: BonusPreorderType) => {
          const existValue = acc[label];
          const resultObject = {
            bonusValue,
            bonusExpireDate,
            bonusInfluenceType,
            updatedAt,
          };
          if (!existValue) {
            localAcc[label] = resultObject;
          } else {
            const existValueDate = new Date(existValue.updatedAt).getTime();
            const newValueDate = new Date(updatedAt).getTime();
            localAcc[label] = newValueDate > existValueDate ? resultObject : existValue;
          }
        });
        return {
          ...acc,
          ...localAcc,
        };
      }, {});

      setBonuses(newBonuses);
    };

    getBonuses();
  }, [user]);

  return {
    allBonuses,
    bonuses,
  };
};

export const useCommissions = (): {
  commissions: CommisionsReponse[];
} => {
  const { setIsLoading, user } = useAuth();
  const [commissions, setCommissions] = useState<CommisionsReponse[]>([]);

  useEffect(() => {
    if (!user) return;

    const handeGetCommision = async () => {
      setIsLoading(true);
      try {
        const response = await getCommisions({ findColumn: 'country', findColumnValue: user.country });
        setCommissions(response);
      } finally {
        setIsLoading(false);
      }
    };

    handeGetCommision();
  }, [user]);

  return { commissions };
};

export const usePayments = (): {
  commissions: CommisionsReponse[];
  balance: TBalance;
  bonuses: IBonus;
  allBonuses: BonusResponseItem[];
} => {
  const balance = useBalance();
  const { allBonuses, bonuses } = useBonuses();
  const { commissions } = useCommissions();

  return {
    balance,
    allBonuses,
    bonuses,
    commissions,
  };
};

type UseValuesProps = {
  orders: IOrder[];
  transactions: Transaction[];
  userCurrency: TCurrency;
  bonuses: BonusResponseItem[];
};

type UseValuesReturn = {
  incomeValue: IncomeValue[];
  internalTransferTransactions: SecurityPaymentItem[];
  archiveTransactions: ArchiveItem[];
  pendingTransactions: PendingTransactionStats;
  incomeSumm: Record<string, number>;
  formattedBonuses: Partial<Record<TCurrency, number>>
};
const paymentArchiveTKey = 'PaymentPage.archive.';
const formatToArchiveItem = (transaction: Transaction): [string, string] => {
  const { transactionType, cardId } = transaction;

  if (transactionType === 'inbound' || transactionType === 'outbound') {
    const paymentType = cardId ? 'card' : 'internalTransfer';
    return [`${paymentArchiveTKey}${transactionType}`, `${paymentArchiveTKey}${paymentType}`];
  }

  return [`${paymentArchiveTKey}services`, `${paymentArchiveTKey}internalTransfer`];
};

export const useValues = ({
  orders,
  transactions,
  userCurrency,
  bonuses,
}: UseValuesProps): UseValuesReturn => {
  const t = getTranslateFunction();

  const formatOrderToLabel = useCallback((order: IOrder | undefined) => {
    if (!order) return '';
    const orderType = t(order.tripType);
    const orderSubType = t(order.subType);

    const projectValue = order[order.subType] || order[order.tripType];

    const orderProject = t(projectValue);
    return [orderType, orderSubType, orderProject].join(', ');
  }, [t]);

  const incomeValue = useMemo(() => {
    if (!(transactions && orders)) return [];

    const result = transactions
      .filter(({ quantity, orderId }: Transaction) => {
        const isQuantityMoreThanZero = quantity >= 0;
        const isOrderExist = orders.some((order) => order.id === orderId);

        return isOrderExist && isQuantityMoreThanZero;
      })
      .map((transaction: Transaction) => {
        const {
          orderId,
          createdAt,
          id,
          quantity,
          currency,
        } = transaction;
        const order = orders.find((currentOrder) => currentOrder.id === orderId) as IOrder;
        const date = dateFormatter(new Date(createdAt));

        const [startDot] = order.dots;
        const { label, value: dotValue } = startDot;

        const projectLabel = formatOrderToLabel(order);

        const typeTransaction = t(order.tripType);
        const address = dotValue ? label : typeTransaction;

        const data: IncomeValue = {
          transactionNumber: id,
          date,
          projectLabel,
          address,
          quantity,
          typeTransaction,
          currency,
        };

        return data;
      });

    return result;
  }, [transactions, orders]);

  const formattedBonuses = useMemo(() => bonuses.reduce((
    acc: Partial<Record<TCurrency, number>>,
    item,
  ) => {
    const { bonusInfluenceType, bonusCurrency, bonusValue } = item;

    if (bonusInfluenceType === 'absolute') {
      acc[bonusCurrency] = (acc[bonusCurrency] || 0) + bonusValue;
    }
    return acc;
  }, { [userCurrency]: 0 }), [bonuses]);

  const incomeSumm = useMemo(() => (
    incomeValue.reduce((acc: Partial<Record<TCurrency, number>>, { quantity, currency }) => ({
      ...acc,
      [currency]: (acc[currency] ?? 0) + quantity,
    }), {
      [userCurrency]: 0,
    })
  ), [incomeValue]);

  const pendingTransactions = useMemo(() => {
    const result = transactions
      .filter(({ transactionStatus }) => transactionStatus === 'pending')
      .reduce((acc: PendingTransactionStats, item) => {
        const currCurrency = acc[item.currency] ?? {};
        const curTypeValue = currCurrency[item.transactionType];

        return {
          ...acc,
          [item.currency]: {
            ...currCurrency,
            [item.transactionType]: (curTypeValue || 0) + item.quantity,
          },
        };
      }, {
        [userCurrency]: {
          outbound: 0,
          inbound: 0,
        },
      });

    return result;
  }, [transactions]);

  const internalTransferTransactions = useMemo(() => {
    const result = transactions.reduce((acc: SecurityPaymentItem[], item) => {
      const {
        transactionType,
        transactionStatus,
        createdAt,
        quantity,
        orderId,
        id,
        currency,
      } = item;
      if (transactionType !== 'internalTransfer') return acc;

      const date = dateFormatter(new Date(createdAt));
      const transactionStatusLabel = t(`PaymentPage.${transactionStatus}`);
      const statusClassName = transactionStatus === 'approved' ? 'green' : 'red';
      const quantityClassName = quantity > 0 ? 'green' : 'red';
      const transactionOrder = orders.find((order) => order.id === orderId);
      const orderLabel = formatOrderToLabel(transactionOrder);
      const projectLabel = transactionOrder ? orderLabel : '';

      const formatedTransactions: SecurityPaymentItem = {
        transactionNumber: id,
        date,
        projectLabel,
        status: {
          text: transactionStatusLabel,
          className: statusClassName,
        },
        quantity: {
          text: `${Math.abs(quantity)} ${currency}`,
          className: quantityClassName,
        },
      };

      return [
        ...acc,
        formatedTransactions,
      ];
    }, []);

    return result;
  }, [transactions, orders, t]);

  const archiveTransactions: ArchiveItem[] = useMemo(() => {
    const result = transactions.map((item) => {
      const date = dateFormatter(new Date(item.createdAt));
      const [transactionType, paymentType] = formatToArchiveItem(item);
      const quantityClassName = item.quantity > 0 ? 'green' : 'red';

      const formatedTransactions: ArchiveItem = {
        transactionNumber: item.id,
        date,
        transactionType: t(transactionType),
        paymentType: t(paymentType),
        quantity: {
          text: Math.abs(item.quantity),
          className: quantityClassName,
        },
      };

      return formatedTransactions;
    });

    return result;
  }, [transactions, t]);

  return {
    incomeValue,
    internalTransferTransactions,
    archiveTransactions,
    pendingTransactions,
    incomeSumm,
    formattedBonuses,
  };
};

export const useTransactions = (): {
  transactions: Transaction[];
  loading: boolean;
  setTransactions: Dispatch<SetStateAction<Transaction[]>>;
} => {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  useEffect(() => {
    const getTransactions = async () => {
      try {
        setLoading(true);
        const res = await getMyTransactions();
        setTransactions(res);
      } finally {
        setLoading(false);
      }
    };

    getTransactions();
  }, []);

  return {
    transactions,
    loading,
    setTransactions,
  };
};

interface SendRequestData {
  cardId?: string;
  toUserUuid?: string;
  quantity: string;
}
interface SendCodeData extends SendRequestData {
  verificationCode: number;
}

interface UPaymentRequest extends PaymentRequest {
  sendRequest: (data: SendRequestData) => Promise<void>;
  currency: TCurrency;
  sendCode: (data: SendCodeData) => Promise<Transaction | null>;
  resetState: () => void;
}

export const usePaymentRequest = (url: string): UPaymentRequest => {
  const { user } = useAuth();
  const [attempts, setAttempts] = useState(0);
  const [isAttemptsForCodeLeft, setIsAttemptsForCodeLeft] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [createdCodeTime, setCreatedCodeTime] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const currency = useMemo(() => getCurrencyByCountry(user?.country), [user]);

  const resetState = useCallback(() => {
    setAttempts(0);
    setIsAttemptsForCodeLeft(false);
    setConfirmed(false);
    setCreatedCodeTime(null);
  }, []);

  const sendRequest = async (data: SendRequestData) => {
    if (!user || isLoading) return;

    try {
      setIsLoading(true);
      const requestData = {
        ...data,
        currency,
        userId: user.id,
      };

      const response = await new RequestService(`${url}-request`).post(requestData);

      const timestamp = new Date().getTime();
      setCreatedCodeTime(timestamp);
      setAttempts(response.attempts);
      setIsAttemptsForCodeLeft(false);
    } catch (error) {
      const message = getErrorMessage(error as Error);

      throw new Error(message);
    } finally {
      setIsLoading(false);
    }
  };

  const sendCode = async (data: SendCodeData): Promise<Transaction | null> => {
    if (!user || isLoading) return null;

    try {
      setIsLoading(true);
      const newTransaction = await new RequestService(url).post({
        ...data,
        currency,
        userId: user.id,
      });

      setConfirmed(true);
      return newTransaction;
    } catch (error) {
      const message = getErrorMessage(error as Error);
      if (message === ERROR_FOR_CODE_ATTEMPTS) setIsAttemptsForCodeLeft(true);

      throw new Error(message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    attempts,
    isAttemptsForCodeLeft,
    confirmed,
    currency,
    createdCodeTime,
    sendRequest,
    sendCode,
    resetState,
  };
};
