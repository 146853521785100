import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Typography,
  Box,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import CommentSvg from 'images/comment';
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { getTranslateFunction } from 'helpers';
import useStyles from './OrderPageStyle';
import { textColor } from '../../constants';

interface RatingModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  id: number;
  children: any;
  onChange: (a: string, b?: any) => void;
}

export const RatingModal: React.FC<RatingModalProps> = ({
  isOpen,
  toggleModal,
  id,
  children,
  onChange,
}) => {
  const t = getTranslateFunction();
  const history = useHistory();
  const [rating, setRating] = useState<any>({
    politenessRating: 5,
  });
  const [message, setMessage] = useState<string>('');
  const classes = useStyles();

  const listItems = [
    {
      label: t('ClientOrderPage.politeness'),
      name: 'politenessRating',
    },
  ];

  const handleRating = useCallback(() => {
    onChange('sendClientRating', {
      rating,
      orderId: id,
      comment: message,
    });

    toggleModal();
    history.push('/');
  }, [isOpen, rating, message]);

  const onChangeRating = useCallback((type: string, name: string) => {
    let value = rating[name];
    if (type === '+' && value < 10) value += 1;
    if (type === '-' && value > 0) value -= 1;

    if (value !== rating[name]) {
      setRating((prev: any) => ({ ...prev, [name]: value }));
    }
  }, [rating]);

  return (
    <div className={classes.ratingBox}>
      <Typography
        variant="h5"
        className={classes.ratingTitle}
      >
        {t('OrderPage.orderFinished')}
      </Typography>
      {children}
      <Typography
        variant="h5"
        className={classes.ratingTitle}
      >
        {t('OrderPage.rateTheUser')}
      </Typography>
      <Box className={classes.ratingItem}>
        <Typography className={classes.maxLabel}>{t('OrderPage.maximumRating')}</Typography>
        <Box className={classes.numberBox}>
          <Typography>10</Typography>
        </Box>
      </Box>
      {
        listItems.map((item) => (
          <Box className={classes.ratingItem} key={item.name}>
            <Typography>{item.label}</Typography>
            <Box className={classes.numberBox}>
              <Box onClick={() => onChangeRating('-', item.name)}>-</Box>
              <Typography>{rating[item.name]}</Typography>
              <Box onClick={() => onChangeRating('+', item.name)}>+</Box>
            </Box>
          </Box>
        ))
      }
      <TextField
        className={classes.reportMessage}
        value={message}
        multiline
        placeholder={t('OrderPage.sendComment')}
        onChange={({ target: { value } }) => setMessage(value)}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <Box className={classes.svg}>
              <CommentSvg color={textColor} />
            </Box>
          ),
        }}
      />
      <Button className={classes.sendRating} color="primary" onClick={handleRating}>
        {t('OrderPage.sendRating')}
      </Button>
    </div>
  );
};

interface ConfirmCancelOfferProps {
  isOpen: boolean;
  handleConfirm: (a: any) => void;
  cancelModal: (a: any) => void;
  array: string[];
}

export const ConfirmCancelOfferModal: React.FC<ConfirmCancelOfferProps> = ({
  isOpen, handleConfirm, cancelModal, array,
}) => {
  const classes = useStyles();
  const t = getTranslateFunction();
  const [textCancel, setTextCancel] = useState('');
  const [typeCancel, setTypeCancel] = useState('');
  const disabledField = useMemo(() => typeCancel !== 'other', [typeCancel]);

  const disableBtn = useMemo(() => {
    if (!typeCancel) return true;
    if (typeCancel === 'other' && textCancel.length < 5) return true;
    return false;
  }, [typeCancel, textCancel]);

  const handleCancel = useCallback(() => {
    if (typeCancel) {
      if (typeCancel === 'other') {
        if (!textCancel) return toast.warning(t('create-projects.error-empty-field'));
        return handleConfirm(textCancel);
      }
      return handleConfirm(typeCancel);
    }
    return null;
  }, [textCancel, typeCancel]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeCancel((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog
      data-order-modal="cancel order"
      open={isOpen}
      onClose={cancelModal}
      className={classes.withBorder}
    >
      <DialogContent>
        <DialogContentText>
          {t('modal.DoYouWantCancelOrder?')}
        </DialogContentText>
        <RadioGroup value={typeCancel} onChange={handleChange}>
          {array.map((item) => (
            <FormControlLabel key={item} value={item} control={<Radio />} label={t(`OrderPage.${item}`)} />
          ))}
          <FormControlLabel
            value="other"
            control={<Radio />}
            label={t('create-projects.other')}
          />
        </RadioGroup>
        {!disabledField && (
          <TextField
            variant="outlined"
            value={textCancel}
            InputProps={{
              readOnly: disabledField,
            }}
            onChange={({ target }: any) => setTextCancel(target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button data-modal-btn="cancel" color="primary" onClick={cancelModal}>
          {t('modal.cancel')}
        </Button>
        <Button disabled={disableBtn} data-modal-btn="confirm" color="primary" onClick={handleCancel}>
          {t('modal.confirmCancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface OrderInfo {
  modal: boolean;
  onClose: (a: any) => void;
  children: any;
}

export const OrderInfoModal: React.FC<OrderInfo> = ({
  modal,
  onClose,
  children,
}) => {
  const classes = useStyles();
  const t = getTranslateFunction();

  return (
    <Dialog className={classes.withBorder} open={modal} onClose={onClose} fullWidth>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t('ClientOrderPage.Ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
