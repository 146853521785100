import { makeStyles } from '@material-ui/core/styles';
import { green, cyan } from '../../constants';

export default makeStyles({
  container: {
    display: 'flex',
    padding: '10px',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  orderButton: {
    width: '100%',
    marginTop: 10,
  },
  button: {
    margin: '10px 0',
  },
  orderTableCell: {
    width: '50%',
  },
  orderTableRow: {
    height: '30px',
  },
  preorderContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '5px',
  },
  line: {
    width: '50%',
    height: '3px',
    backgroundColor: cyan,
    margin: '0 auto 10px',
  },
  orderContainer: {
    marginBottom: '15px',
  },
  noActive: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  orderTableCellGreenText: {
    color: green,
  },
}, { index: 1 });
