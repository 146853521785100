import React, { FC } from 'react';

interface Props {
  color: string
}

const Phone: FC<Props> = ({ color }): any => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 72 72"
  >
    <path
      fill={color}
      d="M67.48,50.95c-1.7-1.67-3.58-3.2-5.53-4.57c-7.59-5.34-10.7-5.01-16.16,1.9c-1.99,2.51-4.16,3.19-7.08,1.82
      c-7.77-3.66-13.76-9.37-17.86-16.91c-1.53-2.82-0.85-4.96,1.45-7.19c1.02-0.99,2.06-1.96,3.01-3.02c2.44-2.72,3.04-5.45,1.08-8.51
      c-2.18-3.41-4.72-6.66-7.5-9.61c-2.32-2.46-4.53-2.19-7.02,0.06C2.79,13.15,0.7,22.98,6,34.05c5.2,10.88,13.66,18.95,23.3,25.85
      c6.56,4.69,13.74,7.79,22.06,7.22c7.46-0.6,12.58-4.94,16.8-10.6C69.59,54.6,69.07,52.52,67.48,50.95z"
    />
  </svg>
);

export default Phone;
