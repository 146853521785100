import React, {
  ChangeEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  Button,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import { ArrowBackIos, HighlightOff, Warning } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import Preloader from 'components/Preloader/Preloader';
import fromSvg from 'images/icons/from.svg';
import toSvg from 'images/icons/to.svg';
import { getTranslateFunction } from 'helpers';
import { parseQueryString, stringifyQueryObject } from 'utils/formatters';
import { useAuth } from 'services/auth';
import { IPoint, Suggestion } from 'interfaces';
import { formatAddress } from 'services/geo';
import { useSuggestions } from '../../pages/PreorderPage/useSuggestions';
import useStyles from './SelectLocationStyle';
import { PLACE_PICKER_FALLBACK } from '../../constants';

interface Props {
  placeholder: string;
  onFocus: () => void;
  dataName: string;
  onChange: (v: number, obj: Record<string, unknown>) => void;
  onMapSelect: (v: boolean) => void;
  removePoint: ((index: number) => void) | boolean;
  isActive: boolean;
  index: number;
  point: IPoint;
  setActivePoint: (a: number | null) => void;
  handleBack: (index: number) => void;
}

interface FProp {
  label: string;
  action?: string;
}

const SelectLocation: React.FC<Props> = ({
  placeholder,
  onFocus,
  dataName,
  onChange,
  onMapSelect,
  isActive,
  removePoint,
  point,
  index,
  setActivePoint,
  handleBack,
}) => {
  const { source, address, value: innerValue } = point;

  const { setIsLoading } = useAuth();
  const t = getTranslateFunction();
  const history = useHistory();
  const classes = useStyles();
  const showFallback = !isActive && source === 'coordinates' && !innerValue;
  const fallback = showFallback ? t(PLACE_PICKER_FALLBACK) : '';
  const [inputValue, setInputValue] = useState(innerValue ?? fallback);
  const {
    isLoading,
    suggestions,
    getSuggestions,
    getFromCoordinates,
    setSuggestions,
  } = useSuggestions();

  useEffect(() => {
    if (!inputValue) {
      setSuggestions(null);
      return;
    }

    getSuggestions(inputValue);
  }, [inputValue.trim()]);

  const addressError = useMemo(() => {
    const isMissingHouseNumber = source === 'nominatim' && !address?.house_number;
    const isOnlyCoords = source && source !== 'nominatim' && point.lat;

    const isShow = !isActive && (isMissingHouseNumber || isOnlyCoords);
    const addressErrorLabel = isShow ? 'addressMayBeInaccurate' : '';

    return addressErrorLabel;
  }, [point, isActive]);

  const suggestionItems = [
    { label: t('input.selectLocation.geoLabel'), action: 'geo', key: 'geo' },
    { label: t('input.selectLocation.mapLabel'), action: 'map', key: 'map' },
  ];

  const handleCloseInputBox = useCallback(() => {
    history.goBack();

    if (source === 'input' && !point.lat) {
      onChange(index, { value: '', source: '' });
    }
  }, [point]);

  function handleAddressClick(locationObj: FProp) {
    setSuggestions(null);
    if (innerValue) {
      onMapSelect(true);
      onMapSelect(false);
    }
    onChange(index, { ...locationObj, value: locationObj.label, source: 'nominatim' });

    history.goBack();
  }

  function handleMapSelectClick() {
    setSuggestions([]);
    onMapSelect(true);
  }

  const handleGetMyPosition = useCallback(() => {
    setIsLoading(true);
    try {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          getFromCoordinates([coords.latitude, coords.longitude])
            .then((res) => {
              const [valueAddress] = formatAddress(res.address);
              onChange(index, { ...res, value: valueAddress, source: 'nominatim' });
              setActivePoint(null);
              onMapSelect(false);
            });
        },
        () => toast.error(t('errors.geoNotAllowed')),
        {
          enableHighAccuracy: true,
        },
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  function handleSuggestionClick(item: FProp) {
    switch (item.action) {
      case 'geo':
        handleGetMyPosition();
        break;
      case 'map':
        handleMapSelectClick();
        break;
      default:
        handleAddressClick(item);
    }
  }

  const handleSetActivePoint = useCallback(() => {
    const params = parseQueryString(history.location.search);
    const newQueryParams = stringifyQueryObject({ ...params, pointId: index });

    history.push(newQueryParams);
  }, [index]);

  const onClick = useCallback(() => {
    handleBack(index);
    setInputValue('');
    setSuggestions([]);

    if (!isActive) {
      handleSetActivePoint();
    }
  }, [index, isActive]);

  const handleInputFocus = useCallback(() => {
    onFocus?.();
    if (isActive) return;
    handleSetActivePoint();
  }, [isActive]);

  const handleRemove = useCallback(() => {
    if (typeof removePoint === 'function') {
      removePoint(index);
    }

    history.goBack();
  }, []);

  const handleChangeInput = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setInputValue(value);

    if (!address) {
      onChange(index, { value, source: 'input' });
    }
  }, [point]);

  return (
    <Box className={classNames(classes.input, { [classes.activeInput]: isActive })}>
      {isActive && (
        <Button
          onClick={handleCloseInputBox}
        >
          <ArrowBackIos />
        </Button>
      )}
      <TextField
        value={inputValue || fallback}
        placeholder={placeholder}
        data-map-search={dataName}
        onFocus={handleInputFocus}
        className={classes.inputGeo}
        InputProps={{
          startAdornment: (
            <Box className={classes.svg}>
              <img src={dataName === 'from' ? fromSvg : toSvg} alt={dataName} />
            </Box>
          ),
          endAdornment: inputValue && (
            <InputAdornment position="end" onClick={onClick} data-map-search="adornment">
              <HighlightOff />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        variant="filled"
        onChange={handleChangeInput}
      />
      <Box className={classes.addressUnavaiableBox}>
        {addressError && (
          <>
            <Warning fontSize="small" />
            <Typography variant="caption" className={classes.addressUnavaiableText}>{t(addressError)}</Typography>
          </>
        )}
      </Box>

      {isActive && removePoint && (
        <Button
          variant="contained"
          className={classes.removeBtn}
          onClick={handleRemove}
        >
          {t('input.selectLocation.remove')}
        </Button>
      )}

      {isLoading ? (
        <Preloader />
      ) : (
        isActive && (
          <List className={classes.selectList}>
            {suggestionItems.map((item) => {
              const currentItem = item as Suggestion;
              const cityLabel = currentItem ? currentItem.cityLabel : '';

              return (
                <ListItem data-map-btn={item.key} key={item.key} button onClick={() => handleSuggestionClick(item)}>
                  <ListItemText data-map-btn="propose-location" primary={item.label} secondary={cityLabel} />
                </ListItem>
              );
            })}

            {Array.isArray(suggestions) && Boolean(suggestions.length) && (
              <>
                <hr />
                {suggestions
                  .filter((item) => typeof item.label === 'string')
                  .map((suggestion) => (
                    <ListItem
                      data-map-btn={suggestion.osm_id}
                      key={suggestion.osm_id}
                      button
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      <ListItemText
                        data-map-btn="propose-location"
                        primary={t(suggestion.label)}
                        secondary={suggestion.cityLabel}
                      />
                    </ListItem>
                  ))}
              </>
            )}
          </List>
        )
      )}
    </Box>
  );
};

export default SelectLocation;
