import { ReactNode } from 'react';
import classNames from 'classnames';
import useStyles from '../ProfilePageStyle';

interface Props {
  children: ReactNode;
  isVerified: boolean;
}

const VerificationInputStatus = ({ children, isVerified }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.verificationInputStatusWrapper}>
      {children}
      <div className={classNames(
        classes.verificationInputStatus,
        { [classes.verified]: isVerified },
      )} />
    </div>
  );
};

export default VerificationInputStatus;
