import React from 'react';
import { useForm } from 'react-hook-form';
import { Container, Typography, Button } from '@material-ui/core';
import { IOnChange, Vendor } from 'interfaces';
import { ModelItem } from 'components/ConstructorForm/items/CarCombobox/useBrands';
import { formatVendorServicesToFormValues, getTranslateFunction } from 'helpers';
import Step3 from '../SignUpPage/Step3';
import useStyles from '../CreateVendorPage/CreateVendorStyle';

interface Props {
  onChange: (data: IOnChange) => void;
  vendor: Vendor;
  brands: string[];
  models: ModelItem[];
}

const UpdateVendorPage: React.FC<Props> = ({
  vendor,
  onChange,
  brands,
  models,
}) => {
  const t = getTranslateFunction();
  const classes = useStyles();
  const {
    control,
    errors,
    handleSubmit,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: formatVendorServicesToFormValues(vendor),
  });

  const submitter = (data: Vendor) => {
    onChange({ action: 'updateVendorSubmit', data });
  };

  return (
    <>
      <Container className={classes.mainContainer}>
        <Typography variant="h4">{t('updateVendorPage.pageName')}</Typography>
        <form onSubmit={handleSubmit(submitter)}>
          <Step3
            setValue={setValue}
            control={control}
            errors={errors}
            brands={brands}
            models={models}
            onChangeProp={onChange}
            clearErrors={clearErrors}
          />
          <Button
            type="submit"
            className={classes.submitBtn}
            variant="contained"
            color="primary"
          >
            {t('updateVendorPage.button.updateLabel')}
          </Button>
        </form>
      </Container>
    </>
  );
};

export default UpdateVendorPage;
