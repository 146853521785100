import React from 'react';
import { getTranslateFunction } from 'helpers';
import useWindowSize from 'services/useWindowSize';
import { RatingType, User, ExtentedCurrencyPreorder } from 'interfaces';
import VendorOfferBuyForm from '../containers/VendorOfferBuyForm';
import {
  ReOfferModal,
  VendorOfferModal,
  VendorOfferDeclined,
  VendorOfferCanceled,
} from '../VendorOfferModal';
import useStyles from '../VendorOfferStyle';
import VendorOfferMainForm from './VendorOfferMainForm';

interface Props {
  isSingleTypePreorder: boolean;
  user: User;
  preorder: ExtentedCurrencyPreorder;
  reofferModal: boolean;
  vendorUuid: string | null;
  vendorLocation: [number, number] | null;
  canceledModal: boolean;
  preorderServiceId: number;
  vendorId: number | null;
  vendorUserUuid: string;
  modal: boolean;
  declinedModal: boolean;
  offerPrice: number;
  time: number;
  userRating: RatingType;
  secondOffer: boolean;
  isMyOrder: boolean;
  onChange: (a: {
    action: string;
    data: any;
  }) => void;
}

const VendorOfferComponent: React.FC<Props> = ({
  isSingleTypePreorder,
  user,
  preorder,
  reofferModal,
  vendorUuid,
  vendorUserUuid,
  vendorLocation,
  canceledModal,
  preorderServiceId,
  vendorId,
  modal,
  declinedModal,
  userRating,
  offerPrice,
  time,
  secondOffer,
  isMyOrder,
  onChange,
}: Props) => {
  const { height } = useWindowSize();
  const classes = useStyles(height);
  const t = getTranslateFunction();

  const submitBtnText = preorderServiceId ? 'glossary.waiting' : 'offer.ConfirmFor';

  const handleSetOnChange = (action: string, data?: any) => {
    onChange({ action, data });
  };

  if (!(preorder && vendorId)) return null;

  return (
    <>
      {isSingleTypePreorder ? (
        <VendorOfferBuyForm
          user={user}
          classes={classes}
          vendorId={vendorId as number}
          preorder={preorder}
          userRating={userRating}
          vendorUserUuid={vendorUserUuid}
          vendorUuid={vendorUuid}
          vendorLocation={vendorLocation}
          preorderServiceId={preorderServiceId}
          submitBtnText={t(submitBtnText)}
          onChange={handleSetOnChange}
          isMyOrder={isMyOrder}
        />
      ) : (
        <VendorOfferMainForm
          vendorLocation={vendorLocation}
          isSingleTypePreorder={isSingleTypePreorder}
          handleSetOnChange={handleSetOnChange}
          preorder={preorder}
          user={user}
          offerPrice={offerPrice}
          userRating={userRating}
          time={time}
          preorderServiceId={preorderServiceId}
          secondOffer={secondOffer}
          vendorId={vendorId}
          vendorUuid={vendorUuid}
          submitBtnText={submitBtnText}
          isMyOrder={isMyOrder}
        />
      )}

      {reofferModal && (
        <ReOfferModal
          modal={reofferModal}
          handleClose={() => handleSetOnChange('handleCloseModal')}
        />
      )}

      {modal && (
        <VendorOfferModal
          modal={modal}
          handleClose={() => handleSetOnChange('handleClose')}
        />
      )}

      {canceledModal && (
        <VendorOfferCanceled
          modal={canceledModal}
          handleClose={() => handleSetOnChange('handleClose')}
        />
      )}

      {declinedModal && (
        <VendorOfferDeclined
          modal={declinedModal}
          handleClose={() => handleSetOnChange('handleClose')}
        />
      )}
    </>
  );
};

export default VendorOfferComponent;
