import { Box, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PreorderInfoComponent from 'components/PreorderInfoComponent';
import { Add } from '@material-ui/icons';
import React from 'react';
import GlobalModal from 'sharedComponents/GlobalModal/GlobalModal';
import { getTranslateFunction } from 'helpers';
import {
  RatingArrayItem,
  Preorder,
  PreorderService,
  User,
  Vendor,
} from 'interfaces';
import PreorderListItem from '../PreorderListItem';
import useStyles from '../PreorderOffersStyle';

interface Props {
  offers: PreorderService[];
  vendors: Vendor[];
  vendorsRating: RatingArrayItem[];
  vendorsComments: any;
  preorder: Preorder;
  users: User[];
  cancelModalPreorder: boolean;
  handleConfirm: (a: PreorderService) => void;
  declinePreorder: (a: PreorderService) => void;
  handleChangeModal: () => void;
  handleEditPreorder: () => void;
  handleEditPricePreorder: (a: number) => void;
  cancelPreorder: () => void;
}

const PreorderOfferComponent: React.FC<Props> = ({
  offers,
  preorder,
  users,
  vendors,
  vendorsRating,
  vendorsComments,
  handleConfirm,
  declinePreorder,
  handleChangeModal,
  handleEditPreorder,
  handleEditPricePreorder,
  cancelModalPreorder,
  cancelPreorder,
}: Props) => {
  const classes = useStyles();
  const t = getTranslateFunction();
  const showOffers = offers.length
    && vendors.length
    && vendorsRating.length
    && vendorsComments.length;

  return (
    <Box
      className={classes.preorderContainer}
      data-order-content="offer-container"
    >
      {showOffers ? (
        <Box
          className={classes.modal}
        >
          <Box
            className={classes.modalContent}
          >
            {offers.map((offer: PreorderService) => {
              const user = users.find((currUser: User) => (
                currUser?.uuid === offer.vendorUserUuid
              ));
              const vendor = vendors.find(({id}: Vendor) => (
                id === offer.serviceId
              ));
              const rating = vendorsRating.find(({id}: { id: string }) => (
                id === offer.vendorUuid
              ));
              const comments = vendorsComments.find(({id}: { id: string }) => (
                id === offer.vendorUuid
              ));

              const isHide = !offer.preorderServiceId;

              if (isHide || !vendor || !user) return null;

              return (
                <PreorderListItem
                  preorder={preorder}
                  key={offer.serviceId}
                  offer={offer}
                  vendor={vendor}
                  userRating={rating?.data}
                  vendorComments={comments.data}
                  user={user}
                  handleConfirm={handleConfirm}
                  decline={declinePreorder}
                />
              );
            }).filter(Boolean)}
          </Box>
        </Box>
      ) : (
        <Box className={classes.waitingLabel}>
          <Typography>
            {t('glossary.preorderPublished')}
          </Typography>
          <Typography>
            {t('glossary.waiting')}
          </Typography>
        </Box>
      )}
      <PreorderInfoComponent
        preorder={preorder}
      />
      <Box className={classes.btnBox}>
        <Box
          className={classNames(classes.button, 'edit')}
          onClick={handleEditPreorder}
        >
          {t('preorder.modal.editPreorder')}
        </Box>
        <Box
          className={classNames(classes.button, 'cancel')}
          onClick={handleChangeModal}
          data-order-btn="order-cancel"
        >
          {t('preorder.modal.cancel')}
        </Box>
        <Box
          className={classNames(classes.button, 'raise')}
          onClick={() => handleEditPricePreorder(Number(preorder.price + 5))}
        >
          {t('preorder.modal.raisePrice')}
        </Box>
        <Box className={classNames(classes.button, 'price')}>
          <Add />
          <Typography>{`${preorder.price} ${preorder.currency}`}</Typography>
        </Box>
      </Box>
      {cancelModalPreorder && (
        <GlobalModal
          isOpen={cancelModalPreorder}
          confirm={cancelPreorder}
          secondaryConfirm={handleChangeModal}
          content={t('modal.DoYouWantCancelOrder?')}
          buttonText={t('modal.yes')}
          secondaryButtonText={t('modal.no')}
        />
      )}
    </Box>
  );
};

export default PreorderOfferComponent;
