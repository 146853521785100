import * as io from 'socket.io-client';

// const way: any = 'wss://preprowebway.eu';
const way: any = process.env.REACT_WEB_SOCKET_HOST;

class Socket {
  io: any | undefined;

  emit: any = () => Object;

  on: any = () => Object;

  off: any = () => Object;

  connect() {
    return () => this;
  }

  init() {
    this.io = io.connect(way, {
      path: '/api/socket',
      transports: ['websocket'],
    });

    this.connect = this.io.connect;
    this.emit = this.io.emit.bind(this.io);
    this.on = this.io.on.bind(this.io);
    this.off = this.io.off.bind(this.io);
  }
}

const socket = new Socket();
export default socket;
