import React, { useState, useCallback, useMemo } from 'react';
import {
  InputAdornment,
  IconButton,
  Box,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PasswordSvg from 'images/password';
import classNames from 'classnames';
import TextField, { SharedTextFieldProps } from 'sharedComponents/TextField';
import useStyles from './PasswordFieldStyle';

interface Props extends Omit<SharedTextFieldProps, 'variant'> {
  styles: string;
  name: string;
  label: string;
  withIcon?: boolean;
  iconColor: string;
}

const PasswordField: React.FC<Props> = ({
  styles, name, label, iconColor, withIcon, ...otherProps
}: Props) => {
  const [showPass, setShowPass] = useState(false);
  const classes = useStyles();

  const onClick = useCallback(() => setShowPass(!showPass), [showPass]);

  const icon = useMemo(() => {
    if (withIcon) {
      return (
        <Box className={classes.iconAdornment}>
          <PasswordSvg color={iconColor} />
        </Box>
      );
    }
    return null;
  }, [withIcon]);

  return (
    <TextField
      type="text"
      name={name}
      className={styles}
      placeholder={label}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onClick}>
              {showPass ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
        inputProps: {
          className: classNames({ [classes.hiddenText]: !showPass}),
        },
        startAdornment: icon,
        disableUnderline: true,
      }}
      {...otherProps}
    />
  );
};

export default PasswordField;

PasswordField.defaultProps = {
  withIcon: false,
};
