import { FormHelperText, Input as InputMUI, InputProps as InputMUIProps } from '@material-ui/core';
import { ReactElement } from 'react';
import classNames from 'classnames';
import useStyles from './styles';

interface InputProps extends InputMUIProps {
  errorMessage?: string;
}

const Input = ({ errorMessage, className, ...rest }: InputProps): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.inputWrapper}>
      <InputMUI
        {...rest}
        className={classNames(
          className,
          classes.input,
          { [classes.inputError]: Boolean(errorMessage) },
        )}
      />

      {errorMessage && (
        <FormHelperText className={classNames(
          classes.errorMessage,
          { [classes.errorMessageStartAdornment]: Boolean(rest.startAdornment) },
        )}
        >
          {errorMessage}

        </FormHelperText>
      )}
    </div>
  );
};

export default Input;

Input.defaultProps = {
  errorMessage: '',
};
