import { Box } from '@material-ui/core';
import { CollapseButtonContainer } from 'components/CollapseButtonContainer/CollapseButtonContainer';
import React, { useCallback } from 'react';
import {
  IOnChange,
  Preorder,
} from 'interfaces';
import { useClient } from 'hooks/client';
import PreorderContainer from './PreorderContainer';
import useStyles from '../styles';

interface Props {
  item: Preorder;
  distanceToYou: number | null;
  distance: number;
  onChange: (a: IOnChange) => void;
}

const PreorderListItem: React.FC<Props> = ({
  item,
  distanceToYou,
  distance,
  onChange,
}: Props) => {
  const {
    clientUser,
    clientRating,
  } = useClient(item.userUuid);
  const classes = useStyles();

  const handleHidePreorder = useCallback((preorderId: number) => {
    onChange({
      action: 'addedCanceledPreorder',
      data: preorderId,
    });
  }, []);

  const handleItem = useCallback((innerItem: Preorder) => {
    onChange({
      action: 'openPreorder',
      data: {
        to: '/vendor-offers',
        state: [innerItem],
      },
    });
  }, []);

  const handleSendAbuse = (data: {
    preorderId: number;
    abuseReason: string;
  }) => {
    onChange({
      action: 'sendAbuse',
      data,
    });
  };

  return (
    <Box className={classes.preorderContainerBox} key={item.key}>
      <PreorderContainer
        onChange={handleItem}
        clientUser={clientUser}
        clientRating={clientRating}
        item={item}
        distanceToYou={distanceToYou}
        distance={distance}
      />
      <CollapseButtonContainer
        item={item}
        handleSendAbuse={handleSendAbuse}
        handleItem={() => handleItem(item)}
        handleHidePreorder={handleHidePreorder}
        preorderType={item.type}
      />
    </Box>
  );
};

export default PreorderListItem;
