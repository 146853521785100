import React from 'react';
import { IconComponentProps } from 'interfaces';

const Language: React.FC<IconComponentProps> = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 72"
  >
    <path
      d="M36 1.18C16.64 1.18.9 16.93.9 36.28s15.75 35.1 35.1 35.1 35.1-15.75 35.1-35.1S55.36 1.18 36 1.18zm10.06 53.56c-.94 1.68-2 3.31-3.18 4.85-1.75 2.3-3.51 4.11-4.82 5.34v-10.2h8zm-8-4.12V38.34H50.8c-.22 4.25-1.14 8.38-2.75 12.27h-9.99zm22.8 4.12c-4.53 6.08-11.23 10.35-18.61 11.88 1.37-1.39 2.66-2.89 3.85-4.45a44.11 44.11 0 004.59-7.43h10.17zm-22.8-36.92V7.17c2.36 2.31 5.48 5.89 8.05 10.64h-8.05zm12.7 0a46.323 46.323 0 00-4.65-7.71 50.518 50.518 0 00-3.37-4.07c7.17 1.61 13.7 5.84 18.12 11.77h-10.1zm-12.7 16.4V21.94h9.99c1.6 3.95 2.52 8.08 2.74 12.27H38.06zm28.85 4.12a30.41 30.41 0 01-3.45 12.27H52.45c1.44-3.95 2.27-8.07 2.48-12.27h11.98zm0-4.12H54.93c-.2-4.18-1.02-8.31-2.45-12.27h10.98c2 3.82 3.17 7.95 3.45 12.27zm-47.36 16.4H8.54a30.454 30.454 0 01-3.45-12.27h11.98c.21 4.2 1.04 8.32 2.48 12.27zM5.09 34.22a30.41 30.41 0 013.45-12.27h10.98c-1.42 3.97-2.25 8.1-2.45 12.27H5.09zm28.85 4.12v12.27h-9.99c-1.6-3.89-2.52-8.02-2.75-12.27h12.74zm0 16.4v10.2a43.698 43.698 0 01-4.82-5.34 42.119 42.119 0 01-3.18-4.85h8zm-12.63 0c1.28 2.61 2.82 5.11 4.59 7.43 1.19 1.56 2.48 3.05 3.85 4.45-7.37-1.53-14.08-5.8-18.61-11.88h10.17zm12.63-32.8v12.27H21.2c.23-4.2 1.15-8.33 2.74-12.27h10zm-22.8-4.12c4.42-5.93 10.95-10.17 18.12-11.78-1.2 1.29-2.33 2.66-3.37 4.07-1.8 2.42-3.36 5.01-4.65 7.71h-10.1zm14.75 0c2.57-4.75 5.69-8.33 8.05-10.64v10.64h-8.05z"
      fill={color}
    />
  </svg>
);

export default Language;
