/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import urlJoin from 'url-join';
import i18n from 'i18n';
import { ApiResponse } from 'interfaces';

const REACT_APP_MAIN_HOST = process.env.REACT_APP_MAIN_HOST || '';

interface Params {
  [key: string]: string | number | boolean | any | any[];
}

interface FormDataValue {
  uri: string;
  name: string;
  type: string;
}

interface WebWayApiResponse<T> {
  docs: T[];
  limit?: number;
  offset?: number;
  returned?: number;
  success?: boolean;
  total?: number;
}

interface FormData {
  append(name: string, value: string | Blob | FormDataValue, fileName?: string): void;
  delete(name: string): void;
  get(name: string): FormDataEntryValue | null;
  getAll(name: string): FormDataEntryValue[];
  has(name: string): boolean;
  set(name: string, value: string | Blob | FormDataValue, fileName?: string): void;
}

class RequestService {
  url: string;

  constructor(url: string) {
    this.url = urlJoin(REACT_APP_MAIN_HOST, url);
  }

  getErrorMessage = (data: any) => (data.message || data.code || data.status || i18n.t('error'));

  handleCustomError = (error: any) => {
    const curError = error.response || error;
    const resultError = (curError.data && typeof curError.data !== 'string')
      ? curError.data
      : Error;
    const errorMessage = this.getErrorMessage(curError);

    throw {
      message: errorMessage,
      ...resultError,
    };
  };

  get(options?: AxiosRequestConfig): Promise<any> {
    return axios
      .get(this.url, options)
      .then((response) => (response.data?.docs ? response.data.docs : response))
      .catch(this.handleCustomError);
  }

  getAll(options?: AxiosRequestConfig): Promise<ApiResponse> {
    return axios
      .get(this.url, options)
      .then((response) => response.data)
      .catch(this.handleCustomError);
  }

  post(payload?: Params | FormData, getArray?: boolean, headers?: any): Promise<any> {
    return axios
      .post(this.url, payload, { headers })
      .then((res) => {
        if (getArray) return res.data.docs;
        return res.data.docs ? res.data.docs[0] : res.data;
      })
      .catch(this.handleCustomError);
  }

  postPre(payload?: Params): Promise<any> {
    return axios
      .post(this.url, payload)
      .then((res) => res)
      .catch(this.handleCustomError);
  }

  put<T>(
    payload?: Params | any,
    headers?: any,
  ): Promise<AxiosResponse<WebWayApiResponse<T>>> {
    return axios.put(this.url, payload, { headers })
      .catch(this.handleCustomError);
  }

  delete(): Promise<any> {
    return axios
      .delete(this.url)
      .then((res) => res.data)
      .catch(this.handleCustomError);
  }
}

export default RequestService;
