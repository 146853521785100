import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import LeafletMapWrapper from 'components/LeafletMapWrapper';
import { Map } from 'leaflet';
import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { getTranslateFunction } from 'helpers';
import { Suggestion } from 'interfaces';
import { useAuth } from 'services/auth';
import { reverseGeocode } from 'services/geo';
import useStyles from './styles';

interface SelectGeolocationMapProps {
  onChange: (item: Suggestion) => void;
  onCancel: () => void;
}

const SelectGeolocationMap: React.FC<SelectGeolocationMapProps> = ({
  onChange,
  onCancel,
}) => {
  const { currentIp } = useAuth();
  const t = getTranslateFunction();
  const [map, setMap] = useState<Map>();
  const classes = useStyles();

  const onSubmit = useCallback(async () => {
    if (!map) return;

    const centerMap = map.getCenter();

    const response = await reverseGeocode([centerMap.lat, centerMap.lng]);

    onChange(response);
  }, [map]);

  return (
    <div className={classNames(classes.mapWrapper)}>

      <LeafletMapWrapper onCreated={setMap} showCenteredPin defaultMapCenter={currentIp?.ll}>
        <div className={classes.boxBtnSelect}>
          <Button
            onClick={onCancel}
            color="primary"
            className={classes.buttonReturn}
            variant="contained"
          >
            <ArrowBack />
          </Button>
          <Button
            onClick={onSubmit}
            color="primary"
            variant="contained"
            className={classes.buttonSelectLocation}
          >
            {t('preorderPage.button.confirmLocationSelect')}
          </Button>
        </div>
      </LeafletMapWrapper>
    </div>
  );
};

export default SelectGeolocationMap;
