import React from 'react';
import { IconComponentProps } from 'interfaces';

const Plus: React.FC<IconComponentProps> = ({ color, width = 20, height = 20 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <path fill={color} d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
  </svg>
);

export default Plus;
