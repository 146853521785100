import classNames from 'classnames';
import React from 'react';
import useStyles from './TableStyles';

export const Table: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({
  children,
  className,
}) => {
  const classes = useStyles();

  return (
    <table className={classNames(classes.table, className)}>
      {children}
    </table>
  );
};

export const TableHead: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({
  children,
  className,
}) => {
  const classes = useStyles();

  return (
    <thead className={classNames(classes.tableHead, className)}>
      {children}
    </thead>
  );
};

export const TableBody: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({
  children,
  className,
}) => {
  const classes = useStyles();

  return (
    <tbody className={classNames(classes.tableBody, className)}>
      {children}
    </tbody>
  );
};

export const TableRow: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({
  children,
  className,
}) => {
  const classes = useStyles();

  return (
    <tr className={classNames(classes.tableRow, className)}>
      {children}
    </tr>
  );
};

export const TableCell: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({
  children,
  className,
}) => {
  const classes = useStyles();

  return (
    <td className={classNames(classes.tableCell, className)}>
      {children}
    </td>
  );
};

TableCell.defaultProps = { className: '' };
Table.defaultProps = { className: '' };
TableHead.defaultProps = { className: '' };
TableBody.defaultProps = { className: '' };
TableRow.defaultProps = { className: '' };
