import React, { FC } from 'react';
import { IconComponentProps } from 'interfaces';

const Commision: FC<IconComponentProps> = ({ color, width = 20, height = 20 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 72 72" width={width} height={height}>
    <path
      fill={color}
      d="M47.06 36.92c-.72 0-1.24.44-1.57 1.33-.32.89-.49 2.25-.49 4.08s.16 3.2.49 4.1c.32.9.85 1.35 1.57 1.35 1.38 0 2.08-1.82 2.08-5.45 0-3.61-.7-5.41-2.08-5.41zM26.99 29.7c0-3.6-.69-5.41-2.08-5.41-.72 0-1.24.44-1.57 1.33-.32.89-.49 2.25-.49 4.08s.16 3.2.49 4.1c.32.9.85 1.35 1.57 1.35 1.39 0 2.08-1.82 2.08-5.45z"
      className="st0"
    />
    <path fill={color} d="M36 4.39C18.54 4.39 4.39 18.54 4.39 36S18.54 67.61 36 67.61 67.61 53.46 67.61 36 53.46 4.39 36 4.39zM19.36 37.06c-1.3-1.71-1.95-4.18-1.95-7.41 0-6.59 2.49-9.88 7.46-9.88 2.44 0 4.31.85 5.61 2.56 1.3 1.71 1.96 4.15 1.96 7.32 0 3.32-.64 5.81-1.92 7.47-1.28 1.66-3.16 2.5-5.64 2.5-2.38 0-4.22-.85-5.52-2.56zm10.42 14.78h-5.19l17.54-31.61h5.19L29.78 51.84zm22.88-2.11c-1.28 1.66-3.16 2.5-5.64 2.5-2.38 0-4.22-.85-5.51-2.56-1.3-1.71-1.95-4.17-1.95-7.38 0-6.59 2.49-9.88 7.46-9.88 2.44 0 4.31.85 5.61 2.56s1.96 4.15 1.96 7.32c-.01 3.29-.65 5.78-1.93 7.44z" />
  </svg>

);

export default Commision;
