import { Box, Button, Typography } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import React from 'react';
import Modal from 'sharedComponents/Modal';
import icons from 'components/Icons';
import { getTranslateFunction } from 'helpers';

import useStyles from './styles';

const { balanceSvg } = icons;

export type TPreorderModalType = 'success' | 'insufficient' | 'unavailable';
export type TPreorderModal = {
  type: TPreorderModalType;
  price: number;
  bodyText?: string;
  onClick: () => void;
};

interface Props {
  value: TPreorderModal;
  balance?: number;
  currency: string;
  icon: string;
  onClose: () => void;
}

const PreorderPaymentModal: React.FC<Props> = ({
  value,
  balance,
  currency,
  icon,
  onClose,
}) => {
  const {
    onClick,
    price,
    type,
    bodyText,
  } = value;

  const t = getTranslateFunction();
  const classes = useStyles();

  return (
    <Modal
      withoutBorder
      modalClassName={classes.preorderModal}
      isOpen={Boolean(Object.keys(value).length)}
    >
      <Box className={classes.preorderModalWrapper}>
        <Typography>{t(`preorderPayment.title.${type}`)}</Typography>
        {bodyText && (
          <Typography className={classes.bodyText}>{bodyText}</Typography>
        )}

      </Box>
      <Box className={classes.balanceWrapper}>
        <Box className={classes.balanceItem}>
          <img className={classes.balanceIcon} src={balanceSvg} alt={t('preorderPayment.balanceAlt')} />
          {`${Math.round(balance || 0)} ${currency}`}
        </Box>
        <Box className={classes.balanceItem}>
          <img className={classes.balanceIcon} src={icon} alt="" />
          {`${Math.round(price)} ${currency}`}
        </Box>
      </Box>
      <Button onClick={onClick} className={classes.preorderModalButton}>{t(`preorderPayment.button.${type}`)}</Button>

      <Button className={classes.closeBtn} onClick={onClose}>
        <Clear />
      </Button>
    </Modal>
  );
};

export default PreorderPaymentModal;

PreorderPaymentModal.defaultProps = {
  balance: 0,
};
