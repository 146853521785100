export const controlButtonsList = [
  {
    id: 'home',
    label: 'footer.home',
    icon: 'homeIcon',
    to: '/',
  },
  {
    id: 'history',
    label: 'footer.history',
    icon: 'historyIcon',
    to: '/orders-history',
  },
  {
    id: 'transaction',
    label: 'footer.transaction',
    icon: 'transactionIcons',
    to: '/payments',
  },
  {
    id: 'chat',
    label: 'footer.chat',
    icon: 'chatLogo',
    to: '/chat',
  },
];
