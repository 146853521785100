import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import Preloader from 'components/Preloader/Preloader';
import { toast } from 'react-toastify';
import { getErrorMessage, getTranslateFunction, getUniqueItemsObjects } from 'helpers';
import { IChat, IOnChange } from 'interfaces';
import { getUserByReferralId } from 'services/commonService';
import { useChats, useOrderChats } from 'hooks/chat';
import ChatPage from '../components/ChatPageComponent';

const ChatContainer: React.FC = () => {
  const history = useHistory();
  const t = getTranslateFunction();
  const [createChatModal, setCreateChatModal] = useState<boolean>(false);

  const goToChat = (uuid: string) => {
    history.push(`/chat/${uuid}`);
  };

  const {
    chats,
    supportLastMessage,
    replyChatLastMessage,
    isLoading: chatsLoading,
  } = useChats();

  const {
    orderChats,
    isLoading: orderChatLoading,
  } = useOrderChats();

  const formattedChats = useMemo(() => {
    const emptyOrderChats = orderChats.filter(({ roomId }) => (
      !chats.some((item) => item.roomId === roomId)
    ));

    const chatsWithOrders = orderChats.reduce((acc: IChat[], orderChat) => {
      const existChat = acc.find(({ roomId }) => roomId === orderChat.roomId);

      if (existChat) {
        existChat.orderIds.push(...orderChat.orderIds);
        existChat.orderType.push(...orderChat.orderType);
        existChat.sortIndex = 2;
      }

      return acc;
    }, chats);

    const filteredChats = getUniqueItemsObjects(chatsWithOrders.concat(emptyOrderChats), 'roomId');

    const sortedChats = filteredChats
      .sort((a, b) => {
        if (!b.lastMessage) return 1;
        if (!a.lastMessage) return -1;

        return (
          new Date(b.lastMessage?.updatedAt).getTime()
          - new Date(a.lastMessage?.updatedAt).getTime()
        );
      })
      .sort((a, b) => b.sortIndex - a.sortIndex);

    return sortedChats;
  }, [chats, orderChats]);

  const createChat = useCallback(async (idUser) => {
    const existChat = chats.find((chat) => chat.user.referralId === idUser);
    if (existChat) {
      goToChat(existChat.roomId);
      setCreateChatModal(false);
      return;
    }

    try {
      const [chatUser] = await getUserByReferralId(idUser);

      if (chatUser) {
        goToChat(chatUser.uuid);
        setCreateChatModal(false);
        return;
      }

      toast.error(t('ChatPage.createChat.notFound'));
    } catch (error) {
      toast.error(getErrorMessage(error as Error));
    }
  }, []);

  const handleChange = ({ action, data }: IOnChange) => {
    switch (action) {
      case 'createChat': {
        createChat(data);
        break;
      }
      case 'setCreateChatModal': {
        setCreateChatModal(data);
        break;
      }
      case 'setActiveChat': {
        goToChat(data);
        break;
      }
      case 'goToPage': {
        history.push(data);
        break;
      }
      default: break;
    }
  };

  return (
    <>
      <ChatPage
        chats={formattedChats}
        supportLastMessage={supportLastMessage}
        replyChatLastMessage={replyChatLastMessage}
        onChange={handleChange}
        createChatModal={createChatModal}
      />

      {(chatsLoading || orderChatLoading) && (
        <Preloader />
      )}
    </>
  );
};

export default ChatContainer;
