import React from 'react';
import {
  Controller,
  UseFormMethods,
} from 'react-hook-form';
import { Country, isValidPhoneNumber } from 'react-phone-number-input';
import { FormControl, FormHelperText } from '@material-ui/core';
import classNames from 'classnames';

import EmailInput from 'components/EmailInput/EmailInput';
import PhoneInputWrapper from 'components/PhoneInput/PhoneInputWrapper';
import { getTranslateFunction } from 'helpers';

import { emailRegExp } from '../../../constants';
import { indexStyles } from '../material';

type Fields = {
  phone: string;
  email: string;
};

interface Props extends Pick<UseFormMethods<Fields>, 'control' | 'errors' | 'clearErrors'> {
  country?: Country;
  phoneDescription: string;
}

const ValidationFieldsBlock: React.FC<Props> = ({
  control,
  errors,
  country,
  phoneDescription,
  clearErrors,
}) => {
  const classes = indexStyles();
  const t = getTranslateFunction();

  return (
    <>
      <Controller
        control={control}
        name="phone"
        render={({ value, onChange, onBlur }) => (
          <FormControl className={classes.formControl} error={!!errors.phone}>
            <PhoneInputWrapper
              className={classNames('required', { error: errors.phone })}
              country={country}
              value={value}
              onBlur={onBlur}
              errorMessage={errors.phone?.message}
              onChange={onChange}
              onFocus={() => clearErrors('phone')}
            />
            <FormHelperText className="MuiFormHelperText-root Mui-error">
              {phoneDescription}
            </FormHelperText>
          </FormControl>
        )}
        rules={{
          required: {
            value: true,
            message: t('error.validation.required'),
          },
          minLength: {
            value: '9',
            message: t('error.WrongNumberFormat'),
          },
          maxLength: {
            value: '15',
            message: t('error.WrongNumberFormat'),
          },
          validate: (value) => isValidPhoneNumber(value) || t('error.validation.phoneNumberInvalid'),
        }}
      />

      <Controller
        control={control}
        name="email"
        render={({ onChange, value, onBlur }) => (
          <FormControl className={classes.formControl} error={!!errors.email}>
            <EmailInput
              className={classNames('required', { error: errors.email })}
              onChange={onChange}
              value={value}
              onFocus={() => clearErrors('email')}
              onBlur={onBlur}
            />
            {errors.email?.message && (
              <FormHelperText id="component-error-text" className={classes.errorMessage}>
                {t(errors.email.message)}
              </FormHelperText>
            )}
          </FormControl>
        )}
        rules={{
          required: {
            value: true,
            message: 'error.validation.required',
          },
          pattern: {
            value: emailRegExp,
            message: 'error.EmailInvalid',
          },
        }}
      />
    </>
  );
};

export default ValidationFieldsBlock;

ValidationFieldsBlock.defaultProps = {
  country: undefined,
};
