import React, { FC } from 'react';

interface Props {
  color: string;
}

const History: FC<Props> = ({ color }) => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 72 72" fill={color}>
    <path d="M51.85 51.8C40 51.8 30.4 42.2 30.4 30.35c0-9.18 5.77-17 13.87-20.07H3.58V66.2h53.65V51.12c-1.72.44-3.52.68-5.38.68zM11.92 20.29h13.39v3.99H11.92v-3.99zm0 7.98h13.39v3.99H11.92v-3.99zm0 7.98h13.39v3.99H11.92v-3.99zm0 7.99h13.39v3.99H11.92v-3.99zm0 7.98h25.17v3.99H11.92v-3.99z" />
    <path className="st0" d="M51.53 13.24c-9.56 0-17.3 7.75-17.3 17.3s7.75 17.3 17.3 17.3c9.56 0 17.3-7.75 17.3-17.3s-7.75-17.3-17.3-17.3zm3.8 17.3c0 2.1-1.7 3.8-3.8 3.8-2.1 0-3.8-1.7-3.8-3.8 0-.41.07-.8.19-1.17l-4.59-4.59 1.84-1.84 4.37 4.37c.58-.36 1.26-.57 2-.57.6 0 1.17.14 1.67.39l5.38-7.54 2.12 1.51-5.67 7.95c.18.46.29.96.29 1.49z" />
    <path d="M51.53 28.79c-.97 0-1.76.79-1.76 1.76s.79 1.76 1.76 1.76 1.76-.79 1.76-1.76c0-.98-.79-1.76-1.76-1.76z" />
  </svg>
);

export default History;
