import { useState, useEffect, useCallback } from 'react';
import { getTranslateFunction } from 'helpers';
import { getClientOrder, getVendorOrder } from 'services/commonService';
import { useUsersUuidByOrder } from 'hooks/order';
import { useOrders } from 'services/orders';
import { useUsers } from 'hooks/user';
import { IOrder } from 'interfaces';
import socket from 'socket';
import { useAuth } from 'services/auth';
import { minutes, ORDER_STATUSES } from '../../constants';

export interface NotificationsProvider {
  isModal: boolean;
  button: string;
  title: string;
  body: string;
  getUserPreorder: (n: number) => void;
  getVendorPreorder: (n: number) => void;
}

interface UMyOrdersRes {
  orders: IOrder[];
}

const useMyOrders = (): UMyOrdersRes => {
  const { user, vendors } = useAuth();
  const {
    orders,
    handleAddClientOrders,
    handleAddVendorOrders,
    handleLogout,
  } = useOrders();

  useEffect(() => {
    if (!user) {
      handleLogout();
      return;
    }

    const handleGetOrders = async () => {
      const clientResponse = await getClientOrder(user.id);
      handleAddClientOrders(clientResponse);

      if (!vendors.length) return;

      const promises = vendors.map((vendor) => (
        getVendorOrder(vendor.id)
      ));
      const vendorResponse = (await Promise.all(promises)).flat();
      handleAddVendorOrders(vendorResponse);
    };

    handleGetOrders();
  }, []);

  return {
    orders,
  };
};

export const useNotifications = () => {
  const { vendorId } = useAuth();

  const t = getTranslateFunction();
  const { orders } = useMyOrders();
  const { usersLoading, setOrders } = useUsersUuidByOrder();
  const {
    getUser,
    setUsersLoading,
  } = useUsers();

  const [exitingModal, setExitingModal] = useState<any>(null);
  const [clientComesModal, setClientComesModal] = useState<any>(null);
  const [canceledByVendorModal, setCanceledByVendorModal] = useState<any>(null);
  const [canceledByClientModal, setCanceledByClientModal] = useState<any>(null);
  const [toTimeModal, setToTimeModal] = useState<any>(null);
  const [toTimeOrders, setToTimeOrders] = useState<any>([]);

  useEffect(() => setUsersLoading(usersLoading), [usersLoading]);

  useEffect(() => {
    setOrders(orders);
    orders.forEach((item: any) => {
      socket.emit('joinToRoom', item.preorderId.toString());
      if (item.tripTime !== 'now') {
        setToTimeOrders((prev: any) => [...prev, item]);
      }
    });
  }, [orders]);

  const deleteOrderFromToTimeOrders = (orderId: number) => {
    setToTimeOrders((prev: any) => (
      prev.filter(({ id }: { id: number }) => id !== orderId)
    ));
  };

  const handleCheckTimeToOrder = useCallback(() => {
    toTimeOrders.forEach((order: any) => {
      const nowTime = Math.floor(Number(new Date()) / 1000);
      const orderTime = Math.floor(Number(new Date(order.tripTime)) / 1000);
      const differentTime = orderTime - nowTime;
      const showMessage = differentTime < 1800 && !order.showed && !toTimeModal;

      if (showMessage) {
        setToTimeModal({ ...order, timeLabel: `${Math.floor(differentTime / 60)} ${t(minutes)}` });
        const newArrayToTimeOrders = toTimeOrders.map((newOrder: any) => (
          newOrder.id === order.id ? { ...newOrder, showed: true } : newOrder
        ));
        setToTimeOrders(newArrayToTimeOrders);
      }
    });
  }, [toTimeOrders, t]);

  useEffect(() => {
    let interval: any;
    if (vendorId) {
      interval = setInterval(handleCheckTimeToOrder, 10000);
    }
    return () => clearInterval(interval);
  }, [vendorId]);

  useEffect(() => {
    socket.on('status', (data: { id: number; status: number }) => {
      const order: IOrder | undefined = orders.find(({ id }) => data.id === id);
      const orderUser = getUser(order?.customerId);
      if (!orderUser) return;

      if (vendorId && order?.serviceId === vendorId) {
        if (data.status === ORDER_STATUSES.CANCELED_BY_CLIENT) {
          setCanceledByClientModal({ ...data, text: `${orderUser && orderUser.firstName} ${t('OrderPage.declineOrder')}` });
          deleteOrderFromToTimeOrders(data.id);
        }
        if (data.status === ORDER_STATUSES.EXITING) {
          setClientComesModal({ ...data, text: `${orderUser?.firstName} ${t('OrderPage.notification.IsComing')}` });
        }
      } else {
        if (data.status === ORDER_STATUSES.WAITING) {
          setExitingModal(data);
        }
        if (data.status === ORDER_STATUSES.CANCELED_BY_VENDOR) {
          setCanceledByVendorModal(data);
          deleteOrderFromToTimeOrders(data.id);
        }
      }
    });

    return () => {
      socket.off('status');
    };
  }, [orders]);

  return {
    exitingModal,
    setExitingModal,
    canceledByVendorModal,
    setCanceledByVendorModal,
    canceledByClientModal,
    setCanceledByClientModal,
    clientComesModal,
    setClientComesModal,
    toTimeModal,
    setToTimeModal,
  };
};
