import { Box, Typography, Avatar } from '@material-ui/core';
import React, { useMemo } from 'react';
import { TFunction } from 'i18next';
import {
  RatingType, User, Vendor,
} from 'interfaces';
import Icons from 'components/Icons';
import { currencyFormatter, dateFormatter } from 'utils/formatters';
import { getUserImageLink } from 'helpers';
import {
  minutes,
  cyan,
  orange,
} from '../../constants';
import useStyles from './VendorInfoStyle';

const {
  fromSvg,
  CommentTsx,
  StarTsx,
  nowSvg,
  toTimeSvg,
} = Icons;

interface Props {
  vendor: Vendor;
  vendorUser: User;
  distance?: number;
  order: any;
  ratingAvg: number;
  userRating: RatingType | undefined;
  commentsLength: number;
  t: TFunction;
}

const VendorInfoItem: React.FC<Props> = ({
  vendor,
  vendorUser,
  distance,
  order,
  userRating,
  ratingAvg,
  commentsLength,
  t,
}) => {
  const classes = useStyles();
  const { tripRequirements, tripType } = order;

  const distanceTo = distance && Math.floor(distance / 1000);

  const rating = useMemo(() => (Math.floor(ratingAvg)), [ratingAvg]);

  const isDriverType = useMemo(() => {
    if (tripRequirements) {
      return tripRequirements === t('HomePage.alongTheWay.TemporaryDriver');
    }
    return false;
  }, [tripRequirements]);

  const isCourirerType = useMemo(() => tripType === 'courier', [tripType]);
  const tripTimeIcon = useMemo(() => (order.tripTime === 'now' ? nowSvg : toTimeSvg), [order]);
  const tripTimeText = useMemo(() => (
    order.tripTime === 'now' ? t('preorder.modal.nowLabel') : dateFormatter(Number(new Date(order.tripTime)))
  ), [order]);
  const photoSrc = useMemo(() => (
    getUserImageLink(vendorUser?.userPhoto, vendorUser?.gender)), [vendorUser]);

  if (!vendorUser) return null;

  return (
    <Box className={classes.vendorContainer}>
      <Box className={classes.vendor}>
        <Box className={classes.services}>
          <Box className={classes.listItem}>
            <img
              className={classes.svg}
              src={Icons[`${order.paymentType}Svg`]}
              alt={t(`preorder.modal.${order.paymentType}Label`)}
            />
            <Typography noWrap data-testid="priceWithCurrency" variant="caption">{currencyFormatter(order.price, order.currency)}</Typography>
          </Box>
          {order.time && (
            <Box className={classes.listItem}>
              <img
                className={classes.svg}
                src={tripTimeIcon}
                alt={tripTimeText}
              />
              <Typography data-testid="orderTime" variant="caption">{`${order.time} ${t(minutes)}`}</Typography>
            </Box>
          )}
          {typeof distance === 'number' && (
            <Box className={classes.listItem}>
              <img
                className={classes.svg}
                src={fromSvg}
                alt={t('order.modal.distanceLabel')}
              />
              <Typography data-testid="distance" variant="caption">{distanceTo}</Typography>
            </Box>
          )}
        </Box>
        <Box className={classes.vendorInfo}>
          <Box className={classes.vendorInfoName}>
            <Typography variant="caption">{vendorUser.firstName}</Typography>
            {!isDriverType && (
              <>
                <Typography data-testid="vehicleInfo" variant="caption">{`${t(`carCombobox.color.${vendor.vehicleColor}`)} ${vendor.vehicleBrand} ${vendor.vehicleModel}`}</Typography>
                <Typography>{vendor.vehicleNumber}</Typography>
              </>
            )}
            <Box className={classes.vendorInfoStats}>
              {Boolean(ratingAvg) && (
                <Box className={classes.vendorInfoStatsItem}>
                  <StarTsx color={orange} width={10} height={10} />
                  <Typography variant="caption">{rating}</Typography>
                </Box>
              )}
              <Box className={classes.vendorInfoStatsItem}>
                <CommentTsx color={cyan} width={10} height={10} />
                <Typography variant="caption">{commentsLength}</Typography>
              </Box>
            </Box>
          </Box>
          <Avatar variant="rounded" className={classes.vendorPhoto} alt={vendorUser.firstName} src={photoSrc} />
        </Box>
      </Box>
      {Boolean(ratingAvg) && userRating && (
        <Box data-testid="rating-container" className={classes.vendorStats}>
          <Box className={classes.vendorStatsItem}>
            <Typography>{t('preorder.modal.politeness')}</Typography>
            <Typography>{Math.round(Number(userRating.politenessRatingAvg))}</Typography>
          </Box>
          {!(isDriverType || isCourirerType) && Boolean(userRating.vehicleConditionRatingAvg) && (
            <Box className={classes.vendorStatsItem}>
              <Typography>{t('preorder.modal.vehicleCondition')}</Typography>
              <Typography data-testid="rating-vehicleCondition">{Math.round(Number(userRating.vehicleConditionRatingAvg))}</Typography>
            </Box>
          )}
          {!isCourirerType && Boolean(userRating.drivingSkillRatingAvg) && (
            <Box className={classes.vendorStatsItem}>
              <Typography>{t('preorder.modal.drivingSkill')}</Typography>
              <Typography data-testid="rating-drivingSkill">{Math.round(Number(userRating.drivingSkillRatingAvg))}</Typography>
            </Box>
          )}
          {isCourirerType && Boolean(userRating.diligenceRatingAvg) && (
            <Box className={classes.vendorStatsItem}>
              <Typography>{t('preorder.modal.diligence')}</Typography>
              <Typography data-testid="rating-diligence">{Math.round(Number(userRating.diligenceRatingAvg))}</Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default VendorInfoItem;

VendorInfoItem.defaultProps = {
  distance: undefined,
};
