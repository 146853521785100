import { makeStyles } from '@material-ui/core';
import {
  grayBG, green, textColor,
} from '../../constants';

export default makeStyles({
  collapseContainer: {
    position: 'absolute',
    height: '100%',
    width: '15px',
    display: 'flex',
    top: '0',
    right: '0',
    backgroundColor: grayBG,
    overflowX: 'hidden',
    transition: '0.4s',
    borderRadius: '3px',
  },
  collapseContainerOpen: {
    width: '100%',
  },
  collapseButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginLeft: '-5px',
    color: textColor,
    width: '30px',
  },
  buttonsContainer: {
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '10px',
    overflow: 'hidden',
  },
  warningButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: green,
    cursor: 'pointer',
  },
}, { index: 1 });
