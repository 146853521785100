import { ConfiguredFile, confugureFilesBeforeSend } from 'helpers';
import {
  Vendor,
  User,
  VendorFileProp,
  VendorToCreate,
} from 'interfaces';
import {
  configureVendor,
  vendorFileProps,
} from 'pages/CreateVendorPage/helpers';
import {
  sendFile,
  updateVendor,
} from 'services/commonService';

export const getFilesPropObj = (vendor: Vendor)
: { [key: string]: File } => Object.entries(vendor)
  .reduce((acc, [key, value]) => {
    const isFile = typeof value !== 'number';
    const field = key as VendorFileProp;

    if (vendorFileProps.includes(field) && isFile) {
      return {
        ...acc,
        [key]: value,
      };
    }
    return acc;
  }, {});

export const onSubmit = async (
  data: VendorToCreate,
  vendor: Vendor,
  user: User,
): Promise<Vendor> => {
  const {
    photos,
    formattedVendor,
    hashesOfFiles,
  } = await configureVendor(data);

  const [updatedVendor] = await updateVendor(vendor.id, {
    ...formattedVendor,
    userId: user?.id,
  });

  const formattedFiles = confugureFilesBeforeSend(photos, hashesOfFiles, updatedVendor);

  formattedFiles.forEach(({ data: formData, id }: ConfiguredFile) => {
    sendFile(formData, 'vendors', id);
  });

  return updatedVendor;
};
