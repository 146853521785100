import { Box } from '@material-ui/core';
import { useHistory } from 'react-router';
import ServiceMessage from 'sharedComponents/ServiceMessage/ServiceMessage';
import { getTranslateFunction } from 'helpers';
import useStyles from './styles';

const UnverifiedPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const t = getTranslateFunction();

  const handleCloseBtnClick = () => {
    history.push('/profile');
  };

  return (
    <Box className={classes.wrapper}>
      <ServiceMessage message={t('unverifiedPage.message')} onClose={handleCloseBtnClick} type='warning' />
    </Box>
  );
};

export default UnverifiedPage;
