import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  iconAdornment: {
    width: '20px',
    height: 'auto',
    margin: '5px',
  },
  hiddenText: {
    textSecurity: 'disc',
  },
}, { index: 1 });
