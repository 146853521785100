import {
  Button,
  Container,
  FormHelperText,
  Input,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ConfirmCode from 'components/ConfirmCode';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { getTranslateFunction } from 'helpers';
import {
  FormDataSignUp,
  IOnChange, Lang, UserToCreate,
} from 'interfaces';
import logo from 'components/common/WebwayLogo.png';
import { useCountdown, useRequests } from 'services/customHooks';
import i18n from 'i18n';
import logoHourseWithBG from 'components/common/logoHourseWithBG.png';
import { submitter as submitUser } from 'pages/SignUpPage/helper';
import { submitter as submitVendor } from 'pages/CreateVendorPage/helpers';
import { useSettings } from 'pages/SettingsPage/SettingsContext';
import { formatMsToSeconds } from 'utils/formatters';
import { checkAvailability } from 'services/commonService';
import IndexedDB from 'indexedDB';
import {
  EMAIL_EDIT_ATTEMPTS,
  EMAIL_TIME_REQUEST,
  INDEXEDDB_SIGNUP_TABLE_NAME,
  SIGNUP_FORM_STEPS,
  emailRegExp,
} from '../../../constants';

import { verifyEmailStyles } from '../material';

interface Props {
  onChange: (a: IOnChange) => void;
  formData: FormDataSignUp;
}

const VerifyEmailStep: React.FC<Props> = ({ formData, onChange }) => {
  const classes = verifyEmailStyles();
  const history = useHistory();
  const t = getTranslateFunction();
  const [createdCodeTime, setCreatedCodeTime] = useState(0);
  const { updateUserSettings } = useSettings();
  const [email, setEmail] = useState('');
  const [editCount, setEditCount] = useState(0);
  const [emailEditable, setEmailEditable] = useState('');
  const [emailInputError, setEmailInputError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { count, setCount } = useCountdown();

  const {
    sendRequest,
    sendCode,
    attempts,
    isAttemptsForCodeLeft,
  } = useRequests();

  const handleSendRequest = async () => {
    try {
      setErrorMessage('');
      const data = {...formData, email, lang: i18n.language};
      await sendRequest('/users/create-validation', data);
      setCreatedCodeTime(new Date().getTime());
    } catch (error) {
      const e = error as Error;
      setErrorMessage(e.message);
    }
  };

  const handleSendCode = async (data: UserToCreate) => sendCode('/users', data);

  const handleSignUp = async (emailCode: string) => {
    const data = {
      ...formData,
      lang: i18n.language,
      email,
      emailCode: Number(emailCode),
    };
    const createdUser = await submitUser(data, handleSendCode);

    await updateUserSettings({
      ringtoneType: 'notification1',
      userLanguage: i18n.language as Lang,
    });

    if (formData.performerAccount && createdUser) {
      await submitVendor({ ...formData, userId: createdUser.id });
    }

    [
      `formSteps-${SIGNUP_FORM_STEPS.client}`,
      `formSteps-${SIGNUP_FORM_STEPS.vendor}`,
    ].forEach((key) => {
      new IndexedDB().deleteData(INDEXEDDB_SIGNUP_TABLE_NAME, key);
    });

    history.push('/processing');
  };

  useEffect(() => {
    setEmail(formData.email);
    setEmailEditable(formData.email);
  }, [formData.email]);

  useEffect(() => {
    if (!email) {
      return;
    }

    handleSendRequest();
  }, [email]);

  const handleEditEmailRequest = async () => {
    const isValid = emailEditable.match(emailRegExp);
    if (!isValid) {
      setEmailInputError('error.EmailInvalid');
      return;
    }

    const emailCheck = await checkAvailability('email', emailEditable);

    if (!emailCheck.result) {
      setEmailInputError('signup.email.existed');
      return;
    }

    setEditCount((prev) => {
      const newValue = prev + 1;
      if (newValue < EMAIL_EDIT_ATTEMPTS) {
        setCount(EMAIL_TIME_REQUEST);
      }
      return newValue;
    });
    setEmail(emailEditable);
  };

  const onCancel = () => {
    onChange({ action: 'stepBack' });
  };

  const shouldEditEmail = !count && editCount < EMAIL_EDIT_ATTEMPTS;

  return (
    <Container className={classes.confirmFormContainer}>
      <div className={classes.top}>
        <div className={classes.logoContainer}>
          <img className={classes.logo} alt={t('webway.logo')} src={logo} />
        </div>
        <Typography className={classes.siteLinkWrapper}>
          <Link
            className={classes.siteLink}
            to={t('signInPage.siteLink.link')}
            target="_blank"
          >
            {t('signInPage.siteLink.label')}
          </Link>
        </Typography>
      </div>

      <div className={classes.content}>
        <ConfirmCode
          createdCodeTime={createdCodeTime}
          isAttemptsForCodeLeft={isAttemptsForCodeLeft}
          onSendCode={handleSignUp}
          resendRequest={handleSendRequest}
          attempts={attempts}
        >
          <div className={classes.editEmailWrapper}>
            <div className={classes.editEmailInputWrapper}>
              <Input
                className={classNames(classes.editEmailInput, { error: emailInputError})}
                disabled={!shouldEditEmail}
                onFocus={() => setEmailInputError('')}
                value={emailEditable}
                onChange={({ target: { value } }) => setEmailEditable(value)}
              />
              {emailInputError && (
                <FormHelperText className={classes.errorMessage}>
                  {t(emailInputError)}
                </FormHelperText>
              )}
            </div>
            <div className={classes.editEmailBottom}>
              <Typography>
                {`${EMAIL_EDIT_ATTEMPTS}/${editCount}`}
              </Typography>

              {Boolean(count) && (
              <Typography>
                {formatMsToSeconds(count)}
              </Typography>
              )}

              {shouldEditEmail && (
              <Button
                className={classes.editEmailButton}
                onClick={handleEditEmailRequest}
                disabled={email === emailEditable}
              >
                {t('signUpPage.confirm.editEmail')}
              </Button>
              )}

              {editCount >= EMAIL_EDIT_ATTEMPTS && (
                <FormHelperText className={classes.codeErrorMessage}>
                  {t('signUpPage.confirm.editEmailAttemptsLeft')}
                </FormHelperText>
              )}
            </div>

          </div>
        </ConfirmCode>

        <FormHelperText className={classes.codeErrorMessage}>
          {t(errorMessage)}
        </FormHelperText>
      </div>

      <div className={classes.bottom}>
        <Button className={classes.cancelButton} onClick={onCancel}>{t('signUpPage.confirm.cancel')}</Button>
        <img className={classes.logoGif} src={logoHourseWithBG} alt="loading..." />
      </div>

    </Container>
  );
};

export default VerifyEmailStep;
