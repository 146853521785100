import React from 'react';
import { Box } from '@material-ui/core';
import {
  Control,
  DeepMap,
  FieldError,
} from 'react-hook-form';
import icons from 'components/Icons';
import { indexStyles } from '../material';
import DocumentItem, { IDocumentItem } from './DocumentItem';

const {
  documentSvg,
  photoWithDocsSvg,
  profileSvg,
} = icons;

type Fields = {
  passportFirstPagePhoto: File[] | null;
  userPhoto: File[] | null;
  passportAndFacePhoto: File[] | null;
};

interface Props {
  errors: DeepMap<Fields, FieldError>;
  control: Control<Fields>;
  userPhotoDescription: string;
}

const fields: IDocumentItem[] = [
  {
    key: 'passportFirstPagePhoto',
    id: 'passportFirstPagePhoto',
    preview: documentSvg,
    text: 'signInPage.passportFirstPagePhoto',
    rules: {
      required: {
        value: true,
        message: 'error.validation.required',
      },
    },
  },
  {
    key: 'passportAndFacePhoto',
    id: 'passportAndFacePhoto',
    preview: photoWithDocsSvg,
    text: 'signInPage.passportAndFacePhoto',
    rules: {
      required: {
        value: true,
        message: 'error.validation.required',
      },
    },
  },
  {
    key: 'userPhoto',
    id: 'userPhoto',
    preview: profileSvg,
    text: 'signInPage.profilePhoto',
  },
];

const DocumentsBlock: React.FC<Props> = ({
  userPhotoDescription,
  errors,
  control,
}: Props) => {
  const classes = indexStyles();

  return (
    <Box className={classes.documentsBox}>
      {fields.map((item) => (
        <DocumentItem
          item={item}
          key={item.key}
          errors={errors}
          control={control}
          decsription={userPhotoDescription}
        />
      ))}
    </Box>
  );
};

export default DocumentsBlock;
