import { makeStyles } from '@material-ui/core';
import {
  grayBG,
} from '../../constants';

export default makeStyles({
  svg: {
    width: '25px',
    height: '25px',
    marginRight: '5px',
  },
  balanceListItem: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  balanceTitle: {
    backgroundColor: grayBG,
    width: '100%',
    fontSize: '13px',
    padding: '5px',
    boxSizing: 'border-box',
  },
  balanceLabel: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },

}, { index: 1 });
