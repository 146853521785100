import { Box, Typography } from '@material-ui/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { ArrowDownward, Done } from '@material-ui/icons';
import { getTranslateFunction } from 'helpers';
import Icons from 'components/Icons';
import {
  options,
  groupByType,
  buttons,
  HomepageButton,
  filteredOptions,
} from 'utils/optionsConfig';
import { IOnChange, OrderType, Vendor } from 'interfaces';
import { useLocalStorage } from 'services/customHooks';
import useStyles from '../DropdownOptionsStyle';
import { VEHICLE_ORDER_TYPES } from '../../../constants';

export type ButtonItem = {
  type: string;
  service: string;
  contentKey: string;
  icon: string;
};

interface CheckboxProps {
  item: ButtonItem,
  checked: boolean;
  onChange: (a: ButtonItem) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  item,
  checked,
  onChange,
}) => {
  const t = getTranslateFunction();
  const handleChange = useCallback(() => {
    onChange(item);
  }, [item, onChange]);

  const classes = useStyles();

  return (
    <Box
      data-opts-btn={item.contentKey}
      className={classNames(classes.optionsBtn, { [classes.optionsBtnChecked]: checked })}
      onClick={handleChange}
    >
      <img
        src={Icons[item.icon]}
        alt={item.contentKey}
        className={classes.contentIcon}
      />
      <Typography className={classes.text}>
        {t(item.contentKey)}
      </Typography>
      {checked && (
        <Done className={classes.optionsBtnCheckedIcon} />
      )}
    </Box>
  );
};

interface ButtonGroupProps {
  checked: ButtonItem[];
  id: string;
  name: string;
  isOpen: boolean;
  option: HomepageButton[];
  onChange: (a: ButtonItem) => void;
  onToggleOpen: (a: string) => void;
  isShowParent: boolean;
  showIsSelected: boolean;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  checked,
  id,
  isOpen,
  name,
  onChange,
  onToggleOpen,
  option = [],
  isShowParent,
  showIsSelected,
}) => {
  const classes = useStyles();
  const onToggleOpenRef = useRef(onToggleOpen);
  const t = getTranslateFunction();

  const handleToggleOpen = useCallback(() => {
    onToggleOpenRef.current(id);
  }, [id, onToggleOpenRef]);

  const isInclude = useCallback((key) => (
    checked.some(({ contentKey }) => contentKey === key)
  ), [checked]);

  return (
    <Box className={classes.mainBtnContainer}>
      {!isShowParent && (
        <Box
          className={classes.mainBtn}
          onClick={handleToggleOpen}
        >
          <Box className={classes.title}>
            <img src={Icons[name]} alt={name} className={classes.svgIcon} />
            <Typography variant="body2">{t(name)}</Typography>
          </Box>
          <Box className={classNames(classes.arrow, {open: isOpen})}>
            <ArrowDownward />
          </Box>
        </Box>
      )}
      <Box className={classNames(classes.optionsBox, {open: isOpen})}>
        {option.map((item: HomepageButton) => (
          <Checkbox
            key={item.contentKey}
            item={item}
            checked={showIsSelected && isInclude(item.contentKey)}
            onChange={onChange}
          />
        ))}
      </Box>
    </Box>
  );
};

interface DropdownOptionsComponentProps {
  vendor: Vendor | null;
  onChange: (a: IOnChange) => void;
  menuExecutorProjectsTop: string;
  currentType: OrderType;
}

const DropdownOptionsComponent: React.FC<DropdownOptionsComponentProps> = ({
  vendor,
  onChange,
  menuExecutorProjectsTop,
  currentType,
}: DropdownOptionsComponentProps) => {
  const [openedGroups, setOpenedGroups] = useState<string[]>([]);
  const [checked, setChecked] = useLocalStorage<HomepageButton[]>(`checkedOptions-${currentType}`, []);
  const classes = useStyles();
  const t = getTranslateFunction();

  const handleToggleOpen = useCallback((id) => {
    setOpenedGroups((prev: string[]) => {
      if (prev.includes(id)) {
        return prev.filter((innerId: string) => id !== innerId);
      }
      return prev.concat([id]);
    });
  }, []);

  const checkOptions = filteredOptions.includes(currentType);

  const handleSubmit = useCallback(() => {
    if (!vendor) return;

    onChange({
      action: 'checkItem',
      data: { checked, currentType },
    });
  }, [checked, vendor]);

  const handleChange = useCallback((item) => {
    if (!vendor) {
      onChange({
        action: 'checkItem',
        data: { checked: [item], currentType },
      });
      return;
    }

    setChecked((prev) => {
      const isInclude = prev.some(({ contentKey }) => contentKey === item.contentKey);

      const changedArray = isInclude
        ? prev.filter(({ contentKey }) => item.contentKey !== contentKey)
        : [...prev, item];

      return changedArray;
    });
  }, [vendor]);

  useEffect(() => {
    if (!vendor) return;
    const isVehicleType = VEHICLE_ORDER_TYPES.includes(currentType);
    const withVehicle = Boolean(vendor[currentType]);

    if (isVehicleType && !withVehicle) {
      onChange({
        action: 'historyPush',
        data: '/create-vendor',
      });
    }
  }, [vendor, currentType]);

  const groups = useMemo(() => groupByType(buttons.filter((item) => item.type === currentType), 'service'), []);

  return (
    <Box className={classes.dropdownBox}>
      {Boolean(vendor && menuExecutorProjectsTop) && (
        <Typography className={classes.pageTitle}>
          {menuExecutorProjectsTop}
        </Typography>
      )}
      {Object.entries(groups).map(([key, option]) => {
        const buttonOptions = options.find(({ service }) => key === service) || { contentKey: '' };
        return (
          <ButtonGroup
            key={key}
            showIsSelected={Boolean(vendor)}
            name={buttonOptions.contentKey}
            id={key}
            isOpen={checkOptions ? true : openedGroups.includes(key)}
            onChange={handleChange}
            onToggleOpen={handleToggleOpen}
            checked={checked}
            option={option}
            isShowParent={checkOptions}
          />
        );
      })}
      {Boolean(vendor) && (
        <Typography className={classes.confirmBtn} onClick={handleSubmit}>
          {t('Submit')}
        </Typography>
      )}
    </Box>
  );
};

export default DropdownOptionsComponent;
